import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { useDebouncedCallback } from 'use-debounce';
import Input from 'Components/atoms/Input';
import FriendlyPng from 'Images/friendly-bg.png';
import Button from 'Components/atoms/Button';

const Container = styled.div`
  height: 100%;
  text-align: center;
  ${space};
`;

const Title = styled.h1`
  ${themeGet('fonts.h1')};
  margin: 0;
`;

const Text = styled.p`
  ${themeGet('fonts.body2')};
  margin: 0;
  margin-top: 4px;
  ${space};
`;

const InputPanel = styled.div`
  height: 44px;
  margin: 10px 0;
`;

const FriendlyImg = styled.img.attrs({ src: FriendlyPng })``;

const HighlightText = styled.h3`
  ${themeGet('fonts.h3Button')};
  color: ${themeGet('colors.mainBlue')};
  margin: 10px 0 30px;
`;

const RegisterButton = styled(Button)`
  width: 240px;
`;

const GoBackButton = styled(Button)`
  ${themeGet('fonts.body')};
  width: 240px;
  color: ${themeGet('colors.mainBlue')};
  margin-top: 4px;
`;

export const PureSignUpInvitation = props => {
  const {
    onPreviousClick,
    onNextClick,
    preStoredInvitationCode,
    setEditingUserUserInvitation,
  } = props;
  const [invitationCode, setInvitationCode] = useState();
  console.log('preStoredInvitationCode', preStoredInvitationCode);

  const handleOnInvitationCodeChange = useCallback(event => {
    setInvitationCode(event.target.value || '');
  }, []);

  const [handleOnNextClick] = useDebouncedCallback(
    () => {
      setEditingUserUserInvitation(invitationCode);
      onNextClick({ invitationCode });
    },
    300,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    setInvitationCode(preStoredInvitationCode);
  }, [preStoredInvitationCode]);

  const [handleOnPreviousClick] = useDebouncedCallback(
    () => {
      setEditingUserUserInvitation(invitationCode);
      onPreviousClick();
    },
    300,
    { leading: true, trailing: false }
  );

  return (
    <Container px={['15px', '30px']}>
      <Title>你有朋友的邀請碼嗎？</Title>
      <Text>現在輸入邀請碼即可於會員資格審核通過後，獲得免費騎乘金50</Text>
      <InputPanel>
        <Input
          placeholder="輸入朋友邀請碼"
          value={invitationCode}
          onChange={handleOnInvitationCodeChange}
        />
      </InputPanel>
      <Text mb="30px">
        若你在此輸入邀請碼，後續將無法使用其他新註冊優惠碼。若無，也可跳過此選項，直接完成註冊
      </Text>
      <FriendlyImg />
      <HighlightText>就快完成了！</HighlightText>
      <RegisterButton boxShadow="light" shape="capsule" type="float" onClick={handleOnNextClick}>
        完成註冊
      </RegisterButton>
      <GoBackButton shape="capsule" color="plan" onClick={handleOnPreviousClick}>
        上一步
      </GoBackButton>
    </Container>
  );
};

PureSignUpInvitation.defaultProps = {
  onPreviousClick: () => {},
  onNextClick: () => {},
};

PureSignUpInvitation.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
};

export default React.memo(PureSignUpInvitation);

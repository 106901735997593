import React, { useState, Fragment, useEffect, useMemo, useCallback } from 'react';
import {
  AlertContainer,
  ButtonIcon,
  ButtonAction,
  TopButtonGroup,
  BottomGroup,
  PaddingWrapper,
  Wrapper,
} from './style';
import { useUserInfo } from '~/context/';
import styled from 'styled-components';
import { parseISO, isAfter, isBefore } from 'date-fns';
import css from '@styled-system/css';
import Alert from 'Components/atoms/Alert';
import { useSpring, useTransition, animated } from 'react-spring';
import Header from 'Components/organisms/Header';
import BarReserve from '../../components/BarReserve';
import { ReactComponent as locate } from 'Images/icons/locate.svg';
import { ReactComponent as parkingLot } from 'Images/icons/parkingLot.svg';
import { ReactComponent as parkingLotGrey } from 'Images/icons/parkingLotGrey.svg';
import { useHistory } from 'react-router-dom';
import { rentStatus, userStatusMap } from '~/configs/';
import { getCardStatusMessage, getAlertMessage } from './config';
import createCardStateMachine, { CardStates } from './cardStateMachine';
import { getMissionSsoToken, getCampaigns } from '~/services/api';
import { logPromotionEventEvent } from '~/utils/firebaseService';

const EventIcon = styled(animated.img)`
  position: absolute;
  z-index: 2;
  height: 60px;
  width: 60px;
  width: 60px;
  ${css({
    top: [10, 20],
    left: [10, 20],
  })}
`;

const MainControlPanel = React.memo(
  ({
    userMode,
    isGuest,
    batPercent,
    isCardVisible,
    statusId,
    status,
    offsetHeight,
    showPolygon,
    togglePolygon,
    toCurrentLocation,
    // logOut,
    pageDispatch,
    setIsCardVisible,
    visibleDialogBox,
  }) => {
    const [alertMessage, setAlertMessage] = useState();
    const [isInfoCardVisible, setIsInfoCardVisible] = useState(false);
    const actionCardStateMachine = createCardStateMachine(CardStates.ActionCardsState);
    const infoCardStateMachine = createCardStateMachine(CardStates.InfoCardsState);
    const history = useHistory();
    const { user } = useUserInfo();
    const [campaign, setCampaign] = useState();

    const barReserveTransition = useTransition(userMode === rentStatus.routeToScooter, null, {
      from: {
        transform: 'translate3d(0, -100%, 0)',
      },
      enter: { opacity: 1, transform: 'translate3d(0, 0%, 0)' },
      leave: {
        opacity: 0,
        transform: 'translate3d(0, -100%, 0)',
      },
      config: { mass: 1, tension: 170, friction: 8 },
    });

    const buttonSpring = useSpring({
      flexGrow: 1,
      margin: '0 10px',
      opacity: isCardVisible ? 0 : 1,
      pointerEvents: isCardVisible ? 'none' : 'auto',
    });

    const topControlGroupSpring = useSpring({
      height: isCardVisible
        ? offsetHeight.topControlGroupContainer + offsetHeight.topControlGroupTop + 8
        : 60,
    });

    const updateAlertMessage = useCallback(
      type => {
        if (statusId || batPercent !== null) {
          const message = getAlertMessage(
            {
              currentUserStatus: userStatusMap[statusId],
              batPercent,
              type,
            },
            history
          );
          setAlertMessage(message);
        }
      },
      [batPercent, history, statusId]
    );

    const topInfoGroupSpring = useSpring({
      height: isInfoCardVisible
        ? offsetHeight.topInfoGroupContainer + offsetHeight.topControlGroupContainer + 8
        : 60 + 10,
    });

    const headerSpring = useSpring({
      transform: `translate3d(0, ${
        alertMessage && userMode !== rentStatus.routeToScooter ? offsetHeight.alert + 8 : 0
      }px,0)`,
    });

    const eventSpring = useSpring({
      transform: `translate3d(0, ${
        alertMessage && userMode !== rentStatus.routeToScooter ? offsetHeight.alert + 8 + 55 : 55
      }px,0)`,
    });

    useEffect(() => {
      updateAlertMessage();
    }, [updateAlertMessage]);

    useEffect(() => {
      if (alertMessage?.isPermanent === false) {
        setTimeout(() => {
          updateAlertMessage();
        }, 2000);
      }
    }, [alertMessage, updateAlertMessage]);

    const cardStatusMessage = useMemo(getCardStatusMessage(userStatusMap[statusId], history), [
      statusId,
    ]);

    const handleSetOffsetHeight = (name, height) => {
      if (height && height !== offsetHeight[name]) {
        pageDispatch({
          type: 'setOffsetHeight',
          name: name,
          offsetHeight: height,
        });
      }
    };

    useEffect(() => {
      setIsCardVisible(
        actionCardStateMachine.getCardIsVisible({
          userMode,
          paymentErr: status.paymentErr,
          userStatus: userStatusMap[statusId],
        })
      );
      setIsInfoCardVisible(
        infoCardStateMachine.getCardIsVisible({
          userMode,
          paymentErr: status.paymentErr,
          userStatus: userStatusMap[statusId],
        })
      );
    }, [
      userMode,
      actionCardStateMachine,
      infoCardStateMachine,
      status.paymentErr,
      statusId,
      setIsCardVisible,
    ]);

    const toLandingPage = () => {
      history.replace('/landing-page');
    };

    const handleCampaignOnClick = async () => {
      const url = new URL(campaign.url);
      let token = '';
      if (campaign.ssoType) {
        token = await getMissionSsoToken(campaign.ssoType).catch(() => '');
      }
      logPromotionEventEvent({
        origin: isGuest ? 'experience' : 'user',
        iconType: campaign.icon,
        url: url.origin,
      });
      window.liff.openWindow({
        url: `${campaign.url}${token}`,
        external: true,
      });
    };

    const handleGetCampaigns = async () => {
      const campaign = await getCampaigns();
      const firstCampaign = campaign?.[0];

      if (
        firstCampaign &&
        isAfter(new Date(), parseISO(firstCampaign.useBeginAt)) &&
        isBefore(new Date(), parseISO(firstCampaign.useEndAt)) &&
        firstCampaign.url?.indexOf('https') === 0 // hide deep links and unsafe links
      ) {
        setCampaign(firstCampaign);
      }
    };

    useEffect(() => {
      handleGetCampaigns();
    }, []);

    return (
      <Fragment>
        {!isGuest && (
          <Header user={user} buttonStyle={headerSpring} visibleDialogBox={visibleDialogBox} />
        )}
        {campaign && (
          <EventIcon src={campaign.icon} onClick={handleCampaignOnClick} style={eventSpring} />
        )}
        <PaddingWrapper padding={[10, 20]}>
          <Wrapper>
            {barReserveTransition.map(
              ({ item, key, props }) => item && <BarReserve key={key} style={props} />
            )}
            {alertMessage && (
              <AlertContainer ref={ref => handleSetOffsetHeight('alert', ref?.offsetHeight)}>
                <Alert {...alertMessage.data} />
              </AlertContainer>
            )}
            <BottomGroup style={topInfoGroupSpring}>
              <div
                ref={ref => handleSetOffsetHeight('topInfoGroupContainer', ref?.offsetHeight)}
                style={{ pointerEvents: 'auto' }}
              >
                {infoCardStateMachine.getCardComponent(
                  {
                    userMode: userMode,
                    paymentErr: status.paymentErr,
                    userStatus: userStatusMap[statusId],
                  },
                  { ratio: [0.88, 0.88] }
                )}
              </div>
            </BottomGroup>
            <BottomGroup style={topControlGroupSpring}>
              <TopButtonGroup
                ref={ref => handleSetOffsetHeight('topControlGroupTop', ref?.offsetHeight)}
                style={{ visibility: isInfoCardVisible ? 'hidden' : 'visible' }}
              >
                <ButtonIcon
                  type="float"
                  shape="circle"
                  color="plan"
                  IconComponent={showPolygon ? parkingLot : parkingLotGrey}
                  onClick={togglePolygon}
                />
                {isGuest && (
                  <animated.div style={buttonSpring}>
                    <ButtonAction shape="capsule" type="float" onClick={toLandingPage}>
                      註冊 / 登入
                    </ButtonAction>
                  </animated.div>
                )}
                <ButtonIcon
                  type="float"
                  shape="circle"
                  color="plan"
                  IconComponent={locate}
                  onClick={toCurrentLocation}
                />
              </TopButtonGroup>
              {!isGuest && (
                <div
                  ref={ref => handleSetOffsetHeight('topControlGroupContainer', ref?.offsetHeight)}
                  style={{ pointerEvents: 'auto' }}
                >
                  {actionCardStateMachine.getCardComponent(
                    {
                      userMode: userMode,
                      paymentErr: status.paymentErr,
                      userStatus: userStatusMap[statusId],
                    },
                    { cardStatusMessage, updateAlertMessage }
                  )}
                </div>
              )}
            </BottomGroup>
          </Wrapper>
        </PaddingWrapper>
      </Fragment>
    );
  }
);

MainControlPanel.propTypes = {};

export default MainControlPanel;

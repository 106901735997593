import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IdCardPositiveImage from 'Images/id-card-positive.png';
import IdCardNegativeImage from 'Images/id-card-negative.png';
import LicensePositiveImage from 'Images/license-positive.png';
import LicenseNegativeImage from 'Images/license-negative.png';
import TakePhotoButton from 'Components/atoms/TakePhotoButton';

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
`;

const ImageMaps = {
  IdCardPositive: IdCardPositiveImage,
  IdCardNegative: IdCardNegativeImage,
  LicensePositive: LicensePositiveImage,
  LicenseNegative: LicenseNegativeImage,
};

const WordingMaps = {
  IdCardPositive: '點擊拍攝正面照',
  IdCardNegative: '點擊拍攝背面照',
  LicensePositive: '點擊拍攝正面照',
  LicenseNegative: '點擊拍攝背面照',
};

export const PureCardPhotoButton = props => {
  const { isCaptured, type, facingMode, onChange, display } = props;
  return (
    <TakePhotoButton
      color="plan"
      shape="round"
      width={['205px', '266.5px']}
      height={['131px', '170.5px']}
      isCaptured={isCaptured}
      facingMode={facingMode}
      unCapturedText={WordingMaps[type]}
      display={display}
      onChange={onChange}
    >
      <Image src={ImageMaps[type]} />
    </TakePhotoButton>
  );
};

PureCardPhotoButton.defaultProps = {
  isCaptured: false,
  facingMode: 'environment',
  display: 'inline-block',
};

PureCardPhotoButton.propTypes = {
  isCaptured: PropTypes.bool,
  type: PropTypes.oneOf(['IdCardPositive', 'IdCardNegative', 'LicensePositive', 'LicenseNegative'])
    .isRequired,
  facingMode: PropTypes.oneOf(['user', 'environment']),
  onChange: PropTypes.func,
  display: PropTypes.string,
};

export default React.memo(PureCardPhotoButton);

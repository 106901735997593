import React, { useCallback, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import schema from 'async-validator';
import { pick, omit } from '~/utils';

export const FormContext = React.createContext({
  errors: {},
});

const FormStyled = styled.form``;

export const PureForm = props => {
  const { children, labelPosition } = props;
  const formStore = useContext(FormContext);

  return (
    <FormContext.Provider value={{ ...formStore, labelPosition }}>
      <FormStyled>
        {React.Children.map(children, child => React.cloneElement(child, {}))}
      </FormStyled>
    </FormContext.Provider>
  );
};

PureForm.defaultProps = {};

PureForm.propTypes = {
  labelPosition: PropTypes.oneOf(['inline', 'top']),
};

const Form = React.memo(
  React.forwardRef((props, ref) => {
    return <PureForm {...props} />;
  })
);

const defaultFormState = {
  values: {},
  fields: {},
  rules: {},
  errors: {},
  toucheds: {},
  onTriggerRules: {},
};

const getInitFormState = (state = {}) => ({
  values: {},
  fields: state.fields || defaultFormState.fields,
  rules: state.rules || defaultFormState.rules,
  errors: {},
  toucheds: {},
  onTriggerRules: {},
});

/**
 * formReducer
 * @param {object} state
 * @param {object} state.values
 * @param {fields} state.fields
 * @param {object} state.rules
 * @param {object} state.errors
 * @param {object} state.toucheds
 * @param {object} action
 * @param {string} action.type
 * @param {any} action.payload
 */
const formReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'setRule': {
      const { id, rules } = payload;
      return {
        ...state,
        rules: {
          ...state.rules,
          [id]: rules,
        },
      };
    }
    case 'setOnTriggerRules': {
      const { id, rules } = payload;
      return {
        ...state,
        onTriggerRules: {
          ...state.onTriggerRules,
          [id]: rules,
        },
      };
    }
    case 'setField': {
      const { id, field } = payload;
      return {
        ...state,
        fields: { ...state.fields, [id]: field },
      };
    }
    case 'setFields': {
      const { fields } = payload;
      return {
        ...state,
        fields: { ...state.fields, ...fields },
      };
    }
    case 'setFieldsValue': {
      const { values } = payload;
      return {
        ...state,
        values: { ...state.values, ...values },
      };
    }
    case 'setFieldValue': {
      const { id, value } = payload;
      return {
        ...state,
        values: { ...state.values, [id]: value },
      };
    }
    case 'setErrors': {
      const { errors } = payload;
      return {
        ...state,
        errors: { ...state.errors, ...errors },
      };
    }
    case 'removeErrors': {
      const { fields = [] } = payload;
      return {
        ...state,
        errors: omit(fields, state.errors),
      };
    }
    case 'resetErrors': {
      return {
        ...state,
        errors: {},
      };
    }
    case 'setFieldTouched': {
      const { id, touched } = payload;
      return {
        ...state,
        toucheds: { ...state.toucheds, [id]: touched },
      };
    }
    case 'setFieldsTouched': {
      const { toucheds } = payload;
      return {
        ...state,
        toucheds: { ...state.toucheds, ...toucheds },
      };
    }
    case 'resetFieldsTouched': {
      return {
        ...state,
        toucheds: {},
      };
    }

    default:
      throw new Error('Invalid action type!');
  }
};

Form.create = (defaultProps = {}) => Comp =>
  React.memo(props => {
    const { defaultFormState } = defaultProps;
    const [state, dispatch] = useReducer(formReducer, defaultFormState, getInitFormState);

    const validator = useCallback(
      async (rules = state.rules, values = state.values) => {
        try {
          const validator = new schema(rules);
          await validator.validate(values);
          if (Object.keys(values).length !== Object.keys(state.values).length)
            dispatch({ type: 'removeErrors', payload: { fields: Object.keys(values) } });
          else dispatch({ type: 'resetErrors' });
          return values;
        } catch (error) {
          const { fields } = error;
          dispatch({ type: 'setErrors', payload: { errors: fields } });
          throw error;
        }
      },
      [state.rules, state.values]
    );

    const handleOnTrigger = useCallback(
      (id, onTrigger) => event => {
        const rules = state.onTriggerRules[id];
        const value =
          (typeof event === 'object' && 'target' in event && 'value' in event.target
            ? event.target.value
            : event) || undefined;

        // Validation
        if (rules) {
          validator({ [id]: rules }, { [id]: value }).catch(() => {});
        }

        dispatch({ type: 'setFieldValue', payload: { id, value } });
        dispatch({ type: 'setFieldTouched', payload: { id, touched: true } });
        if (typeof onTrigger === 'function') {
          onTrigger(value);
        }
      },
      [state.onTriggerRules, validator]
    );

    const setFieldRules = useCallback(
      (id, rules) => {
        /**
         * Set with previous rules.
         * It need to return a new rules array
         */
        if (typeof rules === 'function') {
          rules = rules(state.rules);
        }

        if (Array.isArray(rules)) {
          dispatch({
            type: 'setRule',
            payload: { id, rules },
          });
          dispatch({
            type: 'setOnTriggerRules',
            payload: { id, rules: rules.filter(rule => rule.onTrigger !== false) },
          });
        }
      },
      [state.rules]
    );

    const getFieldDecorator = useCallback(
      (id, options = {}) => {
        const { rules, ...fieldOptions } = options;
        const { initialValue, trigger = 'onChange' } = fieldOptions;
        if (!state.fields[id]) {
          dispatch({
            type: 'setFieldValue',
            payload: { id, value: state.values[id] || initialValue },
          });
          dispatch({
            type: 'setField',
            payload: { id, field: fieldOptions },
          });
          setFieldRules(id, rules);
        }
        return reactNode => {
          const onTrigger = reactNode[trigger];
          return React.cloneElement(reactNode, {
            dataIndex: id,
            /**
             * 激！重要
             * ReactDOM <input>, <textarea> & <select> 必須為 controlled 組件
             * controlled 組件 value 不能等於 undefined | null
             * 如果 value 等於 undefined | null React 會判別為 uncontrolled
             * reference: [https://stackoverflow.com/a/37427596]
             */
            value: state.values[id] || '',
            [trigger]: handleOnTrigger(id, onTrigger),
          });
        };
      },
      [handleOnTrigger, state.fields, state.values, setFieldRules]
    );

    const getFieldsValue = useCallback(
      fieldIds => {
        fieldIds =
          Array.isArray(fieldIds) && fieldIds.length > 0 ? fieldIds : Object.keys(state.fields);
        return fieldIds.reduce((acc, fieldId) => {
          acc[fieldId] = state.values[fieldId];
          return acc;
        }, {});
      },
      [state.fields, state.values]
    );

    const getFieldValue = useCallback(
      fieldId => {
        return state.values[fieldId];
      },
      [state.values]
    );

    const resetFields = useCallback(
      fieldIds => {
        fieldIds =
          Array.isArray(fieldIds) && fieldIds.length > 0 ? fieldIds : Object.keys(state.fields);
        const values = fieldIds.reduce((acc, fieldId) => {
          const field = state.fields[fieldId];
          acc[fieldId] = field.initialValue;
          return acc;
        }, {});
        dispatch({ type: 'setFieldsValue', payload: { values } });
        dispatch({ type: 'resetErrors' });
        dispatch({ type: 'resetFieldsTouched' });
      },
      [state.fields]
    );

    const setFieldValue = useCallback((id, value) => {
      dispatch({ type: 'setFieldValue', payload: { id, value } });
    }, []);

    const setFieldsValue = useCallback(fieldsValue => {
      dispatch({
        type: 'setFieldsValue',
        payload: {
          values: Object.entries(fieldsValue).reduce((acc, [fieldId, value]) => {
            acc[fieldId] = value;
            return acc;
          }, {}),
        },
      });
    }, []);

    const validateFields = useCallback(
      async (fields = []) => {
        const rules = fields.length ? pick(fields, state.rules) : state.rules;
        const values = fields.length ? pick(fields, state.values) : state.values;
        return validator(rules, values);
      },
      [state.rules, state.values, validator]
    );

    const isFieldTouched = useCallback(
      fieldId => {
        return !!state.toucheds[fieldId];
      },
      [state]
    );

    const isFieldsTouched = useCallback(
      (fieldIds, condition = 'some') => {
        if (condition !== 'some' && condition !== 'every') {
          throw new Error('Condition must be `some` or `every`');
        }
        fieldIds =
          Array.isArray(fieldIds) && fieldIds.length > 0 ? fieldIds : Object.keys(state.fields);
        return fieldIds[condition](fieldId => isFieldTouched(fieldId));
      },
      [isFieldTouched, state.fields]
    );

    const getFieldError = useCallback(
      fieldId => {
        return state.errors[fieldId];
      },
      [state.errors]
    );

    const getFieldsError = useCallback(
      fieldIds => {
        fieldIds =
          Array.isArray(fieldIds) && fieldIds.length > 0 ? fieldIds : Object.keys(state.fields);
        return fieldIds.reduce((acc, fieldId) => {
          acc[fieldId] = state.errors[fieldId];
          return acc;
        }, {});
      },
      [state.errors, state.fields]
    );

    return (
      <FormContext.Provider value={{ errors: state.errors }}>
        <Comp
          {...props}
          form={{
            getFieldDecorator,
            getFieldsValue,
            getFieldValue,
            resetFields,
            setFieldValue,
            setFieldsValue,
            validateFields,
            isFieldTouched,
            isFieldsTouched,
            getFieldError,
            getFieldsError,
            setFieldRules,
          }}
        />
      </FormContext.Provider>
    );
  });

export default Form;

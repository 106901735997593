import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import ExpiredStorage from 'expired-storage';
import { useDebouncedCallback } from 'use-debounce';
import qs from 'qs';
import requestErrors from '~/configs/requestErrors';
import CardMap from 'Components/atoms/CardMap';
import Switch from 'Components/molecules/Switch';
import PopupLoading from 'Components/molecules/PopupLoading';
import useInterval from '~/hooks/useInterval';
import { useRenting } from '~/context';
import { rentStatus, scooterStatus } from '~/configs/';
import { parkingLotStatusMap, parkingLotStatusUiMap, parkingLotOpenTypes } from '~/configs';
import TabScooter from './TabScooter';
import TabParkingLot from './TabParkingLot';
import TabNoParkingLot from './TabNoParkingLot';
import PopupExceptionReturn from 'Components/molecules/PopupExceptionReturn';
import TabNoSpace from './TabNoSpace';
import { openParkingLotInGate, openParkingLotOutGate } from '~/services/api';
import { PageContext } from 'Components/pages/Main/pageContext';
import useLoading from '~/hooks/useLoading';
import { useErrorNotification } from '~/context/ErrorNotification';
import { sign, verify } from '~/utils/externalToken';
import wemoToken from '~/utils/wemoToken';
import SwipeUpPage from 'Components/atoms/SwipeUpPage';
import TakeReturnPhotoV2 from 'Components/pages/TakeReturnPhotoV2';

// import ChristmasLights from 'Components/atoms/ChristmasLights';

const SwitchStyled = styled(Switch)`
  margin: 0 auto 10px;
`;

const FlexWrapper = styled.div`
  ${space}
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;
const expiredStorage = new ExpiredStorage();

const checkIsParkingLotError = info => {
  const { isInParkingLot, isAroundParkingLot, inParkingBoxStatus, outParkingBoxStatus } = info;
  return (
    (isInParkingLot && inParkingBoxStatus === 'heartbeatTimeout') ||
    (isAroundParkingLot && outParkingBoxStatus === 'heartbeatTimeout')
  );
};

const popupConfig = {
  NO_SPACE: {
    popup: {
      title: '目前車位已滿',
      confirmButtonText: '確認',
      notes: '建議停放到附近的合法停車格\n或繼續等待停車場車位',
    },
    notifier: 'popup',
  },
  IN_PARKING_LOT_ERROR: {
    popup: {
      title: '停車場異常',
      confirmButtonText: '確認',
      type: 'warning',
      notes: '系統維護中，請聯絡現場人員協助出場',
    },
    notifier: 'popup',
  },
  OUT_PARKING_LOT_ERROR: {
    popup: {
      title: '停車場異常',
      confirmButtonText: '確認',
      type: 'warning',
      notes: '請直接索取停車場代幣進場\n並轉交給停車場管理人員',
    },
    notifier: 'popup',
  },
  [requestErrors.PARKING_LOT_IN_GATE_NON_OPENABLE]: {
    popup: {
      title: '停車場異常',
      confirmButtonText: '確認',
      type: 'warning',
      notes: '請直接索取停車場代幣進場\n並轉交給停車場管理人員',
    },
    notifier: 'popup',
  },
  [requestErrors.PARKING_LOT_OUT_GATE_NON_OPENABLE]: {
    popup: {
      title: '停車場異常',
      type: 'warning',
      confirmButtonText: '確認',
      notes: '系統維護中，請聯絡現場人員協助出場',
    },
    notifier: 'popup',
  },
  COMMON_ERROR: {
    popup: {
      title: '似乎發生錯誤',
      type: 'warning',
      confirmButtonText: '確認',
      notes: '伺服器發生錯誤\n請稍後再試',
    },
    notifier: 'popup',
  },
};

const OS = window.liff ? window.liff.getOS() : 'android';

const CardRide = ({ updateAlertMessage }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { openStoragePopup } = useContext(PageContext);
  const [parkingLotStatusUi, setParkingLotStatusUi] = useState(); //parkingLotError,noParkingLot,noSpace,normal
  const loadingUtil = useLoading();
  const popTurnOff = expiredStorage.getJson('popTurnOff') || {};
  const [swipeUpVisible, setSwipeUpVisible] = useState(false);
  const {
    scooter,
    renting,
    parkingLot,
    handleReturnScooter,
    handleStart,
    handleOpenTrunk,
    handleStop,
    loadingUtils,
    updateParkingGateStatus,
    updateRentingStatus,
    handleCheckExceptionReturnAvailable,
    resetExceptionReturn,
  } = useRenting();
  const urlQuery = useRef(qs.parse(window.location.search.slice(1)));
  const [isAvailableExceptionReturn, setIsAvailableExceptionReturn] = useState(false);
  const { registerErrorConfig, launchApiError, launchError } = useErrorNotification();
  const [isExceptionReturnVisible, setIsExceptionReturnVisible] = useState(false);
  useInterval(updateRentingStatus, renting.userMode === rentStatus.renting ? 60000 : null);
  const [shouldResetButton, setShouldResetButton] = useState(false);

  const onReturnClick = () => {
    popTurnOff['returnNote'] === true ? handleReturnScooter() : openStoragePopup('returnNote');
  };

  const handleOpenGate = async () => {
    if (parkingLot.openType !== parkingLotOpenTypes.parkingBox) return;

    const {
      isInParkingLot,
      isAroundParkingLot,
      inParkingBoxStatus,
      outParkingBoxStatus,
    } = parkingLot.info;

    try {
      setShouldResetButton(false);

      loadingUtil.start();

      if (isAroundParkingLot && inParkingBoxStatus === 'available') {
        const data = await openParkingLotInGate(parkingLot.id, scooter.scooterId);

        if (data.data === 'notOnLoop') {
          setShouldResetButton(true);
          updateAlertMessage('notOnLoop');
        }
      } else if (isInParkingLot && outParkingBoxStatus === 'available') {
        const data = await openParkingLotOutGate(parkingLot.id, scooter.scooterId);

        if (data.data === 'notOnLoop') {
          setShouldResetButton(true);
          updateAlertMessage('notOnLoop');
        }
      }

      loadingUtil.end();
    } catch (error) {
      console.log(error);
      loadingUtil.end('fail');
      updateParkingGateStatus();
      launchApiError(error, 'parkingLots', { isInParkingLot });
    }
  };

  const handleUpdateParkingGate = async () => {
    try {
      loadingUtil.start();
      await updateParkingGateStatus();
      loadingUtil.end();
    } catch (error) {
      loadingUtil.end('fail');
      launchApiError(error, 'parkingLots');
    }
  };

  const handleOnSwitch = async index => {
    if (index === currentTab) return;
    if (index === 1 && renting.location) {
      await handleUpdateParkingGate();
    }
    setCurrentTab(index);
  };

  const [handleTakePhotoOnClick] = useDebouncedCallback(
    () => {
      if (OS === 'ios') {
        setSwipeUpVisible(true);
        setIsAvailableExceptionReturn(false);
      } else {
        const token = sign();
        const { token: _, hasPhoto: __, ...otherQuery } = urlQuery.current;
        const { accessToken } = wemoToken.getToken();
        window.liff.openWindow({
          url: `${
            process.env.REACT_APP_VENUS_URL
          }/take-return-photo-v2?token=${token}&wemoToken=${accessToken}&redirectTo=/&${qs.stringify(
            {
              scooterId: renting.scooter.scooterId,
              rentId: renting.rentId,
              isExceptionReturn: true,
              ...otherQuery,
            }
          )}`,
          external: true,
        });
      }
    },
    300,
    { leading: true, trailing: false }
  );

  const init = useCallback(async () => {
    const { token, hasPhoto } = urlQuery.current;
    /**
     * 異常拍照跳回，幫還車
     */
    if (token && hasPhoto === 'true') {
      loadingUtils.start('exception');
      const payload = await verify(token);
      loadingUtils.end('exception');

      if (!payload) return;
      handleReturnScooter();
    }
  }, [handleReturnScooter]);

  const checkExceptionReturn = useRef();
  checkExceptionReturn.current = async () => {
    const result = await handleCheckExceptionReturnAvailable();
    setIsAvailableExceptionReturn(result);
    setIsExceptionReturnVisible(true);

    if (!result) {
      resetExceptionReturn();
    }
  };

  const onExceptionReturnPopupClose = () => {
    resetExceptionReturn();
    setIsExceptionReturnVisible(false);
  };

  const handleReturnPhotoOnClose = useCallback(() => {
    setSwipeUpVisible(false);
    setIsAvailableExceptionReturn(true);
  }, []);

  const handleReturnPhotoOnFinish = useCallback(() => {
    setSwipeUpVisible(false);
    window.history.replaceState(
      null,
      document.title,
      `${window.location.pathname}?${qs.stringify({
        ...urlQuery.current,
        isExceptionReturn: true,
      })}`
    );
    handleReturnScooter();
  }, []);

  useEffect(() => {
    if (renting.exceptionReturn.count === 3) {
      checkExceptionReturn.current();
    }
  }, [renting.exceptionReturn.count]);

  useEffect(() => {
    init();
    return () => {};
  }, []);

  useEffect(() => {
    const isInRideCard = currentTab === 0;
    let id;
    // TODO: 歸納以下判斷為變數或 function，增加可讀性
    if (isInRideCard && parkingLot.info.isInParkingLot && !parkingLotStatusUi) {
      if (parkingLot.state?.key === parkingLotStatusMap.closed) {
        setParkingLotStatusUi(parkingLotStatusUiMap.inParkingLotClosed);

        id = setTimeout(() => {
          setCurrentTab(1);
        }, 2000);
      } else if (
        parkingLot.state?.key === parkingLotStatusMap.errorBothDisabled ||
        checkIsParkingLotError(parkingLot.info)
      ) {
        setParkingLotStatusUi(parkingLotStatusUiMap.inParkingLotError);
        id = setTimeout(() => {
          setCurrentTab(1);
        }, 2000);
      }
    } else if (isInRideCard) {
      return;
    } else if (parkingLot.info.isAroundParkingLot || parkingLot.info.isInParkingLot) {
      if (parkingLot.state?.key === parkingLotStatusMap.closed && parkingLot.info.isInParkingLot) {
        setParkingLotStatusUi(parkingLotStatusUiMap.inParkingLotClosed);
      } else if (
        parkingLot.state?.key === parkingLotStatusMap.closed &&
        parkingLot.info.isAroundParkingLot
      ) {
        setParkingLotStatusUi(parkingLotStatusUiMap.outParkingLotClosed);
      } else if (
        parkingLot.info.isInParkingLot &&
        (parkingLot.state?.key === parkingLotStatusMap.errorBothDisabled ||
          checkIsParkingLotError(parkingLot.info))
      ) {
        setParkingLotStatusUi(parkingLotStatusUiMap.inParkingLotError);
        launchError({ type: 'IN_PARKING_LOT_ERROR' }, 'parkingLots');
      } else if (
        parkingLot.info.isAroundParkingLot &&
        (parkingLot.state?.key === parkingLotStatusMap.errorBothDisabled ||
          checkIsParkingLotError(parkingLot.info))
      ) {
        setParkingLotStatusUi(parkingLotStatusUiMap.outParkingLotError);
        launchError({ type: 'OUT_PARKING_LOT_ERROR' }, 'parkingLots');
      } else if (parkingLot.availableSpaces <= 0) {
        setParkingLotStatusUi(parkingLotStatusUiMap.noSpace);
        updateAlertMessage('noSpace');
        launchError({ type: 'NO_SPACE' }, 'parkingLots');
      } else {
        setParkingLotStatusUi(parkingLotStatusUiMap.normal);
      }
    } else {
      setParkingLotStatusUi(parkingLotStatusUiMap.noParkingLot);
      updateAlertMessage('noParkingLot');
    }

    return () => {
      clearTimeout(id);
    };
  }, [
    currentTab,
    launchError,
    parkingLot,
    parkingLot.availableSpaces,
    parkingLot.id,
    parkingLot.info,
    parkingLot.isAvailable,
    parkingLot.state,
    parkingLot.totalSpaces,
    updateAlertMessage,
  ]);

  useMemo(() => {
    registerErrorConfig('parkingLots', {
      ...popupConfig,
      [requestErrors.PARKING_LOT_OUT_OF_BUSINESS_HOUR]: ({ data }) => ({
        popup: {
          title: '休息中',
          confirmButtonText: '確認',
          onConfirmClick: handleUpdateParkingGate,
          notes: data.isInParkingLot
            ? '休息中\n請聯絡現場人員協助出場'
            : '休息中\n還車請先取代幣進場，並放置於杯架內',
        },
        notifier: 'popup',
      }),
    });
  }, []);

  return (
    <Fragment>
      <CardMap ratio={[0.72, 0.7, 0.62]}>
        {/* <ChristmasLights /> */}
        <FlexWrapper padding={['10px 12px', '20px 30px']}>
          <SwitchStyled defaultTab={currentTab} onSwitch={handleOnSwitch} currentTab={currentTab} />
          {currentTab === 0 ? (
            <TabScooter
              timerConfig={
                scooter.sinceTime
                  ? {
                      sinceTime: scooter.sinceTime,
                      startTimeMs: parseInt(scooter.time, 10) * 1000,
                    }
                  : null
              }
              onStopCancel={() => updateAlertMessage('stop')}
              scooterId={scooter.scooterId}
              batPercent={scooter.batPercent}
              availableDistance={scooter.availableDistance}
              isScooterStart={renting.scooterMode === scooterStatus.start}
              onStartClick={handleStart}
              onReturnClick={onReturnClick}
              onOpenTrunkClick={handleOpenTrunk}
              onStopClick={handleStop}
            />
          ) : (
            <Fragment>
              {parkingLotStatusUi === parkingLotStatusUiMap.noParkingLot ? (
                <TabNoParkingLot handleRefresh={handleUpdateParkingGate} />
              ) : parkingLotStatusUi === parkingLotStatusUiMap.noSpace ? (
                <TabNoSpace handleRefresh={handleUpdateParkingGate} />
              ) : (
                <TabParkingLot
                  {...parkingLot}
                  isLoading={loadingUtil.state.spinning}
                  shouldResetButton={shouldResetButton}
                  parkingLotStatusUi={parkingLotStatusUi}
                  handleOpenGate={handleOpenGate}
                  handleOpenGateAlert={() => updateAlertMessage('parkingGate')}
                />
              )}
            </Fragment>
          )}
        </FlexWrapper>
      </CardMap>
      {isAvailableExceptionReturn && renting.exceptionReturn.count >= 3 && (
        <PopupExceptionReturn
          status={renting.userMode}
          visible={isExceptionReturnVisible}
          maskClosable={true}
          onClose={onExceptionReturnPopupClose}
          onConfirmClick={handleTakePhotoOnClick}
        />
      )}
      <PopupLoading {...loadingUtils.state} />
      {OS === 'ios' && (
        <SwipeUpPage visible={swipeUpVisible}>
          <TakeReturnPhotoV2
            rentId={renting.rentId}
            onClose={handleReturnPhotoOnClose}
            onFinish={handleReturnPhotoOnFinish}
          />
        </SwipeUpPage>
      )}
    </Fragment>
  );
};

CardRide.defaultProps = {};

CardRide.propTypes = {};

CardRide.whyDidYouRender = {
  customName: 'CardRide',
};

export default React.memo(CardRide);

import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import css from '@styled-system/css';
import { useDebouncedCallback } from 'use-debounce';
import { format, parse } from 'date-fns';
import { parkingLotOpenTypes } from '~/configs';
import ParkingLotTutorialSmall from '~/images/parking-lot-tutorial-small.png';
import ParkingLotInfoSmall from '~/images/parking-lot-info-small.png';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import InfoParkingBox from 'Components/organisms/InfoParkingBox';
import InfoParkingAutomatic from 'Components/organisms/InfoParkingAutomatic';
import ParkingImages from '~/components/organisms/ParkingImages';

const Container = styled.div`
  background: ${themeGet('colors.typeSpaceGrey')};
  ${css({ p: ['10px', '20px'] })};
  height: 100%;
  overflow-y: scroll;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LinkPanel = styled.div`
  background-image: url(${props => props.img});
  width: 50%;
  height: 80px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;

  &:not(:first-child) {
    ${css({ ml: ['10px', '20px'] })};
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
  }
`;

const Link = styled.a`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.white')};
  width: 100%;
  height: 100%;
  padding: 5px;
  position: absolute;
  top: 0;
  left: 0;
`;

const DescriptionWrapper = styled.div`
  ${css({ mt: ['10px', '20px'] })};
  width: 100%;
`;

const Description = styled.p`
  ${themeGet('fonts.body2')};
  margin: 0;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
`;

const dayTextMap = {
  0: '星期日',
  1: '星期一',
  2: '星期二',
  3: '星期三',
  4: '星期四',
  5: '星期五',
  6: '星期六',
};

const PureParkingLotInfo = props => {
  const {
    totalSpaces,
    phone,
    address,
    onTutorialClick,
    openType,
    mapsUrl,
    regularBusinessHours,
    specialBusinessHours,
  } = props;
  const containerEl = useRef();
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isImagesVisible, setIsImagesVisible] = useState(false);

  /**
   * openType 調整：
      v9 前：只有 etag
      v9 後：有 etag 及 automatic ，UI 一樣，但後台不會再新增 etag 的類別了
   */
  const InfoPopup = useMemo(() => {
    if (openType === parkingLotOpenTypes.automatic || openType === parkingLotOpenTypes.etag)
      return InfoParkingAutomatic;
    if (openType === parkingLotOpenTypes.parkingBox) return InfoParkingBox;
    return null;
  }, [openType]);

  const [handleTutorialOnClick] = useDebouncedCallback(
    () => {
      onTutorialClick();
      InfoPopup !== null && setIsInfoVisible(true);
    },
    300,
    { leading: true, trailing: false }
  );

  const closeInfo = useCallback(() => {
    setIsInfoVisible(false);
  }, []);

  const [handleImagesOnClick] = useDebouncedCallback(
    () => {
      setIsImagesVisible(true);
    },
    300,
    { leading: true, trailing: false }
  );

  const closeImages = useCallback(() => {
    setIsImagesVisible(false);
  }, []);

  useEffect(() => {
    const targetEl = containerEl.current;
    disablePageScroll(targetEl);
    return () => {
      enablePageScroll(targetEl);
    };
  }, []);

  return (
    <Container ref={containerEl}>
      <LinkWrapper>
        {InfoPopup && (
          <LinkPanel img={ParkingLotTutorialSmall}>
            <Link onClick={handleTutorialOnClick}>停車進出教學</Link>
          </LinkPanel>
        )}
        <LinkPanel img={ParkingLotInfoSmall}>
          {/* TODO: 這裡要在討論看要怎麼處理 */}
          <Link onClick={handleImagesOnClick}>停車場資訊</Link>
        </LinkPanel>
      </LinkWrapper>
      <DescriptionWrapper>
        <Description>總機車位：{totalSpaces}</Description>
        <Description>電話：{phone}</Description>
        <Description>地址：{address}</Description>
        <Description as="div">
          營業時間：
          {specialBusinessHours?.map(({ specialHoursName, beginAt, endAt }) => (
            <div key={beginAt}>
              {specialHoursName} {format(new Date(beginAt), 'MM/dd HH:mm')}~
              {format(new Date(endAt), 'MM/dd HH:mm')}
            </div>
          ))}
          {regularBusinessHours?.map(({ beginAt, endAt }, index) => (
            <div key={`${index}${beginAt}`}>
              {dayTextMap[index]}
              {!beginAt && !endAt
                ? ' 休息'
                : ` ${format(parse(beginAt, 'HH:mmxx', new Date()), 'HH:mm')}~${format(
                    parse(endAt, 'HH:mmxx', new Date()),
                    'HH:mm'
                  )}`}
            </div>
          ))}
        </Description>
      </DescriptionWrapper>
      {InfoPopup && (
        <InfoPopup visible={isInfoVisible} onClose={closeInfo} onConfirmClick={closeInfo} />
      )}
      <ParkingImages
        urls={mapsUrl}
        visible={isImagesVisible}
        onClose={closeImages}
        onConfirmClick={closeImages}
      />
    </Container>
  );
};

PureParkingLotInfo.defaultProps = {
  onTutorialClick: () => {},
  mapsUrl: [],
};

PureParkingLotInfo.propTypes = {
  businessHours: PropTypes.string,
  regularBusinessHours: PropTypes.array,
  specialBusinessHours: PropTypes.array,
  totalSpaces: PropTypes.number,
  phone: PropTypes.string,
  address: PropTypes.string,
  onTutorialClick: PropTypes.func,
  openType: PropTypes.string,
  mapsUrl: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(PureParkingLotInfo);

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ItemCard from '~/components/molecules/ItemCard';
import Link, { PureLink } from '~/components/atoms/Link';

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`;

const Content = styled.div`
  position: relative;
  color: inherit;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const PureActionItemCard = props => {
  const { children, to, replace, actionText, onActionClick, ...otherProps } = props;

  const handleActionOnClick = useCallback(
    event => {
      event.stopPropagation();
      onActionClick(event);
    },
    [onActionClick]
  );

  return (
    <ItemCard {...otherProps}>
      <Container>
        <Content>{children}</Content>
        <Link to={to} replace={replace} onClick={handleActionOnClick}>
          {actionText}
        </Link>
      </Container>
    </ItemCard>
  );
};

PureActionItemCard.defaultProps = {
  onActionClick: () => {},
  to: PureLink.defaultProps.to,
  replace: PureLink.defaultProps.replace,
};

PureActionItemCard.propTypes = {
  actionText: PropTypes.string,
  onActionClick: PropTypes.func,
  /**
   * Refer Link.to
   */
  to: PureLink.propTypes.to,
  replace: PureLink.propTypes.replace,
};

export default React.memo(PureActionItemCard);

import React from 'react';
import styled from 'styled-components';
import { InfoWindow } from '@react-google-maps/api';
import { ReactComponent as Battery } from 'Images/icons/battery.svg';

const Title = styled.h3`
  text-align: center;
  margin: 10px 0 4px 0;
  ${props => props.theme.fonts.caption}
`;

const Container = styled.div``;
const InfoContainer = styled.div`
  padding: 4px;
  text-align: center;
  background-color: ${props => props.theme.colors.typeSpaceGrey};
  border-radius: 6px;
`;

const Info = styled.div`
  margin: auto;
  margin-right: 0;
  display: flex;
  align-items: center;
`;
const ID = styled.div`
  ${props => props.theme.fonts.body2}
  color:  ${props => props.theme.colors.mainDark};
`;

const Text = styled.div`
  ${props => props.theme.fonts.note}
  margin: 0 4px;
  color: ${props => props.theme.colors.mainDark};
`;

const Icon = styled.img`
  vertical-align: middle;
  width: 22px;
`;

const ScooterInfo = ({ scooter }) => {
  return scooter ? (
    <InfoWindow
      position={{ lng: scooter.lng, lat: scooter.lat }}
      //  zIndex={zIndex}
    >
      <Container>
        <Title>機車資訊</Title>
        <InfoContainer>
          <ID>{scooter.id}</ID>
          <Info>
            <Icon as={Battery} />
            <Text>{scooter.batPercent}% </Text>|<Text>{scooter.availableDistance}km</Text>
          </Info>
        </InfoContainer>
      </Container>
    </InfoWindow>
  ) : null;
};

export default ScooterInfo;

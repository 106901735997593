import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';

let mock;

// TODO:  should place in some intial function, but it will not mock data once I do that.
mock = new MockAdapter(axios, { delayResponse: 800 });

const getMockAdapter = () => {
  return mock;
};

export { getMockAdapter };

import React, { Fragment } from 'react';
import styled from 'styled-components';
import Button from 'Components/atoms/Button';
import { ReactComponent as Refresh } from 'Images/icons/refresh.svg';

const Title = styled.div`
  ${props => props.theme.fonts.h2}
  color: ${props => props.theme.colors.mainBlue};
  width:100%;
  text-align: center;
`;

const Content = styled.div`
  ${props => props.theme.fonts.body2};
  text-align: center;
  width:100%;
`;

const TabNoSpace = ({
  name,
  isLoading,
  totalSpaces,
  handleRefresh,
}) => {
  return (
    !isLoading && (
      <Fragment>
        <Title>目前車位已滿</Title>
        <Content>
          如有車位請重新整理或索取代幣
          <br />
          進場轉交給管理人員
        </Content>
        <Button
          shape="capsule"
          width={0.4}
          boxShadow="light"
          type="float"
          color="primary"
          IconComponent={Refresh}
          onClick={handleRefresh}
        />
      </Fragment>
    )
  );
};

export default React.memo(TabNoSpace);

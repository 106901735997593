import delay from '~/utils/delay';

const resolutionConstraints = {
  vga: { width: { ideal: 640 }, height: { ideal: 480 } },
  hd: { width: { ideal: 1280 }, height: { ideal: 720 } },
  fhd: { width: { ideal: 1920 }, height: { ideal: 1080 } },
  // twok: { width: { ideal: 2048 }, height: { ideal: 1152 } },
  // qhd: { width: { ideal: 2560 }, height: { ideal: 1440 } },
};

const getBestResolution = async track => {
  return [1920, 1080];
  // let currentConstraints;
  // for (const key in resolutionConstraints) {
  //   const constraint = resolutionConstraints[key];
  //   try {
  //     await track.applyConstraints({
  //       ...constraint,
  //     });
  //     currentConstraints = constraint;
  //   } catch (error) {
  //     if (error.name == 'OverconstrainedError' || error.name == 'ConstraintNotSatisfiedError') {
  //       break;
  //     }
  //     console.log(error.name);
  //     throw error;
  //   }
  // }

  // if (currentConstraints) {
  //   console.log(currentConstraints);
  //   await track.applyConstraints({
  //     ...currentConstraints,
  //   });
  //   return [currentConstraints.width.ideal, currentConstraints.height.ideal];
  // } else {
  //   throw Error("can't get best resolution");
  // }
};

export const initCamera = async (viewport, camera, isExact = true) => {
  const track = camera.stream.getVideoTracks()[0];
  const capabilities = track.getCapabilities();
  let {
    width: { max: width },
    height: { max: height },
  } = capabilities;
  if (isExact) {
    const resolution = await getBestResolution(track);
    width = resolution[0];
    height = resolution[1];
  } else await track.applyConstraints();
  let stretch = 'horizontal';
  // const aspectRatio = width / height;
  // const actualHeight = Math.floor(viewport.offsetWidth * aspectRatio);
  // if (viewport.offsetHeight > actualHeight) {
  //   stretch = 'vertical';
  // }

  /**
   * ! 這邊可能會造成開發時，在電腦上做 scan barcode 失敗
   */
  return stretch;
};

export const devicesTutorials = new Proxy(
  {
    iOS: [],
    'Android OS': [
      '輕觸網址列右邊的「更多」> 接下來 [設定]',
      '輕觸 [網站設定]。',
      `輕觸 [所有網站] > ${window.location.hostname}`,
      '輕觸 [攝影機]。',
      '輕觸 [允許] 開啟攝影機功能。',
      '輕觸下方 [確認] 進行拍照。',
    ],
  },
  {
    get(target, prop) {
      return prop in target ? target[prop] : [];
    },
  }
);

export const releaseCanvas = canvas => {
  canvas.height = 0;
  canvas.width = 0;
  canvas.remove();
};

export const getCanvas = async (camera, viewport, stretch, facingMode) => {
  if (!(camera && camera.video && camera.stream) || !viewport) return false;
  let canvas = null;
  const { video, stream } = camera;
  if (video && stream) {
    canvas = document.createElement('canvas');
    const track = stream.getVideoTracks()[0];
    if (!track) return canvas;
    const { videoWidth, videoHeight } = video;
    let imageSrc = video;

    // 有 ImageCapture 就用到滿
    if (window.ImageCapture) {
      const imageCapture = new ImageCapture(track);
      imageSrc = await imageCapture.grabFrame();
    }

    // 裁剪計算
    const { offsetWidth: panelWidth, offsetHeight: panelHeight } = viewport;
    const scalingRatio =
      stretch === 'horizontal' ? videoWidth / panelWidth : videoHeight / panelHeight;
    const cropWidth = stretch === 'horizontal' ? videoWidth : Math.floor(panelWidth * scalingRatio);
    const cropHeight =
      stretch === 'vertical' ? videoHeight : Math.floor(panelHeight * scalingRatio);
    canvas.width = cropWidth;
    canvas.height = cropHeight;

    // 成像
    const context = canvas.getContext('2d');
    if (facingMode === 'user') context.setTransform(-1, 0, 0, 1, cropWidth, 0);
    context.drawImage(
      imageSrc,
      Math.floor(videoWidth / 2 - cropWidth / 2),
      Math.floor(videoHeight / 2 - cropHeight / 2),
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );
  }
  return canvas;
};

export const checkCameraCanShot = async (camera, canvas) => {
  if (!(camera && camera.video) || !canvas) return false;
  let conuter = 1;
  let isReady = false;

  do {
    const { video } = camera;
    const { videoWidth, videoHeight } = video;
    const context = canvas.getContext('2d');
    const imageData = context.getImageData(
      0,
      0,
      canvas.width || videoWidth,
      canvas.height || videoHeight
    );
    let gray, grayValues, index, size, sum, sumOfSquareDistances;
    size = imageData.width * imageData.height;
    sum = 0.0;
    grayValues = new Array(size);
    for (let i = 0; i < size; i++) {
      index = i * 4;
      gray =
        0.2126 * imageData.data[index + 0] +
        0.7152 * imageData.data[index + 1] +
        0.0722 * imageData.data[index + 2];
      gray = Math.round(gray);
      sum += gray;
      grayValues[i] = gray;
    }
    const mean = Math.round(sum / size);
    sumOfSquareDistances = 0;
    for (let j = 0, len = grayValues.length; j < len; j++) {
      gray = grayValues[j];
      sumOfSquareDistances += Math.pow(gray - mean, 2);
    }
    const std = Math.round(Math.sqrt(sumOfSquareDistances / size));
    isReady = std > 2;
    conuter++;
    await delay(300);
  } while (!isReady && conuter < 5);
  return isReady;
};

export const getFacingModeConstraint = async facingMode => {
  let devices = await navigator.mediaDevices.enumerateDevices();
  devices = devices.filter(function(devices) {
    return devices.kind === 'videoinput';
  });
  var videoinput_id = '';
  devices.forEach(function(device) {
    if (device.label.toLowerCase().search(facingMode === 'environment' ? 'back' : 'front') > -1) {
      videoinput_id = device.deviceId;
    }
  });
  if (videoinput_id) {
    return { deviceId: videoinput_id, facingMode };
  } else {
    return { facingMode };
  }
};

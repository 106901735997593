import React, { createContext, useCallback, useState, useRef, useMemo } from 'react';
import PopupNote from 'Components/molecules/PopupNote';
import { useSnackbar } from '~/context/Snackbars';
import requestErrors from '~/configs/requestErrors';
import { getApiErrorMessage } from '~/utils/getApiErrorMessage';

export const ErrorNotificationContext = createContext();

const notifierType = {
  popup: 'popup',
  snackbar: 'snackbar',
};

const commonErrors = {
  [requestErrors.INTERNAL_SERVER_ERROR]: {
    snackbar: {
      message: '系統發生異常\n請稍後再試',
    },
    popup: {
      title: '似乎發生錯誤',
      confirmButtonText: '確認',
      notes: '系統發生異常\n請稍後再試',
    },
    notifier: notifierType.snackbar,
  },
  [requestErrors.FORBIDDEN]: {
    snackbar: {
      message: '此帳號無法租借，請絡客服人員',
      notifier: notifierType.snackbar,
    },
  },
  COMMON_ERROR: {
    snackbar: {
      message: '系統發生異常，請稍後再試',
    },
    popup: {
      title: '似乎發生錯誤',
      confirmButtonText: '確認',
      notes: '系統發生異常\n請稍後再試',
    },
    notifier: notifierType.popup,
  },
};

// TODO: 複寫或指定 Notifier, 目前只能在初始化時指定
// TODO: 處理多個 popup error 的問題
export const ErrorNotificationProvider = React.memo(({ children, duration = 2000 }) => {
  const { enqueueSnackbar } = useSnackbar();
  const customErrorConfig = useRef({});
  const [popupSetting, setPopupSetting] = useState({ type: null, visible: false });

  const closePopup = useCallback(() => {
    setPopupSetting(props => ({ ...props, visible: false }));
  }, []);

  const popupAction = useCallback(
    (action, closable = true) => {
      typeof action === 'function' && action();
      closable && closePopup();
    },
    [closePopup]
  );

  const registerErrorConfig = useCallback((key, config) => {
    if (!key) {
      console.error('please register error config with key');
      return;
    }
    customErrorConfig.current[key] = config;
  }, []);

  const revokeErrorConfig = useCallback(key => {
    if (customErrorConfig.current[key]) {
      delete customErrorConfig.current[key];
    }
  }, []);

  const launchError = useCallback(
    (error, key) => {
      let errorConfig =
        (key ? customErrorConfig.current[key]?.[error?.type] : commonErrors?.[error?.type]) ||
        commonErrors.COMMON_ERROR;

      if (typeof errorConfig === 'function') {
        errorConfig = errorConfig(error);
      }

      if (!errorConfig) return;

      if (errorConfig.notifier === notifierType.snackbar)
        enqueueSnackbar(errorConfig[notifierType.snackbar]);
      else if (errorConfig.notifier === notifierType.popup) {
        setPopupSetting({
          ...errorConfig[notifierType.popup],
          visible: true,
          onConfirmClick: () =>
            popupAction(
              errorConfig[notifierType.popup].onConfirmClick,
              errorConfig[notifierType.popup].closable
            ),
          onCancelClick:
            errorConfig[notifierType.popup].hasCancelButton &&
            (() => popupAction(errorConfig[notifierType.popup].onCancelClick)),
        });
      }
    },
    [enqueueSnackbar, popupAction]
  );

  const launchApiError = useCallback(
    (rawError, key, options) => {
      const error = getApiErrorMessage(rawError);
      launchError({ ...error, data: { ...error.data, ...options } }, key);
    },
    [launchError]
  );

  const providerProps = useMemo(
    () => ({ registerErrorConfig, revokeErrorConfig, launchApiError, launchError }),
    [launchApiError, launchError, registerErrorConfig, revokeErrorConfig]
  );

  return (
    <ErrorNotificationContext.Provider value={providerProps}>
      {children}
      <PopupNote {...popupSetting} />
    </ErrorNotificationContext.Provider>
  );
});

import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useTransition } from 'react-spring';
import Loading from '~/components/atoms/Loading';
import useLeastLoading from '~/hooks/useLeastLoading';

const Container = styled.div``;

const LottiePanel = styled(animated.div)`
  height: 100%;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
`;

export const PureFullScreenLoading = props => {
  const { children, spinning, status, onFinish, onSuccess, onFailure } = props;
  const { loading, start, stop } = useLeastLoading(350);
  const spinningTransition = useTransition(loading, null, {
    from: { opacity: 1 },
    leave: { opacity: 0 },
    onDestroyed(isDestroyed) {
      if (isDestroyed) {
        if (status === 'success' && typeof onSuccess === 'function') onSuccess();
        if (status === 'fail' && typeof onFailure === 'function') onFailure();
        if (typeof onFinish === 'function') onFinish();
      }
    },
  });

  useEffect(() => {
    if (spinning) {
      start();
    } else {
      stop();
    }
  }, [spinning]);

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Container>
          {spinningTransition.map(
            ({ item, key, props }) =>
              item && (
                <LottiePanel key={key} style={props}>
                  <Loading />
                </LottiePanel>
              )
          )}
        </Container>,
        document.body
      )}
      {!spinning && children}
    </React.Fragment>
  );
};

PureFullScreenLoading.defaultProps = {
  spinning: true,
  status: 'success',
};

PureFullScreenLoading.propTypes = {
  spinning: PropTypes.bool,
  status: PropTypes.oneOf(['success', 'fail']),
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  onFinish: PropTypes.func,
};

export default React.memo(PureFullScreenLoading);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { border, color, space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as Line } from 'Images/icons/line.svg';

const PersonStyled = styled.div`
  display: block;
  display: flex;
  align-items: center;
  ${space}
`;

const CircleImg = styled.img`
  border-radius: 50%;
  width: 100%;
`;

const CircleText = styled.div`
  border-radius: 50%;
  width: 100%;
  background: linear-gradient(
    45deg,
    ${props => themeGet(`colors.mainGreen`, 'gray')}cc,
    ${props => themeGet(`colors.mainBlue`, 'gray')}cc
  );
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => themeGet(`fonts.h1`)};
  color: white;

  &::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%;
  }
  &::after {
    /* to clear float */
    content: '';
    display: table;
    clear: both;
  }
`;

const Text = styled.p`
  margin: 3px 0;
  ${props => (props.size === 'big' ? themeGet(`fonts.h3Button`) : themeGet(`fonts.h2Button`))};
`;

const Icon = styled.img`
  margin-right: 10px;
  vertical-align: middle;
`;

const CapsuleText = styled(Text)`
  border-radius: 50px;
  border: 1px solid;
  padding: 4px 8px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  width: auto;
  ${props => themeGet(`fonts.note`)};
  ${border}
  ${color}
`;

const ImageWrapper = styled.div`
  vertical-align: top;
  width: ${props => (props.size === 'big' ? '27%' : '30%')};
  max-width: 100px;
`;

const Email = styled.p`
  margin: 0;
  ${props => themeGet(`fonts.body2`)};
  color: ${props => themeGet(`colors.secondaryGrey`)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const InfoContainer = styled.div`
  word-break: break-all;
  white-space: pre-line;
  width: ${props => (props.size === 'big' ? '73%' : '70%')};
  ${space}
`;

const Person = ({ picture, name, email, type, userCategoryName, ...personProps }) => {
  const size = type === 'account' ? 'big' : 'small';
  return (
    <PersonStyled {...personProps}>
      <ImageWrapper size={size}>
        {picture ? <CircleImg src={picture} /> : <CircleText src={picture}>{name?.[0]}</CircleText>}
      </ImageWrapper>
      <InfoContainer size={size} paddingLeft={size === 'small' ? 10 : [10, 20]}>
        <Text size={size}>{name}</Text>
        {type === 'nav' ? (
          userCategoryName && (
            <CapsuleText borderColor="mainBlue" color="mainBlue">
              {userCategoryName}
            </CapsuleText>
          )
        ) : (
          <Email>
            <Icon as={Line} />
            {email}
          </Email>
        )}
      </InfoContainer>
    </PersonStyled>
  );
};

Person.propTypes = {
  picture: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userCategoryName: PropTypes.string,
  email: PropTypes.string,
  type: PropTypes.oneOf(['nav', 'account']),
};

export default Person;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import Card from 'Components/atoms/Card';
import { themeGet } from '@styled-system/theme-get';

const CardLicenseStyled = styled(Card)`
  text-align: center;
  position: relative;
  ${space}
  ${props => themeGet(`fonts.body`)};
`;

const CoutryLabel = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #555555;
  top: -30px;
  left: -10px;
  padding: 7px 10px;
  border-radius: 30px;
  ${props => themeGet(`fonts.h4`)};
`;

const Content = styled.div``;

const Hint = styled.div`
  color: #bcbcbc;
  margin-top: 6px;
`;

const Text = styled.p`
  margin: 0;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;

const CardLicense = ({ label, hint, licenses, className }) => {
  return (
    <CardLicenseStyled
      padding={[10, 20]}
      boxShadow="none"
      backgroundColor="typeSpaceGrey"
      className={className}
    >
      <CoutryLabel>{label}</CoutryLabel>
      <Content>
        {licenses.map(license => (
          <Text key={license}>- {license}</Text>
        ))}
      </Content>
      {hint && <Hint>{hint}</Hint>}
    </CardLicenseStyled>
  );
};

CardLicense.defaultProps = {};

CardLicense.propTypes = {
  label: PropTypes.string,
  licenses: PropTypes.array,
  hint: PropTypes.string,
};

export default CardLicense;

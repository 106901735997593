import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import CreditCard from './CreditCard';
import Text from './Text';
import NumberInput from './Number';

const InputComps = {
  text: Text,
  number: NumberInput,
  creditCard: CreditCard,
};

const InputWrapperStyled = styled.div`
  position: relative;
  background: ${themeGet('colors.typeSpaceGrey')};
  max-width: 100%;
  height: 100%;
  border: 1px solid ${themeGet('colors.typeSpaceGrey')};
  margin: 0;
  min-width: 0;
  display: flex;
  flex-wrap: nowrap;
  outline: none;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0 10px;
  transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-color: ${({ focus, feedback }) => {
    if (feedback === 'error') return themeGet('colors.wrongRed');
    if (focus) return themeGet('colors.mainBlue');
  }};
`;

const SuffixPanelStyled = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  border: 0;
  background: ${themeGet('colors.typeSpaceGrey')};
  margin: auto;

  position: absolute;
  right: 10px;
  margin-right: 0;
  padding-left: 10px;
`;

export const PureInput = props => {
  const { type } = props;
  const {
    labelPosition,
    isShrink,
    width,
    feedback,
    inputStyle,
    style,
    onFocus,
    onBlur,
    suffix,
    ...inputProps
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  const getInputPlaceHolder = useCallback(
    placeholder => {
      if (labelPosition === 'top' || labelPosition === undefined) return placeholder;
      if (isShrink) return placeholder;
      return undefined;
    },
    [labelPosition, isShrink]
  );

  const handleOnFocus = useCallback(
    event => {
      setIsFocus(true);
      onFocus(event);
    },
    [onFocus]
  );

  const handleOnBlur = useCallback(
    event => {
      setIsFocus(false);
      onBlur(event);
    },
    [onBlur]
  );

  const InputComp = useMemo(() => InputComps[type] || Text, [type]);

  return (
    <InputWrapperStyled style={{ width }} focus={isFocus} feedback={feedback}>
      <InputComp
        {...inputProps}
        getInputPlaceHolder={getInputPlaceHolder}
        type={type}
        style={{ ...inputStyle, ...style }}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      {suffix && <SuffixPanelStyled>{suffix}</SuffixPanelStyled>}
    </InputWrapperStyled>
  );
};

PureInput.defaultProps = {
  type: 'text',
  mask: false,
  size: 'medium',
  style: {},
  width: 300,
  align: 'left',
  inputStyle: {},
  onFocus: () => {},
  onBlur: () => {},
};

PureInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'large']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  feedback: PropTypes.oneOf(['error']),
  mask: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.func]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  // Mask Input Props
  guide: PropTypes.bool,
  placeholderChar: PropTypes.string,
  keepCharPositions: PropTypes.bool,
  showMask: PropTypes.bool,
  inputStyle: PropTypes.object,
  /**
   * @ignore
   */
  dataIndex: PropTypes.string,
  /**
   * @ignore
   */
  labelPosition: PropTypes.string,
  /**
   * @ignore
   */
  isShrink: PropTypes.bool,
};

export default React.memo(PureInput);

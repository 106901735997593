import React from 'react';
import styled from 'styled-components';

const Mask = styled.div`
  background-color: ${props => props.theme.colors.gpsBlueSup};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const Dot = styled.div`
  ${props => props.theme.boxShadows.light};
  background-color: ${props => props.theme.colors.gpsBlue};
  width: 15px;
  height: 15px;
  border: 2px solid white;
  border-radius: 50%;
`;

export const PureIndicatorMapDot = props => {
  return (
    <Mask>
      <Dot />
    </Mask>
  );
};

PureIndicatorMapDot.defaultProps = {};

PureIndicatorMapDot.propTypes = {};

export default React.memo(PureIndicatorMapDot);

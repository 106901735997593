import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Button from 'Components/atoms/Button';
import { ReactComponent as Cancel } from '~/images/icons/cancel.svg';
import { useDebouncedCallback } from 'use-debounce';
import ReactDOM from 'react-dom';

const Container = styled.div`
  height: ${props => (props.visible ? props.height : 0)};
  width: ${props => props.width};
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: ${themeGet('colors.white')};
  overflow: hidden;
  overflow-y: scroll;
  transition: 0.5s ease;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  height: 36px;
  width: 36px;
  padding: 0;
`;

const CloseIcon = styled.img.attrs({ as: Cancel })``;

export const PureSwipeUpPage = props => {
  const { children, visible, onClose, width, height } = props;

  const [handleCloseOnClick] = useDebouncedCallback(
    () => {
      if (typeof onClose === 'function') {
        onClose();
      }
    },
    300,
    { leading: true, trailing: false }
  );

  return (
    <Container visible={visible} width={width} height={height}>
      <Wrapper>
        {typeof onClose === 'function' && (
          <CloseButton shape="round" color="plan" size="small" onClick={handleCloseOnClick}>
            <CloseIcon />
          </CloseButton>
        )}
        {children}
      </Wrapper>
    </Container>
  );
};

PureSwipeUpPage.defaultProps = {
  width: '100%',
  height: '100%',
};

PureSwipeUpPage.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const PureSwipeUpPagePortal = props => {
  const { getContainer, ...otherProps } = props;
  return ReactDOM.createPortal(
    <PureSwipeUpPage {...otherProps} />,
    getContainer() || document.body
  );
};

PureSwipeUpPagePortal.defaultProps = {
  getContainer: () => document.body,
};

export default React.memo(PureSwipeUpPagePortal);

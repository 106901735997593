import React, { createContext, useReducer } from 'react';
import userCategoryReducer from '../reducers/userCategoryReducer.js';

const UserCategoryContext = createContext();

const UserCategoryProvider = ({ children }) => {
  const reducers = useReducer(userCategoryReducer, userCategoryReducer());

  return <UserCategoryContext.Provider value={reducers}>{children}</UserCategoryContext.Provider>;
};

export { UserCategoryProvider, UserCategoryContext };

import { useEffect, useState, useRef, useCallback } from 'react';

const useLeastLoading = (ms, defaultLoading = false) => {
  const [loading, setLoading] = useState(defaultLoading);
  const counter = useRef();
  const timer = useRef();
  const isMounted = useRef(false);

  const start = useCallback(() => {
    if (!isMounted.current) return;
    setLoading(true);
    counter.current = Date.now() + ms;
  }, [ms]);

  const stop = useCallback(async () => {
    if (!isMounted.current) return;
    if (!counter.current && !defaultLoading) return;
    const diff = counter.current - Date.now();
    if (diff <= 0) {
      setLoading(false);
    } else {
      await new Promise(resolve => {
        timer.current = setTimeout(() => {
          setLoading(false);
          resolve();
        }, diff);
      });
    }
  }, [defaultLoading]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      clearTimeout(timer.current);
      timer.current = undefined;
    };
  }, []);

  return {
    loading,
    start,
    stop,
  };
};

export default useLeastLoading;

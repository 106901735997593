import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { FormContext } from '~/components/atoms/Form';
import InlineFormItem from './InlineFormItem';
import TopFormItem from './TopFormItem';
import Suffix from './Suffix';

const FormItemWrapperStyled = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  min-width: 0;
  margin-bottom: 8px;
  vertical-align: top;
  width: ${props => (Number(props.width) ? `${props.width}px` : props.width)};
`;

const InputPrompt = styled.div`
  user-select: none;
  ${themeGet('fonts.note')};
  margin-top: 2px;
  text-align: right;
  height: 13px;
`;

const InputErrorMessage = styled(InputPrompt)`
  color: ${themeGet('colors.wrongRed')};
`;

export const PureFromItem = props => {
  const { children, label, prompt, size, width, validateStatus, help, hasFeedback } = props;
  const {
    dataIndex,
    value,
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
  } = children.props;
  const formStore = useContext(FormContext);
  const validErrors = useMemo(() => (dataIndex ? formStore.errors[dataIndex] || [] : []), [
    dataIndex,
    formStore.errors,
  ]);
  const [isFocus, setIsFocus] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const labelPosition = useMemo(() => props.labelPosition || formStore.labelPosition || 'inline', [
    formStore.labelPosition,
    props.labelPosition,
  ]);
  const isShrink = useMemo(() => isFocus || isEmpty, [isEmpty, isFocus]);
  const focusColor = useMemo(
    () => (validErrors.length || validateStatus === 'error' ? 'red' : isFocus ? 'blue' : 'grey'),
    [isFocus, validErrors.length, validateStatus]
  );
  const feedback = useMemo(
    () => (validErrors.length ? 'error' : hasFeedback ? validateStatus : undefined),
    [validErrors.length, validateStatus, hasFeedback]
  );

  const handleOnChange = useCallback(
    event => {
      const value = event.target.value;
      setIsEmpty(!!value);
      onChange(value);
    },
    [onChange]
  );

  const handleOnFocus = useCallback(
    event => {
      setIsFocus(true);
      onFocus(event);
    },
    [onFocus]
  );

  const handleOnBlur = useCallback(
    event => {
      setIsFocus(false);
      onBlur(event);
    },
    [onBlur]
  );

  useEffect(() => {
    setIsEmpty(!!value);
  }, [value]);

  const interceptorChildren = React.cloneElement(children || <div />, {
    value,
    width,
    labelPosition,
    isShrink,
    feedback,
    suffix: <Suffix feedback={feedback} />,
    onChange: handleOnChange,
    onFocus: handleOnFocus,
    onBlur: handleOnBlur,
  });

  return (
    <FormItemWrapperStyled width={width}>
      {labelPosition === 'top' && (
        <TopFormItem label={label} focusColor={focusColor} size={size}>
          {interceptorChildren}
        </TopFormItem>
      )}
      {labelPosition === 'inline' && (
        <InlineFormItem label={label} focusColor={focusColor} isShrink={isShrink} size={size}>
          {interceptorChildren}
        </InlineFormItem>
      )}
      {validErrors.length ? (
        <InputErrorMessage>{validErrors[0].message}</InputErrorMessage>
      ) : help ? (
        <InputErrorMessage>{help}</InputErrorMessage>
      ) : (
        <InputPrompt>{prompt}</InputPrompt>
      )}
    </FormItemWrapperStyled>
  );
};

PureFromItem.defaultProps = {
  size: 'medium',
  width: '100%',
  hasFeedback: false,
  children: <React.Fragment />,
};

PureFromItem.propTypes = {
  label: PropTypes.string,
  prompt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['medium', 'large']),
  labelPosition: PropTypes.oneOf(['inline', 'top']),
  validateStatus: PropTypes.oneOf(['success', 'warning', 'error', 'validating']),
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hasFeedback: PropTypes.bool,
};

export default React.memo(PureFromItem);

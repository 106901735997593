import React from 'react';

export const steps = [
  'TakePositiveIdentity',
  'PreviwPositiveIdentity',
  'TakeNegativeIdentity',
  'PreviwNegativeIdentity',
  'TakePositiveLicense',
  'PreviwPositiveLicense',
  'TakeNegativeLicense',
  'PreviwNegativeLicense',
  'TakeFace',
  'PreviwFace',
];

export const holes = new Proxy(
  {},
  {
    get(target, prop) {
      const certificateProps = [
        'TakePositiveIdentity',
        'PreviwPositiveIdentity',
        'TakeNegativeIdentity',
        'PreviwNegativeIdentity',
        'TakePositiveLicense',
        'PreviwPositiveLicense',
        'TakeNegativeLicense',
        'PreviwNegativeLicense',
      ];
      if (certificateProps.includes(prop)) {
        return 'certificate';
      } else {
        return 'face';
      }
    },
  }
);

export const cameras = new Proxy(
  {},
  {
    get(target, prop) {
      const environmentProps = [
        'TakePositiveIdentity',
        'PreviwPositiveIdentity',
        'TakeNegativeIdentity',
        'PreviwNegativeIdentity',
        'TakePositiveLicense',
        'PreviwPositiveLicense',
        'TakeNegativeLicense',
        'PreviwNegativeLicense',
      ];
      if (environmentProps.includes(prop)) {
        return 'environment';
      } else {
        return 'user';
      }
    },
  }
);

export const components = new Proxy(
  {},
  {
    get(target, prop) {
      const cameraProps = [
        'TakePositiveIdentity',
        'TakeNegativeIdentity',
        'TakePositiveLicense',
        'TakeNegativeLicense',
        'TakeFace',
      ];
      if (cameraProps.includes(prop)) {
        return 'camera';
      } else {
        return 'preview';
      }
    },
  }
);

const Info = React.memo(({ contents = [] }) => (
  <React.Fragment>
    {contents.map((content, key) => (
      <div key={key}>{content}</div>
    ))}
  </React.Fragment>
));

export const takePhotoInfos = new Proxy(
  {
    TakePositiveIdentity: {
      header: <Info contents={['拍攝正面', '身分證（護照）正本']} />,
      body: (
        <Info
          contents={[
            '請將身分證(護照)置入方框內拍攝',
            '並避免照片過於模糊、反光、確認照片清晰可辨',
          ]}
        />
      ),
    },
    TakeNegativeIdentity: {
      header: <Info contents={['拍攝背面', '身分證（護照）正本']} />,
      body: (
        <Info
          contents={[
            '請將身分證(護照)置入方框內拍攝',
            '並避免照片過於模糊、反光、確認照片清晰可辨',
          ]}
        />
      ),
    },
    TakePositiveLicense: {
      header: <Info contents={['拍攝正面', '駕照正本']} />,
      body: (
        <Info contents={['請將駕照置入方框內拍攝', '並避免照片過於模糊、反光、確認照片清晰可辨']} />
      ),
    },
    TakeNegativeLicense: {
      header: <Info contents={['拍攝背面', '駕照正本']} />,
      body: (
        <Info contents={['請將駕照置入方框內拍攝', '並避免照片過於模糊、反光、確認照片清晰可辨']} />
      ),
    },
    TakeFace: {
      header: <Info contents={['拍攝自拍照']} />,
      body: <Info contents={['請將臉對準圓框拍照']} />,
    },
  },
  {
    get(target, prop) {
      const maps = {
        PreviwPositiveIdentity: 'TakePositiveIdentity',
        PreviwNegativeIdentity: 'TakeNegativeIdentity',
        PreviwPositiveLicense: 'TakePositiveLicense',
        PreviwNegativeLicense: 'TakeNegativeLicense',
        PreviwFace: 'TakeFace',
      };
      if (prop in target) {
        return target[prop];
      } else {
        return target[maps[prop]];
      }
    },
  }
);

export const previewInfos = new Proxy(
  {
    PreviwPositiveIdentity: (
      <Info
        contents={[
          (() => <span>確認照片是否過於模糊、反光、確認照片清晰可辨</span>)(),
          (() => (
            <span>
              如沒有問題請按 <strong>確認</strong> 繼續拍攝 <strong>反面</strong> 照片
            </span>
          ))(),
        ]}
      />
    ),
    PreviwNegativeIdentity: (
      <Info
        contents={[
          (() => <span>確認照片是否過於模糊、反光、確認照片清晰可辨</span>)(),
          (() => (
            <span>
              如沒有問題請按 <strong>確認</strong> 繼續拍攝 <strong>正面駕照</strong>
            </span>
          ))(),
        ]}
      />
    ),
    PreviwPositiveLicense: (
      <Info
        contents={[
          (() => <span>確認照片是否過於模糊、反光、確認照片清晰可辨</span>)(),
          (() => (
            <span>
              如沒有問題請按 <strong>確認</strong> 繼續拍攝 <strong>反面</strong> 照片
            </span>
          ))(),
        ]}
      />
    ),
    PreviwNegativeLicense: (
      <Info
        contents={[
          (() => <span>確認照片是否過於模糊、反光、確認照片清晰可辨</span>)(),
          (() => (
            <span>
              如沒有問題請按 <strong>確認</strong> 繼續拍攝 <strong>自拍照</strong>
            </span>
          ))(),
        ]}
      />
    ),
    PreviwFace: (
      <Info
        contents={[
          (() => <span>確認自拍照片是否清晰可辨</span>)(),
          (() => (
            <span>
              如沒有問題請按 <strong>完成</strong>
            </span>
          ))(),
        ]}
      />
    ),
  },
  {
    get(target, prop) {
      const maps = {
        TakePositiveIdentity: 'PreviwPositiveIdentity',
        TakeNegativeIdentity: 'PreviwNegativeIdentity',
        TakePositiveLicense: 'PreviwPositiveLicense',
        TakeNegativeLicense: 'PreviwNegativeLicense',
        TakeFace: 'PreviwFace',
      };
      if (prop in target) {
        return target[prop];
      } else {
        return target[maps[prop]];
      }
    },
  }
);

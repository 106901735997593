import { useContext, useCallback } from 'react';
import { PageInfoContext } from '../providers/PageInfoProvider';

const usePageInfo = () => {
  const [pageInfo, dispatch] = useContext(PageInfoContext);
  // console.log('user', user);

  const setPageInfo = useCallback(
    payload => {
      dispatch({ type: 'setPageInfo', payload });
    },
    [dispatch]
  );

  const resetPageInfo = useCallback(() => {
    dispatch({ type: 'resetPageInfo' });
  }, [dispatch]);

  const setPageInfoPrevPage = useCallback(
    payload => {
      dispatch({ type: 'setPageInfoPrevPage', payload });
    },
    [dispatch]
  );

  // dev login (external browser line login)
  return {
    pageInfo,
    setPageInfo,
    resetPageInfo,
    setPageInfoPrevPage,
  };
};

export { usePageInfo };

import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { useDebouncedCallback } from 'use-debounce/lib';
import numeral from 'numeral';
import { themeGet } from '@styled-system/theme-get';
import copy from 'copy-to-clipboard';
import Button from '~/components/atoms/Button';
import wemoToken from '~/utils/wemoToken';
import useAsyncGet from '~/hooks/useAsyncGet';
import BasicContainer from 'Components/layouts/BasicContainer';
import BackToLine, { useBackToLine } from '~/components/molecules/BackToLine';
import FullScreenLoading from '~/components/molecules/FullScreenLoading';
import {
  postUserOrderDeal,
  getUserOrderById,
  getApplePayPrime,
  setupPaymentRequest,
} from '~/services/api';
import { useErrorNotification } from '~/context/ErrorNotification';
import { ReactComponent as ApplePayBorder } from '~/images/icons/apple-pay-border.svg';
import { ReactComponent as ApplePayWhite } from '~/images/icons/apple-pay-white.svg';
import { ReactComponent as LogoWaterMark } from 'Images/logo-water-mark.svg';
import { paymentTypes } from '~/configs';
import { useSnackbar } from '~/context/Snackbars';

const merchantId = process.env.REACT_APP_APPLE_PAY_MERCHANT_ID;
const countryCode = process.env.REACT_APP_APPLE_PAY_COUNTRY_CODE;
const defaultConfig = {
  supportedNetworks: ['MASTERCARD', 'VISA', 'AMEX'],
  supportedMethods: ['apple_pay'],
};

const ApplePayBorderIcon = styled.img.attrs({
  as: ApplePayBorder,
})`
  margin: 0 10px;
`;

const ApplePayWhiteIcon = styled.img.attrs({
  as: ApplePayWhite,
})`
  margin: 4px 10px 0;
`;

const LogoWaterMarkIcon = styled.img.attrs({
  as: LogoWaterMark,
})`
  margin: 4px 10px 0;
`;

const Container = styled(React.forwardRef((props, ref) => <BasicContainer ref={ref} {...props} />))`
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  text-align: center;
`;

const Title = styled.h2`
  ${themeGet('fonts.h2')}
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-bottom: 38px;
`;

const TitleMainDark = styled(Title)`
  color: ${themeGet('colors.mainDark')};
  margin-bottom: 60px;
`;

const UnpaidAmount = styled.h6`
  ${themeGet('fonts.h6')};
  color: #000;
  margin: 0;
  margin-bottom: 10px;
`;

const Description = styled.p`
  ${themeGet('fonts.body')};
  color: #000;
  margin: 0;
  margin-bottom: 60px;
`;

const LogoImagePanel = styled.div`
  margin-top: 40px;
`;

const PayButton = styled(Button)`
  width: 240px;
`;

export const PurePayByApplePay = () => {
  const history = useHistory();
  const urlQuery = useMemo(() => qs.parse(window.location.search.slice(1)), []);
  const [backToLineState, dispatchBackToLine] = useBackToLine({
    title: '付款完成',
    content: '回到 WeMo Scooter 繼續旅程',
  });
  const { registerErrorConfig, launchError } = useErrorNotification();
  const order = useAsyncGet(getUserOrderById, {
    loading: true,
    data: {
      status: 'noRequired',
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const [handleOnApplePayClick] = useDebouncedCallback(
    async () => {
      try {
        const prime = await getApplePayPrime();
        await postUserOrderDeal({
          orderId: urlQuery.orderId,
          amount: Number(urlQuery.unpaidAmount),
          paymentType: paymentTypes.applePay,
          prime,
        });
        dispatchBackToLine.switchVisible();
      } catch (error) {
        // ! No deal
        launchError(error, 'applePay');
      }
    },
    300,
    {
      leading: true,
      trailing: false,
    }
  );

  const [handleCopyOnClick] = useDebouncedCallback(
    () => {
      copy(window.location.href);
      enqueueSnackbar({ message: '複製成功', preventDuplicate: true });
    },
    300,
    { leading: true, trailing: false }
  );

  const handleBackToMini = useCallback(() => {
    const { redirectTo, wemoToken, ...otherQuery } = urlQuery;
    window.location.href = `${process.env.REACT_APP_LINE_MINI_URL}${redirectTo}?${qs.stringify(
      otherQuery
    )}`;
    setTimeout(() => {
      if (window.close) {
        window.close();
      }
    }, 500);
  }, [urlQuery]);

  const errorConfig = useMemo(
    () => ({
      GET_APPLE_PAY_PRIME_ERROR: {
        notifier: 'popup',
        popup: {
          title: 'Apple Pay 付款請求異常',
          notes: '無法取得支付授權，請確認 Apple Pay 信用卡設定',
          confirmButtonText: '確認',
        },
      },
      CAN_NOT_MAKE_PAYMENT_WITH_ACTIVE_CARD: {
        notifier: 'snackbar',
        snackbar: {
          message: '請確認 Apple Pay 是否有設定信用卡',
        },
      },
      BROWSER_NO_SUPPORT_PAYMENT_REQUEST: {
        notifier: 'popup',
        popup: {
          closable: false,
          title: '無法使用 Apple Pay',
          notes: '此瀏覽器無法使用 Apple Pay，請改用 iOS Safari 開啟',
          confirmButtonText: '複製連結',
          onConfirmClick: handleCopyOnClick,
        },
      },
      APPLE_PAYMENT_REQUEST_UNAVAILABLE: {
        notifier: 'popup',
        popup: {
          closable: false,
          title: '無法使用 Apple Pay',
          notes: '此瀏覽器無法使用 Apple Pay，請改用 iOS Safari 開啟',
          confirmButtonText: '複製連結',
          onConfirmClick: handleCopyOnClick,
        },
      },
    }),
    [handleCopyOnClick]
  );

  useEffect(() => {
    const init = async () => {
      const { accessToken } = wemoToken.getToken();
      if (!accessToken) {
        const { wemoToken: accessToken } = urlQuery;
        if (!accessToken) {
          history.replace('/errors/unauthorized');
          return;
        }
        wemoToken.syncLineAxiosToken(accessToken);
      }

      if (urlQuery.orderId && urlQuery.unpaidAmount) {
        const { status } = await order.getData(urlQuery.orderId);
        if (['unpaid', 'paying'].includes(status)) {
          if (window.TPDirect.paymentRequestApi.checkAvailability()) {
            window.TPDirect.paymentRequestApi.setupApplePay({
              merchantIdentifier: merchantId,
              countryCode,
            });

            try {
              await setupPaymentRequest({
                ...defaultConfig,
                total: {
                  label: '付給 WeMo Scooter',
                  amount: {
                    currency: 'TWD',
                    value: numeral(urlQuery.unpaidAmount).format('0.00'),
                  },
                },
              });
            } catch (error) {
              launchError(error, 'applePay');
            }
          } else {
            launchError({ type: 'APPLE_PAYMENT_REQUEST_UNAVAILABLE' }, 'applePay');
          }
        }
      } else {
        order.cancel();
      }
    };

    registerErrorConfig('applePay', errorConfig);
    init();
  }, []);

  return (
    <FullScreenLoading spinning={order.loading}>
      <Container navigationBar={false} autoHeight={false}>
        {['unpaid', 'paying'].includes(order.data.status) ? (
          <Wrapper>
            <Title>
              正在使用
              <ApplePayBorderIcon />
              付款
            </Title>
            <UnpaidAmount>${urlQuery.unpaidAmount || 0}</UnpaidAmount>
            <Description>請確認付款金額是否正確</Description>
            <PayButton
              color="black"
              boxShadow="light"
              shape="capsule"
              type="float"
              onClick={handleOnApplePayClick}
            >
              使用
              <ApplePayWhiteIcon />
              付款
            </PayButton>
            <LogoImagePanel>
              <LogoWaterMarkIcon />
            </LogoImagePanel>
          </Wrapper>
        ) : (
          <Wrapper>
            <TitleMainDark>無法進行支付</TitleMainDark>
            <PayButton boxShadow="light" shape="capsule" type="float" onClick={handleBackToMini}>
              回到 WeMo
            </PayButton>
            <LogoImagePanel>
              <LogoWaterMarkIcon />
            </LogoImagePanel>
          </Wrapper>
        )}
      </Container>
      <BackToLine {...backToLineState} onClick={handleBackToMini} />
    </FullScreenLoading>
  );
};

PurePayByApplePay.defaultProps = {};

PurePayByApplePay.propTypes = {};

export default React.memo(PurePayByApplePay);

import LineAxios from './LineAxios';
import { googleMapURLWemoQat } from '~/configs';
LineAxios.create('google', { host: 'https://www.googleapis.com' });
const axios = LineAxios.getInstance('google');

// TODO : 討論 $$ 
export const getGoogleMapLocation = geoLocation => {
  return axios
    .post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${googleMapURLWemoQat}`)
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
};

import React from 'react';

class ContentMountHandler extends React.Component {
  componentDidMount() {
    if (this.props.onLoad) {
      this.props.onLoad();
    }
  }

  render() {
    return this.props.children;
  }
}

export default ContentMountHandler;

import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { layout } from 'styled-system';
import Button from 'Components/atoms/Button';
import { ReactComponent as UnCapture } from 'Images/icons/camera.svg';
import { ReactComponent as Capture } from 'Images/icons/icon_photo_done.svg';
import { themeGet } from '@styled-system/theme-get';
import { useDebouncedCallback } from 'use-debounce';
import css from '@styled-system/css';

const CustomButton = props => {
  const { width, height, ..._props } = props;
  return <Button {..._props} />;
};

const Container = styled(CustomButton)`
  ${props =>
    css({
      width: props.width,
      height: props.height,
    })}
  padding: 0;
  border-radius: ${({ shape }) => (shape === 'circle' ? '50%' : '10px')};
  position: relative;
  line-height: 0;
  overflow: hidden;
  ${layout};
`;

const Cover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  border-radius: ${({ shape }) => (shape === 'circle' ? '50%' : '10px')};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Content = styled.div`
  ${themeGet('fonts.caption')};
  color: ${themeGet('colors.white')};
`;

const Text = styled.p`
  margin: 0;
`;

const UnCaptureIcon = styled.img.attrs({ as: UnCapture })`
  width: 38px;
  height: 38px;
  margin-top: 12px;
  margin-bottom: 10px;
`;

const CaptureIcon = styled.img.attrs({ as: Capture })``;

const Input = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const PureTakePhotoButton = props => {
  const {
    children,
    isCaptured,
    facingMode,
    onChange,
    display,
    isCapturedText,
    unCapturedText,
    color,
    shape,
    width,
    height,
  } = props;
  const inputEl = useRef();

  const [handleOnClick] = useDebouncedCallback(
    () => {
      inputEl.current.click();
    },
    300,
    { leading: true, trailing: false }
  );

  const handleOnChange = useCallback(
    event => {
      const file = event.target.files[0];
      if (typeof onChange === 'function') {
        onChange(file);
      }
    },
    [onChange]
  );

  return (
    <Container
      color={color}
      shape={shape}
      width={width}
      height={height}
      display={display}
      onClick={handleOnClick}
    >
      {children}
      <Cover shape={shape}>
        <Content>
          {isCaptured ? <CaptureIcon /> : <UnCaptureIcon />}
          <Text>{isCaptured ? isCapturedText : unCapturedText}</Text>
        </Content>
      </Cover>
      <Input
        ref={inputEl}
        name="wemo-camera"
        type="file"
        accept="image/*"
        capture={facingMode}
        onChange={handleOnChange}
      />
    </Container>
  );
};

PureTakePhotoButton.defaultProps = {
  isCaptured: false,
  facingMode: 'environment',
  display: 'inline-block',
  isCapturedText: '拍攝成功！',
  unCapturedText: '點擊拍照',
  width: '100%',
  height: '100%',
  color: 'plan',
  shape: 'round',
};

PureTakePhotoButton.propTypes = {
  isCaptured: PropTypes.bool,
  facingMode: PropTypes.oneOf(['user', 'environment']),
  onChange: PropTypes.func,
  display: PropTypes.string,
  isCapturedText: PropTypes.string,
  unCapturedText: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  color: PropTypes.string, // Ref Button
  shape: PropTypes.string, // Ref Button
};

export default React.memo(PureTakePhotoButton);

import { useState, useCallback, useEffect, useRef } from 'react';

export default ({ loading = false, status = 'success' } = {}) => {
  const [_loading, setLoading] = useState(loading);
  const [loadingStatus, setLoadingStatus] = useState(status);
  const isMounted = useRef(false);
  const counter = useRef();
  const timer = useRef();

  const start = useCallback((status = 'success') => {
    if (!isMounted.current) return;
    setLoadingStatus(status);
    setLoading(true);
    counter.current = Date.now() + 16;
  }, []);

  const end = useCallback(async status => {
    if (!isMounted.current) return;
    if (!counter.current) return;
    if (status) setLoadingStatus(status);
    const diff = counter.current - Date.now();
    if (diff <= 0) {
      setLoading(false);
    } else {
      await new Promise(resolve => {
        timer.current = setTimeout(() => {
          if (isMounted.current) setLoading(false);
          resolve();
        }, diff);
      });
    }
  }, []);

  useEffect(() => {
    isMounted.current = true;
    if (loading) counter.current = Date.now() + 16;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    state: { spinning: _loading, status: loadingStatus },
    start,
    end,
  };
};

import React, { useEffect } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from '~/themes/light';
import PreparedRouter from './PreparedRouter';
import ConnectionDetector from './components/ConnectionDetector';
import GlobalDataProviders from './components/GlobalDataProviders';
import LandScapeAlert from '~/components/atoms/LandscapeAlert';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Mountains of Christmas';
    font-style: normal;
    src: local("Mountains of Christmas"), local("MountainsofChristmas-Regular"), url(https://fonts.gstatic.com/s/mountainsofchristmas/v8/dVGBFPwd6G44IWDbQtPewylJhLDHyIrT3I5b5eGTHmw.woff2) format("woff2"); 
  }

  * {
    box-sizing: border-box;
  }
  
  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
  
  html, body,#root{
    height: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  .gm-style .gm-style-iw-c{
    padding: 0 16px;
  }
`;

const App = () => {
  useEffect(() => {
    let isFocus = false;

    const focusHandler = event => {
      if (
        document.documentElement.offsetHeight <= document.documentElement.clientHeight &&
        ['input', 'textarea'].includes(event.target.localName)
      ) {
        isFocus = true;
      }
    };

    const blurHandler = event => {
      if (
        document.documentElement.offsetHeight <= document.documentElement.clientHeight &&
        ['input', 'textarea'].includes(event.target.localName)
      ) {
        isFocus = false;
        setTimeout(() => {
          if (!isFocus) {
            document.body.scrollIntoView({ behavior: 'smooth' });
          }
        }, 16);
      }
    };

    document.addEventListener('focus', focusHandler, true);
    document.addEventListener('blur', blurHandler, true);

    return () => {
      document.removeEventListener('focus', focusHandler, true);
      document.removeEventListener('blur', blurHandler, true);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ConnectionDetector>
        <GlobalDataProviders>
          <PreparedRouter />
        </GlobalDataProviders>
      </ConnectionDetector>
      <LandScapeAlert />
    </ThemeProvider>
  );
};

export default App;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from 'styled-system';

const Container = styled.div`
  text-align: center;
`;

const Dot = styled.span`
  border-radius: 50%;
  display: inline-block;
  width: 6px;
  height: 6px;
  ${color}
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

export const PurePaginationDots = ({ index, onIndexChange, count }) => {
  const handleOnIndexChange = index => {
    typeof onIndexChange === 'function' && onIndexChange(index);
  };

  return (
    <Container>
      {Array(count)
        .fill(0)
        .map((c, i) => (
          <Dot
            key={i}
            onClick={() => handleOnIndexChange(i)}
            backgroundColor={i === index ? 'mainBlue' : 'unavailableGrey'}
          />
        ))}
    </Container>
  );
};

PurePaginationDots.defaultProps = { index: 0 };

PurePaginationDots.propTypes = {
  index: PropTypes.number,
  onIndexChange: PropTypes.func,
  count: PropTypes.number,
};

export default React.memo(PurePaginationDots);

import { rentStatus, userStatus, wemoPhone } from '~/configs';

export const storagePopupData = {
  rentNote: {
    title: '租車須知',
    confirmButtonText: '租車',
    notes: ['按下租車，即開始收費', '確認車牌後再租借'],
  },
  returnNote: {
    title: '還車須知',
    confirmButtonText: '還車',
    notes: [
      '按下還車，即付款並歸還機車',
      '機車是否已停妥合法公有露天停車格內',
      '安全帽是否已放回車',
      '個人物品是否已取出',
    ],
  },
};

// export const userStatusWithCardStatus = [
//   userStatus.pending,
//   userStatus.disable,
//   userStatus.unpassed,
// ];

// export const userStatusWithAlert = [userStatus.incomplete, userStatus.updated];

// export const getCardStatusMessage = (currentUserStatus, history) => {
//   const setting = {
//     [userStatus.pending]: {
//       title: '帳號審核中',
//       content: '帳號審核中，結果將以 e-mail 通知',
//       buttonText: '帳號審核中',
//       isButtonDisable: true,
//       onButtonClick: null,
//     },
//     [userStatus.unpassed]: {
//       title: '請更新註冊資料',
//       content: '目前無法租車，請更新註冊資料',
//       buttonText: '更新資料',
//       isButtonDisable: false,
//       onButtonClick: () => history?.replace('/account'),
//     },
//     [userStatus.disable]: {
//       title: '帳號已停權',
//       content: '帳號已停權，請洽客服以取得更多資訊',
//       buttonText: '撥打客服',
//       isButtonDisable: false,
//       onButtonClick: () => (window.location.href = `tel:${wemoPhone}`),
//     },
//   };

//   return () => setting[currentUserStatus];
// };

// const batteryStatus = {
//   lowBattery: 'lowBattery',
//   veryLowBattery: 'veryLowBattery',
//   outOfBattery: 'outOfBattery',
// };

// const instrutionStatus = {
//   stop: 'stop',
//   parkingGate: 'parkingGate',
// };

// const alertTypePriority = {
//   userStatus: 3,
//   scooterStatus: 2,
//   instrution: 1,
// };
// // Alert：
// // 1. 可以分兩種，長存型 / 短暫提示型
// // 2. 畫面一次只能出現一個  Alert ，短暫提示型出現時會取代長存型
// export const getAlertMessage = ({ currentUserStatus, batPercent, type }, history) => {
//   let currentBatteryStatus;
//   if (batPercent <= 30 && batPercent > 20) {
//     currentBatteryStatus = batteryStatus.lowBattery;
//   } else if (batPercent <= 20 && batPercent > 0) {
//     currentBatteryStatus = batteryStatus.veryLowBattery;
//   } else if (batPercent === 0) {
//     currentBatteryStatus = batteryStatus.outOfBattery;
//   }

//   const setting = {
//     [userStatus.incomplete]: {
//       data: {
//         title: '請更新註冊資料',
//         iconType: 'warning',
//         content: '請更新註冊資料，否則將無法租車',
//         onAlertClick: () => history?.replace('/account'),
//       },
//       isPermanent: true,
//       type: 'userStatus',
//     },
//     [userStatus.incomplete30]: {
//       data: {
//         title: '請更新註冊資料',
//         iconType: 'warning',
//         content: '請更新註冊資料，否則將無法租車',
//         onAlertClick: () => history?.replace('/account'),
//       },
//       isPermanent: true,
//       type: 'userStatus',
//     },
//     [userStatus.updated]: {
//       data: {
//         title: '帳號審核中',
//         iconType: 'info',
//         content: '帳號審核中，結果將以 e-mail 通知，期間可租車',
//       },
//       isPermanent: true,
//       type: 'userStatus',
//     },
//     [batteryStatus.lowBattery]: {
//       data: {
//         iconType: 'warning',
//         singleContent: '機車已進入限速模式',
//       },
//       isPermanent: true,

//       type: 'scooterStatus',
//     },
//     [batteryStatus.veryLowBattery]: {
//       data: {
//         title: '機車電量即將耗盡',
//         iconType: 'warning',
//         content: '請盡快還車',
//       },
//       isPermanent: true,
//       type: 'scooterStatus',
//     },
//     [batteryStatus.outOfBattery]: {
//       data: {
//         title: '機車電量已耗盡',
//         iconType: 'warning',
//         content: '熄火後將無法再次啟動',
//       },
//       isPermanent: true,
//       type: 'scooterStatus',
//     },
//     [instrutionStatus.stop]: {
//       data: {
//         title: '長按3秒熄火',
//         iconType: 'info',
//         content: '為了防止誤觸，長按 3 秒才可熄火',
//       },
//       isPermanent: false,
//       type: 'instrution',
//     },
//     [instrutionStatus.parkingGate]: {
//       data: {
//         title: '記得要長按3秒',
//         iconType: 'info',
//         content: '長按3秒才可以開啟閘門',
//       },
//       isPermanent: false,
//       type: 'instrution',
//     },
//   };

//   const messages = [setting[currentUserStatus], setting[currentBatteryStatus], setting[type]]
//     .filter(message => message && message)
//     .reduce((result, message) => {
//       if (!result) return message;
//       else if (alertTypePriority[result?.type] > alertTypePriority[message?.type]) return message;
//       return result;
//     }, null);

//   return messages;
// };

export const scooterButtonText = {
  [rentStatus.guest]: '註冊 / 登入',
  [rentStatus.notRenting]: '預約',
  [rentStatus.routeToScooter]: '租車',
  [rentStatus.reserveParkingLot]: '預約',
};

export const googleMapURL3 =
  'AIzaSyDYp1te-bQEhWE9P9yehRE3biB7LpSEh4U&libraries=geometry,places,visualization';

export const layerType = {
  OPERATION: 1,
  FORBIDDEN: 2,
  WARNING: 3,
  WARNING_PRIVATE: 4,
  WARNING_PARKING_LOT: 5,
  WARNING_ILLEGAL: 6,
  FORBIDDEN_RENT: 7,
  FORBIDDEN_RETURN: 8,
  NON_ALERT: 9,
  ADVERTISEMENT: 10,
  FORBIDDEN_RENT_RETURN: 11,
  PARKING_LOT: 12,
  TOW_YARD: 13,
  DISTRICT: 14,
};

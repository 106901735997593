import firebase from 'firebase/app';
import 'firebase/analytics';
import jwt from 'jsonwebtoken';
import wemoToken from '~/utils/wemoToken';

let currentWemoUtm = {
  campaign: null,
  medium: null,
  source: null,
  term: null,
  content: null,
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyCKlpEWSfXs0Zha4-TjyZMGq0JoOKFcUlY',
  authDomain: 'line-qat.firebaseapp.com',
  databaseURL: 'https://line-qat.firebaseio.com',
  projectId: 'line-qat',
  storageBucket: 'line-qat.appspot.com',
  messagingSenderId: '630072923395',
  appId: '1:630072923395:web:c5640b6a78bf589e09c536',
  measurementId: 'G-LESFMY9T6G',
};

const firebaseConfigProd = {
  apiKey: 'AIzaSyCMmoTf3Y2i4eqHiI9j_U3OGf3RbSPCcuo',
  authDomain: 'line-270109.firebaseapp.com',
  databaseURL: 'https://line-270109.firebaseio.com',
  projectId: 'line-270109',
  storageBucket: 'line-270109.appspot.com',
  messagingSenderId: '53052195215',
  appId: '1:53052195215:web:3ae58eeeb6ec90a90a8bfa',
  measurementId: 'G-YDVXTR7YGL',
};

const eventNames = {
  error: 'error',
  signUp: 'sign_up',
  wemoUtm: 'wemo_utm',
  screenView: 'screen_view',
  oldUserLogin: 'old_user_login',
  scanIdCardBarcodeEffect: 'scan_id_card_barcode_effect',
  rentScooter: 'rent_scooter',
  returnScooter: 'return_scooter',
  find_wemo: 'find_wemo',
};

const initializeFirebaseService = () => {
  if (!firebase.apps.length) {
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_FIREBASE_ENV === 'production'
    ) {
      firebase.initializeApp(firebaseConfigProd);
      console.log('production init firebase');
    } else {
      firebase.initializeApp(firebaseConfigDev);
      console.log('development init firebase');
    }
  }
};

const logOldUserLoginEvent = ({ userCategoryId } = {}) => {
  const { accessToken } = wemoToken.getToken();
  firebase.analytics().logEvent(eventNames.oldUserLogin, {
    user_id: accessToken ? jwt.decode(accessToken).userId : null,
    login_dt: new Date().toISOString() || null,
    user_category_id: userCategoryId || null,
  });
};

const logSignUpEvent = ({ userCategoryId } = {}) => {
  const { campaign, medium, source, term, content } = currentWemoUtm;

  firebase.analytics().logEvent(eventNames.signUp, {
    method: 'line',
    utm_campaign: campaign || null,
    utm_medium: medium || null,
    utm_source: source || null,
    utm_term: term || null,
    utm_content: content || null,
  });
};

const logErrorEvent = ({ type, message, stack } = {}) => {
  firebase.analytics().logEvent(eventNames.error, {
    type,
    message,
    stack,
  });
};

const logWemoUtmEvent = ({ campaign, medium, source, term, content } = {}) => {
  const { accessToken } = wemoToken.getToken();
  currentWemoUtm = {
    campaign,
    medium,
    source,
    term,
    content,
  };

  firebase.analytics().logEvent(eventNames.wemoUtm, {
    user_id: accessToken ? jwt.decode(accessToken).userId : -1,
    utm_campaign: campaign || null,
    utm_medium: medium || null,
    utm_source: source || null,
    utm_term: term || null,
    utm_content: content || null,
  });
};

const logScreenViewEvent = ({ name } = {}) => {
  firebase.analytics().logEvent(eventNames.screenView, {
    screen_name: name || window.location.pathname.replace('/', '') || 'map',
  });
};

const logScanIdCardBarcodeEvent = ({ status } = {}) => {
  firebase.analytics().logEvent(eventNames.scanIdCardBarcodeEffect, {
    status,
  });
};

const logRentScooterEvent = ({ rentId } = {}) => {
  const { accessToken } = wemoToken.getToken();
  const { campaign, medium, source, term, content } = currentWemoUtm;

  firebase.analytics().logEvent(eventNames.rentScooter, {
    user_id: accessToken ? jwt.decode(accessToken).userId : -1,
    utm_campaign: campaign || null,
    utm_medium: medium || null,
    utm_source: source || null,
    utm_term: term || null,
    utm_content: content || null,
    rent_id: rentId || -1,
  });
};

const logReturnScooterEvent = ({ rentId } = {}) => {
  const { accessToken } = wemoToken.getToken();
  const { campaign, medium, source, term, content } = currentWemoUtm;

  firebase.analytics().logEvent(eventNames.returnScooter, {
    user_id: accessToken ? jwt.decode(accessToken).userId : -1,
    utm_campaign: campaign || null,
    utm_medium: medium || null,
    utm_source: source || null,
    utm_term: term || null,
    utm_content: content || null,
    rent_id: rentId || -1,
  });
};

const logPromotionEventEvent = ({ origin, iconType: icon_type, url }) => {
  const { accessToken } = wemoToken.getToken();
  firebase.analytics().logEvent(eventNames.find_wemo, {
    action: 'click',
    object: 'campaign',
    user_id: accessToken ? jwt.decode(accessToken).userId : null,
    origin,
    icon_type,
    url,
  });
};

export {
  logErrorEvent,
  logSignUpEvent,
  logWemoUtmEvent,
  logScreenViewEvent,
  logRentScooterEvent,
  logReturnScooterEvent,
  logOldUserLoginEvent,
  logPromotionEventEvent,
  logScanIdCardBarcodeEvent,
  initializeFirebaseService,
};

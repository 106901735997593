import { getMockAdapter } from './mock';

const mockScooter = () => {
  const mock = getMockAdapter();

  mock.onGet(/v3\/scooters\/.+\/current.+/, 200, {
    result: 'success',
    data: {
      type: 'FORBIDDEN',
      title: '非營運範圍',
      description: '此處為營運範圍外，禁止還車，請將機車停入營運範圍內',
      shapeName: '非營運範圍（文山試院路）',
      canReturn: true,
    },
  });

  mock.onGet('/v3/scooters').reply(200, {
    result: 'success',
    data: [
      {
        id: 'QAT-0017',
        batPercent: 87,
        groupId: '0',
        lat: 25.053977,
        lng: 121.547852,
        styles: {
          pin: 'Skyblue',
        },
        styleId: '64',
      },
      {
        id: 'QAT-0018',
        batPercent: 35,
        groupId: '0',
        lat: 25.052111,
        lng: 121.546532,
        styles: {
          pin: 'Skyblue',
        },
        styleId: '57',
      },
      {
        id: 'QAT-0014',
        batPercent: 90,
        groupId: '0',
        lat: 25.051081,
        lng: 121.545685,
        styles: {
          pin: 'Skyblue',
        },
        styleId: '40',
      },
      {
        id: 'QAT-0015',
        batPercent: 100,
        groupId: '0',
        lat: 25.052819,
        lng: 121.544273,
        styles: {
          pin: 'Skyblue',
        },
        styleId: '4',
      },
      {
        id: 'QAT-0013',
        batPercent: 25,
        groupId: '0',
        lat: 25.054257,
        lng: 121.544868,
        styles: {
          pin: 'Skyblue',
        },
        styleId: '64',
      },
    ],
    meta: {
      radius: 1200,
    },
  });
  // mock.onGet('/v3/scooters').reply(500, {
  //   result: 'fail',
  //   error: 'SCOOTER_NOT_FOUND',
  //   // [ AUTH_NO_VALID_IDENTITY, UNFITTABLE_PARAMS, SCOOTER_NOT_FOUND, INTERNAL_SERVER_ERROR ]
  // });

  mock.onGet(/v3\/scooters\/\w+/).reply(200, {
    result: 'success',
    data: {
      id: 'QAT-0014',
      batPercent: 87,
      groupId: 10,
      lat: 23.12,
      lng: 121.45,
      availableDistance: 23.1,
      priceRule: '15.10.2.5',
      pricing: {
        timePlans: [
          {
            id: '1',
            type: 'hourlyPlan',
            name: '6H 內免費',
            useBeginAt: '2019-03-01T16:00:00Z',
            useEndAt: '2019-03-02T16:00:00Z',
            isDefault: true,
            usedFreeMinutes: 0,
            totalFreeMinutes: 360,
            minsLeft: 360,
            detailUrl: 'https://wemoscooter.com',
            price: 250,
            meta: null,
            isDeletable: false,
          },
          {
            id: '2',
            type: 'monthlyPlan',
            name: '智慧暢遊 299',
            useBeginAt: '2019-03-01T16:00:00Z',
            useEndAt: '2019-03-02T16:00:00Z',
            isDefault: false,
            usedFreeMinutes: 67,
            totalFreeMinutes: 120,
            minsLeft: 53,
            detailUrl: 'https://wemoscooter.com',
            price: 299,
            meta: null,
            isDeletable: false,
          },
          {
            id: '3',
            type: 'mengoPlan',
            name: 'Mengo 方案',
            useBeginAt: '2019-09-09T16:00:00Z',
            useEndAt: '2020-12-31T16:00:00Z',
            isDefault: false,
            usedFreeMinutes: 0,
            totalFreeMinutes: 0,
            minsLeft: 0,
            detailUrl: 'https://wemoscooter.com',
            price: 0,
            meta: {
              unitPerRent: 25,
              unit: 'point',
              freeMinutesPerRent: 12,
            },
            isDeletable: true,
          },
        ],
        pricingPlans: [
          {
            id: '1',
            startPrice: 15,
            freeMinutes: 10,
            pricePerMinute: 2.5,
            isDefault: true,
            isBasic: true,
            name: '一般人',
          },
          {
            id: '2',
            startPrice: 0,
            freeMinutes: 0,
            pricePerMinute: 1.5,
            isDefault: false,
            isBasic: false,
            name: '長租方案001',
          },
        ],
      },
      parkingSpaceId: '1',
      parkingLot: {
        id: '1',
        lat: 23.12,
        lng: 121.45,
        name: 'WeMo 天空停車場',
        description: '來來來',
        isAvailable: true,
        businessHours: '24H',
        address: '慶城街1號',
        phone: '(02)242123124',
        totalSpaces: 300,
        availableSpaces: 50,
        images: {
          covers: ['https://storage.googleapis.com/wemo-backend/parkingLots/1/covers/01.png'],
          maps: ['https://storage.googleapis.com/wemo-backend/parkingLots/1/maps/01'],
        },
        scooters: [
          {
            id: '987-QHA',
            batPercent: 32,
            availableDistance: 10.2,
            groupId: '20',
            parkingSpaceId: '5566',
          },
        ],
        openType: 'automatic',
      },
      styles: {
        pin: 'Skyblue',
      },
      images: {
        covers: [
          'https://storage.googleapis.com/wemo-backend/scooterInAppImages/1-Normal/covers/01.png',
        ],
      },
      styleId: 'string',
    },
  });

  mock.onGet(/v3\/scooters\/\w+/).reply(200, {
    result: 'success',
    // [ AUTH_NO_VALID_IDENTITY, UNFITTABLE_PARAMS, SCOOTER_NOT_FOUND, INTERNAL_SERVER_ERROR ]
  });

  // mock.onGet(/v3\/scooters\/\w+/).reply(500, {
  //   result: 'fail',
  //   error: { type: 'SCOOTER_NOT_IN_ZONE' },
  //   // [ AUTH_NO_VALID_IDENTITY, UNFITTABLE_PARAMS, SCOOTER_NOT_FOUND, INTERNAL_SERVER_ERROR ]
  // });

  mock.onPost('/v3/scooters/userOpenTrunk').reply(200, {
    result: 'success',
  });
  // mock.onPost('/v3/scooters/userOpenTrunk').reply(500, {
  //   result: 'fail',
  // });

  // mock.onPost('/api/scooter/control').reply(200, {
  //   result: 'success',
  // });

  mock.onPost('/api/scooter/control').reply(500, {
    result: 'fail',
    error: { type: 'TIMEOUT' },
    //   // [ AUTH_NO_VALID_IDENTITY, UNFITTABLE_PARAMS, SCOOTER_NOT_FOUND, INTERNAL_SERVER_ERROR ]
  });
};

export default mockScooter;

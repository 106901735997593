import { variant } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

export const panelSize = variant({
  prop: 'size',
  variants: {
    medium: {
      height: 44,
    },
    large: {
      height: 60,
    },
  },
});

export const labelSize = variant({
  prop: 'size',
  variants: {
    medium: {
      fontSize: 16,
    },
    large: {
      fontSize: 20,
    },
  },
});

export const labelShrink = variant({
  prop: 'shrink',
  variants: {
    true: {
      transform: 'translate(0, 4px) scale(0.75)',
    },
    false: {
      transform: 'translate(0, 13px) scale(1)',
    },
  },
});

export const labelFocus = variant({
  prop: 'focusColor',
  variants: {
    blue: {
      color: 'mainBlue',
    },
    red: {
      color: 'wrongRed',
    },
    grey: {},
  },
});

export const wrapperFocus = props =>
  variant({
    prop: 'focusColor',
    variants: {
      blue: {
        border: `1px solid ${themeGet('colors.mainBlue')(props)}`,
      },
      red: {
        border: `1px solid ${themeGet('colors.wrongRed')(props)}`,
      },
      grey: {
        border: `1px solid ${themeGet('colors.typeSpaceGrey')(props)}`,
      },
    },
  });

import React, { useEffect, useLayoutEffect, Fragment, useReducer, useRef } from 'react';
import { Route } from 'react-router-dom';
import { isObjectDiff } from '~/utils';
import { usePageInfo } from '~/context/';
import { routeAuths } from '../configs/defs';
import InLinePrivateRoute from './InLinePrivateRoute';
import InLinePublicRoute from './InLinePublicRoute';
import OutLinePrivateRoute from './OutLinePrivateRoute';

const RouteWithSubRoutes = React.memo(
  route => {
    const { setPageInfo, resetPageInfo } = usePageInfo();
    const { auth, withTitle, pageInfo, isLogin } = route;
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const prevPath = useRef();

    useLayoutEffect(() => {
      if (withTitle) {
        document.title = 'WeMo Scooter';
      }

      if (pageInfo) {
        setPageInfo({ prevPage: null, ...pageInfo });
      } else {
        resetPageInfo();
      }

      // return () => {
      //   document.title = '';
      // };
    }, []);

    useEffect(() => {
      if (prevPath.current === route.path) {
        forceUpdate();
      } else {
        prevPath.current = route.path;
      }
    }, [route]);

    return (
      <Fragment key={ignored}>
        {(() => {
          switch (auth) {
            case routeAuths.inLinePrivate:
              return (
                <InLinePrivateRoute
                  isLogin={isLogin}
                  exact={route.route || false}
                  path={route.path}
                  routes={route.routes}
                  component={route.component}
                />
              );
            case routeAuths.outLinePrivate:
              return (
                <OutLinePrivateRoute
                  exact={route.route || false}
                  path={route.path}
                  routes={route.routes}
                  component={route.component}
                />
              );
            case routeAuths.inLinePublic:
              return (
                <InLinePublicRoute
                  exact={route.route || false}
                  path={route.path}
                  routes={route.routes}
                  component={route.component}
                />
              );
            default:
              return (
                <Route
                  exact={route.route || false}
                  path={route.path}
                  render={props => (
                    // pass the sub-routes down to keep nesting
                    <route.component {...props} routes={route.routes} />
                  )}
                />
              );
          }
        })()}
      </Fragment>
    );
  },
  (prevProps, nextProps) => !isObjectDiff(prevProps, nextProps)
);

export default RouteWithSubRoutes;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import FullScreenLoading from '~/components/molecules/FullScreenLoading';

const useLoadingCountdown = () => {
  const hooks = useRef({ onSuccess: () => {} });
  const [loading, setLoading] = useState(true);

  const handleOnSuccess = useCallback(listener => {
    if (typeof listener !== 'function') return;
    hooks.current.onSuccess = listener;
  }, []);

  useEffect(() => {
    let id = setTimeout(() => {
      setLoading(false);
      hooks.current.onSuccess();
    }, 1200);

    return () => {
      clearTimeout(id);
    };
  }, []);

  return {
    loading,
    onSuccess: handleOnSuccess,
  };
};

export const PureInterchange = props => {
  const history = useHistory();
  const { loading, onSuccess } = useLoadingCountdown();

  useEffect(() => {
    const { search } = history.location;
    onSuccess(() => {
      if (window.liff.isInClient()) {
        history.replace({ pathname: '/', search: `?${search.slice(1)}` });
      } else {
        window.location.replace(`${process.env.REACT_APP_LINE_MINI_URL}?${search.slice(1)}`);
      }
    });
  }, []);

  return <FullScreenLoading spinning={loading} />;
};

PureInterchange.defaultProps = {};

PureInterchange.propTypes = {};

export default React.memo(PureInterchange);

import { variant } from 'styled-system';

export const size = variant({
  prop: 'size',
  variants: {
    small: {
      fontSize: 14,
    },
    medium: {
      fontSize: 16,
      height: 44,
    },
    large: {
      fontSize: 20,
      height: 60,
    },
  },
});

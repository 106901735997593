import React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from 'Images/icons/arrowRight.svg';
import { ReactComponent as CompleteGreen } from 'Images/icons/completeGreen.svg';
import { ReactComponent as Wemo } from 'Images/icons/wemo.svg';
import { format } from 'date-fns';

const UserCategoryStyled = styled.div`
  background-color: white;
`;

const Title = styled.h1`
  margin: 0;
  ${props => props.theme.fonts.h3Button}
  ${css({ padding: ['15px 10px 5px', '30px 20px 5px'] })}
`;

const Ulstyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const List = styled.li`
  padding: 14px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.bgGrey};
  }
  ${css({ padding: ['14px 10px', '14px 20px'] })}
`;

const LinkNoHighlight = styled(Link)`
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
`;

const LinkStyled = styled.span`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  display: flex;
`;

const ItemTitle = styled.h2`
  margin: 2px 0;
  display: inline-block;
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.mainDark};
`;

const Icon = styled.svg`
  margin-right: 13px;
  min-width: 25px;
`;

const Arrow = styled.img`
  margin: auto;
  margin-right: 0;
  width: 24px;
`;

const Value = styled.p`
  margin: 0;
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.secondaryGrey};
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 38px);
  text-overflow: ellipsis;
`;

const InfoContainer = styled.div`
  /* margin-right: 10px; */
  max-width: 100%
  flex-grow: 1;
`;

const IconItem = styled.img`
  margin-left: 10px;
  width: 18px;
`;

const openExternalBrowser = url => {
  if (window.liff && url) {
    window.liff.openWindow({
      url,
      external: true,
    });
  }
};

const UserCategory = ({ userCategory }) => {
  return (
    <UserCategoryStyled>
      <Title>我的方案</Title>
      <Ulstyled>
        <List>
          <LinkStyled>
            <Icon as={Wemo} />
            <InfoContainer>
              <Item>
                <ItemTitle>{userCategory?.name}</ItemTitle>
              </Item>
              <Value>
                使用期限：
                {userCategory?.plan?.useEndAt
                  ? format(new Date(userCategory?.plan?.useEndAt), 'yyyy-MM-dd hh:mm:ss')
                  : '無使用期限'}
              </Value>
            </InfoContainer>
            {/* <Arrow as={ArrowRight} /> */}
          </LinkStyled>
        </List>
      </Ulstyled>
    </UserCategoryStyled>
  );
};

UserCategory.defaultProps = {};

UserCategory.propTypes = {};

export default UserCategory;

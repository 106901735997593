import { useContext, useCallback } from 'react';
import { UserCategoryContext } from '../providers/UserCategoryProvider';
import { getUserCategory } from '~/services/api';
import useLoadingGroup from '~/hooks/useLoadingGroup';

import { underlineToCamelCase } from '~/utils/stringConverter';

const useUserCategory = () => {
  const [userCategory, dispatch] = useContext(UserCategoryContext);
  const loadingUtils = useLoadingGroup({
    userCategory: false,
  });

  // console.log('user', user);

  // dev login (external browser line login)
  const updateUserCategory = useCallback(async () => {
    try {
      loadingUtils.start('userCategory');
      const data = await getUserCategory();
      dispatch({
        type: 'setUserCategory',
        payload: data,
      });

      loadingUtils.end('userCategory');
    } catch (error) {
      loadingUtils.end('userCategory', 'fail');
      console.log(error);
      return null;
    }
  }, []);

  const resetUserCategory = useCallback(() => {
    dispatch({
      type: 'resetUserCategory',
    });
  }, []);

  return {
    userCategory,
    loadingUtils,
    updateUserCategory,
    resetUserCategory,
  };
};

export { useUserCategory };

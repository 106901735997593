import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';
import PageHeader from 'Components/molecules/PageHeader';
import TakeScooterPhoto from './components/TakeScooterPhoto';
import { Row, Col } from 'react-grid-system';
import Button from 'Components/atoms/Button';
import useLoading from '~/hooks/useLoading';
import PopupLoading from '~/components/molecules/PopupLoading';
import { postUserReturnPhoto, postLineIDTokenVerify } from '~/services/api';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import wemoToken from '~/utils/wemoToken';
import { useErrorNotification } from '~/context/ErrorNotification';
import useGeoLocation, { handleLocationFail, errorConfig } from '~/hooks/useGeoLocation';
import createPhotoUtils from '~/utils/photoUtils';

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: scroll;
  ${css({
    padding: ['10px', '20px'],
  })}
`;

const CustomPageHeader = styled(PageHeader)`
  flex-shrink: 0;
  ${css({ marginBottom: ['34.5px', '46px'] })};
`;

const Content = styled.div`
  width: 100%;
  text-align: center;
  flex: 1 0 auto;
`;

const Footer = styled.div`
  width: 100%;
  flex-shrink: 0;
  margin-top: 40px;
`;

const OS = window.liff ? window.liff.getOS() : 'android';
const errorsConfig = {
  PHOTO_UTILS_ERROR: {
    popup: {
      title: '照片處理異常',
      notes: '無法進行照片前置處理',
      confirmButtonText: '確認',
    },
    notifier: 'popup',
  },
  COMPRESSION_ERROR: {
    popup: {
      title: '照片壓縮異常',
      notes: '無法進行照片壓縮',
      confirmButtonText: '確認',
    },
    notifier: 'popup',
  },
  RENT_ID_NOT_FOUND: {
    popup: {
      title: '參數異常',
      notes: '無法取得租賃資訊',
      confirmButtonText: '確認',
    },
    notifier: 'popup',
  },
  ACCESS_TOKEN_NOT_FOUND: {
    popup: {
      title: '參數異常',
      notes: '無法取得權限資訊',
      confirmButtonText: '確認',
    },
    notifier: 'popup',
  },
  ...errorConfig,
};

export const PureTakeReturnPhotoV2 = props => {
  const { rentId: _rentId, onClose, onFinish } = props;
  const [photo, setPhoto] = useState();
  const uploadLoading = useLoading({ loading: false });
  const rentId = useRef(_rentId);
  const urlQuery = useRef(qs.parse(window.location.search.slice(1)));
  const isExceptionReturn = useMemo(
    () => urlQuery.current.isExceptionReturn === 'true' || false,
    []
  );
  const history = useHistory();
  const { registerErrorConfig, launchError, launchApiError } = useErrorNotification();
  const { location, error: locationError } = useGeoLocation();

  const handleOnCancel = useCallback(() => {
    if (OS === 'ios' && typeof onClose === 'function') {
      onClose();
      setPhoto(undefined);
    } else {
      const { redirectTo = '', goBack = {}, wemoToken, ...otherQuery } = urlQuery.current;
      window.location.href = `${process.env.REACT_APP_LINE_MINI_URL}${redirectTo}?${qs.stringify({
        ...goBack,
        ...otherQuery,
        hasPhoto: false,
      })}`;
      setTimeout(() => {
        if (window.close) {
          window.close();
        }
      }, 500);
    }
  }, [onClose]);

  const handleOnChange = useCallback(photo => {
    if (photo) {
      setPhoto(photo);
    }
  }, []);

  const handleOnFinish = useCallback(async () => {
    try {
      uploadLoading.start();

      const { lat, lng, altitude } = location;
      const resultPhoto = await createPhotoUtils()
        .rotateOrientation()
        .setGPS(lat, lng, altitude)
        .compression()
        .execute(photo)
        .catch(error => {
          launchError({ type: 'PHOTO_UTILS_ERROR' }, 'take-return-photo-v2');
          throw error;
        });

      await postUserReturnPhoto(rentId.current, resultPhoto).catch(error => {
        launchApiError(error, 'take-return-photo-v2');
        throw error;
      });

      uploadLoading.end();
    } catch (error) {
      uploadLoading.end('fail');
      throw error;
    }

    if (OS === 'ios' && typeof onFinish === 'function') {
      onFinish();
      setPhoto(undefined);
    } else {
      const { redirectTo = '', forward = {}, accessToken, ...otherQuery } = urlQuery.current;
      window.location.href = `${process.env.REACT_APP_LINE_MINI_URL}${redirectTo}?${qs.stringify({
        ...forward,
        ...otherQuery,
        hasPhoto: true,
      })}`;
      setTimeout(() => {
        if (window.close) {
          window.close();
        }
      }, 500);
    }
  }, [onFinish, photo]);

  useEffect(() => {
    errorsConfig.RENT_ID_NOT_FOUND.popup.onConfirmClick = () =>
      history.replace('/errors/unauthorized');
    errorsConfig.ACCESS_TOKEN_NOT_FOUND.popup.onConfirmClick = () =>
      history.replace('/errors/unauthorized');
    registerErrorConfig('take-return-photo-v2', errorsConfig);

    const { rentId: _rentId, wemoToken: _accessToken } = urlQuery.current;
    if (_rentId) rentId.current = _rentId;
    if (!rentId.current) {
      launchApiError({ type: 'RENT_ID_NOT_FOUND' }, 'take-return-photo-v2');
      return;
    }

    const { accessToken } = wemoToken.getToken();
    if (!accessToken) {
      if (!_accessToken) {
        launchApiError({ type: 'ACCESS_TOKEN_NOT_FOUND' }, 'take-return-photo-v2');
      } else {
        wemoToken.syncLineAxiosToken(_accessToken);
      }
    }
  }, []);

  useEffect(() => {
    if (locationError) {
      launchError(handleLocationFail(locationError), 'take-return-photo-v2');
    }
  }, [locationError]);

  return (
    <Container>
      <CustomPageHeader
        title="拍攝還車照片"
        subtitle={`請確認有拍攝到車牌與停車格\n${
          isExceptionReturn ? '並送出才算還車成功' : '以保障自身權益'
        }`}
      />
      <Content>
        <TakeScooterPhoto isCaptured={!!photo} onChange={handleOnChange} />
      </Content>
      <Footer>
        <Row>
          <Col xs={6}>
            <Button
              shape="capsule"
              boxShadow="light"
              type="float"
              color="plan"
              width={1}
              onClick={handleOnCancel}
            >
              取消
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              shape="capsule"
              type="float"
              boxShadow="light"
              width={1}
              disabled={!photo}
              onClick={handleOnFinish}
            >
              送出
            </Button>
          </Col>
        </Row>
      </Footer>
      <PopupLoading {...uploadLoading.state} successText="上傳成功" />
    </Container>
  );
};

PureTakeReturnPhotoV2.defaultProps = {};

PureTakeReturnPhotoV2.propTypes = {
  rentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
};

export default React.memo(PureTakeReturnPhotoV2);

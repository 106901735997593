import CardUnpaid from '../../CardUnpaid';
import CardSingleAction from 'Components/molecules/CardSingleAction';
import CardRide from '../../CardRide';
import CardReserve from '../../CardReserve';
import CardParkingLot from '../../CardParkingLot';
import { rentStatus, userStatus } from '~/configs';
import { userStatusWithCardStatus } from '../config';

/**
 * propsKey 用來提取 component 要用的 props，一般放要提取的 key，如果有 prop 要展開可以寫成 [{key}, 'spread']
 * keyPoints 是 or 判別
 */
export const ActionCardsState = {
  cards: [
    {
      name: 'unpaid',
      propsKey: [],
      component: CardUnpaid,
      keyPoints: {
        paymentErr: [3],
        userStatus: [],
        userMode: [],
      },
    },
    {
      name: 'singleAction',
      propsKey: [['cardStatusMessage', 'spread']],
      component: CardSingleAction,
      keyPoints: {
        paymentErr: [],
        userStatus: [userStatus.pending, userStatus.unpassed, userStatus.disable],
        userMode: [],
      },
    },
    {
      name: 'ride',
      propsKey: ['updateAlertMessage'],
      component: CardRide,
      keyPoints: {
        paymentErr: [],
        userStatus: [],
        userMode: [rentStatus.renting, rentStatus.rentingParkingLot],
      },
    },
    {
      name: 'parkinglot',
      propsKey: [],
      component: CardParkingLot,
      keyPoints: {
        paymentErr: [],
        userStatus: [],
        userMode: [rentStatus.reserveParkingLot],
      },
    },
    {
      name: 'reserve',
      propsKey: [],
      component: CardReserve,
      keyPoints: {
        paymentErr: [],
        userStatus: [],
        userMode: [rentStatus.notRenting, rentStatus.routeToScooter],
      },
    },
  ],
  visible: {
    paymentErr: [3],
    userStatus: [...userStatusWithCardStatus],
    userMode: [
      rentStatus.routeToScooter,
      rentStatus.renting,
      rentStatus.reserveParkingLot,
      rentStatus.notRenting,
      rentStatus.rentingParkingLot,
    ],
  },
};

export const InfoCardsState = {
  cards: [
    {
      name: 'parkinglot',
      propsKey: ['ratio'],
      component: CardParkingLot,
      keyPoints: {
        paymentErr: [],
        userStatus: [],
        userMode: [rentStatus.rentingParkingLot],
      },
    },
  ],
  visible: {
    paymentErr: [],
    userStatus: [],
    userMode: [rentStatus.rentingParkingLot],
  },
};

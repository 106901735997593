import React, { useState, useEffect, useMemo, useRef, useCallback, useReducer } from 'react';
import { MainStyled } from './style';
import { useStatus, useRenting } from '~/context/';
import useGeoLocation from '~/hooks/useGeoLocation';
import { pageReducer, initialPageState } from './reducer';
import { PageContext } from './pageContext';
import WeMoMap from './components/WeMoMap';
import MainControlPanel from './components/MainControlPanel';
import PopupClosableNote from 'Components/molecules/PopupClosableNote';
import PopupLoading from '~/components/molecules/PopupLoading';
import { storagePopupData } from './config';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { useErrorNotification } from '~/context/ErrorNotification';
import { RentingProvider } from '~/context/providers/RentingProvider';
import { rentStatus, paymentTypes } from '~/configs';
import { getPaymentsInfo } from '~/services/api';
import { detect } from 'detect-browser';
import { useHistory } from 'react-router-dom';

import Snow from './components/Snow';
import SantaClaus from './components/SantaClaus';

const browser = detect();

const errorConfig = {
  GEOLOCATION_ERROR: {
    notifier: 'popup',
    popup: {
      title: '無法取得位置',
      confirmButtonText: '確認',
      notes: '無法取得定位，將無法使用部分功能\n請嘗試重新啟動或是稍後再試',
    },
  },
  GEOLOCATION_PERMISSION_DENIED: {
    notifier: 'popup',
    popup: {
      title: '無法取得位置',
      confirmButtonText: '確認',
      notes:
        // TODO: issue - 在 line 裡頭 browser 無設定處能重新允許權限
        // browser.os === 'iOS' ? '定位功能未開啟，無法使用部分功能\n請允許定位，或至\n設定 > 隱私權 > 定位服務 > Line \n開啟定位權限以使用更多 WeMo'
        '定位功能未開啟，無法使用部分功能',
    },
  },
  GEOLOCATION_POSITION_UNAVAILABLE: {
    notifier: 'popup',
    popup: {
      title: '無法取得位置',
      confirmButtonText: '確認',
      notes: '無法取得定位，將無法使用部分功能\n請嘗試重新啟動或是稍後再試',
    },
  },
  GEOLOCATION_TIMEOUT: {
    notifier: 'popup',
    popup: {
      title: '無法取得位置',
      confirmButtonText: '確認',
      notes: '無法取得定位，將無法使用部分功能\n請嘗試重新啟動或是稍後再試',
    },
  },
};

const handleLocationFail = error => {
  let type = '';

  switch (error?.code) {
    case 1:
      type = 'GEOLOCATION_PERMISSION_DENIED';
      break;
    case 2:
      type = 'GEOLOCATION_POSITION_UNAVAILABLE';
      break;
    case 3:
      type = 'GEOLOCATION_TIMEOUT';
      break;
    default:
      type = 'GEOLOCATION_ERROR';
      break;
  }

  return { type };
};

const deviceIllegalPaymentTypes = new Proxy(
  {
    'Android OS': [paymentTypes.applePay],
    iOS: [],
  },
  {
    get(target, prop) {
      if (prop in target) return target[prop];
      else return Object.values(paymentTypes);
    },
  }
);

const PureMain = () => {
  const mapRef = useRef();
  const [{ storagePopup, offsetHeight }, pageDispatch] = useReducer(pageReducer, initialPageState);
  const { status, getCurrentStatus } = useStatus();
  const { location, hasAskedLocation, error: locationError } = useGeoLocation();

  const [showPolygon, setShowPolygon] = useState(false);
  const {
    initializeRenting,
    renting,
    scooter,
    resetRenting,
    resetDirections,
    handleRentScooter,
    handleReturnScooter,
    loadingUtils,
    setUserLocation,
    handleRouteToScooter,
  } = useRenting();
  const { registerErrorConfig, launchError } = useErrorNotification();
  const [isCardVisible, setIsCardVisible] = useState(false);
  const isGuest = status.isGuest;
  const history = useHistory();
  // Christmas
  const santaCounter = useRef(0);
  const [visibleSanta, setVisibleSanta] = useState(false);
  const [visibleDialogBox, setVisibleDialogBox] = useState(false);
  const visibleDialogBoxTimer = useRef();

  const toCurrentLocation = useCallback(() => {
    if (mapRef.current && location.lat) {
      mapRef.current.googleMap.panTo(location);
      mapRef.current.googleMap.setZoom(17);
    }
  }, [location]);

  const openStoragePopup = key => {
    pageDispatch({ type: 'openStoragePopup', storagePopupType: key });
  };

  const closeStoragePopup = () => {
    pageDispatch({ type: 'closeStoragePopup' });
  };

  const togglePolygon = useCallback(() => {
    setShowPolygon(!showPolygon);
    if (santaCounter.current < 7 && !visibleSanta) {
      santaCounter.current++;
      if (santaCounter.current === 7) {
        setVisibleSanta(true);
        setVisibleDialogBox(true);
      }
    } else if (santaCounter.current > 0 && visibleSanta) {
      santaCounter.current = 0;
      setVisibleSanta(false);
    }
  }, [showPolygon, visibleSanta]);

  const handleNoteOnConfirm = useCallback(() => {
    closeStoragePopup();
    if (storagePopup.type === 'rentNote') {
      handleRentScooter();
    } else if (storagePopup.type === 'returnNote') {
      handleReturnScooter();
    }
  }, [handleRentScooter, handleReturnScooter, storagePopup.type]);

  useEffect(() => {
    !isGuest && initializeRenting();
    return () => !isGuest && resetRenting();
  }, []); // only update once

  useEffect(() => {
    !isGuest && getCurrentStatus();
  }, [isGuest]);

  useEffect(() => {
    if (locationError) {
      launchError(handleLocationFail(locationError), 'location');
    }
  }, [locationError]);

  useEffect(() => {
    if (location) {
      setUserLocation(location);
    }
  }, [location, setUserLocation]);

  useEffect(() => {
    if (renting.userMode !== rentStatus.routeToScooter && renting.directions) {
      resetDirections();
    } else if (renting.userMode === rentStatus.routeToScooter && !renting.directions) {
      handleRouteToScooter();
    }
  }, [renting.userMode, renting.directions]);

  useEffect(() => {
    if (visibleDialogBox) {
      visibleDialogBoxTimer.current = setTimeout(() => {
        setVisibleDialogBox(false);
      }, 3000);
    }
  }, [visibleDialogBox]);

  useEffect(() => {
    disablePageScroll(document.body);
    registerErrorConfig('location', errorConfig);
    registerErrorConfig('payment', {
      PAYMENT_TYPE_UNAVAILABLE: {
        notifier: 'popup',
        popup: {
          title: '無法支援支付方式',
          notes: '此裝置無法使用當前支付方式\n請設定其他支付方式',
          confirmButtonText: '前往設定',
          onConfirmClick() {
            history.replace('/choose-payment');
          },
        },
      },
    });
    getPaymentsInfo().then(paymentInfo => {
      const illegalPaymentTypes = deviceIllegalPaymentTypes[browser.os];
      if (illegalPaymentTypes.includes(paymentInfo.paymentType)) {
        launchError({ type: 'PAYMENT_TYPE_UNAVAILABLE' }, 'payment');
      }
    });
    return () => {
      enablePageScroll(document.body);
      clearTimeout(visibleDialogBoxTimer.current);
      visibleDialogBoxTimer.current = undefined;
    };
  }, []);

  const pageContext = useMemo(() => ({ pageDispatch, openStoragePopup }), []);

  return (
    <PageContext.Provider value={pageContext}>
      <MainStyled>
        {/* <Snow />
        {visibleSanta && <SantaClaus />} */}
        <MainControlPanel
          status={status}
          togglePolygon={togglePolygon}
          toCurrentLocation={toCurrentLocation}
          userMode={renting.userMode}
          isGuest={status.isGuest}
          statusId={status.statusId}
          batPercent={scooter.batPercent}
          isCardVisible={isCardVisible}
          offsetHeight={offsetHeight}
          showPolygon={showPolygon}
          setIsCardVisible={setIsCardVisible}
          pageDispatch={pageDispatch}
          visibleDialogBox={visibleDialogBox}
        />
        {hasAskedLocation && (
          <WeMoMap
            isGuest={isGuest}
            ref={mapRef}
            showPolygon={showPolygon}
            isCardVisible={isCardVisible}
            offsetHeight={offsetHeight}
            setIsCardVisible={setIsCardVisible}
            pageDispatch={pageDispatch}
          />
        )}

        <PopupClosableNote
          typeKey={storagePopup.type}
          visible={storagePopup.isShow}
          turnOffText={'我已知道，下次不要再出現'}
          onConfirmClick={handleNoteOnConfirm}
          onCancelClick={closeStoragePopup}
          {...storagePopupData[storagePopup.type]}
        />
        <PopupLoading {...loadingUtils.state} />
      </MainStyled>
    </PageContext.Provider>
  );
};

PureMain.defaultProps = {};

PureMain.propTypes = {};

const Main = () => (
  <RentingProvider>
    <PureMain />
  </RentingProvider>
);

export default Main;

import React from 'react';
import PropTypes from 'prop-types';
import ItemCard from '~/components/molecules/ItemCard';
import ActionItemCard from '~/components/organisms/ActionItemCard';

export const PureInvoiceItemCard = props => {
  const {
    children,
    type,
    itemIndex,
    title,
    content,
    to,
    actionText,
    disabled,
    ...otherProps
  } = props;

  return type === 'default' ? (
    <ItemCard {...otherProps} itemIndex={itemIndex} title={title}>
      {children ? children : typeof content === 'function' ? content() : content}
    </ItemCard>
  ) : (
    <ActionItemCard
      {...otherProps}
      itemIndex={itemIndex}
      title={title}
      to={to}
      replace={true}
      actionText={typeof actionText === 'function' ? actionText() : actionText}
      disabled={typeof disabled === 'function' ? disabled() : disabled}
    >
      {children ? children : typeof content === 'function' ? content() : content}
    </ActionItemCard>
  );
};

PureInvoiceItemCard.defaultProps = {
  type: 'default',
};

PureInvoiceItemCard.propTypes = {
  type: PropTypes.oneOf(['default', 'action']),
  itemIndex: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.func]),
  /**
   * Refer Link.to
   */
  to: PropTypes.any,
  actionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default React.memo(PureInvoiceItemCard);

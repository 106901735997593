import React, { createContext, useReducer } from 'react';
import pageInfoReducer from '../reducers/pageInfoReducer.js';

const PageInfoContext = createContext();

const PageInfoProvider = ({ children }) => {
  const reducers = useReducer(pageInfoReducer, pageInfoReducer());
  // console.log('PageInfoProvider');

  return <PageInfoContext.Provider value={reducers}>{children}</PageInfoContext.Provider>;
};

export { PageInfoProvider, PageInfoContext };

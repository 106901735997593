import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const InLinePrivateRoute = React.memo(({ component: Component, isLogin, routes, ...rest }) => (
  <Route
    {...rest}
    render={({ location, ...props }) =>
      isLogin ? (
        <Component {...props} routes={routes} />
      ) : (
        <Redirect
          to={{
            pathname: '/landing-page',
            search: location.search,
            state: { from: location },
          }}
        />
      )
    }
  />
));

export default InLinePrivateRoute;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import BasicContainer from 'Components/layouts/BasicContainer';
import ItemCard from '~/components/molecules/ItemCard';
import InvoiceItemCard from '~/components/organisms/InvoiceItemCard';
import FullScreenLoading from '~/components/molecules/FullScreenLoading';
import Header from './components/Header';
import PupupLoading from '~/components/molecules/PopupLoading';
import { invoiceTypes, invoiceKeys, invoiceTexts } from '~/configs';
import useAsyncGet from '~/hooks/useAsyncGet';
import { getInvoiceSettings, patchInvoiceSettingById } from '~/services/api';
import useLoading from '~/hooks/useLoading';
import PopupNote from '~/components/molecules/PopupNote';
import { useDebouncedCallback } from 'use-debounce';

const defaultInvoiceSettings = {
  settings: {},
  chosen: invoiceKeys[invoiceTypes.cetustek],
};

const invoiceSettingsFormatter = api => async (...args) =>
  api(...args)
    .then((settings = []) =>
      settings.reduce((acc, setting) => {
        const { type } = setting;
        const invoiceType = invoiceKeys[type];
        acc.settings[invoiceType] = setting;
        if (setting.isChosen) acc.chosen = invoiceType;
        return acc;
      }, defaultInvoiceSettings)
    )
    .catch(error => {
      throw error;
    });

const getIvoiceActionItemConfig = (type, data) => {
  const itemIndex = invoiceKeys[type];
  const title = invoiceTexts[itemIndex];
  const setting = data[itemIndex] || {};
  return {
    itemIndex,
    type: 'action',
    title,
    content: itemIndex in data ? setting.content : `新增${title}`,
    actionText: itemIndex in data ? '編輯' : '新增',
    disabled: !(itemIndex in data),
    to: {
      pathname: '/edit-invoice',
      state: { invoiceType: itemIndex, id: setting.id, content: setting.content },
    },
  };
};

const InvoiceSettingsPanel = styled.div`
  height: 100%;
`;

const PayConfirmText = styled.p`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
  text-align: center;
`;

const getPaymentInternalServerError = {
  title: '讀取失敗',
  notes: (
    <React.Fragment>
      <PayConfirmText>系統發生異常</PayConfirmText>
      <PayConfirmText>請稍後再試</PayConfirmText>
    </React.Fragment>
  ),
};

export const PureChooseInvoice = props => {
  const loading = useLoading();
  const invoiceSettings = useAsyncGet(invoiceSettingsFormatter(getInvoiceSettings), {
    loading: true,
    data: defaultInvoiceSettings,
  });
  const [showAlert, setShowAlert] = useState({ visible: false });

  const invoiceItems = useMemo(
    () => [
      {
        type: 'default',
        itemIndex: invoiceKeys[invoiceTypes.cetustek],
        title: '店家載具',
        content: '中獎發票以 Email 寄出',
      },
      {
        ...getIvoiceActionItemConfig(invoiceTypes.phone, invoiceSettings.data.settings),
      },
      {
        ...getIvoiceActionItemConfig(invoiceTypes.taxIdNo, invoiceSettings.data.settings),
      },
      {
        ...getIvoiceActionItemConfig(invoiceTypes.donate, invoiceSettings.data.settings),
      },
      {
        ...getIvoiceActionItemConfig(invoiceTypes.citizen, invoiceSettings.data.settings),
      },
    ],
    [invoiceSettings]
  );

  const [handleAlertOnConfirm] = useDebouncedCallback(
    () => {
      setShowAlert({ visible: false });
    },
    300,
    { leading: true, trailing: false }
  );

  const handleItemOnChange = useCallback(
    async itemIndex => {
      const { id } = invoiceSettings.data.settings[itemIndex];
      if (!id) return;
      try {
        loading.start();
        await patchInvoiceSettingById(id);
        loading.end();
      } catch (error) {
        loading.end('fail');
      }
    },
    [invoiceSettings, loading]
  );

  const init = async () => {
    try {
      await invoiceSettings.getData();
    } catch (error) {
      setShowAlert({ visible: true, ...getPaymentInternalServerError });
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <FullScreenLoading spinning={invoiceSettings.loading}>
      <BasicContainer>
        <Header />
        <InvoiceSettingsPanel>
          <ItemCard.Group
            defaultSelectedIndex={invoiceKeys[invoiceTypes.cetustek]}
            selected={invoiceSettings.data.chosen}
            onChange={handleItemOnChange}
          >
            {invoiceItems.map((config, key) => (
              <InvoiceItemCard key={key} {...config} />
            ))}
          </ItemCard.Group>
        </InvoiceSettingsPanel>
      </BasicContainer>
      <PupupLoading {...loading.state} successText="設定成功" />
      <PopupNote {...showAlert} onConfirmClick={handleAlertOnConfirm} />
    </FullScreenLoading>
  );
};

PureChooseInvoice.defaultProps = {};

PureChooseInvoice.propTypes = {};

export default React.memo(PureChooseInvoice);

import { useContext, useCallback, useRef } from 'react';
import { EmergencyContext } from '../providers/EmergencyProvider';
import { getServiceStatus } from '~/services/api';
import delay from '~/utils/delay';
import { emergencyWaitingTime } from '~/configs';

const useEmergency = () => {
  const [emergency, dispatch, pollingWorker] = useContext(EmergencyContext);
  const isPolling = useRef(false);

  const getEmergency = useCallback(async () => {
    try {
      dispatch({ type: 'setLoading', payload: true });
      const data = await getServiceStatus();
      dispatch({ type: 'setEmergency', payload: data });
      dispatch({ type: 'setLoading', payload: false });
      return data;
    } catch (error) {
      dispatch({ type: 'reset' });
      console.log(error);
    }
  }, [dispatch]);

  const setIsBlockingEntry = useCallback(
    (isBlocking = true) => {
      dispatch({ type: 'setIsBlockingEntry', payload: isBlocking });
    },
    [dispatch]
  );

  const startPollingEmergency = useCallback(async () => {
    isPolling.current = true;
    if (pollingWorker) {
      pollingWorker.postMessage({ cmd: 'start' });
    } else {
      while (true) {
        if (!isPolling.current) return;
        const data = await getServiceStatus();
        if (!isPolling.current) return;
        dispatch({ type: 'setEmergency', payload: data });
        if (!isPolling.current) return;
        await delay(emergencyWaitingTime);
      }
    }
  }, [pollingWorker, dispatch]);

  const stopPollingEmergency = useCallback(() => {
    isPolling.current = false;
  }, []);

  return {
    emergency,
    getEmergency,
    setIsBlockingEntry,
    startPollingEmergency,
    stopPollingEmergency,
  };
};

export { useEmergency };

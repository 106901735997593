import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const certificateHole = (context, canvasWidth, canvasHeight) => {
  const left = canvasWidth * 0.09333333333;
  const top = canvasHeight * 0.3583208396;
  const width = canvasWidth * 0.8133333333;
  const height = width * 0.6196721311;
  context.globalCompositeOperation = 'destination-out';
  fillRoundRect(context, left, top, width, height, 5);
  context.globalCompositeOperation = 'source-over';
  strokeOutlinePath(context, left - 8.5, top - 8.5, width + 8.5 * 2, height + 8.5 * 2, 10);
};

const fillRoundRect = (context, x, y, width, height, radius, fillColor = 'rgba(0, 0, 0, 1)') => {
  if (2 * radius > width || 2 * radius > height) {
    throw new Error('Radius bigger than width or height');
  }

  context.save();
  context.translate(x, y);
  context.fillStyle = fillColor;
  drawRoundRectPath(context, width, height, radius);
  context.fill();
  context.restore();
};

const drawRoundRectPath = (context, width, height, radius) => {
  context.beginPath(0);
  //从右下角顺时针绘制，弧度从0到1/2PI
  context.arc(width - radius, height - radius, radius, 0, Math.PI / 2);
  //矩形下边线
  context.lineTo(radius, height);
  //左下角圆弧，弧度从1/2PI到PI
  context.arc(radius, height - radius, radius, Math.PI / 2, Math.PI);
  //矩形左边线
  context.lineTo(0, radius);
  //左上角圆弧，弧度从PI到3/2PI
  context.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2);
  //上边线
  context.lineTo(width - radius, 0);
  //右上角圆弧
  context.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2);
  //右边线
  context.lineTo(width, height - radius);
  context.closePath();
};

const strokeOutlinePath = (
  context,
  x,
  y,
  width,
  height,
  radius,
  strokeStyle = 'rgba(255, 255, 255, 1)'
) => {
  context.save();
  context.translate(x, y);
  context.strokeStyle = strokeStyle;
  context.lineWidth = 3;
  context.beginPath(0);
  context.arc(width - radius, height - radius, radius, 0, Math.PI / 2);
  context.moveTo(width - radius, height);
  context.lineTo(width - radius - 26, height);
  context.moveTo(width, height - radius);
  context.lineTo(width, height - radius - 26);
  context.stroke();
  context.beginPath(0);
  context.arc(radius, height - radius, radius, Math.PI / 2, Math.PI);
  context.moveTo(radius, height);
  context.lineTo(radius + 26, height);
  context.moveTo(0, height - radius);
  context.lineTo(0, height - radius - 26);
  context.stroke();
  context.beginPath(0);
  context.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2);
  context.moveTo(radius, 0);
  context.lineTo(radius + 26, 0);
  context.moveTo(0, radius);
  context.lineTo(0, radius + 26);
  context.stroke();
  context.beginPath(0);
  context.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2);
  context.moveTo(width - radius, 0);
  context.lineTo(width - radius - 26, 0);
  context.moveTo(width, radius);
  context.lineTo(width, radius + 26);
  context.stroke();
  context.restore();
};

const faceHole = (context, canvasWidth, canvasHeight) => {
  const left = canvasWidth * 0.1666666667;
  const top = canvasHeight * 0.2496251874;
  const width = canvasWidth * 0.6666666667;
  context.globalCompositeOperation = 'destination-out';
  fillCircle(context, left, top, width);
  context.globalCompositeOperation = 'source-over';
  strokeCircle(context, left - 8.5, top - 8.5, width + 8.5 * 2);
};

const fillCircle = (context, x, y, width, fillColor = 'rgba(0, 0, 0, 1)') => {
  context.save();
  context.fillStyle = fillColor;
  drawCirclePath(context, x, y, width / 2);
  context.fill();
  context.restore();
};

const strokeCircle = (context, x, y, width, strokeStyle = 'rgba(255, 255, 255, 1)') => {
  context.save();
  context.strokeStyle = strokeStyle;
  context.lineWidth = 3;
  drawCirclePath(context, x, y, width / 2);
  context.stroke();
  context.restore();
};

const drawCirclePath = (context, x, y, radius) => {
  context.beginPath(0);
  context.arc(x + radius, y + radius, radius, 0, 2 * Math.PI, false);
  context.closePath();
};

const drawEllipsePath = (context, x, y, width, height) => {
  const kappa = 0.5522848;
  const ox = (width / 2) * kappa; //control point offset horizontal
  const oy = (height / 2) * kappa; //control point offset vertical
  const xe = x + width; //x-end
  const ye = y + height; //y-end
  const xm = x + width / 2; //x-middle
  const ym = y + height / 2; //y-middle

  context.translate(x, y);
  context.beginPath(0);
  context.moveTo(x, ym);
  context.bezierCurveTo(x, ym - oy, xm - ox, y, xm, y);
  context.bezierCurveTo(xm + ox, y, xe, ym - oy, xe, ym);
  context.bezierCurveTo(xe, ym + oy, xm + ox, ye, xm, ye);
  context.bezierCurveTo(xm - ox, ye, x, ym + oy, x, ym);
  context.closePath();
};

const CameraOverlayWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: transparent;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const CameraOverlayCover = styled.canvas`
  width: 100%;
  height: 100%;
`;

const CameraOverlayInfoPanel = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  color: #fff;
  padding: calc(100vh * 0.03898050975) calc(100vw * 0.05333333333);
`;

export const PureCameraOverlay = props => {
  const { children, visible, hole, coverBackgroundColor } = props;
  const coverEl = useRef();

  const initCover = useCallback(() => {
    const canvas = coverEl.current;
    const { offsetWidth, offsetHeight } = canvas;
    canvas.width = offsetWidth;
    canvas.height = offsetHeight;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, offsetWidth, offsetHeight);
    context.fillStyle = coverBackgroundColor;
    context.fillRect(0, 0, offsetWidth, offsetHeight);
  }, [coverBackgroundColor]);

  const drawHole = useCallback(() => {
    const canvas = coverEl.current;
    const { width, height } = canvas;
    const context = canvas.getContext('2d');
    if (hole === 'certificate') certificateHole(context, width, height);
    if (hole === 'face') faceHole(context, width, height);
    if (typeof hole === 'function') hole(canvas);
  }, [hole]);

  useEffect(() => {
    initCover();
  }, []);

  useEffect(() => {
    if (visible && hole) {
      initCover();
      drawHole();
    }
  }, [visible, hole, initCover, drawHole]);

  return (
    <CameraOverlayWrapper visible={visible}>
      <CameraOverlayInfoPanel>{children}</CameraOverlayInfoPanel>
      <CameraOverlayCover ref={coverEl} />
    </CameraOverlayWrapper>
  );
};

PureCameraOverlay.defaultProps = {
  visible: true,
  coverBackgroundColor: 'rgba(0, 0, 0, 0.6)',
};

PureCameraOverlay.propTypes = {
  visible: PropTypes.bool,
  hole: PropTypes.oneOfType([PropTypes.oneOf(['certificate', 'face']), PropTypes.func]),
  coverBackgroundColor: PropTypes.string,
};

export default PureCameraOverlay;

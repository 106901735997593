import LineAxios from './LineAxios';

LineAxios.create('hermes');
const axios = LineAxios.getInstance('hermes').useToken();

export const getScooters = geoLocation => {
  return axios
    .get(`/v3/scooters`, {
      params: {
        ...geoLocation,
        radius: 500,
      },
    })
    .then(({ data }) => {
      return data.data;
    });
};

export const checkReturnAvailable = scooterId => {
  return axios.get(`/v3/scooters/${scooterId}/zones/current`, { type: 4 }).then(({ data }) => {
    return data.data;
  });
};

export const checkExceptionReturnAvailable = (scooterId, userLocation) => {
  return axios
    .get(`/v3/scooters/${scooterId}/zones/current`, {
      type: 5,
      lng: userLocation.lng,
      lat: userLocation.lat,
    })
    .then(({ data }) => {
      return data.data;
    });
};

export const getScooterById = scooterId => {
  return axios.get(`/v13/scooters/${scooterId}`).then(({ data }) => {
    return data.data;
  });
};

// if (parseInt(controlType, 10) === 1) {
//   return 'POWER_ON'
// } else if (parseInt(controlType, 10) === 2) {
//   return 'POWER_OFF'
// } else if (parseInt(controlType, 10) === 3) {
//   return 'OPEN_BAT'
// } else if (parseInt(controlType, 10) === 4) {
//   return 'OPEN_TRUNK'
// } else if (parseInt(controlType, 10) === 5) {
//   return 'FLASH'
// }

export const powerOnScooter = () => {
  return axios.post(`api/scooter/control`, { control_type_id: 1 }).then(({ data }) => {
    return data;
  });
};

export const powerOffScooter = () => {
  return axios.post(`api/scooter/control`, { control_type_id: 2 }).then(({ data }) => {
    return data;
  });
};

export const flashScooter = () => {
  return axios.post(`api/scooter/control`, { control_type_id: 5 }).then(({ data }) => {
    return data;
  });
};

export const openScooterTrunk = scooterId => {
  return axios.post(`api/scooter/control`, { control_type_id: 4 }).then(({ data }) => {
    return data;
  });
};

export const returnScooterOpenTrunk = async scooterId => {
  return axios.post(`/v3/scooters/userOpenTrunk`, { scooterId }).then(({ data }) => {
    return data;
  });
};

import { isObjectDiff } from '~/utils';

const initEmergencyState = {
  loading: false,
  popupVisible: false,
  popupClosed: false,
  isBlockingEntry: true,
  service: {
    status: 0,
  },
  announcement: {
    mapUrl: null,
    entryUrl: null,
  },
};

const emergencyReducer = (state, action) => {
  const { type, payload } = action || {};
  switch (type) {
    case 'setEmergency':
      return {
        ...state,
        ...payload,
        popupClosed:
          state.service.status !== payload.service.status && state.popupClosed
            ? false
            : state.popupClosed,
        isBlockingEntry:
          payload.service.status === 0 && state.isBlockingEntry ? false : state.isBlockingEntry,
      };
    case 'setPopupVisible':
      return {
        ...state,
        popupVisible: true,
      };
    case 'setPopupClose':
      return {
        ...state,
        popupVisible: false,
        popupClosed: false,
      };
    case 'setLoading':
      return {
        ...state,
        loading: payload,
      };
    case 'setIsBlockingEntry':
      return {
        ...state,
        isBlockingEntry: payload,
      };
    case 'reset':
      return {
        ...initEmergencyState,
      };
    default:
      return state;
  }
};

export default (state = initEmergencyState, action) => {
  const nextState = emergencyReducer(state, action);
  return isObjectDiff(state, nextState) ? nextState : state;
};

import LineAxios from './LineAxios';

LineAxios.create('hermes');
const axios = LineAxios.getInstance('hermes').useToken();

// const getToken = () => LineAxios.getInstance('hermes').getToken();

export const getScooterStyles = () => {
  return axios.get(`/v2/users/me/scooterStyles`).then(({ data }) => {
    if (data.result !== 'success') {
      throw new Error(data);
    }
    return data.data;
  });
};

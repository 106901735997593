export class NoLIFF extends Error {
  constructor(message) {
    super(message);
    this.name = 'NoLIFF';
  }
}

export class LineNotLoggedIn extends Error {
  constructor(message) {
    super(message);
    this.name = 'LineNotLoggedIn';
  }
}

export class NoLineAccessToken extends Error {
  constructor(message) {
    super(message);
    this.name = 'NoLineAccessToken';
  }
}

export class NoLineIdToken extends Error {
  constructor(message) {
    super(message);
    this.name = 'NoLineIdToken';
  }
}

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Polygon, InfoWindow } from '@react-google-maps/api';
import { getGooglePolygonCenterPoint } from '~/utils/geometry';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

const Info = styled.div`
  text-align: center;
`;

const Title = styled.p`
  margin: 0;
  margin-top: 8px;
  ${props => themeGet(`fonts.caption`)};
`;

const Text = styled.p`
  margin: 8px 0;
  ${props => themeGet(`fonts.body2`)};
  font-size: 12px;
`;

const PolygonViewer = ({
  id,
  paths,
  name,
  desc,
  polygonsBorderColorHex,
  polygonsBorderColorOpacity,
  polygonsFilledColorHex,
  polygonsFilledColorOpacity,
  onPolygonClick,
  isInfoVisible,
  onInfoCloseClick,
  zIndex = 1,
  visible,
} = {}) => {
  const center = useMemo(() => isInfoVisible && paths && getGooglePolygonCenterPoint(paths), [
    isInfoVisible,
    paths,
  ]);

  const handleOnPolygonClick = () => {
    onPolygonClick && onPolygonClick(id);
  };

  return (
    <div>
      <Polygon
        onClick={handleOnPolygonClick}
        visible={visible}
        paths={paths}
        options={{
          strokeWeight: '0.5',
          strokeColor: polygonsBorderColorHex,
          strokeOpacity: polygonsBorderColorOpacity,
          fillColor: polygonsFilledColorHex,
          fillOpacity: polygonsFilledColorOpacity,
        }}
        zIndex={zIndex}
      />
      {isInfoVisible && (
        <InfoWindow onCloseClick={onInfoCloseClick} position={center || paths[0]} zIndex={zIndex}>
          <Info>
            {name && <Title>{name}</Title>}
            {desc && <Text>{desc}</Text>}
          </Info>
        </InfoWindow>
      )}
    </div>
  );
};

PolygonViewer.propTypes = {
  id: PropTypes.string,
  path: PropTypes.array,
  name: PropTypes.string,
  desc: PropTypes.string,
  polygonsBorderColorHex: PropTypes.string,
  polygonsBorderColorOpacity: PropTypes.number,
  polygonsFilledColorHex: PropTypes.string,
  polygonsFilledColorOpacity: PropTypes.number,
  onPolygonClick: PropTypes.func,
  onInfoCloseClick: PropTypes.func,
  isInfoVisible: PropTypes.bool,
  isHighLight: PropTypes.bool,
};

export default React.memo(PolygonViewer);

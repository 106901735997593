export const initialPageState = {
  storagePopup: { isShow: false, type: 'rentNote' },
  popup: { isShow: false, type: 'rentNote' },
  offsetHeight: {
    alert: 0,
    map: 0,
    topControlGroupTop: 0,
    topControlGroupContainer: 0,
    topInfoGroupTop: 0,
    topInfoGroupContainer: 0,
  },
};

export const pageReducer = (state, action) => {
  switch (action.type) {
    case 'closeStoragePopup':
      return { ...state, storagePopup: { ...state.storagePopup, isShow: false } };
    case 'openStoragePopup':
      return { ...state, storagePopup: { isShow: true, type: action.storagePopupType } };
    case 'closePopup':
      return { ...state, popup: { ...state.popup, isShow: false } };
    case 'openPopup':
      return { ...state, popup: { isShow: true, type: action.popupType } };
    case 'setOffsetHeight':
      return {
        ...state,
        offsetHeight: { ...state.offsetHeight, [action.name]: action?.offsetHeight },
      };
    case 'setTimerStart':
      return {
        ...state,
        since: Date.now(),
      };
    case 'reset':
    default:
      return initialPageState;
  }
};

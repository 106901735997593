import React from 'react';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import Card from 'Components/atoms/Card';
import { space } from 'styled-system';

const CardOldUserStyled = styled(Card)`
  text-align: center;

  ${space}
`;

const Title = styled.h2`
  ${props => themeGet(`fonts.body`)};
  line-height: 0;
`;
const Content = styled.p`
  margin-top: 22px;
  ${props => themeGet(`fonts.body`)};
`;

const CardOldUser = ({ className }) => {
  return (
    <CardOldUserStyled
      boxShadow="none"
      backgroundColor="typeSpaceGrey"
      padding={['22px 16px', '22px 32px']}
      className={className}
    >
      <Title>＊舊用戶須知＊</Title>
      <Content>
        WeMo
        非常重視帳號安全性。為保護您的帳戶將進行舊用戶兩步驟驗證。請確認手機號碼及身分證字號符合您的
        WeMo 帳號資料。
        <br />
        如有更多問題請撥打客服專線 0800-3493204
      </Content>
    </CardOldUserStyled>
  );
};

CardOldUser.defaultProps = {};

CardOldUser.propTypes = {};

export default CardOldUser;

import ExpiredStorage from 'expired-storage';
import jwt from 'jsonwebtoken';
import uniqid from 'uniqid';
import dayjs from 'dayjs';
import { NoLIFF, LineNotLoggedIn, NoLineIdToken } from '~/errors';

const SecretKeyFieldName = 'litjs';
const expiredStorage = new ExpiredStorage();
const developIdToken = {
  iss: 'https://access.line.me',
  sub: 'develop.qat',
  amr: ['linesso'],
  name: 'develop.qat',
};

export const sign = () => {
  let payload;
  if (!window.liff) throw new NoLIFF();
  if (process.env.NODE_ENV === 'production') {
    if (!window.liff.isLoggedIn()) throw new LineNotLoggedIn();
    payload = window.liff.getDecodedIDToken();
  } else {
    // development
    developIdToken.iat = dayjs().unix();
    developIdToken.exp = dayjs()
      .add(1, 'day')
      .unix();
    payload = developIdToken;
  }

  if (!payload) throw new NoLineIdToken();
  const { exp } = payload;
  const secretKey = uniqid();
  const token = jwt.sign(payload, secretKey);
  expiredStorage.removeItem(SecretKeyFieldName);
  expiredStorage.setItem(SecretKeyFieldName, secretKey, exp);
  return token;
};

export const verify = token => {
  if (!window.liff) throw new NoLIFF();
  if (process.env.NODE_ENV === 'production') {
    if (!window.liff.isLoggedIn()) throw new LineNotLoggedIn();
  }

  const secretKey = expiredStorage.getItem(SecretKeyFieldName);
  if (!secretKey) {
    return false;
  }

  try {
    const payload = jwt.verify(token, secretKey);
    if (!payload) throw new NoLineIdToken();
    return payload;
  } catch (error) {
    console.log(error.name);
    return false;
  }
};

export const getSecretKey = () => expiredStorage.getItem(SecretKeyFieldName);

import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import { themeGet } from '@styled-system/theme-get';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { getUserHistoryById } from '~/services/api';
import useAsyncGet from '~/hooks/useAsyncGet';
import FullScreenLoading from '~/components/molecules/FullScreenLoading';
import BasicContainer from 'Components/layouts/BasicContainer';
import RidingDetail from '~/components/atoms/RidingDetail';
import PopupNote from '~/components/molecules/PopupNote';
import Button from '~/components/atoms/Button';
import userLoading from '~/hooks/useLoading';

// import ChristmasLights from 'Components/atoms/ChristmasLights';

const getPaymentTypeByPaymentsAmount = ({
  creditCardAmount,
  walletAmount,
  linePayAmount,
  creditAmount,
  applePayAmount,
  totalAmount,
}) => {
  if (!(totalAmount - creditAmount)) return 'free';
  if (creditCardAmount) return 'creditCard';
  if (walletAmount) return 'wallet';
  if (linePayAmount) return 'linePay';
  if (applePayAmount) return 'applePay';
  if (creditAmount) return 'credit';
  return 'none';
};

const userRentHistoryFormatter = api => {
  return async (...args) =>
    api(...args).then(({ rent } = {}) => {
      if (!rent) return {};
      const {
        creditCardAmount,
        walletAmount,
        linePayAmount,
        creditAmount,
        applePayAmount,
        totalAmount,
        status,
      } = rent;
      return {
        ...rent,
        paymentType:
          status === 'cancel'
            ? 'cancel'
            : status !== 'paid'
            ? 'none'
            : getPaymentTypeByPaymentsAmount({
                creditCardAmount,
                walletAmount,
                linePayAmount,
                applePayAmount,
                creditAmount,
                totalAmount,
              }),
      };
    });
};

const Container = styled(React.forwardRef((props, ref) => <BasicContainer ref={ref} {...props} />))`
  background-color: ${themeGet('colors.bgGrey')};
`;

const Wrapper = styled.div`
  ${css({
    minHeight: ['calc(100vh - 30px)', 'calc(100vh - 60px)'],
  })}
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const FooterButton = styled(Button)`
  width: 240px;
`;

const AlertPopupNoteConfigs = new Proxy(
  {
    PAYMENT_GET_ORDERS_FAILURE: {
      visible: true,
      title: '無法取得訂單資訊',
      notes: '查無此次租賃訂單，如無疑慮請洽客服進行協助',
    },
  },
  {
    get(target, prop) {
      if (target[prop]) {
        return target[prop];
      } else {
        return {
          visible: true,
          title: '無法取得訂單資訊',
          notes: '伺服器發生異常狀況',
        };
      }
    },
  }
);

export const PureOrderDetail = props => {
  const history = useHistory();
  const urlQuery = useRef(qs.parse(window.location.search.slice(1)));
  const screenLoading = userLoading({ loading: true });
  const rentHistory = useAsyncGet(userRentHistoryFormatter(getUserHistoryById), {
    loading: true,
    data: {},
  });
  const [alertConfig, setAlertConfig] = useState({ visible: false });
  const containerEl = useRef();

  const handleOnAlertConfirm = useCallback(() => {
    history.replace('/');
  }, [history]);

  const handleConfirmOnClick = useCallback(() => {
    history.replace('/');
  }, [history]);

  const init = useRef(async () => {
    const { rentId } = urlQuery.current;
    if (!rentId) {
      history.replace('/errors/not-found');
      return;
    }

    screenLoading.start();
    try {
      await rentHistory.getData({
        id: rentId,
        type: 'rent',
      });
      screenLoading.end();
    } catch (error) {
      screenLoading.end('fail');
      if (error.response && error.response.data) {
        const {
          error: { type },
        } = error.response.data;
        const config = AlertPopupNoteConfigs[type];
        setAlertConfig(config);
      }
    }
  });

  useEffect(() => {
    const container = containerEl.current;
    if (!container) return;
    if (!screenLoading.state.spinning) {
      disablePageScroll(container);
    } else {
      enablePageScroll(container);
    }
  }, [screenLoading.state.spinning]);

  useEffect(() => {
    init.current();
    const container = containerEl.current;
    return () => {
      container && enablePageScroll(container);
    };
  }, []);

  return (
    <FullScreenLoading {...screenLoading.state}>
      <Container ref={containerEl} navigationBar={false} autoHeight={false}>
        {/* <ChristmasLights top="-6px" /> */}
        <Wrapper>
          <RidingDetail
            scooterId={rentHistory.data.scooterId}
            rentAt={rentHistory.data.rentAt}
            returnAt={rentHistory.data.returnAt}
            totalRideMinutes={Number(rentHistory.data.totalRideMinutes)}
            rideDistance={rentHistory.data.distances}
            totalAmount={rentHistory.data.totalAmount}
            bills={rentHistory.data.bills}
            creditAmount={rentHistory.data.creditAmount}
            paymentType={rentHistory.data.paymentType}
          />
          <Footer>
            <FooterButton
              boxShadow="light"
              shape="capsule"
              type="float"
              onClick={handleConfirmOnClick}
            >
              確認
            </FooterButton>
          </Footer>
        </Wrapper>
      </Container>
      <PopupNote {...alertConfig} onConfirmClick={handleOnAlertConfirm} />
    </FullScreenLoading>
  );
};

PureOrderDetail.defaultProps = {};

PureOrderDetail.propTypes = {};

export default React.memo(PureOrderDetail);

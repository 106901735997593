import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';
import { ReactComponent as WalletIcon } from '~/images/icons/wallet.svg';
import { ReactComponent as WalletDisabledIcon } from '~/images/icons/wallet-disabled.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  opacity: ${({ hazy }) => (hazy ? 0.5 : 1)};
`;

const Icon = styled.img`
  ${space}
`;

const Balance = styled.span`
  ${themeGet(`fonts.body`)};
  color: ${({ disabled }) => disabled && themeGet(`colors.secondaryGrey`)};
`;

export const PureWeMoWalletInfo = props => {
  const { children, balance, hazy, disabled } = props;
  return (
    <Container hazy={hazy}>
      <Icon as={disabled ? WalletDisabledIcon : WalletIcon} mr={['10px', '20px']} />
      <Balance disabled={disabled}>{children || `餘額：${balance}`}</Balance>
    </Container>
  );
};

PureWeMoWalletInfo.defaultProps = {
  balance: 0,
  hazy: false,
  disabled: false,
};

PureWeMoWalletInfo.propTypes = {
  balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hazy: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default React.memo(PureWeMoWalletInfo);

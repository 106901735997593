import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import css from '@styled-system/css';
import { Link } from 'react-router-dom';
import { ReactComponent as Phone } from 'Images/icons/phone.svg';
import { ReactComponent as Email } from 'Images/icons/email.svg';
import { ReactComponent as Address } from 'Images/icons/address.svg';
import { ReactComponent as ArrowRight } from 'Images/icons/arrowRight.svg';
import { ReactComponent as CompleteGreen } from 'Images/icons/completeGreen.svg';
import { ReactComponent as Incomplete } from 'Images/icons/incomplete.svg';

const settingConfig = [
  { name: 'phone', title: '手機號碼', path: '/edit-phone', icon: Phone },
  { name: 'email', title: '聯絡電子信箱', path: '/edit-email', icon: Email },
  {
    name: 'address',
    title: '聯絡地址',
    path: '/edit-address',
    icon: Address,
  },
];

const UserSettingStyled = styled.div`
  background-color: white;
  margin-bottom: 6px;
`;

const Title = styled.h1`
  margin: 0;
  ${props => props.theme.fonts.h3Button}
  ${css({ padding: ['15px 10px 5px', '30px 20px 5px'] })}
`;

const Ulstyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const List = styled.li`
  padding: 14px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.bgGrey};
  }
  ${css({ padding: ['14px 10px', '14px 20px'] })}
`;

const LinkNoHighlight = styled(Link)`
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
`;

const LinkStyled = styled(LinkNoHighlight)`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  display: flex;
`;

const ItemTitle = styled.h2`
  margin: 2px 0;
  display: inline-block;
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.mainDark};
`;

const Icon = styled.svg`
  margin-right: 13px;
  min-width: 25px;
`;

const Arrow = styled.img`
  margin: auto;
  margin-right: 0;
  width: 24px;
`;

const Value = styled.p`
  margin: 0;
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.secondaryGrey};
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 38px);
  text-overflow: ellipsis;
`;

const InfoContainer = styled.div`
  margin-right: 10px;
  max-width: calc(100% - 62px);
  flex-grow: 1;
`;

const IconItem = styled.img`
  margin-left: 10px;
  width: 18px;
`;

const UserSetting = props => {
  return (
    <UserSettingStyled>
      <Title>帳號</Title>
      <Ulstyled>
        {settingConfig.map(item => (
          <List key={item.name}>
            <LinkStyled to={item.path} replace={true}>
              <Icon as={item.icon} />
              <InfoContainer>
                <Item>
                  <ItemTitle>{item.title}</ItemTitle>
                  {item.name === 'phone' && (
                    <IconItem as={props.isPhoneVerified ? CompleteGreen : Incomplete} />
                  )}
                </Item>
                <Value>{props[item.name]}</Value>
              </InfoContainer>
              <Arrow as={ArrowRight} />
            </LinkStyled>
          </List>
        ))}
      </Ulstyled>
    </UserSettingStyled>
  );
};

UserSetting.defaultProps = {
  isPhoneVerified: false,
};

UserSetting.propTypes = {
  phone: PropTypes.string,
  isPhoneVerified: PropTypes.bool,
  address: PropTypes.string,
  email: PropTypes.string,
};

export default UserSetting;

import {
  loginUser,
  loginGuestUser,
  refreshToken,
  registerUser,
  existUserLogin,
  DEV_loginUser,
} from '~/services/api';
import wemoToken from '~/utils/wemoToken';
import LineAxios from '~/services/LineAxios';
import { pathIsAuth } from '~/App/configs/routes';
import { routeAuths } from '~/App/configs/defs';

const loginTypes = {
  guest: 'guest',
  user: 'user',
  notLogin: null,
};

const axios = LineAxios.getInstance('hermes').useToken();

export let loginStatus = { isLogin: false, type: loginTypes.notLogin };

const resetToken = err => {
  wemoToken.setToken({});
  wemoToken.syncLineAxiosToken();
  setLoginStatus(false);
};

const setLoginStatus = (isLogin = false, type = loginTypes.notLogin) => {
  loginStatus = { isLogin, type };
};

const handleUnauthorization = async error => {
  const originalRequest = error.config;
  LineAxios.clearInstancesUnauthorizationAction(); // prevent duplication of 401 handling
  if (error?.response?.data?.data?.error?.type === 'USER_EXPIRED_TOKEN') {
    if (wemoToken.hasToken()) {
      const token = wemoToken.getToken();
      await refreshExpiredToken(token);
      if (wemoToken.hasToken()) return axios(originalRequest);
    }
  }
  LineAxios.setInstancesUnauthorizationAction(handleUnauthorization, ['hermes']);

  logOut();
  window.location.replace('/');
  throw error;
};

LineAxios.create('hermes');
LineAxios.setInstancesUnauthorizationAction(handleUnauthorization, ['hermes']);

const loginExistUser = async data => {
  try {
    const token = await existUserLogin(data);
    wemoToken.setToken(token);
    wemoToken.syncLineAxiosToken();
    setLoginStatus(true, loginTypes.user);
  } catch (error) {
    setLoginStatus(false);
    throw error;
  }

  return loginStatus;
};

const login = async () => {
  try {
    resetToken();
    const token = await loginUser();
    wemoToken.setToken(token);
    wemoToken.syncLineAxiosToken();
    setLoginStatus(true, loginTypes.user);
  } catch (error) {
    setLoginStatus(false);
    throw error;
  }

  return loginStatus;
};

const loginGuest = async () => {
  try {
    resetToken();
    const token = await loginGuestUser();
    wemoToken.setToken(token);
    wemoToken.syncLineAxiosToken();
    setLoginStatus(true, loginTypes.guest);
  } catch (error) {
    setLoginStatus(false);
  }

  return loginStatus;
};

const refreshExpiredToken = async () => {
  const token = wemoToken.getToken();
  try {
    if (token.refreshToken) {
      const newToken = await refreshToken(token.refreshToken);
      wemoToken.setToken(newToken);
      wemoToken.syncLineAxiosToken();
      setLoginStatus(true, loginTypes.user);
    }
  } catch (error) {
    console.log(error);
    setLoginStatus(false);
  }
};

const registerAndBindLineId = async data => {
  try {
    const result = await registerUser(data);
    if (result.token) {
      wemoToken.setToken({
        refreshToken: result.refreshToken,
        accessToken: result.accessToken,
      });
      wemoToken.syncLineAxiosToken();
      setLoginStatus(true, loginTypes.user);
    }
  } catch (error) {
    setLoginStatus(false);
    throw error;
  }

  return loginStatus;
};

// dev login (external browser line login)
const DEV_line_login = async () => {
  try {
    if (!wemoToken.hasToken() && !window.liff.isLoggedIn()) {
      // window.liff.login({
      //   redirectUri: pathIsAuth(window.location.pathname, routeAuths.outLinePrivate)
      //     ? window.location
      //     : process.env.REACT_APP_VENUS_URL,
      // });
      const token = await DEV_loginUser();
      wemoToken.setToken(token);
      wemoToken.syncLineAxiosToken();
      setLoginStatus(true, loginTypes.user);
    } else {
      if (wemoToken.hasToken()) {
        wemoToken.syncLineAxiosToken();
        setLoginStatus(true, loginTypes.user);
      } else {
        const token = await loginUser();
        wemoToken.setToken(token);
        wemoToken.syncLineAxiosToken();

        setLoginStatus(true, loginTypes.user);
      }
    }
  } catch (error) {
    setLoginStatus(false);
    throw error;
  }

  return loginStatus;
};

// clear token
const logOut = () => {
  resetToken();
  return loginStatus;
};

export { loginTypes };

export default {
  login,
  logOut,
  loginGuest,
  DEV_line_login,
  registerAndBindLineId,
  loginExistUser,
};

import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import ErrorCase from '~/components/organisms/ErrorCase';
import { logErrorEvent } from '~/utils/firebaseService';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 以至於下一個 render 會顯示 fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    /**
     * TODO: 可以把錯誤記錄到一個錯誤回報系統服務
     */
    console.log(error);
    logErrorEvent({ stack: JSON.stringify(errorInfo), message: error.toString() });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      if (this.props.type === 'fullPage') {
        return <ErrorCase {...this.props.config} />;
      }
    }
    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {
  type: 'fullPage',
  config: {
    title: '系統維護中',
    description: (
      <span>
        很抱歉！本頁面目前不可使用
        <br />
        工程師努力修復中...
      </span>
    ),
  },
};

ErrorBoundary.propTypes = {
  type: PropTypes.oneOf(['fullPage']),
  config: PropTypes.object,
};

export default React.memo(ErrorBoundary);

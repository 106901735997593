import React, { useState, Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import PageHeader from 'Components/molecules/PageHeader';
import Card from 'Components/atoms/Card';
import Button from 'Components/atoms/Button';
import PaginationDots from 'Components/atoms/PaginationDots';
import Popup from '~/components/atoms/Popup';
import SwipeableViews from 'react-swipeable-views';
import automaticTutorial from 'Images/automaticTutorial.png';

const PopupStyled = styled(Popup)`
  max-height: 90%;
  height: 90%;
`;

const Image = styled.img`
  max-width: 100%;
  margin: 0 auto;
  display: block;
`;

const CardStyled = styled(Card)`
  ${space};
  text-align: center;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  ${props => props.theme.fonts.subtitle};
  margin: 0;
`;

const Note = styled.p`
  ${props => props.theme.fonts.body2};
  margin: 0;
`;

const Hint = styled.p`
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.secondaryGrey};
  text-align: center;
`;

const PageHeaderStyled = styled(PageHeader)`
  h1 {
    font-size: 22px;
  }
`;

const ButtonStyled = styled(Button)`
  ${props => props.theme.fonts.h2Button}
  margin-top: 18px;
  color: white;

  &:first-child {
    margin-top: 36px;
  }
`;

export const PureInfoParkingAutomatic = ({ visible, onConfirmClick, onClose }) => {
  const [index, setIndex] = useState(0);
  const [popupContentHeight, setPopupContentHeight] = useState(0);
  const popupRef = useRef();

  const handleChangeIndex = index => {
    setIndex(index);
  };

  useEffect(() => {
    if (visible && popupRef.current) {
      setPopupContentHeight(
        Math.max(
          popupRef.current?.parentElement?.clientHeight,
          popupRef.current?.parentElement?.innerHeight || 0
        ) * 0.74
      );
    }
  }, [popupContentHeight, visible]);

  return (
    <PopupStyled visible={visible} maskClosable={true} onClose={onClose}>
      <div
        ref={ref => {
          popupRef.current = ref;
        }}
      >
        {popupContentHeight && (
          <Fragment>
            <SwipeableViews
              enableMouseEvents={true}
              index={index}
              onChangeIndex={handleChangeIndex}
              resistance
              containerStyle={{ height: popupContentHeight }}
            >
              <div>
                <PageHeaderStyled
                  title="如何進出停車場？"
                  hint="機車停至 WeMo 前輪感應區，車頭朝向感應器，閘門即自動開啟"
                />
                <Image src={automaticTutorial} />
              </div>
              <div>
                <PageHeaderStyled title="閘門未打開如何處理？ " />
                <CardStyled padding={[10, 20]} boxShadow="none" backgroundColor="typeSpaceGrey">
                  <Title>進入停車場</Title>
                  <Note>
                    直接拿取停車代幣進場，再將代幣交還給管理室人員，並透過 WeMo 客服電話回報異常情形
                  </Note>
                </CardStyled>
                <CardStyled padding={[10, 20]} boxShadow="none" backgroundColor="typeSpaceGrey">
                  <Title>離開停車場</Title>
                  <Note>機車退出 WeMo 前輪感應區，五秒後重新進入感應區，並確認車頭朝向感應器</Note>
                </CardStyled>
                <Hint>(如上述作法均無法開啟閘門時，請通報管理室人員，協助處理設備故障問題)</Hint>
              </div>
            </SwipeableViews>
            <PaginationDots count={2} index={index} onIndexChange={handleChangeIndex} />
            <ButtonStyled
              boxShadow="light"
              size="small"
              width={1}
              shape="capsule"
              type="float"
              fontcolor="white"
              onClick={onConfirmClick}
            >
              確認
            </ButtonStyled>
          </Fragment>
        )}
      </div>
    </PopupStyled>
  );
};

PureInfoParkingAutomatic.defaultProps = {};

PureInfoParkingAutomatic.propTypes = {};

export default React.memo(PureInfoParkingAutomatic);

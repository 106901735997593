import React, { useRef, useEffect, forwardRef, useReducer, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TimerStyled = styled.div`
  ${props => props.theme.fonts.h5}
`;

function reducer(state, action) {
  let now, duration, startTimeMs, sinceTime;
  switch (action.type) {
    case 'update':
      now = Date.now();
      duration = now - state.sinceTime + state.startTimeMs;

      return {
        ...state,
        duration,
      };
    case 'resume':
      return {
        ...state,
        sinceTime: Date.now() - state.duration,
        isTurnOn: true,
        startTimeMs: 0,
      };
    case 'stop':
      return { ...state, isTurnOn: false };
    case 'start':
      startTimeMs = action?.payload?.startTimeMs || 0;
      sinceTime = action?.payload?.sinceTime ? action?.payload?.sinceTime : Date.now();
      duration = action?.payload?.sinceTime
        ? Date.now() - action?.payload?.sinceTime + startTimeMs
        : 0;

      return {
        isTurnOn: true,
        sinceTime,
        duration,
        startTimeMs,
      };
    default:
      throw new Error();
  }
}

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const secondToMinute = second => {
  if (typeof second === 'number') {
    const totalMinute = Math.floor(second / 60);
    const hour = Math.floor(totalMinute / 60);
    if (hour) {
      const minute = Math.floor(second / 60) % 60;
      return `${String(hour).padStart(2, 0)}:${String(minute).padStart(2, 0)}:${String(
        second % 60
      ).padStart(2, 0)}`;
    } else {
      return `${String(totalMinute).padStart(2, 0)}:${String(second % 60).padStart(2, 0)}`;
    }
  }
  return '-- : --';
};

export const Timer = forwardRef(({ className, ...rest }, ref) => {
  const [state, dispatch] = useReducer(reducer, { isTurnOn: false, duration: 0 });

  useInterval(
    () => {
      if (state.isTurnOn) {
        dispatch({ type: 'update' });
      }
    },
    state.isTurnOn !== null ? 50 : null
  );

  useImperativeHandle(
    ref,
    () => ({
      resume: () => dispatch({ type: 'resume' }),
      start: config => dispatch({ type: 'start', payload: config }),
      stop: () => dispatch({ type: 'stop' }),
      duration: state.duration,
      isTurnOn: state.isTurnOn,
    }),
    [state.duration, state.isTurnOn]
  );

  return (
    <TimerStyled className={className}>
      {secondToMinute(Math.round(state.duration / 1000))}
    </TimerStyled>
  );
});

Timer.defaultProps = {
  isTurnOn: false,
};

Timer.propTypes = {
  isTurnOn: PropTypes.bool,
};

export default React.memo(Timer);

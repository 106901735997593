import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CardMap from 'Components/atoms/CardMap';
import Button from 'Components/atoms/Button';
import { space } from 'styled-system';

const CardSingleActionStyled = styled(CardMap)`
  text-align: center;
  ${space}
`;

const Title = styled.div`
  margin-bottom: 2px;
  ${props => props.theme.fonts.subtitle}
`;

const Content = styled.div`
  margin-bottom: 20px;
  ${props => props.theme.fonts.body}
`;

const CardSingleAction = ({
  title,
  content,
  isButtonDisable,
  onButtonClick,
  buttonText,
  cardSingleActionProps,
}) => {
  return (
    <CardSingleActionStyled padding={['10px', '20px']} ratio="none" {...cardSingleActionProps}>
      <Title>{title}</Title>
      <Content>{content}</Content>
      <Button
        shape="capsule"
        type="float"
        color="primary"
        width={0.71}
        boxShadow="light"
        disabled={isButtonDisable}
        onClick={onButtonClick}
      >
        {buttonText}
      </Button>
    </CardSingleActionStyled>
  );
};

CardSingleAction.defaultProps = {
  isButtonDisable: false,
};

CardSingleAction.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  buttonText: PropTypes.string,
  isButtonDisable: PropTypes.bool,
  onButtonClick: PropTypes.func,
};

export default CardSingleAction;

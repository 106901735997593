import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import {
  isGuiNumberValid, // 統一編號
  isCitizenDigitalCertificateValid, // 自然人憑證
  isEInvoiceCellPhoneBarcodeValid, // 手機條碼
  isEInvoiceDonateCodeValid, // 捐贈碼
} from 'taiwan-id-validator2';
import { useDebouncedCallback } from 'use-debounce';
import BasicContainer from 'Components/layouts/BasicContainer';
import Form from '~/components/atoms/Form';
import FormItem from '~/components/atoms/Form/FormItem';
import Input from '~/components/atoms/Input';
import Button from '~/components/atoms/Button';
import PageHeader from '~/components/molecules/PageHeader';
import FullScreenLoading from '~/components/molecules/FullScreenLoading';
import PupupLoading from '~/components/molecules/PopupLoading';
import Popup from '~/components/atoms/Popup';
import { invoiceTypes, invoiceKeys, invoiceTexts } from '~/configs';
import { postInvoiceSetting, patchInvoiceSettingById } from '~/services/api';
import useLoading from '~/hooks/useLoading';

const isLegalInvoiceType = invoiceType =>
  Object.values(invoiceKeys)
    .filter(value => value !== invoiceKeys[0]) // ignore cetustek
    .includes(invoiceType);

const invoiceTitles = new Proxy(invoiceTexts, {
  get(target, prop) {
    if (prop === invoiceKeys[invoiceTypes.donate]) {
      return '捐贈發票碼';
    } else {
      return target[prop];
    }
  },
});

const invoicePlaceholder = new Proxy(invoiceTexts, {
  get(target, prop) {
    if (prop === invoiceKeys[invoiceTypes.donate]) {
      return '愛心碼';
    } else {
      return target[prop];
    }
  },
});

const invoiceValidators = {
  phone: isEInvoiceCellPhoneBarcodeValid,
  donate: isEInvoiceDonateCodeValid,
  citizen: isCitizenDigitalCertificateValid,
  taxIdNo: isGuiNumberValid,
};

const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
`;

const SubmitButton = styled(Button)`
  width: 240px;
`;

const ErrorMessageTitle = styled.h1`
  ${themeGet('fonts.subtitle')};
  color: ${themeGet('colors.wrongRed')};
  margin: 0;
  text-align: center;
`;

const ErrorMessageContent = styled.p`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
  margin-top: 4px;
  text-align: center;
`;

const ErrorMessageButton = styled(Button)`
  ${themeGet('fonts.h2Button')};
  color: #fff;
  height: 50px;
  width: 100%;
  margin-top: 36px;
`;

export const PureEditInvoice = props => {
  const { form } = props;
  const { getFieldDecorator, getFieldsError, isFieldsTouched, validateFields } = form;
  const history = useHistory();
  const loading = useLoading();
  const [invoiceType, setInvoiceType] = useState();
  const [showError, setShowError] = useState(false);

  const isSaveDisabled = useCallback(() => {
    const fieldsError = getFieldsError();
    const isFieldsError = Object.keys(fieldsError).some(field => fieldsError[field]);
    return isFieldsError || !isFieldsTouched(null, 'every');
  }, [getFieldsError, isFieldsTouched]);

  const contentValidator = useCallback(
    (rule, value, callback) => {
      const validator = invoiceValidators[invoiceType];
      if (!validator) {
        callback('載具類型錯誤');
        return;
      }

      if (!validator(value)) {
        callback(`${invoicePlaceholder[invoiceType]}格式錯誤`);
        return;
      }

      callback();
    },
    [invoiceType]
  );

  const [handleSubmitOnClick] = useDebouncedCallback(
    async () => {
      await validateFields()
        .then(async ({ content }) => {
          try {
            loading.start();
            const { location } = history;
            const { id } = location.state;
            if (id) {
              await patchInvoiceSettingById(id, { type: invoiceTypes[invoiceType], content });
            } else {
              await postInvoiceSetting({ type: invoiceTypes[invoiceType], content }); // for test: /YETDHIQ
            }
            loading.end();
          } catch (error) {
            loading.end('fail');
            setShowError(true);
            console.log(error);
          }
        })
        .catch(() => {});
    },
    300,
    { leading: true, trailing: false }
  );

  const handleErrorCloseOnClick = useCallback(() => {
    setShowError(false);
  }, []);

  useEffect(() => {
    const { location } = history;
    if (!location.state || !isLegalInvoiceType(location.state.invoiceType)) {
      history.replace('/errors/not-found');
    } else {
      const { invoiceType } = location.state;
      setInvoiceType(invoiceType);
    }
  }, []);

  return (
    <FullScreenLoading spinning={!invoiceType}>
      <BasicContainer autoHeight={false}>
        <PageHeader title={`編輯${invoiceTitles[invoiceType]}`} />
        <Form labelPosition="top">
          <FormItem>
            {invoiceType &&
              getFieldDecorator('content', {
                initialValue: history.location.state.content,
                rules: [
                  {
                    required: true,
                    message: `請輸入${invoiceTitles[invoiceType]}`,
                  },
                  { validator: contentValidator, onTrigger: false },
                ],
              })(
                <Input type="text" placeholder={`請輸入${invoicePlaceholder[invoiceType]}(必填)`} />
              )}
          </FormItem>
        </Form>
        <Footer>
          <SubmitButton
            type="float"
            shape="capsule"
            boxShadow="light"
            disabled={isSaveDisabled()}
            onClick={handleSubmitOnClick}
          >
            儲存
          </SubmitButton>
        </Footer>
      </BasicContainer>
      <PupupLoading {...loading.state} successText="設定成功" onSuccessGoBack={true} />
      <Popup visible={showError}>
        <ErrorMessageTitle>無法儲存{invoicePlaceholder[invoiceType]}</ErrorMessageTitle>
        <ErrorMessageContent>輸入{invoicePlaceholder[invoiceType]}無效或重複。</ErrorMessageContent>
        <ErrorMessageButton
          type="float"
          shape="capsule"
          boxShadow="light"
          onClick={handleErrorCloseOnClick}
        >
          確認
        </ErrorMessageButton>
      </Popup>
    </FullScreenLoading>
  );
};

PureEditInvoice.defaultProps = {};

PureEditInvoice.propTypes = {};

export default React.memo(Form.create()(PureEditInvoice));

import React, { useContext, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CardMap from 'Components/atoms/CardMap';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Button from 'Components/atoms/Button';
import PopupLoading from 'Components/molecules/PopupLoading';
import { ReactComponent as Battery } from 'Images/icons/battery.svg';
import { ReactComponent as Money } from 'Images/icons/money.svg';
import { useRenting } from '~/context';
import { rentStatus } from '~/configs/';
import { PageContext } from 'Components/pages/Main/pageContext';
import { scooterButtonText } from 'Components/pages/Main/config';
import ExpiredStorage from 'expired-storage';

// import ChristmasLights from 'Components/atoms/ChristmasLights';

const ButtonStyled = styled(Button)`
  margin: 8px auto 0;
`;

const FlexWrapper = styled.div`
  ${space}
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const Id = styled.span`
  ${props => themeGet(`fonts.h3Button`)};
  margin: auto;
  margin-left: 0;
  color: ${props => themeGet(`colors.mainDark`)};
`;

const Icon = styled.img`
  vertical-align: middle;
`;

const Info = styled.span`
  ${props => themeGet(`fonts.body`)};
  margin: auto;
  margin-right: 0;
  display: flex;
  align-items: center;
`;

const Price = styled.span`
  ${props => themeGet(`fonts.body2`)};
  margin: 8px auto 0;
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  margin: 0 6px;
`;

const PrimaryText = styled(Text)`
  color: ${themeGet('colors.mainBlue')};
`;

const expiredStorage = new ExpiredStorage();

const CardMapRatio = [0.59, 0.54];
const FlexWrapperPadding = ['8px 8px', '10px 10px', '20px 20px'];

const CardReserveContainer = React.memo(
  ({
    userMode,
    handleReserveCardOnClick,
    pricingPlans,
    scooterId,
    batPercent,
    availableDistance,
    loadingUtils,
    ...cardProps
  }) => {
    return (
      <Fragment>
        <CardMap ratio={CardMapRatio} {...cardProps}>
          {/* <ChristmasLights /> */}
          <FlexWrapper padding={FlexWrapperPadding}>
            <Id>{scooterId}</Id>
            <Info>
              <Icon as={Battery} />
              <Text>{batPercent}% </Text>|<Text>{availableDistance}km</Text>
            </Info>
            <Price>
              <Icon as={Money} />
              <Text>
                前 {pricingPlans?.freeMinutes} 分鐘
                <PrimaryText>{pricingPlans?.startPrice} 元</PrimaryText>，之後
                <PrimaryText>{pricingPlans?.pricePerMinute} 元 / 分</PrimaryText>
              </Text>
            </Price>
            <ButtonStyled
              shape="capsule"
              boxShadow="light"
              type="float"
              color="primary"
              width={0.72}
              onClick={handleReserveCardOnClick}
            >
              {scooterButtonText[userMode]}
            </ButtonStyled>
          </FlexWrapper>
        </CardMap>
      </Fragment>
    );
  }
);

const PureCardReserve = ({ ...cardProps }) => {
  const { openStoragePopup } = useContext(PageContext);
  const { renting, scooter, handleReserveScooter, handleRentScooter, loadingUtils } = useRenting();

  const { scooterId, batPercent, availableDistance } = scooter;

  const pricingPlans =
    scooter?.pricing?.pricingPlans?.filter?.(s => s.isDefault)[0] ||
    scooter?.pricing?.pricingPlans[0];
  const rentNotePopTurnOff = expiredStorage.getJson('popTurnOff')?.rentNote || false;

  const handleReserveCardOnClick = useCallback(async () => {
    if (renting.userMode === rentStatus.notRenting) {
      handleReserveScooter();
    } else if (renting.userMode === rentStatus.routeToScooter) {
      rentNotePopTurnOff === true ? handleRentScooter() : openStoragePopup('rentNote');
    }
  }, [
    renting.userMode,
    handleReserveScooter,
    rentNotePopTurnOff,
    handleRentScooter,
    openStoragePopup,
  ]);

  return (
    <Fragment>
      <CardReserveContainer
        userMode={renting.userMode}
        handleReserveCardOnClick={handleReserveCardOnClick}
        pricingPlans={pricingPlans}
        scooterId={scooterId}
        batPercent={batPercent}
        availableDistance={availableDistance}
      />
      <PopupLoading {...loadingUtils.state} />
    </Fragment>
  );
};

PureCardReserve.defaultProps = {};

PureCardReserve.propTypes = {
  id: PropTypes.string,
  batPercent: PropTypes.number,
  availableDistance: PropTypes.number,
  onReserveClick: PropTypes.func,
  pricingPlans: PropTypes.object,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
};

const CardReserve = React.memo(PureCardReserve);

export default CardReserve;

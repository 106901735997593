import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as ErrrorIcon} from '~/images/icons/error.svg';

const icons = {
  error: ErrrorIcon,
};

const Icon = styled.img`
  vertical-align: middle;
`;

export const PureSuffix = props => {
  const { feedback } = props;
  const iconSrc = useMemo(() => icons[feedback], [feedback]);

  return iconSrc && <Icon as={iconSrc} />;
};

PureSuffix.propTypes = {
  feedback: PropTypes.oneOf(['error', undefined]),
};

export default React.memo(PureSuffix);

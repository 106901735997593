import React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
  pointer-events: none;
  user-select: none;
`;

const falling = keyframes`
  0% {
    transform: translate3D(-7.5%, -100%, 0);
  }
      
  100% {
    transform: translate3D(7.5%, 100%, 0);
  }
`;

const nearSecond = 10;
const midSecond = nearSecond * 2;
const farSecond = nearSecond * 3;

const nearImage = 'snow-large.png';
const midImage = 'snow-medium.png';
const farImage = 'snow-small.png';

const Snow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: ${falling} linear infinite both;
  transform: translate3D(0, -100%, 0);
  background-size: contain;
  animation-duration: ${props => props.second}s;
  background-image: ${props =>
    `url(https://storage.googleapis.com/wemo-apollo/Christmas/${props.image})`};
`;

const SnowAlt = styled(Snow)`
  animation-delay: (${props => props.second}s / 2);
`;

const PureSnow = props => {
  return (
    <Container>
      <Snow second={nearSecond} image={nearImage} />
      <SnowAlt second={nearSecond} image={nearImage} />
      <Snow second={midSecond} image={midImage} />
      <SnowAlt second={midSecond} image={midImage} />
      <Snow second={farSecond} image={farImage} />
      <SnowAlt second={farSecond} image={farImage} />
    </Container>
  );
};

export default React.memo(PureSnow);

import React from 'react';
import * as CardStates from './states';

export { CardStates };

const extractProps = (sourceProps, propsKey = []) => {
  let extractionProps = {};
  for (const propKey of propsKey) {
    if (typeof propKey === 'string') {
      extractionProps[propKey] = sourceProps[propKey];
    } else if (Array.isArray(propKey)) {
      const [key, config] = propKey;
      if (config === 'spread') {
        extractionProps = { ...extractionProps, ...sourceProps[key] };
      }
    }
  }
  return extractionProps;
};

/**
 * @param {Array} cards 的先後順序與權重有關
 * @param {object} keyResults
 * @param {number} [keyResults.paymentErr]
 * @param {string} [keyResults.userStatus]
 * @param {string} [keyResults.userMode]
 */
const getCardComponent = cards => (keyResults = {}, props) => {
  for (const card of cards) {
    const { keyPoints } = card;
    for (const keyPointKey in keyPoints) {
      const keyPoint = keyPoints[keyPointKey];
      const keyResult = keyResults[keyPointKey];
      if (keyResult === undefined) continue;
      if (!keyPoint.includes(keyResult)) continue;
      const { component: Comp, propsKey } = card;
      return <Comp {...extractProps(props, ['key', 'style', ...propsKey])} />;
    }
  }
};

/**
 * @param {object} keyPoints
 * @param {object} keyResults
 * @param {number} [keyResults.paymentErr]
 * @param {string} [keyResults.userStatus]
 * @param {string} [keyResults.userMode]
 */
const getCardIsVisible = keyPoints => keyResults => {
  for (const keyPointKey in keyPoints) {
    const keyPoint = keyPoints[keyPointKey];
    const keyResult = keyResults[keyPointKey];
    if (!keyPoint.includes(keyResult)) continue;
    return true;
  }
  return false;
};

/**
 *
 * @param {object} cardsState
 * @param {Array} cardsState.cards
 * @param {object} cardsState.visible
 */
const cardStateMachine = cardsState => {
  return {
    getCardComponent: getCardComponent(cardsState.cards),
    getCardIsVisible: getCardIsVisible(cardsState.visible),
  };
};

export default cardStateMachine;

import { useState, useEffect, useRef } from 'react';
// import { getGoogleMapLocation } from '~/services/api';
import { isObjectDiff } from '~/utils';

const geoOptions = {
  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 27000,
  frequency: 15000,
};

let watcherId = null;

const useGeoLocation = () => {
  const mounted = useRef(true);
  const [location, setGeoLocation] = useState({
    lat: null,
    lng: null,
    altitude: null,
    timestamp: null,
  });
  const [error, setError] = useState();
  const [hasAskedLocation, setHasAskedLocation] = useState(false);

  const onSuccess = location => {
    if (mounted.current) {
      const nextState = {
        lat: process.env.REACT_APP_LAT || location.coords.latitude,
        lng: process.env.REACT_APP_LNG || location.coords.longitude,
        altitude: location.coords.altitude || 0,
        timestamp: location.timestamp,
      };
      if (isObjectDiff(location, nextState)) {
        setGeoLocation(nextState);
      }
      !hasAskedLocation && setHasAskedLocation(true);
    }
  };

  const handleOnFail = error => {
    setError(error);
    !hasAskedLocation && setHasAskedLocation(true);
  };

  const getLocation = () => {
    return window.navigator.geolocation.watchPosition(onSuccess, handleOnFail, geoOptions);
  };

  useEffect(() => {
    if (watcherId === null) {
      watcherId = getLocation();
    }

    return () => {
      if (watcherId !== null) {
        window.navigator.geolocation.clearWatch(watcherId);
        watcherId = null;
      }
    };
  }, []);

  return { location, hasAskedLocation, error, getLocation };
};

export default useGeoLocation;

export const handleLocationFail = error => {
  let type = '';

  switch (error?.code) {
    case 1:
      type = 'GEOLOCATION_PERMISSION_DENIED';
      break;
    case 2:
      type = 'GEOLOCATION_POSITION_UNAVAILABLE';
      break;
    case 3:
      type = 'GEOLOCATION_TIMEOUT';
      break;
    default:
      type = 'GEOLOCATION_ERROR';
      break;
  }

  return { type };
};

export const errorConfig = {
  GEOLOCATION_ERROR: {
    notifier: 'popup',
    popup: {
      title: '無法取得位置',
      confirmButtonText: '確認',
      notes: '無法取得定位，將無法使用部分功能\n請嘗試重新啟動或是稍後再試',
    },
  },
  GEOLOCATION_PERMISSION_DENIED: {
    notifier: 'popup',
    popup: {
      title: '無法取得位置',
      confirmButtonText: '確認',
      notes:
        // TODO: issue - 在 line 裡頭 browser 無設定處能重新允許權限
        // browser.os === 'iOS' ? '定位功能未開啟，無法使用部分功能\n請允許定位，或至\n設定 > 隱私權 > 定位服務 > Line \n開啟定位權限以使用更多 WeMo'
        '定位功能未開啟，無法使用部分功能',
    },
  },
  GEOLOCATION_POSITION_UNAVAILABLE: {
    notifier: 'popup',
    popup: {
      title: '無法取得位置',
      confirmButtonText: '確認',
      notes: '無法取得定位，將無法使用部分功能\n請嘗試重新啟動或是稍後再試',
    },
  },
  GEOLOCATION_TIMEOUT: {
    notifier: 'popup',
    popup: {
      title: '無法取得位置',
      confirmButtonText: '確認',
      notes: '無法取得定位，將無法使用部分功能\n請嘗試重新啟動或是稍後再試',
    },
  },
};

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';

const GroupStyled = styled.div`
  width: 100%;

  > * {
    ${space};

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const PureItemCardGroup = props => {
  const { children, defaultSelectedIndex, selected, onChange, ...otherProps } = props;
  const [currentIndex, setCurrentIndex] = useState(defaultSelectedIndex);

  const handleOnSelect = useCallback(
    onSelect => (selected, itemIndex) => {
      // setCurrentIndex(itemIndex);
      onChange(itemIndex);
      if (typeof onSelect === 'function') {
        onSelect(itemIndex, selected);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (selected !== currentIndex) {
      setCurrentIndex(selected);
    }
  }, [selected]);

  return (
    <GroupStyled mt={['15px;', '30px']} {...otherProps}>
      {React.Children.map(children, child => {
        if (child) {
          return React.cloneElement(child, {
            selected: currentIndex === child.props.itemIndex && child.props.itemIndex !== undefined,
            onSelect: handleOnSelect(child.props.onSelect),
            unselectedHazy: true,
          });
        }
      })}
    </GroupStyled>
  );
};

PureItemCardGroup.defaultProps = {
  onChange: () => {},
};

PureItemCardGroup.propTypes = {
  defaultSelectedIndex: PropTypes.string,
  selected: PropTypes.string,
  onChange: PropTypes.func,
};

export default React.memo(PureItemCardGroup);

import styled from 'styled-components';
import { animated } from 'react-spring';
import Button from 'Components/atoms/Button';
import { space } from 'styled-system';

export const AlertContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 100%;
`;

export const ButtonIcon = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 0;
  pointer-events: auto;
`;

export const ButtonAction = styled(Button)`
  width: 100%;
`;

export const TopButtonGroup = styled(animated.div)`
  margin: auto;
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  /* overflow: hidden; */
`;

export const BottomGroup = styled(animated.div)`
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
`;

export const PaddingWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  ${space};
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import qs from 'qs';
import 'firebase/analytics';
import { useDebouncedCallback } from 'use-debounce';
import { omit } from '~/utils';
import CameraAlternative from '~/components/molecules/CameraAlternative';
import CameraOverlay from '~/components/atoms/CameraOverlay';
import TakePhotoInfo from './components/TakePhotoInfo';
import PhotoPreviewer from '~/components/molecules/PhotoPreviewer';
import PreviewController from './components/PreviewController';
import { steps, holes, cameras, components, takePhotoInfos, previewInfos } from './defs';
import { uploadUserPhotos } from '~/services/api';
import PopupNote from '~/components/molecules/PopupNote';
import createPhotoCompression from '~/utils/photoCompression';
import BackToLine, { useBackToLine } from '~/components/molecules/BackToLine';
import CameraV2 from '~/components/molecules/CameraV2';
import Button from '~/components/atoms/Button';
import { createScanImageBarcode } from '~/utils/scanCode';
import { logScanIdCardBarcodeEvent } from '~/utils/firebaseService';
import { logScreenViewEvent } from '~/utils/firebaseService';

const photoCompression = createPhotoCompression();
const DefaultMinStep = 0;
const DefaultMaxStep = 9;

const { beginScanImageBarcode, cancelScanImageBarcode } = createScanImageBarcode();

const getStepRangeByUrlQuery = urlQuery => {
  let min = parseInt(urlQuery.min) || DefaultMinStep;
  let max = parseInt(urlQuery.max) || DefaultMaxStep;
  min = min < DefaultMinStep ? DefaultMinStep : min;
  max = max > DefaultMaxStep ? DefaultMaxStep : max;
  return { max, min };
};

const TakeRegisterPhotosStyled = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const ControlWrapper = styled.div`
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    css({
      pointerEvents: props.isCameraReady ? 'auto' : 'none',
    })}
`;

const CaptureButton = styled(Button)`
  height: 60px;
  width: 60px;

  &:after {
    content: '';
    min-height: 30px;
    min-width: 30px;
    border: 2px solid #fff;
    background: transparent;
    border-radius: 100%;
  }
`;

export const PureTakeRegisterPhotos = props => {
  const urlQuery = qs.parse(window.location.search.slice(1));
  const camera = useRef();
  const stepConfig = useRef(getStepRangeByUrlQuery(urlQuery));
  const token = useRef(urlQuery.token);
  const redirectTo = useRef(urlQuery.redirectTo);
  const urlStep = useRef(urlQuery.step);
  const canCapture = useRef(true);
  const photos = useRef({});
  const idCard = useRef();
  const [isPreviewerDestroyed, setIsPreviewerDestroyed] = useState(false);
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [media, setMedia] = useState('camera');
  const [step, setStep] = useState(stepConfig.current.min);
  const [urls, setUrls] = useState([]);
  const [facingMode, setFacingMode] = useState(cameras[steps[stepConfig.current.min]]);
  const [previewVisible, setPreviewVisible] = useState(
    components[steps[stepConfig.current.min]] === 'preview'
  );
  const [previewInfo, setPreviewInfo] = useState(previewInfos[steps[stepConfig.current.min]]);
  const [visibleOverlay, setVisibleOverlay] = useState(false);
  const isFinish = useMemo(() => step === stepConfig.current.max, [step]);
  const [showAlert, setShowAlert] = useState({ visible: false, confirmButtonText: '回到 WeMo' });
  const [backToLineState, dispatchBackToLine] = useBackToLine();

  const nextStep = useCallback(async () => {
    if (components[steps[step]] === 'camera') {
      const photoAmount = Math.floor((step - stepConfig.current.min + 1) / 2) + 1;
      while (Object.keys(photos.current).length !== photoAmount) {}
    }
    setStep(prev => (prev === stepConfig.current.max ? prev : prev + 1));
  }, [step]);

  const prevStep = useCallback(() => {
    setStep(prev => (prev === stepConfig.current.min ? prev : prev - 1));
  }, []);

  const handleOnError = useCallback(error => {
    console.log(error.name);
    // 先拍後上傳
    setMedia('picker');
  }, []);

  const setPhoto = useCallback(
    async (photo, url) => {
      photos.current = { ...photos.current, [steps[step]]: photo };
      setUrls(prev => [url, ...prev]);
      nextStep();
      if (step === 2) {
        beginScanImageBarcode()(url).then(result => {
          // * 如果掃不到但已經有 ID Card Number 就以先前的為準
          idCard.current = !result && idCard.current ? idCard.current : result;
          logScanIdCardBarcodeEvent({ status: result ? 'success' : 'failure' });
        });
      }
    },
    [nextStep, step]
  );

  const [handleOnCapture, cancelHandleOnCapture] = useDebouncedCallback(
    async () => {
      if (!canCapture.current) return;
      canCapture.current = false;
      const [photo, url] = await camera.current.capturePhoto();
      if (photo && url) {
        setPhoto(photo, url);
      } else {
        setMedia('picker');
      }
    },
    300,
    { leading: true, trailing: false }
  );

  const handleOnChange = useCallback(
    (photo, url) => {
      setPhoto(photo, url);
    },
    [setPhoto]
  );

  const [handleOnRecapture] = useDebouncedCallback(
    () => {
      photos.current = omit([steps[step]], photos.current);
      setUrls(prev => prev.slice(1));
      prevStep();
    },
    300,
    { leading: true, trailing: false }
  );

  const handleOnDestroyed = useCallback(isDestroyed => {
    canCapture.current = true;
    setIsPreviewerDestroyed(isDestroyed);
  }, []);

  const handleOnCameraReady = useCallback(isReady => {
    setIsCameraReady(isReady);
    if (isReady) {
      setVisibleOverlay(true);
    }
  }, []);

  const handleOnProgress = useCallback(
    event => {
      const { lengthComputable, loaded, total } = event;
      if (!lengthComputable) return;
      dispatchBackToLine.setProgress((loaded / total) * 100);
    },
    [dispatchBackToLine]
  );

  const [handleOnConfirm] = useDebouncedCallback(
    async () => {
      nextStep();
      if (step === stepConfig.current.max && !backToLineState.visible) {
        try {
          /**
           * Upload photos
           */
          dispatchBackToLine.switchVisible({ title: '照片壓縮中' });
          const compressionPhotos = await photoCompression.compress(
            Object.values(photos.current),
            progress => {
              dispatchBackToLine.setProgress(progress);
            }
          );
          dispatchBackToLine.resetProgress({ title: '照片上傳中' });
          const photoNames = await uploadUserPhotos(compressionPhotos, handleOnProgress).then(
            ({ data: { names } }) => {
              const keys = Object.keys(photos.current);
              return keys.reduce((acc, curr, index) => {
                acc[curr] = names[index];
                return acc;
              }, {});
            }
          );
          cancelScanImageBarcode();
          logScreenViewEvent({ name: `camera-popup-upload-success` });
          dispatchBackToLine.setState({
            title: '上傳完成',
            url: `${process.env.REACT_APP_LINE_MINI_URL}${redirectTo.current}?step=${
              urlStep.current !== undefined ? urlStep.current : 1
            }&token=${token.current}&${qs.stringify({
              photos: photoNames,
              idCard: idCard.current,
            })}`,
          });
        } catch (error) {
          dispatchBackToLine.reset();
          logScreenViewEvent({ name: `camera-popup-upload-failed` });
          setShowAlert(prev => ({
            ...prev,
            visible: true,
            title: '上傳失敗',
            notes: '伺服器發生異常狀況',
          }));
          throw error;
        }
      }
    },
    300,
    { leading: true, trailing: false }
  );

  const handleAlertPopupOnConfirm = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_LINE_MINI_URL}${redirectTo.current}?step=${
      urlStep.current !== undefined ? urlStep.current : 1
    }&token=${token.current})}`;
  }, []);

  const handlePickerOnClick = useCallback(() => {
    canCapture.current = false;
    setMedia('picker');
  }, []);

  useEffect(() => {
    if (step === 2 && isCameraReady) {
      // const { width } = camera.current.getVideoSize();
      // videoScanBarcode(camera.current.capturePhoto, width);
    }
  }, [isCameraReady, step]);

  useEffect(() => {
    setPreviewVisible(components[steps[step]] === 'preview');
    if (cameras[steps[step]] !== facingMode) {
      setVisibleOverlay(false);
    }
    // if (step === 2) {
    //   const { width } = camera.current.getVideoSize();
    //   videoScanBarcode(camera.current.capturePhoto, width);
    // }

    /**
     * ! 這邊會造成選照片產生Error
     */
    // if (step === 3) {
    //   const { width } = camera.current.getVideoSize();
    //   scanBarcode(urls[0], width)
    //     .then(code => {
    //       idCard.current = code;
    //     })
    //     .catch(() => {});
    // }
  }, [step]);

  useEffect(() => {
    if (isPreviewerDestroyed) {
      if (cameras[steps[step]] !== facingMode) {
        setFacingMode(cameras[steps[step]]);
      }
    }
  }, [facingMode, isPreviewerDestroyed, step]);

  useEffect(() => {
    if (previewVisible) {
      setPreviewInfo(previewInfos[steps[step]]);
    }
  }, [step, previewVisible]);

  useEffect(() => {
    return () => {
      cancelHandleOnCapture();
    };
  }, []);

  useEffect(() => {
    logScreenViewEvent({ name: `camera-page-${steps[step]}` });
  }, [step]);

  return (
    <TakeRegisterPhotosStyled>
      {media === 'camera' ? (
        <React.Fragment>
          <CameraV2
            parentComponentName={`TakeRegisterPhotos-${steps[step]}`}
            // type={step === 2 ? 'scanner' : 'camera'}
            ref={camera}
            facingMode={facingMode}
            overlay={
              visibleOverlay && (
                <CameraOverlay hole={holes[steps[step]]}>
                  <TakePhotoInfo {...takePhotoInfos[steps[step]]} />
                </CameraOverlay>
              )
            }
            onReady={handleOnCameraReady}
            onError={handleOnError}
            onOpenPicker={handlePickerOnClick}
          />
          <ControlWrapper isCameraReady={isCameraReady}>
            <CaptureButton shape="circle" type="float" onClick={handleOnCapture} />
          </ControlWrapper>
        </React.Fragment>
      ) : (
        <CameraAlternative
          info={<TakePhotoInfo {...takePhotoInfos[steps[step]]} />}
          onChange={handleOnChange}
        />
      )}
      <PhotoPreviewer
        imgSrc={urls[0]}
        visible={previewVisible}
        controller={
          <PreviewController
            info={previewInfo}
            confirmText={isFinish ? '完成' : '確認'}
            onRecapture={handleOnRecapture}
            onConfirm={handleOnConfirm}
          />
        }
        onDestroyed={handleOnDestroyed}
      />
      <BackToLine {...backToLineState} />
      <PopupNote zindex={7} {...showAlert} onConfirmClick={handleAlertPopupOnConfirm} />
    </TakeRegisterPhotosStyled>
  );
};

PureTakeRegisterPhotos.defaultProps = {};

PureTakeRegisterPhotos.propTypes = {};

const TakeRegisterPhotos = React.memo(PureTakeRegisterPhotos);
TakeRegisterPhotos.whyDidYouRender = {
  customName: 'TakeRegisterPhotos',
};
export default TakeRegisterPhotos;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as ErrorIcon } from 'Images/icons/error.svg';

const Container = styled.div`
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.supGrey};
  background: ${props => props.theme.colors.typeSpaceGrey};
  padding: 20px 43px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

const Icon = styled.svg`
  width: 43px;
  height: 43px;
  min-width: 43px;
  min-height: 43px;
  margin-bottom: 20px;
`;

export const PureWarningText = ({ text }) => {
  return (
    <Container>
      <Icon as={ErrorIcon} />
      {text}
    </Container>
  );
};

PureWarningText.defaultProps = {};

PureWarningText.propTypes = {
  text: PropTypes.string,
};

export default React.memo(PureWarningText);

import React from 'react';
import ReactDOM from 'react-dom';
import invariant from 'invariant';
import MapContext from './map-context';
import { getOffsetOverride, getLayoutStyles } from './dom-helper';
import ContentMountHandler from './CountMountHandler';
import { useGoogleMap } from '@react-google-maps/api';
import { isObjectDiff } from '~/utils';

export class PureOverlayView extends React.PureComponent {
  static FLOAT_PANE = `floatPane`;
  static MAP_PANE = `mapPane`;
  static MARKER_LAYER = `markerLayer`;
  static OVERLAY_LAYER = `overlayLayer`;
  static OVERLAY_MOUSE_TARGET = `overlayMouseTarget`;

  static contextType = MapContext;

  state = {
    overlayView: null,
    containerStyle: {},
  };
  mapPaneEl = null;
  containerRef;

  setOverlayViewCallback = () => {
    if (this.state.overlayView !== null && this.props.onLoad) {
      this.props.onLoad(this.state.overlayView);
    }

    this.onPositionElement();
  };

  onAdd = () => {
    invariant(
      !!this.props.mapPaneName,
      `OverlayView requires props.mapPaneName but got %s`,
      this.props.mapPaneName
    );

    // https://developers.google.com/maps/documentation/javascript/3.exp/reference#MapPanes
    const mapPanes = this.state.overlayView?.getPanes();

    if (!mapPanes) {
      return;
    }

    this.mapPaneEl = mapPanes[this.props.mapPaneName];
  };

  onPositionElement = () => {
    if (this.state.overlayView !== null) {
      const mapCanvasProjection = this.state.overlayView.getProjection();

      const offset = {
        x: 0,
        y: 0,
        ...(this.containerRef.current
          ? getOffsetOverride(this.containerRef.current, this.props.getPixelPositionOffset)
          : {}),
      };

      const layoutStyles = getLayoutStyles(
        mapCanvasProjection,
        offset,
        this.props.bounds,
        this.props.position
      );

      if (isObjectDiff(this.state.containerStyle, layoutStyles)) {
        this.setState({
          containerStyle: layoutStyles,
        });
      }
    }
  };

  draw = () => {
    this.onPositionElement();
  };

  onRemove = () => {
    this.mapPaneEl = null;
  };

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const overlayView = new window.google.maps.OverlayView();

    // You must implement three methods: onAdd(), draw(), and onRemove().
    overlayView.onAdd = this.onAdd;
    overlayView.draw = this.draw;
    overlayView.onRemove = this.onRemove;

    overlayView.setMap(this.context);

    // You must call setMap() with a valid Map object to trigger the call to
    // the onAdd() method and setMap(null) in order to trigger the onRemove() method.

    this.setState(function setOverlayView() {
      return {
        overlayView,
      };
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.position !== this.props.position || prevProps.bounds !== this.props.bounds) {
      setTimeout(() => {
        this.state.overlayView !== null && this.state.overlayView.draw();
      }, 0);
    }
  }

  componentWillUnmount() {
    if (this.state.overlayView !== null) {
      if (this.props.onUnmount) {
        this.props.onUnmount(this.state.overlayView);
      }

      this.state.overlayView.setMap(null);
    }
  }

  render() {
    return this.mapPaneEl ? (
      ReactDOM.createPortal(
        <div ref={this.containerRef} style={{ ...this.state.containerStyle, position: 'absolute' }}>
          <ContentMountHandler onLoad={this.setOverlayViewCallback}>
            {React.Children.only(this.props.children)}
          </ContentMountHandler>
        </div>,
        this.mapPaneEl
      )
    ) : (
      <React.Fragment />
    );
  }
}
PureOverlayView.whyDidYouRender = {
  customName: 'PureOverlayView',
};

const OverlayView = React.memo(props => {
  const map = useGoogleMap();
  return (
    <MapContext.Provider value={map}>
      <PureOverlayView {...props} />;
    </MapContext.Provider>
  );
});
OverlayView.FLOAT_PANE = `floatPane`;
OverlayView.MAP_PANE = `mapPane`;
OverlayView.MARKER_LAYER = `markerLayer`;
OverlayView.OVERLAY_LAYER = `overlayLayer`;
OverlayView.OVERLAY_MOUSE_TARGET = `overlayMouseTarget`;

export default OverlayView;
OverlayView.whyDidYouRender = {
  customName: 'OverlayView',
};

import piexif from 'piexifjs';
import merge from 'lodash.merge';
import cloneDeep from 'lodash.clonedeep';
import asyncLoadImage from '~/utils/asyncLoadImage';
import imageCompression from 'browser-image-compression';

const { getDataUrlFromFile, getFilefromDataUrl } = imageCompression;

const DefaultCompressionConfigs = {
  maxWidthOrHeight: 734,
  useWebWorker: true,
  fileType: 'image/jpeg',
};

class NoTargetPhoto extends Error {
  constructor(message) {
    super(message);
    this.type = 'NO_TARGET_PHOTO';
    this.name = 'NoTargetPhoto';
  }
}

class CompressionError extends Error {
  constructor(message) {
    super(message);
    this.type = 'COMPRESSION_ERROR';
    this.name = 'CompressionError';
  }
}

class PhotoUtilsExecuteError extends Error {
  constructor(message) {
    super(message);
    this.type = 'PHOTO_UTILS_EXECUTE_ERROR';
    this.name = 'PhotoUtilsExecuteError';
  }
}

class PhotoUtilsInitialError extends Error {
  constructor(message) {
    super(message);
    this.type = 'PHOTO_UTILS_INITIAL_ERROR';
    this.name = 'PhotoUtilsInitialError';
  }
}

function cloneFile(file) {
  const name = file.name;
  const type = file.type;
  const lastModified = file.lastModified;
  return new File([file], name, { type, lastModified });
}

function photoUtilsCore(_pipeQueue) {
  if (_pipeQueue && !Array.isArray(_pipeQueue)) throw new PhotoUtilsInitialError();

  const pipeQueue = _pipeQueue || [];
  let photo = null;
  let exifObj = null;

  const methods = {
    async insertExif(_exifObj) {
      exifObj = _exifObj === exifObj ? exifObj : merge(exifObj, _exifObj);
    },
    setGPS(lat, lng, altitude) {
      if (Number(lat) && Number(lng)) {
        exifObj.GPS[piexif.GPSIFD.GPSLatitudeRef] = lat < 0 ? 'S' : 'N';
        exifObj.GPS[piexif.GPSIFD.GPSLatitude] = piexif.GPSHelper.degToDmsRational(lat);
        exifObj.GPS[piexif.GPSIFD.GPSLongitudeRef] = lng < 0 ? 'W' : 'E';
        exifObj.GPS[piexif.GPSIFD.GPSLongitude] = piexif.GPSHelper.degToDmsRational(lng);
        exifObj.GPS[piexif.GPSIFD.GPSAltitudeRef] = altitude >= 0 ? 0 : 1;
        exifObj.GPS[piexif.GPSIFD.GPSAltitude] = parseInt(altitude);
      }
    },
    async rotateOrientation() {
      const orientation = exifObj['0th'][piexif.ImageIFD.Orientation];
      if (orientation !== 1) {
        const photoUrl = await getDataUrlFromFile(photo);
        const image = await asyncLoadImage(photoUrl);
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        let x = 0;
        let y = 0;

        ctx.save();
        if (orientation == 2) {
          x = -canvas.width;
          ctx.scale(-1, 1);
        } else if (orientation == 3) {
          x = -canvas.width;
          y = -canvas.height;
          ctx.scale(-1, -1);
        } else if (orientation == 4) {
          y = -canvas.height;
          ctx.scale(1, -1);
        } else if (orientation == 5) {
          canvas.width = image.height;
          canvas.height = image.width;
          ctx.translate(canvas.width, canvas.height / canvas.width);
          ctx.rotate(Math.PI / 2);
          y = -canvas.width;
          ctx.scale(1, -1);
        } else if (orientation == 6) {
          canvas.width = image.height;
          canvas.height = image.width;
          ctx.translate(canvas.width, canvas.height / canvas.width);
          ctx.rotate(Math.PI / 2);
        } else if (orientation == 7) {
          canvas.width = image.height;
          canvas.height = image.width;
          ctx.translate(canvas.width, canvas.height / canvas.width);
          ctx.rotate(Math.PI / 2);
          x = -canvas.height;
          ctx.scale(-1, 1);
        } else if (orientation == 8) {
          canvas.width = image.height;
          canvas.height = image.width;
          ctx.translate(canvas.width, canvas.height / canvas.width);
          ctx.rotate(Math.PI / 2);
          x = -canvas.height;
          y = -canvas.width;
          ctx.scale(-1, -1);
        }
        ctx.drawImage(image, x, y);
        ctx.restore();

        photo = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
        exifObj['0th'][piexif.ImageIFD.Orientation] = 1;
        canvas.height = 0;
        canvas.width = 0;
        canvas.remove();
      }
    },
    async compression(compressionConfigs) {
      try {
        photo = await imageCompression(photo, compressionConfigs);
      } catch (error) {
        throw new CompressionError();
      }
    },
  };

  return {
    /**
     * 會把之前的 pipe queue clone 一份並產生新的 photo utils core
     */
    clone() {
      return photoUtilsCore(cloneDeep(pipeQueue));
    },
    geExifObj() {
      return exifObj;
    },
    insertExif(_exifObj = {}) {
      pipeQueue.push({ methodName: 'insertExif', args: [_exifObj] });
      return this;
    },
    setGPS(lat, lng, altitude) {
      pipeQueue.push({ methodName: 'setGPS', args: [lat, lng, altitude] });
      return this;
    },
    rotateOrientation() {
      pipeQueue.push({ methodName: 'rotateOrientation' });
      return this;
    },
    compression(compressionConfigs = DefaultCompressionConfigs) {
      pipeQueue.push({ methodName: 'compression', args: [compressionConfigs] });
      return this;
    },
    async execute(originPhoto) {
      if (!originPhoto) throw new NoTargetPhoto();
      try {
        const name = originPhoto.name;
        photo = cloneFile(originPhoto);
        let photoUrl = await getDataUrlFromFile(photo);
        exifObj = piexif.load(photoUrl);
        for (const pipe of pipeQueue) {
          const { methodName, args = [] } = pipe;
          const method = methods[methodName];
          if (typeof method === 'function') {
            await method(...args);
          }
        }
        photoUrl = await getDataUrlFromFile(photo);
        const exifStr = piexif.dump(exifObj);
        const result = await getFilefromDataUrl(piexif.insert(exifStr, photoUrl), name);
        return result;
      } catch (error) {
        throw new PhotoUtilsExecuteError();
      } finally {
        photo = null;
        exifObj = null;
      }
    },
  };
}

/**
 *
 */
export default photoUtilsCore;

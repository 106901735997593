import { isObjectDiff } from '~/utils';

const initPageInfoState = {
  navigatorType: 'menu',
  prevPage: null,
};

const pageInfoReducer = (state = initPageInfoState, action) => {
  switch (action?.type) {
    case 'setPageInfo': {
      const nextState = {
        ...state,
        ...action.payload,
      };
      return isObjectDiff(state, nextState) ? nextState : state;
    }
    case 'setPageInfoPrevPage': {
      return state.prevPage !== action.payload
        ? {
            ...state,
            prevPage: action.payload,
          }
        : state;
    }
    case 'resetPageInfo': {
      return isObjectDiff(state, initPageInfoState) ? { ...initPageInfoState } : state;
    }
    default:
      return state;
  }
};

export default pageInfoReducer;

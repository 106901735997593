import React, { Fragment, useState } from 'react';
import booleanClockwise from '@turf/boolean-clockwise';
import { lineString } from '@turf/helpers';
import PolygonViewer from './PolygonViewer';

const earthPolygon = [
  { lat: -85, lng: -180 },
  { lat: 85, lng: -180 },
  { lat: 85, lng: 180 },
  { lat: -85, lng: 180 },
  { lat: -85, lng: 0 },
];

const Zones = ({ layers, visible, onPinCancel }) => {
  // const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [visibleKey, setVisibleKey] = useState();

  const onLayerClick = key => {
    setVisibleKey(key);
    typeof onPinCancel === 'function' && onPinCancel();
  };

  const handleOnInfoCloseClick = () => {
    setVisibleKey();
  };

  return (
    <Fragment>
      {Object.entries(layers)?.map(([key, layer]) =>
        key !== 'OPERATION'
          ? layer?.map(({ polygonsPath, title, desc, id, ...layer }) =>
              polygonsPath?.map((path, i) => (
                <PolygonViewer
                  isInfoVisible={`${id}-${i}` === visibleKey}
                  visible={visible}
                  name={title}
                  desc={desc}
                  key={`${id}-${i}`}
                  paths={path}
                  {...layer}
                  onInfoCloseClick={handleOnInfoCloseClick}
                  onPolygonClick={() => onLayerClick(`${id}-${i}`)}
                />
              ))
            )
          : null
      )}
      {layers?.OPERATION?.length > 0 &&
        layers?.OPERATION?.map(({ id, polygonsPath, ...layers }, i) => (
          <PolygonViewer
            hasInfo={false}
            zIndex={0}
            isInfoVisible={false}
            key={`${id}-${i}`}
            {...layers}
            polygonsFilledColorHex="#000000"
            polygonsFilledColorOpacity={0.12}
            paths={[
              earthPolygon,
              ...polygonsPath.map(path =>
                !booleanClockwise(lineString(path.map(({ lat, lng }) => [lng, lat])))
                  ? path
                  : path.reverse()
              ),
            ]}
            onPolygonClick={onPinCancel}
          />
        ))}
    </Fragment>
  );
};

export default React.memo(Zones);

import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as MenuSvg } from 'Images/icons/menu.svg';
import Nav from 'Components/molecules/Nav';
import { useTransition, animated } from 'react-spring';
import { useHistory } from 'react-router-dom';

const NavigationBar = styled.div`
  width: 100%;
  height: 44px;
  background: ${themeGet('colors.white')};
  position: fixed;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  ${space};
`;

const NavigationBarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
`;

const NavigationBarPanel = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const NavigationButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  user-select: none;
  background: transparent;
  height: 100%;
  width: 45px;
  margin-left: -10px;
`;

const NavigationTitle = styled.h1`
  ${themeGet('fonts.button2')}
  margin: 0;
  line-height: 44px;
  text-align: center;
  flex: 0 0 auto;
`;

const NavWrapper = styled(animated.div)`
  position: fixed;
  top: 0;
  z-index: 3;
  height: 100%;
`;

const MenuMask = styled(animated.div)`
  background-color: rgba(26, 26, 26, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  -webkit-tap-highlight-color: transparent;
`;

const GoBackButton = styled(NavigationButton)`
  width: auto;
  height: auto;
  color: ${themeGet('colors.mainDark')};
  border-radius: 15px;
  border: 1px solid ${themeGet('colors.secondaryGrey')};
  box-sizing: border-box;
  padding: 2px 8px;
  margin-left: 0px;
  font-weight: bold;
  font-size: 16px;
`;

const Menu = props => {
  const { isMenuOpen, user, onClose } = props;

  const navTransition = useTransition(isMenuOpen, null, {
    from: { transform: 'translate3d(-100%,0,0)' },
    enter: { transform: 'translate3d(0%,0,0)' },
    leave: { transform: 'translate3d(-100%,0,0)' },
  });

  const maskTransition = useTransition(isMenuOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <React.Fragment>
      {maskTransition.map(
        ({ item, key, props }) => item && <MenuMask key={key} style={props} onClick={onClose} />
      )}
      {navTransition.map(
        ({ item, key, props }) =>
          item && (
            <NavWrapper key={key} style={props}>
              <Nav user={user} onClick={onClose} />
            </NavWrapper>
          )
      )}
    </React.Fragment>
  );
};

const PortalMenu = props => {
  const { ...otherProps } = props;
  return ReactDOM.createPortal(<Menu {...otherProps} />, document.body);
};

export const PureNavigationBar = props => {
  const { user, pageInfo, ...otherProps } = props;
  const { navigatorType, prevPage } = pageInfo;
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOnNavigatorClick = useCallback(() => {
    if (navigatorType === 'goBack' && prevPage) {
      if (Array.isArray(prevPage)) history.replace(...prevPage);
      else history.replace(prevPage);
    } else if (user.idcardId) {
      setIsMenuOpen(true);
    }
  }, [navigatorType, prevPage, user.idcardId]);

  const handleOnCloseClick = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <NavigationBar {...otherProps}>
      <NavigationBarWrapper>
        <NavigationBarPanel>
          {navigatorType === 'menu' ? (
            <NavigationButton onClick={handleOnNavigatorClick}>
              <MenuSvg />
            </NavigationButton>
          ) : (
            <GoBackButton onClick={handleOnNavigatorClick}>上一頁</GoBackButton>
          )}
        </NavigationBarPanel>
        <NavigationTitle></NavigationTitle>
        <NavigationBarPanel></NavigationBarPanel>
      </NavigationBarWrapper>
      <PortalMenu user={user} isMenuOpen={isMenuOpen} onClose={handleOnCloseClick} />
    </NavigationBar>
  );
};

PureNavigationBar.defaultProps = {
  pageInfo: {
    navigatorType: 'menu',
  },
};

PureNavigationBar.propTypes = {
  user: PropTypes.object,
  pageInfo: PropTypes.shape({
    navigatorType: PropTypes.oneOf(['menu', 'goBack']).isRequired,
    prevPage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf([PropTypes.string, PropTypes.object]),
    ]),
  }),
};

export default React.memo(PureNavigationBar);

// TODO Add a model to initialize all states?
import { rentStatus, scooterStatusMap } from '~/configs';
import { isObjectDiff } from '~/utils';

const initRentingState = {
  userMode: rentStatus.none,
  scooterMode: scooterStatusMap[0],
  rentId: null,
  location: null,
  rawDirections: null,
  scooter: {
    isLoading: false,
    time: null,
    batPercent: null,
    lat: null,
    lng: null,
    scooterGroupId: null,
    distanceRemaining: null,
    distances: null,
    zones: {},
    pricingPlanId: null,
    timePlanId: null,
    scooterId: null,
    parkingLot: null,
    parkingSpaceId: null,
    styles: { pin: null },
    styleId: null,
    pricing: { pricingPlans: [], timePlans: [] },
    availableDistance: null,
    leftReservationTime: null,
  },
  parkingLot: {
    isLoading: false,
    id: null,
    lat: null,
    lng: null,
    name: null,
    description: null,
    isAvailable: null,
    businessHours: null,
    address: null,
    phone: null,
    totalSpaces: null,
    availableSpaces: null,
    images: {
      covers: [],
      maps: [], // 停車場平面圖
    },
    scooters: [],
    openType: null, // automatic, parkingBox
    info: {
      isInParkingLot: null,
      isAroundParkingLot: null,
      parkingLotId: null,
      inParkingBoxStatus: null, // available, heartbeatTimeout
      outParkingBoxStatus: null,
    },
  },
  mapParkingLot: {
    id: null,
    lat: null,
    lng: null,
    name: null,
    nickname: null,
    description: null,
    isAvailable: null,
    businessHours: null,
    address: null,
    phone: null,
    totalSpaces: null,
    availableSpaces: null,
    images: {
      covers: [],
      maps: [], // 停車場平面圖
    },
    scooters: [],
    openType: null, // automatic, parkingBox
  },
  mapAllParkingLots: [],
  directions: null,
  exceptionReturn: {
    count: 0,
    lastTime: null,
  },
};

const rentingReducer = (state, action) => {
  switch (action?.type) {
    case 'setRenting':
      return {
        ...state,
        ...action.payload,
      };
    case 'setScooter':
      return {
        ...state,
        scooter: { ...state.scooter, ...action.payload },
      };
    case 'updateScooterMode':
      return {
        ...state,
        scooterMode: action.scooterMode,
      };
    case 'setParkingLot':
      return {
        ...state,
        parkingLot: { ...state.parkingLot, ...action.payload },
      };
    case 'setMapParkingLot':
      return {
        ...state,
        mapParkingLot: {
          ...state.mapParkingLot,
          ...action.payload,
        },
      };
    case 'setDirections':
      return {
        ...state,
        directions: action.payload,
      };
    case 'setExceptionReturn':
      return {
        ...state,
        exceptionReturn: action.payload,
      };
    case 'setMapAllParkingLots':
      return {
        ...state,
        mapAllParkingLots: [...action.payload],
      };
    case 'resetMapAllParkingLots':
      return {
        ...state,
        mapAllParkingLots: {
          ...initRentingState.mapAllParkingLots,
        },
      };
    case 'resetScooter':
      return {
        ...state,
        scooter: {
          ...initRentingState.scooter,
        },
      };
    case 'resetParkingLot':
      return {
        ...state,
        parkingLot: {
          ...initRentingState.parkingLot,
        },
      };
    case 'resetMapParkingLot':
      return {
        ...state,
        mapParkingLot: {
          ...initRentingState.mapParkingLot,
        },
      };
    case 'resetExceptionReturn':
      return {
        ...state,
        exceptionReturn: { ...initRentingState.exceptionReturn },
      };

    case 'resetRenting':
      return {
        ...initRentingState,
        mapAllParkingLots: state.mapAllParkingLots, // 地圖用不該隨 renting 狀態改變清掉
        location: state.location,
      };
    case 'resetScooterSinceTime':
      return {
        ...state,
        sinceTime: Date.now(),
      };
    default:
      return state;
  }
};

export default (state = initRentingState, action) => {
  const nextState = rentingReducer(state, action);
  return isObjectDiff(state, nextState) ? nextState : state;
};

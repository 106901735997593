import React from 'react';
import PropTypes from 'prop-types';

export const PureOption = props => {
  const { children, value, title } = props;
  return <option value={value}>{children || title || value}</option>;
};

PureOption.propTypes = {
  value: PropTypes.any.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const InvisibleOption = React.memo(() => {
  return <option style={{ display: 'none' }} />;
});

export default React.memo(PureOption);

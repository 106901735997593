import React, { Fragment } from 'react';
import { Offline, Online } from 'react-detect-offline';
import ErrorCase from '~/components/organisms/ErrorCase';

const ConnectionDetector = ({ children }) => (
  <Fragment>
    <Online>{children}</Online>
    <Offline>
      <ErrorCase title="網路異常" description="請確認裝置網路是否開啟" />
    </Offline>
  </Fragment>
);

export default ConnectionDetector;

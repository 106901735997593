import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '~/components/atoms/Button';

export const PurePicker = props => {
  const { onClick, onChange, accept, ...otherProps } = props;
  const inputEl = useRef();

  const handleOnClick = useCallback(
    event => {
      const input = document.createElement('input');
      inputEl.current = input;
      const handleOnPick = event => {
        const {
          target: { files },
        } = event;
        const file = files[0];
        onChange(file);
        input.removeEventListener('change', handleOnPick);
        document.body.removeChild(input);
        inputEl.current = null;
      };
      input.type = 'file';
      input.accept = accept;
      input.style.height = '0px';
      input.style.width = '0px';
      input.style.position = 'absolute';
      input.style.top = 0;
      input.style.left = 0;
      input.addEventListener('change', handleOnPick);
      document.body.appendChild(input);
      input.click();
      onClick(event);
    },
    [accept, onClick, onChange]
  );

  useEffect(() => {
    return () => {
      if (inputEl.current) {
        document.body.removeChild(inputEl.current);
      }
    };
  }, []);

  return <Button {...otherProps} onClick={handleOnClick} />;
};

PurePicker.defaultProps = {
  onClick: () => {},
  onChange: () => {},
};

PurePicker.propTypes = {
  accept: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

export default React.memo(PurePicker);

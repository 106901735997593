import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import Button from '~/components/atoms/Button';

const ErrorCaseWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorCasePanel = styled.div`
  max-width: 287px;
  text-align: center;
`;

const ErrorCaseTitle = styled.h3`
  font-size: 18pt;
  font-weight: 600;
  color: #111111;
  line-height: 1;
  margin: 0;
  margin-bottom: 6px;
`;

const ErrorCaseDescription = styled.div`
  font-size: 14pt;
  color: #949494;
  line-height: 2;
`;

const ErrorCaseButton = styled(Button)`
  height: auto;
  color: #000000;
  font-size: 13pt;
  font-weight: bold;
  line-height: 1;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  background: transparent;
  padding: 12px 20px;
  margin-top: 21px;
`;

export const PureErrorCase = props => {
  const { title, description, buttonText, onClick, bodyScroll } = props;
  const wrapperEl = useRef();

  useEffect(() => {
    if (wrapperEl.current) {
      if (bodyScroll) enablePageScroll(wrapperEl.current);
      else disablePageScroll(wrapperEl.current);
    }
  }, [bodyScroll]);

  useEffect(() => {
    const wrapper = wrapperEl.current;
    return () => {
      wrapper && enablePageScroll(wrapper);
    };
  }, []);

  return (
    <ErrorCaseWrapper ref={wrapperEl}>
      <ErrorCasePanel>
        {title && <ErrorCaseTitle>{title}</ErrorCaseTitle>}
        <ErrorCaseDescription>{description}</ErrorCaseDescription>
        {buttonText && <ErrorCaseButton onClick={onClick}>{buttonText}</ErrorCaseButton>}
      </ErrorCasePanel>
    </ErrorCaseWrapper>
  );
};

PureErrorCase.defaultProps = {
  onClick: () => {},
  bodyScroll: false,
};

PureErrorCase.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  bodyScroll: PropTypes.bool,
};

export default React.memo(PureErrorCase);

import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Popup from '~/components/atoms/Popup';
import Button from '~/components/atoms/Button';
import { useDebouncedCallback } from 'use-debounce';

const Title = styled.p`
  ${themeGet('fonts.subtitle')}
  margin-top: 0;
  margin-bottom: 4px;
  text-align: center;
`;

const Content = styled.p`
  ${themeGet('fonts.body2')}
  color: ${themeGet('colors.secondaryGrey')};
  text-align: center;
`;

const Footer = styled.div`
  width: 100%;
  margin-top: 36px;
  text-align: center;
`;

const BackButton = styled(Button)`
  width: 240px;
`;

export const PureBackToLine = props => {
  const { visible, title, content, progress, progressText, url, zindex, onClick } = props;

  const [handleOnClick] = useDebouncedCallback(
    () => {
      if (typeof onClick === 'function') {
        onClick();
      } else {
        if (url) window.location.href = url;
      }
    },
    300,
    { leading: true, trailing: false }
  );

  return (
    <Popup visible={visible} zindex={zindex}>
      <Title>{title}</Title>
      {content && <Content>{content}</Content>}
      {progress !== undefined && (
        <Content>
          {progressText ? `${progressText}: ` : ''}
          {Math.floor(progress)}%
        </Content>
      )}
      <Footer>
        <BackButton
          boxShadow="light"
          type="float"
          shape="capsule"
          size="small"
          disabled={!(progress === 100 || progress === undefined) || !(url || onClick)}
          onClick={handleOnClick}
        >
          回到 WeMo
        </BackButton>
      </Footer>
    </Popup>
  );
};

PureBackToLine.defaultProps = {
  zindex: 7,
};

PureBackToLine.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  progress: PropTypes.number,
  progressText: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  zindex: PropTypes.number,
};

const BackToLine = React.memo(PureBackToLine);
BackToLine.whyDidYouRender = {
  customName: 'BackToLine',
};
export default BackToLine;

const initUseBackToLine = initialState => ({
  title: initialState.title || '處理完成',
  visible: initialState.visible || false,
  progress: initialState.progress || undefined,
  progressText: initialState.progressText || '目前進度',
});

const reducerFuncs = (state, payload) => {
  return {
    setState() {
      return { ...state, ...payload };
    },
    switchVisible() {
      const { title } = payload || {};
      return { ...state, visible: !state.visible, title: title || state.title };
    },
    setProgress() {
      return { ...state, progress: payload || 0 };
    },
    resetProgress() {
      const { title } = payload || {};
      return { ...state, progress: 0, title: title || state.title };
    },
    reset() {
      return initUseBackToLine(payload || {});
    },
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;
  const func = reducerFuncs(state, payload);
  return (func[type] || (() => state))();
};

export const useBackToLine = (initialState = {}) => {
  const [state, dispatch] = useReducer(reducer, initialState, initUseBackToLine);
  const actions = useMemo(
    () => ({
      ...Object.keys(reducerFuncs()).reduce((acc, funcKey) => {
        acc[funcKey] = payload => {
          dispatch({ type: funcKey, payload });
        };
        return acc;
      }, {}),
    }),
    []
  );

  return [state, actions];
};

import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { space, color, position } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import SwipeableViews from 'react-swipeable-views';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import BasicContainer from 'Components/layouts/BasicContainer';
import PaginationDots from 'Components/atoms/PaginationDots';
import Button from 'Components/atoms/Button';
import { userStatus } from '~/configs';
import { canUserRent } from '~/utils';
import PeopleWithCityPng from 'Images/people-with-city.png';
import IdentityConfirmPng from 'Images/identity-confirm.png';
import WatchingPeoplePng from 'Images/watching-people.png';
import PeopleLookEachOtherPng from 'Images/people-look-each-other.png';
import InviteFriend from 'Images/invite-friend.png';

const Content = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  ${themeGet('fonts.h1')};
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
`;

const Img = styled.img`
  ${space};
  /* margin: 10px 0 4px; */
`;

const SubTitle = styled.p`
  ${themeGet('fonts.body')};
  margin: 0;
  margin-bottom: 4px;
  text-align: center;
  ${space};
`;

const Text = styled.p`
  ${themeGet('fonts.body2')};
  margin: 0;
  margin-bottom: 4px;
  text-align: left;
  ${space};
`;

const Note = styled.p`
  ${themeGet('fonts.note')};
  color: ${themeGet('colors.mainDark')};
  margin: 0;
  margin-top: 4px;
  text-align: left;
  ${space};
`;

const BoldText = styled.span`
  ${themeGet('fonts.body2')};
  font-weight: bold;
  margin: 0;
  ${space};
`;

const Section = styled.div`
  position: relative;
  padding: 30px 20px 10px;
  width: 100%;
  margin: 40px 0 10px;
  ${color};

  &::before {
    content: url(${props => props.img});
    position: absolute;
    ${position};
  }
`;

const Footer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const FooterButton = styled(Button)`
  width: 240px;
`;

export const PureWelcome = props => {
  const history = useHistory();
  const { location } = history;
  const { state } = location;
  const [status, setStatus] = useState(userStatus.pending);
  const [swipeIndex, setSwipeIndex] = useState(0);

  const handleChangeIndex = useCallback(index => {
    setSwipeIndex(index);
  }, []);

  const [handleOnClick] = useDebouncedCallback(
    () => {
      if (status === userStatus.reject) {
        history.replace('/landing-page');
      } else {
        history.replace('/');
      }
    },
    300,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    if (state) {
      setStatus(state.userStatus);
    }
  }, []);

  return (
    <BasicContainer navigationBar={false}>
      <SwipeableViews enableMouseEvents={true} index={swipeIndex} onChangeIndex={handleChangeIndex}>
        {canUserRent(status) ? (
          <Content>
            <Title>你可以用 WeMo Scooter 了！</Title>
            <Img src={PeopleWithCityPng} />
            <Text mb="30px">
              我們會盡快覆核你的使用資格，如有違反法律或本服務之規範等情形發生，本公司有權暫停或終止您的使用資格。
            </Text>
            <Section
              bg="uselessGreen"
              img={IdentityConfirmPng}
              top="-29.5px"
              left="calc(50% - 50.5px)"
            >
              <SubTitle>綁定信用卡/簽帳金融卡拿更多回饋！</SubTitle>
              <Text>
                <BoldText>新會員預設使用"Line Pay"付款</BoldText>
                ，可至「付款設定」頁面，改用信用卡或簽帳金融卡付款！
              </Text>
            </Section>
          </Content>
        ) : (
          <Content>
            <Title>完成註冊流程！你的帳號正在審核中</Title>
            <Img src={WatchingPeoplePng} />
            <Text>
              我們會盡快與主管機關確認您的駕駛資格，待確認資格後會以 Email 及 App
              推播通知您審核結果，請您耐心等候。
            </Text>
            <Section
              bg="uselessGreen"
              img={IdentityConfirmPng}
              top="-29.5px"
              left="calc(50% - 50.5px)"
            >
              <SubTitle>綁定信用卡/簽帳金融卡拿更多回饋！</SubTitle>
              <Text>
                <BoldText>新會員預設使用"Line Pay"付款</BoldText>
                ，可至「付款設定」頁面，改用信用卡或簽帳金融卡付款！
              </Text>
            </Section>
          </Content>
        )}
        <Content>
          <Title>兩大優惠，不可不知</Title>
          <Section bg="uselessGreen" img={InviteFriend} top="-33px" left="calc(50% - 25.5px)">
            <SubTitle>新朋友免費騎</SubTitle>
            <Text>當您通過會員審核後，即可獲得騎乘金30。在三天內完成首騎，再送你騎乘金30！</Text>
            <Note>（註：騎乘金均於收到後三天內失效，可至「付款設定 - 騎乘金明細」中查看）</Note>
          </Section>
          <Section
            bg="uselessBlue"
            img={PeopleLookEachOtherPng}
            top="-33px"
            left="calc(50% - 25px)"
          >
            <SubTitle>邀請朋友加入一起拿免費騎乘金！</SubTitle>
            <Text>
              透過「邀請朋友」頁面分享你的專屬邀請碼，好友註冊輸入並完成首騎，您就可獲得騎乘金50！
            </Text>
            <Note>（註：騎乘金均於收到後三天內失效，可至「付款設定 - 騎乘金明細」中查看）</Note>
          </Section>
        </Content>
      </SwipeableViews>
      <PaginationDots count={2} index={swipeIndex} onIndexChange={handleChangeIndex} />
      <Footer>
        <FooterButton boxShadow="light" shape="capsule" type="float" onClick={handleOnClick}>
          {canUserRent(status) ? '開始騎車' : '我知道了'}
        </FooterButton>
      </Footer>
    </BasicContainer>
  );
};

PureWelcome.defaultProps = {};

PureWelcome.propTypes = {};

export default React.memo(PureWelcome);

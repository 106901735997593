import React, { createContext, useReducer } from 'react';
import rentingReducer from '../reducers/rentingReducer.js';

const RentingContext = createContext();

const RentingProvider = ({ children }) => {
  const reducers = useReducer(rentingReducer, rentingReducer());
  // console.log('RentingProvider');

  return <RentingContext.Provider value={reducers}>{children}</RentingContext.Provider>;
};

export { RentingProvider, RentingContext };

import React, { useState } from 'react';
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import Button from 'Components/atoms/Button';
import PageHeader from 'Components/molecules/PageHeader';
import CardLicense from 'Components/molecules/CardLicense';
import Checkbox from 'Components/atoms/Checkbox';

const ButtonStyled = styled(Button)`
  margin: 0 auto 40px;
  display: block;
`;

const Promotion = styled.p`
  ${props => props.theme.fonts.caption};
  color: ${props => props.theme.colors.mainGreen};
  text-align: center;
`;

const Confirm = styled.div`
  ${props => props.theme.fonts.caption}
  text-align:center;
  margin: 26px;
`;

const CardLicenseStyled = styled(CardLicense)`
  ${space}
  ${layout}
`;

const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.mainBlue};
`;

const SignUpNote = ({ onConfirm }) => {
  const [isConfirm, setIsConfirm] = useState(false);
  const onCheckboxChange = evt => {
    setIsConfirm(evt.target.value);
  };

  const openExternalBrowser = url => {
    if (window.liff && url) {
      window.liff.openWindow({
        url,
        external: true,
      });
    }
  };

  return (
    <div>
      <PageHeader title="簡單2步驟完成註冊" subtitle={'註冊前請先準備好'}></PageHeader>
      <CardLicenseStyled
        key="1"
        label="本國籍"
        margin={['45px auto 0', '60px auto 0']}
        width={'calc(100% - 20px)'}
        licenses={['身分證', '機車 / 汽車駕照']}
        hint="(過期也可使用)"
      />
      <CardLicenseStyled
        key="2"
        margin={['45px auto 0', '60px auto 0']}
        width={'calc(100% - 20px)'}
        label="外國籍"
        licenses={['護照', '國際駕照']}
      />
      <Promotion>現在註冊，再送你免費３０元騎乘金！</Promotion>
      <Confirm>
        <Checkbox
          label={
            <span>
              我暸解並同意
              <Link
                onClick={() =>
                  openExternalBrowser(
                    'https://storage.googleapis.com/wemo-config/terms-and-conditions/index.html'
                  )
                }
              >
                使用者條款
              </Link>
              和
              <Link
                onClick={() => openExternalBrowser('https://www.wemoscooter.com/privacy-policy')}
              >
                隱私權條款
              </Link>
            </span>
          }
          onChange={onCheckboxChange}
        ></Checkbox>
      </Confirm>
      <ButtonStyled
        width={64 / 100}
        shape="capsule"
        boxShadow="light"
        type="float"
        disabled={!isConfirm}
        onClick={onConfirm}
      >
        下一步
      </ButtonStyled>
    </div>
  );
};

SignUpNote.defaultProps = {};

SignUpNote.propTypes = {};

export default React.memo(SignUpNote);

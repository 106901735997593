export const underlineToCamelCase = (target = {}) => {
  if (typeof target !== 'object') return target;

  if (target !== null && typeof target === 'object') {
    return Object.entries(target).reduce((result, [name, value]) => {
      const newPropName = name.replace(/_(\w)/g, (fullleMatch, letter) =>
        letter.toLocaleUpperCase()
      );

      if (value !== null && typeof value === 'object') {
        if (Array.isArray(value)) {
          let newArray = [];

          for (let index in value) {
            const newValue = underlineToCamelCase(value[index]);
            newArray.push(newValue);
          }
          result[newPropName] = newArray;
        } else {
          result[newPropName] = underlineToCamelCase(value);
        }
      } else {
        result[newPropName] = value;
      }

      return result;
    }, {});
  }
};

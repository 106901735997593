import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import imageCompression from 'browser-image-compression';
import Picker from '~/components/atoms/Picker';

const CameraAlternativeWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: black;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const CameraAlternativePanel = styled.div`
  width: 100%;
`;

const InfoPanel = styled.div`
  width: 100%;
`;

const PickerPanel = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;

export const PureCameraAlternative = props => {
  const { info, onChange } = props;

  const handleOnChange = useCallback(
    async photo => {
      const url = await imageCompression.getDataUrlFromFile(photo);
      onChange(photo, url);
    },
    [onChange]
  );

  return (
    <CameraAlternativeWrapper>
      <CameraAlternativePanel>
        <InfoPanel>{info}</InfoPanel>
        <PickerPanel>
          <Picker
            accept="image/*"
            type="float"
            shape="capsule"
            boxShadow="light"
            onChange={handleOnChange}
          >
            選取照片
          </Picker>
        </PickerPanel>
      </CameraAlternativePanel>
    </CameraAlternativeWrapper>
  );
};

PureCameraAlternative.propTypes = {
  info: PropTypes.element,
  onChange: PropTypes.func,
};

export default PureCameraAlternative;

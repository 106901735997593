import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { paymentTypes } from '~/configs';
import CreditCardInfo from '~/components/atoms/CreditCardInfo';
import WeMoWalletInfo from '~/components/atoms/WeMoWalletInfo';
import { ReactComponent as LinePay } from '~/images/icons/line-pay.svg';
import { ReactComponent as ApplePay } from '~/images/icons/apple-pay.svg';

const LinePayIcon = styled.img.attrs(props => ({
  as: LinePay,
}))``;

const ApplePayIcon = styled.img.attrs(props => ({
  as: ApplePay,
}))``;

export const PurePaymentType = props => {
  const { paymentType, walletBalance, creditCard } = props;

  switch (paymentType) {
    case paymentTypes.creditCard:
      return <CreditCardInfo type={creditCard.type} last4Digits={creditCard.last4Digits} />;

    case paymentTypes.wallet:
      return <WeMoWalletInfo balance={walletBalance} />;

    case paymentTypes.linePay:
      return <LinePayIcon />;

    case paymentTypes.applePay:
      return <ApplePayIcon />;

    default:
      return <React.Fragment />;
  }
};

PurePaymentType.defaultProps = {
  walletBalance: 0,
  creditCard: {
    type: '-1',
  },
};

PurePaymentType.propTypes = {
  paymentType: PropTypes.oneOf(Object.values(paymentTypes)),
  walletBalance: PropTypes.number,
  creditCard: PropTypes.shape({
    type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([
        'VISA',
        'Mastercard',
        'JCB',
        'UnionPay',
        'American Express',
        '-1',
        '1',
        '2',
        '3',
        '4',
        '5',
      ]),
    ]),
    last4Digits: PropTypes.string,
  }),
};

export default React.memo(PurePaymentType);

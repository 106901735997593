import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, layout, position, compose } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import css from '@styled-system/css';
import { useSpring, animated } from 'react-spring';
import { useDebouncedCallback } from 'use-debounce';
import SwipeableViews from 'react-swipeable-views';
import TabPane from './TabPane';

const Container = styled.div`
  ${compose(space, layout)}
`;

const TabNav = styled.div`
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  position: relative;
  ${space};
  border-bottom: 1px solid ${themeGet('colors.unavailableGrey')};
`;

const TabList = styled.div`
  display: inline-block;
  min-width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: none;
`;

const Tab = styled.div`
  ${themeGet('fonts.body')}
  color: ${({ color }) => themeGet(`colors.${color}`)};
  display: inline-block;
  text-align: center;
  box-sizing: border-box;
  padding: 3px 0;
  margin-left: 20px;
  ${layout}
  ${props => css(props.css)}

  &:first-child {
    margin-left: 0;
  }
`;

const Content = styled.div`
  position: relative;
  ${props => css(props.css)}
`;

const TabInk = styled(animated.div)`
  height: 2px;
  background: ${themeGet('colors.mainBlue')};
  position: absolute;
  bottom: 0;
  border: 0;
  z-index: 1;
  padding: 0;
  margin: 0;
  border: none;
  ${compose(position, layout)}
`;

export const PureTabs = props => {
  const { children, onChange, defaultActiveIndex } = props;
  const tabListEl = useRef();
  const tabsBound = useRef(new Array(children.length));
  const tabsValue = useRef(new Array(children.length));
  const [inkSpring, set] = useSpring(() => ({
    transform: `translate3d(0px, 0px, 0px)`,
    width: 0,
  }));
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);

  const [handleActiveIndexOnChange] = useDebouncedCallback(
    index => {
      setActiveIndex(index);
    },
    300,
    { leading: true, trailing: false }
  );

  const bindTabWidth = useCallback((node, index) => {
    if (!node) return;
    const { left, width } = node.getBoundingClientRect();
    tabsBound.current[index] = { left, width };
  }, []);

  useEffect(() => {
    const { left: offsetLeft } = tabListEl.current.getBoundingClientRect();
    const tabBound = tabsBound.current[activeIndex];
    if (tabBound) {
      set({
        transform: `translate3d(${tabBound.left - offsetLeft}px, 0px, 0px)`,
        width: tabBound.width,
      });
    }
    onChange(tabsValue.current[activeIndex], activeIndex);
  }, [activeIndex]);

  return (
    <Container mx={['-10px', '-20px']} width={['calc(100% + 20px)', 'calc(100% + 40px)']}>
      <TabNav px={['10px', '20px']}>
        <TabInk left={['10px', '20px']} style={inkSpring} />
        <TabList ref={tabListEl}>
          {React.Children.map(children, (child, index) => {
            if (!child.type === TabPane) return;
            const { tab, value, tabStyle } = child.props;
            tabsValue.current[index] = value;
            return (
              <Tab
                ref={node => bindTabWidth(node, index)}
                css={tabStyle}
                color={activeIndex === index ? 'mainDark' : 'supGrey'}
                onClick={() => handleActiveIndexOnChange(index)}
              >
                {tab}
              </Tab>
            );
          })}
        </TabList>
      </TabNav>
      <SwipeableViews
        enableMouseEvents={true}
        index={activeIndex}
        onChangeIndex={handleActiveIndexOnChange}
      >
        {React.Children.map(children, child => {
          const { contentStyle } = child.props;
          if (child.type === TabPane) return <Content css={contentStyle}>{child}</Content>;
        })}
      </SwipeableViews>
    </Container>
  );
};

PureTabs.defaultProps = {
  defaultActiveIndex: 0,
  onChange: () => {},
};

PureTabs.propTypes = {
  defaultActiveIndex: PropTypes.number,
  onChange: PropTypes.func,
};

const Tabs = React.memo(PureTabs);
Tabs.TabPane = TabPane;
export default Tabs;

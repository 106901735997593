import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { space } from 'styled-system';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import Card from 'Components/atoms/Card';
import { useDebouncedCallback } from 'use-debounce';

const PopupContainerStyled = styled.div``;

const Mask = styled(animated.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: ${props => props.zindex};
  user-select: none;
  ${({ mode }) =>
    mode === 'dark'
      ? css`
          background-color: rgba(26, 26, 26, 0.4);
        `
      : css`
          background-color: transparent;
        `}
`;

const CardStyled = styled(Card)`
  width: ${({ width }) =>
    width
      ? typeof width === 'number'
        ? `${width}px`
        : width
      : 'calc(100% - (100% * 0.08533333333 * 2))'};
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  z-index: ${props => props.zindex};
  transform: translate3d(0, -50%, 0);
  ${({ mode }) =>
    mode === 'light' &&
    css`
      background-color: #555555;
      opacity: 0.85;
      border: none;
      box-shadow: none;
    `}
  ${space}
`;

const cardPadding = ['18px;', '36px'];

export const PurePopup = props => {
  const {
    visible,
    maskClosable,
    onClose,
    children,
    zindex,
    mode,
    width,
    className,
    onDestroyed,
  } = props;
  const containerEl = useRef();
  const [destoryedCount, setDestoryedCount] = useState(0);

  const cardTransition = useTransition(visible, null, {
    from: {
      opacity: 0,
      transform: 'translate3d(0, -60%, 0)',
    },
    enter: { opacity: 1, transform: 'translate3d(0,-50%,0)' },
    leave: {
      opacity: 0,
      transform: 'translate3d(0,-60%,0)',
    },
    onDestroyed(isDestroyed) {
      isDestroyed && setDestoryedCount(prev => prev + 1);
    },
  });

  const maskTransition = useTransition(visible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    onDestroyed(isDestroyed) {
      isDestroyed && setDestoryedCount(prev => prev + 1);
    },
  });

  const [handleOnClick, cancelHandleOnClick] = useDebouncedCallback(
    () => {
      maskClosable && typeof onClose === 'function' && onClose();
    },
    300,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    if (destoryedCount === 2) {
      typeof onDestroyed === 'function' && onDestroyed();
    }
  }, [destoryedCount]);

  useEffect(() => {
    () => {
      cancelHandleOnClick();
    };
  }, []);

  return (
    <PopupContainerStyled ref={containerEl}>
      {maskTransition.map(
        ({ item, key, props }) =>
          item && (
            <Mask
              key={key}
              style={props}
              onClick={handleOnClick}
              zindex={zindex}
              mode={mode}
            ></Mask>
          )
      )}
      {cardTransition.map(
        ({ item, key, props }) =>
          item && (
            <CardStyled
              className={className}
              padding={cardPadding}
              boxShadow="dark"
              key={key}
              style={props}
              zindex={zindex}
              mode={mode}
              width={width}
            >
              {children}
            </CardStyled>
          )
      )}
    </PopupContainerStyled>
  );
};

PurePopup.defaultProps = {
  visible: false,
  maskClosable: false,
  getContainer: () => document.body,
  zindex: 4,
  mode: 'dark',
};

PurePopup.propTypes = {
  maskClosable: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  getContainer: PropTypes.elementType,
  zindex: PropTypes.number,
  mode: PropTypes.oneOf(['dark', 'light']),
  width: PropTypes.number,
  onDestroyed: PropTypes.func,
};

const PortalPopup = props => {
  const { getContainer, ...otherProps } = props;

  return ReactDOM.createPortal(
    <PurePopup {...otherProps} />,
    getContainer ? getContainer() || document.body : document.body
  );
};

PortalPopup.defaultProps = PurePopup.defaultProps;
PortalPopup.propTypes = PurePopup.propTypes;

export default React.memo(PortalPopup);

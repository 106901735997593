import React from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskInput from './MaskInput';

const numberMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
});

const InuptValuePipe = conformedValue => {
  return /\d/g.test(conformedValue) || conformedValue === '_' ? conformedValue : false;
};

const PureNumberInput = props => {
  const { type, mask, getInputPlaceHolder, placeholder, ...otherProps } = props;

  return (
    <MaskInput
      type="tel"
      mask={mask || numberMask}
      pipe={mask ? false : InuptValuePipe}
      {...otherProps}
      placeholder={getInputPlaceHolder(placeholder)}
    />
  );
};

export default React.memo(PureNumberInput);

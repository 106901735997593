import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Link } from 'react-router-dom';

const LinkStyled = styled(Link)`
  ${themeGet(`fonts.body`)};
  color: ${themeGet(`colors.mainBlue`)};
  position: relative;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
`;

export const PureLink = props => {
  const { children, to, replace, onClick, ...otherProps } = props;
  return (
    <LinkStyled {...otherProps} to={to} replace={replace} onClick={onClick}>
      {children}
    </LinkStyled>
  );
};

PureLink.defaultProps = {
  to: {},
  replace: true,
  onClick: () => {},
};

PureLink.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
    PropTypes.func,
    PropTypes.string,
  ]),
  replace: PropTypes.bool,
  onClick: PropTypes.func,
};

export default React.memo(PureLink);

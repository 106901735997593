import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ExpiredStorage from 'expired-storage';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { useUserInfo, useStatus } from '~/context/';
import { editUserInfoArp, postLineIDTokenVerify } from '~/services/api';
import BasicContainer from 'Components/layouts/BasicContainer';
import Steps from 'Components/atoms/Steps';
import PopupNote from 'Components/molecules/PopupNote';
import { Step } from 'Components/atoms/Steps';
import PageHeader from 'Components/molecules/PageHeader';
import Alert from 'Components/atoms/Alert';
import PupupLoading from 'Components/molecules/PopupLoading';
import FullScreenLoading from 'Components/molecules/FullScreenLoading';
import AddressForm from './AddressForm';
import PhotoForm from './PhotoForm';
import { FormContext } from './FormContext';
import { useErrorNotification } from '~/context/ErrorNotification';

const PhotoNameStorageExpiredTime = 60 * 60;
const expiredStorage = new ExpiredStorage();

const StepsStyled = styled(Steps)`
  height: 100%;
`;

// TODO :info
export const rejectReasonTypeMap = {
  1: {
    type: 'profile',
    header: '輸入個人資料',
    alertTitle: '通訊地址',
    popup: {
      title: '為什麼要輸入個人資料？',
      notes:
        '依中華民國交通部規定，請填寫與證件完全相符之資料，若資料有誤將無法通過審核。您的個人資料僅供 WeMo Scooter 內部使用。',
    },
  },
  2: {
    type: 'identity',
    header: '上傳證件照片',
    alertTitle: '身分證',

    popup: {
      title: '為什麼要拍攝證件照片？',
      notes:
        '依中華民國交通部規定，依國籍不同，須提供不同證件照片：本國籍人士須提供身份證、機車/汽車駕照正本 (過期也可使用)、自拍照供查核。外國籍人士須提供護照、國際駕照、自拍照供查核。您的證件照僅供 WeMo Scooter 內部使用。',
    },
  },
  3: {
    type: 'license',
    header: '上傳證件照片',
    alertTitle: '駕駛證件照',
    popup: {
      title: '為什麼要拍攝證件照片？',
      notes:
        '依中華民國交通部規定，依國籍不同，須提供不同證件照片：本國籍人士須提供身份證、機車/汽車駕照正本 (過期也可使用)、自拍照供查核。外國籍人士須提供護照、國際駕照、自拍照供查核。您的證件照僅供 WeMo Scooter 內部使用。',
    },
  },
  4: {
    type: 'selfie',
    header: '上傳照片',
    alertTitle: '自拍照',
    popup: {
      title: '為什麼要拍攝自拍照？',
      notes:
        '依中華民國交通部規定，依國籍不同，須提供不同證件照片：本國籍人士須提供身份證、機車/汽車駕照正本 (過期也可使用)、自拍照供查核。外國籍人士須提供護照、國際駕照、自拍照供查核。您的證件照僅供 WeMo Scooter 內部使用。',
    },
  },
};

const stepsPhotoNameKeys = {
  idCard: ['TakePositiveIdentity', 'TakeNegativeIdentity'],
  license: ['TakePositiveLicense', 'TakeNegativeLicense'],
  selfie: ['TakeFace'],
};

const typesNameMap = {
  '2': 'idCard',
  '3': 'license',
  '4': 'selfie',
};

const stepFactory = (type, desc, step, isLastStep) => {
  switch (type) {
    case '1':
      return <AddressForm isLastStep={isLastStep} />;
    case '2':
      return <PhotoForm isLastStep={isLastStep} typeName={typesNameMap[type]} step={step} />;
    case '3':
      return <PhotoForm isLastStep={isLastStep} typeName={typesNameMap[type]} step={step} />;
    case '4':
      return <PhotoForm isLastStep={isLastStep} typeName={typesNameMap[type]} step={step} />;
    default:
      return 1;
  }
};

const errorsConfig = {
  LINE_CANNOT_GET_USER_PROFILE: {
    popup: {
      title: 'LINE API 異常',
      notes: '無法取得 LINE 使用者資訊',
      confirmButtonText: '確認',
    },
    notifier: 'popup',
  },
  LINE_INVALID_ID_TOKEN: {
    popup: {
      title: 'LINE API 異常',
      notes: '無法驗證 LINE 使用者資訊',
      confirmButtonText: '確認',
    },
    notifier: 'popup',
  },
};

const AlertStyled = styled(Alert)`
  margin: 30px 0;
  background-color: ${props => props.theme.colors.typeSpaceGrey};
`;

const getAllPhotoNames = () =>
  JSON.parse(expiredStorage.getItem('furtherDocumentsPhotoNames') || '{}');

const FurtherDocuments = () => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [photoNames, setPhotoNames] = useState({});
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { status, getCurrentStatus } = useStatus();
  const { user, loadingUtils } = useUserInfo();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { registerErrorConfig, launchError, launchApiError } = useErrorNotification();

  const handleFormSubmit = useCallback(async () => {
    try {
      let data = {};
      const address = expiredStorage.getJson('furtherDocumentsAddress');
      const allPhotoNames = getAllPhotoNames();
      setIsLoading(true);

      if (address) data.address = address;
      if (allPhotoNames.TakePositiveIdentity && allPhotoNames.TakeNegativeIdentity)
        data.idcard = [allPhotoNames.TakePositiveIdentity, allPhotoNames.TakeNegativeIdentity];
      if (allPhotoNames.TakePositiveLicense && allPhotoNames.TakeNegativeLicense)
        data.licence = [allPhotoNames.TakePositiveLicense, allPhotoNames.TakeNegativeLicense];
      if (allPhotoNames.TakeFace) data.selfie = [allPhotoNames.TakeFace];

      await editUserInfoArp(data);
      setIsLoading(false);
      history.replace('/account');
    } catch (error) {
      setIsLoading(false);
      launchApiError(error, 'further-documents');
      throw error;
    }
  }, [history]);

  const handleOnNextClick = useCallback(() => {
    if (currentStep + 1 === status?.rejectReasons?.length) {
      handleFormSubmit();
      return;
    }

    setCurrentStep(currentStep + 1);
  }, [currentStep, handleFormSubmit, status]);

  const storePhotoNames = useCallback((photos, type) => {
    const stepPhotoNameKeys = stepsPhotoNameKeys[type];
    const photoNames = photos.reduce((acc, photo, index) => {
      acc[stepPhotoNameKeys[index]] = photo;
      return acc;
    }, {});

    const currentPhotoNames = JSON.parse(
      expiredStorage.getItem('furtherDocumentsPhotoNames') || '{}'
    );
    const updatedPhotoNames = { ...currentPhotoNames, ...photoNames };

    expiredStorage.setItem(
      'furtherDocumentsPhotoNames',
      JSON.stringify(updatedPhotoNames),
      PhotoNameStorageExpiredTime
    );

    setPhotoNames(updatedPhotoNames);
  }, []);

  const initialize = useRef(() => {});
  useEffect(() => {
    initialize.current = async () => {
      if (!isLoading && (!status.rejectReasons || status.rejectReasons?.length === 0)) {
        history.push('/account');
      } else {
        const urlQuery = qs.parse(window.location.search.slice(1));
        const { photos, token, step } = urlQuery;

        if (step === undefined && parseInt(currentStep, 10) === 0) {
          expiredStorage.removeItem('furtherDocumentsAddress');
          expiredStorage.removeItem('furtherDocumentsPhotoNames');
        } else {
          // Only android flow
          if (token) {
            const { userId } = await window.liff.getProfile().catch(error => {
              launchError({ type: 'LINE_CANNOT_GET_USER_PROFILE' }, 'further-documents');
              throw error;
            });
            await postLineIDTokenVerify(token, userId).catch(error => {
              launchError({ type: 'LINE_INVALID_ID_TOKEN' }, 'further-documents');
              throw error;
            });

            if (photos) {
              const intStep = parseInt(step, 10);
              const { type } = status?.rejectReasons[intStep];
              storePhotoNames(photos, typesNameMap[type]);
              step && currentStep !== intStep && setCurrentStep(intStep);
            }
          }
        }
      }
    };
  }, [currentStep, history, isLoading, launchError, status, storePhotoNames]);

  const getStatus = useRef(async () => {
    await getCurrentStatus();
    setIsFirstRender(false);
  });

  useEffect(() => {
    registerErrorConfig('further-documents', errorsConfig);
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      getStatus.current();
    } else {
      initialize.current();
    }
  }, [isFirstRender]);

  const formContextValue = useMemo(
    () => ({
      currentStep,
      photoNames,
      handleOnNextClick,
      storePhotoNames,
      address: user?.address,
    }),
    [currentStep, handleOnNextClick, photoNames, storePhotoNames, user]
  );

  return isFirstRender ? (
    <FullScreenLoading spinning={true} />
  ) : (
    <FormContext.Provider value={formContextValue}>
      <PupupLoading {...loadingUtils} successText="更新完成" />
      <BasicContainer base={20} autoHeight={false}>
        <StepsStyled current={currentStep}>
          {status?.rejectReasons?.map(({ type, desc }, i) => (
            <Step key={type}>
              <PageHeader
                title={rejectReasonTypeMap[type].header}
                hasInfo={true}
                infoOnClick={() => setIsPopupVisible(true)}
              ></PageHeader>
              <AlertStyled
                boxShadow="none"
                iconType="warning"
                content={desc}
                title={rejectReasonTypeMap[type].alertTitle}
              />
              {stepFactory(
                type,
                desc,
                currentStep,
                currentStep + 1 === status?.rejectReasons?.length
              )}
              <PopupNote
                {...rejectReasonTypeMap[type].popup}
                visible={isPopupVisible}
                onConfirmClick={() => setIsPopupVisible(false)}
              />
            </Step>
          ))}
        </StepsStyled>
      </BasicContainer>
    </FormContext.Provider>
  );
};

FurtherDocuments.defaultProps = {};

FurtherDocuments.propTypes = {};

export default FurtherDocuments;

import React, { useContext, Fragment, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'qs';
import Button from 'Components/atoms/Button';
import { FormContext } from '../FormContext';
import FullScreenLoading from 'Components/molecules/FullScreenLoading';
import ItemCard from 'Components/molecules/ItemCard';
import { themeGet } from '@styled-system/theme-get';
import ButtonPicture from 'Components/atoms/ButtonPicture';
import css from '@styled-system/css';
import { useDebouncedCallback } from 'use-debounce';
import SwipeUpPage from 'Components/atoms/SwipeUpPage';
import TakeRegistrationPhotosV2 from 'Components/pages/TakeRegistrationPhotosV2';
import { useErrorNotification } from '~/context/ErrorNotification';

const OS = window.liff ? window.liff.getOS() : 'android';
const uploadList = [
  {
    name: 'idCard',
    subtitle: '拍攝正反面',
    title: '身分證',
    picture: null,
    min: 0,
    max: 0,
    photoNameKeys: ['TakePositiveIdentity', 'TakeNegativeIdentity'],
  },
  {
    name: 'license',
    subtitle: '拍攝正反面',
    title: '駕照',
    picture: null,
    min: 1,
    max: 1,
    photoNameKeys: ['TakePositiveLicense', 'TakeNegativeLicense'],
  },
  {
    name: 'selfie',
    subtitle: '拍攝正面',
    title: '自拍照',
    picture: null,
    min: 2,
    max: 2,
    photoNameKeys: ['TakeFace'],
  },
];

const errorsConfig = {
  LINE_CANNOT_GET_ID_TOKEN: {
    popup: {
      title: 'LINE API 異常',
      notes: '無法取得 LINE 使用者驗證資訊',
      confirmButtonText: '確認',
    },
    notifier: 'popup',
  },
};

const SubjectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const TitlePanel = styled.div``;

const Title = styled.h4`
  ${themeGet('fonts.body')};
  margin: 0;
`;

const SubTitle = styled.p`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
`;

const CustomButtonPicture = styled(ButtonPicture)`
  ${css({ width: ['88px', '110px'] })};
  flex: 0 0 auto;
`;

const ButtonStyled = styled(Button)`
  display: block;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
`;

const isReadyToUpload = (photoNames, targetNameKeys) => {
  const photoNameKeys = Object.keys(photoNames);

  for (const targetNameKey of targetNameKeys) {
    if (!photoNameKeys.includes(targetNameKey)) {
      return false;
    }
  }
  return true;
};

const PhotoForm = ({ typeName, step, isLastStep }) => {
  const history = useHistory();
  const { storePhotoNames, photoNames, handleOnNextClick } = useContext(FormContext);
  const [currentPhotoType, setCurrentPhotoType] = useState();
  const [swipeUpVisible, setSwipeUpVisible] = useState(false);
  const { registerErrorConfig, launchError } = useErrorNotification();

  const [handleOnCameraClick] = useDebouncedCallback(
    () => {
      if (OS === 'ios') {
        setSwipeUpVisible(true);
      } else {
        // Android 跳出 Line 進行拍照
        try {
          const idToken = window.liff.getIDToken();
          if (!idToken) throw new Error('LINE empty id token');
          window.liff.openWindow({
            url: `${process.env.REACT_APP_VENUS_URL}/take-registration-photos-v2?${qs.stringify({
              redirectTo: '/further-documents',
              goBack: { step: step },
              forward: { step: step },
              token: idToken,
              min: currentPhotoType?.min,
              max: currentPhotoType?.max,
              allFinishText: '繼續完成補件',
            })}`,
            external: true,
          });
        } catch (error) {
          launchError({ type: 'LINE_CANNOT_GET_ID_TOKEN' }, 'photo-form');
          throw error;
        }
      }
    },
    300,
    {
      leading: true,
      trailing: false,
    }
  );

  const handleOnClose = useCallback(() => {
    setSwipeUpVisible(false);
  }, []);

  const handleOnFinish = useCallback(
    photos => {
      storePhotoNames(photos, typeName);
      setSwipeUpVisible(false);
    },
    [storePhotoNames, typeName]
  );

  useEffect(() => {
    const currentPhotoType = uploadList.find(({ name }) => name === typeName);

    if (!typeName || !currentPhotoType) {
      history.goBack();
    }
    setCurrentPhotoType(currentPhotoType);
  }, [history, typeName]);

  const handleOnButtonClick = async () => {
    try {
      handleOnNextClick();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    registerErrorConfig('photo-form', errorsConfig);
  }, []);

  return currentPhotoType ? (
    <div height={['calc(100% - 30px)']}>
      <Fragment>
        <ItemCard>
          <SubjectWrapper>
            <TitlePanel>
              <Title>{currentPhotoType?.title}</Title>
              <SubTitle>{currentPhotoType?.subtitle}</SubTitle>
            </TitlePanel>
            <CustomButtonPicture
              isChecked={isReadyToUpload(photoNames, currentPhotoType?.photoNameKeys)}
              onClick={handleOnCameraClick}
            />
          </SubjectWrapper>
        </ItemCard>
        <ButtonStyled
          onClick={handleOnButtonClick}
          shape="capsule"
          type="float"
          disabled={!isReadyToUpload(photoNames, currentPhotoType?.photoNameKeys)}
          boxShadow="light"
          width={0.64}
        >
          {isLastStep ? '重新送審' : '下一步'}
        </ButtonStyled>
      </Fragment>
      {OS === 'ios' && (
        <SwipeUpPage visible={swipeUpVisible}>
          <TakeRegistrationPhotosV2
            limit={{ min: currentPhotoType?.min, max: currentPhotoType?.max }}
            onClose={handleOnClose}
            onFinish={handleOnFinish}
            allFinishText="繼續完成補件"
          />
        </SwipeUpPage>
      )}
    </div>
  ) : (
    <FullScreenLoading spinning={true} />
  );
};

PhotoForm.defaultProps = {};

PhotoForm.propTypes = {};

export default PhotoForm;

import React, { useContext, useState, useMemo, useCallback } from 'react';
import authenticationService from '~/utils/authenticationService';
import styled from 'styled-components';
import { AuthenticationContext } from '../providers/AuthenticationProvider';
import { useStatus, useUserInfo } from '~/context/';
import { useErrorNotification } from '~/context/ErrorNotification';
import requestErrors from '~/configs/requestErrors';
import { wemoPhone } from '~/configs';
import { getApiErrorMessage } from '~/utils/getApiErrorMessage';

const Link = styled.a`
  color: ${props => props.theme.colors.mainBlue};
`;

const useAuthentication = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [authentication, dispatch] = useContext(AuthenticationContext);
  const { resetStatus, setIsGuest } = useStatus();
  const { resetUserInfo } = useUserInfo();
  const { registerErrorConfig, launchApiError, launchError } = useErrorNotification();

  // dev login (external browser line login)
  const DEV_line_login = useCallback(async () => {
    try {
      setIsLoading(true);
      const loginStatus = await authenticationService.DEV_line_login();
      dispatch({ type: 'updateUserLogin', loginStatus });
      setIsLoading(false);
      return loginStatus.isLogin;
    } catch (rawError) {
      setIsLoading(false);
      const error = getApiErrorMessage(rawError);
      if (
        [
          requestErrors.USER_BANNED,
          requestErrors.USER_LOGIN_EMAIL_IN_USE,
          requestErrors.LINE_TOKEN_LINE_ID_MISSING,
          requestErrors.LINE_TOKEN_EMAIL_MISSING,
        ].includes(error?.type)
      ) {
        setLoginError({ hasError: true, type: error.type });
      }
      return false;
    }
  }, []);

  const login = useCallback(async () => {
    try {
      setIsLoading(true);
      const loginStatus = await authenticationService.login();
      resetStatus();
      dispatch({ type: 'updateUserLogin', loginStatus });
      setIsLoading(false);
      return loginStatus.isLogin;
    } catch (rawError) {
      setIsLoading(false);
      const error = getApiErrorMessage(rawError);
      if (
        [
          requestErrors.USER_BANNED,
          requestErrors.USER_LOGIN_EMAIL_IN_USE,
          requestErrors.LINE_TOKEN_LINE_ID_MISSING,
          requestErrors.LINE_TOKEN_EMAIL_MISSING,
        ].includes(error?.type)
      ) {
        setLoginError({ hasError: true, type: error.type });
      }
      return false;
    }
  }, []);

  const loginExistedUser = useCallback(async data => {
    try {
      setIsLoading(true);
      const loginStatus = await authenticationService.loginExistUser(data);
      resetStatus();
      dispatch({ type: 'updateUserLogin', loginStatus });
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return false;
    }
  }, []);

  const registerAndBindLineId = useCallback(async data => {
    try {
      resetStatus();
      setIsLoading(true);
      const loginStatus = await authenticationService.registerAndBindLineId(data);
      resetStatus();
      dispatch({ type: 'updateUserLogin', loginStatus });
      setIsLoading(false);
      return loginStatus.isLogin;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  }, []);

  const loginGuest = useCallback(async data => {
    setIsLoading(true);
    const loginStatus = await authenticationService.loginGuest();
    dispatch({ type: 'updateUserLogin', loginStatus });
    setIsGuest();
    setIsLoading(false);
    return loginStatus.isLogin;
  }, []);

  const logOut = useCallback(async data => {
    setIsLoading(true);
    const loginStatus = await authenticationService.logOut();
    resetUserInfo();
    resetStatus();
    dispatch({ type: 'updateUserLogin', loginStatus });
    setIsLoading(false);
    return loginStatus.isLogin;
  }, []);

  const checkLoginError = useCallback(
    (type, shouldLaunchError, history) => {
      if (type === 'all') {
        const hasError = authentication.loginError?.hasError;
        hasError &&
          shouldLaunchError &&
          launchError({ type: authentication.loginError?.type, history }, 'authentication');
        return hasError;
      } else {
        const hasError = authentication.loginError?.type === type;
        hasError && shouldLaunchError && launchError({ type, history }, 'authentication');
        return hasError;
      }
    },
    [launchError, authentication.loginError]
  );

  const handleLogin = useCallback(async () => {
    let isLogin = false;

    // ! 這段這樣寫會有 Bug，loginStatus.isLogin 狀態會跟 authentication.isLogin 不同步
    // if (process.env.REACT_APP_IS_LOCAL === 'true') {
    //   isLogin = await DEV_line_login();
    //   } else if (!loginStatus.isLogin) {
    //   isLogin = await login();
    // }

    if (process.env.REACT_APP_IS_LOCAL === 'true') {
      isLogin = await DEV_line_login();
    } else if (!authentication.isLoading) {
      isLogin = await login();
    }
    return isLogin;
  }, [DEV_line_login, authentication.isLoading, login]);

  const setLoginError = useCallback(payload => {
    dispatch({ type: 'setLoginError', payload });
  }, []);

  useMemo(() => {
    registerErrorConfig('authentication', {
      [requestErrors.USER_LOGIN_EMAIL_IN_USE]: error => {
        return {
          popup: {
            title: '帳號已註冊',
            notes: (
              <span>
                系統偵測到你曾經註冊過 WeMo Scooter，如果沒有請聯絡客服
                <Link href={`tel:${wemoPhone}`}>{wemoPhone} </Link>
              </span>
            ),
            confirmButtonText: '使用舊用戶登入',
            cancelButtonText: '取消',
            onConfirmClick: () => error.history?.replace('/old-user-phone'),
            hasCancelButton: true,
          },
          notifier: 'popup',
        };
      },
      [requestErrors.LINE_TOKEN_LINE_ID_MISSING]: {
        snackbar: {
          message: '系統發生異常\n請稍後再試',
        },
        notifier: 'snackbar',
      },
      [requestErrors.LINE_TOKEN_EMAIL_MISSING]: {
        popup: {
          title: '無法取得您的電子郵件',
          confirmButtonText: '前往設定',
          onConfirmClick: () => {
            window.liff.openWindow({
              url: 'https://line.me/R/nv/settings/account',
            });
          },
          type: 'warning',
          notes: `請至 LINE 設定（路徑:設定>我的帳號>電子郵件>新增) 後重新進入 WeMo\n並允許存取 LINE 電子郵件`,
        },
        notifier: 'popup',
      },
      LINE_HAS_NO_EMAIL: {
        popup: {
          title: '無法取得您的電子郵件',
          confirmButtonText: '確認',
          type: 'warning',
          notes: `請至 LINE 設定（路徑:設定>我的帳號>電子郵件>新增) 後重新進入 WeMo\n並允許存取 LINE 電子郵件`,
        },
        notifier: 'popup',
      },
    });
  }, []);

  return {
    login,
    logOut,
    loginGuest,
    checkLoginError,
    loginExistedUser,
    registerAndBindLineId,
    handleLogin,
    isLogin: authentication.isLogin,
    userType: authentication.userType,
    isLoading,
  };
};

export { useAuthentication };

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popup from 'Components/atoms/Popup';
import Checkbox from 'Components/atoms/Checkbox';
import Button from 'Components/atoms/Button';
import { Row, Col } from 'react-grid-system';
import ExpiredStorage from 'expired-storage';

const Title = styled.h3`
  margin: 0 0 13px;
  text-align: center;
  ${props => props.theme.fonts.subtitle}
`;

const Notes = styled.div`
  ${props => props.theme.fonts.body2}
  color: ${props => props.theme.colors.secondaryGrey};
`;

const Note = styled.div`
  position: relative;
  padding-left: 16px;
  margin-bottom: 10px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    top: 7.5px;
    background-color: ${props => props.theme.colors.mainBlue};
  }
`;

const CheckboxStyled = styled(Checkbox)`
  ${props => props.theme.fonts.body2}
  color: ${props => props.theme.colors.secondaryGrey};
  display: block;
  margin: 46px auto 18px;

`;

const ButtonStyled = styled(Button)``;
const expiredStorage = new ExpiredStorage();

const PopupClosableNote = ({
  title,
  typeKey,
  notes,
  onCancelClick,
  onConfirmClick,
  turnOffText,
  confirmButtonText,
  ...popupProp
}) => {
  const [shouldNeverShow, setShouldNeverShow] = useState(false);
  //  寫 Util 集中管理 localStorage
  const handleOnConfirm = () => {
    if (shouldNeverShow) {
      const popupRecord = expiredStorage.getJson('popTurnOff') || {};
      expiredStorage.setJson('popTurnOff', { ...popupRecord, [typeKey]: shouldNeverShow });
    }

    typeof onConfirmClick === 'function' && onConfirmClick();
  };

  const handleOnCheckboxClick = evt => {
    setShouldNeverShow(evt.target.value);
  };

  return (
    <Popup {...popupProp}>
      <div>
        <Title>{title}</Title>
        <Notes>
          {notes?.map((note, i) => (
            <Note key={note}>{note}</Note>
          ))}
        </Notes>
        <CheckboxStyled label={turnOffText} onChange={handleOnCheckboxClick}></CheckboxStyled>
        <Row>
          <Col xs={12} style={{ marginBottom: 18 }}>
            <ButtonStyled
              boxShadow="light"
              size="small"
              width={1}
              shape="capsule"
              type="float"
              onClick={handleOnConfirm}
            >
              {confirmButtonText}
            </ButtonStyled>
          </Col>
          <Col xs={12}>
            <ButtonStyled
              boxShadow="light"
              size="small"
              width={1}
              shape="capsule"
              type="float"
              color="plan"
              onClick={onCancelClick}
            >
              取消
            </ButtonStyled>
          </Col>
        </Row>
      </div>
    </Popup>
  );
};

PopupClosableNote.defaultProps = {};

PopupClosableNote.propTypes = {
  title: PropTypes.string,
  notes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  typeKey: PropTypes.string,
  confirmButtonText: PropTypes.string,
};

export default PopupClosableNote;

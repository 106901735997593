import { useEffect, useMemo, useReducer, useCallback, useRef } from 'react';
import asyncCancelable from '~/utils/asyncCancelable';
import { isObjectDiff } from '~/utils';

const defaultState = {
  loading: false,
  fetching: false,
  data: null,
  query: {},
};

const getInitState = configState => ({
  loading: configState.loading || defaultState.loading,
  data: configState.data || defaultState.data,
  query: configState.query || defaultState.query,
});

const reducer = (state, action) => {
  const { payload } = action;
  const nextState = (() => {
    switch (action.type) {
      case 'setData':
        return {
          ...state,
          loading: false,
          fetching: false,
          data: payload.data || payload,
        };
      case 'setQuery':
        return {
          ...state,
          loading: true,
          fetching: true,
          query: payload,
        };
      case 'reload':
        return {
          ...state,
          loading: true,
          fetching: true,
        };
      case 'setLoading':
        return {
          ...state,
          loading: payload,
        };
      case 'cancel':
        return {
          ...state,
          loading: false,
          fetching: false,
        };
      default:
        return state;
    }
  })();
  return isObjectDiff(state, nextState) ? nextState : state;
};

export default (api, initialState = defaultState) => {
  const [state, _dispatch] = useReducer(reducer, initialState, getInitState);
  const fetch = useMemo(() => asyncCancelable(api), [api]);
  const isMounted = useRef(false);

  const dispatch = action => {
    if (isMounted.current) {
      _dispatch(action);
    }
  };

  const reload = useCallback(() => {
    dispatch({ type: 'reload' });
  }, []);

  const getData = useCallback(
    async (_query, pipes = []) => {
      if (state.fetching) {
        fetch.cancel();
      }

      try {
        const query = _query || state.query;
        dispatch({ type: 'setQuery', payload: query });
        let req = fetch(query).then(resp => resp.data || resp);
        for (const pipe of pipes) {
          req = req.then(resp => pipe(resp) || resp);
        }
        const resp = await req;
        dispatch({
          type: 'setData',
          payload: { data: resp },
        });
        return resp;
      } catch (error) {
        dispatch({ type: 'cancel' });
        throw error;
      }
    },
    [fetch, state.fetching, state.query]
  );

  const setPipe = useCallback((pipe, prevPipes = []) => {
    const pipes = [...prevPipes, ...(Array.isArray(pipe) ? pipe : [pipe])];
    return {
      pipe: pipe => setPipe(pipe, pipes),
      getData: async query => getData(query, pipes),
    };
  }, []);

  const setData = useCallback(data => {
    dispatch({ type: 'setData', payload: { data } });
  }, []);

  const clearData = useCallback(() => {
    dispatch({ type: 'setData', payload: { data: initialState.data } });
  }, [initialState.data]);

  const setLoading = useCallback((loading = false) => {
    dispatch({ type: 'setLoading', payload: loading });
  }, []);

  const cancel = useCallback(() => {
    fetch.cancel();
    dispatch({ type: 'setLoading', payload: false });
  }, [fetch]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      fetch.cancel();
    };
  }, []);

  return {
    data: state.data,
    loading: state.loading,
    getData: async query => getData(query),
    pipe: pipe => setPipe(pipe),
    setData,
    reload,
    clearData,
    cancel,
    setLoading,
  };
};

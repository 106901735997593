import React, { createContext, useReducer } from 'react';
import statusReducer from '../reducers/statusReducer.js';

const StatusContext = createContext();

const StatusProvider = ({ children }) => {
  const reducers = useReducer(statusReducer, statusReducer());
  // console.log('StatusProvider');

  return <StatusContext.Provider value={reducers}>{children}</StatusContext.Provider>;
};

export { StatusProvider, StatusContext };

// TODO Add a model to initialize all states?

const initUserCategory = {};

const UserCategoryReducer = (state = initUserCategory, action) => {
  switch (action?.type) {
    case 'setUserCategory':
      return {
        ...state,
        ...action.payload,
      };
    case 'resetUserCategory':
      return initUserCategory;
    default:
      return state;
  }
};

export default UserCategoryReducer;

import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import imageCompression from 'browser-image-compression';
import { themeGet } from '@styled-system/theme-get';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import BasicContainer from 'Components/layouts/BasicContainer';
import PageHeader from '~/components/molecules/PageHeader';
import Button from '~/components/atoms/Button';
import { ReactComponent as CameraSvg } from 'Images/icons/camera-color.svg';
import { ReactComponent as CameraCheckSvg } from 'Images/icons/camera-check.svg';
import PupupLoading from '~/components/molecules/PopupLoading';
import PopupNote from '~/components/molecules/PopupNote';
import useLoading from '~/hooks/useLoading';
import { postUserFeedback } from '~/services/api';

const Text = styled.span`
  ${themeGet('fonts.body2')};
`;

const TextUnderLine = styled(Text)`
  text-decoration: underline;
  text-decoration-color: ${themeGet('colors.secondaryGrey')};
`;

const TextareaStyled = styled.textarea`
  ${themeGet('fonts.body')};
  background: ${themeGet('colors.typeSpaceGrey')};
  color: ${themeGet('colors.secondaryGrey')};
  border-radius: 10px;
  height: 132px;
  width: 100%;
  border: none;
  resize: none;
  padding: 20px;
  outline: none;
  user-select: auto;
`;

const ImageLabel = styled.p`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 10px 0;
`;

const ImageButtonGroup = styled.div`
  display: flex;
`;

const ImageButton = styled(Button)`
  border-radius: 10px;
  background: ${themeGet('colors.uselessBlue')};
  height: 74px;
  width: 74px;
  margin-left: 20px;
  padding: 0;
  &:first-child {
    margin-left: 0;
  }
`;

const Footer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const SubmitButton = styled(Button)`
  width: 240px;
`;

export const PureFeedback = props => {
  const picker = useRef();
  const pickIndex = useRef();
  const history = useHistory();
  const loading = useLoading();
  const [feedback, setfeedback] = useState();
  const [photos, setPhotos] = useState([null, null, null]);
  const [showAlert, setShowAlert] = useState({ visible: false, confirmButtonText: '確認' });

  const [handleImageButtonOnClick] = useDebouncedCallback(
    index => {
      pickIndex.current = index;
      picker.current.click();
    },
    300,
    { leading: true, trailing: false }
  );

  const handleOnFeedbackChange = useCallback(event => {
    setfeedback(event.target.value);
  }, []);

  const handlePopupNoteOnConfirm = useCallback(() => {
    setShowAlert(prev => ({ ...prev, visible: false, title: '', note: '' }));
  }, []);

  const [handleOnSubmitClick] = useDebouncedCallback(
    async () => {
      try {
        loading.start();
        const jobs = [];
        for (const photo of photos) {
          if (!photo) continue;
          jobs.push(imageCompression(photo, { maxSizeMB: 0.5 }));
        }
        const compressionPhotos = await Promise.all(jobs);
        await postUserFeedback(feedback, compressionPhotos);
        loading.end();
      } catch (error) {
        setShowAlert(prev => ({
          ...prev,
          title: '付款失敗',
          notes: '伺服器發生異常狀況',
        }));
        loading.end('fail');
        console.log(error);
      }
    },
    300,
    { leading: true, trailing: false }
  );

  const handleOnSuccess = useCallback(() => {
    history.goBack();
  }, []);

  const handleOnFailure = useCallback(() => {
    setShowAlert(prev => ({ ...prev, visible: true }));
  }, []);

  useEffect(() => {
    const inputEl = document.createElement('input');
    picker.current = inputEl;
    inputEl.setAttribute('type', 'file');
    inputEl.setAttribute('accept', 'image/*');
    inputEl.style.display = 'none';
    document.body.appendChild(inputEl);
    inputEl.addEventListener('change', event => {
      const file = event.target.files[0];
      setPhotos(prev => [
        ...prev.slice(0, pickIndex.current),
        file,
        ...prev.slice(pickIndex.current + 1),
      ]);
    });
    return () => {
      document.body.removeChild(inputEl);
    };
  }, []);

  return (
    <BasicContainer>
      <PageHeader
        title="意見回饋"
        hint={
          <Text>
            請簡短描述您的建議與回饋，提供我們作為優化操考。
            <TextUnderLine>
              如有操作或騎乘問題，請使用上頁的 Call WeMo 服務，撥打免付費客服專線
            </TextUnderLine>
            ，將由線上人員立即協助您！
          </Text>
        }
      />
      <TextareaStyled placeholder="請在此輸入你的意見" onChange={handleOnFeedbackChange} />
      <ImageLabel>新增最多 3 張照片</ImageLabel>
      <ImageButtonGroup>
        {photos.map((_, index) => (
          <ImageButton
            key={index}
            boxShadow="light"
            type="float"
            IconComponent={photos[index] ? CameraCheckSvg : CameraSvg}
            onClick={() => handleImageButtonOnClick(index)}
          />
        ))}
      </ImageButtonGroup>
      <Footer>
        <SubmitButton
          disabled={!feedback}
          boxShadow="light"
          type="float"
          onClick={handleOnSubmitClick}
        >
          送出
        </SubmitButton>
      </Footer>
      <PupupLoading
        {...loading.state}
        successText="成功送出"
        onSuccess={handleOnSuccess}
        onFailure={handleOnFailure}
      />
      <PopupNote {...showAlert} onConfirmClick={handlePopupNoteOnConfirm} />
    </BasicContainer>
  );
};

PureFeedback.defaultProps = {};

PureFeedback.propTypes = {};

export default React.memo(PureFeedback);

import React, { createContext, useReducer } from 'react';
import editingUserReducer from '../reducers/editingUserReducer.js';

const EditingUserContext = createContext();

const EditingUserProvider = ({ children }) => {
  const reducers = useReducer(editingUserReducer, editingUserReducer());
  // console.log('EditingUserProvider');

  return <EditingUserContext.Provider value={reducers}>{children}</EditingUserContext.Provider>;
};

export { EditingUserProvider, EditingUserContext };

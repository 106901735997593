import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';
import Iframe from 'react-iframe';
import Popup from '~/components/atoms/Popup';
import { ReactComponent as Cancel } from '~/images/icons/cancel.svg';
import { useDebouncedCallback } from 'use-debounce';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

const Container = styled.div`
  position: relative;
  border-radius: 10px;
  ${css({
    margin: ['-19px', '-37px'],
    height: ['calc(100vh - 30px)', 'calc(100vh - 60px)'],
  })}

  &::-webkit-scrollbar {
    display: none;
  }
`;

const WemoIframe = styled(Iframe)`
  border: none;
  margin: 0;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CancelIcon = styled.img.attrs({ as: Cancel })`
  height: 20px;
  width: 20px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 6px;
  right: 6px;
  border: none;
  outline: none;
  user-select: none;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
`;

export const PurePopupEmergency = props => {
  const { visible, url, onClose } = props;
  const container = useRef();

  const [handleOnClose] = useDebouncedCallback(
    () => {
      typeof onClose === 'function' && onClose();
    },
    300,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    const el = container.current;
    disablePageScroll(el);
    return () => {
      enablePageScroll(el);
    };
  }, []);

  return (
    <Popup visible={visible}>
      <Container ref={container}>
        <WemoIframe loading="加載中..." url={url} position="relative" />
        <CloseButton onClick={handleOnClose}>
          <CancelIcon />
        </CloseButton>
      </Container>
    </Popup>
  );
};

PurePopupEmergency.defaultProps = {};

PurePopupEmergency.propTypes = {
  visible: PropTypes.bool,
  url: PropTypes.string,
  onClose: PropTypes.func,
};

export default React.memo(PurePopupEmergency);

// TODO Add a model to initialize all states?

const initEditingUser = {
  phoneObj: {
    isSet: false,
    countryCode: null,
    phone: null,
    completePhone: null,
    firebaseToken: null,
  },
  userInfo: {},
  scannedIdCard: null,
};

const editingUser = (state = initEditingUser, action) => {
  switch (action?.type) {
    case 'setEditingUser':
      return {
        ...state,
        ...action.payload,
      };
    case 'resetEditingUser':
      return initEditingUser;
    case 'setEditingUserUserInfo': {
      return {
        ...state,
        userInfo: { ...action.payload },
      };
    }
    case 'setEditingUserUserInvitation': {
      return {
        ...state,
        userInfo: { ...state.userInfo, invitationCode: action.payload },
      };
    }
    case 'setScannedIdCard': {
      return {
        ...state,
        scannedIdCard: action.payload,
      };
    }
    default:
      return state;
  }
};

export default editingUser;

import React, { useRef, useEffect, useState, useMemo, Fragment, useContext } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import PhoneNumber from 'awesome-phonenumber';
import Input from 'Components/atoms/Input';
import { color } from 'styled-system';
import Button from 'Components/atoms/Button';
import Select from 'Components/atoms/Select';
import Form from 'Components/atoms/Form';
import FormItem from 'Components/atoms/Form/FormItem';
import PageHeader from 'Components/molecules/PageHeader';
import BasicContainer from 'Components/layouts/BasicContainer';
import { useHistory } from 'react-router-dom';
import countryCode from '~/configs/countryCode.json';
import { useEditingUser } from '~/context/';

const { Option } = Select;

const RowStyled = styled(Row)`
  margin: 40px;
`;

const ButtonStyled = styled(Button)`
  margin: 0 auto 40px;
  display: block;
`;

const Status = styled.p`
  text-align: right;
  margin: 0;
  ${props => props.theme.fonts.note}
  ${color}
`;

export const PureSmsPhone = Form.create()(({ form, ...props }) => {
  const history = useHistory();
  const { getFieldDecorator, isFieldsTouched, getFieldValue } = form;
  const { editingUser, setEditingUser } = useEditingUser();
  const [toNextPage, setToNextPage] = useState(false);

  const phoneObj = useRef(
    editingUser.phoneObj?.isSet
      ? PhoneNumber(editingUser.phoneObj.completePhone)
      : window.liff.getDecodedIDToken()?.phone_number
      ? PhoneNumber(window.liff.getDecodedIDToken()?.phone_number)
      : null
  );

  const isValid = useMemo(() => {
    const phone = getFieldValue('countryCode') + getFieldValue('phone');
    return phone && PhoneNumber(phone).isValid();
  }, [getFieldValue]);

  const helpMessage = useMemo(() => {
    if (isFieldsTouched(['countryCode', 'phone'])) {
      if (!isValid) {
        return <Status color="mainBlue">請輸入長度符合的手機號碼</Status>;
      }
    }
  }, [isFieldsTouched, isValid]);

  const sendSmsRequest = evt => {
    evt.preventDefault();
    setEditingUser({
      phoneObj: {
        isSet: true,
        countryCode: getFieldValue('countryCode'),
        phone: getFieldValue('phone'),
        completePhone: getFieldValue('countryCode') + getFieldValue('phone'),
      },
    });
    setToNextPage(true);
  };

  useEffect(() => {
    if (editingUser?.phoneObj?.isSet && toNextPage) {
      history.replace('/sign-up-phone-verify', {
        onValidRedirectTo: '/sign-up',
        prevPage: '/sign-up-phone',
      });
    }
    return () => {};
  }, [editingUser, history, toNextPage]);

  return (
    <BasicContainer>
      <Fragment>
        <PageHeader title="輸入手機號碼"></PageHeader>
        <Form>
          <RowStyled gutterWidth={12}>
            <Col xs={5} sm={4}>
              <FormItem labelPosition="top">
                {getFieldDecorator('countryCode', {
                  rules: [{ required: true }],
                  initialValue: phoneObj.current
                    ? '+' + phoneObj.current?.getCountryCode()
                    : '+886',
                })(
                  <Select>
                    {countryCode.map(code => (
                      <Option key={code} value={code}>
                        {code}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={7} sm={8}>
              <FormItem labelPosition="top" help={helpMessage}>
                {getFieldDecorator('phone', {
                  initialValue: phoneObj.current?.getNumber('significant'),
                })(<Input type="tel" />)}
              </FormItem>
            </Col>
          </RowStyled>
          <ButtonStyled
            disabled={!isValid}
            boxShadow="light"
            type="float"
            shape="capsule"
            width={64 / 100}
            onClick={sendSmsRequest}
          >
            傳送驗證碼
          </ButtonStyled>
        </Form>
      </Fragment>
    </BasicContainer>
  );
});

PureSmsPhone.defaultProps = {};

PureSmsPhone.propTypes = {};

const SmsPhone = React.memo(PureSmsPhone);

export default SmsPhone;

import React, { useCallback } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import BasicContainer from 'Components/layouts/BasicContainer';
import { themeGet } from '@styled-system/theme-get';
import Link from '~/components/atoms/Link';
import { ReactComponent as Feedback } from 'Images/icons/black-feedback.svg';
import { ReactComponent as Helmet } from 'Images/icons/black-helmet.svg';
import { ReactComponent as Info } from 'Images/icons/black-info.svg';
import { ReactComponent as Question } from 'Images/icons/black-question.svg';
import { ReactComponent as Tutorial } from 'Images/icons/black-tutorial.svg';
import { ReactComponent as Phone } from 'Images/icons/phone.svg';
import { ReactComponent as Arrow } from 'Images/icons/arrow.svg';

const Header = styled.h1`
  ${themeGet('font.h3Button')};
  color: ${themeGet('colors.mainDark')};
  margin: 0;
`;

const Ul = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  ${css({ marginX: ['-15px', '-30px'] })};
`;

const Li = styled.li`
  padding: 24px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${themeGet('colors.bgGrey')};
  }
  ${css({ paddingX: ['15px', '30px'] })};
`;

const LiLink = styled(Link)`
  color: ${themeGet('colors.mainDark')};
  display: flex;
`;

const PhoneLink = styled.a`
  ${themeGet(`fonts.body`)};
  color: ${themeGet('colors.mainDark')};
  position: relative;
  text-decoration: none;
  display: flex;
  -webkit-tap-highlight-color: transparent;
`;

const Text = styled.span`
  ${themeGet('font.body2')};
  width: 100%;
`;

const Icon = styled.img`
  margin-right: 20px;
  flex: 0 0 auto;
`;

const NextIcon = styled.img.attrs({ as: Arrow })`
  margin-right: 0;
  flex: 0 0 auto;
`;

export const PureHelp = props => {
  const openExternalBrowser = useCallback(url => {
    if (window.liff && url) {
      window.liff.openWindow({
        url,
        external: true,
      });
    }
  }, []);

  return (
    <BasicContainer>
      <Header>幫助</Header>
      <Ul>
        <Li>
          <LiLink
            to={{
              pathname: '/rent-tutorial',
              state: { nextPage: '/' },
            }}
            replace={true}
          >
            <Icon as={Tutorial} />
            <Text>租借教學</Text>
            <NextIcon />
          </LiLink>
        </Li>
        <Li>
          <LiLink
            // to={{
            //   pathname: '/web-view',
            //   state: {
            //     url: 'https://www.wemoscooter.com/%e5%ae%89%e5%bf%83%e5%ae%89%e5%85%a8',
            //     pageHeader: { title: '安心騎乘' },
            //     prevPage: '/help',
            //   },
            // }}
            onClick={() =>
              openExternalBrowser(
                'https://www.wemoscooter.com/%e5%ae%89%e5%bf%83%e5%ae%89%e5%85%a8'
              )
            }
          >
            <Icon as={Helmet} />
            <Text>安心騎乘</Text>
            <NextIcon />
          </LiLink>
        </Li>
        <Li>
          <LiLink onClick={() => openExternalBrowser('https://www.wemoscooter.com/help')}>
            <Icon as={Question} />
            <Text>常見問題</Text>
            <NextIcon />
          </LiLink>
        </Li>
        <Li>
          <LiLink onClick={() => openExternalBrowser('https://www.wemoscooter.com/about')}>
            <Icon as={Info} />
            <Text>認識 WeMo</Text>
            <NextIcon />
          </LiLink>
        </Li>
        <Li>
          <LiLink to="/feedback" replace={true}>
            <Icon as={Feedback} />
            <Text>意見回饋</Text>
            <NextIcon />
          </LiLink>
        </Li>
        <Li>
          <PhoneLink href="tel:0809-091-880">
            <Icon as={Phone} />
            <Text>Call WeMo</Text>
            <NextIcon />
          </PhoneLink>
        </Li>
      </Ul>
    </BasicContainer>
  );
};

PureHelp.defaultProps = {};

PureHelp.propTypes = {};

export default React.memo(PureHelp);

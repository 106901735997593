import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { animated, useTransition } from 'react-spring';
import SwipeableViews from 'react-swipeable-views';
import PaginationDots from 'Components/atoms/PaginationDots';
import PageHeader from 'Components/molecules/PageHeader';
import Popup from '~/components/atoms/Popup';
import Button from 'Components/atoms/Button';
import { useDebouncedCallback } from 'use-debounce';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

const Image = styled.img`
  max-width: 100%;
  margin: 0 auto;
  display: block;
`;

const ZoomImage = styled.img`
  margin: 0 auto;
`;

const ButtonStyled = styled(Button)`
  ${props => props.theme.fonts.h2Button}
  margin-top: 18px;
  color: white;

  &:first-child {
    margin-top: 36px;
  }
`;

const ZoomContainer = styled(animated.div)`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  z-index: 9998;
  display: flex;
  align-items: center;
  overflow: scroll;
  padding: 40px 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Zoom = props => {
  const { isZoomed, onZoomChange, children } = props;
  const containerEl = useRef();

  const zoomTransition = useTransition(isZoomed, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const [onClose] = useDebouncedCallback(
    () => {
      onZoomChange(false);
    },
    300,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    const container = containerEl.current;
    if (!container) return;
    if (isZoomed) {
      disablePageScroll(container);
    } else {
      enablePageScroll(container);
    }
  }, [isZoomed]);

  return zoomTransition.map(
    ({ item, key, props }) =>
      item && (
        <ZoomContainer ref={containerEl} key={key} style={props} onClick={onClose}>
          {children}
        </ZoomContainer>
      )
  );
};

const PortalZoom = props => {
  const { getContainer, ...otherProps } = props;

  return ReactDOM.createPortal(
    <Zoom {...otherProps} />,
    getContainer ? getContainer() || document.body : document.body
  );
};

export const PureParkingImages = props => {
  const { visible, urls, onClose, onConfirmClick } = props;
  const [index, setIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const imageEl = useRef();

  const handleChangeIndex = useCallback(index => {
    setIndex(index);
  }, []);

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom);
  }, []);

  const handleImgClick = useCallback(() => {
    setIsZoomed(true);
  }, []);

  const handeZoomImageClick = useCallback(event => {
    setIsZoomed(false);
    event.stopPropagation();
  }, []);

  const handleImageOnLoad = useCallback(() => {
    const image = imageEl.current;
    const { offsetWidth, offsetHeight } = image;
    if (offsetWidth > offsetHeight) {
      if (offsetWidth > document.body.offsetWidth) {
        image.style.height = '100%';
      }
    } else {
      if (offsetHeight > document.body.offsetHeight - 80) {
        image.style.height = '100%';
      }
    }
  }, []);

  return (
    <Popup visible={visible} onClose={onClose} maskClosable={true}>
      <PageHeader title="停車場地圖" />
      <SwipeableViews enableMouseEvents={true} index={index} onChangeIndex={handleChangeIndex}>
        {urls.map((url, index) => (
          <Image key={index} src={url} onClick={handleImgClick} />
        ))}
      </SwipeableViews>
      <PaginationDots count={urls.length} index={index} onIndexChange={handleChangeIndex} />
      <ButtonStyled
        boxShadow="light"
        size="small"
        width={1}
        shape="capsule"
        type="float"
        fontcolor="white"
        onClick={onConfirmClick}
      >
        確認
      </ButtonStyled>
      <PortalZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
        <ZoomImage
          ref={imageEl}
          src={urls[index]}
          onClick={handeZoomImageClick}
          onLoad={handleImageOnLoad}
        />
      </PortalZoom>
    </Popup>
  );
};

PureParkingImages.defaultProps = {
  urls: [],
};

PureParkingImages.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirmClick: PropTypes.func,
  urls: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(PureParkingImages);

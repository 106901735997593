import React, { useState, useEffect, useRef, useCallback } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { position } from 'styled-system';
import styled from 'styled-components';
import css from '@styled-system/css';
import { useSpring, animated, config } from 'react-spring';
import ExpiredStorage from 'expired-storage';
import { useAuthentication, useEditingUser } from '~/context/';
import { useHistory } from 'react-router-dom';
import PaginationDots from 'Components/atoms/PaginationDots';
import Button from 'Components/atoms/Button';
import PageHeader from 'Components/molecules/PageHeader';
import CityViewIcon from 'Images/city-view.svg';
import rider from 'Images/rider.png';
import requestErrors from '~/configs/requestErrors';

const expiredStorage = new ExpiredStorage();

const Container = styled.div`
  overflow-x: hidden;
  height: 100%;
  position: relative;
`;

const TourButton = styled.button`
  ${props => props.theme.fonts.body};
  color: ${props => props.theme.colors.secondaryGrey};
  background-color: ${props => props.theme.colors.typeSpaceGrey};
  border: none;
  border-radius: 15px;
  margin: 30px auto;
  margin-right: 30px;
  display: block;
  outline: none;
  padding: 2px 8px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

const ButtonStyled = styled(Button)`
  display: block;
  margin: 40px auto 30px;
`;

const Text = styled.div`
  display: block;
  ${props => props.theme.fonts.body};
  letter-spacing: 0.15px;
  text-align: center;
`;

const Login = styled.p`
  display: inline-block;
  margin: 0;
  color: ${props => props.theme.colors.mainBlue};
`;

const CityView = styled(animated.img)`
  width: 300%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

const Rider = styled(animated.img)`
  position: absolute;
  bottom: 0;
  left: -30px;
  width: 40%;
  z-index: -1;
`;

const FloatContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  ${css({ height: ['69%', '73%', '75%'] })};
`;

const ActionContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  ${position};
`;

export const PureLandingPage = props => {
  const history = useHistory();
  const { loginGuest, isLogin, userType, checkLoginError } = useAuthentication();
  const [index, setIndex] = useState(0);
  const containerRef = useRef();
  const [slideContainer, setSlideContainer] = useState();
  const { resetEditingUser, setEditingUserUserInvitation } = useEditingUser();

  const cityViewSpring = useSpring({
    transform: `translate3d(${-index * 33.3}%,0,0)`,
    config: { ...config.slow, duration: 1000 },
  });

  const riderSpring = useSpring({
    transform: `translate3d(${index * 100}%,0,0)`,
    config: { mass: 1, tension: 280, friction: 80 },
  });

  const handleChangeIndex = index => {
    setIndex(index);
  };

  const handleLoginGuest = async () => {
    let loginSuccess = false;
    if (!isLogin) {
      loginSuccess = await loginGuest();
    }
    if (isLogin || loginSuccess) history.replace('/');
  };

  const handleOnRegisterClick = useCallback(() => {
    if (!checkLoginError('all', true, history)) {
      expiredStorage.removeItem('registerPhotoNames');
      history.replace('/sign-up-phone');
    }
  }, [checkLoginError, history]);

  const handleOldUserLogin = () => {
    if (
      !checkLoginError(requestErrors.LINE_TOKEN_EMAIL_MISSING, true, history) &&
      !checkLoginError(requestErrors.LINE_TOKEN_LINE_ID_MISSING, true, history)
    ) {
      history.replace('/old-user-phone');
    }
  };

  const handleInvitee = useCallback(() => {
    let search = window.location.search;
    const medium = search.match(/utm_medium=([^&]+)/)?.[1];
    if (medium === 'invitee') {
      const content = search.match(/utm_content=([^&]+)/)?.[1];
      if (content) {
        setEditingUserUserInvitation(content);
      }
    }
  }, []);

  useEffect(() => {
    resetEditingUser();
    handleInvitee();
    checkLoginError('all', true, history);
  }, []);

  useEffect(() => {
    if (isLogin && userType !== 'GUEST') history.replace('/');
  }, [history, isLogin, userType]);

  return (
    <Container ref={ref => (containerRef.current = ref)}>
      <TourButton onClick={handleLoginGuest}> 體驗</TourButton>
      <ActionContainer top={slideContainer?.getBoundingClientRect().bottom + 10}>
        <PaginationDots count={3} index={index} onIndexChange={handleChangeIndex} />
        <ButtonStyled
          boxShadow="light"
          type="float"
          shape="capsule"
          width={64 / 100}
          onClick={handleOnRegisterClick}
        >
          註冊
        </ButtonStyled>
        <Text>
          原本就有帳戶？<Login onClick={handleOldUserLogin}>登入</Login>
        </Text>
      </ActionContainer>
      <FloatContainer>
        <CityView src={CityViewIcon} style={cityViewSpring} />
        <Rider src={rider} style={riderSpring} />
      </FloatContainer>
      <SwipeableViews
        enableMouseEvents={true}
        index={index}
        onChangeIndex={handleChangeIndex}
        resistance
        containerStyle={{
          paddingTop: 80.25,
          height: containerRef.current?.getBoundingClientRect().height,
        }}
      >
        <div ref={ref => setSlideContainer(ref)}>
          <PageHeader
            title="24小時皆可租借"
            hint={
              <span>
                全年無休 24H 專業客服
                <br />
                守護你的騎乘安全
              </span>
            }
          ></PageHeader>
        </div>
        <div>
          <PageHeader
            title="乾淨衛生，專屬帽套"
            hint={
              <span>
                不需擔心衛生問題
                <br />
                每台機車提供安全帽和乾淨帽套
              </span>
            }
          ></PageHeader>
        </div>
        <div>
          <PageHeader
            title="單向行程，隨租隨還"
            hint={
              <span>
                機車停至合法公有露天停車格
                <br />
                即可歸還
              </span>
            }
          ></PageHeader>
        </div>
      </SwipeableViews>
    </Container>
  );
};

PureLandingPage.defaultProps = {};

PureLandingPage.propTypes = {};

export default React.memo(PureLandingPage);

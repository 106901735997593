import React, { useState, Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popup from 'Components/atoms/Popup';
import Button from 'Components/atoms/Button';
import { themeGet } from '@styled-system/theme-get';
import SwipeableViews from 'react-swipeable-views';
import PaginationDots from 'Components/atoms/PaginationDots';
import exception1 from 'Images/exceptionReturn/exception-1.png';
import exception2 from 'Images/exceptionReturn/exception-2.png';
import exception3 from 'Images/exceptionReturn/exception-3.png';
import { wemoPhone, rentStatus } from '~/configs';

export const Title = styled.h1`
  ${props => props.theme.fonts.subtitle}
  margin: 0 0 4px 0;
  color: ${({ type }) =>
    type === 'warning' ? themeGet('colors.wrongRed') : themeGet('colors.mainDark')};
  text-align: center;
`;

export const Text = styled.p`
  ${props => props.theme.fonts.body2}
  margin: 0;
  text-align: center;
`;

export const NotesP = styled.p`
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.secondaryGrey};
  margin: 0;
  margin-top: 4px;
  text-align: center;
  white-space: pre-line;
`;

export const NotesDiv = styled.div`
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.secondaryGrey};
  margin: 0;
  margin-top: 4px;
  text-align: center;
  white-space: pre-line;
`;

const Footer = styled.div`
  width: 100%;
`;

const ButtonStyled = styled(Button)`
  margin-top: 16px;
  margin-bottom: 4px;

  &:first-child {
    margin-top: 36px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  margin: 16px auto;
  display: block;
`;

const Hint = styled.p`
  margin: 0;
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.secondaryGrey};
  text-align: center;
`;

const Success = styled.p`
  ${props => props.theme.fonts.button2};
  color: ${props => props.theme.colors.secondaryGrey};
  text-align: center;
  margin-top: 16px;
  margin-bottom: 4px;

  &:first-child {
    margin-top: 36px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.mainBlue};
`;

export const PurePopupExceptionReturn = ({ visible, status, onConfirmClick, ...popupProp }) => {
  const [index, setIndex] = useState(status === rentStatus.renting ? 0 : 2);
  const handleChangeIndex = index => {
    setIndex(index);
  };

  return (
    <Popup {...popupProp} visible={visible}>
      <SwipeableViews
        enableMouseEvents={true}
        index={index}
        onChangeIndex={handleChangeIndex}
        resistance
        containerStyle={{ height: 'auto' }}
      >
        <div>
          <Title>偵測到連線異常</Title>
          <Text>
            請確認依規定停放於
            <br />
            合法公有露天停車格或合作停車場
            <br />
            完成還車拍照終止計費
          </Text>
          <Image src={exception1} />
          <Text>
            如何開車廂與熄火
            <br />
            請往後滑動
          </Text>
        </div>
        <div>
          <Title>將車廂物品取出並歸還安全帽</Title>
          <Text>
            手動熄火密技
            <br />
            1. 踢下側腳架(儀表亮紅燈)
            <br />
            2. 連續按P鈕2下以上
          </Text>
          <Hint>(如車輛已熄火請撥打客服協助)</Hint>
          <Image src={exception2} />
        </div>
        <div>
          <Title>確保將車輛熄火</Title>
          <Text>
            手動熄火密技
            <br />
            1. 踢下側腳架(儀表亮紅燈)
            <br />
            2. 連續按P鈕12下以上
          </Text>
          <Hint>(車廂跳開請持續按到儀表熄滅)</Hint>
          <Image src={exception3} />
        </div>
      </SwipeableViews>
      <PaginationDots count={3} index={index} onIndexChange={handleChangeIndex} />
      {status === rentStatus.renting ? (
        <Fragment>
          <ButtonStyled
            boxShadow="light"
            size="small"
            width={1}
            shape="capsule"
            type="float"
            fontcolor="white"
            onClick={onConfirmClick}
          >
            還車拍照
          </ButtonStyled>
        </Fragment>
      ) : (
        <Success>已還車，熄火後可安心離開！</Success>
      )}
      <Hint>
        以上說明幫不到你？
        <Link href={`tel:${wemoPhone}`}>尋求協助</Link>
      </Hint>
    </Popup>
  );
};

PurePopupExceptionReturn.defaultProps = {};

PurePopupExceptionReturn.propTypes = {};

export default React.memo(PurePopupExceptionReturn);

import { useContext, useCallback, useState } from 'react';
import authenticationService, { loginStatus } from '~/utils/authenticationService';
import { StatusContext } from '../providers/StatusProvider';
import { updateUserInfo, getUserStatus, getRejectReasons } from '~/services/api';
import {
  rentStatus,
  scooterStatusMap,
  userStatusWithRejectReasons,
  userStatusMap,
} from '~/configs';

import { underlineToCamelCase } from '~/utils/stringConverter';

const useStatus = () => {
  const [status, dispatch] = useContext(StatusContext);
  // console.log('status', status);

  const getCurrentStatus = useCallback(async () => {
    try {
      let userStatus = await getUserStatus();
      let rejectReasons = null;
      if (userStatusWithRejectReasons.includes(userStatusMap[userStatus.statusId])) {
        rejectReasons = await getRejectReasons();
      }
      dispatch({ type: 'setStatus', payload: { ...userStatus, rejectReasons } });
    } catch (error) {}
  }, []);

  const setUserLocation = useCallback(location => {
    dispatch({ type: 'setStatus', payload: { location } });
  }, []);

  const setIsGuest = useCallback(payload => {
    dispatch({ type: 'setStatus', payload: { isGuest: true } });
  }, []);

  const setPaymentInfo = paymentInfo => {
    dispatch({ type: 'setStatus', payload: { ...paymentInfo } });
  };

  const resetStatus = useCallback(location => {
    dispatch({ type: 'resetStatus' });
  }, []);

  return {
    status,
    setIsGuest,
    resetStatus,
    setUserLocation,
    getCurrentStatus,
    setPaymentInfo,
  };
};

export { useStatus };

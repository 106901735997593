import LineAxios from './LineAxios';
import { paymentKeys } from '~/configs/';

LineAxios.create('hermes');
const axios = LineAxios.getInstance('hermes').useToken();

export const DEV_loginUser = () => {
  return axios
    .post(`/v2/developers/login`, { email: process.env.REACT_APP_DEV_USER_ACCOUNT })
    .then(({ data = { data: {} } }) => data.data)
    .catch(e => console.log('errs'));
};

export const registerUser = async data => {
  const idToken = window.liff.getIDToken();
  return axios
    .post(`/v13/users/register`, {
      ...data,
      type: 'line',
      userAgent: 'linemini',
      data: idToken,
    })
    .then(({ data }) => data.data);
};

// TODO: remove this when register v4 is stable
// export const bindLineIdToAccount = async ({ id_token }) => {
//   const profile = await window.liff?.getProfile();
//   return axios
//     .post(`/v13/users/me/createUserAuth`, {
//       type: 'line',
//       data: profile.userId,
//       id_token,
//     })
//     .then(({ data }) => data.data);
// };

export const loginUser = accessToken => {
  return axios
    .post(`/v13/users/login`, {
      type: 'line',
      userAgent: 'linemini',
      id_token: window.liff?.getIDToken(),
    })
    .then(({ data }) => data.data);
};

export const loginGuestUser = accessToken => {
  return axios.get(`/v6/guests`).then(({ data }) => data.data);
};

export const existUserLogin = data => {
  return axios
    .post('/v13/users/existUserLineLogin', {
      id_token: window?.liff?.getIDToken(),
      type: 'line',
      userAgent: 'linemini',
      ...data,
    })
    .then(({ data }) => data.data);
};

export const refreshToken = token => {
  return axios.post(`/v13/users/me/tokens`, { refreshToken: token }).then(({ data }) => {
    return data.data;
  });
};

export const getUserInfo = (select = 'userInfo,appReturnInfo') => {
  return axios
    .post(`/service.asmx/UserInfoV3`, { selects: 'userInfo', lang: 'zh-tw' })
    .then(({ data }) => {
      return data;
    });
};

export const getAppReturnInfo = (select = 'userInfo,appReturnInfo') => {
  return axios
    .post(`/service.asmx/UserInfoV3`, { selects: 'appReturnInfo', lang: 'zh-tw' })
    .then(({ data }) => {
      return data;
    });
};

export const editUserInfo = data => {
  return axios.patch(`/v13/users/info`, data).then(({ data }) => {
    return data;
  });
};

export const editUserInfoArp = data => {
  return axios.patch(`/v13/users/me/info`, data).then(({ data }) => {
    return data;
  });
};

export const getUserStatus = () => {
  return axios.get(`/v13/users/me/status`).then(({ data }) => {
    return data.data;
  });
};

export const getRejectReasons = () => {
  return axios.get(`/v13/users/me/rejectReasons`).then(({ data }) => {
    return data.data;
  });
};

// {
//   "scooterId": "QAT-0001",
//   "pricingPlanId": "1",
//   "timePlanId": "1"
// }
export const reserveScooter = reserveData => {
  return axios.post(`/v13/users/me/rents`, reserveData).then(({ data }) => {
    return data.data;
  });
};

export const getUserCategory = () => {
  return axios.get(`/v13/users/me/userCategories/default`).then(({ data }) => {
    return data.data;
  });
};

export const getRouteToScooter = (userLocation, destLocation) => {
  return axios
    .post(`/api/gme-gateway/ios`, {
      url: `https://maps.googleapis.com/maps/api/directions/json?origin=${userLocation.lat},${userLocation.lng}&destination=${destLocation.lat},${destLocation.lng}&mode=walking`,
    })
    .then(({ data }) => {
      return data.data;
    });
};

export const cancelReserveScooter = rendId => {
  return axios.delete(`/v13/users/me/rents/${rendId}`).then(({ data }) => {
    return data.data;
  });
};

export const rentScooter = (rendId, rentData) => {
  return axios.patch(`/v13/users/me/rents/${rendId}`, { type: 3, ...rentData }).then(({ data }) => {
    return data.data;
  });
};

export const returnScooter = (rendId, data) => {
  return axios.patch(`/v13/users/me/rents/${rendId}`, { type: 4, ...data }).then(({ data }) => {
    return data.data;
  });
};

export const exceptionReturnScooter = (scooterId, rentData) => {
  return axios
    .patch(`/v13/users/me/rents/${scooterId}`, { type: 5, ...rentData })
    .then(({ data }) => {
      return data.data;
    });
};

export const getCurrentRentingStatus = () => {
  return axios.post('service.asmx/KeyPageInfo').then(({ data }) => data);
};

export const uploadUserPhotos = async (photos = [], onProgress) => {
  const data = new FormData();
  for (let photoId in photos) {
    const photo = photos[photoId];
    data.append(photoId, photo);
  }
  return axios.post('/v13/users/me/images', data, {
    headers: {
      contentType: 'multipart/form-data',
    },
    ...(onProgress && {
      onUploadProgress: onProgress,
    }),
  });
};

// 功能 :改完電話後改為已驗證
export const verifyPhone = idToken => {
  return axios
    .post('/v13/users/me/verifyPhone', { idToken, type: 'linemini' }) // TODO: 後端還沒改 要測
    .then(({ data }) => data);
};

export const postUserOrderTransaction = async ({
  orderId,
  amount,
  paymentType,
  isWaiting,
  redirectUrl, // for line pay
  prime, // for line pay
}) => {
  return axios
    .post('/v5/users/me/trans', {
      orderId,
      amount,
      paymentType: paymentKeys[paymentType],
      isWaiting,
      redirectUrl,
      prime,
    })
    .then(({ data }) => data.data);
};

export const getZones = () => {
  return (
    axios
      // .get('/v13/users/me/zones', { params: { swapCoordinates: 1 } })
      .get('/v13/users/me/maps')
      .then(({ data }) => data.data)
  );
};

export const getUserHistoryById = async ({ id, type }) => {
  return axios
    .get(`/v13/users/me/history/${id}`, { params: { type } })
    .then(({ data }) => data.data);
};

export const postUserReturnPhoto = async (rentId, photo, onProgress) => {
  const data = new FormData();
  data.append('files', photo);
  return axios.post(`/v13/users/me/rents/${rentId}/images`, data, {
    headers: {
      contentType: 'multipart/form-data',
    },
    ...(onProgress && {
      onUploadProgress: onProgress,
    }),
  });
};

export const getUserInvitaionInfo = async () => {
  return axios.get('/api/user/invitation-info').then(data => data.data);
};

export const postUserOrderDeal = async ({
  orderId,
  amount,
  paymentType,
  prime, // for line pay
}) => {
  return axios
    .post('/v5/users/me/deal', {
      orderId,
      amount,
      paymentType: paymentKeys[paymentType],
      prime,
    })
    .then(({ data }) => data.data);
};

export const checkLicence = async idcardId =>
  axios.get('/v5/users/licence', { params: { idcardId } }).then(({ data }) => data.data);

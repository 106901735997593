import mockScooter from './scooters';
import mockUser from './users';
import mockScooterStyles from './scooterStyles';
import mockParkingLots from './parkingLots';
import mockParkingSpaces from './parkingSpaces';
import mockEmergency from './emergency';
import { getMockAdapter } from './mock';

export const enableMockData = () => {
  getMockAdapter().restore();
  mockScooter();
  mockUser();
  mockScooterStyles();
  mockParkingLots();
  mockParkingSpaces();
  mockEmergency();
  getMockAdapter()
    .onAny()
    .passThrough();
};

export const disableMockData = () => {
  getMockAdapter().restore();
  getMockAdapter()
    .onAny()
    .passThrough();
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import Card from 'Components/atoms/Card';
import Button from 'Components/atoms/Button';
import { ReactComponent as AlertInfo } from 'Images/icons/alertInfo.svg';
import { ReactComponent as AlertError } from 'Images/icons/alertError.svg';

const AlertStyled = styled(Card)`
  display: flex;
  align-items: center;
  min-height: 60px;
  justify-content: space-between;
  ${space};
`;

const iconMap = {
  info: AlertInfo,
  warning: AlertError,
};

const Icon = styled.img`
  margin-right: 10px;
  display: inline-block;
`;

const Container = styled.div`
  display: inline-block;
  flex: 5;
  /* width: calc(100% - 36px); */
`;

const Title = styled.div`
  ${props => props.theme.fonts.subtitle}
`;

const Content = styled.div`
  ${props => props.theme.fonts.body2}
`;

const ButtonStyled = styled(Button)`
  width: 45%;
  margin-left: 12px;
  flex: 1;
`;

const Alert = ({
  iconType,
  title,
  content,
  singleContent,
  buttonText,
  boxShadow,
  onAlertClick,
  type,
  onButtonClick,
  ...alertProps
}) => {
  return (
    <AlertStyled
      ratio="none"
      boxShadow={boxShadow}
      onClick={onAlertClick}
      padding={iconType ? 10 : 20}
      {...alertProps}
    >
      {iconType && <Icon as={iconMap[iconType]} />}
      <Container>
        {singleContent ? (
          <Title>{singleContent}</Title>
        ) : (
          <Fragment>
            <Title>{title}</Title>
            <Content>{content}</Content>
          </Fragment>
        )}
      </Container>
      {buttonText && (
        <ButtonStyled
          type="float"
          shape="capsule"
          size="small"
          boxShadow="light"
          onClick={onButtonClick}
        >
          {buttonText}
        </ButtonStyled>
      )}
    </AlertStyled>
  );
};

Alert.defaultProps = {
  boxShadow: 'love',
};

Alert.propTypes = {
  iconType: PropTypes.oneOf(['warning', 'info']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  content: PropTypes.string,
  singleContent: PropTypes.string,
  buttonText: PropTypes.string,
  onAlertClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  boxShadow: PropTypes.oneOf(['none', 'love', 'light']),
};

export default React.memo(Alert);

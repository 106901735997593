import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';
import dayjs from 'dayjs';
import CreditCardInfo from '~/components/atoms/CreditCardInfo';
import { paymentTypes } from '~/configs/';
import { ReactComponent as LinePay } from '~/images/icons/line-pay.svg';
import { ReactComponent as Wallet } from '~/images/icons/wallet.svg';
import { ReactComponent as Errror } from '~/images/icons/error.svg';
import { ReactComponent as Info } from '~/images/icons/alertInfo.svg';
import { ReactComponent as ApplePay } from '~/images/icons/apple-pay.svg';

const Text = styled.p`
  ${themeGet(`fonts.body2`)};
  margin: 0;
`;

const Divider = styled.div`
  background-color: #e4e4e4;
  height: 1px;
  ${space};
`;

const Container = styled.div`
  background-color: white;

  &:after {
    content: '';
    display: block;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 14px;
    background: linear-gradient(white 0%, transparent 0%),
      linear-gradient(135deg, ${themeGet('colors.bgGrey')} 33.33%, transparent 33.33%) 0 0%,
      ${themeGet('colors.bgGrey')}
        linear-gradient(45deg, ${themeGet('colors.bgGrey')} 33.33%, white 33.33%) 0 0%;
    background: -webkit-linear-gradient(white 0%, transparent 0%),
      -webkit-linear-gradient(135deg, ${themeGet('colors.bgGrey')} 33.33%, transparent 33.33%) 0 0%,
      ${themeGet('colors.bgGrey')} -webkit-linear-gradient(
          45deg,
          ${themeGet('colors.bgGrey')} 33.33%,
          white 33.33%
        ) 0 0%;
    background: -o-linear-gradient(white 0%, transparent 0%),
      -o-linear-gradient(135deg, ${themeGet('colors.bgGrey')} 33.33%, transparent 33.33%) 0 0%,
      ${themeGet('colors.bgGrey')} -o-linear-gradient(
          45deg,
          ${themeGet('colors.bgGrey')} 33.33%,
          white 33.33%
        ) 0 0%;
    background: -moz-linear-gradient(white 0%, transparent 0%),
      -moz-linear-gradient(135deg, ${themeGet('colors.bgGrey')} 33.33%, transparent 33.33%) 0 0%,
      ${themeGet('colors.bgGrey')} -moz-linear-gradient(
          45deg,
          ${themeGet('colors.bgGrey')} 33.33%,
          white 33.33%
        ) 0 0%;
    background-repeat: repeat-x;
    background-size: 14px 14px;
  }
`;

const Wrapper = styled.div`
  ${space};
`;

const Title = styled.h1`
  ${themeGet(`fonts.h4`)};
  text-align: center;
  margin: 0;
`;

const OrderLabelPanel = styled.div``;

const OrderLabel = styled(Text)`
  color: ${themeGet('colors.secondaryGrey')};
`;

const TotalPanel = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const TotalItem = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 6px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 16px;
  }
`;

const TotalText = styled.span`
  ${themeGet(`fonts.button2`)};
  color: ${themeGet('colors.mainDark')};
  ${space};
`;

const Icon = styled.img``;

const LinePayIcon = styled(Icon).attrs(props => ({ as: LinePay }))`
  margin-right: -4px;
`;

const ApplePayIcon = styled(Icon).attrs(props => ({ as: ApplePay }))`
  margin-right: -4px;
`;

const WalletIcon = styled(Icon).attrs(props => ({ as: Wallet }))`
  margin-right: 4px;
`;

const WalletText = styled.span`
  ${themeGet(`fonts.body`)};
  line-height: 30px;
`;

const ErrorIcon = styled(Icon).attrs(props => ({ as: Errror }))`
  margin-right: 4px;
  height: 30px;
  width: 50px;
  padding: 3px 13px;
`;

const InfoIcon = styled(Icon).attrs(props => ({ as: Info }))`
  margin-right: 4px;
  height: 30px;
  width: 50px;
  padding: 3px 13px;
`;

const UnknownPaymentType = styled.span`
  ${themeGet(`fonts.body`)};
  color: ${themeGet(`colors.wrongRed`)};
  line-height: 30px;
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;

  &:first-child {
    margin-top: 0;
  }
`;

const DetailLabel = styled(Text)`
  color: ${themeGet('colors.secondaryGrey')};
`;

const WaitForPayment = styled.span`
  ${themeGet(`fonts.body`)};
  color: ${themeGet('colors.mainBlue')};
  line-height: 30px;
`;

const NoteText = styled(Text)`
  color: ${themeGet('colors.secondaryGrey')};
  text-align: center;
  width: 100%;
`;

export const PureRidingDetail = props => {
  const {
    scooterId,
    rentAt,
    returnAt,
    totalAmount,
    totalRideMinutes,
    rideDistance,
    creditAmount,
    paymentType,
    creditCardType,
    last4Digits,
    bills,
    note,
  } = props;

  return (
    <Container>
      <Wrapper px={['15px', '30px']} pt={['15px', '30px']}>
        <Title>騎乘明細</Title>
        <DetailItem>
          <DetailLabel>車牌</DetailLabel>
          <Text>{scooterId || '-'}</Text>
        </DetailItem>
        <DetailItem>
          <DetailLabel>租借時間</DetailLabel>
          <Text>{rentAt ? dayjs(rentAt).format('YYYY/MM/DD HH:mm') : '-'}</Text>
        </DetailItem>
        <DetailItem>
          <DetailLabel>還車時間</DetailLabel>
          <Text>{returnAt ? dayjs(returnAt).format('YYYY/MM/DD HH:mm') : '-'}</Text>
        </DetailItem>
        <DetailItem>
          <DetailLabel>行駛時間</DetailLabel>
          <Text>{totalRideMinutes || 0} 分鐘</Text>
        </DetailItem>
        <DetailItem>
          <DetailLabel>騎乘距離</DetailLabel>
          <Text>{rideDistance || 0} 公里</Text>
        </DetailItem>
        <Divider m={['20px 0', '20px -10px']} />
        {bills.map((bill, key) => (
          <DetailItem key={key}>
            <OrderLabelPanel>
              <OrderLabel>{bill.name}</OrderLabel>
              <OrderLabel>({bill.rideMinutes}分鐘)</OrderLabel>
            </OrderLabelPanel>
            <Text>${bill.amount}</Text>
          </DetailItem>
        ))}
        {creditAmount > 0 && (
          <DetailItem>
            <OrderLabelPanel>
              <OrderLabel>騎乘金</OrderLabel>
            </OrderLabelPanel>
            <Text>-${creditAmount}</Text>
          </DetailItem>
        )}
        {note && (
          <DetailItem>
            <NoteText>{note}</NoteText>
          </DetailItem>
        )}
        <TotalPanel>
          <TotalItem>
            <TotalText>總計</TotalText>
            <TotalText ml="18px">NT${totalAmount - creditAmount}</TotalText>
          </TotalItem>
          <TotalItem>
            {(() => {
              switch (paymentType) {
                case paymentTypes.creditCard:
                  return (
                    <CreditCardInfo
                      iconMarginRight={['4px', '4px']}
                      type={creditCardType}
                      last4Digits={last4Digits}
                    />
                  );
                case paymentTypes.linePay:
                  return <LinePayIcon />;
                case paymentTypes.applePay:
                  return <ApplePayIcon />;
                case paymentTypes.wallet:
                  return (
                    <React.Fragment>
                      <WalletIcon />
                      <WalletText>WeMo 錢包</WalletText>
                    </React.Fragment>
                  );
                case 'free':
                case 'credit':
                  return <React.Fragment />;
                case 'none':
                  return (
                    <React.Fragment>
                      <InfoIcon />
                      <WaitForPayment>等待付款中...</WaitForPayment>
                    </React.Fragment>
                  );
                case 'cancel':
                  return (
                    <React.Fragment>
                      <ErrorIcon />
                      <UnknownPaymentType>訂單已取消</UnknownPaymentType>
                    </React.Fragment>
                  );
                default:
                  return (
                    <React.Fragment>
                      <ErrorIcon />
                      <UnknownPaymentType>未知支付方式</UnknownPaymentType>
                    </React.Fragment>
                  );
              }
            })()}
          </TotalItem>
        </TotalPanel>
      </Wrapper>
    </Container>
  );
};

PureRidingDetail.defaultProps = {
  bills: [],
  creditAmount: 0,
  totalAmount: 0,
};

PureRidingDetail.propTypes = {
  scooterId: PropTypes.string,
  rentAt: PropTypes.string,
  returnAt: PropTypes.string,
  totalAmount: PropTypes.number,
  totalRideMinutes: PropTypes.number,
  rideDistance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  creditAmount: PropTypes.number,
  paymentType: PropTypes.oneOf([...Object.values(paymentTypes), 'credit', 'cancel', 'none']),
  creditCardType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([
      'VISA',
      'Mastercard',
      'JCB',
      'UnionPay',
      'American Express',
      '-1',
      '1',
      '2',
      '3',
      '4',
      '5',
    ]),
  ]),
  last4Digits: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      amount: PropTypes.number,
      rideMinutes: PropTypes.number,
    })
  ),
  note: PropTypes.string,
};

export default React.memo(PureRidingDetail);

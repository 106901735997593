import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import piexif from 'piexifjs';
import CameraAlternative from '~/components/molecules/CameraAlternative';
import CameraOverlay from '~/components/atoms/CameraOverlay';
import CameraController from './components/CameraController';
import TakePhotoInfo from './components/TakePhotoInfo';
import PreviewController from './components/PreviewController';
import scooterWireframeSvg from '~/images/scooter-wireframe.svg';
import PopupNote from '~/components/molecules/PopupNote';
import CameraV2 from '~/components/molecules/CameraV2';
import PhotoPreviewer from '~/components/molecules/PhotoPreviewer';
import BackToLine, { useBackToLine } from '~/components/molecules/BackToLine';
import asyncLoadImage from '~/utils/asyncLoadImage';
import createPhotoCompression, { getFilefromDataUrl } from '~/utils/photoCompression';
import { postUserReturnPhoto } from '~/services/api';
import useGeoLocation, { handleLocationFail, errorConfig } from '~/hooks/useGeoLocation';
import { useErrorNotification } from '~/context/ErrorNotification';
import wemoToken from '~/utils/wemoToken';

const photoCompression = createPhotoCompression();

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const Info = React.memo(({ contents = [] }) => (
  <React.Fragment>
    {contents.map((content, key) => (
      <div key={key}>{content}</div>
    ))}
  </React.Fragment>
));

const drawScooterWireframe = async canvas => {
  const context = canvas.getContext('2d');
  const image = await asyncLoadImage(scooterWireframeSvg);
  const { offsetWidth, offsetHeight } = canvas;
  const rate = (offsetWidth / 375) * (offsetHeight / 667);
  const width = rate * image.width;
  const height = rate * image.height;
  context.translate((offsetWidth - width) / 2, offsetHeight * 0.07496251874);
  context.drawImage(image, 0, 0, width, height);
};

const setPhotoExifGps = async (photo, lat, lng, altitude) => {
  if (!(lat && lng)) return photo;
  const gps = {};
  gps[piexif.GPSIFD.GPSLatitudeRef] = lat < 0 ? 'S' : 'N';
  gps[piexif.GPSIFD.GPSLatitude] = piexif.GPSHelper.degToDmsRational(lat);
  gps[piexif.GPSIFD.GPSLongitudeRef] = lng < 0 ? 'W' : 'E';
  gps[piexif.GPSIFD.GPSLongitude] = piexif.GPSHelper.degToDmsRational(lng);
  gps[piexif.GPSIFD.GPSAltitudeRef] = altitude >= 0 ? 0 : 1;
  gps[piexif.GPSIFD.GPSAltitude] = parseInt(altitude);

  return await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async event => {
      try {
        const exifStr = piexif.dump({ GPS: gps });
        const inserted = piexif.insert(exifStr, event.target.result);
        const result = await getFilefromDataUrl(inserted);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsDataURL(photo);
  });
};

export const PureTakeReturnPhoto = props => {
  const history = useHistory();
  const urlQuery = qs.parse(window.location.search.slice(1));
  const isExceptionReturn = useMemo(() => urlQuery.isExceptionReturn || false, [urlQuery]);
  const camera = useRef();
  const canCapture = useRef(true);
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [url, setUrl] = useState();
  const photo = useRef();
  const [media, setMedia] = useState('camera');
  const [step, setStep] = useState('camera');
  const [showAlert, setShowAlert] = useState({ visible: false, confirmButtonText: '回到 WeMo' });
  const [previewVisible, setPreviewVisible] = useState(false);
  const [backToLineState, dispatchBackToLine] = useBackToLine();
  const { location, error: locationError } = useGeoLocation();
  const { registerErrorConfig, launchError } = useErrorNotification();

  const handleOnCameraReady = useCallback(isReady => {
    setIsCameraReady(isReady);
  }, []);

  const handleOnError = useCallback(error => {
    console.log(error.name);
    // 先拍後上傳
    setMedia('picker');
  }, []);

  const setPhoto = useCallback((_photo, url) => {
    photo.current = _photo;
    setUrl(url);
    setStep('preview');
  }, []);

  const [handleOnCapture] = useDebouncedCallback(
    async () => {
      if (!canCapture.current) return;
      canCapture.current = false;
      const [photo, url] = await camera.current.capturePhoto();
      if (photo && url) {
        setPhoto(photo, url);
      } else {
        setMedia('picker');
      }
    },
    300,
    { leading: true, trailing: false }
  );

  const handleOnChange = useCallback(
    (photo, url) => {
      setPhoto(photo, url);
    },
    [setPhoto]
  );

  const handleOnRecapture = useCallback(() => {
    setStep('camera');
  }, []);

  const handleOnProgress = useCallback(
    event => {
      const { lengthComputable, loaded, total } = event;
      if (!lengthComputable) return;
      dispatchBackToLine.setProgress((loaded / total) * 100);
    },
    [dispatchBackToLine]
  );

  const handleOnConfirm = useCallback(async () => {
    const { rentId } = urlQuery;
    if (!rentId) {
      setShowAlert(prev => ({
        ...prev,
        visible: true,
        title: '上傳失敗',
        notes: '查無此租借紀錄',
      }));
      return;
    }
    dispatchBackToLine.switchVisible({ title: '照片壓縮中' });
    try {
      let [compressionPhoto] = await photoCompression.compress(photo.current, progress => {
        dispatchBackToLine.setProgress(progress);
      });

      const { lat, lng, altitude } = location;
      compressionPhoto = await setPhotoExifGps(compressionPhoto, lat, lng, altitude);

      dispatchBackToLine.resetProgress({ title: '照片上傳中' });
      await postUserReturnPhoto(rentId, compressionPhoto, handleOnProgress);
      dispatchBackToLine.setState({ title: '上傳完成' });
    } catch (error) {
      dispatchBackToLine.reset();
      setShowAlert(prev => ({
        ...prev,
        visible: true,
        title: '上傳失敗',
        notes: '伺服器發生異常狀況',
      }));
      throw error;
    }
  }, [dispatchBackToLine, handleOnProgress, urlQuery]);

  const handlePickerOnClick = useCallback(() => {
    canCapture.current = false;
    setMedia('picker');
  }, []);

  const handleBackToMini = useCallback(
    (hasPhoto = true) => {
      const { redirectTo, wemoToken, ...otherQuery } = urlQuery;
      window.location.href = `${process.env.REACT_APP_LINE_MINI_URL}${redirectTo}?${qs.stringify(
        otherQuery
      )}&hasPhoto=${hasPhoto}`;
    },
    [urlQuery]
  );

  const handleOnDestroyed = useCallback(isDestroyed => {
    canCapture.current = true;
  }, []);

  useEffect(() => {
    setPreviewVisible(step === 'preview');
  }, [step]);

  useEffect(() => {
    if (locationError) {
      launchError(handleLocationFail(locationError), 'location');
    }
  }, [locationError]);

  useEffect(() => {
    const { accessToken } = wemoToken.getToken();
    if (!accessToken) {
      const { wemoToken: accessToken } = urlQuery;
      if (!accessToken) history.replace('/errors/unauthorized');
      wemoToken.syncLineAxiosToken(accessToken);
    }
    registerErrorConfig('location', errorConfig);
  }, []);

  return (
    <Container>
      {media === 'camera' ? (
        <React.Fragment>
          <CameraV2
            parentComponentName="TakeReturnPhoto"
            ref={camera}
            overlay={
              <CameraOverlay coverBackgroundColor="transparent" hole={drawScooterWireframe} />
            }
            onReady={handleOnCameraReady}
            onError={handleOnError}
            onOpenPicker={handlePickerOnClick}
          />
          <CameraController
            isCameraReady={isCameraReady}
            onClick={handleOnCapture}
            isExceptionReturn={isExceptionReturn}
          />
        </React.Fragment>
      ) : (
        <CameraAlternative info={<TakePhotoInfo />} onChange={handleOnChange} />
      )}
      <PhotoPreviewer
        imgSrc={url}
        visible={previewVisible}
        controller={
          <PreviewController
            info={
              <Info
                contents={[
                  (() => <span>確認照片是否過於模糊、反光、清晰可辨</span>)(),
                  (() => (
                    <span>
                      如沒有問題請按 <strong>確認</strong>
                    </span>
                  ))(),
                ]}
              />
            }
            confirmText="確認"
            onRecapture={handleOnRecapture}
            onConfirm={handleOnConfirm}
          />
        }
        onDestroyed={handleOnDestroyed}
      />
      <BackToLine {...backToLineState} onClick={handleBackToMini} />
      <PopupNote zindex={7} {...showAlert} onConfirmClick={() => handleBackToMini(false)} />
    </Container>
  );
};

PureTakeReturnPhoto.defaultProps = {};

PureTakeReturnPhoto.propTypes = {};

const ReturnPhoto = React.memo(PureTakeReturnPhoto);
ReturnPhoto.whyDidYouRender = {
  customName: 'ReturnPhoto',
};
export default ReturnPhoto;

import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTransition } from 'react-spring';
import SnackbarItem from './SnackbarItem';
import css from '@styled-system/css';

const Container = styled.div`
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  flex-direction: column-reverse;
  z-index: 2;
  text-align: center;
  ${() =>
    css({
      padding: ['0 10px', '0 20px'],
    })};
`;

const transigionConfig = { tension: 125, friction: 20, precision: 0.1 };

export const Snackbars = forwardRef(({ maxCount, onEnter, onClose, message, duration }, ref) => {
  const [snackbars, setSnackbars] = useState([]);

  const snackbarTransition = useTransition(snackbars, snackbar => snackbar.key, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    onRest: (item, state) => {
      // TODO 這樣寫不好但一直碰到 bug
      // (寫 delay 在 leave 順序會混淆 / 寫 duration 在 from onRest 不會觸發)
      if (state === 'enter') {
        typeof onEnter && onEnter();
        setTimeout(() => {
          setSnackbars(snackbars => snackbars.filter(({ key }) => item.key !== key));
        }, item.config || duration);
      }
    },
    onDestroyed: item => {
      typeof item.onClose === 'function' && item.onClose();
    },
    config: transigionConfig,
  });

  useImperativeHandle(
    ref,
    () => ({
      enqueue: config => {
        setSnackbars([...snackbars, { ...config, key: Date.now() }]);
      },
    }),
    [snackbars]
  );

  return (
    <Container>
      {snackbarTransition.map(({ item, key, props }) => (
        <SnackbarItem key={key} style={props}>
          {item.message}
        </SnackbarItem>
      ))}
    </Container>
  );
});

Snackbars.defaultProps = {
  duration: 5000,
};

Snackbars.propTypes = {
  duration: PropTypes.number,
  message: PropTypes.string,
  onClose: PropTypes.func,
  isShown: PropTypes.bool,
};

export default React.memo(Snackbars);

import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import SantaClausImage from './xmas_mkt_03.png';
import asyncCancelable from '~/utils/asyncCancelable';

const customAxios = axios.create();
const InitStatus = {
  unInitialized: 0,
  Initialized: 1,
  Triggered: 2,
};
const imageWidth = 99;
const clientHeight = document.documentElement.clientHeight;
const clientWidth = document.documentElement.clientWidth;
dayjs.extend(isSameOrAfter);

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
  pointer-events: none;
  user-select: none;
`;

const SantaClausWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
`;

const bounce = (scale = 1) => keyframes`
  0% {
      transform: translate(0px, 0px) scaleX(${scale});
  }

  50% {
      transform: translate(0px, -10px) scaleX(${scale});
  }

  100% {
      transform: translate(0px, 0px) scaleX(${scale});
  }
`;

const SantaClaus = styled.img.attrs({ src: SantaClausImage })`
  animation: ${props => bounce(props.scale)} 0.6s infinite;
`;

const PureSantaClaus = props => {
  const isInit = useRef(0);
  const [currentRTL, setCurrentRTL] = useState(1);
  const [started, setStarted] = useState(false);
  const [springProps, setSpring, stopSpring] = useSpring(() => ({
    transform: `translate3d(-${imageWidth}px, 0px, 0px)`,
    onRest() {
      if (!started && isInit.current) {
        setCurrentRTL(prev => (prev === 1 ? -1 : 1));
        setStarted(true);
      }
    },
  }));

  useEffect(() => {
    const getCurrentTime = asyncCancelable(() =>
      customAxios
        .get('http://worldclockapi.com/api/json/utc/now')
        .then(resp => resp.data)
        .catch(() => ({ currentDateTime: '2020-12-21T16:00Z' }))
    );

    getCurrentTime().then(({ currentDateTime }) => {
      if (getCurrentTime.isCanceled) return;
      if (dayjs(currentDateTime).isSameOrAfter('2020-12-21T16:00Z')) {
        setStarted(true);
        isInit.current = InitStatus.Initialized;
      }
    });

    return () => {
      getCurrentTime.cancel();
      isInit.current = InitStatus.unInitialized;
      stopSpring();
    };
  }, []);

  useEffect(() => {
    if (started) {
      const delay =
        isInit.current === InitStatus.Initialized ? 0 : getRandomInt(3) + getRandomInt(3) + 2;
      const x = currentRTL === 1 ? clientWidth + imageWidth : -imageWidth;
      const y = getRandomInt(clientHeight + 1);
      setSpring({
        transform: `translate3d(${x}px, ${y}px, 0)`,
        delay: delay * 1000,
        config: { duration: 5000 },
      });
      setStarted(false);
      if (isInit.current === InitStatus.Initialized) isInit.current = InitStatus.Triggered;
    }
  }, [currentRTL, started, setSpring]);

  return (
    <Container>
      <SantaClausWrapper style={springProps}>
        <SantaClaus scale={currentRTL} />
      </SantaClausWrapper>
    </Container>
  );
};

export default React.memo(PureSantaClaus);

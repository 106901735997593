import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { layout, space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Button from '~/components/atoms/Button';
import { useDebouncedCallback } from 'use-debounce';

const CameraControllerWrapper = styled.div`
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  ${layout};
  ${space};

  ${props =>
    css({
      pointerEvents: props.isCameraReady ? 'auto' : 'none',
    })}
`;

const CaptureButton = styled(Button)`
  height: 60px;
  width: 60px;

  &:after {
    content: '';
    min-height: 30px;
    min-width: 30px;
    border: 2px solid #fff;
    background: transparent;
    border-radius: 100%;
  }
`;

const CaptureInfoPanel = styled.div`
  text-align: center;
  width: 100%;
  ${space};
`;

const CaptureInfoText = styled.p`
  ${themeGet(`fonts.body`)};
  margin: 0;
  color: #fff;
`;

const PureCameraController = React.memo(props => {
  const { isCameraReady, onClick, isExceptionReturn } = props;

  const [handleOnClick, cancelHandleOnClick] = useDebouncedCallback(
    () => {
      onClick();
    },
    300,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    return () => {
      cancelHandleOnClick();
    };
  }, []);

  return (
    <CameraControllerWrapper
      isCameraReady={isCameraReady}
      height={['169px', '194px']}
      p={['15px', '30px']}
    >
      <CaptureInfoPanel mb={['11px', '21px']}>
        <CaptureInfoText>
          {isExceptionReturn ? '拍完送出才算還車成功' : '請依框線拍攝機車車牌照片'}
        </CaptureInfoText>
        <CaptureInfoText>並請留意道路安全</CaptureInfoText>
      </CaptureInfoPanel>
      <CaptureButton shape="circle" onClick={handleOnClick} />
    </CameraControllerWrapper>
  );
});

PureCameraController.defaultProps = {
  onClick: () => {},
};

PureCameraController.propTypes = {
  isCameraReady: PropTypes.bool,
  onClick: PropTypes.func,
};

export default React.memo(PureCameraController);

import { getMockAdapter } from './mock';

const mockParkingLots = () => {
  const mock = getMockAdapter();
  mock.onGet('/v3/parkingLots').reply(200, {
    result: 'success',
    data: [
      {
        id: '1',
        lat: 23.12,
        lng: 121.45,
        name: 'WeMo 天空停車場',
        description: '來來來',
        isAvailable: true,
        businessHours: '24H',
        address: '慶城街1號',
        phone: '(02)242123124',
        totalSpaces: 300,
        availableSpaces: 0,
        images: {
          covers: ['https://storage.googleapis.com/wemo-backend/parkingLots/1/covers/01.png'],
          maps: ['https://storage.googleapis.com/wemo-backend/parkingLots/1/maps/01'],
        },
        scooters: [
          {
            id: '987-QHA',
            batPercent: 32,
            availableDistance: 10.2,
            groupId: '20',
            parkingSpaceId: '5566',
          },
        ],
        openType: 'automatic',
      },
    ],
  });
  mock.onGet(/\/v3\/parkingLots\/parkingInfo.*/).reply(200, {
    result: 'success',
    data: {
      isInParkingLot: true,
      isAroundParkingLot: false,
      parkingLotId: '1',
      // parkingLotId: null,
      inParkingBoxStatus: 'available',
      outParkingBoxStatus: 'available',
    },
  });
  mock.onGet(/v3\/parkingLots\/[\w\d]+/).reply(200, {
    result: 'success',
    data: {
      id: '4',
      lat: 25.0127136,
      lng: 121.4617905,
      nickname: '麗寶',
      businessHours: '24H',
      phone: '(02) 2252-6381',
      totalSpaces: 368,
      availableSpaces: 368,
      images: {
        covers: ['https://storage.googleapis.com/wemo-backend/parkingLots/4/covers/01.jpg'],
        maps: ['https://storage.googleapis.com/wemo-backend/parkingLots/4/maps/01.jpg'],
      },
      isAvailable: true,
      beginAt: null,
      zoneId: 40,
      state: {
        key: 'normal',
        reservable: true,
        returnable: true,
        inGateOpenable: true,
        outGateOpenable: true,
      },
      openType: 'parkingBox',
      scooters: [
        {
          id: 'EWA-8033',
          groupId: '0',
          batPercent: 84,
          availableDistance: 37.8,
          parkingSpaceId: null,
        },
        {
          id: 'EWJ-3597',
          groupId: '0',
          batPercent: 36,
          availableDistance: 16.2,
          parkingSpaceId: '305',
        },
        {
          id: '901-QHA',
          groupId: '0',
          batPercent: 64,
          availableDistance: 28.8,
          parkingSpaceId: '313',
        },
        {
          id: 'EWJ-2293',
          groupId: '0',
          batPercent: 49,
          availableDistance: 22.05,
          parkingSpaceId: '306',
        },
      ],
      name: '麗寶百貨地下停車場',
      description: '手機操作進出停車場',
      address: '新北市板橋區縣民大道二段3號',
    },
  });

  mock.onPost(/\/v3\/parkingLots\/[\w\d]+\/parkingSpaces/).reply(200, {});
  mock.onPost(/\/v3\/parkingLots\/[\w\d]+\/openInGate/).reply(200, {
    result: 'fail',
    error: {
      type: 'INTERNAL_SERVER_ERROR',
    },
  });
  mock.onPost(/\/v3\/parkingLots\/[\w\d]+\/openOutGate/).reply(200, {
    result: 'fail',
    error: {
      type: 'INTERNAL_SERVER_ERROR',
    },
  });
};

export default mockParkingLots;

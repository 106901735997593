import React, { useCallback } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import PropTypes from 'prop-types';
import NavigationBar from '~/components/organisms/NavigationBar';
import ErrorBoundary from '~/components/errorBoundaries/ErrorBoundary';
import { usePageInfo, useUserInfo } from '~/context/';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${({ autoHeight }) => (autoHeight ? 'auto' : '100%')};
  ${space};
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
  /* pointer-events: none; */
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  pointer-events: auto;
  ${space};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PureBasicContainer = ({
  children,
  base = 30,
  autoHeight = true,
  navigationBar,
  bind,
  ...props
}) => {
  const { pageInfo } = usePageInfo();
  const { user } = useUserInfo();

  return (
    <React.Fragment>
      {navigationBar && <NavigationBar user={user} pageInfo={pageInfo} />}
      <ErrorBoundary>
        <Container
          autoHeight={autoHeight}
          id="basic-container"
          ref={bind}
          {...props}
          pt={navigationBar ? [base / 2 + 29 + 9.5, base + 16 + 9.5] : [base / 2, base]}
          pb={[base / 2, base]}
          px={[base / 2, base]}
        >
          {children}
          {/* <Wrapper px={[base / 2, base]} pt={navigationBar ? [29 + 9.5, 16 + 9.5] : [0, 0]}>
          {children}
        </Wrapper> */}
        </Container>
      </ErrorBoundary>
    </React.Fragment>
  );
};

PureBasicContainer.defaultProps = {
  base: 30,
  navigationBar: true,
  autoHeight: true,
};

PureBasicContainer.propTypes = {
  navigationBar: PropTypes.bool,
  autoHeight: PropTypes.bool,
  base: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
};

const ForwardRefBasicContainer = React.forwardRef((props, ref) => {
  const bind = useCallback(
    node => {
      if (!ref) return;
      if ('current' in ref) {
        ref.current = node;
      }
      if (typeof ref === 'function') {
        ref(node);
      }
    },
    [ref]
  );
  return <PureBasicContainer {...props} bind={bind} />;
});
// ForwardRefBasicContainer.whyDidYouRender = {
//   customName: 'ForwardRefBasicContainer',
// };

export default ForwardRefBasicContainer;

import { hexToHsla, getHslaFromString } from '~/utils/colorConverter';

const countWithBoundary = (a, b, min, max) => {
  const result = parseFloat(a) + b;
  if (result < min) return min;
  if (result > max) return max;
  return result;
};

const getMiddleColor = color => {
  const { h, s, l, a } = getHslaFromString(color);
  return `hsla(${h},${s}%,${countWithBoundary(l, 1, 0, 100)}%,${a})`;
};

const getLastColor = color => {
  const { h, s, l, a } = getHslaFromString(color);
  return `hsla(${countWithBoundary(h, -23, 0, 360)},${countWithBoundary(
    s,
    5,
    0,
    100
  )}%,${countWithBoundary(l, 17, 0, 100)}%,${a})`;
};

const getLinearColor = color => {
  const { h, s, l, a } = getHslaFromString(color);
  return `hsla(${countWithBoundary(h, 2, 0, 360)},${s}%,${countWithBoundary(l, -7, 0, 100)}%,${a -
    0.3})`;
};

export const getGradientColors = pinHex => {
  const pinColorConfig = JSON.parse(
    localStorage.getItem(`PinColorConfig${pinHex.replace('#', '')}`)
  );
  if (pinColorConfig) return pinColorConfig;

  const color = hexToHsla(pinHex) || hexToHsla('#47CAE5');
  const colorMiddle = getMiddleColor(color);
  const colorLast = getLastColor(color);
  const colorLinear = getLinearColor(colorLast);
  const colorLinearLast = getLinearColor(color);

  localStorage.setItem(
    `PinColorConfig${pinHex.replace('#', '')}`,
    JSON.stringify({
      color,
      colorMiddle,
      colorLast,
      colorLinear,
      colorLinearLast,
    })
  );

  return { color, colorMiddle, colorLast, colorLinear, colorLinearLast };
};

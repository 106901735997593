import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/atoms/Button';
import styled, { withTheme } from 'styled-components';
import { useSpring, animated } from 'react-spring';

const useTimeout = (callback, time) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const event = () => {
      savedCallback?.current();
    };

    if (time) {
      let id = setTimeout(event, time);
      return () => clearTimeout(id);
    }
  }, [time]);
};

const Check = styled(animated.svg)`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transform: translate3d(0px, -50%, 0);
  top: 50%;
`;

const MaskComplete = styled(animated.div)`
  position: absolute;
  top: -50%;
  left: -100%;
  height: 200%;
  width: 100%;
  border-radius: 50px;
  transform: translate3d(0, 0, 0);
  /* transform: scale(0) rotate(0); */
`;

/*
  動畫需求
  - 按下時按鈕會 0.9 倍縮小
  - 會依照 width: duration / trigger time * 100% 由左往右蓋上一條 #00000014 遮罩
  - 若不滿 trigger time 觸碰結束，遮罩會由右往左消失，按鈕回復原來大小
  - 若按滿 trigger time ，按鈕大小即會回覆，同時淡入一個 `useless blue` 背景在按鈕上，中間出現一個打勾動畫
*/

const ButtonLongPress = ({
  time,
  children,
  hasCompleteState,
  onClick,
  theme,
  color,
  onCancel,
  shouldReset,
  ...buttonProps
}) => {
  const [isTouchNow, setIsTouchNow] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  const [{ springSize }, setButton] = useSpring(() => ({
    springSize: 1,
    onRest: setIsComplete(false),
  }));

  const [maskSpring, setMask] = useSpring(() => ({
    backgroundColor: color === 'plan' ? `${theme.colors.mainBlue}14` : '#00000014',
    // transform: `scale(0) rotate(0deg)`,
    transform: `translate3d(0%,0,0)`, // regular one
  }));

  const [svgStyle, setSvg] = useSpring(() => ({
    strokeDashoffset: 36,
  }));

  useTimeout(
    () => {
      onClick();
      handleCancel(false);
      setIsComplete(true);
      if (hasCompleteState) {
        setMask({
          // wave one
          to: async (next, cancel) => {
            await next({
              transform: `translate3d(100%,0,0)`, // regular one
              backgroundColor: `${theme.colors.uselessBlue}14`,
            });
            await next({
              transform: `translate3d(100%,0,0)`, // regular one
              backgroundColor: theme.colors.uselessBlue,
            });
          },
          config: { duration: 200 },
        });
        setSvg({ strokeDashoffset: 0, delay: 250, config: { tension: 300, clamp: false } });
      }
    },
    time && isTouchNow ? time : null
  );

  useEffect(() => {
    shouldReset &&
      setTimeout(() => {
        setIsComplete(false);
        setMask({
          to: {
            backgroundColor: color === 'plan' ? `${theme.colors.mainBlue}14` : '#00000014',
            transform: `translate3d(0%,0,0)`,
          },
          immediate: true, // regular one
        });
        setSvg({ to: { strokeDashoffset: 36 }, immediate: true });
      }, 200);
  }, [shouldReset]);

  const scale = springSize.interpolate({
    range: [0, 0.75, 1],
    output: ['scale(0.9)', 'scale(0.9)', 'scale(1)'],
    extrapolate: 'clamp',
  });

  const handleCancel = (shoudCancel = true) => {
    setIsTouchNow(false);
    setStartTime(null);
    setButton({
      springSize: 1,
    });
    setMask({
      // transform: `scale(0) rotate(0deg)`,
      transform: `translate3d(0%,0,0)`, // regular one
    });
    shoudCancel && typeof onCancel === 'function' && onCancel();
  };

  const handleTouchStart = () => {
    setIsTouchNow(true);
    setButton({
      to: { springSize: 0 },
      immediate: false,
    });
    setMask({
      // transform: `scale(3) rotate(135deg)`, // wave one
      transform: `translate3d(100%,0,0)`,
      config: { duration: time },
      immediate: false,
    });
    setStartTime(Date.now());
  };

  const handleTouchMove = evt => {
    const element = document.elementFromPoint(evt.touches[0].clientX, evt.touches[0].clientY);

    if (element?.tagName !== 'BUTTON' && element.parentElement?.tagName !== 'BUTTON')
      handleCancel();
  };

  const handleTouchEnd = evt => {
    if (startTime && Date.now() - startTime < time) handleCancel();
  };

  return (
    <Button
      type="float"
      shape="capsule"
      color={color}
      hasAnimation={false}
      {...buttonProps}
      {...((!hasCompleteState || (hasCompleteState && !isComplete)) && {
        onTouchStart: handleTouchStart,
      })}
      {...(isTouchNow && { onTouchMove: handleTouchMove, onTouchEnd: handleTouchEnd })}
      style={{ transform: scale, ...(isComplete && { backgroundColor: '#00000014' }) }}
    >
      {children}
      <MaskComplete style={maskSpring} />
      {hasCompleteState && (
        <Check
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <animated.path
            d="M5 17.4286L13.6897 26L26 6"
            stroke={theme.colors.mainBlue}
            strokeDasharray={36}
            strokeWidth="3"
            style={svgStyle}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </Check>
      )}
    </Button>
  );
};

ButtonLongPress.defaultProps = {
  time: 1000,
  hasCompleteState: false,
};

ButtonLongPress.propTypes = {
  time: PropTypes.number,
  onClick: PropTypes.func,
  onCancel: PropTypes.func,
  hasCompleteState: PropTypes.bool,
};

export default withTheme(ButtonLongPress);

import ExpiredStorage from 'expired-storage';
import jwt from 'jsonwebtoken';
import uniqid from 'uniqid';
import { verify as verifyIdToken, sign as signIdToken } from './lineIdToken';
import { NoLIFF, LineNotLoggedIn, NoLineIdToken, IsDifferentUser } from '~/errors';

const ExpireIn = 60 * 60;
const SecretKeyFieldName = 'ejs';
const expiredStorage = new ExpiredStorage();

export const sign = payload => {
  if (!window.liff) throw new NoLIFF();
  if (process.env.NODE_ENV === 'production') {
    if (!window.liff.isLoggedIn()) throw new LineNotLoggedIn();
  }

  const idToken = signIdToken();
  const secretKey = uniqid();
  const token = jwt.sign(
    {
      idToken,
      ...payload,
    },
    secretKey,
    {
      issuer: 'WeMo Scooter Line Mini',
      expiresIn: ExpireIn,
    }
  );
  expiredStorage.removeItem(SecretKeyFieldName);
  expiredStorage.setItem(SecretKeyFieldName, secretKey, ExpireIn);
  return token;
};

export const verify = async token => {
  if (!window.liff) throw new NoLIFF();
  if (process.env.NODE_ENV === 'production') {
    if (!window.liff.isLoggedIn()) throw new LineNotLoggedIn();
  }

  const secretKey = expiredStorage.getItem(SecretKeyFieldName);
  if (!secretKey) {
    return false;
  }

  try {
    const payload = jwt.verify(token, secretKey);
    const { idToken } = payload;
    if (!idToken) throw new NoLineIdToken();
    const { sub: _userId } = verifyIdToken(idToken);
    if (process.env.NODE_ENV === 'production') {
      const { userId } = await window.liff.getProfile();
      if (userId !== _userId) throw new IsDifferentUser();
    }
    // development 的 payload 是假的
    return payload;
  } catch (error) {
    console.log(error.name);
    return false;
  }
};

export const getSecretKey = () => expiredStorage.getItem(SecretKeyFieldName);

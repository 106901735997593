import React, { useState } from 'react';
import styled from 'styled-components';
import { layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { useDebouncedCallback } from 'use-debounce';
import PageHeader from '~/components/molecules/PageHeader';
import Button from 'Components/atoms/Button';
import Popup from '~/components/atoms/Popup';

const Container = styled.div`
  width: 100%;
`;

const PopupTitle = styled.h2`
  ${themeGet('fonts.subtitle')}
  margin: 0;
  text-align: center;
`;

const PopupContentPanel = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  ${layout};
`;

const PopupContent = styled.p`
  ${themeGet('fonts.body2')}
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
`;

const PopupButton = styled(Button)`
  ${themeGet('fonts.h2Button')};
  color: #fff;
  height: 50px;
  width: 100%;
  margin-top: 36px;
`;

const PureHeader = () => {
  const [visible, setVisible] = useState(false);

  const [handleOnClick] = useDebouncedCallback(
    () => {
      setVisible(prev => !prev);
    },
    300,
    { leading: true, trailing: false }
  );

  return (
    <Container>
      <PageHeader
        title="發票設定"
        hint={
          <React.Fragment>
            為了響應環保，未得獎者 WeMo Scooter 將不提供紙本發票索取。讓我們一起愛護這個地球
            <span role="img" aria-label="">
              🌲🌳
            </span>
          </React.Fragment>
        }
        hasInfo={true}
        infoOnClick={handleOnClick}
      />
      <Popup visible={visible}>
        <PopupTitle>為什麼要發票設定？</PopupTitle>
        <PopupContentPanel height={['195px', '275px']}>
          <PopupContent>店家載具： WeMo</PopupContent>
          <PopupContent>
            Scooter 將使用您的聯絡信箱作為店家載具，中獎後將以信件方式寄出中獎通知，請得獎 7-11
            ibon 列印發票。列印方式：ibon「生活服務」>「電子發票」>「威摩科技」並完成後續指示，即可完成列印。
          </PopupContent>
          <br />
          <PopupContent>手機條碼：</PopupContent>
          <PopupContent>將發票存入手機條碼中。</PopupContent>
          <br />
          <PopupContent>捐贈：</PopupContent>
          <PopupContent>將發票捐贈。</PopupContent>
          <br />
          <PopupContent>自然人憑證：</PopupContent>
          <PopupContent>將發票存入自然人憑證。</PopupContent>
          <br />
          <PopupContent>統一編號：</PopupContent>
          <PopupContent>
            發票會開立統一編號，並在七個工作天內，寄出電子發票至您的聯絡信箱。
          </PopupContent>
        </PopupContentPanel>
        <PopupButton shape="capsule" type="float" boxShadow="light" onClick={handleOnClick}>
          確認
        </PopupButton>
      </Popup>
    </Container>
  );
};

export default React.memo(PureHeader);

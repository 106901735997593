import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const InfoPanel = styled.div`
  text-align: center;
  width: 100%;
  padding: 0 20px;
`;

const InfoText = styled.p`
  ${themeGet(`fonts.body`)};
  margin: 0;
  color: #fff;
`;

const PureTakePhotoInfo = props => {
  return (
    <InfoPanel>
      <InfoText>請避免照片過於模糊、反光</InfoText>
      <InfoText>確認照片清晰可辨</InfoText>
    </InfoPanel>
  );
};

export default React.memo(PureTakePhotoInfo);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, position } from 'styled-system';
import { useSpring, animated, config } from 'react-spring';
import { ReactComponent as Scooter } from 'Images/icons/scooter.svg';
import { ReactComponent as Parking } from 'Images/icons/parking.svg';
import { themeGet } from '@styled-system/theme-get';

const SwitchStyled = styled.div`
  width: 103px;
  height: 24px;
  border-radius: 50px;
  position: relative;
  background-color: ${props => props.theme.colors.unavailableGrey};
`;

const Option = styled.div`
  display: inline-block;
  width: 50%;
  text-align: center;
  padding-top: 2px;
  position: absolute;
  padding: 4px;
  z-index: 2;

  &:nth-child(2) {
    right: 0;
  }
`;

const Icon = styled.div`
  fill: ${props =>
    props.active === 'true'
      ? themeGet(`colors.mainBlue`)(props)
      : themeGet(`colors.supGrey`)(props)};
`;

const ActiveTab = styled(animated.div)`
  position: absolute;
  top: 0;
  background-color: white;
  border-radius: 50px;
  width: 58px;
  z-index: 1;
  height: 100%;
  ${props => props.theme.boxShadows.light};
`;

const Switch = ({ defaultTab, currentTab: currentTabSet, onSwitch, ...switchProps }) => {
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const props = useSpring({
    transform: `translate3d(${50 * currentTab}%,0,0)`,
    left: currentTab !== 0 ? 18 : 0,
    config: config.stiff,
  });

  const handleOnSwitch = index => {
    setCurrentTab(index);
    typeof onSwitch === 'function' && onSwitch(index);
  };

  useEffect(() => {
    setCurrentTab(currentTabSet);
  }, [currentTabSet]);

  return (
    <SwitchStyled {...switchProps}>
      <Option onClick={() => handleOnSwitch(0)}>
        <Icon as={Scooter} active={`${currentTab === 0}`} />
      </Option>
      <Option onClick={() => handleOnSwitch(1)}>
        <Icon as={Parking} active={`${currentTab === 1}`} />
      </Option>
      <ActiveTab style={props} />
    </SwitchStyled>
  );
};

Switch.defaultProps = {
  defaultTab: 0,
};

Switch.propTypes = {
  defaultTab: PropTypes.number,
  onSwitch: PropTypes.func,
};

export default Switch;

/**
 * Example:
 *
 * const getVersion = asyncCancelable(() => axios.get('https://kottos-qat.wemoscooter.com/'));
 * (async () => {
 *   getVersion.cancel();
 *   getVersion().then(console.log); // { isCanceled: true }
 * })();
 */
const asyncCancelable = promise => {
  let isCanceled = false;

  if (!promise) throw new Error('promise is undefined');

  const wrappedPromise = (...args) => {
    return new Promise((resolve, reject) => {
      promise(...args)
        .then(
          val => (isCanceled ? reject({ isCanceled }) : resolve(val)),
          error => (isCanceled ? reject({ isCanceled }) : reject(error))
        )
        .then(() => {
          isCanceled = false;
        });
    });
  };

  const cancel = () => {
    isCanceled = true;
  };

  const reset = () => {
    isCanceled = false;
  };

  return new Proxy(promise, {
    get(target, prop) {
      if (prop === 'cancel') return cancel;
      if (prop === 'reset') return reset;
      if (prop === 'isCanceled') return isCanceled;
      return target[prop];
    },
    apply(target, thisArg, argumentsList) {
      return wrappedPromise(...argumentsList);
    },
  });
};

export default asyncCancelable;

export const hexToHsla = hex => {
  var color = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2}){0,1}$/i.exec(hex);
  if (!color) return;

  const r = parseInt(color[1], 16) / 255;
  const g = parseInt(color[2], 16) / 255;
  const b = parseInt(color[3], 16) / 255;
  const a = parseInt(color[4] || 'ff', 16) / 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h, s, l;

  l = (max + min) / 2;

  if (max === min) {
    h = 0;
    s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h = h / 6;
  }
  return `hsla(${360 * h},${s * 100}%,${l * 100}%,${a})`;
};

export const getHslaFromString = hslaString => {
  const match = /hsla\(([\d.]+),([\d.]+)%,([\d.]+)%,([\d.]+)\)/.exec(hslaString);
  if (!match) return;
  const [, h, s, l, a] = match;
  return { h, s, l, a };
};

export const getColorAndAlphaFromHex = hex => [hex.slice(0, 7), parseInt(hex.slice(7), 16) / 255];

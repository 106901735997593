export const googleMapURL =
  'AIzaSyATCWyBh4urme6ddoy85IB9kf-tQ87dxCo&libraries=geometry,places,visualization';

export const googleMapURL2 =
  'AIzaSyCmCMMs6zYmQtg51fKE8b3Ua6Qtc245V4o&libraries=geometry,places,visualization';
/**
 * WeMo Console project key
 */

export const googleMapURLWemoQat = 'AIzaSyCKlpEWSfXs0Zha4-TjyZMGq0JoOKFcUlY';

// steal from IOS code
// const PromotionStatus = {
//   active: 'active',
//   inactive: 'inactive',
// };

export const wemoPhone = '0809091880';

const uiRentStatus = {
  viewing: 'VIEWING', // not even a guet(has no token)
  guest: 'GUEST', // for guest (has guest token)
  confirming: 'CONFIRMING',
  reserveParkingLot: 'RESERVE_PARKING_LOT',
  rentingParkingLot: 'RENTING_PARKING_LOT',
  none: 'None',
};

// Note: all possible states for a Scooter rent
export const rentStatus = {
  ...uiRentStatus,
  renting: 'RENTING',
  routeToScooter: 'ROUTE_TO_SCOOTER',
  overtime: 'OVER_TIME',
  notRenting: 'NOT_RENTING',
};

export const scooterStatusMap = {
  0: 'stop',
  1: 'start',
};

export const scooterStatus = {
  stop: 'stop',
  start: 'start',
};

// // Note: all possible states for an user's payment
// const paymentStatus = {
//   normal: 0,
//   invalidCreditCard: 1,
//   insufficientBalance: 2,
//   unpaidAmount: 3,
//   checking: 4,
// };

// //All possible states for scooter renting's failure
// const rentErrorStatus = {
//   tokenFail: 'Token fail',
//   noUserPayment: 'User has no payment',
//   cantConnectScooter: 'Could not connect this scooter',
// };

export const paymentTypes = {
  /** 信用卡 */
  creditCard: 'creditCard',
  /** WeMo 錢包 */
  wallet: 'wallet',
  /** Line Pay */
  linePay: 'linePay',
  /** Apple Pay */
  applePay: 'applePay',
};

export const paymentKeys = {
  /** 信用卡 */
  creditCard: 1,
  /** WeMo 錢包 */
  wallet: 2,
  /** Line Pay */
  linePay: 3,
  /** Apple Pay */
  applePay: 4,
};

export const userStatus = {
  disable: 'disable',
  active: 'active', //
  pending: 'pending',
  incomplete: 'incomplete', //
  reject: 'reject',
  unconfirmed: 'unconfirmed', //
  unpassed: 'unpassed',
  updated: 'updated', //
  ban: 'ban',
  incomplete30: 'incomplete30',
};

// if the user status is ban or reject, login will fail
export const userStatusMap = {
  0: userStatus.disable,
  1: userStatus.active,
  2: userStatus.pending,
  3: userStatus.incomplete,
  4: userStatus.reject, // login failed
  5: userStatus.unconfirmed,
  6: userStatus.unpassed,
  7: userStatus.updated,
  8: userStatus.ban, // login failed
  9: userStatus.incomplete30,
};

export const userStatusWithRejectReasons = [
  userStatus.incomplete30,
  userStatus.incomplete,
  userStatus.unpassed,
  userStatus.reject,
];

export const rejectReasonType = {
  profile: 'profile',
  identity: 'identity',
  license: 'license',
  selfie: 'selfie',
};

export const rejectReasonTypeMap = {
  1: 'profile',
  2: 'identity',
  3: 'license',
  4: 'selfie',
};

export const invoiceTypes = {
  cetustek: 0,
  phone: 1,
  donate: 2,
  citizen: 3,
  taxIdNo: 4,
};

export const invoiceKeys = {
  0: 'cetustek',
  1: 'phone',
  2: 'donate',
  3: 'citizen',
  4: 'taxIdNo',
};

export const invoiceTexts = {
  cetustek: '店家載具',
  phone: '手機條碼',
  donate: '捐贈',
  citizen: '自然人憑證',
  taxIdNo: '統一編號',
};

// 參考 android
export const userEvents = {
  keyOn: 'KEY_ON', // '手機啟動位置' v
  reserve: 'RESERVE', // '手機訂車位置'
  return: 'RETURN', // '手機還車位置'
  flash: 'FLASH', // '手機閃燈位置'
  rent: 'RENT', // '手機租車位置'
  keyOff: 'KEY_OFF', // '手機熄火位置'
  openTrunk: 'OPEN_TRUNK', // '手機開車廂位置'
  cancel: 'CANCEL', // '取消預約'
};

export const emergencyWaitingTime = 15 * 60 * 1000;

export const emergencyWorkerCmd = {
  GetResults: 'getResults',
  Message: 'message',
  Start: 'start',
  Stop: 'stop',
};

export const registerPhotosKeySequence = [
  'TakePositiveIdentity',
  'TakeNegativeIdentity',
  'TakePositiveLicense',
  'TakeNegativeLicense',
  'TakeFace',
];

export const parkingLotStatusMap = {
  normal: 'normal',
  errorBothDisabled: 'errorBothDisabled',
  aboutToClose: 'aboutToClose',
  closed: 'closed',
};

export const parkingLotStatusUiMap = {
  inParkingLotClosed: 'inParkingLotClosed',
  inParkingLotError: 'inParkingLotError',
  outParkingLotClosed: 'outParkingLotClosed',
  outParkingLotError: 'outParkingLotError',
  noSpace: 'noSpace',
  normal: 'normal',
  noParkingLot: 'noParkingLot',
};

export const parkingLotOpenTypes = {
  gateless: 'gateless',
  automatic: 'automatic',
  etag: 'etag',
  parkingBox: 'parkingBox',
};

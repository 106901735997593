import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FaceImage from 'Images/face.png';
import TakePhotoButton from 'Components/atoms/TakePhotoButton';

const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const PureFacePhotoButton = props => {
  const { isCaptured, facingMode, onChange, display } = props;
  return (
    <TakePhotoButton
      color="plan"
      shape="circle"
      width={['250px', '325px']}
      height={['250px', '325px']}
      isCaptured={isCaptured}
      unCapturedText="點擊拍攝自拍照"
      facingMode={facingMode}
      display={display}
      onChange={onChange}
    >
      <Image src={FaceImage} />
    </TakePhotoButton>
  );
};

PureFacePhotoButton.defaultProps = {
  isCaptured: false,
  facingMode: 'user',
  display: 'inline-block',
};

PureFacePhotoButton.propTypes = {
  isCaptured: PropTypes.bool,
  facingMode: PropTypes.oneOf(['user', 'environment']),
  onChange: PropTypes.func,
  display: PropTypes.string,
};

export default React.memo(PureFacePhotoButton);

import Quagga from 'quagga';
import delay from './delay';
import asyncLoadImage from '~/utils/asyncLoadImage';

export class NoCodeResultException extends Error {
  name = 'NoCodeResult';
  constructor(message) {
    super(message || 'no code result');
  }
}

export default timeout => {
  let mainCanceled = false;

  const mainCancel = () => {
    mainCanceled = true;
  };

  const createClock = () => {
    let clockCanceled = false;
    const clockCancel = () => {
      clockCanceled = true;
    };

    const fire = async () =>
      delay(timeout)
        .then(() => {
          if (!clockCanceled) mainCancel();
          return;
        })
        .catch(error => {
          if (!clockCancel) throw error;
          throw { reason: 'cancelled' };
        });

    return [fire, clockCancel];
  };

  const scan = async (base64Code, resolution) => {
    const [clockFire, clockCancel] = createClock();
    clockFire();

    return new Promise((resolve, reject) => {
      Quagga.decodeSingle(
        {
          src: base64Code,
          decoder: {
            readers: ['code_128_reader'], // List of active readers
          },
          inputStream: {
            size: resolution,
          },
          locate: true,
          locator: {
            patchSize: 'medium',
            halfSample: true,
          },
        },
        result => {
          clockCancel();
          if (result && result.codeResult) {
            console.log(result.codeResult.code);
            resolve(result.codeResult.code);
          } else {
            reject(new NoCodeResultException());
          }
        }
      );
    })
      .then(result => {
        if (mainCanceled) return;
        return result;
      })
      .catch(error => {
        if (!mainCanceled) throw error;
        throw { reason: 'cancelled' };
      });
  };

  return [scan, mainCancel];
};

export const videoScanBarcode = async (capturePhoto, resolution) => {
  while (true) {
    const [_, url] = await capturePhoto();
    if (url) {
      const result = await new Promise((resolve, reject) => {
        Quagga.decodeSingle(
          {
            src: url,
            decoder: {
              readers: ['code_128_reader'], // List of active readers
            },
            inputStream: {
              size: resolution,
            },
            locate: true,
            locator: {
              patchSize: 'medium',
              // halfSample: true,
            },
            numOfWorkers: Math.ceil(navigator.hardwareConcurrency / 2),
          },
          result => {
            resolve(result);
          }
        );
      });
      if (result && result.codeResult) {
        console.log(result);
        break;
      } else {
        console.log('Fail');
      }
    }
    await delay(100);
    console.log(Date.now());
  }
};

// videoStream => {
//   Quagga.init(
//     {
//       inputStream: {
//         name: 'Live',
//         type: 'LiveStream',
//         target: videoStream,
//       },
//       decoder: {
//         readers: ['code_128_reader'], // List of active readers
//       },
//       locator: {
//         patchSize: 'medium',
//         halfSample: true,
//       },
//       frequency: 10,
//       locate: true,
//     },
//     error => {
//       console.log(error);
//       console.log('123');
//       Quagga.start();
//     }
//   );

//   Quagga.onDetected(result => {
//     console.log('onDetected: ', result);
//     Quagga.stop();
//   });
// };

const defaultPatchSizes = ['medium', 'large', 'small', 'x-large', 'x-small'];
const defaultImagesSize = [1600, 1440, 1280, 1920, 550];

const asyncDecodeSingle = async (imageUrl, size, patchSize) => {
  return new Promise(resolve => {
    Quagga.decodeSingle(
      {
        src: imageUrl,
        inputStream: {
          size, // restrict input-size to be 800px in width (long-side)
        },
        decoder: {
          readers: ['code_128_reader'], // List of active readers
        },
        locator: {
          patchSize,
          halfSample: true,
        },
        locate: true,
        numOfWorkers: window.navigator.hardwareConcurrency || 2,
      },
      function(result) {
        resolve(result);
      }
    );
  });
};

export const scanImageBarcode = async imageUrl => {
  const image = await asyncLoadImage(imageUrl);
  const size = image.width > image.height ? image.width : image.height;
  const imagesSize = [size, ...defaultImagesSize];
  const results = {};
  for (const patchSize of defaultPatchSizes) {
    for (const imageSize of imagesSize) {
      const result = await asyncDecodeSingle(imageUrl, imageSize, patchSize);
      if (result?.codeResult?.code) {
        if (result.codeResult.code in results) {
          results[result.codeResult.code]++;
        } else {
          results[result.codeResult.code] = 1;
        }
      }
    }
  }

  const resultsCodeAndCount = Object.entries(results);
  if (!resultsCodeAndCount.length) return undefined;

  let resultIndex = 0;
  resultsCodeAndCount.forEach(([_, count], index) => {
    if (index === 0) return;
    if (count > resultsCodeAndCount[resultIndex][1]) {
      resultIndex = index;
    }
  });

  return resultsCodeAndCount[resultIndex][0];
};

function* jobGenerator(imageUrl, strict = false) {
  const image = yield asyncLoadImage(imageUrl);
  const size = image.width > image.height ? image.width : image.height;
  const imagesSize = [size, ...defaultImagesSize];
  const results = {};
  let isBreak = false;

  for (const patchSize of defaultPatchSizes) {
    for (const imageSize of imagesSize) {
      const result = yield asyncDecodeSingle(imageUrl, imageSize, patchSize);
      if (result?.codeResult?.code) {
        if (result.codeResult.code in results) {
          results[result.codeResult.code]++;
        } else {
          results[result.codeResult.code] = 1;
        }
        if (!strict) isBreak = true;
        break;
      }
    }
    if (isBreak) break;
  }

  const resultsCodeAndCount = Object.entries(results);
  if (!resultsCodeAndCount.length) return undefined;

  let resultIndex = 0;
  yield resultsCodeAndCount.forEach(([_, count], index) => {
    if (index === 0) return;
    if (count > resultsCodeAndCount[resultIndex][1]) {
      resultIndex = index;
    }
  });

  return resultsCodeAndCount[resultIndex][0];
}

export const createScanImageBarcode = () => {
  let job;

  const cancelScanImageBarcode = () => {
    if (job) {
      job.return(undefined);
      Quagga.stop();
      job = undefined;
    }
  };

  const beginScanImageBarcode = () => {
    cancelScanImageBarcode();
    return async (imageUrl, strict) => {
      job = jobGenerator(imageUrl, strict);
      let result;
      while (true) {
        if (!job) return undefined;
        const { value, done } = job.next(result);
        if (done) return value;
        result = await value;
      }
    };
  };

  return { beginScanImageBarcode, cancelScanImageBarcode };
};

import React, { useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { createTextMaskInputElement } from 'text-mask-core';
import { size } from './style';

const InputStyled = styled.input`
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  line-height: 1;
  height: 100%;
  width: 100%;
  color: ${themeGet('colors.mainDark')};
  text-align: ${props => props.align};
  -webkit-tap-highlight-color: transparent;
  ${size}

  &::placeholder {
    color: ${themeGet('colors.secondaryGrey')};
    padding-top: 2px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s; /*透明*/
    -webkit-text-fill-color: ${themeGet('colors.mainDark')};
  }
`;

const initTextMask = (props, inputRef, maskRef) => {
  if (!inputRef.current) return;
  const { value, ...otherProps } = props;
  maskRef.current = createTextMaskInputElement({
    inputElement: inputRef.current,
    ...otherProps,
  });
  maskRef.current.update(value);
};

export const PureMaskInput = props => {
  const { value, onChange, onBlur } = props;
  const {
    dataIndex,
    mask,
    guide,
    placeholderChar,
    showMask,
    keepCharPositions,
    pipe,
    ...inputProps
  } = props;
  const inputRef = useRef();
  const maskRef = useRef();

  const handleOnChange = useCallback(
    event => {
      if (maskRef.current) {
        maskRef.current.update();
      }

      if (typeof onChange === 'function') {
        onChange(event);
      }
    },
    [onChange]
  );

  const handleOnBlur = useCallback(
    event => {
      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    },
    [onBlur]
  );

  useEffect(() => {
    initTextMask(
      { value, mask, guide, placeholderChar, showMask, keepCharPositions, pipe },
      inputRef,
      maskRef
    );
  }, [value, guide, keepCharPositions, mask, pipe, placeholderChar, showMask]);

  return (
    <InputStyled ref={inputRef} {...inputProps} onChange={handleOnChange} onBlur={handleOnBlur} />
  );
};

PureMaskInput.defaultProps = {
  placeholderChar: '•',
};

PureMaskInput.propTypes = {};

export default React.memo(PureMaskInput);

import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';

const TackPhotoInfoHeader = styled.div`
  ${themeGet('fonts.h3Button')}
  width: 100%;
  color: #fff;
  user-select: none;

  > * {
    width: 100%;
    text-align: center;
  }
`;

const TackPhotoInfoBody = styled.div`
  ${themeGet('fonts.body2')}
  margin-top: 4px;
  width: 100%;
  text-align: center;
  color: #fff;
  user-select: none;
`;

const PureTakePhotoInfo = props => {
  const { header, body } = props;

  return (
    <React.Fragment>
      <TackPhotoInfoHeader>{header}</TackPhotoInfoHeader>
      <TackPhotoInfoBody>{body}</TackPhotoInfoBody>
    </React.Fragment>
  );
};

PureTakePhotoInfo.propTypes = {
  header: PropTypes.element,
  body: PropTypes.element,
};

export default PureTakePhotoInfo;

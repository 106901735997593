import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Person from 'Components/molecules/Person';
import { Link } from 'react-router-dom';
import { ReactComponent as Credit } from 'Images/icons/credit.svg';
import { ReactComponent as Help } from 'Images/icons/help.svg';
import { ReactComponent as User } from 'Images/icons/user.svg';
import { ReactComponent as Pin } from 'Images/icons/wire-pin.svg';
import { ReactComponent as Present } from 'Images/icons/wire-present.svg';
import { themeGet } from '@styled-system/theme-get';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router-dom';

const NavStyled = styled.div`
  width: 240px;
  padding: 20px;
  background: white;
  height: 100%;
`;

const menuConfig = [
  { name: 'map', title: '回到地圖', path: '/', icon: Pin },
  { name: 'profile', title: '我的帳戶', path: '/account', icon: User },
  { name: 'payment', title: '付款設定', path: '/payment', icon: Credit },
  { name: 'invite', title: '邀請朋友', path: '/invitation', icon: Present },
  { name: 'help', title: '幫助', path: '/help', icon: Help },
];

const Ulstyled = styled.ul`
  list-style: none;
  padding: 0;
`;

const List = styled.li`
  padding: 14px 0;
`;

const LinkNoHighlight = styled(Link)`
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
`;

const NavLinkStyled = styled(LinkNoHighlight)`
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 24px;
  ${props => themeGet(`fonts.h2Button`)};
`;

const PrivacyLinkStyled = styled.a`
  color: ${props => themeGet(`colors.grey3`)};
  text-decoration-line: underline;
`;

const Privacy = styled.div`
  position: absolute;
  bottom: 40px;
  ${props => themeGet(`fonts.note`)};
  color: ${props => themeGet(`colors.grey3`)};
`;

const Version = styled.p`
  margin: 0;
  margin-top: 10px;
  color: ${themeGet(`colors.grey3`)};
`;

const Icon = styled.img`
  margin-right: 13px;
  vertical-align: top;
`;

const Nav = ({ user, className, onClick }) => {
  const history = useHistory();

  const openExternalBrowser = url => {
    if (window.liff && url) {
      window.liff.openWindow({
        url,
        external: true,
      });
      window.liff.closeWindow();
    }
  };

  const [handleOnClick] = useDebouncedCallback(
    pathName => {
      onClick();
      if (history.location.pathname === pathName) {
        // history.push({ search: '' });
      }
    },
    300,
    { leading: true, trailing: false }
  );

  return (
    <NavStyled className={className}>
      <LinkNoHighlight to="/account" replace={true} onClick={() => handleOnClick('/account')}>
        <Person
          picture={user?.line?.picture}
          name={user?.line?.displayName || user?.firstName}
          userCategoryName={user?.userCategoryName}
          type="nav"
        />
      </LinkNoHighlight>
      <nav>
        <Ulstyled>
          {menuConfig.map(menu => (
            <NavLinkStyled
              key={menu.name}
              to={menu.path}
              replace={true}
              onClick={() => handleOnClick(menu.path)}
            >
              <Icon as={menu.icon} />
              <List>{menu.title}</List>
            </NavLinkStyled>
          ))}
        </Ulstyled>
      </nav>
      <Privacy>
        <PrivacyLinkStyled
          onClick={() =>
            openExternalBrowser(
              'https://storage.googleapis.com/wemo-config/terms-and-conditions/index.html'
            )
          }
        >
          使用者條款
        </PrivacyLinkStyled>
        及
        <PrivacyLinkStyled
          onClick={() => openExternalBrowser('https://www.wemoscooter.com/privacy-policy')}
        >
          隱私權聲明
        </PrivacyLinkStyled>
        <Version>版本：{process.env.REACT_APP_VERSION}</Version>
      </Privacy>
    </NavStyled>
  );
};

Nav.defaultProps = {};

Nav.propTypes = {
  user: PropTypes.object,
  onClick: PropTypes.func,
};

export default Nav;

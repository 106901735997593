import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space } from 'styled-system';
import { ReactComponent as VisaIcon } from '~/images/icons/credit-card/Visa.svg';
import { ReactComponent as MasterIcon } from '~/images/icons/credit-card/Master.svg';
import { ReactComponent as JcbIcon } from '~/images/icons/credit-card/JCB.svg';
import { ReactComponent as UnionPayIcon } from '~/images/icons/credit-card/Union-pay.svg';
import { ReactComponent as AmexIcon } from '~/images/icons/credit-card/Amex.svg';
import { ReactComponent as UnknownIcon } from '~/images/icons/credit-card/Unknown.svg';
import light from '~/themes/light';

const getIconSrc = (type = '') => {
  switch (
    type
      .replace(' ', '')
      .replace('-', '')
      .toLowerCase()
  ) {
    case 'visa':
    case '1':
      return VisaIcon;
    case 'mastercard':
    case '2':
      return MasterIcon;
    case 'jcb':
    case '3':
      return JcbIcon;
    case 'unionpay':
    case '4':
      return UnionPayIcon;
    case 'americanexpress':
    case '5':
      return AmexIcon;

    default:
      return UnknownIcon;
  }
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  opacity: ${({ hazy }) => (hazy ? 0.5 : 1)};
`;

const Icon = styled.img`
  ${space}
`;

const Last4Digits = styled.span`
  ${props => themeGet(props.fontStyle)};
`;

export const PureCreditCardInfo = props => {
  const {
    children,
    type,
    last4Digits,
    hazy,
    prefix,
    iconMarginRight,
    last4DigitsFontStyle,
  } = props;
  return (
    <Container hazy={hazy}>
      <Icon as={getIconSrc(type)} mr={iconMarginRight} />
      {children ? (
        children
      ) : (
        <Last4Digits fontStyle={last4DigitsFontStyle}>
          {prefix} {last4Digits}
        </Last4Digits>
      )}
    </Container>
  );
};

PureCreditCardInfo.defaultProps = {
  hazy: false,
  prefix: '••••••',
  iconMarginRight: ['10px', '20px'],
  last4DigitsFontStyle: 'fonts.body',
};

PureCreditCardInfo.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([
      'VISA',
      'Mastercard',
      'JCB',
      'UnionPay',
      'American Express',
      '-1',
      '1',
      '2',
      '3',
      '4',
      '5',
    ]),
  ]),
  last4Digits: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hazy: PropTypes.bool,
  prefix: PropTypes.string,
  iconMarginRight: PropTypes.array,
  last4DigitsFontStyle: PropTypes.oneOf(Object.keys(light.fonts).map(key => `fonts.${key}`)),
};

export default React.memo(PureCreditCardInfo);

import React, { useState } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Popup from '~/components/atoms/Popup';
import Button from 'Components/atoms/Button';
import { useDebouncedCallback } from 'use-debounce';
import { ReactComponent as InfoIcon } from 'Images/icons/info.svg';

const TitlePanel = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Title = styled.h4`
  ${themeGet('fonts.h4')}
  margin: 0;
`;

const InfoButton = styled(Button)`
  padding: 0;
  width: auto;
  height: auto;
  margin-left: 10px;
`;

const PopupTitle = styled.h1`
  ${themeGet('fonts.subtitle')}
  margin: 0;
  text-align: center;
`;

const PopupContent = styled.p`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
  margin-top: 4px;
  text-align: center;
`;

const PopupButton = styled(Button)`
  ${themeGet('fonts.h2Button')};
  color: #fff;
  height: 50px;
  width: 100%;
  margin-top: 36px;
`;

const PureWalletTitle = props => {
  const [visible, setVisible] = useState(false);

  const [handleOnClick] = useDebouncedCallback(
    () => {
      setVisible(prev => !prev);
    },
    300,
    {
      leading: true,
      trailing: false,
    }
  );

  return (
    <React.Fragment>
      <TitlePanel>
        <Title>WeMo 錢包</Title>
        <InfoButton shape="circle" color="plan" IconComponent={InfoIcon} onClick={handleOnClick} />
      </TitlePanel>
      <Popup visible={visible}>
        <PopupTitle>什麼是 WeMo 錢包？</PopupTitle>
        <PopupContent>
          WeMo 錢包是 WeMo Scooter 提供的加值服務。只限 App 用戶使用，如想加值請到 store 下載。
        </PopupContent>
        <PopupButton type="float" boxShadow="light" shape="capsule" onClick={handleOnClick}>
          確認
        </PopupButton>
      </Popup>
    </React.Fragment>
  );
};

export default React.memo(PureWalletTitle);

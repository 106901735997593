const loadImage = src => {
  const img = new Image();
  img.src = src;
  return img;
};

const asyncLoadImage = src => {
  return new Promise((resolve, reject) => {
    const img = loadImage(src);
    img.onerror = reject;
    img.onload = () => {
      resolve(img);
    };
  });
};

export default asyncLoadImage;

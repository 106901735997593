import React, { useCallback, useMemo } from 'react';
// import { OverlayView } from '@react-google-maps/api';
import OverlayView from './OverlayView';
import PurePin from 'Components/atoms/Pin';
import { pick, isObjectDiff } from '~/utils';

const handleOverlayPosition = (offsetWidth, offsetHeight) => ({
  x: -offsetWidth / 2,
  y: -offsetHeight / 2,
});

const PinOverLayView = React.memo(
  ({ id, onClick, isSelect, hasLabel, lat, lng, batPercent, pinHex, pinImage }) => {
    const position = useMemo(() => ({ lat, lng }), [lat, lng]);

    const handleOnClick = useCallback(
      evt => {
        evt.stopPropagation();
        !isSelect && typeof onClick === 'function' && onClick(id, evt);
      },
      [id, isSelect, onClick]
    );

    return (
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={handleOverlayPosition}
      >
        <PurePin
          hasLabel={hasLabel}
          isSelect={isSelect}
          batPercent={batPercent}
          pinHex={pinHex}
          pinImage={pinImage}
          onClick={handleOnClick}
        />
      </OverlayView>
    );
  }
);

PinOverLayView.whyDidYouRender = {
  customName: 'PinOverLayView',
};

const PureScooterPinList = ({
  currentScooter,
  scooters,
  handleOnScooterPinClick,
  onlyShowCurrentScooter,
}) => {
  const ScooterPins = useMemo(
    () =>
      [...scooters, { ...currentScooter, id: currentScooter.scooterId }].reduce(
        (record, scooter) => {
          if (!record.scooterId[scooter.id]) {
            record.scooterId[scooter.id] === 1;
            record.scooters.push(scooter);
            record.scooterId[scooter.id] = true;
          }

          return record;
        },
        { scooters: [], scooterId: {} }
      ),
    [currentScooter, scooters]
  );

  return ScooterPins.scooters.map(({ styles, ...scooter }) =>
    onlyShowCurrentScooter ? (
      scooter.id === currentScooter.scooterId ? (
        <PinOverLayView
          hasLabel={parseInt(scooter.groupId, 10) === 20}
          isSelect={true}
          key={`pin-${scooter.id}`}
          {...pick(['id', 'lat', 'lng', 'batPercent', 'pinHex', 'pinImage'], scooter)}
          onClick={handleOnScooterPinClick}
        />
      ) : null
    ) : (
      <PinOverLayView
        hasLabel={parseInt(scooter.groupId, 10) === 20}
        isSelect={currentScooter.scooterId === scooter.id}
        key={`pin-${scooter.id}`}
        {...pick(['id', 'lat', 'lng', 'batPercent', 'pinHex', 'pinImage'], scooter)}
        onClick={handleOnScooterPinClick}
      />
    )
  );
};

PureScooterPinList.defaultProps = {
  scooters: [],
};

const ScooterPinList = React.memo(
  PureScooterPinList,
  (prevProps, nextProps) => !isObjectDiff(prevProps, nextProps)
);
ScooterPinList.whyDidYouRender = {
  customName: 'ScooterPinList',
};

export default ScooterPinList;

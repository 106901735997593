import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CardPhotoButton from '~/components/atoms/CardPhotoButton';
import FacePhotoButton from '~/components/atoms/FacePhotoButton';

const Container = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }
`;

const photoGroupsComponents = {
  IdCard: [
    { components: CardPhotoButton, props: { type: 'IdCardPositive' } },
    { components: CardPhotoButton, props: { type: 'IdCardNegative' } },
  ],
  License: [
    { components: CardPhotoButton, props: { type: 'LicensePositive' } },
    { components: CardPhotoButton, props: { type: 'LicenseNegative' } },
  ],
  Face: [{ components: FacePhotoButton }],
};

export const PureRegistrationPhotoGroup = props => {
  const { type, onChange, onFinish, photos: _photos } = props;
  const [photos, setPhotos] = useState(new Array(photoGroupsComponents[type].length));

  const handleOnChange = useCallback(
    (index, photo) => {
      setPhotos(prev => {
        const next = [...prev];
        next[index] = photo;
        return next;
      });
      if (typeof onChange === 'function') {
        onChange(index, photo);
      }
    },
    [onChange]
  );

  const handleOnFinish = useRef();
  handleOnFinish.current = photos => {
    if (photos.filter(item => !!item).length === photoGroupsComponents[type].length) {
      if (typeof onFinish === 'function') {
        onFinish();
      }
    }
  };

  useEffect(() => {
    if (_photos) {
      setPhotos(_photos);
    }
  }, []);

  useEffect(() => {
    handleOnFinish.current(photos);
  }, [photos]);

  return (
    <Container>
      {photoGroupsComponents[type].map(({ components: ButtonComp, props: defaultProps }, index) => (
        <ButtonWrapper key={index}>
          <ButtonComp
            {...defaultProps}
            onChange={photo => handleOnChange(index, photo)}
            isCaptured={!!photos[index]}
          />
        </ButtonWrapper>
      ))}
    </Container>
  );
};

PureRegistrationPhotoGroup.defaultProps = {};

PureRegistrationPhotoGroup.propTypes = {
  type: PropTypes.oneOf(['IdCard', 'License', 'Face']).isRequired,
  photos: PropTypes.array,
  onChange: PropTypes.func,
  onFinish: PropTypes.func,
};

export default React.memo(PureRegistrationPhotoGroup);

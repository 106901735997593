import { scooterStatus, rentStatus } from '~/configs/';
import { loginTypes } from '~/utils/authenticationService';

const initAuthenticationState = {
  isLogin: false,
  isGuest: false,
};

const authenticationReducer = (state = initAuthenticationState, action) => {
  // debugger
  // console.log('action', action, 'state', state);

  switch (action?.type) {
    case 'setStatusLoading':
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case 'updateUserLogin': {
      return {
        ...state,
        isLogin: action.loginStatus.isLogin,
        userType: action.loginStatus.isLogin
          ? action.loginStatus.type === loginTypes.guest
            ? rentStatus.guest
            : rentStatus.none
          : rentStatus.viewing,
      };
    }
    case 'setLoginError': {
      return {
        ...state,
        loginError: action.payload,
      };
    }
    case 'resetStatus':
      return initAuthenticationState;
    default:
      return state;
  }
};

export default authenticationReducer;

import axios from 'axios';
import asyncCancelable from '~/utils/asyncCancelable';
import { kottosHost } from './host';

const BaseURL = kottosHost;

function wrappedAxios(instance) {
  instance = asyncCancelable(instance);
  const funcKeys = ['request', 'get', 'delete', 'head', 'options', 'post', 'put', 'patch'];
  for (const key of funcKeys) {
    instance[key] = asyncCancelable(instance[key]);
  }
  return instance;
}

function ApolloPublicAxios({ host = BaseURL, prefix = '' } = {}) {
  const instance = wrappedAxios(
    axios.create({
      baseURL: `${host}/${prefix.replace(/^\//, '')}`,
      headers: {
        'Cache-Control': 'no-cache',
      },
    })
  );
  let setLocaleInterceptor = null;

  instance.setLocale = (locale = 'zh-TW') => {
    if (setLocaleInterceptor) {
      instance.interceptors.request.eject(setLocaleInterceptor);
      setLocaleInterceptor = null;
    }

    setLocaleInterceptor = instance.interceptors.request.use(config => {
      if (config.url.includes('geojson')) return config;
      if (config.method == 'get') {
        config.headers['Accept-Language'] = locale;
        if (config.params) {
          config.params.lang = locale;
        } else {
          config.params = { locale };
        }
      }
      return config;
    });

    return instance;
  };

  return instance;
}

function ApolloPrivateAxios({ host = BaseURL, prefix = '' } = {}) {
  const instance = ApolloPublicAxios({ host, prefix });
  let _token = null;
  let _identity = null;
  let _unauthorizationAction = null;

  instance.interceptors.request.use(config => {
    if (_identity && _token) config.headers['Authorization'] = `${_identity} ${_token}`;
    return config;
  });

  instance.setToken = token => {
    _token = token;
    return instance;
  };

  instance.getToken = () => {
    return _token;
  };

  instance.clearToken = () => {
    _token = null;
    return instance;
  };

  instance.setUnauthorizationAction = action => {
    if (action && _unauthorizationAction) {
      instance.clearUnauthorizationAction();
    }

    if (action) {
      _unauthorizationAction = instance.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          if (error.response?.status === 401) {
            return action(error);
            // return error;
          }
          throw error;
        }
      );
    }

    return instance;
  };

  instance.clearUnauthorizationAction = () => {
    if (_unauthorizationAction) {
      instance.interceptors.response.eject(_unauthorizationAction);
      _unauthorizationAction = null;
    }
    return instance;
  };

  instance.setIdentity = (identity = 'WeMo') => {
    _identity = identity;
    return instance;
  };

  return instance;
}

function ApolloAxiosCore({ host = BaseURL, prefix = '' } = {}) {
  const pubInstance = ApolloPublicAxios({ host, prefix });
  const privInstance = ApolloPrivateAxios({ host, prefix });
  const _public = { ...pubInstance };

  _public.setToken = token => {
    privInstance.setToken(token);
    return _public;
  };

  _public.getToken = () => privInstance.getToken();

  _public.clearToken = () => {
    privInstance.clearToken();
    return _public;
  };

  _public.setUnauthorizationAction = action => {
    privInstance.setUnauthorizationAction(action);
    return _public;
  };

  _public.clearUnauthorizationAction = () => {
    privInstance.clearUnauthorizationAction();
    return _public;
  };

  _public.setLocale = locale => {
    pubInstance.setLocale(locale);
    privInstance.setLocale(locale);
    return _public;
  };

  /**
   * Change to private instance
   */
  _public.useToken = identity => privInstance.setIdentity(identity);

  return _public;
}

function ApolloAxios() {
  const _public = {};
  const axiosStore = {};

  _public.create = (key, config) => {
    if (!axiosStore.hasOwnProperty(key)) {
      axiosStore[key] = ApolloAxiosCore(config);
    }
    return axiosStore[key];
  };

  _public.getInstance = key => {
    if (!key) throw Error('instance key is undefined');

    const instance = axiosStore[key];
    if (!instance) throw Error(`axios instance not found, only keys: [${Object.keys(axiosStore)}]`);

    return instance;
  };

  _public.getInstanceKeys = () => Object.keys(axiosStore);

  _public.setInstancesToken = (token, keys = Object.keys(axiosStore)) => {
    if (!Array.isArray(keys)) throw Error('keys must be array');
    for (const key of keys) {
      axiosStore[key].setToken(token);
    }
    return _public;
  };

  _public.clearInstancesToken = (keys = Object.keys(axiosStore)) => {
    if (!Array.isArray(keys)) throw Error('keys must be array');
    for (const key of keys) {
      axiosStore[key].clearToken();
    }
    return _public;
  };

  _public.setInstancesUnauthorizationAction = (action, keys = Object.keys(axiosStore)) => {
    if (!Array.isArray(keys)) throw Error('keys must be array');
    for (const key of keys) {
      axiosStore[key].setUnauthorizationAction(action);
    }
    return _public;
  };

  _public.clearInstancesUnauthorizationAction = (keys = Object.keys(axiosStore)) => {
    if (!Array.isArray(keys)) throw Error('keys must be array');
    for (const key of keys) {
      axiosStore[key].clearUnauthorizationAction();
    }
    return _public;
  };

  _public.setInstancesLocale = (locale, keys = Object.keys(axiosStore)) => {
    if (!Array.isArray(keys)) throw Error('keys must be array');
    for (const key of keys) {
      axiosStore[key].setLocale(locale);
    }
    return _public;
  };

  return _public;
}

const apolloAxios = ApolloAxios();
export default apolloAxios;

import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { position } from 'styled-system';
import Button from 'Components/atoms/Button';
import PopupLoading from 'Components/molecules/PopupLoading';
import CountDown from 'Components/atoms/CountDown';
import { ReactComponent as Cancel } from 'Images/icons/cancel.svg';
import { ReactComponent as Light } from 'Images/icons/light.svg';
import { ReactComponent as LightDisable } from 'Images/icons/light-disable.svg';
import { useRenting } from '~/context/';
import { useSnackbar } from '~/context/Snackbars';
import { animated } from 'react-spring';

const BarReserveStyled = styled(animated.div)`
  background-color: white;
  width: calc(100% - 56px);
  height: 60px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  ${position}
  ${props => props.theme.boxShadows.dark}
`;

const ButtonStyled = styled(Button)`
  height: 40px;
  width: 68px;
  padding: 0;
  ${props => props.theme.boxShadows.light}
`;

const BarReserve = barReserveProps => {
  const { scooter, handleCancelReserveScooter, handleFlashScooter, loadingUtils } = useRenting();
  const [isFlashing, setIsFlashing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onFlashClick = async () => {
    if (isFlashing) {
      enqueueSnackbar({
        message: '您已啟動車燈閃光，請稍後再試',
        preventDuplicate: true,
        duration: 2000, //NOTE 目前無特別處理 dequeue snackbar，故設兩秒改善下一動作殘存問
      });
      return;
    }
    setIsFlashing(true);
    const success = await handleFlashScooter();

    if (success) {
      setTimeout(() => {
        setIsFlashing(false);
      }, 15000);
    } else {
      setIsFlashing(false);
    }
  };

  return (
    <Fragment>
      <BarReserveStyled top={[-5, -7.5]} {...barReserveProps}>
        <ButtonStyled
          shape="capsule"
          type="float"
          color="plan"
          IconComponent={Cancel}
          onClick={handleCancelReserveScooter}
        />
        <CountDown
          type="minute"
          duration={scooter.leftReservationTime}
          onCountToZero={handleCancelReserveScooter}
        />
        <ButtonStyled
          shape="capsule"
          type="float"
          color="plan"
          IconComponent={isFlashing ? LightDisable : Light}
          onClick={onFlashClick}
        />
      </BarReserveStyled>
      <PopupLoading {...loadingUtils.state} />
    </Fragment>
  );
};

BarReserve.defaultProps = {};

BarReserve.propTypes = {
  onCancelClick: PropTypes.func,
  onLightClick: PropTypes.func,
};

export default BarReserve;

import { getMockAdapter } from './mock';

const mockScooterStyles = () => {
  const mock = getMockAdapter();
  mock.onGet('/v2/users/me/scooterStyles').reply(200, {
    result: 'success',
    data: [
      {
        id: '65',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1572342199344.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363482092.png',
        ],
        pinHex: '#42BFE5',
      },
      {
        id: '64',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1572342199344.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363482092.png',
        ],
        pinHex: '#52DCDAFF',
      },
      {
        id: '63',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363482092.png',
        ],
        pinHex: '#FF2700FF',
      },
      {
        id: '62',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363482092.png',
        ],
        pinHex: '#E3E542FF',
      },
      {
        id: '61',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363482092.png',
        ],
        pinHex: '#42BFE5',
      },
      {
        id: '60',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363482092.png',
        ],
        pinHex: '#42BFE5',
      },
      {
        id: '59',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363482092.png',
        ],
        pinHex: '#42E587FF',
      },
      {
        id: '58',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363482092.png',
        ],
        pinHex: '#407E91FF',
      },
      {
        id: '57',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363471436.png',
        ],
        pinHex: '#FF92D0FF',
      },
      {
        id: '56',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363482092.png',
        ],
        pinHex: '#42BFE5',
      },
      {
        id: '55',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1566363529239.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363492008.png',
        ],
        pinHex: '#42BFE5',
      },
      {
        id: '54',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1566363538019.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363508832.png',
        ],
        pinHex: '#42BFE5',
      },
      {
        id: '53',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1566363546393.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1565978743141.jpg',
        ],
        pinHex: '#42BFE5',
      },
      {
        id: '52',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1566363607303.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363607303.png',
        ],
        pinHex: '#3D484BFF',
      },
      {
        id: '51',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1566363575622.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363575622.png',
        ],
        pinHex: '#42BFE5',
      },
      {
        id: '50',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1566363560340.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1566363560340.png',
        ],
        pinHex: '#A86A00FF',
      },
      {
        id: '49',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#42BFE5FF',
      },
      {
        id: '48',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#133641FF',
      },
      {
        id: '47',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF50',
      },
      {
        id: '46',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF50',
      },
      {
        id: '45',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#77B1C350',
      },
      {
        id: '44',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#50727CFF',
      },
      {
        id: '43',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#6A7477FF',
      },
      {
        id: '42',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#000000FF',
      },
      {
        id: '41',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1565850680529.jpg',
        ],
        pinHex: '#2E7084FF',
      },
      {
        id: '40',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1565850216856.jpg',
        ],
        pinHex: '#B2CED3FF',
      },
      {
        id: '39',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#435A61FF',
      },
      {
        id: '38',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1564480787083.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#1D0C49FF',
      },
      {
        id: '37',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#71E0FF00',
      },
      {
        id: '36',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF00',
      },
      {
        id: '35',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF00',
      },
      {
        id: '34',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1572343863730.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#52DCDAFF',
      },
      {
        id: '33',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1563958467724.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1563958467724.png',
        ],
        pinHex: '#E54245FF',
      },
      {
        id: '32',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF00',
      },
      {
        id: '31',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#101A1DFF',
      },
      {
        id: '30',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '29',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '28',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '27',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '26',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '25',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '24',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFF0',
      },
      {
        id: '23',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '22',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1563944046098.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1563944046098.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '21',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '20',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '19',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1563874954443.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1563874954443.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '18',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1563874944855.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1563874944855.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '17',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1563874220493.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1563874220493.png',
        ],
        pinHex: '#FFFFFF',
      },
      {
        id: '14',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFF00',
      },
      {
        id: '13',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1572344517139.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1572254678957.0',
        ],
        pinHex: '#52DCDAFF',
      },
      {
        id: '12',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFF00',
      },
      {
        id: '11',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFF00',
      },
      {
        id: '10',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFF00',
      },
      {
        id: '9',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1565777172547.05',
        ],
        pinHex: '#FFFF00',
      },
      {
        id: '8',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FFFF00',
      },
      {
        id: '7',
        pinImage:
          'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/burger-king.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/coverImage_1564392229290.23',
        ],
        pinHex: '#F4F47C84',
      },
      {
        id: '6',
        pinImage:
          'https://storage.googleapis.com/wemo-config-qat/scooterStyles/pinImage_1565849506473.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#F54242FF',
      },
      {
        id: '5',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#4287f5',
      },
      {
        id: '4',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#FEC771',
      },
      {
        id: '3',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#517A09FF',
      },
      {
        id: '2',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#000000',
      },
      {
        id: '1',
        pinImage: 'https://storage.googleapis.com/wemo-config/scooterStyles/pinImages/white.png',
        coverImages: [
          'https://storage.googleapis.com/wemo-config/scooterStyles/coverImages/default.png',
        ],
        pinHex: '#4287f5',
      },
    ],
  });
};

export default mockScooterStyles;

import React, { useEffect, useState, Fragment, useCallback, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import wemoToken from '~/utils/wemoToken';
import LaunchAnimation from 'Components/atoms/LaunchAnimation';
import { pathIsAuth } from './configs/routes';
import { useAuthentication, useUserInfo, useEmergency } from '~/context/';
import { routeAuths } from './configs/defs';
import ErrorBoundary from 'Components/errorBoundaries/ErrorBoundary';
import requestErrors from '~/configs/requestErrors';
import EntryEmergency from '~/components/atoms/EntryEmergency';
import { initializeFirebaseService, logErrorEvent, logWemoUtmEvent } from '~/utils/firebaseService';
import WemoRouters from './components/WemoRouters';

const defaultUtm = {
  medium: 'oa',
  source: 'line',
  campaign: 'website',
};

const initializeGaErrorTracking = () => {
  document.addEventListener('error', event => {
    console.error('an error happened');
    logErrorEvent({ type: event.type, message: event.message });
  });
};

const initializeFirebase = () => {
  let search = window.location.search;

  if (search.indexOf('liffStatus') !== -1) {
    return;
  } else {
    initializeFirebaseService();
    const medium = search.match(/utm_medium=([^&]+)/)?.[1];
    const source = search.match(/utm_source=([^&]+)/)?.[1];
    const campaign = search.match(/utm_campaign=([^&]+)/)?.[1];
    const content = search.match(/utm_content=([^&]+)/)?.[1];
    const term = search.match(/utm_term=([^&]+)/)?.[1];

    initializeGaErrorTracking();
    if (medium && source && campaign) {
      logWemoUtmEvent({ campaign, source, content, term, medium });
    } else {
      logWemoUtmEvent(defaultUtm);
    }
  }
};

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.

/**
 * interchange 適用於在 In Line Browser(ILB) 使用 liff.openWindow 跳轉無法正常開啟 Line mini(LM)
 * 例如：
 *      Line Pay 付款時 tappay redirect API 會開啟 ILB 離開 LM
 *      返回時因為環境是在 ILB 使用 liff.openWindow 會無法順利帶起 LM
 *      所以特別跳轉到 interchange 頁面後使用 location.replace 帶起 LM
 */
const PreparedRouter = React.memo(
  () => {
    const { pathname } = window.location;
    const { emergency, getEmergency, setIsBlockingEntry, startPollingEmergency } = useEmergency();
    const [isAnimationComplete, setIsAnimationComplete] = useState(false);
    const { handleLogin, checkLoginError, isLogin, isLoading } = useAuthentication();
    const { updateUserInfo } = useUserInfo();
    let hasEmail = useMemo(() => !checkLoginError(requestErrors.LINE_TOKEN_EMAIL_MISSING, false), [
      checkLoginError,
    ]);
    let hasId = useMemo(
      () => !checkLoginError(!checkLoginError(requestErrors.LINE_TOKEN_EMAIL_MISSING, false)),
      [checkLoginError]
    );
    let isBanned = useMemo(() => checkLoginError(requestErrors.USER_BANNED, false), [
      checkLoginError,
    ]);

    const handleAnimationComplete = useCallback(() => {
      setIsAnimationComplete(true);
    }, []);

    const initialize = async () => {
      if (
        pathIsAuth(pathname, routeAuths.outLinePublic) ||
        pathIsAuth(pathname, routeAuths.outLinePrivate)
      ) {
        initializeFirebase();
        return;
      }

      await handleLogin();
      initializeFirebase();
    };

    useEffect(() => {
      wemoToken.syncLineAxiosToken();
    }, []);

    useEffect(() => {
      if (isLogin) updateUserInfo();
    }, [updateUserInfo, isLogin]);

    useEffect(() => {
      getEmergency().then((data = {}) => {
        const { service = {} } = data;
        if (service.status !== 2) {
          setIsBlockingEntry(false);
        }
      });
      initialize();
    }, []);

    useEffect(() => {
      if (isAnimationComplete && !isLoading && !emergency.loading) {
        startPollingEmergency();
      }
    }, [isAnimationComplete, isLoading, emergency.loading]);

    return (
      <Fragment>
        <ErrorBoundary>
          {!isAnimationComplete || isLoading || emergency.loading ? (
            <LaunchAnimation onComplete={handleAnimationComplete} />
          ) : emergency.isBlockingEntry ? (
            <EntryEmergency url={emergency.announcement.entryUrl} />
          ) : (
            <Router>
              <WemoRouters
                isLogin={isLogin}
                isBanned={isBanned}
                hasId={hasId}
                hasEmail={hasEmail}
                pathname={pathname}
              />
            </Router>
          )}
        </ErrorBoundary>
      </Fragment>
    );
  }
  // (prevProps, nextProps) => !isObjectDiff(prevProps, nextProps)
);
PreparedRouter.whyDidYouRender = {
  customName: 'PreparedRouter',
};

export default PreparedRouter;

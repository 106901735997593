import React, { useEffect, Suspense } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import routes from '../configs/routes';
import FullScreenLoading from '~/components/molecules/FullScreenLoading';
import { initializeFirebaseService, logScreenViewEvent } from '~/utils/firebaseService';
import RouteWithSubRoutes from './RouteWithSubRoutes';

const WemoRouters = ({ isLogin, hasId, isBanned, hasEmail, pathname }) => {
  const [_, redirect] = window.location.search.slice(1).match(/redirect=(.+)/) || [];
  const [redirectPathname, redirectSearch = ''] = (redirect
    ? decodeURIComponent(redirect)
    : ''
  ).split('?');
  const history = useHistory();

  useEffect(() => {
    initializeFirebaseService();
    logScreenViewEvent();
  }, []);

  useEffect(() => {
    return history.listen(location => {
      if (location.pathname !== '/sign-up') {
        logScreenViewEvent();
        return;
      }
    });
  }, [history]);

  return (
    <Suspense fallback={<FullScreenLoading />}>
      <Switch>
        {routes.map((route, i) =>
          !hasId || (route.shouldHaveEmail && !hasEmail) ? (
            <Route
              key={i}
              exact={route.route || false}
              path={route.path}
              render={(location, ...props) => (
                <Redirect
                  to={{
                    pathname: '/landing-page',
                  }}
                />
              )}
            />
          ) : (
            <RouteWithSubRoutes key={i} {...route} isLogin={isLogin} hasEmail={hasEmail} />
          )
        )}
      </Switch>
      {redirect && pathname !== '/interchange' && (
        <Redirect
          to={{
            pathname: redirectPathname,
            search: `?${redirectSearch}`,
          }}
        />
      )}
      {isBanned && (
        <Redirect
          to={{
            pathname: '/errors/ban',
          }}
        />
      )}
    </Suspense>
  );
};

WemoRouters.whyDidYouRender = {
  customName: 'RouteWithSubRoutes',
};

export default WemoRouters;

import * as helpers from '@turf/helpers';
import center from '@turf/center';

export const googlePolygonToGeojson = googlePolygon =>
  helpers.polygon([googlePolygon.map(({ lat, lng }) => [lng, lat])]);

export const googlePolylineToGeojson = googlePolyline =>
  helpers.lineString(googlePolyline.map(({ lat, lng }) => [lng, lat]));

export const getGeojsonCenter = geojson => center(geojson);

export const getPolygonCenterPoint = polygon => center(helpers.polygon(polygon));

export const getPolylineCenterPoint = polyline => center(helpers.lineString(polyline));

export const geojsonPolygonToGooglePolygon = coordinates =>
  coordinates[0].map(([lng, lat]) => ({ lng, lat }));

export const geojsonPolylinesToGooglePolylines = coordinates =>
  coordinates.map(([lng, lat]) => ({ lng, lat }));

export const geojsonToGooglePoint = coordinates => ({
  lng: coordinates[0],
  lat: coordinates[1],
});

export const getGooglePolygonCenterPoint = googlePolygon => {
  const geojson = googlePolygonToGeojson(googlePolygon);
  const center = getGeojsonCenter(geojson);
  const googlePoint = geojsonToGooglePoint(center.geometry.coordinates);
  return googlePoint;
};

export const getGooglePolylineCenterPoint = googlePolyline => {
  const geojson = googlePolylineToGeojson(googlePolyline);
  const center = getGeojsonCenter(geojson);
  const googlePoint = geojsonToGooglePoint(center.geometry.coordinates);
  return googlePoint;
};

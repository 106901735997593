import { scooterStatus, rentStatus } from '~/configs/';
import { isObjectDiff } from '~/utils';

const initUserInfoState = {
  isLoading: false,
  location: null,
  rejectReasons: [],
  statusId: null,
  isAllowLogin: null,
  isAllowRide: null,
  isAllowResubmitDoc: null,
  isAllowPurchase: null,
  deadline: null,
  firstName: null,
  lastName: null,
  birth: null,
  gender: null,
  idcardId: null,
  license: null,
  phone: null,
  address: null,
  contactEmail: null,
  account_photo: null,
  userGroupId: null,
  userCategoryId: null,
  isPhoneVerified: null,
  paymentErr: null,
  unpaidAmount: null,
  userMode: rentStatus.viewing,
  scooterMode: scooterStatus.stop,
  rentId: null,
  userEvents: [],
};

const userInfoReducer = (state = initUserInfoState, action) => {
  switch (action?.type) {
    case 'setUser': {
      const nextState = {
        ...state,
        ...action.payload,
        isLoading: false,
      };
      return isObjectDiff(state, nextState) ? nextState : state;
    }
    case 'updateUserMode':
      return {
        ...state,
        userMode: action.userMode,
      };
    case 'updateScooterMode':
      return {
        ...state,
        scooterMode: action.scooterMode,
      };
    case 'updateRentId':
      return {
        ...state,
        rentId: action.rentId,
      };
    case 'setUserEvent':
      return { ...state, userEvents: [...state.userEvents, action.payload.userEvent] };
    case 'setStatusError':
      return {
        ...state,
        error: action.payload.error,
      };
    case 'resetUser':
      return initUserInfoState;
    default:
      return state;
  }
};

export default userInfoReducer;

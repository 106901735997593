import { getMockAdapter } from './mock';

const mockParkingLots = () => {
  const mock = getMockAdapter();
  mock
    .onGet('/api/wemo/public')
    .reply(200, {
      result: 'success',
      data: {
        service: { status: 0 },
        announcement: {
          entryUrl: 'https://storage.googleapis.com/wemo-config-qat/eris/entry.html',
          mapUrl: 'https://storage.googleapis.com/wemo-config-qat/eris/slow_connection_0108.html',
        },
      },
    });
};

export default mockParkingLots;

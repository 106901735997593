import 'webrtc-adapter';
import React, { useRef, useEffect, useCallback, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Webcam from 'react-webcam';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { animated, useTransition } from 'react-spring';
import { useDebouncedCallback } from 'use-debounce';
import { detect } from 'detect-browser';
import { useHistory } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import imageCompression from 'browser-image-compression';
import {
  initCamera,
  devicesTutorials,
  releaseCanvas,
  getCanvas,
  checkCameraCanShot,
  getFacingModeConstraint,
} from './utils';
import { useFeedback } from './hooks';
import CanvasFastBlur from './canvasBlur';
import PopupNote from '~/components/molecules/PopupNote';
import Button from '~/components/atoms/Button';
import Scancam from '~/components/atoms/Scancam';
import { logScreenViewEvent, logErrorEvent } from '~/utils/firebaseService';

const canvasBlur = new CanvasFastBlur({ blur: 6 });
const browser = detect();
const visibleCopy = !(
  (browser.name === 'ios' && browser.os === 'iOS') ||
  (browser.name === 'chrome' && browser.os === 'Android OS')
);

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: black;
`;

const CameraWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const CustomWebcam = styled(
  React.memo(
    React.forwardRef((props, ref) => {
      const { stretch, facingMode, ...otherProps } = props;
      return <Webcam ref={ref} {...otherProps} />;
    })
  )
)`
  width: ${({ stretch }) => (stretch === 'horizontal' ? '100%' : 'auto')};
  height: ${({ stretch }) => (stretch === 'vertical' ? '100%' : 'auto')};
  transform: ${({ facingMode }) =>
    facingMode === 'environment' ? 'rotateY(0deg)' : 'rotateY(180deg)'};
`;

const CustomScancam = styled(
  React.memo(
    React.forwardRef((props, ref) => {
      const { stretch, ...otherProps } = props;
      return <Scancam ref={ref} {...otherProps} />;
    })
  )
)`
  width: ${({ stretch }) => (stretch === 'horizontal' ? '100%' : 'auto')};
  height: ${({ stretch }) => (stretch === 'vertical' ? '100%' : 'auto')};
  transform: ${({ facingMode }) =>
    facingMode === 'environment' ? 'rotateY(0deg)' : 'rotateY(180deg)'};
`;

const CameraCover = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  height: 100%;
  width: 100%;
  background: black;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  ${themeGet('fonts.subtitle')};
  color: ${themeGet('colors.white')};
`;

const Feedback = styled(animated.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  overflow: hidden;
  top: 0;
  left: 0;
  background: black;
`;

const OverlayPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: transparent;
`;

const TutorialOl = styled.ol`
  width: 100%;
  padding-inline-start: 16px;
  ${themeGet('fonts.body2')}
  color: ${themeGet('colors.secondaryGrey')};
  margin-bottom: 20px;
`;

const CustomButton = styled(Button)`
  margin-top: 18px;

  &:first-child {
    margin-top: 36px;
  }
`;

const SwitchCover = styled(animated.img)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  perspective-origin: center;
`;

export const PureCamera = props => {
  const {
    forwardRef,
    type,
    open,
    facingMode,
    overlay,
    onReady,
    onError,
    onOpenPicker,
    parentComponentName,
    onFeedbackDestroyed,
  } = props;
  const viewport = useRef();
  const camera = useRef();
  const canvas = useRef();
  const [stretch, setStretch] = useState('horizontal');
  const [switchCoverImg, setSwitchCoverImg] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isSwitchCoverDestroyed, setIsSwitchCoverDestroyed] = useState(false);
  const [visibleSwitchCover, setVisibleSwitchCover] = useState(false);
  const [visibleNotAllowedError, setVisibleNotAllowedError] = useState(false);
  const [visibleNotSupportError, setVisibleNotSupportError] = useState(false);
  const [selfFacingMode, setSelfFacingMode] = useState(facingMode);
  const [feeback, triggerFeeback] = useFeedback();
  const history = useHistory();
  const [facingModeConstraint, setFacingModeConstraint] = useState({ facingMode: selfFacingMode });

  const coverTransition = useTransition(!isReady, null, {
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const feebackTransition = useTransition(feeback, null, {
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    onDestroyed(isDestroyed) {
      if (isDestroyed) {
        typeof onFeedbackDestroyed === 'function' && onFeedbackDestroyed();
      }
    },
  });

  const switchCoverTransition = useTransition(visibleSwitchCover, null, {
    from: { opacity: 0 },
    enter: { opacity: 1, transform: 'rotateY(0deg)' },
    leave: { transform: 'rotateY(180deg)', opacity: 0 },
    onDestroyed: isDestroyed => {
      setIsSwitchCoverDestroyed(isDestroyed);
      if (!isDestroyed) {
        setTimeout(() => {
          setVisibleSwitchCover(false);
        }, 334);
      }
    },
  });

  const [handleOnConfirmClick, cancelHandleOnConfirmClick] = useDebouncedCallback(
    () => {
      setVisibleNotAllowedError(false);
    },
    300,
    { leading: true, trailing: false }
  );

  const handleNotAllowedPopupOnDestroyed = useCallback(() => {
    history.go(0);
  }, [history]);

  const [handlCopyOnClick, cancelHandleCopyOnClick] = useDebouncedCallback(
    () => {
      copy(window.location.href);
    },
    300,
    { leading: true, trailing: false }
  );

  const [handleOnOpenPickerClick, cancelHandleOnPickerClick] = useDebouncedCallback(
    () => {
      typeof onOpenPicker === 'function' && onOpenPicker();
      setVisibleNotSupportError(false);
    },
    300,
    { leading: true, trailing: false }
  );

  const setCanvas = useCallback(async () => {
    canvas.current = await getCanvas(camera.current, viewport.current, stretch, selfFacingMode);
  }, [selfFacingMode, stretch]);

  const capturePhoto = useCallback(async () => {
    setCanvas();
    await new Promise(resolve => {
      triggerFeeback(resolve);
    });
    while (canvas.current === undefined) {}

    return new Promise((resolve, reject) => {
      if (!canvas.current) {
        resolve([null, null]);
        return;
      }
      try {
        canvas.current.toBlob(
          async photo => {
            if (photo) {
              const url = await imageCompression.getDataUrlFromFile(photo);
              resolve([photo, url]);
            } else {
              resolve([null, null]);
            }
            releaseCanvas(canvas.current);
            canvas.current = undefined;
          },
          'image/jpeg',
          0.92
        );
      } catch (error) {
        reject(error);
        releaseCanvas(canvas.current);
        canvas.current = undefined;
      }
    });
  }, [setCanvas]);

  const init = async isExact => {
    const stretch = await initCamera(viewport.current, camera.current, isExact);
    setStretch(stretch);
    if (camera.current.video.paused) {
      let loadedMetadataHandler = () => {
        setIsLoaded(true);
        camera.current.video.removeEventListener('loadedmetadata', loadedMetadataHandler);
        loadedMetadataHandler = null;
      };
      setIsLoaded(false);
      camera.current.video.addEventListener('loadedmetadata', loadedMetadataHandler);
    } else {
      setIsLoaded(true);
    }
  };

  const handleOnError = useCallback(
    error => {
      if (
        typeof error === 'string' ||
        error.message === 'getUserMedia is not implemented in this browser'
      ) {
        logScreenViewEvent(`camera-error-${parentComponentName}-visible-not-support`);
        setVisibleNotSupportError(true);
      } else if (error.name === 'NotAllowedError') {
        logScreenViewEvent(`camera-error-${parentComponentName}-not-allowed`);
        setVisibleNotAllowedError(true);
      } else if (error.name === 'OverconstrainedError') {
        logScreenViewEvent(`camera-error-${parentComponentName}-overconstrained`);
        init(false);
      } else if (error.name === 'NotReadableError') {
        logScreenViewEvent(`camera-error-${parentComponentName}-not-readable`);
        if (facingModeConstraint.deviceId) {
          setFacingModeConstraint({ facingMode: selfFacingMode });
        } else {
          getFacingModeConstraint(selfFacingMode).then(setFacingModeConstraint);
        }
      } else {
        // TODO: 這邊可能要記一下 ERROR
        console.log('get unknown error');
        console.log(error.name, error.message);
        logErrorEvent({ message: error.message, type: error.name, stack: parentComponentName });
        if (typeof onError === 'function') onError(error);
        else throw error;
      }
    },
    [facingModeConstraint.deviceId, onError, parentComponentName, selfFacingMode]
  );

  const handleOnUserMedia = useCallback(async () => {
    try {
      await init();
    } catch (error) {
      handleOnError(error);
    }
  }, [handleOnError]);

  const handleOnDetected = useCallback(async results => {
    console.log(results);
  }, []);

  const getCameraState = useCallback(async () => {
    const canvas = await getCanvas(camera.current, viewport.current, stretch, selfFacingMode);
    const isCameraCanShot = await checkCameraCanShot(camera.current, canvas);
    return isCameraCanShot;
  }, [selfFacingMode, stretch]);

  // 狀態轉移過程
  // facingMode => visibleSwitchCover => selfFacingMode
  useEffect(() => {
    if (facingMode !== selfFacingMode) {
      const switchFacingMode = async () => {
        const canvas = await getCanvas(camera.current, viewport.current, stretch, selfFacingMode);
        if (!canvas) return;
        canvasBlur.initCanvas(canvas);
        canvasBlur.gBlur();
        const imgUrl = canvas.toDataURL('image/jpeg', 0.92);
        setSwitchCoverImg(imgUrl);
        setVisibleSwitchCover(true);
        releaseCanvas(canvas);
      };
      setIsReady(false);
      switchFacingMode();
    }
  }, [facingMode]);

  useEffect(() => {
    if (!visibleSwitchCover && isSwitchCoverDestroyed) {
      setSelfFacingMode(facingMode);
      getFacingModeConstraint(facingMode).then(setFacingModeConstraint);
    }
  }, [visibleSwitchCover, isSwitchCoverDestroyed]);

  // useEffect(() => {
  //   getFacingModeConstraint(selfFacingMode).then(setFacingModeConstraint);
  // }, [selfFacingMode]);

  // useEffect(() => {
  //   let loadedMetadataHandler = () => {
  //     setIsLoaded(true);
  //     camera.current.video.removeEventListener('loadedmetadata', loadedMetadataHandler);
  //     loadedMetadataHandler = null;
  //   };
  //   setIsLoaded(false);
  //   camera.current.video.addEventListener('loadedmetadata', loadedMetadataHandler);
  // }, [selfFacingMode]);

  useEffect(() => {
    if (isLoaded) {
      getCameraState()
        .then(state => {
          if (state) setIsReady(true);
          else handleOnError(new Error("camera open but can't shot"));
        })
        .catch(error => {
          console.log(error.name, error);
        });
    }
  }, [isLoaded]);

  useEffect(() => {
    typeof onReady === 'function' && onReady(isReady);
  }, [isReady]);

  useEffect(() => {
    const container = viewport.current.parentElement;
    disablePageScroll(container);
    return () => {
      enablePageScroll(container);
      cancelHandleOnConfirmClick();
      cancelHandleCopyOnClick();
      cancelHandleOnPickerClick();
    };
  }, []);

  useImperativeHandle(forwardRef, () => ({
    capturePhoto,
    getVideoSize: () => ({
      width: camera.current.video.videoWidth,
      height: camera.current.video.videoHeight,
    }),
    getVideo: () => camera.current.video,
    getVideoStream: () => camera.current.stream,
  }));

  return (
    <Container>
      <CameraWrapper ref={viewport}>
        {open &&
          (type === 'camera' ? (
            <CustomWebcam
              ref={camera}
              audio={false}
              screenshotFormat="image/jpeg"
              stretch={stretch}
              facingMode={selfFacingMode}
              onUserMedia={handleOnUserMedia}
              videoConstraints={{
                ...facingModeConstraint,
                width: 1920,
                height: 1080,
              }}
              onUserMediaError={handleOnError}
              imageSmoothing={false}
            />
          ) : (
            <CustomScancam
              ref={camera}
              facingMode={selfFacingMode}
              onUserMedia={handleOnUserMedia}
              onUserMediaError={handleOnError}
              onDetected={handleOnDetected}
            />
          ))}
        {overlay && isReady && <OverlayPanel>{overlay}</OverlayPanel>}
        {coverTransition.map(
          ({ item, key, props }) =>
            item && (
              <CameraCover key={key} style={props}>
                開啟相機中
              </CameraCover>
            )
        )}
        {feebackTransition.map(
          ({ item, key, props }) => item && <Feedback key={key} style={props} />
        )}
        {switchCoverTransition.map(
          ({ item, key, props }) =>
            item && <SwitchCover key={key} style={props} src={switchCoverImg} />
        )}
      </CameraWrapper>
      <PopupNote
        visible={visibleNotAllowedError}
        title="請允許開啟攝影機進行拍照"
        onConfirmClick={handleOnConfirmClick}
        onDestroyed={handleNotAllowedPopupOnDestroyed}
      >
        <TutorialOl>
          {devicesTutorials[browser.os].map((text, index) => (
            <li key={index}>{text}</li>
          ))}
        </TutorialOl>
      </PopupNote>
      <PopupNote
        visible={visibleNotSupportError}
        title="此瀏覽器不支援攝影機"
        notes={
          visibleCopy
            ? `建議輕觸 [複製網址] 後以 ${
                browser.os === 'iOS' ? 'Safari' : 'Chrome'
              } 瀏覽器開啟此頁面`
            : '請輕觸 [上傳照片] '
        }
        footer={
          <React.Fragment>
            {visibleCopy && (
              <CustomButton
                boxShadow="light"
                size="small"
                width={1}
                shape="capsule"
                type="float"
                onClick={handlCopyOnClick}
              >
                複製網址
              </CustomButton>
            )}
            <CustomButton
              color={visibleCopy ? 'plan' : 'primary'}
              boxShadow="light"
              size="small"
              width={1}
              shape="capsule"
              type="float"
              onClick={handleOnOpenPickerClick}
            >
              上傳照片
            </CustomButton>
          </React.Fragment>
        }
      />
    </Container>
  );
};

PureCamera.defaultProps = {
  type: 'camera',
  open: true,
  facingMode: 'environment',
};

PureCamera.propTypes = {
  type: PropTypes.oneOf(['camera', 'scanner']),
  open: PropTypes.bool,
  facingMode: PropTypes.oneOf(['environment', 'user']),
  overlay: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  onReady: PropTypes.func,
  onError: PropTypes.func,
  onOpenPicker: PropTypes.func,
  onFeedbackDestroyed: PropTypes.func,
  onDetected: PropTypes.func,
};

const ForwardCamera = React.forwardRef((props, ref) => <PureCamera {...props} forwardRef={ref} />);
export default ForwardCamera;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as Info } from 'Images/icons/info.svg';

const PageHeaderStyled = styled.header`
  text-align: center;
  margin-bottom: 30px;
  ${space};
`;

const Title = styled.h1`
  ${themeGet(`fonts.h1`)};
  margin: 0;
`;

const Subtitle = styled.p`
  ${themeGet(`fonts.h4`)};
  margin: 0;
  margin-top: 4px;
  white-space: pre-line;
`;

const Hint = styled.p`
  ${themeGet(`fonts.body2`)};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
  margin-top: 4px;
  white-space: pre-line;
`;

const InfoIcon = styled.img`
  margin-left: 10px;
`;

export const PurePageHeader = ({
  title,
  subtitle,
  hint,
  hasInfo,
  infoOnClick,
  className,
  bind,
}) => {
  return (
    <PageHeaderStyled ref={bind} mb={['15px', '30px']} className={className}>
      <Title>
        {title}
        {hasInfo && <InfoIcon as={Info} onClick={infoOnClick} />}
      </Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {hint && <Hint>{hint}</Hint>}
    </PageHeaderStyled>
  );
};

PurePageHeader.defaultProps = {
  hasInfo: false,
};

PurePageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hasInfo: PropTypes.bool,
  infoOnClick: PropTypes.func,
};

const ForwardRefPageHeader = (props, ref) => {
  const bind = useCallback(
    node => {
      if (!ref) return;
      if ('current' in ref) {
        ref.current = node;
      }
      if (typeof ref === 'function') {
        ref(node);
      }
    },
    [ref]
  );
  return <PurePageHeader {...props} bind={bind} />;
};

export default React.memo(React.forwardRef(ForwardRefPageHeader));

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { themeGet } from '@styled-system/theme-get';
import { position } from 'styled-system';

const StepBarWrapper = styled(animated.div)`
  width: 65%;
  height: 3px;
  margin: 13px auto;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
`;

const StepBar = styled(animated.div)`
  position: absolute;
  top: 0;
  height: 3px;
  width: 100%;
  background: ${props => themeGet(`colors.${props.background}`, 'gray')};
  border-radius: 15px;
  ${position}
`;

export const Step = ({ children }) => {
  return children;
};

const Steps = ({ children, current, className, extraBefore, extraAfter, style }) => {
  const [total, setTotal] = useState(1);
  const props = useSpring({
    transform: `translate3d(${
      current > total || current < 0 ? 100 : ((current + 1) / total) * 100
    }%,0,0)`,
  });

  useEffect(() => {
    let stepCount = 0;
    React.Children.map(children, (child, index) => {
      child.type === Step && stepCount++;
    });

    setTotal(stepCount);
  }, [children]);

  return (
    <div className={className} style={style}>
      <StepBarWrapper>
        <StepBar background="unavailableGrey" />
        <StepBar background="mainBlue" style={props} right={'100%'} />
      </StepBarWrapper>
      {extraBefore}
      {React.Children.map(children, (child, index) => {
        if (child.type === Step && index === current) return child;
      })}
      {extraAfter}
    </div>
  );
};

Steps.defaultProps = {
  current: 1,
};

Steps.propTypes = {
  children: PropTypes.array,
  current: PropTypes.number,
  extraBefore: PropTypes.element,
  extraAfter: PropTypes.element,
};

export default Steps;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Iframe from 'react-iframe';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const WemoIframe = styled(Iframe)`
  border: none;
  margin: 0;
  width: 100%;
  height: 100%;
`;

export const PureEntryEmergency = props => {
  const { url } = props;

  return (
    <Container data-testid="entryEmergency">
      <WemoIframe loading="加載中..." url={url} position="relative" />
    </Container>
  );
};

PureEntryEmergency.defaultProps = {};

PureEntryEmergency.propTypes = {
  url: PropTypes.string,
};

export default React.memo(PureEntryEmergency);

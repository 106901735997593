import { isObjectDiff } from '~/utils';

const initStatusState = {
  rejectReasons: [],
  statusId: null,
  isAllowLogin: null,
  isAllowRide: null,
  isAllowResubmitDoc: null,
  isAllowPurchase: null,
  deadline: null,
  isGuest: false,
};

const statusReducer = (state = initStatusState, action) => {
  switch (action?.type) {
    case 'setStatus': {
      const nextState = {
        ...state,
        ...action.payload,
        isLoading: false,
      };
      return isObjectDiff(state, nextState) ? nextState : state;
    }
    // case 'updateUserMode':
    //   return {
    //     ...state,
    //     userMode: action.userMode,
    //   };
    // case 'updateScooterMode':
    //   return {
    //     ...state,
    //     scooterMode: action.scooterMode,
    //   };
    // case 'updateRentId':
    //   return {
    //     ...state,
    //     rentId: action.rentId,
    //   };
    // case 'setUserEvent':
    //   return { ...state, userEvents: [...state.userEvents, action.payload.userEvent] };
    // case 'setStatusError':
    //   return {
    //     ...state,
    //     error: action.payload.error,
    //   };
    case 'resetStatus':
      return initStatusState;
    default:
      return state;
  }
};

export default statusReducer;

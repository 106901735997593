import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import CardValidator from 'card-validator';
import BasicContainer from 'Components/layouts/BasicContainer';
import Form from '~/components/atoms/Form';
import FormItem from '~/components/atoms/Form/FormItem';
import Input from '~/components/atoms/Input';
import Button from '~/components/atoms/Button';
import PupupLoading from '~/components/molecules/PopupLoading';
import Popup from '~/components/atoms/Popup';
import PageHeader from '~/components/molecules/PageHeader';
import { postBindCreditCard, patchPaymentsInfo, getDirectPayPrime } from '~/services/api';
import { paymentKeys } from '~/configs';

const creditCardNumberFormatter = value => {
  return (value || '')
    .replace(/•/g, '')
    .replace(/ /g, '')
    .toString();
};

const validateCardNumber = (rule, value, callback) => {
  const creditCardNo = creditCardNumberFormatter(value);
  const validation = CardValidator.number(creditCardNo);
  if (!validation.isValid && !validation.isPotentiallyValid) {
    callback('卡片號碼錯誤');
    return;
  }
  callback();
};

const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ErrorMessageTitle = styled.h1`
  ${themeGet('fonts.subtitle')};
  color: ${themeGet('colors.wrongRed')};
  margin: 0;
  text-align: center;
`;

const ErrorMessageContent = styled.p`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
  margin-top: 4px;
  text-align: center;
`;

const ErrorMessageButton = styled(Button)`
  ${themeGet('fonts.h2Button')};
  color: #fff;
  height: 50px;
  width: 100%;
  margin-top: 36px;
`;

export const PureEditCreditCard = props => {
  const { form } = props;
  const history = useHistory();
  const { getFieldDecorator, isFieldsTouched, getFieldsError, validateFields } = form;
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('success');
  const [showError, setShowError] = useState(false);

  const isSaveDisabled = useCallback(() => {
    const fieldsError = getFieldsError();
    const isFieldsError = Object.keys(fieldsError).some(field => fieldsError[field]);
    return isFieldsError || !isFieldsTouched(null, 'every');
  }, [getFieldsError, isFieldsTouched]);

  const handleSaveOnClick = useCallback(async () => {
    validateFields()
      .then(async ({ cardNumber, expiredAt, ccv }) => {
        try {
          setLoadingStatus('success');
          setLoading(true);
          const prime = await getDirectPayPrime(
            creditCardNumberFormatter(cardNumber),
            ...expiredAt.split(' / '),
            ccv
          );
          await postBindCreditCard(prime);
          await patchPaymentsInfo(paymentKeys.creditCard);
          setLoading(false);
        } catch (error) {
          setLoadingStatus('fail');
          setLoading(false);
          setShowError(true);
          console.log(error);
        }
      })
      .catch(() => {});
  }, [validateFields]);

  const handleErrorCloseOnClick = useCallback(() => {
    setShowError(false);
  }, []);

  const handleOnSuccess = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <BasicContainer autoHeight={false}>
      <PageHeader title="編輯信用卡" />
      <Form labelPosition="top">
        <FormItem label="卡片號碼">
          {getFieldDecorator('cardNumber', {
            rules: [
              { required: true, message: '請輸入卡片號碼' },
              {
                validator: validateCardNumber,
                onTrigger: false,
              },
            ],
          })(<Input type="creditCard" />)}
        </FormItem>
        <Row gutterWidth={20}>
          <Col xs={6}>
            <FormItem label="到期日">
              {getFieldDecorator('expiredAt', {
                rules: [
                  { required: true, message: '請輸入到期日' },
                  { pattern: /[0-1]\d \/ \d{2}/, message: '到期日格式錯誤', onTrigger: false },
                ],
              })(
                <Input
                  type="number"
                  placeholder="01 / 20"
                  mask={[/[0-1]/, /\d/, ' ', '/', ' ', /\d/, /\d/]}
                />
              )}
            </FormItem>
          </Col>
          <Col xs={6}>
            <FormItem label="安全碼">
              {getFieldDecorator('ccv', {
                rules: [
                  { required: true, message: '請輸入安全碼' },
                  { pattern: /\d{3}/, message: '安全碼格式錯誤', onTrigger: false },
                ],
              })(<Input type="number" placeholder="CCV" mask={[/\d/, /\d/, /\d/]} />)}
            </FormItem>
          </Col>
        </Row>
      </Form>
      <Footer bottom={[15, 30]}>
        <Button
          shape="capsule"
          type="float"
          boxShadow="light"
          width={0.64}
          disabled={isSaveDisabled()}
          onClick={handleSaveOnClick}
        >
          儲存
        </Button>
      </Footer>
      <PupupLoading
        spinning={loading}
        successText="綁定成功"
        onSuccess={handleOnSuccess}
        status={loadingStatus}
      />
      <Popup visible={showError}>
        <ErrorMessageTitle>無法儲存信用卡</ErrorMessageTitle>
        <ErrorMessageContent>輸入信用卡無效，如有疑慮請洽發卡銀行詢問</ErrorMessageContent>
        <ErrorMessageButton
          type="float"
          shape="capsule"
          boxShadow="light"
          onClick={handleErrorCloseOnClick}
        >
          確認
        </ErrorMessageButton>
      </Popup>
    </BasicContainer>
  );
};

PureEditCreditCard.defaultProps = {};

PureEditCreditCard.propTypes = {};

export default React.memo(Form.create()(PureEditCreditCard));

import React, { useContext, useEffect, Fragment, useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import { useUserInfo } from '~/context/';
import { useHistory } from 'react-router-dom';
import Input from 'Components/atoms/Input';
import Button from 'Components/atoms/Button';
import Form from 'Components/atoms/Form';
import FormItem from 'Components/atoms/Form/FormItem';
import PageHeader from 'Components/molecules/PageHeader';
import BasicContainer from 'Components/layouts/BasicContainer';
import FullScreenLoading from 'Components/molecules/FullScreenLoading';
import { editUserInfo } from '~/services/users';
import PopupLoading from '~/components/molecules/PopupLoading';
import delay from '~/utils/delay';

const RowStyled = styled(Row)`
  margin: 40px;
`;

const ButtonStyled = styled(Button)`
  margin: 0 auto 40px;
  display: block;
`;

const EditAddress = Form.create()(({ form }) => {
  const { getFieldDecorator, isFieldTouched, getFieldValue, setFieldValue, validateFields } = form;

  const history = useHistory();
  const { user, updateUserInfo } = useUserInfo();
  const [isLoading, setIsLoading] = useState(false);

  const handleEditAddress = async evt => {
    evt.preventDefault();
    try {
      setIsLoading(true);
      const data = await validateFields();
      await editUserInfo({ address: data.address });
      await updateUserInfo();
      setIsLoading(false);
      await delay(700);
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user.address) {
      setFieldValue('address', user.address);
    }
  }, [setFieldValue, user.address]);

  return (
    <BasicContainer>
      <PopupLoading
        onSuccessGoBack={true}
        spinning={isLoading || user.isLoading}
        successText="更新完成"
      />
      <Fragment>
        <PageHeader title="輸入聯絡地址"></PageHeader>
        <Form>
          <RowStyled gutterWidth={12}>
            <Col xs={12}>
              <FormItem labelPosition="top">
                {getFieldDecorator('address', {
                  rules: [{ required: true, message: '請輸入聯絡地址' }],
                  initialValue: user?.address,
                })(<Input />)}
              </FormItem>
            </Col>
          </RowStyled>
          <ButtonStyled
            boxShadow="light"
            type="float"
            shape="capsule"
            disabled={!isFieldTouched('address') || getFieldValue('address') === user?.address}
            width={64 / 100}
            onClick={handleEditAddress}
          >
            儲存
          </ButtonStyled>
        </Form>
      </Fragment>
    </BasicContainer>
  );
});

EditAddress.defaultProps = {};

EditAddress.propTypes = {};

export default EditAddress;

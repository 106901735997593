import React, { createContext, useReducer, useCallback } from 'react';
import reducer from './reducers/';

export const Context = createContext();

const GlobalStore = ({ children }) => {
  console.log('global store');

  const reducers = useReducer(reducer, reducer());
  // const reducers = useCallback(useReducer(reducer, reducer()), []);
  return <Context.Provider value={reducers}>{children}</Context.Provider>;
};

export default GlobalStore;

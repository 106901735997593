import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Button from 'Components/atoms/Button';
import Popup from '~/components/atoms/Popup';
import { ReactComponent as InfoIcon } from 'Images/icons/info.svg';

const WeMoCreditInfoWrapper = styled.div`
  width: 100%;
`;

const WeMoCreditAmount = styled.p`
  ${themeGet('fonts.h6')};
  width: 100%;
  text-align: center;
  margin: 0;
`;

const WeMoCreditTitlePanel = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WeMoCreditTitle = styled.span`
  ${themeGet('fonts.h4')};
  margin: 0;
`;

const InfoButton = styled(Button)`
  padding: 0;
  width: auto;
  height: auto;
  margin-left: 10px;
`;

const WeMoCreditDescription = styled.p`
  ${themeGet('fonts.body2')};
  width: 100%;
  margin: 0;
  text-align: center;
`;

const WeMoCreditInfoTitle = styled.p`
  ${themeGet('fonts.subtitle')};
  margin: 0;
  margin-bottom: 4px;
  width: 100%;
  text-align: center;
`;

const WeMoCreditInfoContent = styled.div`
  width: 100%;
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secondaryGrey')};
  text-align: center;
`;

const WeMoCreditInfoFooter = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 30px;
  ${space}
`;

const WeMoCreditInfoConFirm = styled(Button)`
  width: 100%;
`;

export const PureWeMoCreditInfo = props => {
  const { balance } = props;
  const [visibleInfo, setVisibleInfo] = useState(false);

  const handleOpenInfoOnClick = useCallback(() => {
    setVisibleInfo(true);
  }, []);

  const handleCloseInfoOnClick = useCallback(() => {
    setVisibleInfo(false);
  }, []);

  return (
    <WeMoCreditInfoWrapper>
      <WeMoCreditAmount>{balance}</WeMoCreditAmount>
      <WeMoCreditTitlePanel>
        <WeMoCreditTitle>WeMo 騎乘金</WeMoCreditTitle>
        <InfoButton
          shape="circle"
          color="plan"
          IconComponent={InfoIcon}
          onClick={handleOpenInfoOnClick}
        />
      </WeMoCreditTitlePanel>
      <WeMoCreditDescription>
        你的付款順序會以 WeMo 騎乘金為優先。若無騎乘金，請設定付款方式。
      </WeMoCreditDescription>
      <Popup visible={visibleInfo}>
        <WeMoCreditInfoTitle>什麼是 WeMo 騎乘金？</WeMoCreditInfoTitle>
        <WeMoCreditInfoContent>
          <p>WeMo 騎乘金是 WeMo Scooter 提供給會員的騎乘優惠點數。將會從費用中優先扣除。</p>
        </WeMoCreditInfoContent>
        <WeMoCreditInfoFooter marginTop={['15px', '30px']}>
          <WeMoCreditInfoConFirm
            shape="capsule"
            type="float"
            size="small"
            boxShadow="light"
            onClick={handleCloseInfoOnClick}
          >
            確認
          </WeMoCreditInfoConFirm>
        </WeMoCreditInfoFooter>
      </Popup>
    </WeMoCreditInfoWrapper>
  );
};

PureWeMoCreditInfo.defaultProps = {
  balance: 0,
};

PureWeMoCreditInfo.propTypes = {
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default React.memo(PureWeMoCreditInfo);

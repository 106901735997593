import { useContext, useCallback } from 'react';
import { UserInfoContext } from '../providers/UserInfoProvider';
import { getUserInfo } from '~/services/api';
import useLoadingGroup from '~/hooks/useLoadingGroup';
import { rentStatus } from '~/configs';
import { underlineToCamelCase } from '~/utils/stringConverter';

const getLiffData = () => ({
  ...window.liff.getDecodedIDToken(),
  accessToken: window.liff.getAccessToken(),
});

const useUserInfo = () => {
  const [user, dispatch] = useContext(UserInfoContext);
  const loadingUtils = useLoadingGroup({
    userInfo: false,
  });

  // console.log('user', user);

  // dev login (external browser line login)
  const updateUserInfo = useCallback(async () => {
    try {
      loadingUtils.start('userInfo');
      let [rawUserInfo, liffProfile] = await Promise.all([getUserInfo(), getLiffData()]);

      const { userInfo, appReturnInfo } = underlineToCamelCase(rawUserInfo);

      dispatch({
        type: 'setUser',
        payload: {
          ...userInfo,
          line: liffProfile,
          userMode: appReturnInfo ? appReturnInfo.appReturnLocation : rentStatus.none,
        },
      });

      loadingUtils.end('userInfo');
    } catch (error) {
      loadingUtils.end('userInfo', 'fail');
      console.log(error);
      return null;
    }
  }, []);

  const resetUserInfo = useCallback(() => {
    dispatch({
      type: 'resetUser',
    });
  }, []);

  return {
    user,
    loadingUtils,
    resetUserInfo,
    updateUserInfo,
  };
};

export { useUserInfo };

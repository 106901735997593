import React from 'react';
import MaskInput from './MaskInput';

const placeholder = '••••  ••••  ••••  ••••';
const mask = Array.from(placeholder).map(text => {
  if (text === ' ') return text;
  else return /\d/;
});

const PureCreditCardInput = props => {
  const { getInputPlaceHolder, ...otherProps } = props;
  return (
    <MaskInput
      {...otherProps}
      type="tel"
      placeholder={getInputPlaceHolder(placeholder)}
      mask={mask}
      placeholderChar="•"
    />
  );
};

export default React.memo(PureCreditCardInput);

import LineAxios from './LineAxios';

LineAxios.create('hermes');
const axios = LineAxios.getInstance('hermes').useToken();

export const postUserFeedback = async (content, photos, onProgress) => {
  const data = new FormData();
  data.append('content', content);
  for (let photo of photos) {
    data.append('image[]', photo);
  }
  return axios.post(`/v2/feedbacks`, data, {
    headers: {
      contentType: 'multipart/form-data',
    },
    ...(onProgress && {
      onUploadProgress: onProgress,
    }),
  });
};

import React from 'react';
import PropTypes from 'prop-types';

export const PureTabPane = props => {
  const { children } = props;
  return children;
};

PureTabPane.defaultProps = {};

PureTabPane.propTypes = {
  tab: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  tabStyle: PropTypes.object,
  contentStyle: PropTypes.object,
};

export default React.memo(PureTabPane);

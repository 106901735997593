import { getMockAdapter } from './mock';

const mockParkingSpaces = () => {
  const mock = getMockAdapter();
  mock.onGet('/v2/parkingSpaces').reply(200, {
    result: 'success',
    data: [
      [[25.0641261673, 121.5184775604]],
      [[25.0633306303, 121.5184623857]],
      [[25.0633708637, 121.5184625808]],
      [[25.0607342671, 121.5183994709]],
      [[25.0617383518, 121.5181015266]],
      [[25.0616890376, 121.5181012119]],
      [[25.0609106586, 121.518085645]],
      [[25.0627576997, 121.517886496]],
      [[25.0636865667, 121.5178872071]],
      [[25.0645981897, 121.5177519991]],
      [[25.0627682954, 121.5174635083]],
      [[25.0630914292, 121.5162276641]],
      [[25.0604789339, 121.5158759779]],
      [[25.0599518566, 121.5158607611]],
      [[25.0631027708, 121.5158349053]],
      [[25.0621821674, 121.5157510145]],
      [[25.0628149223, 121.5157084795]],
      [[25.0597925303, 121.5156037289]],
      [[25.0596899374, 121.5155994166]],
      [[25.063108438, 121.5156140155]],
      [[25.0631152258, 121.5153690664]],
      [[25.0631170476, 121.5153124096]],
      [[25.0642926852, 121.5152117923]],
      [[25.0642206067, 121.5151858079]],
      [[25.0631288004, 121.5149503961]],
      [[25.0628354848, 121.5148972408]],
      [[25.0624018171, 121.514527161]],
      [[25.0622972354, 121.5143812836]],
      [
        [25.0622046512, 121.5192533045],
        [25.0622028903, 121.5193341066],
      ],
      [
        [25.0632841719, 121.5191281766],
        [25.0633175697, 121.5192991673],
      ],
      [
        [25.0622079367, 121.5191444358],
        [25.0622061637, 121.5192144545],
      ],
      [
        [25.063383009, 121.5191554552],
        [25.0633933849, 121.5192122676],
      ],
      [
        [25.0622102707, 121.519037316],
        [25.062209089, 121.5191073495],
      ],
      [
        [25.0636086864, 121.5190229564],
        [25.0634312216, 121.5190687785],
      ],
      [
        [25.063833444, 121.5189659033],
        [25.0636875582, 121.5190029985],
      ],
      [
        [25.0640543643, 121.5189082666],
        [25.0638732188, 121.5189548832],
      ],
      [
        [25.0622160193, 121.518825769],
        [25.0622146892, 121.5188742331],
      ],
      [
        [25.0622192772, 121.518702774],
        [25.0622168338, 121.5188035796],
      ],
      [
        [25.0622206893, 121.5186361965],
        [25.0622195874, 121.518676579],
      ],
      [
        [25.0622236174, 121.5185475574],
        [25.0622220281, 121.5185879206],
      ],
      [
        [25.0645175681, 121.5184672658],
        [25.0645960405, 121.5184869811],
      ],
      [
        [25.0644995922, 121.5184865538],
        [25.0644156023, 121.5184846471],
      ],
      [
        [25.0643114673, 121.5184819396],
        [25.0643786434, 121.5184837321],
      ],
      [
        [25.0640222606, 121.5184688325],
        [25.0640931961, 121.5184772196],
      ],
      [
        [25.062343605, 121.5184307574],
        [25.0623915446, 121.5184312405],
      ],
      [
        [25.0620527599, 121.5184246913],
        [25.0621398598, 121.5184261033],
      ],
      [
        [25.0619865544, 121.5184239011],
        [25.0620231505, 121.5184241978],
      ],
      [
        [25.0619042546, 121.5184223624],
        [25.0619530558, 121.5184228899],
      ],
      [
        [25.0618432041, 121.5184206526],
        [25.0618846761, 121.5184215295],
      ],
      [
        [25.0617748806, 121.5184190924],
        [25.0618187933, 121.5184200391],
      ],
      [
        [25.0610302909, 121.5184141105],
        [25.0610717248, 121.518416263],
      ],
      [
        [25.0617530117, 121.5184182096],
        [25.0617117426, 121.5184180446],
      ],
      [
        [25.0606614911, 121.5183963631],
        [25.0607086963, 121.5183982256],
      ],
      [
        [25.0605545955, 121.5183936997],
        [25.0606416964, 121.5183957548],
      ],
      [
        [25.064034258, 121.5181592568],
        [25.0641155938, 121.5181610565],
      ],
      [
        [25.0637435192, 121.5181534086],
        [25.0639794336, 121.5181585039],
      ],
      [
        [25.0646587659, 121.5181517492],
        [25.0646948512, 121.5181525144],
      ],
      [
        [25.0632920669, 121.5181243298],
        [25.0633821147, 121.5181266956],
      ],
      [
        [25.060969127, 121.5180857094],
        [25.0609396893, 121.51810439],
      ],
      [
        [25.0608089176, 121.5180938864],
        [25.0608697362, 121.5180859191],
      ],
      [
        [25.0642404285, 121.5179082909],
        [25.0642359577, 121.5181064441],
      ],
      [
        [25.0606884977, 121.5180813914],
        [25.0607299757, 121.51808185],
      ],
      [
        [25.0604583722, 121.5180761155],
        [25.0605144823, 121.5180777038],
      ],
      [
        [25.0643153878, 121.5178834337],
        [25.0643121591, 121.5180541547],
      ],
      [
        [25.0636852752, 121.5179261639],
        [25.0636840668, 121.5179586297],
      ],
      [
        [25.0602716073, 121.5178422372],
        [25.060270235, 121.5179244659],
      ],
      [
        [25.0636350193, 121.5177030305],
        [25.0636317824, 121.517846693],
      ],
      [
        [25.0650456943, 121.5177586819],
        [25.0651190374, 121.5177600018],
      ],
      [
        [25.064952057, 121.517757259],
        [25.065000852, 121.5177582152],
      ],
      [
        [25.0648340811, 121.5177559012],
        [25.0649072916, 121.5177566361],
      ],
      [
        [25.0644643583, 121.5177500718],
        [25.0644965853, 121.5177504946],
      ],
      [
        [25.060175643, 121.5176690749],
        [25.0601788088, 121.5177263065],
      ],
      [
        [25.0625339422, 121.5177041954],
        [25.0626949563, 121.5177090704],
      ],
      [
        [25.0624365627, 121.5177013693],
        [25.0624889772, 121.5177028905],
      ],
      [
        [25.0623564402, 121.5176993449],
        [25.0622661797, 121.5176990361],
      ],
      [
        [25.0601578224, 121.5175918919],
        [25.0601707816, 121.5176432901],
      ],
      [
        [25.0642597303, 121.5172072788],
        [25.0642466939, 121.5176595511],
      ],
      [
        [25.0627663955, 121.5175702051],
        [25.0627647428, 121.5176393091],
      ],
      [
        [25.0636424218, 121.5175075211],
        [25.0636383429, 121.5176241928],
      ],
      [
        [25.061561869, 121.5171821189],
        [25.0615500509, 121.5176058253],
      ],
      [
        [25.0601848519, 121.5172848932],
        [25.0602462399, 121.5175425774],
      ],
      [
        [25.0636474988, 121.5173459788],
        [25.0636471843, 121.517381886],
      ],
      [
        [25.0599433959, 121.5173320338],
        [25.0598470559, 121.5173494939],
      ],
      [
        [25.0627747816, 121.517252099],
        [25.0627727868, 121.5173063556],
      ],
      [
        [25.063649704, 121.5172556039],
        [25.0636489899, 121.5172915051],
      ],
      [
        [25.0630637854, 121.5171802405],
        [25.063061995, 121.5172495721],
      ],
      [
        [25.0600241068, 121.5170261875],
        [25.0600646269, 121.5172001697],
      ],
      [
        [25.0635344354, 121.5170974396],
        [25.063609553, 121.5170999063],
      ],
      [
        [25.0631061517, 121.5170871431],
        [25.0631512656, 121.5170879992],
      ],
      [
        [25.0615677666, 121.5169749886],
        [25.0615647174, 121.5170800147],
      ],
      [
        [25.0641219325, 121.5170542],
        [25.0641625901, 121.5170550971],
      ],
      [
        [25.0635387559, 121.5170377908],
        [25.0635712713, 121.5170388068],
      ],
      [
        [25.0642668807, 121.5169642152],
        [25.0642642872, 121.5170485934],
      ],
      [
        [25.0616232695, 121.5168980869],
        [25.0616219807, 121.5169699154],
      ],
      [
        [25.0642748106, 121.5166578827],
        [25.0642726391, 121.5167665295],
      ],
      [
        [25.0627888577, 121.5166825261],
        [25.0627876337, 121.5167353481],
      ],
      [
        [25.0622003371, 121.5164979741],
        [25.0621960768, 121.5166809112],
      ],
      [
        [25.0642799312, 121.5164934004],
        [25.0642780193, 121.5165606913],
      ],
      [
        [25.0627956804, 121.5164274047],
        [25.0627943669, 121.5164812612],
      ],
      [
        [25.0622044883, 121.5163465025],
        [25.0622026263, 121.5164398817],
      ],
      [
        [25.0615877777, 121.5160771381],
        [25.0615806076, 121.5164143272],
      ],
      [
        [25.0627988465, 121.5163181396],
        [25.062796907, 121.5163978663],
      ],
      [
        [25.0598412237, 121.5162492824],
        [25.0598514066, 121.5162927465],
      ],
      [
        [25.059932527, 121.5162112273],
        [25.0599509872, 121.5162903577],
      ],
      [
        [25.0622063425, 121.5161869778],
        [25.0622048209, 121.5162803647],
      ],
      [
        [25.0642904873, 121.5161646264],
        [25.0642880042, 121.5162696709],
      ],
      [
        [25.0630931654, 121.5161426504],
        [25.0630918949, 121.516191116],
      ],
      [
        [25.0622065002, 121.5160542542],
        [25.0622061794, 121.5161530457],
      ],
      [
        [25.065377798, 121.5160163149],
        [25.0654103086, 121.5160174683],
      ],
      [
        [25.0648811344, 121.5160001506],
        [25.064995141, 121.5160029709],
      ],
      [
        [25.0604389581, 121.51593341],
        [25.0605365696, 121.5159354564],
      ],
      [
        [25.0647572666, 121.5158212297],
        [25.0647537294, 121.5159302013],
      ],
      [
        [25.0619641958, 121.5158926034],
        [25.0620853862, 121.5158956651],
      ],
      [
        [25.0610723773, 121.5158862181],
        [25.0611048917, 121.5158872935],
      ],
      [
        [25.0617636409, 121.5158883294],
        [25.0618143729, 121.5158894366],
      ],
      [
        [25.0603999022, 121.5158741524],
        [25.0604389923, 121.51587564],
      ],
      [
        [25.0603205171, 121.5158721957],
        [25.0603530413, 121.5158728314],
      ],
      [
        [25.0606355971, 121.5158741955],
        [25.0606762576, 121.5158749781],
      ],
      [
        [25.0599893777, 121.5158621539],
        [25.0600502793, 121.5158633159],
      ],
      [
        [25.0621810389, 121.5157866211],
        [25.0621798249, 121.5158404808],
      ],
      [
        [25.0628130951, 121.5157522599],
        [25.0628115583, 121.5158219233],
      ],
      [
        [25.0598234296, 121.5157463841],
        [25.0598321268, 121.5157902393],
      ],
      [
        [25.0628213778, 121.5155058855],
        [25.0628208249, 121.5155507742],
      ],
      [
        [25.0608276872, 121.5150926204],
        [25.0608457153, 121.5154581398],
      ],
      [
        [25.064254385, 121.5153440377],
        [25.0642517272, 121.5154424097],
      ],
      [
        [25.0597006139, 121.5152226599],
        [25.0597095663, 121.515260307],
      ],
      [
        [25.0638783504, 121.5151234022],
        [25.0640967646, 121.5152044669],
      ],
      [
        [25.0628293187, 121.5151646197],
        [25.0628282434, 121.5152077003],
      ],
      [
        [25.0628308, 121.5150861775],
        [25.062829424, 121.5151319431],
      ],
      [
        [25.0608876691, 121.5145830526],
        [25.0608915708, 121.5146470431],
      ],
      [
        [25.0626175979, 121.514534774],
        [25.0627676197, 121.5146043599],
      ],
      [
        [25.062432205, 121.5145409217],
        [25.0625091484, 121.5145764659],
      ],
      [
        [25.0607837858, 121.5145008161],
        [25.0607881815, 121.5145724953],
      ],
      [
        [25.0608804539, 121.5144733157],
        [25.060885575, 121.5145407236],
      ],
      [
        [25.0624490792, 121.5144510623],
        [25.062483594, 121.5144665673],
      ],
      [
        [25.0604868668, 121.5143968293],
        [25.0604463322, 121.5144097937],
      ],
      [
        [25.062190435, 121.5143327811],
        [25.062218097, 121.5143451452],
      ],
      [
        [25.0611211665, 121.5141760838],
        [25.0610076971, 121.5142129577],
      ],
      [
        [25.0612362887, 121.5141386877],
        [25.0612050347, 121.514148647],
      ],
      [
        [25.060755948, 121.5139427418],
        [25.0607575603, 121.5139823263],
      ],
      [
        [25.0618595425, 121.5137555679],
        [25.0618598929, 121.5139082697],
      ],
      [
        [25.0617533445, 121.5137603314],
        [25.0617534738, 121.5138142074],
      ],
      [
        [25.0620073666, 121.5134055253],
        [25.0620945101, 121.5134073819],
      ],
      [
        [25.0617139072, 121.5133925738],
        [25.0619027761, 121.5134040073],
      ],
      [
        [25.0619343369, 121.5134037307],
        [25.0619715133, 121.5134043798],
      ],
      [
        [25.06240376, 121.5133263788],
        [25.0624061942, 121.5133758711],
      ],
    ],
  });
};

export default mockParkingSpaces;

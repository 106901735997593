import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Option, { InvisibleOption } from './Option';
import { ReactComponent as ArrowDownIcon } from '~/images/icons/arrow-down.svg';

const SelectWrapperStyled = styled.div`
  position: relative;
  background: ${themeGet('colors.typeSpaceGrey')};
  max-width: 100%;
  height: 100%;
  border: 1px solid ${themeGet('colors.typeSpaceGrey')};
  margin: 0;
  min-width: 0;
  display: block;
  outline: none;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0 10px;
  transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  text-align: ${props => props.align};

  &::placeholder {
    color: ${themeGet('colors.secondaryGrey')};
  }

  ${({ size, focus, feedback }) => {
    let styles = css``;
    if (size === 'large')
      styles = styles.concat(css`
        font-size: 20px;
        height: 60px;
      `);
    else
      styles = styles.concat(css`
        font-size: 16px;
        height: 44px;
      `);

    if (feedback === 'error')
      styles = styles.concat(css`
        border-color: ${themeGet('colors.wrongRed')};
      `);
    else if (focus)
      styles = styles.concat(css`
        border-color: ${themeGet('colors.mainBlue')};
      `);

    return styles;
  }}
`;

const SelectStyled = styled.select`
  -webkit-appearance: none;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  line-height: 1;
  height: 100%;
  width: 100%;
  color: ${themeGet('colors.mainDark')};
  -webkit-tap-highlight-color: transparent;

  ${({ size }) => {
    if (size === 'large')
      return css`
        font-size: 20px;
      `;
    else
      return css`
        font-size: 16px;
      `;
  }}
`;

const IcoPanelStyled = styled.div`
  background: ${themeGet('colors.typeSpaceGrey')};
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 1;
  height: 100%;
  padding-left: 10px;
  pointer-events: none;
`;

const IconStyled = styled.img`
  vertical-align: middle;
`;

export const PureSelect = props => {
  const {
    children,
    placeholder,
    labelPosition,
    isShrink,
    width,
    selectIconPanelStyle,
    inputWrapperStyle,
    inputStyle,
    align,
    feedback,
    style,
    size,
    options,
    onFocus,
    onBlur,
    ...selectProps
  } = props;
  const [isFocus, setIsFocus] = useState(false);

  const getInputPlaceHolder = useCallback(
    placeholder => {
      if (labelPosition === 'top' || labelPosition === undefined) return placeholder;
      if (isShrink) return placeholder;
      return undefined;
    },
    [labelPosition, isShrink]
  );

  const handleOnFocus = useCallback(
    event => {
      setIsFocus(true);
      onFocus(event);
    },
    [onFocus]
  );

  const handleOnBlur = useCallback(
    event => {
      setIsFocus(false);
      onBlur(event);
    },
    [onBlur]
  );

  return (
    <SelectWrapperStyled
      style={{ ...inputWrapperStyle, ...style, width }}
      size={size}
      focus={isFocus}
      feedback={feedback}
    >
      <SelectStyled
        {...selectProps}
        style={{ ...inputStyle }}
        placeholder={getInputPlaceHolder(placeholder)}
        align={align}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      >
        <InvisibleOption />
        {options ? options.map(option => <Option key={option.value} {...option} />) : children}
      </SelectStyled>
      <IcoPanelStyled size={size} style={{ ...selectIconPanelStyle }}>
        <IconStyled as={ArrowDownIcon} />
      </IcoPanelStyled>
    </SelectWrapperStyled>
  );
};

PureSelect.defaultProps = {
  size: 'medium',
  width: 300,
  onFocus: () => {},
  onBlur: () => {},
};

PureSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  size: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  feedback: PropTypes.oneOf(['error']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

const Select = React.memo(PureSelect);

Select.Option = props => <Option {...props} />;

export default Select;

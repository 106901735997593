import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import TakeRegistrationPhotoStep from 'Components/organisms/TakeRegistrationPhotoStep';
import qs from 'qs';
import { createScanImageBarcode } from '~/utils/scanCode';
import imageCompression from 'browser-image-compression';
import { logScanIdCardBarcodeEvent } from '~/utils/firebaseService';
import useLoading from '~/hooks/useLoading';
import PopupLoading from '~/components/molecules/PopupLoading';
import { uploadUserPhotos } from '~/services/api';
import { logScreenViewEvent } from '~/utils/firebaseService';
import { useErrorNotification } from '~/context/ErrorNotification';
import createPhotoUtils from '~/utils/photoUtils';

const { beginScanImageBarcode, cancelScanImageBarcode } = createScanImageBarcode();
const OS = window.liff ? window.liff.getOS() : 'android';
const DefaultMinStep = 0;
const DefaultMaxStep = 2;
const stepsTexts = [
  { prevStepText: '取消', nextStepText: '下一步' },
  { prevStepText: '上一步', nextStepText: '下一步' },
  { prevStepText: '上一步', nextStepText: '完成' },
];

const getStepRangeByUrlQuery = urlQuery => {
  let min = parseInt(urlQuery.min, 10);
  let max = parseInt(urlQuery.max, 10);
  min = isNaN(min) ? DefaultMinStep : min < DefaultMinStep ? DefaultMinStep : min;
  max = isNaN(max) ? DefaultMaxStep : max > DefaultMaxStep ? DefaultMaxStep : max;
  return { max, min };
};

export const PureTakeRegistrationPhotosV2 = props => {
  const { limit: _limit, onClose, onFinish, allFinishText: _allFinishText } = props;
  const [step, setStep] = useState(0);
  const idCard = useRef();
  const urlQuery = useRef(qs.parse(window.location.search.slice(1)));
  const [nextStepDisabled, setNextStepDisabled] = useState(true);
  const [stepsPhotos, setStepsPhotos] = useState(new Array(stepsTexts.length).fill([]));
  const scanBarcodeLoading = useLoading({ loading: false });
  const uploadLoading = useLoading({ loading: false });
  const photosName = useRef();
  const [isAllFinish, setIsAllFinish] = useState(false);
  const [allFinishText, setAllFinishText] = useState(_allFinishText);
  const [limit, setLimit] = useState(_limit);
  const { registerErrorConfig, launchError, launchApiError } = useErrorNotification();

  const handleUploadPhotos = useRef();
  useEffect(() => {
    handleUploadPhotos.current = async () => {
      try {
        uploadLoading.start();
        const photos = stepsPhotos.reduce((photos, stepPhotos) => {
          return [...photos, ...stepPhotos];
        }, []);
        photosName.current = await uploadUserPhotos(photos).then(({ data }) => data.names);
        cancelScanImageBarcode();
        logScreenViewEvent({ name: `camera-popup-upload-success` });
        uploadLoading.end();
        setIsAllFinish(true);
      } catch (error) {
        uploadLoading.end('fail');
        logScreenViewEvent({ name: `camera-popup-upload-failed` });
        launchApiError(error, 'take-registration-photos-v2');
        throw error;
      }
    };
  }, [stepsPhotos]);

  const handlePrevStepOnClick = useCallback(() => {
    if (step === limit.min) {
      if (OS === 'ios' && typeof onClose === 'function') {
        onClose();
        setStepsPhotos(new Array(stepsTexts.length).fill([]));
        idCard.current = undefined;
      } else {
        const { redirectTo, goBack = {} } = urlQuery.current;
        window.location.href = `${process.env.REACT_APP_LINE_MINI_URL}${redirectTo}?${qs.stringify({
          ...goBack,
        })}`;
        setTimeout(() => {
          if (window.close) {
            window.close();
          }
        }, 500);
      }
    } else {
      setStep(step - 1);
    }
  }, [step, onClose, limit.min]);

  const handleNextStepOnClick = useCallback(async () => {
    if (step === limit.max) {
      handleUploadPhotos.current();
    } else {
      setStep(step + 1);
      setNextStepDisabled(true);
    }
  }, [step, limit.max]);

  const handleOnStepFinish = useCallback(() => {
    setNextStepDisabled(false);
  }, []);

  const handleOnAllFinish = useCallback(() => {
    if (OS === 'ios' && typeof onFinish === 'function') {
      onFinish(photosName.current, idCard.current);
      setStepsPhotos(new Array(stepsTexts.length).fill([]));
      photosName.current = undefined;
      idCard.current = undefined;
      setIsAllFinish(false);
    } else {
      const { redirectTo, forward = {} } = urlQuery.current;
      window.location.href = `${process.env.REACT_APP_LINE_MINI_URL}${redirectTo}?${qs.stringify({
        ...forward,
        token: urlQuery.current.token,
        photos: photosName.current,
        idCard: idCard.current,
      })}`;
      setTimeout(() => {
        if (window.close) {
          window.close();
        }
      }, 500);
    }
  }, [onFinish]);

  const handleOnError = useCallback(error => {
    launchError(error, 'take-registration-photos-v2');
  }, []);

  const handleScanBarcode = useRef(async photo => {
    const url = await imageCompression.getDataUrlFromFile(photo);
    const result = await beginScanImageBarcode()(url);
    idCard.current = !result && idCard.current ? idCard.current : result;
    logScanIdCardBarcodeEvent({ status: result ? 'success' : 'failure' });
  });

  const handleOnChange = useCallback(
    async (index, photo) => {
      if (!photo) return;
      scanBarcodeLoading.start();

      // 旋轉照片（Android 很機歪）
      const photoUtils = createPhotoUtils();
      photoUtils.rotateOrientation();

      // 掃描身分證條碼
      if (step === 0 && index === 1) {
        const scannedPhoto = await photoUtils
          .clone()
          .compression({
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: 'image/jpeg',
          })
          .execute(photo);
        await handleScanBarcode.current(scannedPhoto);
      }

      // 壓縮照片
      photo = await photoUtils.compression().execute(photo);

      setStepsPhotos(prev => {
        const next = [...prev];
        const group = [...(next[step] || [])];
        group[index] = photo;
        next[step] = group;
        return next;
      });

      scanBarcodeLoading.end();
    },
    [step]
  );

  useEffect(() => {
    const { min, max, allFinishText } = urlQuery.current;
    let _limit = { ...limit };
    if (min !== undefined || max !== undefined) {
      _limit = getStepRangeByUrlQuery(urlQuery.current);
      if (limit.max !== _limit.max || limit.min !== _limit.min) {
        setLimit(_limit);
      }
    }
    setStep(_limit.min);

    if (allFinishText) {
      setAllFinishText(allFinishText);
    }

    registerErrorConfig('take-registration-photos-v2', {
      STEP_OUT_OFF_RANGE: {
        popup: {
          title: '流程異常',
          notes: '已超過最大流程數',
          confirmButtonText: '確認',
          onConfirmClick() {
            setStep(_limit.min);
          },
        },
        notifier: 'popup',
      },
    });

    return () => {
      cancelScanImageBarcode();
    };
  }, []);

  const stepTexts = useMemo(() => {
    const stepTexts = stepsTexts[step];
    const prevStepText = step === limit.min ? '取消' : stepTexts.prevStepText;
    const nextStepText = step === limit.max ? '完成' : stepTexts.nextStepText;
    return {
      ...stepTexts,
      prevStepText,
      nextStepText,
    };
  }, [step, limit]);

  return (
    <React.Fragment>
      <TakeRegistrationPhotoStep
        step={step}
        {...stepTexts}
        photos={stepsPhotos[step]}
        nextStepDisabled={nextStepDisabled}
        prevStepOnClick={handlePrevStepOnClick}
        nextStepOnClick={handleNextStepOnClick}
        onStepFinish={handleOnStepFinish}
        onChange={handleOnChange}
        onError={handleOnError}
        isAllFinish={isAllFinish}
        onAllFinishClick={handleOnAllFinish}
        allFinishText={allFinishText}
      />
      <PopupLoading {...scanBarcodeLoading.state} />
      <PopupLoading {...uploadLoading.state} successText="上傳成功" />
    </React.Fragment>
  );
};

PureTakeRegistrationPhotosV2.defaultProps = {
  limit: { min: 0, max: 2 },
};

PureTakeRegistrationPhotosV2.propTypes = {
  limit: PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  allFinishText: PropTypes.string,
};

export default React.memo(PureTakeRegistrationPhotosV2);

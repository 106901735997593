import React, { useEffect, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import css from '@styled-system/css';
import { themeGet } from '@styled-system/theme-get';
import { useHistory } from 'react-router-dom';
import { animated, useSpring, useTransition, useChain } from 'react-spring';
import SwipeableViews from 'react-swipeable-views';
import { useDebouncedCallback } from 'use-debounce';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { usePageInfo } from '~/context/';
import BasicContainer from 'Components/layouts/BasicContainer';
import PaginationDots from 'Components/atoms/PaginationDots';
import Button from 'Components/atoms/Button';
import { tutorials } from './config';
import RiderPng from '~/images/rider.png';

const details = tutorials.reduce((acc, topic) => [...acc, ...topic.details], []);
const topicsDetailBeginIndex = tutorials.reduce((acc, topic, index) => {
  if (index === 0) return [0];
  return [...acc, acc[index - 1] + tutorials[index - 1].details.length];
}, []);
const topicsDetailEndIndex = tutorials.reduce((acc, topic, index) => {
  if (index === 0) return [tutorials[index].details.length - 1];
  return [...acc, acc[index - 1] + tutorials[index].details.length - 1];
}, []);

const Container = styled(React.forwardRef((props, ref) => <BasicContainer ref={ref} {...props} />))`
  background-color: ${({ bgColor }) => themeGet(`colors.${bgColor}`)};
  transition: background-color 200ms;
`;

const Wrapper = styled.div`
  ${space};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const TutorialImg = styled.img`
  flex: 0 0 auto;
  width: 100%;
`;

const Content = styled.div`
  position: relative;
  flex-grow: 1;
  height: 100%;
  padding: 20px;
`;

const FooterWrapper = styled.div`
  height: 74px;
  flex: 0 0 auto;
`;

const Footer = styled(animated.div)``;

const TopicPanel = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  ${css({ mt: ['10px', '20px'] })}
`;

const Topic = styled.span`
  padding: 6px 20px;
  border-radius: 5px;
  ${themeGet('fonts.button2')}
  color: ${({ isCurrent }) => themeGet(`colors.${isCurrent ? 'mainBlue' : 'supGrey'}`)};
  transition: color 200ms;
  z-index: 1;
`;

const TopicHightLight = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  border-radius: 5px;
  background: ${themeGet(`colors.uselessBlue`)};
`;

const Title = styled.h3`
  ${themeGet('fonts.h3Button')}
  margin: 0;
  margin-bottom: 4px;
`;

const Description = styled.p`
  /* ${themeGet('fonts.h4')} */
  ${themeGet('fonts.body')}
  margin: 0;
`;

const SwipeContent = styled.div`
  position: relative;
  height: 100%;
`;

const RiderImg = styled.img.attrs({ src: RiderPng })``;

const FinalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const FinalContentWrapper = styled.div`
  text-align: center;
`;

const FinalTitle = styled.h3`
  ${themeGet('fonts.h3Button')};
  margin: 0;
  margin-top: 44px;
`;

const FinalText = styled.p`
  ${themeGet('fonts.body')};
  margin: 0;
`;

const FinalButton = styled(Button)`
  width: 240px;
  flex: 0 0 auto;
`;

const FinalFooter = styled(Footer)`
  text-align: center;
`;

export const PureRentTutorial = props => {
  const history = useHistory();
  const { state } = history.location;
  const containerEl = useRef();
  const { setPageInfoPrevPage } = usePageInfo();
  const topicsEl = useRef([]);
  const [topicIndex, setTopicIndex] = useState(0);
  const [detailIndex, setDetailIndex] = useState(0);
  const [topicSpring, setTopicSpring] = useSpring(() => ({
    transform: `translate3d(0px, 0px, 0px)`,
    width: 0,
  }));
  const topicFooterTransitionRef = useRef();
  const topicFooterTransition = useTransition(detailIndex < details.length, null, {
    from: {
      transform: 'translate3d(0, 100%, 0)',
    },
    enter: { transform: 'translate3d(0, 0, 0)' },
    leave: {
      transform: 'translate3d(0, 100%, 0)',
    },
    config: {
      duration: 250,
    },
    ref: topicFooterTransitionRef,
  });
  const finalFooterTransitionRef = useRef();
  const finalFooterTransition = useTransition(detailIndex === details.length, null, {
    from: {
      transform: 'translate3d(0, 100%, 0)',
    },
    enter: { transform: 'translate3d(0, 0%, 0)' },
    leave: {
      transform: 'translate3d(0, 100%, 0)',
    },
    config: {
      duration: 250,
    },
    ref: finalFooterTransitionRef,
  });

  useChain(
    detailIndex === details.length
      ? [topicFooterTransitionRef, finalFooterTransitionRef]
      : [finalFooterTransitionRef, topicFooterTransitionRef]
  );

  const bindTopicEl = useCallback(
    index => node => {
      topicsEl.current[index] = node;
    },
    []
  );

  const [handleOnTopicClick] = useDebouncedCallback(
    index => {
      setTopicIndex(index);
      setDetailIndex(topicsDetailBeginIndex[index]);
    },
    300,
    { leading: true, trailing: false }
  );

  const handleOnSwipeChange = useCallback(index => {
    setDetailIndex(index);
    let topicIndex = topicsDetailBeginIndex.findIndex(src => src === index);
    topicIndex =
      topicIndex === -1 ? topicsDetailEndIndex.findIndex(src => src === index) : topicIndex;
    if (topicIndex !== -1) {
      setTopicIndex(topicIndex);
    }
  }, []);

  const handleOnDotClick = useCallback(
    index => {
      setDetailIndex(index + topicsDetailBeginIndex[topicIndex]);
    },
    [topicIndex]
  );

  const [handleOnFinalClick] = useDebouncedCallback(
    () => {
      if (state) {
        if (state.nextPage) {
          if (Array.isArray(state.nextPage)) history.replace(...state.nextPage);
          else history.replace(state.nextPage);
        } else if (state.prevPage) {
          history.replace(...state.prevPage);
        }
      }
    },
    300,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    const currentTopic = topicsEl.current[topicIndex];
    const { width, left } = currentTopic.getBoundingClientRect();
    setTopicSpring({ transform: `translate3d(${left}px, 0px, 0px)`, width });
  }, [topicIndex]);

  useEffect(() => {
    if (state && state.prevPage) {
      const { prevPage } = state;
      setPageInfoPrevPage(prevPage);
    }

    const wrapper = containerEl.current;
    wrapper && disablePageScroll(wrapper);
    return () => {
      wrapper && enablePageScroll(wrapper);
    };
  }, []);

  return (
    <Container
      ref={containerEl}
      autoHeight={false}
      bgColor={detailIndex === details.length ? 'white' : 'bgGrey'}
    >
      <Wrapper mx={['-15px', '-30px']} mt={['-9.5px', '-11px']}>
        <SwipeableViews
          style={{ height: '100%' }}
          containerStyle={{ height: '100%' }}
          slideStyle={{ overflow: 'hidden' }}
          enableMouseEvents={true}
          index={detailIndex}
          onChangeIndex={handleOnSwipeChange}
        >
          {details.map((detail, index) => (
            <SwipeContent key={index}>
              <TutorialImg src={detail.img} />
              <Content>
                <Title>{detail.title}</Title>
                <Description>{detail.description}</Description>
              </Content>
            </SwipeContent>
          ))}
          <FinalContent>
            <FinalContentWrapper>
              <RiderImg />
              <FinalTitle>恭喜你完成租借教學！</FinalTitle>
              <FinalText>租借 WeMo Scooter 是不是很簡單呢？</FinalText>
            </FinalContentWrapper>
          </FinalContent>
        </SwipeableViews>
        <FooterWrapper>
          {topicFooterTransition.map(
            ({ item, key, props }) =>
              item && (
                <Footer key={key} style={props}>
                  <PaginationDots
                    index={detailIndex - topicsDetailBeginIndex[topicIndex]}
                    count={tutorials[topicIndex].details.length}
                    onIndexChange={handleOnDotClick}
                  />
                  <TopicPanel>
                    {tutorials.map((tutorial, index) => (
                      <Topic
                        key={index}
                        ref={bindTopicEl(index)}
                        isCurrent={topicIndex === index}
                        onClick={() => handleOnTopicClick(index)}
                      >
                        {tutorial.topic}
                      </Topic>
                    ))}
                    <TopicHightLight style={topicSpring} />
                  </TopicPanel>
                </Footer>
              )
          )}
          {finalFooterTransition.map(
            ({ item, key, props }) =>
              item && (
                <FinalFooter key={key} style={props}>
                  <FinalButton
                    boxShadow="light"
                    shape="capsule"
                    type="float"
                    onClick={handleOnFinalClick}
                  >
                    {(state || {}).finalButtonText || '馬上租借 WeMo'}
                  </FinalButton>
                </FinalFooter>
              )
          )}
        </FooterWrapper>
      </Wrapper>
    </Container>
  );
};

PureRentTutorial.defaultProps = {};

PureRentTutorial.propTypes = {};

export default React.memo(PureRentTutorial);

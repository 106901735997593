import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { animated, useTransition } from 'react-spring';
import BezierEasing from 'bezier-easing';
import { useDebouncedCallback } from 'use-debounce';
import Card from 'Components/atoms/Card';
import Group from './ItemCardGroup';
import approveIcon from '~/images/icons/approve-button-complete.svg';

const iconEasing = BezierEasing(0, 0, 0.2, 1);

// TODO: change svg to component and fix the animation
const Container = styled.div`
  width: 100%;
  user-select: none;
  cursor: ${({ clickable, disabled }) =>
    clickable ? (disabled ? 'not-allowed' : 'pointer') : 'default'};
  position: relative;
`;

const Title = styled.h4`
  ${themeGet('fonts.h4')}
  margin: 0;
  margin-bottom: 10px;
`;

const ContentWrapper = styled(Card)`
  ${themeGet('boxShadows.light')}
  min-height: 86px;
  display: flex;
  align-items: center;
  transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  ${({ disabled }) =>
    disabled &&
    css`
      background: ${themeGet('colors.typeSpaceGrey')};
      border-color: transparent;
      box-shadow: none;
    `};
  ${({ selected }) =>
    selected &&
    css`
      border-color: ${themeGet('colors.mainBlue')};
    `};
  ${space};
`;

const ContentPanel = styled.div`
  ${themeGet('fonts.body')}
  color: ${({ disabled }) => disabled && themeGet('colors.secondaryGrey')};
  width: 100%;
  user-select: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({ hazy }) =>
    hazy &&
    css`
      opacity: 1;
      /* opacity: 0.5; */
    `};
`;

const SelectedIcon = styled(animated.img)`
  position: absolute;
  top: -8px;
  left: -8px;
  z-index: 1;
`;

export const PureItemCard = props => {
  const {
    children,
    itemIndex,
    title,
    selected,
    visibleSelected,
    onSelect,
    unselectedHazy,
    disabled,
  } = props;
  const clickable = useMemo(() => !!onSelect, [onSelect]);
  const iconTransition = useTransition(selected && visibleSelected, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 200,
      easing: iconEasing,
    },
  });

  const [handleOnClick] = useDebouncedCallback(
    () => {
      if (onSelect && !disabled) {
        onSelect(!selected, itemIndex);
      }
    },
    300,
    { leading: true, trailing: false }
  );

  return (
    <Container clickable={clickable} disabled={disabled}>
      {title && (typeof title === 'string' ? <Title>{title}</Title> : title)}
      <ContentWrapper
        padding={['10px', '20px']}
        disabled={disabled}
        selected={selected && visibleSelected}
        onClick={handleOnClick}
      >
        <ContentPanel disabled={disabled} hazy={unselectedHazy && !selected && !disabled}>
          {children}
        </ContentPanel>
        {iconTransition.map(
          ({ item, key, props }) =>
            item && <SelectedIcon key={key} style={props} src={approveIcon} />
        )}
      </ContentWrapper>
    </Container>
  );
};

PureItemCard.defaultProps = {
  selected: false,
  visibleSelected: true,
  unselectedHazy: false,
  disabled: false,
};

PureItemCard.propTypes = {
  itemIndex: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  selected: PropTypes.bool,
  visibleSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  unselectedHazy: PropTypes.bool,
  disabled: PropTypes.bool,
};

const ItemCard = React.memo(PureItemCard);
ItemCard.Group = Group;

/**
 * @component
 */
export default ItemCard;

import LineAxios from './LineAxios';

LineAxios.create('hermes');
const axios = LineAxios.getInstance('hermes').useToken();

// 取得所有停車場資訊， 語系:[zh-tw, en]，注意不是"_"是＂-＂！
export const getParkingLots = async position => {
  return axios.get('/v13/parkingLots', { params: { ...position } }).then(({ data }) => data.data);
};

// 取得指定停車場資訊
export const getParkingLotById = async parkingLotId => {
  return axios.get(`/v13/parkingLots/${parkingLotId}`).then(({ data }) => data.data);
};

/* Report whether scooter is in/around/out of parking lot, related parking lot id and also related parking boxes status will also be returned

Criteria of checking isInParkingLot and isAroundParkingLot:

Check with the providing scooterId. If that scooter has active parkingHistory, we say that it's in parking lot

If scooter is not in parking lot, check with the providing latitude and longitude. If the location is around parking lot (100m from borders of parking lot), we say that it's around parking lot*/
// lat, lng, scooterId
export const getParkingInfoByScooterId = async params => {
  return axios.get(`/v13/parkingLots/parkingInfo`, { params }).then(({ data }) => data.data);
};

// 設定停車格位置
//   scooterId,parkingSpaceId
export const setParkingSpace = async (parkingLotId, data) => {
  return axios.post(`/v13/parkingLots/${parkingLotId}/parkingSpaces`, data);
};

// Open 'in' gate of parking lot
//   scooterId,parkingSpaceId
export const openParkingLotInGate = async (parkingLotId, scooterId) => {
  return axios
    .post(`/v13/parkingLots/${parkingLotId}/openInGate`, { scooterId })
    .then(({ data }) => data);
};

// Open 'out' gate of parking lot
//   scooterId,parkingSpaceId
export const openParkingLotOutGate = async (parkingLotId, scooterId) => {
  return axios
    .post(`/v13/parkingLots/${parkingLotId}/openOutGate`, { scooterId })
    .then(({ data }) => data);
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as ParkingLotIcon } from 'Images/icons/parking-lot-pin.svg';
import { ReactComponent as ParkingLotClosedIcon } from 'Images/icons/parking-lot-closed-pin.svg';
import { ReactComponent as automaticIcon } from 'Images/icons/parking-lot-automatic.svg';
import { ReactComponent as gatelessIcon } from 'Images/icons/parking-lot-gateless.svg';
import { ReactComponent as parkingBoxIcon } from 'Images/icons/parking-lot-parkingBox.svg';
import { ReactComponent as WarningIcon } from 'Images/icons/warning.svg';
import { parkingLotOpenTypes } from '~/configs';

const Container = styled.div`
  position: relative;
  z-index: 2;
`;

const Icon = styled.img``;

const Badge = styled.div`
  font-size: 12px;
  color: #0078c9;
  border: 2px solid #0078c9;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background: #fff;
  position: absolute;
  top: -8px;
  right: -8px;
  text-align: center;
  box-sizing: border-box;
  line-height: 18px;
`;

const ErrorIcon = styled.svg`
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
`;

const iconMap = {
  etag: automaticIcon,
  automatic: automaticIcon,
  parkingBox: parkingBoxIcon,
  gateless: gatelessIcon,
};

export const PureParkingLotPin = props => {
  const { amount, onClick, type, showType, hasError, isClosed } = props;

  return (
    <Container onClick={onClick}>
      {hasError ? (
        <ErrorIcon as={WarningIcon} />
      ) : (
        type !== parkingLotOpenTypes.gateless &&
        !isClosed && <Badge>{amount > 99 ? 99 : amount}</Badge>
      )}
      {isClosed ? (
        <Icon as={ParkingLotClosedIcon} />
      ) : (
        <Icon as={showType ? iconMap[type] || ParkingLotIcon : ParkingLotIcon} />
      )}
    </Container>
  );
};

PureParkingLotPin.defaultProps = {
  amount: 0,
  onClick: () => {},
};

PureParkingLotPin.propTypes = {
  amount: PropTypes.number,
  onClick: PropTypes.func,
  hasError: PropTypes.bool,
  type: PropTypes.oneOf(['parkingBox', 'gateless', 'automatic','etag']),
};

export default React.memo(PureParkingLotPin);

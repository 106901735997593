import React, { useCallback, useState, useRef, useMemo, useContext } from 'react';
import qs from 'qs';
import { useSnackbar } from '~/context/Snackbars';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  rentScooter,
  getParkingLots,
  returnScooter,
  checkReturnAvailable,
  checkExceptionReturnAvailable,
  cancelReserveScooter,
  reserveScooter,
  powerOnScooter,
  openScooterTrunk,
  getRouteToScooter,
  powerOffScooter,
  flashScooter,
  postPaymentsLineNotify,
  getCurrentRentingStatus,
  setParkingSpace,
  getParkingLotById,
  getParkingInfoByScooterId,
  getAppReturnInfo,
} from '~/services/api';
import useLoadingGroup from '~/hooks/useLoadingGroup';
import { userEvents, rentStatus, scooterStatusMap, scooterStatus } from '~/configs/';
import { useErrorNotification } from '~/context/ErrorNotification';
import requestErrors from '~/configs/requestErrors';
import { RentingContext } from '../providers/RentingProvider';
import { underlineToCamelCase } from '~/utils/stringConverter';
import { useStatus } from '~/context';
import { logRentScooterEvent, logReturnScooterEvent } from '~/utils/firebaseService';
import ExpiredStorage from 'expired-storage';

const expiredStorage = new ExpiredStorage();

const mengoPlan = 'mengoPlan';
const mengoLatitude = 22.9;

const Input = styled.input`
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.typeSpaceGrey};
  margin: 10px 0 4px;
  outline: none;
  text-align: center;
  ${props => props.theme.fonts.body};
  user-select: auto;
`;
/**
 * 異常還車(exception return)規則：
 * 1. 打控制車子的 API 時，遇到 'TIME_OUT'，則會記下一筆 count = 1、時間
 * 2. 下一次遇到 'TIME_OUT' 時，若離上次紀錄值少於一分鐘，則 count +1，記下時間，否則 count = 1
 * 3. 若遇到 count === 3 ，即進入異常還車流程，跳出 popup 提示使用者
 * 4. 還車時也要確認在營運區才可還
 */

// 寫控制車有關的事情： reserve / cancel / rent / flash / trunk / return
export const useRenting = () => {
  const { setPaymentInfo } = useStatus();
  const [renting, dispatch] = useContext(RentingContext);
  const scooter = renting.scooter;

  const history = useHistory();
  const { registerErrorConfig, launchError, launchApiError } = useErrorNotification();
  const loadingUtils = useLoadingGroup({
    rent: false,
    reserve: false,
    stop: false,
    trunk: false,
    cancel: false,
    check: false,
    return: false,
    flash: false,
    start: false,
    scooters: false,
    route: false,
    exception: false,
    parkingLot: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const parkingSpaceIdRef = useRef();
  const [parkingSpaceId, setParkingSpaceId] = useState();

  const handleInputOnchange = evt => {
    const value = evt.target.value;
    setParkingSpaceId(value);
    parkingSpaceIdRef.current = evt.target.value;
  };

  const updateUserMode = useCallback(mode => {
    dispatch({ type: 'setRenting', payload: { userMode: mode } });
  }, []);

  const setUserLocation = useCallback(location => {
    dispatch({ type: 'setRenting', payload: { location } });
  }, []);

  const resetScooter = useCallback(() => {
    dispatch({ type: 'resetScooter' });
  }, []);

  const resetMapParkingLot = useCallback(() => {
    dispatch({ type: 'resetMapParkingLot' });
  }, []);

  const resetExceptionReturn = useCallback(() => {
    dispatch({ type: 'resetExceptionReturn' });
  }, []);

  const resetRenting = useCallback(() => {
    dispatch({ type: 'resetRenting' });
  }, []);

  const setScooter = useCallback(payload => {
    dispatch({ type: 'setScooter', payload });
  }, []);

  const setMapParkingLot = useCallback(payload => {
    dispatch({ type: 'setMapParkingLot', payload });
  }, []);

  const initializeRenting = useCallback(async () => {
    try {
      let rawAppReturnInfo = await getAppReturnInfo();

      const { appReturnInfo, userInfo } = underlineToCamelCase(rawAppReturnInfo);
      setPaymentInfo({ paymentErr: userInfo.paymentErr, unpaidAmount: userInfo.unpaidAmount });

      if (appReturnInfo) {
        await updateRentingStatus({ appReturnInfo });
      }
    } catch (error) {}
  }, []);

  const updateRentingStatus = useCallback(
    async ({ appReturnInfo, reserveInfo } = {}) => {
      try {
        const rawRentingStatus = await getCurrentRentingStatus();
        const rentingStatus = underlineToCamelCase(rawRentingStatus);
        const { power, reserveTime, result, ...restStatus } = rentingStatus;

        if (result === 'success') {
          if (appReturnInfo) {
            const {
              appReturnLocation,
              rentId,
              time: leftReservationTime,
              ...restAppReturnInfo
            } = appReturnInfo;

            dispatch({
              type: 'setRenting',
              payload: {
                userMode: appReturnLocation,
                scooterMode: scooterStatusMap[power],
                rentId,
                scooter: {
                  ...scooter,
                  sinceTime: Date.now(),
                  ...restStatus,
                  ...restAppReturnInfo,
                  leftReservationTime: parseInt(leftReservationTime, 10),
                },
              },
            });
          } else if (reserveInfo) {
            dispatch({
              type: 'setRenting',
              payload: {
                sinceTime: Date.now(),
                userMode: rentStatus.routeToScooter,
                rentId: reserveInfo.rentId,
                scooter: {
                  ...scooter,
                  scooterId: reserveInfo.scooterId,
                  ...restStatus,
                  leftReservationTime: 600,
                },
              },
            });
          }
        }
      } catch (error) {}
    },
    [dispatch, scooter]
  );

  const setMapAllParkingLots = useCallback(
    payload => {
      dispatch({ type: 'setMapAllParkingLots', payload });
    },
    [dispatch]
  );

  const resetMapAllParkingLots = useCallback(() => {
    dispatch({ type: 'resetMapAllParkingLots' });
  }, [dispatch]);

  const getNearByParkingLots = useCallback(
    async location => {
      if (!location) return;
      try {
        const parkingLots = await getParkingLots(location);
        setMapAllParkingLots(parkingLots);
      } catch (error) {
        launchApiError(error, 'scooters');
        console.error(error);
      }
    },
    [launchApiError, setMapAllParkingLots]
  );

  const resetParkingLot = () => {
    dispatch({ type: 'resetParkingLot' });
  };

  const finishReturnProcess = useCallback(
    async rent => {
      const {
        scooterId,
        orderId,
        rentId,
        amount,
        totalRideMinutes,
        rentAt,
        returnAt,
        distances,
        isRentTimeTooShort,
      } = rent;
      const urlQuery = qs.parse(window.location.search.slice(1));

      if (rentId && !isRentTimeTooShort) {
        await postPaymentsLineNotify(rentId).catch(console.log);
      }

      dispatch({ type: 'updateRenting', payload: { userMode: rentStatus.confirming } });
      resetScooter();
      resetParkingLot();
      history.replace({
        pathname: '/ride-detail',
        search: `?${qs.stringify({
          scooterId,
          orderId,
          rentId,
          amount,
          isRentTimeTooShort,
          totalRideMinutes,
          rentAt,
          returnAt,
          distances,
          isExceptionReturn: urlQuery.isExceptionReturn || false,
        })}`,
      });
    },
    [dispatch, history, resetScooter]
  );

  const updateUserEvents = useCallback(
    (type, rentId) => {
      const userEventsData = expiredStorage.getJson('userEventsData');
      if (rentId === userEventsData?.rentId) {
        expiredStorage.setJson('userEventsData', {
          ...userEventsData,
          userEvents: [
            ...userEventsData.userEvents,
            {
              lat: renting.location.lat,
              lng: renting.location.lng,
              dt: new Date().toISOString(),
              type,
            },
          ],
        });
      } else {
        expiredStorage.setJson('userEventsData', {
          rentId,
          userEvents: [
            {
              lat: renting.location.lat,
              lng: renting.location.lng,
              dt: new Date().toISOString(),
              type,
            },
          ],
        });
      }
    },
    [renting.location]
  );

  const updateMapParkingLot = async () => {
    if (!renting.mapParkingLot.id) return;
    try {
      const parkingLot = await getParkingLotById(renting.mapParkingLot.id);
      renting.mapAllParkingLots?.forEach(({ id, state }) => {
        if (id === parkingLot.id && state.key !== parkingLot.state.key) {
          getNearByParkingLots(renting.location);
        }
      });
      setMapParkingLot(parkingLot);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReserveScooter = async () => {
    /**
     * NOTE: 預約時傳預設資費與方案 => 影響 UserInfoV3 appReturnInfo.pricing 裡面的 isDefault 是否為 true
     */
    try {
      loadingUtils.start('reserve');
      const { pricing: { pricingPlans = [], timePlans = [] } = {} } = scooter;
      const pricingPlanId = (pricingPlans.find(pricingPlan => pricingPlan.isDefault) || {}).id;
      const timePlanId = (timePlans.find(timePlan => timePlan.isDefault) || {}).id;
      const reserveInfo = await reserveScooter({
        scooterId: scooter.scooterId,
        pricingPlanId,
        timePlanId,
      });
      updateUserEvents(userEvents.reserve, reserveInfo.rentId);
      await updateRentingStatus({ reserveInfo });
      resetMapParkingLot();
      loadingUtils.end('reserve');
    } catch (error) {
      loadingUtils.end('reserve', 'fail');
      launchApiError(error, 'useRenting');
      console.log(error);
    }
  };

  const handleRouteToScooter = useCallback(async () => {
    try {
      if (
        !!renting.directions &&
        !renting.location?.lat &&
        !renting.location?.lng &&
        !scooter.lat &&
        !scooter.lng
      )
        return;

      loadingUtils.start('route');
      const rawDirections = await getRouteToScooter(renting.location, {
        lat: scooter.lat,
        lng: scooter.lng,
      });
      loadingUtils.end('route');

      if (rawDirections?.status === 'OK' && window.google) {
        const polyline = window.google.maps.geometry.encoding.decodePath(
          rawDirections.routes[0].overview_polyline.points
        );
        dispatch({ type: 'setDirections', payload: polyline });
      }
    } catch (error) {
      loadingUtils.end('route', 'fail');
      // launchApiError(error, 'useRenting');
      console.log(error);
    }
  }, [dispatch, loadingUtils, renting.directions, renting.location, scooter.lat, scooter.lng]);

  const resetDirections = useCallback(() => {
    dispatch({ type: 'setDirections', payload: null });
  }, [dispatch]);

  const updateParkingGateStatus = useCallback(async () => {
    if (
      !renting.userMode === rentStatus.renting ||
      (!renting.location.lat && !renting.location.lat)
    )
      return;

    try {
      loadingUtils.start('parkingLot');
      const info = await getParkingInfoByScooterId({
        scooterId: scooter.scooterId,
        ...renting.location,
      });

      const parkingLot = info.parkingLotId ? await getParkingLotById(info.parkingLotId) : {};
      renting.mapAllParkingLots?.forEach(({ id, state }) => {
        if (id === parkingLot.id && state.key !== parkingLot.state.key) {
          getNearByParkingLots(renting.location);
        }
      });
      dispatch({
        type: 'setParkingLot',
        payload: {
          ...parkingLot,
          info,
        },
      });
      loadingUtils.end('parkingLot');
    } catch (error) {
      loadingUtils.end('parkingLot', 'fail');
      throw error;
    }
  }, [renting.userMode, renting.location, loadingUtils, scooter.scooterId, dispatch]);

  /**
   * replacingPricingPlanId: undefined => 預設 pricing & time plan
   * replacingPricingPlanId null => 僅使用 pricing plan
   * （mengo 沒點數的 / 找不到 server 時 timePlanId 預設仍為 （mengo）
   *
   * 點選高雄車輛才可以使用MenGo方案
   * 點選高雄以外車輛無法使用MenGo方案，綁定者可以看到方案，但無法選擇，並以「此方案僅適用於高雄地區」文字加註
   * 判斷高雄車輛的方式使用緯度「22.9」以南的方式進行判斷
   */
  const handleRentScooter = useCallback(
    async replacingPricingPlanId => {
      const { pricing: { pricingPlans = [], timePlans = [] } = {} } = scooter;
      const pricingPlanId =
        replacingPricingPlanId !== undefined && replacingPricingPlanId !== null
          ? replacingPricingPlanId
          : (pricingPlans.find(pricingPlan => pricingPlan.isDefault) || {}).id;
      const timePlan =
        replacingPricingPlanId !== undefined
          ? {}
          : timePlans.find(timePlan => timePlan.isDefault) || {};

      if (timePlan?.type === mengoPlan && parseInt(scooter.lat, 10) >= mengoLatitude) {
        launchError({ type: 'NOT_MENGO_SCOOTER' }, 'useRenting');
        return;
      }

      try {
        loadingUtils.start('rent');
        await rentScooter(renting.rentId, {
          pricingPlanId,
          timePlanId: timePlan?.id,
        });
        logRentScooterEvent({ rentId: renting.rentId });
        updateUserMode(rentStatus.renting);
        dispatch({ type: 'setScooter', payload: { sinceTime: Date.now() } });
        updateUserEvents(userEvents.rent, renting.rentId);
        updateParkingGateStatus();
        loadingUtils.end('rent');
      } catch (error) {
        loadingUtils.end('rent', 'fail');
        launchApiError(error, 'useRenting');
        // await updateCurrentStatus();
        console.log(error);
      }
    },
    [
      scooter,
      launchError,
      loadingUtils,
      renting.rentId,
      updateParkingGateStatus,
      renting.parkingLot,
      updateUserMode,
      dispatch,
      updateUserEvents,
      launchApiError,
    ]
  );

  const handleCancelReserveScooter = useCallback(async () => {
    try {
      loadingUtils.start('cancel');
      await cancelReserveScooter(renting.rentId);
      // updateUserEvents(userEvents.cancel);
      // await updateRentingStatus();
      resetRenting();
      // updateUserMode(rentStatus.notRenting);
      loadingUtils.end('cancel');
    } catch (error) {
      launchApiError(error, 'useRenting');
      loadingUtils.end('cancel', 'fail');
      console.log(error);
    }
  }, []);

  const returnValidScooter = useCallback(async () => {
    try {
      loadingUtils.start('return');

      updateUserEvents(userEvents.return, renting.rentId);
      const data = { userEvents: expiredStorage.getJson('userEventsData')?.userEvents || [] };

      const rent = await returnScooter(renting.rentId, data);
      logReturnScooterEvent({ rentId: renting.rentId });
      const { scooterId, parkingLotId } = rent;
      loadingUtils.end('return');

      if (parkingLotId !== undefined && parkingLotId !== null) {
        launchError({ type: 'FILL_PARKING_SPACE', parkingLotId, scooterId, rent }, 'useRenting');
      } else {
        finishReturnProcess(rent);
      }
    } catch (error) {
      console.log(error);
      loadingUtils.end('return', 'fail');
      launchApiError(error, 'useRenting');
    }
  }, [
    loadingUtils,
    updateUserEvents,
    renting.rentId,
    launchError,
    finishReturnProcess,
    launchApiError,
  ]);

  const handleCheckExceptionReturnAvailable = useCallback(async () => {
    try {
      loadingUtils.start('check');
      const returnChecking = await checkExceptionReturnAvailable(
        scooter.scooterId,
        renting.location
      );
      loadingUtils.end('check');

      if (['FORBIDDEN', 'WARNING', 'FORBIDDEN_RETURN'].includes(returnChecking.type)) {
        debugger;
        if (!returnChecking.canReturn) {
          launchError({ type: 'INVALID_PARKING_PLACE', data: returnChecking }, 'useRenting');
          return false;
        }
      }
      return true;
    } catch (error) {
      loadingUtils.end('check', 'fail');
      launchApiError(error, 'useRenting');
    }
  }, [scooter.scooterId]);

  const handleReturnScooter = useCallback(async () => {
    /**
     * TODO: 例外還車 returnScooter 這樣寫會吃屎
     */
    try {
      loadingUtils.start('check');
      const returnChecking = await checkReturnAvailable(scooter.scooterId);
      // const returnChecking = {
      //   type: 'FORBIDDEN',
      //   title: '非營運範圍',
      //   description: '此處為營運範圍外，禁止還車，請將機車停入營運範圍內',
      //   shapeName: '非營運範圍（文山試院路）',
      //   canReturn: true,
      // };
      loadingUtils.end('check');
      if (['FORBIDDEN', 'WARNING', 'FORBIDDEN_RETURN'].includes(returnChecking.type)) {
        if (returnChecking.canReturn) {
          launchError({ type: 'WARNING_PARKING_PLACE', data: returnChecking }, 'useRenting');
          return;
        }
        launchError({ type: 'INVALID_PARKING_PLACE', data: returnChecking }, 'useRenting');
      } else if (returnChecking.canReturn) {
        await returnValidScooter();
        return;
      } else {
        launchError();
      }
    } catch (error) {
      loadingUtils.end('check', 'fail');
      launchApiError(error, 'useRenting');
    }
  }, [launchApiError, launchError, loadingUtils, returnValidScooter, scooter.scooterId]);

  const handleSetParkingSpace = useCallback(
    async (parkingLotId, scooterId, rent) => {
      try {
        parkingSpaceIdRef.current &&
          (await setParkingSpace(parkingLotId, {
            scooterId,
            parkingSpaceId: parkingSpaceIdRef.current,
          }));
      } catch (error) {}

      finishReturnProcess(rent);
    },
    [finishReturnProcess]
  );

  const handleFlashScooter = useCallback(async () => {
    try {
      loadingUtils.start('flash');
      const result = await flashScooter();
      if (result.error === 'timeOut') {
        launchError({ type: requestErrors.TIMEOUT }, 'useRenting');
      } else if (result.result === 'failed' || result.result === 'Box not Exist') {
        launchError({ type: 'CONTROL_FAILED' }, 'useRenting');
      } else {
        updateUserEvents(userEvents.flash, renting.rentId);
        enqueueSnackbar({ message: '機車車燈閃爍中', preventDuplicate: true, duration: 2000 });
      } //NOTE 目前無特別處理 dequeue snackbar，故設兩秒改善下一動作殘存問題
      loadingUtils.end('flash');
      return true;
    } catch (error) {
      loadingUtils.end('flash', 'fail');
      launchApiError(error, 'useRenting');
      return false;
    }
  }, [updateUserEvents]);

  const handleStart = async () => {
    try {
      loadingUtils.start('start');
      const result = await powerOnScooter();
      if (result.error === 'timeOut') {
        launchError({ type: requestErrors.TIMEOUT }, 'useRenting');
      } else if (result.result === 'failed' || result.result === 'Box not Exist') {
        launchError({ type: 'CONTROL_FAILED' }, 'useRenting');
      } else {
        dispatch({ type: 'updateScooterMode', scooterMode: scooterStatus.start });
        updateUserEvents(userEvents.keyOn, renting.rentId);
      }
      loadingUtils.end('start');
    } catch (error) {
      launchApiError(error, 'useRenting');
      loadingUtils.end('start', 'fail');
      console.log(error);
    }
  };

  const handleOpenTrunk = useCallback(async () => {
    try {
      loadingUtils.start('trunk');
      const result = await openScooterTrunk(scooter.scooterId);
      if (result.error === 'timeOut') {
        launchError({ type: requestErrors.TIMEOUT }, 'useRenting');
      } else if (result.result === 'failed' || result.result === 'Box not Exist') {
        launchError({ type: 'CONTROL_FAILED' }, 'useRenting');
      } else {
        updateUserEvents(userEvents.openTrunk, renting.rentId);
      }
      loadingUtils.end('trunk');
    } catch (error) {
      loadingUtils.end('trunk', 'fail');
      launchApiError(error, 'useRenting');
      console.log(error);
    }
  }, [scooter.scooterId, updateUserEvents]);

  const handleStop = useCallback(async () => {
    try {
      loadingUtils.start('stop');
      const result = await powerOffScooter();
      if (result.error === 'timeOut') {
        launchError({ type: requestErrors.TIMEOUT }, 'useRenting');
      } else if (result.result === 'failed' || result.result === 'Box not Exist') {
        launchError({ type: 'CONTROL_FAILED' }, 'useRenting');
      } else {
        dispatch({ type: 'updateScooterMode', scooterMode: scooterStatus.stop });
        updateUserEvents(userEvents.keyOff, renting.rentId);
      }
      loadingUtils.end('stop');
    } catch (error) {
      launchApiError(error, 'useRenting');
      loadingUtils.end('stop', 'fail');
      console.log(error);
    }
  }, [updateUserEvents]);

  useMemo(
    () =>
      registerErrorConfig('useRenting', {
        /* reserve | rent | cancel reservation */
        [requestErrors.RENT_NOT_FOUND]: {
          popup: {
            title: '您目前沒有租借機車',
            confirmButtonText: '確認',
            notes: `如欲繼續使用，請重新租借。`,
            onConfirmClick: initializeRenting,
          },
          notifier: 'popup',
        },
        [requestErrors.RENT_USER_RESERVATION_IS_CONFLICT]: {
          snackbar: {
            message: '已預約其他車輛',
            onEnter: initializeRenting,
          },
          notifier: 'snackbar',
        },
        [requestErrors.RENT_SCOOTER_IS_CONFLICT]: {
          snackbar: {
            message: '該車已被預約，請預約其他車輛',
            onEnter: initializeRenting,
          },
          notifier: 'snackbar',
        },
        [requestErrors.RENT_FALLBACK_MENGO_POINT_NOT_ENOUGH]: error => ({
          popup: {
            title: 'MeNGo 點數不足',
            notes: '您的 MeNGo 點數不足，系統將不使用時間方案進行租車',
            confirmButtonText: '租車',
            concelButtonText: '取消',
            onConfirmClick: () => handleRentScooter(error?.data?.replacingPricingPlan?.id || null),
            type: 'warning',
            hasCancelButton: true,
          },
          notifier: 'popup',
        }),
        [requestErrors.RENT_FALLBACK_MENGO_SERVICE_UNAVAILABLE]: error => ({
          popup: {
            title: 'MeNGo Server 無回應',
            notes: '無法偵測您的 MeNGo 點數，系統將不使用時間方案進行租車',
            confirmButtonText: '租車',
            concelButtonText: '取消',
            onConfirmClick: () => handleRentScooter(error?.data?.replacingPricingPlan?.id || null),
            type: 'warning',
            hasCancelButton: true,
          },
          notifier: 'popup',
        }),
        NOT_MENGO_SCOOTER: {
          popup: {
            title: '無法使用 MeNGo 時間方案',
            notes: '您所租用的車輛位置無法使用預設 MeNGo 時間方案，系統將不使用時間方案進行租車',
            confirmButtonText: '租車',
            concelButtonText: '取消',
            onConfirmClick: () => handleRentScooter(null),
            type: 'warning',
            hasCancelButton: true,
          },
          notifier: 'popup',
        },
        [requestErrors.RENT_FALLBACK]: error => ({
          popup: {
            title: '方案已過期',
            confirmButtonText: '租車',
            concelButtonText: '取消',
            notes: `您的${error.data?.originalTimePlan?.name ||
              error.data?.originalPricingPlan.name}已過期，系統將使用${
              error?.data?.replacingPricingPlan?.name
            }為租車的計價方式`,
            onConfirmClick: () => handleRentScooter(error?.data?.replacingPricingPlan?.id),
            hasCancelButton: true,
          },
          notifier: 'popup',
        }),
        [requestErrors.SCOOTER_NOT_IN_ZONE]: error => ({
          popup: {
            title: '請確認您的車輛停放位置',
            confirmButtonText: '確認',
            type: 'warning',
            notes: `您目前在營運範圍外\n無法還車。`,
          },
          notifier: 'popup',
        }),
        [requestErrors.USER_PAYMENT_ERROR]: error => ({
          snackbar: {
            message: '餘額不足，請儲值或變更付款方式',
          },
          notifier: 'snackbar',
        }),
        [requestErrors.PARKING_LOT_NON_RESERVABLE]: {
          popup: {
            title: '停車場異常',
            confirmButtonText: '確認',
            type: 'warning',
            notes: `系統維護中無法租車`,
            onConfirmClick: updateMapParkingLot,
          },
          notifier: 'popup',
        },
        [requestErrors.PARKING_LOT_NON_RETURNABLE]: {
          popup: {
            title: '停車場異常',
            confirmButtonText: '確認',
            type: 'warning',
            notes: `系統維護中，無法還車`,
            onConfirmClick: updateMapParkingLot,
          },
          notifier: 'popup',
        },
        [requestErrors.PARKING_LOT_OUT_OF_BUSINESS_HOUR]: {
          popup: {
            title: '休息中',
            confirmButtonText: '確認',
            notes: `休息中無法租車`,
            onConfirmClick: updateMapParkingLot,
          },
          notifier: 'popup',
        },
        // return
        INVALID_PARKING_PLACE: error => {
          return {
            popup: {
              title: error?.data.title,
              notes: error?.data.description,
            },
            notifier: 'popup',
          };
        },
        [requestErrors.TIMEOUT]: error => {
          const now = Date.now();

          if (renting.exceptionReturn.lastTime && now - renting.exceptionReturn.lastTime < 60000) {
            dispatch({
              type: 'setExceptionReturn',
              payload: { count: renting.exceptionReturn.count + 1, lastTime: now },
            });
          } else {
            dispatch({
              type: 'setExceptionReturn',
              payload: { count: 1, lastTime: now },
            });
          }
          return {
            snackbar: {
              message: '連線異常，請稍後再試',
            },
            notifier: 'snackbar',
          };
        },
        WARNING_PARKING_PLACE: error => {
          return {
            popup: {
              title: error?.data.title,
              notes: error?.data.description,
              confirmButtonText: '還車',
              onConfirmClick: () => returnValidScooter(error.setPopupSetting),
              hasCancelButton: true,
            },
            notifier: 'popup',
          };
        },
        // TODO: 把 onConfirmClick 移出去，然後繼續做本來 return 後要做的事情
        FILL_PARKING_SPACE: data => {
          return {
            popup: {
              title: '停車格號碼',
              notes: `請輸入還車時的停車格號碼\n以方便下一位使用者租借`,
              contentComponent: <Input onChange={handleInputOnchange} value={parkingSpaceId} />,
              onConfirmClick: () =>
                handleSetParkingSpace(data.parkingLotId, data.scooterId, data.rent),
              onCancelClick: () => finishReturnProcess(data.rent),
              confirmButtonText: '送出',
              hasCancelButton: true,
            },
            notifier: 'popup',
          };
        },
        CONTROL_FAILED: {
          snackbar: {
            message: '操作失敗',
          },
          notifier: 'snackbar',
        },
      }),
    [
      renting.exceptionReturn.lastTime,
      renting.exceptionReturn.count,
      parkingSpaceId,
      registerErrorConfig,
      initializeRenting,
      handleRentScooter,
      returnValidScooter,
      finishReturnProcess,
      handleSetParkingSpace,
    ]
  );

  return {
    initializeRenting,
    setUserLocation,
    setScooter,
    resetParkingLot,
    setMapParkingLot,
    resetScooter,
    resetMapParkingLot,
    resetExceptionReturn,
    resetRenting,
    resetDirections,
    updateUserMode,
    handleRentScooter,
    updateUserEvents,
    updateMapParkingLot,
    handleReserveScooter,
    handleCancelReserveScooter,
    handleRouteToScooter,
    handleFlashScooter,
    handleReturnScooter,
    handleStart,
    handleOpenTrunk,
    updateParkingGateStatus,
    handleCheckExceptionReturnAvailable,
    handleStop,
    loadingUtils,
    getNearByParkingLots,
    renting,
    updateRentingStatus,
    scooter: renting.scooter,
    parkingLot: renting.parkingLot,
    mapParkingLot: renting.mapParkingLot,
  };
};

// const PopupNoteInput = popupNoteProps => {
//   const [input, setInput] = useState();
//   const handleInputOnchange = evt => {
//     setInput(evt.target.value);
//   };

//   return (
//     <PopupNote contentComponent={<Input onChange={handleInputOnchange} />} {...popupNoteProps} />
//   );
// };

const routeResponse = {
  result: 'success',
  data: {
    geocoded_waypoints: [
      { geocoder_status: 'OK', place_id: 'ChIJg72oduerQjQRlcMskyOHG7A', types: ['street_address'] },
      { geocoder_status: 'OK', place_id: 'ChIJ2zX3m-erQjQRu7zcYiLJYXY', types: ['street_address'] },
    ],
    routes: [
      {
        bounds: {
          northeast: { lat: 25.0531773, lng: 121.5460794 },
          southwest: { lat: 25.0528848, lng: 121.545274 },
        },
        copyrights: 'Map data ©2020 Google',
        legs: [
          {
            distance: { text: '88 m', value: 88 },
            duration: { text: '1 min', value: 65 },
            end_address:
              'No. 16, Alley 7, Lane 303, Section 3, Nanjing East Road, Songshan District, Taipei City, Taiwan 105',
            end_location: { lat: 25.0529226, lng: 121.5460794 },
            start_address: 'No. 14, Qingcheng Street, Songshan District, Taipei City, Taiwan 105',
            start_location: { lat: 25.0531773, lng: 121.545274 },
            steps: [
              {
                distance: { text: '59 m', value: 59 },
                duration: { text: '1 min', value: 42 },
                end_location: { lat: 25.0529537, lng: 121.5458555 },
                html_instructions: 'Head <b>southeast</b> on <b>慶城街16巷</b>',
                polyline: { points: 'ke|wC}hzdVh@cADGBI@E@IBIME' },
                start_location: { lat: 25.0531773, lng: 121.545274 },
                travel_mode: 'WALKING',
              },
              {
                distance: { text: '29 m', value: 29 },
                duration: { text: '1 min', value: 23 },
                end_location: { lat: 25.0529226, lng: 121.5460794 },
                html_instructions: 'Continue onto <b>南京東路三段303巷7弄</b>',
                polyline: { points: '}c|wCslzdV@E@M?M@I' },
                start_location: { lat: 25.0529537, lng: 121.5458555 },
                travel_mode: 'WALKING',
              },
            ],
            traffic_speed_entry: [],
            via_waypoint: [],
          },
        ],
        overview_polyline: { points: 'ke|wC}hzdVt@{ADSME@E@[@I' },
        summary: '慶城街16巷 and 南京東路三段303巷7弄',
        warnings: [
          'Walking directions are in beta. Use caution – This route may be missing sidewalks or pedestrian paths.',
        ],
        waypoint_order: [],
      },
    ],
    status: 'OK',
  },
};

import compressor from 'browser-image-compression';

const defaultConfig = {
  maxSizeMB: 0.5,
  useWebWorker: !!window.Worker,
};

const photosFilter = photos => {
  return (Array.isArray(photos) ? photos : [photos]).filter((photo, index) => {
    if (!(photo instanceof Blob || photo instanceof File)) {
      console.warn(`Arguments[${index}] isn't Blob or File instance.`);
      return false;
    }
    return true;
  });
};

export default () => {
  let isWorking = false;
  const queue = [];

  async function pushCompressQueue(job, callback) {
    queue.push({ job, callback });
  }

  async function delegateCompressWorker() {
    if (isWorking) return;
    isWorking = true;
    while (queue.length > 0) {
      const { job, callback } = queue.shift();
      try {
        const result = await job();
        callback(result);
      } catch (error) {
        callback(null, error);
        throw error;
      }
    }
    isWorking = false;
  }

  function createCompressJob(photos, onProgress) {
    return async () => {
      const jobs = [];
      const total = photos.length;
      let count = new Array(total).fill(0);

      const handleProgress = index => progress => {
        count[index] = progress;
        if (typeof onProgress === 'function') {
          onProgress(Math.floor(count.reduce((acc, cur) => acc + cur) / total));
        }
      };

      for (const index in photos) {
        const photo = photos[index];
        jobs.push(
          compressor(photo, {
            ...defaultConfig,
            onProgress: handleProgress(index),
          })
        );
      }
      const results = await Promise.all(jobs);

      // if (jobs.length > 1) {
        return results;
      // } 
      // else {
      //   return results[0];
      // }
    };
  }

  async function compress(photos, onProgress) {
    photos = photosFilter(photos);
    if (photos.length < 1) return;

    return new Promise((resolve, reject) => {
      const job = createCompressJob(photos, onProgress);
      pushCompressQueue(job, (result, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
      delegateCompressWorker();
    });
  }

  return { compress };
};

export const getFilefromDataUrl = async (...args) => compressor.getFilefromDataUrl(...args);

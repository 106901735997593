// import userReducer fro./userInfoReducerser';
// import scooterReducer from './scooter';
// import statusReducer from './Reducer';
// import parkingLotReducer from './parkingLot';
// import mapParkingLotReducer from './mapParkingLot';
// import editingUserReducer fro./editingUserReducerser';
// import pageInfoReducer from './pageInfoReducer';

const combineReducer = reducers => {
  const reducerKeys = Object.keys(reducers);
  let objInitState = {};

  reducerKeys.forEach(key => {
    const initState = reducers[key](undefined, { type: '' });
    if (initState === 'undefined') {
      throw new Error(`${key} does not return state.`);
    }
    objInitState[key] = initState;
  });

  return (state, action) => {
    if (action) {
      reducerKeys.forEach(key => {
        const previousState = objInitState[key];
        objInitState[key] = reducers[key](previousState, action);
      });
    }

    return { ...objInitState };
  };
};

const reducers = combineReducer({
  // user: userReducer,
  // scooter: scooterReducer,
  // status: statusReducer,
  // parkingLot: parkingLotReducer,
  // mapParkingLot: mapParkingLotReducer,
  // editingUser: editingUserReducer,
  // pageInfo: pageInfoReducer,
});

export default reducers;

import ReserveStep1 from '~/images/tutorial/reserve/reserve-1.jpg';
import ReserveStep2 from '~/images/tutorial/reserve/reserve-2.jpg';
import ReserveStep3 from '~/images/tutorial/reserve/reserve-3.jpg';
import RentStep1 from '~/images/tutorial/rent/rent-1.jpg';
import RentStep2 from '~/images/tutorial/rent/rent-2.jpg';
import RentStep3 from '~/images/tutorial/rent/rent-3.jpg';
import ReturnStep1 from '~/images/tutorial/return/return-1.jpg';
import ReturnStep2 from '~/images/tutorial/return/return-2.jpg';
import ReturnStep3 from '~/images/tutorial/return/return-3.jpg';

export const tutorials = [
  {
    topic: '預約',
    details: [
      {
        title: '尋找機車',
        description: '點選地圖上的機車 PIN 點，查看機車資訊',
        img: ReserveStep1,
      },
      {
        title: '預訂機車',
        description: '確認電量足夠後，即可預訂機車',
        img: ReserveStep2,
      },
      {
        title: '10分鐘取車',
        description: '點選「預訂」，系統將保留10分鐘的取車時間，讓你輕鬆走向 WeMo Scooter',
        img: ReserveStep3,
      },
    ],
  },
  {
    topic: '租借',
    details: [
      {
        title: '開始騎乘',
        description: '先啟動機車電源，再發動機車',
        img: RentStep1,
      },
      {
        title: '開啟車廂',
        description: '點選「車箱」開啟車廂，戴上安全帽',
        img: RentStep2,
      },
      {
        title: '發動機車',
        description:
          '同時按機車上的「黃色 P 鈕」及「剎車桿」，當儀表板上的「00」變成「0」即可開始騎囉！',
        img: RentStep3,
      },
    ],
  },
  {
    topic: '歸還',
    details: [
      {
        title: '歸還安全帽',
        description: '開始和結束騎乘時，記得取出及歸還安全帽',
        img: ReturnStep1,
      },
      {
        title: '熄火歸還機車',
        description: '按下還車，完成本次租借',
        img: ReturnStep2,
      },
      {
        title: '騎乘紀錄',
        description: '檢視本次租借的騎乘記錄',
        img: ReturnStep3,
      },
    ],
  },
];

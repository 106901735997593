import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { themeGet } from '@styled-system/theme-get';
import qs from 'qs';
import BasicContainer from 'Components/layouts/BasicContainer';
import ItemCard from '~/components/molecules/ItemCard';
import ActionItemCard from '~/components/organisms/ActionItemCard';
import WeMoWalletInfo from '~/components/atoms/WeMoWalletInfo';
import CreditCardInfo from '~/components/atoms/CreditCardInfo';
import FullScreenLoading from '~/components/molecules/FullScreenLoading';
import { paymentTypes, paymentKeys, rentStatus } from '~/configs';
import {
  getPaymentsInfo,
  patchPaymentsInfo,
  getLinePayPrime,
  postUserOrderTransaction,
} from '~/services/api';
import useAsyncGet from '~/hooks/useAsyncGet';
import PopupLoading from '~/components/molecules/PopupLoading';
import PageHeader from '~/components/molecules/PageHeader';
import WalletTitle from './WalletTitle';
import UnpaidItemCard from '~/components/organisms/UnpaidItemCard';
import useLoading from '~/hooks/useLoading';
import PopupNote from '~/components/molecules/PopupNote';
import { ReactComponent as LinePay } from '~/images/icons/line-pay.svg';
import { ReactComponent as ApplePay } from '~/images/icons/apple-pay.svg';
import { sign } from '~/utils/externalToken';
import wemoToken from '~/utils/wemoToken';
import { useUserInfo } from '~/context/';

const LinePayIcon = styled.img.attrs({
  as: LinePay,
})``;

const ApplePayIcon = styled.img.attrs({
  as: ApplePay,
})``;

const PaywayIconPanel = styled.div`
  height: 44px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 8px 0;
`;

const PayConfirmText = styled.p`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
  text-align: center;
`;

const getExternalUrl = ({ domain, pathName, redirectTo = '/choose-payment', urlQuery = {} }) => {
  const token = sign();
  const { accessToken } = wemoToken.getToken();
  return `${domain ||
    process.env
      .REACT_APP_VENUS_URL}${pathName}?token=${token}&wemoToken=${accessToken}&redirectTo=${redirectTo}&${qs.stringify(
    urlQuery
  )}`;
};

/**
 * ! 如果修改此處要記得 RideDetail 也可能要同時修改
 */
const orderTransaction = async (orderId, amount, paymentType) => {
  if (paymentType === paymentTypes.linePay) {
    const prime = await getLinePayPrime();
    const { paymentUrl } = await postUserOrderTransaction({
      orderId,
      amount,
      paymentType,
      redirectUrl: `${process.env.REACT_APP_VENUS_URL}/interchange?redirect=/choose-payment?`,
      prime,
      isWaiting: true,
    });
    return { paymentUrl };
  } else if (paymentType === paymentTypes.applePay) {
    const { unpaidAmount } = await postUserOrderTransaction({
      orderId,
      amount,
      paymentType,
      isWaiting: true,
    });

    if (unpaidAmount === undefined || unpaidAmount === null) {
      throw new Error('no get unpaidAmount');
    }

    if (unpaidAmount !== 0) {
      window.liff.openWindow({
        url: getExternalUrl({
          domain: process.env.REACT_APP_APPLE_PAY_URL,
          pathName: '/pay-by-apple-pay',
          urlQuery: {
            orderId,
            unpaidAmount,
            paymentType,
          },
        }),
        external: true,
      });
    }

    return { unpaidAmount };
  } else {
    // isWaiting 顯示可能會有問題，不確定 bills 資料會不會產生
    await postUserOrderTransaction({ orderId, amount, paymentType, isWaiting: true });
    return {};
  }
};

const PaymentErrors = {
  getPaymentInternalServerError: {
    title: '讀取失敗',
    notes: (
      <React.Fragment>
        <PayConfirmText>系統發生異常</PayConfirmText>
        <PayConfirmText>請稍後再試</PayConfirmText>
      </React.Fragment>
    ),
  },
  changePaymentInternalServerError: {
    title: '設定失敗',
    notes: (
      <React.Fragment>
        <PayConfirmText>系統發生異常</PayConfirmText>
        <PayConfirmText>請稍後再試</PayConfirmText>
      </React.Fragment>
    ),
  },
  changePaymentNotAvailableError: {
    title: '設定失敗',
    notes: (
      <React.Fragment>
        <PayConfirmText>無法修改</PayConfirmText>
        <PayConfirmText>預約及騎乘中無法修改支付方式</PayConfirmText>
      </React.Fragment>
    ),
  },
  paymentInternalServerError: {
    title: '付款失敗',
    notes: (
      <React.Fragment>
        <PayConfirmText>系統發生異常</PayConfirmText>
        <PayConfirmText>請稍後再試</PayConfirmText>
      </React.Fragment>
    ),
  },
  paymentFail: {
    title: '付款失敗',
    notes: (
      <React.Fragment>
        <PayConfirmText>您的付款未成功</PayConfirmText>
        <PayConfirmText>請重設您的付款方式並結清款項</PayConfirmText>
      </React.Fragment>
    ),
  },
  paymentProblem: {
    title: '付款發生問題',
    notes: (
      <React.Fragment>
        <PayConfirmText>您的部分付款無法扣款</PayConfirmText>
        <PayConfirmText>請重設付款方式並結清剩餘款項</PayConfirmText>
      </React.Fragment>
    ),
  },
};

export const PureChoosePayment = props => {
  const [paymentType, setPaymentType] = useState(paymentTypes.linePay);
  const [creditCardDisabled, setCreditCardDisabled] = useState(true);
  const [walletDisabled, setWalletDisabled] = useState(true);
  const payment = useAsyncGet(getPaymentsInfo, { loading: true, data: {} });
  const changePaymentLoading = useLoading({ loading: false });
  const payOrderLoading = useLoading({ loading: false });
  const [visiblePayConfirm, setVisiblePayConfirm] = useState(false);
  const [unpaidOrder, setUnpaidOrder] = useState({ unpaidAmount: 0 });
  const [showAlert, setShowAlert] = useState({ visible: false });
  const os = useMemo(() => window.liff.getOS(), []);
  const { updateUserInfo, user, loadingUtils } = useUserInfo();

  const handleItemOnChange = useCallback(
    async key => {
      if (
        ![
          rentStatus.notRenting,
          rentStatus.none,
          rentStatus.viewing,
          rentStatus.confirming,
          rentStatus.overtime,
          rentStatus,
        ].includes(user.userMode)
      ) {
        setShowAlert({ visible: true, ...PaymentErrors.changePaymentNotAvailableError });
        return;
      }
      const paymentKey = paymentKeys[key];
      if (paymentKey) {
        changePaymentLoading.start();
        try {
          await patchPaymentsInfo(paymentKey);
          setPaymentType(key);
          changePaymentLoading.end();
        } catch (error) {
          changePaymentLoading.end('fail');
          setShowAlert({ visible: true, ...PaymentErrors.changePaymentInternalServerError });
        }
      }
    },
    [user.userMode]
  );

  const handleOnPay = useCallback(
    (orderId, unpaidAmount) => {
      setUnpaidOrder({ orderId, unpaidAmount, paymentType });
      setVisiblePayConfirm(true);
    },
    [paymentType]
  );

  const [handleOnPayConfirm] = useDebouncedCallback(
    async () => {
      setVisiblePayConfirm(false);
      payOrderLoading.start();
      try {
        const { paymentUrl, unpaidAmount } = await orderTransaction(
          unpaidOrder.orderId,
          unpaidOrder.unpaidAmount,
          unpaidOrder.paymentType
        );

        /**
         * ! 如果選用 Line Pay 但 Order 被騎乘金扣完了就不會拿到 paymentUrl
         */
        if (paymentUrl) {
          window.TPDirect.redirect(paymentUrl);
          payOrderLoading.end('normal');
        } else if (unpaidOrder.paymentType === paymentTypes.applePay && unpaidAmount !== 0) {
          // ! 這裡要注意一下行為
          payOrderLoading.end('normal');
        } else {
          payOrderLoading.end();
        }
      } catch (error) {
        // TODO: 取得詳盡 Error
        payOrderLoading.end('fail');
        setShowAlert({ visible: true, ...PaymentErrors.paymentInternalServerError });
      }
    },
    300,
    { leading: true, trailing: false }
  );

  const [handleOnPayCancel] = useDebouncedCallback(
    () => {
      setVisiblePayConfirm(false);
      setUnpaidOrder({ unpaidAmount: 0 });
    },
    300,
    { leading: true, trailing: false }
  );

  const [handleAlertOnConfirm] = useDebouncedCallback(
    () => {
      setShowAlert({ visible: false });
    },
    300,
    { leading: true, trailing: false }
  );

  const init = useCallback(async () => {
    try {
      await updateUserInfo();
      const paymentData = await payment.getData();
      setPaymentType(paymentData.paymentType);
      setCreditCardDisabled(!paymentData.creditCard);
      setWalletDisabled(!paymentData.walletBalance);
    } catch (error) {
      setShowAlert({ visible: true, ...PaymentErrors.getPaymentInternalServerError });
    }
  }, [payment]);

  useEffect(() => {
    init();
  }, []);

  return (
    <FullScreenLoading spinning={payment.loading || loadingUtils.state.spinning}>
      <BasicContainer>
        <PageHeader title="付款設定" />
        {payment.data.unpaidOrders && payment.data.unpaidOrders.length > 0 && (
          <UnpaidItemCard unpaidOrders={payment.data.unpaidOrders} onPay={handleOnPay} />
        )}
        <ItemCard.Group
          defaultSelectedIndex="linePay"
          selected={paymentType}
          onChange={handleItemOnChange}
        >
          <ActionItemCard
            itemIndex="creditCard"
            title="信用卡"
            actionText={creditCardDisabled ? '新增' : '編輯'}
            to="/edit-credit-card"
            replace={true}
            disabled={creditCardDisabled}
          >
            {payment.data.creditCard ? (
              <CreditCardInfo {...payment.data.creditCard} />
            ) : (
              '新增信用卡 / 簽帳金融卡'
            )}
          </ActionItemCard>
          <ItemCard itemIndex="linePay" title="LINE Pay">
            <PaywayIconPanel>
              <LinePayIcon />
            </PaywayIconPanel>
          </ItemCard>
          {os === 'ios' && (
            <ItemCard itemIndex="applePay" title="Apple Pay">
              <PaywayIconPanel>
                <ApplePayIcon />
              </PaywayIconPanel>
            </ItemCard>
          )}
          <ItemCard itemIndex="wallet" title={<WalletTitle />} disabled={walletDisabled}>
            {walletDisabled ? (
              <WeMoWalletInfo disabled={true}>如需加值請到 App 操作</WeMoWalletInfo>
            ) : (
              <WeMoWalletInfo balance={payment.data.walletBalance} />
            )}
          </ItemCard>
        </ItemCard.Group>
        <PopupLoading {...changePaymentLoading.state} successText="更新完成" />
        <PopupLoading {...payOrderLoading.state} successText="付款成功" onSuccess={init} />
        <PopupNote
          visible={visiblePayConfirm}
          title="確認付款"
          confirmButtonText="付款"
          notes={
            <React.Fragment>
              <PayConfirmText>
                系統將會從
                {paymentType === paymentTypes.creditCard &&
                  ` **** ${payment.data.creditCard?.last4Digits} 信用卡`}
                {paymentType === paymentTypes.linePay && ' LINE Pay'}
                {paymentType === paymentTypes.wallet && ' WeMo 錢包'}
                {paymentType === paymentTypes.applePay && ' Apple Pay'}
              </PayConfirmText>
              <PayConfirmText>收取 NT$ {unpaidOrder.unpaidAmount} 未付款項</PayConfirmText>
            </React.Fragment>
          }
          onConfirmClick={handleOnPayConfirm}
          onCancelClick={handleOnPayCancel}
        ></PopupNote>
        <PopupNote {...showAlert} onConfirmClick={handleAlertOnConfirm} />
      </BasicContainer>
    </FullScreenLoading>
  );
};

PureChoosePayment.defaultProps = {};

PureChoosePayment.propTypes = {};

export default React.memo(PureChoosePayment);

import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import themeGet from '@styled-system/theme-get';
import Form from 'Components/atoms/Form';
import FormItem from 'Components/atoms/Form/FormItem';
import Input from 'Components/atoms/Input';
import Button from 'Components/atoms/Button';
import PageHeader from 'Components/molecules/PageHeader';
import CountDown from 'Components/atoms/CountDown';
import useFirebase from '~/hooks/useFirebase';
import BasicContainer from 'Components/layouts/BasicContainer';
import { verifyPhone } from '~/services/users';
import useLoading from '~/hooks/useLoading';
import PopupLoading from '~/components/molecules/PopupLoading';
import { useAuthentication, useEditingUser, usePageInfo } from '~/context/';
import { useErrorNotification } from '~/context/ErrorNotification';

const RowStyled = styled(Row)`
  margin: 40px;
`;

const Retry = styled.div`
  text-align: center;
`;

const Text = styled.span`
  ${props => themeGet('fonts.body', props)};
  color: ${props => (props.isActive ? props.theme.colors.mainBlue : props.theme.colors.supGrey)};
`;

const CountDownStyled = styled(CountDown)`
  margin-left: 14px;
`;

const ButtonStyled = styled(Button)`
  display: block;
  margin: 0 auto 40px;
`;

const InputStyled = styled(Input)`
  text-align: center;
`;

const maskPhone = phone =>
  phone?.replace(/(\d+)(\d{3})/, (m, p1, p2, p3) => ''.padStart(p1.length, '*') + p2);

const SmsVerify = Form.create()(({ form, ...props }) => {
  const history = useHistory();
  const [isValidCode, setIsValidCode] = useState(false);
  const [isAbleToRetry, setIsAbleToRetry] = useState(false);
  const countDownRef = useRef();
  const onValidRedirectTo = useRef(props?.location?.state?.onValidRedirectTo);
  const loadingUtil = useLoading();
  const { isLogin } = useAuthentication();
  const { editingUser, resetEditingUser, setEditingUser } = useEditingUser();
  const { initializeReCaptcha, sendMessage, confirmCode, getIdToken, recaptcha } = useFirebase();
  const { setPageInfoPrevPage } = usePageInfo();
  const { registerErrorConfig, launchError } = useErrorNotification();

  const { getFieldDecorator } = form;

  const sendCode = useCallback(async () => {
    try {
      const result = await sendMessage(
        editingUser.phoneObj.countryCode + editingUser.phoneObj.phone
      );
      loadingUtil.start();

      if (result) {
        countDownRef.current.restart();
        setIsAbleToRetry(false);
      }
      loadingUtil.end();
    } catch (error) {
      loadingUtil.end('fail');
      launchError({ type: 'SMS_SEND_CODE' }, 'smsVerify');
      console.log(error);
    }
  }, [
    editingUser.phoneObj.countryCode,
    editingUser.phoneObj.phone,
    launchError,
    loadingUtil,
    sendMessage,
  ]);

  const verifyUserPhone = useCallback(
    async evt => {
      evt?.preventDefault();
      try {
        loadingUtil.start();
        const idToken = await getIdToken();
        if (isLogin) {
          idToken && (await verifyPhone(idToken));
          resetEditingUser();
          history.replace(onValidRedirectTo.current || '/');
        } else {
          setEditingUser({
            phoneObj: { ...editingUser?.phoneObj, firebaseToken: idToken },
          });
        }
        loadingUtil.end();
      } catch (error) {
        launchError();
        loadingUtil.end('fail');
        console.log(error);
      }
    },
    [
      loadingUtil,
      getIdToken,
      isLogin,
      history,
      resetEditingUser,
      setEditingUser,
      editingUser,
      launchError,
    ]
  );

  useEffect(() => {
    if (editingUser?.phoneObj?.firebaseToken) {
      history.replace(onValidRedirectTo.current || '/');
    }
    return () => {};
  }, [editingUser, history]);

  const handleConfirmCode = async (rule, value, callback) => {
    if (!value?.match(/\d+/)) {
      callback('請輸入正確驗證碼');
    }

    if (value.length === 6) {
      loadingUtil.start();
      const result = await confirmCode(value);
      loadingUtil.end();

      if (result) {
        setIsValidCode(true);
      } else {
        callback('請輸入正確驗證碼');
      }
    }
    callback();
  };

  useEffect(() => {
    if (!recaptcha) {
      loadingUtil.start();
    } else if (recaptcha) {
      loadingUtil.end();
    }
  }, [loadingUtil, recaptcha]);

  useEffect(() => {
    if (!editingUser.phoneObj.isSet) history.replace('/');
  }, [editingUser.phoneObj.isSet, history]);

  useEffect(() => {
    const { state } = history.location;
    if (state && state.prevPage) {
      const { prevPage } = state;
      setPageInfoPrevPage(prevPage);
    }
    sendCode();
  }, []); // only do this one time

  useEffect(() => {
    isValidCode && verifyUserPhone();
  }, [isValidCode]);

  useMemo(
    () =>
      registerErrorConfig('smsVerify', {
        SMS_SEND_CODE: {
          popup: {
            title: '發送次數超過限制',
            confirmButtonText: '確認',
            onConfirmClick: () => history.replace('/landing-page'),
            notes: '因發送次數超過限制\n將暫時無法執行操作\n請稍後再試',
          },
          notifier: 'popup',
        },
      }),
    []
  );

  return (
    <BasicContainer>
      <PageHeader
        title="輸入6位數驗證碼"
        hint={`已傳送簡訊到${editingUser.phoneObj &&
          editingUser.phoneObj.countryCode + maskPhone(editingUser.phoneObj.phone)}`}
      ></PageHeader>
      <Form>
        <RowStyled gutterWidth={12}>
          <Col xs={8} push={{ xs: 2 }}>
            <FormItem labelPosition="top">
              {getFieldDecorator('name', {
                rules: [{ required: true, validator: handleConfirmCode }],
              })(<InputStyled type="tel" />)}
            </FormItem>
          </Col>
        </RowStyled>
        <ButtonStyled
          boxShadow="light"
          type="float"
          width={0.64}
          disabled={!isValidCode}
          onClick={verifyUserPhone}
          shape="capsule"
        >
          下一步
        </ButtonStyled>
        <Retry>
          <Text onClick={() => isAbleToRetry && sendCode()} isActive={isAbleToRetry}>
            重新發送
          </Text>
          <CountDownStyled
            ref={ref => (countDownRef.current = ref)}
            suffix="秒"
            duration={recaptcha ? 30 : null}
            onCountToZero={() => setIsAbleToRetry(true)}
          />
        </Retry>
      </Form>
      <PopupLoading {...loadingUtil.state} />
      <div style={{ visibility: 'hidden' }} ref={ref => initializeReCaptcha(ref)} />
    </BasicContainer>
  );
});

SmsVerify.defaultProps = {};

SmsVerify.propTypes = {};

export default SmsVerify;

import styled from 'styled-components';
import { animated } from 'react-spring';
import css from '@styled-system/css';

const SnackbarItem = styled(animated.div)`
  background-color: ${props => props.theme.colors.mainDark}d9;
  ${props => props.theme.fonts.body2};
  color: white;
  display: inline-block;
  border-radius: 5px;
  padding: 20px;
  ${() =>
    css({
      marginBottom: [10, 20],
    })}
`;

export default SnackbarItem;

import React, { createContext, useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useTransition } from 'react-spring';
import SnackbarItem from './SnackbarItem';
import css from '@styled-system/css';

const Container = styled.div`
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  flex-direction: column-reverse;
  z-index: 2;
  text-align: center;
  ${() =>
    css({
      padding: ['0 10px', '0 20px'],
    })};
`;

export const SnackbarContext = createContext();

const transigionConfig = { tension: 125, friction: 20, precision: 0.1 };

export const SnackbarProvider = React.memo(({ children, duration = 2000 }) => {
  const [snackbars, setSnackbars] = useState([]);
  const [reset, setReset] = useState(false);
  const snackbarTransition = useTransition(snackbars, snackbar => snackbar.key, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reset,
    onRest: (item, state) => {
      // TODO 這樣寫不好但一直碰到 bug
      // (寫 delay 在 leave 順序會混淆 / 寫 duration 在 from onRest 不會觸發)
      (state === 'enter' || state === 'update') &&
        setTimeout(() => {
          setSnackbars(snackbars => snackbars.filter(({ key }) => item.key !== key));
        }, duration);
    },
    onDestroyed: item => {
      typeof item.onClose === 'function' && item.onClose();
    },
    config: transigionConfig,
  });

  const enqueueSnackbar = useCallback(config => {
    if (config.preventDuplicate) {
      setSnackbars([]);
      setReset(true);
      setTimeout(() => {
        setSnackbars(props => [{ ...config, key: Date.now() }]);
        setReset(false);
      }, 700);
    } else {
      setSnackbars(props => [...props, { ...config, key: Date.now() }]);
    }
  }, []);

  const providerProps = useMemo(() => ({ enqueueSnackbar }), [enqueueSnackbar]);

  return (
    <SnackbarContext.Provider value={providerProps}>
      {children}
      <Container>
        {snackbarTransition.map(({ item, key, props }) => (
          <SnackbarItem key={key} style={props}>
            {item.message}
          </SnackbarItem>
        ))}
      </Container>
    </SnackbarContext.Provider>
  );
});

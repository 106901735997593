import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { space, color } from 'styled-system';
import SwipeableViews from 'react-swipeable-views';
import { useDebouncedCallback } from 'use-debounce';
import ItemCard from '~/components/molecules/ItemCard';
import PaginationDots from 'Components/atoms/PaginationDots';
import Button from '~/components/atoms/Button';

const Container = styled.div`
  ${space};
  ${color};
`;

const UnpaidWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const UnpaidInfoPanel = styled.div`
  margin-right: 8px;
`;

const UpaidTitle = styled.h6`
  ${themeGet('fonts.subtitle')}
  color: ${themeGet('colors.wrongRed')};
  margin: 0;
`;

const UpaidAmount = styled.span`
  color: ${themeGet('colors.mainDark')};
  padding-left: 4px;
`;

const UpaidMessage = styled.p`
  ${themeGet('fonts.body2')}
  margin: 0;
  margin-top: 2px;
`;

const PaymentButton = styled(Button)`
  width: 110px;
  ${themeGet('fonts.button2')}
  color: #fff;
  flex: 0 0 auto;
`;

export const PureUnpaidItemCard = props => {
  const { unpaidOrders, containerBackgroundColor, containerMarginX, onPay } = props;
  const [viewIndex, setViewIndex] = useState(0);

  const handleViewIndexOnChange = useCallback(viewIndex => {
    setViewIndex(viewIndex);
  }, []);

  const [handlePayOnClick] = useDebouncedCallback(
    () => {
      const { orderId, unpaidAmount } = unpaidOrders[viewIndex];
      onPay(orderId, unpaidAmount);
    },
    300,
    { leading: true, trailing: false }
  );

  return (
    <Container
      bg={containerBackgroundColor}
      mx={containerMarginX}
      p={['15px 5px 4px', '20px 10px 8px']}
      my={['15px', '30px']}
    >
      <SwipeableViews
        enableMouseEvents={true}
        index={viewIndex}
        onChangeIndex={handleViewIndexOnChange}
        slideStyle={{ padding: '4px 10px' }}
      >
        {unpaidOrders.map(unpaidOrder => (
          <ItemCard key={unpaidOrder.orderId}>
            <UnpaidWrapper>
              <UnpaidInfoPanel>
                <UpaidTitle>
                  本次未付金額<UpaidAmount>${unpaidOrder.unpaidAmount}</UpaidAmount>
                </UpaidTitle>
                <UpaidMessage>請先於下方重設您的付款方式後再結清款。</UpaidMessage>
              </UnpaidInfoPanel>
              <PaymentButton
                boxShadow="light"
                shape="capsule"
                type="float"
                size="small"
                onClick={handlePayOnClick}
              >
                付款
              </PaymentButton>
            </UnpaidWrapper>
          </ItemCard>
        ))}
      </SwipeableViews>
      <PaginationDots
        count={unpaidOrders.length}
        index={viewIndex}
        onIndexChange={handleViewIndexOnChange}
      />
    </Container>
  );
};

PureUnpaidItemCard.defaultProps = {
  unpaidOrders: [],
  containerBackgroundColor: '#F2F2F2',
  containerMarginX: ['-15px', '-30px'],
  onPay: () => {},
};

PureUnpaidItemCard.propTypes = {
  unpaidOrders: PropTypes.arrayOf(
    PropTypes.shape({
      orderId: PropTypes.string.isRequired,
      orderAmount: PropTypes.number.isRequired,
      unpaidAmount: PropTypes.number.isRequired,
    })
  ),
  containerBackgroundColor: PropTypes.string,
  containerMarginX: PropTypes.array,
  onPay: PropTypes.func,
};

export default React.memo(PureUnpaidItemCard);

import React, { Fragment, useState, useMemo } from 'react';
import styled from 'styled-components';
import { color } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { parkingLotOpenTypes, parkingLotStatusUiMap } from '~/configs';
import ButtonLongPress from 'Components/atoms/ButtonLongPress';
import { ReactComponent as Info } from 'Images/icons/info.svg';
import InfoParkingBox from 'Components/organisms/InfoParkingBox';
import InfoParkingAutomatic from 'Components/organisms/InfoParkingAutomatic';

const ButtonLongPressFull = styled(ButtonLongPress)`
  line-height: 27px;
  display: block;
`;

const RideInfo = styled.span`
  ${props => themeGet(`fonts.body`)};
  text-align: center;
  display: block;
  margin: 2px 0 16px;
`;

const Text = styled.span`
  margin: 0 6px;
`;

const Hint = styled.div`
  font-size: 13px;
  line-height: 18px;
`;

const Title = styled.div`
  text-align: center;
  ${props => props.theme.fonts.h3Button}
  font-size: 20px; // NOTE: 停車場的字最多會有 12 個
  flex-basis: 100%;
`;

const ActionTitle = styled.div`
  ${props => props.theme.fonts.h2}
  ${color};
  text-align: center;
  width: 100%;
`;

const ActionHint = styled.div`
  text-align: center;
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.secondaryGrey};
`;

const InfoIcon = styled.svg`
  margin-left: 10px;
`;

const SpecialStatusCardMap = {
  [parkingLotStatusUiMap.inParkingLotError]: (
    <Fragment>
      <ActionTitle color="wrongRed">停車場異常</ActionTitle>
      <ActionHint>系統維護中，請聯絡現場人員協助出場</ActionHint>
    </Fragment>
  ),
  [parkingLotStatusUiMap.outParkingLotError]: (
    <Fragment>
      <ActionTitle color="wrongRed">停車場異常</ActionTitle>
      <ActionHint>
        請直接索取停車場代幣進場
        <br />
        並轉交給停車場管理人員
      </ActionHint>
    </Fragment>
  ),
  [parkingLotStatusUiMap.inParkingLotClosed]: (
    <Fragment>
      <ActionTitle color="alertYellow">休息中</ActionTitle>
      <ActionHint>
        休息中
        <br />
        請聯絡現場人員協助出場
      </ActionHint>
    </Fragment>
  ),
  [parkingLotStatusUiMap.outParkingLotClosed]: (
    <Fragment>
      <ActionTitle color="alertYellow">休息中</ActionTitle>
      <ActionHint>
        休息中
        <br />
        還車請先取代幣進場，並放置於杯架內
      </ActionHint>
    </Fragment>
  ),
};

const TabParkingLot = ({
  name,
  isLoading,
  scooters,
  totalSpaces,
  openType,
  info,
  parkingLotStatusUi,
  handleOpenGate,
  shouldResetButton,
  handleOpenGateAlert,
}) => {
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  // Line Mini 沒有無柵欄停車場的說明，會回 null，在那個情況下就不顯示說明 icon
  const InfoPopup = useMemo(() => {
    if (openType === parkingLotOpenTypes.automatic || openType === parkingLotOpenTypes.etag)
      return InfoParkingAutomatic;
    else if (openType === parkingLotOpenTypes.parkingBox) return InfoParkingBox;
    return null;
  }, [openType]);

  const openInfo = () => {
    setIsInfoVisible(true);
  };

  const closeInfo = () => {
    setIsInfoVisible(false);
  };

  return !isLoading && parkingLotStatusUi ? (
    <Fragment>
      <Title>
        {name}
        {InfoPopup && <InfoIcon as={Info} onClick={openInfo} />}
      </Title>
      <RideInfo>
        <Text>可租用車輛 {totalSpaces}</Text>|<Text>剩餘車位 {scooters.length}</Text>
      </RideInfo>
      {parkingLotStatusUi === parkingLotStatusUiMap.normal ||
      ([
        parkingLotStatusUiMap.outParkingLotClosed,
        parkingLotStatusUiMap.inParkingLotClosed,
      ].includes(parkingLotStatusUi) &&
        openType !== parkingLotOpenTypes.parkingBox) ? (
        openType === parkingLotOpenTypes.automatic || openType === parkingLotOpenTypes.etag ? (
          <Fragment>
            <ActionTitle color="mainBlue">自動感應進出</ActionTitle>
            <ActionHint>
              機車停在前輪感應區
              <br />
              閘門即自動開啟
            </ActionHint>
          </Fragment>
        ) : (
          <ButtonLongPressFull
            shape="capsule"
            width={0.82}
            boxShadow="light"
            type="float"
            color="primary"
            time={1000}
            onCancel={handleOpenGateAlert}
            onClick={handleOpenGate}
            hasCompleteState={true}
            shouldReset={shouldResetButton}
          >
            開啟閘門
            <Hint>長按3秒開啟</Hint>
          </ButtonLongPressFull>
        )
      ) : (
        SpecialStatusCardMap[parkingLotStatusUi]
      )}
      <InfoPopup visible={isInfoVisible} onClose={closeInfo} onConfirmClick={closeInfo} />
    </Fragment>
  ) : null;
};

TabParkingLot.propTypes = {};

export default TabParkingLot;

import React, { Fragment, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import PaginationDots from 'Components/atoms/PaginationDots';
import PageHeader from 'Components/molecules/PageHeader';
import Card from 'Components/atoms/Card';
import Popup from '~/components/atoms/Popup';
import Button from 'Components/atoms/Button';
import SwipeableViews from 'react-swipeable-views';
import parkingBoxTutorial1 from 'Images/parkingBoxTutorial1.png';
import parkingBoxTutorial2 from 'Images/parkingBoxTutorial2.png';

const PopupStyled = styled(Popup)`
  height: 90%;
  max-height: 90%;
`;

const Image = styled.img`
  max-width: 100%;
  margin: 0 auto;
  display: block;
`;

const CardStyled = styled(Card)`
  ${space};
  text-align: center;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  ${props => props.theme.fonts.subtitle};
  margin: 0;
`;

const Note = styled.p`
  ${props => props.theme.fonts.body2};
  margin: 0;
`;

const Hint = styled.p`
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.secondaryGrey};
  text-align: center;
`;

const PageHeaderStyled = styled(PageHeader)`
  h1 {
    font-size: 22px;
  }
`;

const ButtonStyled = styled(Button)`
  ${props => props.theme.fonts.h2Button}
  margin-top: 18px;
  color: white;

  &:first-child {
    margin-top: 36px;
  }
`;

export const PureInfoParkingBox = ({ visible, onClose, onConfirmClick }) => {
  const [index, setIndex] = useState(0);
  const [popupContentHeight, setPopupContentHeight] = useState(0);
  const popupRef = useRef();

  const handleChangeIndex = index => {
    setIndex(index);
  };

  useEffect(() => {
    if (visible && popupRef.current) {
      setPopupContentHeight(
        Math.max(
          popupRef.current?.parentElement?.clientHeight,
          popupRef.current?.parentElement?.innerHeight || 0
        ) * 0.74
      );
    }
  }, [popupContentHeight, visible]);

  return (
    <PopupStyled visible={visible} onClose={onClose} maskClosable={true}>
      <div
        ref={ref => {
          popupRef.current = ref;
        }}
      >
        {popupContentHeight ? (
          <Fragment>
            <SwipeableViews
              enableMouseEvents={true}
              index={index}
              onChangeIndex={handleChangeIndex}
              containerStyle={{ height: popupContentHeight }}
            >
              <div>
                <PageHeaderStyled
                  title="如何進出停車場？"
                  hint={
                    <Fragment>
                      租車中滑至停車場功能
                      <br />
                      自動偵測附近威摩特約停車場
                    </Fragment>
                  }
                />
                <Image src={parkingBoxTutorial1} />
              </div>
              <div>
                <PageHeaderStyled
                  title="專屬車道，一鍵搞定"
                  hint={
                    <Fragment>
                      確認車在閘門前的感應區
                      <br />
                      在操作頁面長按3秒開啟閘門
                    </Fragment>
                  }
                />
                <Image src={parkingBoxTutorial2} />
              </div>
              <div>
                <PageHeaderStyled title="閘門未打開如何處理？" />
                <CardStyled padding={[10, 20]} boxShadow="none" backgroundColor="typeSpaceGrey">
                  <Title>進入停車場</Title>
                  <Note>
                    若閘門無順利開啟
                    <br />
                    直接索取停車場代幣進場
                    <br />
                    並轉交給停車場管理人員
                  </Note>
                </CardStyled>
                <Hint>(如上述作法均無法開啟閘門時，請通報管理室人員，協助處理設備故障問題)</Hint>
              </div>
            </SwipeableViews>
            <PaginationDots count={3} index={index} onIndexChange={handleChangeIndex} />
            <ButtonStyled
              boxShadow="light"
              size="small"
              width={1}
              shape="capsule"
              type="float"
              fontcolor="white"
              onClick={onConfirmClick}
            >
              確認
            </ButtonStyled>
          </Fragment>
        ) : (
          <p></p>
        )}
      </div>
    </PopupStyled>
  );
};

PureInfoParkingBox.defaultProps = {};

PureInfoParkingBox.propTypes = {};

export default React.memo(PureInfoParkingBox);

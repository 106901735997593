import React from 'react';
import styled from 'styled-components';
import RadioGroup from './RadioGroup';
import RadioButton from './RadioButton';

const RadioStyled = styled.div``;

export const Radio = () => {
  return (
    <RadioStyled>
      <input type="radio" />
    </RadioStyled>
  );
};

Radio.Group = RadioGroup;

Radio.Button = RadioButton;

Radio.defaultProps = {};

Radio.propTypes = {};

export default Radio;

import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';
import { space, layout } from 'styled-system';
import { useDebouncedCallback } from 'use-debounce';
import Button from '~/components/atoms/Button';

const PreviewControllerWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${space}
`;

const PreviewControllerInfo = styled.div`
  ${themeGet('fonts.body2')}
  width: 100%;
  text-align: center;
  color: #fff;
  height: 64px;
  ${space}
  ${layout}
`;

const RecaptureButton = styled(Button)`
  ${themeGet('fonts.h2Button')}
  color: #fff;
  background: transparent;
  border: none;
  height: 60px;
  width: 100%;
  padding: 0;
`;

const ConfirmButton = styled(Button)`
  ${themeGet('fonts.h2Button')}
  color: #fff;
  width: 100%;
  padding: 0;
`;

export const PurePreviewController = props => {
  const { info, confirmText, onRecapture, onConfirm } = props;

  const [handleRecaptureOnClick] = useDebouncedCallback(
    () => {
      onRecapture();
    },
    300,
    { leading: true, trailing: false }
  );

  const [handleConfirmOnClick] = useDebouncedCallback(
    () => {
      onConfirm();
    },
    300,
    { leading: true, trailing: false }
  );

  return (
    <PreviewControllerWrapper padding={[10, 20]}>
      <PreviewControllerInfo marginBottom={['6px', 30]} height={[42, 42]}>
        {typeof info === 'string' ? info : React.cloneElement(info, {})}
      </PreviewControllerInfo>
      <Container fluid style={{ padding: 0 }}>
        <Row justify="end" gutterWidth={20} style={{ margin: 0 }}>
          <Col xs={4} style={{ padding: 0 }}>
            <RecaptureButton shape="capsule" onClick={handleRecaptureOnClick}>
              重新拍攝
            </RecaptureButton>
          </Col>
          <Col xs={4} style={{ padding: 0 }}>
            <ConfirmButton
              boxShadow="light"
              type="float"
              shape="capsule"
              onClick={handleConfirmOnClick}
            >
              {confirmText}
            </ConfirmButton>
          </Col>
        </Row>
      </Container>
    </PreviewControllerWrapper>
  );
};

PurePreviewController.defaultProps = {
  confirmText: '確認',
  onRecapture: () => {},
  onConfirm: () => {},
};

PurePreviewController.propTypes = {
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  confirmText: PropTypes.string,
  onRecapture: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default PurePreviewController;

import React, { useEffect, useRef, useImperativeHandle, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Quagga from 'quagga';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;

  > canvas {
    display: none;
  }
`;

export const PureScancam = props => {
  const { forwardRef, facingMode, onUserMedia, onUserMediaError, onDetected } = props;
  const container = useRef();
  const video = useRef();
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: container.current,
          constraints: { facingMode, width: { ideal: 1920 }, height: { ideal: 1080 } },
          // size: 1280,
          area: {
            // top: '-50%', // top offset
            // right: '0%', // right offset
            left: '0%', // left offset
            bottom: '60%', // bottom offset
          },
        },
        decoder: {
          readers: ['code_128_reader'], // List of active readers
        },
        // locator: {
        //   patchSize: 'medium',
        //   halfSample: true,
        // },
        frequency: 10,
        locate: true,
        numOfWorkers: Math.ceil((window.navigator.hardwareConcurrency || 2) / 2),
      },
      error => {
        if (error) {
          typeof onUserMediaError === 'function' && onUserMediaError(error);
          return;
        }
        Quagga.start();
        typeof onUserMedia === 'function' && onUserMedia();
        console.log(video.current.videoWidth);
      }
    );

    // Quagga.onProcessed(function(result) {
    //   var drawingCtx = Quagga.canvas.ctx.overlay,
    //     drawingCanvas = Quagga.canvas.dom.overlay;

    //   if (result) {
    //     if (result.boxes) {
    //       drawingCtx.clearRect(
    //         0,
    //         0,
    //         parseInt(drawingCanvas.getAttribute('width')),
    //         parseInt(drawingCanvas.getAttribute('height'))
    //       );
    //       result.boxes
    //         .filter(function(box) {
    //           return box !== result.box;
    //         })
    //         .forEach(function(box) {
    //           Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
    //             color: 'green',
    //             lineWidth: 2,
    //           });
    //         });
    //     }

    //     if (result.box) {
    //       Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
    //         color: '#00F',
    //         lineWidth: 2,
    //       });
    //     }

    //     if (result.codeResult && result.codeResult.code) {
    //       Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, {
    //         color: 'red',
    //         lineWidth: 3,
    //       });
    //     }
    //   }
    // });

    video.current = container.current.querySelector('video');
    video.current.style.width = '100%';
    forceUpdate();

    Quagga.onDetected(result => {
      console.log(result);
      typeof onDetected === 'function' && onDetected(result);
    });

    return () => {
      Quagga.stop();
    };
  }, []);

  useImperativeHandle(
    forwardRef,
    () => ({
      video: video.current,
      stream: {
        getVideoTracks() {
          return [Quagga.CameraAccess.getActiveTrack()];
        },
      },
    }),
    [ignored]
  );

  return <Container ref={container} />;
};

PureScancam.defaultProps = {};

PureScancam.propTypes = {
  facingMode: PropTypes.oneOf(['environment', 'user']),
  onUserMedia: PropTypes.func,
  onUserMediaError: PropTypes.func,
  onDetected: PropTypes.func,
};

const ForwardRefScancam = React.forwardRef((props, ref) => (
  <PureScancam {...props} forwardRef={ref} />
));
const MemoScancam = React.memo(ForwardRefScancam);
export default MemoScancam;

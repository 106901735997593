import React, { createContext, useReducer } from 'react';
import userInfoReducer from '../reducers/userInfoReducer.js';

const UserInfoContext = createContext();

const UserInfoProvider = ({ children }) => {
  const reducers = useReducer(userInfoReducer, userInfoReducer());
  // console.log('UserInfoProvider');

  return <UserInfoContext.Provider value={reducers}>{children}</UserInfoContext.Provider>;
};

export { UserInfoProvider, UserInfoContext };

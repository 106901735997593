import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { position } from 'styled-system';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import Button from 'Components/atoms/Button';
import Nav from 'Components/molecules/Nav';
import { ReactComponent as Menu } from 'Images/icons/menu.svg';
// import { ReactComponent as SantaClaus } from 'Images/icons/santa-claus.svg';
import css from '@styled-system/css';

const HeaderStyled = styled(animated.header)``;

const ButtonContainer = styled(animated.div)`
  position: absolute;
  z-index: 2;
  ${position}
`;

const ButtonNav = styled(Button)`
  width: 46px;
  height: 46px;
  padding: 0;
`;

const NavHeader = styled(animated.div)`
  position: fixed;
  top: 0;
  z-index: 3;
  height: 100%;
`;

const Mask = styled(animated.div)`
  background-color: rgba(26, 26, 26, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  -webkit-tap-highlight-color: transparent;
`;

const DialogBox = styled(animated.div)`
  position: absolute;
  width: auto;
  ${css({ maxWidth: ['calc(100vw - 66px)', 'calc(100vw - 86px)'] })}
  height: 46px;
  ${css({ padding: ['8px 10px', '10px 12px'] })}
  overflow: hidden;
  background: white;
  top: 0;
  left: 56px;
  box-shadow: 2px 2px 3px 0.5px rgba(0, 0, 0, 0.25);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  user-select: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  background: #cd343d;
  border: 2px solid #faebd7;
  color: #faebd7;
  font-family: 'Mountains of Christmas';
  white-space: nowrap;
  text-overflow: ellipsis;
  transform-origin: 0;
`;

const Header = ({ user, buttonStyle, visibleDialogBox, ...headerProps }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navTransition = useTransition(isMenuOpen, null, {
    from: { transform: 'translate3d(-100%,0,0)' },
    enter: { transform: 'translate3d(0%,0,0)' },
    leave: { transform: 'translate3d(-100%,0,0)' },
  });

  const maskTransition = useTransition(isMenuOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const handleOnClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const showDialogBox = useTransition(visibleDialogBox, null, {
    from: { transform: 'scaleX(0)' },
    enter: { transform: 'scaleX(1)' },
    leave: { transform: 'scaleX(0)' },
    delay: 3000,
  });

  return (
    <HeaderStyled {...headerProps}>
      <ButtonContainer top={[10, 20]} left={[10, 20]} style={buttonStyle}>
        <ButtonNav
          type="float"
          shape="circle"
          color="plan"
          IconComponent={Menu}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
        {/* <ButtonNav
          type="float"
          shape="circle"
          color="plan"
          IconComponent={SantaClaus}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
        {showDialogBox.map(
          ({ item, key, props }) =>
            item && (
              <DialogBox key={key} style={props}>
                Ho Ho Ho Merry Christmas!
              </DialogBox>
            )
        )} */}
      </ButtonContainer>
      {maskTransition.map(
        ({ item, key, props }) => item && <Mask key={key} style={props} onClick={handleOnClose} />
      )}
      {navTransition.map(
        ({ item, key, props }) =>
          item && (
            <NavHeader key={key} style={props}>
              <Nav user={user} onClick={handleOnClose} />
            </NavHeader>
          )
      )}
    </HeaderStyled>
  );
};

Header.defaultProps = {};

Header.propTypes = {
  user: PropTypes.object,
};
export default React.memo(Header);

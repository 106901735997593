import React, { useState, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import Input from 'Components/atoms/Input';
import Button from 'Components/atoms/Button';
import PageHeader from 'Components/molecules/PageHeader';
import CardOldUser from 'Components/molecules/CardOldUser';
import BasicContainer from 'Components/layouts/BasicContainer';
import useLoading from '~/hooks/useLoading';
import PopupLoading from '~/components/molecules/PopupLoading';
import { useHistory } from 'react-router-dom';
import { useAuthentication, useEditingUser } from '~/context/';
import { useErrorNotification } from '~/context/ErrorNotification';
import { wemoPhone } from '~/configs';
import { getUserInfo } from '~/services/api';
import { logOldUserLoginEvent } from '~/utils/firebaseService';

const RowStyled = styled(Row)`
  margin: 40px;
`;

const ButtonStyled = styled(Button)`
  margin: 0 auto 40px;
  display: block;
`;

const Link = styled.a`
  color: ${props => props.theme.colors.mainBlue};
`;

// passport number verification is hard: https://www.quora.com/How-can-you-verify-a-passport-number
export const PureOldUserIdCard = props => {
  const history = useHistory();
  const [idcard, setIdcard] = useState('');
  const loadingUtil = useLoading();
  const { loginExistedUser } = useAuthentication();
  const { editingUser } = useEditingUser();
  const { registerErrorConfig, launchError } = useErrorNotification();

  const handleIdcardOnChange = evt => {
    setIdcard(evt.target.value?.trim());
  };

  const login = useCallback(async () => {
    try {
      loadingUtil.start();
      const isLogin = await loginExistedUser({
        firebaseToken: editingUser.phoneObj?.firebaseToken,
        idcard,
      });
      // TODO: 這裡要拿到 token

      if (isLogin) {
        try {
          const data = await getUserInfo();
          logOldUserLoginEvent({ userCategoryId: data.userInfo.user_group_id || null });
        } catch (error) {
          logOldUserLoginEvent({ userCategoryId: null });
        }

        history.replace('/');
      } else {
        throw Error();
      }
      loadingUtil.end();
    } catch (error) {
      launchError({ type: 'VERIFY_ERROR' }, 'oldUser');
      loadingUtil.end('fail');
      console.log(error);
    }
  }, [editingUser.phoneObj, history, idcard, launchError, loadingUtil, loginExistedUser]);

  useEffect(() => {
    if (!editingUser.phoneObj.isSet) history.replace('/');
  }, []);

  useMemo(
    () =>
      registerErrorConfig('oldUser', {
        VERIFY_ERROR: {
          popup: {
            type: 'warning',
            title: '認證失敗',
            confirmButtonText: '確認',
            notes: (
              <span>
                身份認證失敗，如有更多問題請撥打客服專線
                <Link href={`tel:${wemoPhone}`}>{wemoPhone} </Link>
              </span>
            ),
          },
          notifier: 'popup',
        },
      }),
    []
  );

  return (
    <BasicContainer>
      <PageHeader title="輸入身分證字號"></PageHeader>
      <RowStyled gutterWidth={12}>
        <Col xs={12}>
          <Input
            width="100%"
            placeholderChar="輸入身分證或外籍護照碼"
            onChange={handleIdcardOnChange}
          />
        </Col>
      </RowStyled>
      <ButtonStyled
        disabled={!idcard.length > 0}
        boxShadow="light"
        type="float"
        shape="capsule"
        width={64 / 100}
        onClick={login}
      >
        下一步
      </ButtonStyled>
      <CardOldUser />
      <PopupLoading {...loadingUtil.state} />
    </BasicContainer>
  );
};

PureOldUserIdCard.defaultProps = {};

PureOldUserIdCard.propTypes = {};

export default React.memo(PureOldUserIdCard);
// 400	OAUTH_FAIL_GETTING_UUID	oAuth 無法取得 UUID
// 400	USER_AUTH_NOT_FOUND	Auth找不到 User
// 400	USER_EMAIL_NOT_FOUND	oAuth撈取不到 User Email
// 400	USER_NOT_FOUND	系統Email反撈不到 User
// 400	USER_LOGIN_FORBIDDEN	User權限問題, 不允許登入

import React, { Fragment } from 'react';
import { Circle } from '@react-google-maps/api';
import OverlayView from './OverlayView';
import IndicatorMapDot from 'Components/atoms/IndicatorMapDot';

const circleOptions = {
  strokeColor: '#4BC6E8',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: '#4bc6e8',
  fillOpacity: 0.06,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 500,
  zIndex: 1,
};

const handleOverlayPosition = (offsetWidth, offsetHeight) => ({
  x: -offsetWidth / 2,
  y: -offsetHeight / 2,
});

const LocationIndicator = ({ position, handleCenterCircleOnload }) => {
  return (
    <Fragment>
      <Circle onLoad={handleCenterCircleOnload} options={circleOptions} />
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={handleOverlayPosition}
      >
        <IndicatorMapDot />
      </OverlayView>
    </Fragment>
  );
};

LocationIndicator.whyDidYouRender = true;

export default React.memo(LocationIndicator);

import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import { useUserInfo } from '~/context/';
import Input from 'Components/atoms/Input';
import Button from 'Components/atoms/Button';
import Form from 'Components/atoms/Form';
import PopupLoading from '~/components/molecules/PopupLoading';
import FormItem from 'Components/atoms/Form/FormItem';
import PageHeader from 'Components/molecules/PageHeader';
import BasicContainer from 'Components/layouts/BasicContainer';
import { editUserInfo } from '~/services/users';

const RowStyled = styled(Row)`
  margin: 40px;
`;

const ButtonStyled = styled(Button)`
  margin: 0 auto 40px;
  display: block;
`;

const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const EditEmail = Form.create()(({ form }) => {
  const { getFieldDecorator, isFieldTouched, getFieldValue, setFieldValue, validateFields } = form;
  const { user, updateUserInfo } = useUserInfo();
  const [isLoading, setIsLoading] = useState(false);

  const handleEditEmail = async evt => {
    evt.preventDefault();
    try {
      setIsLoading(true);
      const data = await validateFields();
      await editUserInfo({ contactEmail: data.contactEmail });
      await updateUserInfo();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user.contactEmail) {
      setFieldValue('contactEmail', user.contactEmail);
    }
  }, [setFieldValue, user.contactEmail]);

  return (
    <BasicContainer>
      <PopupLoading
        spinning={isLoading || user.isLoading}
        onSuccessGoBack={true}
        successText="更新完成"
      />
      <Fragment>
        <PageHeader title="輸入電子郵件"></PageHeader>
        <Form>
          <RowStyled gutterWidth={12}>
            <Col xs={12}>
              <FormItem labelPosition="top">
                {getFieldDecorator('contactEmail', {
                  rules: [
                    {
                      required: true,
                      pattern: emailRegExp,
                      message: '請輸入正確格式的電子郵件',
                    },
                  ],
                  initialValue: user.contactEmail,
                })(<Input />)}
              </FormItem>
            </Col>
          </RowStyled>
          <ButtonStyled
            boxShadow="light"
            type="float"
            shape="capsule"
            disabled={
              !isFieldTouched('contactEmail') ||
              getFieldValue('contactEmail') === user?.contactEmail
            }
            width={64 / 100}
            onClick={handleEditEmail}
          >
            儲存
          </ButtonStyled>
        </Form>
      </Fragment>
    </BasicContainer>
  );
});

EditEmail.defaultProps = {};

EditEmail.propTypes = {};

export default EditEmail;

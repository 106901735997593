import LineAxios from './LineAxios';

LineAxios.create('hermes');
const axios = LineAxios.getInstance('hermes').useToken();

export const getInvoiceSettings = async () => {
  return axios.get('v2/invoices').then(({ data }) => data.data);
};

/**
 * @param {object} data
 * @param {string} data.type
 * @param {string} data.content
 */
export const postInvoiceSetting = async data => {
  return axios.post('v2/invoices', data);
};

/**
 * @param {string} id
 * @param {object} data
 * @param {string} data.type
 * @param {string} data.content
 */
export const patchInvoiceSettingById = async (id, data) => {
  return axios.patch(`v2/invoices/${id}`, data);
};

import React, { useContext, Fragment } from 'react';
import styled from 'styled-components';
import ExpiredStorage from 'expired-storage';
import Button from 'Components/atoms/Button';
import Form from 'Components/atoms/Form';
import FormItem from 'Components/atoms/Form/FormItem';
import Input from 'Components/atoms/Input';
import { FormContext } from '../FormContext';

const expiredStorage = new ExpiredStorage();
const PhotoNameStorageExpiredTime = 60 * 60;

const ButtonStyled = styled(Button)`
  display: block;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
`;

const AddressForm = Form.create()(({ form, onFormSubmit, isLastStep }) => {
  const { address, handleOnNextClick } = useContext(FormContext);
  const { getFieldDecorator, validateFields } = form;

  const handleOnButtonClick = async () => {
    try {
      const data = await validateFields();
      if (data.address) {
        expiredStorage.setItem(
          'furtherDocumentsAddress',
          JSON.stringify(data.address),
          PhotoNameStorageExpiredTime
        );
        handleOnNextClick();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Form>
        <FormItem label="通訊地址">
          {getFieldDecorator('address', {
            rules: [{ required: true, message: '請輸入聯絡地址' }],
            initialValue: address,
          })(<Input />)}
        </FormItem>
      </Form>
      <ButtonStyled
        onClick={handleOnButtonClick}
        shape="capsule"
        type="float"
        boxShadow="light"
        width={0.64}
      >
        {isLastStep ? '重新送審' : '下一步'}
      </ButtonStyled>
    </Fragment>
  );
});

AddressForm.defaultProps = {};

AddressForm.propTypes = {};

export default AddressForm;

import React, { useCallback, useMemo } from 'react';
import ErrorCase from '~/components/organisms/ErrorCase';
import { useHistory } from 'react-router-dom';

const getContent = (hasLiff, isInClient) => {
  if (hasLiff && isInClient)
    return { title: '未經授權', description: '你沒有權限訪問此頁面', buttonText: '回到 WeMo' };
  if (hasLiff && !isInClient)
    return {
      title: '24H 隨傳隨租共享機車',
      description: 'WeMo Scooter 想去哪 就出發',
      buttonText: '點我騎乘',
    };
  return { title: 'WeMo Scooter', description: '請在 Line 開啟本服務', buttonText: '開啟 Line' };
};

const Unauthorized = () => {
  const history = useHistory();
  const content = useMemo(() => getContent(!!window.liff, window.liff.isInClient()), []);

  const handleOnClick = useCallback(() => {
    const { location } = history;
    const { from } = location.state || {};
    const search = from ? from.search : '';

    if (window.liff) {
      if (window.liff.isInClient()) {
        history.replace('/');
      } else {
        window.location.href = `${process.env.REACT_APP_LINE_MINI_URL}${search}`;
      }
    } else {
      window.location.href = `${process.env.REACT_APP_LINE_MINI_URL}${search}`;
    }
  }, [history]);

  return (
    <ErrorCase
      title={content.title}
      description={content.description}
      buttonText={content.buttonText}
      onClick={handleOnClick}
    />
  );
};

Unauthorized.defaultProps = {};

Unauthorized.propTypes = {};

export default Unauthorized;

import React, { useCallback, useMemo } from 'react';
// import { OverlayView } from '@react-google-maps/api';
import OverlayView from './OverlayView';
import ParkingLotPin from '~/components/atoms/ParkingLotPin';
import { isObjectDiff } from '~/utils';
import { parkingLotStatusMap } from '~/configs';

const handleOverlayPosition = (offsetWidth, offsetHeight) => ({
  x: -offsetWidth / 2,
  y: -offsetHeight / 2,
});

const ParkingLotPinOverLayView = React.memo(
  ({ parkingLot, hasError, openType, showType, onClick }) => {
    const position = useMemo(() => ({ lat: parkingLot.lat, lng: parkingLot.lng }), [
      parkingLot.lat,
      parkingLot.lng,
    ]);

    const handleOnClick = useCallback(
      evt => {
        onClick(parkingLot, evt);
      },
      [onClick, parkingLot]
    );

    return (
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={handleOverlayPosition}
      >
        <ParkingLotPin
          hasError={hasError}
          type={openType}
          showType={showType}
          isClosed={parkingLot.state?.key === parkingLotStatusMap.closed}
          amount={(parkingLot.scooters || []).length}
          onClick={handleOnClick}
        />
      </OverlayView>
    );
  }
);

const ParkingLotPinList = ({ parkingLots, showType, handleOnParkingLotPinClick }) => {
  return parkingLots.map(parkingLot => (
    <ParkingLotPinOverLayView
      showType={showType}
      hasError={parkingLot.state.key === parkingLotStatusMap.errorBothDisabled}
      parkingLot={parkingLot}
      openType={parkingLot.openType}
      key={`parking-lot-pin-${parkingLot.id}`}
      onClick={handleOnParkingLotPinClick}
    />
  ));
};

export default React.memo(
  ParkingLotPinList,
  (prevProps, nextProps) => !isObjectDiff(prevProps, nextProps)
);

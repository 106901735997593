import React, { createContext, useReducer, useCallback, useMemo, useEffect, useState } from 'react';
import emergencyReducer from '../reducers/emergencyReducer';
import PopupEmergency from '~/components/molecules/PopupEmergency';
import { emergencyWorkerCmd } from '~/configs';

const EmergencyContext = createContext();

const EmergencyProvider = ({ children }) => {
  const reducers = useReducer(emergencyReducer, emergencyReducer());
  const [emergency, dispatch] = reducers;
  const visible = useMemo(
    () => emergency.popupVisible && !emergency.popupClosed && !emergency.isBlockingEntry,
    [emergency.popupVisible, emergency.popupClosed, emergency.isBlockingEntry]
  );
  const [url, setUrl] = useState();
  const [worker, setWorker] = useState();

  const handleOnClose = useCallback(() => {
    dispatch({ type: 'setPopupClose' });
  }, [dispatch]);

  useEffect(() => {
    let worker;
    const messageHandler = event => {
      const { cmd, data } = event.data || {};
      if (cmd === emergencyWorkerCmd.GetResults) {
        dispatch({ type: 'setEmergency', payload: data });
      } else if (cmd === emergencyWorkerCmd.Message) {
        console.log(`%c${data}`, 'color: red; font-weight: bold;');
      }
    };

    if (window.Worker) {
      worker = new Worker('~/workers/emergencyPolling.js', { type: 'module' });
      setWorker(worker);
      worker.addEventListener('message', messageHandler);
    }

    return () => {
      if (worker) {
        worker.removeEventListener('message', messageHandler);
      }
    };
  }, []);

  useEffect(() => {
    if (!emergency.isBlockingEntry) {
      if (emergency.service.status !== 0) {
        dispatch({ type: 'setPopupVisible' });
        setUrl(
          emergency.service.status === 1
            ? emergency.announcement.mapUrl
            : emergency.announcement.entryUrl
        );
      } else {
        dispatch({ type: 'setPopupClose' });
      }
    }
  }, [emergency.service.status, emergency.isBlockingEntry]);

  return (
    <EmergencyContext.Provider value={[...reducers, worker]}>
      {children}
      <PopupEmergency visible={visible} url={url} onClose={handleOnClose} />
    </EmergencyContext.Provider>
  );
};

export { EmergencyProvider, EmergencyContext };

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';
import { layout } from 'styled-system';
import css from '@styled-system/css';

const PreviewPhotoWrapper = styled(animated.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  z-index: 6;
  color: #fff;
`;

const PreviewImagePanel = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const PreviewImage = styled.img`
  width: 100%;
`;

const PreviewImageControlWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
  ${layout}
  ${props =>
    css({
      pointerEvents: props.isReady ? 'auto' : 'none',
    })}
`;

export const PurePhotoPreviewer = props => {
  const { visible, imgSrc, controller, onDestroyed } = props;
  const [isReady, setIsReady] = useState(false);
  const transition = useTransition(visible, null, {
    from: {
      transform: 'translate3d(0, 100%, 0)',
    },
    enter: { transform: 'translate3d(0, 0, 0)' },
    leave: {
      transform: 'translate3d(0, 100%, 0)',
    },
    config: {
      tension: 200,
    },
    onStart(fire, action) {
      if (fire && action === 'enter') {
        setIsReady(false);
      }
    },
    onDestroyed(isDestroyed) {
      typeof onDestroyed === 'function' && onDestroyed(isDestroyed);
      if (!isDestroyed) {
        setIsReady(true);
      }
    },
  });

  return transition.map(
    ({ item, key, props }) =>
      item && (
        <PreviewPhotoWrapper key={key} style={props}>
          <PreviewImagePanel>
            <PreviewImage src={imgSrc} />
            {controller && (
              <PreviewImageControlWrapper isReady={isReady} height={['128px', 172]}>
                {React.cloneElement(controller, {})}
              </PreviewImageControlWrapper>
            )}
          </PreviewImagePanel>
        </PreviewPhotoWrapper>
      )
  );
};

PurePhotoPreviewer.defaultProps = {};

PurePhotoPreviewer.propTypes = {
  visible: PropTypes.bool,
  imgSrc: PropTypes.string,
  controller: PropTypes.element,
  onDestroyed: PropTypes.func,
};

export default PurePhotoPreviewer;

import React from 'react';
import * as Sentry from '@sentry/browser';
import ErrorCase from '~/components/organisms/ErrorCase';

class ErrorBoundaryNormal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 以至於下一個 render 會顯示 fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

export default React.memo(ErrorBoundaryNormal);

import React, { useRef, useEffect, forwardRef, useReducer, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { variant } from 'styled-system';

export const size = props =>
  variant({
    prop: 'size',
    variants: {
      small: {
        ...themeGet(`fonts.note`)(props),
        color: themeGet(`colors.supGrey`)(props),
      },
      medium: {
        ...themeGet(`fonts.body`)(props),
        color: themeGet(`colors.supGrey`)(props),
      },
    },
  });

export const type = props =>
  variant({
    prop: 'type',
    variants: {
      minute: {
        ...themeGet(`fonts.h3Button`)(props),
        color: themeGet(`colors.secondaryGrey`)(props),
      },
    },
  });

const CountDownStyled = styled.span`
  ${size}
  ${type}
`;

function reducer(state, action) {
  switch (action.type) {
    case 'decrement':
      return {
        ...state,
        duration: state.duration - action.times,
        sinceTime: action.sinceTime,
        delta: 0,
      };
    case 'reset':
      return { duration: action.duration, sinceTime: Date.now(), delta: 0 };
    default:
      throw new Error();
  }
}

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const secondToMinute = second => {
  return second < 0
    ? 0
    : `${String(Math.floor(second / 60)).padStart(2, 0)}:${String(second % 60).padStart(2, 0)}`;
};

export const PureCountDown = forwardRef(
  ({ duration, type, onCountToZero, size, suffix, className }, ref) => {
    const [state, dispatch] = useReducer(reducer, { duration });
    useInterval(
      () => {
        if (state.duration > 0) {
          const now = Date.now();
          const delta = now - state.sinceTime;

          if (delta > 1000) {
            const times = Math.floor(delta / 1000);
            const adjustment = delta - 1000 * times;
            dispatch({ type: 'decrement', times, sinceTime: now - adjustment });
          }
        }
      },
      state.duration > 0 ? 50 : null
    );

    useEffect(() => {
      if (parseInt(state.duration, 10) <= 0 && typeof onCountToZero === 'function') {
        onCountToZero();
      }
    }, [state.duration]);

    useEffect(() => {
      dispatch({ type: 'reset', duration });
    }, [duration]);

    useImperativeHandle(
      ref,
      () => ({
        restart: () => dispatch({ type: 'reset', duration }),
      }),
      [duration]
    );

    return (
      <CountDownStyled size={size} type={type} className={className}>
        {type === 'minute' ? secondToMinute(state.duration) : state.duration}
        {suffix}
      </CountDownStyled>
    );
  }
);

PureCountDown.defaultProps = {
  duration: null,
  size: 'medium',
};

PureCountDown.propTypes = {
  duration: PropTypes.number,
  type: PropTypes.oneOf(['second', 'minute']),
  size: PropTypes.oneOf(['small', 'medium']),
  onCountToZero: PropTypes.func,
  suffix: PropTypes.string,
};

export default React.memo(PureCountDown);

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { layout } from 'styled-system';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import Button from 'Components/atoms/Button';
import PageHeader from 'Components/molecules/PageHeader';
import { Row, Col } from 'react-grid-system';
import PopupNote from 'Components/molecules/PopupNote';
import { useDebouncedCallback } from 'use-debounce';
import ItemCard from 'Components/molecules/ItemCard';
import { themeGet } from '@styled-system/theme-get';
import css from '@styled-system/css';
import SwipeUpPage from 'Components/atoms/SwipeUpPage';
import TakeRegistrationPhotosV2 from 'Components/pages/TakeRegistrationPhotosV2';
import { useErrorNotification } from '~/context/ErrorNotification';

const OS = window.liff ? window.liff.getOS() : 'android';
const errorsConfig = {
  LINE_CANNOT_GET_ID_TOKEN: {
    popup: {
      title: 'LINE API 異常',
      notes: '無法取得 LINE 使用者驗證資訊',
      confirmButtonText: '確認',
    },
    notifier: 'popup',
  },
};

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;
  ${layout};
`;

const CustomPageHeader = styled(PageHeader)`
  flex-shrink: 0;
`;

const Content = styled.div`
  width: 100%;
  flex: 1 0 auto;
`;

const Footer = styled.div`
  width: 100%;
  flex-shrink: 0;
`;

const RowStyled = styled(Row)`
  align-self: flex-end;
  margin-bottom: 20px;
`;

const SubjectWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const Title = styled.h4`
  ${themeGet('fonts.h4')}
  margin: 0;
`;

const SubTitle = styled.p`
  ${themeGet('fonts.body')};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
`;

const ForwardButton = styled(Button)`
  display: block;
  margin: 0 auto;
  width: 240px;
`;

const SignUpPhoto = ({ onNextClick }) => {
  const history = useHistory();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [swipeUpVisible, setSwipeUpVisible] = useState(false);
  const { registerErrorConfig, launchError } = useErrorNotification();

  const [handleForwardOnClick] = useDebouncedCallback(
    () => {
      if (OS === 'ios') {
        setSwipeUpVisible(true);
      } else {
        // Android 跳出 Line 進行拍照
        try {
          const idToken = window.liff.getIDToken();
          if (!idToken) throw new Error('LINE empty id token');
          window.liff.openWindow({
            url: `${process.env.REACT_APP_VENUS_URL}/take-registration-photos-v2?${qs.stringify({
              redirectTo: '/sign-up',
              goBack: { step: 1 },
              forward: { step: 2 },
              token: idToken,
            })}`,
            external: true,
          });
        } catch (error) {
          launchError({ type: 'LINE_CANNOT_GET_ID_TOKEN' }, 'sign-up-photo');
          throw error;
        }
      }
    },
    300,
    {
      leading: true,
      trailing: false,
    }
  );

  const handleOnClose = useCallback(() => {
    setSwipeUpVisible(false);
  }, []);

  const handleOnFinish = useCallback((photos, idCard) => {
    window.history.replaceState(
      null,
      document.title,
      `${history.location.pathname}?${qs.stringify({
        token: window.liff.getIDToken(),
        photos,
        idCard: idCard,
      })}`
    );
    onNextClick();
  }, []);

  useEffect(() => {
    registerErrorConfig('sign-up-photo', errorsConfig);
  }, []);

  return (
    <Container>
      <CustomPageHeader
        title="拍攝證件照"
        subtitle={'點擊前往進行身份驗證'}
        hasInfo={true}
        infoOnClick={() => setIsPopupVisible(true)}
      />
      <Content>
        <RowStyled gutterWidth={20}>
          <Col xs={6}>
            <ItemCard disabled={true}>
              <SubjectWrapper>
                <SubTitle>拍攝正反面</SubTitle>
                <Title>身分證正本</Title>
              </SubjectWrapper>
            </ItemCard>
          </Col>
          <Col xs={6}>
            <ItemCard disabled={true}>
              <SubjectWrapper>
                <SubTitle>拍攝正反面</SubTitle>
                <Title>駕照正本</Title>
              </SubjectWrapper>
            </ItemCard>
          </Col>
        </RowStyled>
        <ItemCard disabled={true}>
          <SubjectWrapper>
            <SubTitle>拍攝正面</SubTitle>
            <Title>自拍照</Title>
          </SubjectWrapper>
        </ItemCard>
      </Content>
      <Footer>
        <ForwardButton type="float" boxShadow="light" onClick={handleForwardOnClick}>
          前往拍照
        </ForwardButton>
      </Footer>
      <PopupNote
        visible={isPopupVisible}
        title="為什麼要拍攝證件照片？"
        notes="依中華民國交通部規定，依國籍不同，須提供不同證件照片：本國籍人士須提供身份證、機車/汽車駕照正本 (過期也可使用)、自拍照供查核。外國籍人士須提供護照、國際駕照、自拍照供查核。您的證件照僅供 WeMo Scooter 內部使用。"
        onConfirmClick={() => setIsPopupVisible(false)}
        confirmButtonText="確認"
      />
      {OS === 'ios' && (
        <SwipeUpPage visible={swipeUpVisible}>
          <TakeRegistrationPhotosV2 onClose={handleOnClose} onFinish={handleOnFinish} />
        </SwipeUpPage>
      )}
    </Container>
  );
};

SignUpPhoto.defaultProps = {};

SignUpPhoto.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
};

export default SignUpPhoto;

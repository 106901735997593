import React from 'react';
import { routeAuths } from './defs';
import retryFetchChunk from '~/utils/retryFetchChunk';

import LandingPage from 'Components/pages/LandingPage';
import Main from 'Components/pages/Main';
import EditAddress from 'Components/pages/EditAddress';
import EditCreditCard from 'Components/pages/EditCreditCard';
import EditEmail from 'Components/pages/EditEmail';
import EditPhone from 'Components/pages/EditPhone';
import Payment from 'Components/pages/Payment';
import Account from 'Components/pages/Account';
import RideDetail from 'Components/pages/RideDetail';
import OldUserIdCard from 'Components/pages/OldUserIdCard';
import OldUserPhone from 'Components/pages/OldUserPhone';
import SignUp from 'Components/pages/SignUp';
import SignUpNote from 'Components/pages/SignUpNote';
import SignUpPhoto from 'Components/pages/SignUpPhoto';
import SignUpUserInfo from 'Components/pages/SignUpUserInfo';
import SmsPhone from 'Components/pages/SmsPhone';
import SmsVerify from 'Components/pages/SmsVerify';
import ChoosePayment from 'Components/pages/ChoosePayment';
import ChooseInvoice from 'Components/pages/ChooseInvoice';
import FurtherDocuments from '~/components/pages/FurtherDocuments';
import EditInvoice from 'Components/pages/EditInvoice';
import Interchange from 'Components/pages/Interchange';
import Help from 'Components/pages/Help';
// import WebView from 'Components/pages/WebView';
import Feedback from 'Components/pages/Feedback';
import RentTutorial from 'Components/pages/RentTutorial';
import Invitation from 'Components/pages/Invitation';
import SignUpInvitation from 'Components/pages/SignUpInvitation';
import Welcome from 'Components/pages/Welcome';
import OrderDetail from 'Components/pages/OrderDetail';
import TakeRegisterPhotos from 'Components/pages/TakeRegisterPhotos';
import TakeReturnPhoto from 'Components/pages/TakeReturnPhoto';
import PayByApplePay from 'Components/pages/PayByApplePay';
import Unauthorized from 'Components/pages/Errors/Unauthorized';
import NotFound from 'Components/pages/Errors/NotFound';
import Ban from 'Components/pages/Errors/Ban';
import TakeRegistrationPhotosV2 from 'Components/pages/TakeRegistrationPhotosV2';
import TakeReturnPhotoV2 from 'Components/pages/TakeReturnPhotoV2';
// const PayByApplePay = React.lazy(() =>
//   retryFetchChunk(() =>
//     import(/* webpackChunkName: "pay-by-apple-pay" */ 'Components/pages/PayByApplePay')
//   )
// );

// const TakeRegisterPhotos = React.lazy(() =>
//   retryFetchChunk(() =>
//     import(/* webpackChunkName: "take-register-photos" */ 'Components/pages/TakeRegisterPhotos')
//   )
// );
// const TakeReturnPhoto = React.lazy(() =>
//   retryFetchChunk(() =>
//     import(/* webpackChunkName: "take-return-photos" */ 'Components/pages/TakeReturnPhoto')
//   )
// );
// const Unauthorized = React.lazy(() =>
//   retryFetchChunk(() =>
//     import(/* webpackChunkName: "unauthorized" */ 'Components/pages/Errors/Unauthorized')
//   )
// );
// const NotFound = React.lazy(() =>
//   retryFetchChunk(() =>
//     import(/* webpackChunkName: "not-found" */ 'Components/pages/Errors/NotFound')
//   )
// );
// const Ban = React.lazy(() =>
//   retryFetchChunk(() => import(/* webpackChunkName: "ban" */ 'Components/pages/Errors/Ban'))
// );

const routes = [
  {
    path: '/',
    component: Main,
    exact: true,
    auth: routeAuths.inLinePrivate,
    withTitle: true,
    shouldHaveEmail: false,
  },
  {
    path: '/interchange',
    component: Interchange,
    exact: true,
    auth: routeAuths.outLinePublic,
    withTitle: true,
    shouldHaveEmail: false,
  },
  {
    path: '/landing-page',
    component: LandingPage,
    exact: true,
    auth: routeAuths.inLinePublic,
    withTitle: true,
    shouldHaveEmail: false,
  },
  {
    path: '/account',
    component: Account,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'menu' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/edit-address',
    component: EditAddress,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'goBack', prevPage: '/account' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/edit-credit-card',
    component: EditCreditCard,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'goBack', prevPage: '/choose-payment' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/edit-email',
    component: EditEmail,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'goBack', prevPage: '/account' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/edit-phone',
    component: EditPhone,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'goBack', prevPage: '/account' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/payment',
    component: Payment,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'menu' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/choose-payment',
    component: ChoosePayment,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'goBack', prevPage: '/payment' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/choose-invoice',
    component: ChooseInvoice,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'goBack', prevPage: '/payment' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/ride-detail',
    component: RideDetail,
    auth: routeAuths.inLinePrivate,
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/order-detail',
    component: OrderDetail,
    auth: routeAuths.inLinePrivate,
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/old-user-phone',
    component: OldUserPhone,
    auth: routeAuths.inLinePublic,
    pageInfo: { navigatorType: 'goBack', prevPage: '/landing-page' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/old-user-phone-verify',
    component: SmsVerify,
    auth: routeAuths.inLinePublic,
    pageInfo: { navigatorType: 'goBack' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/old-user-idcard',
    component: OldUserIdCard,
    auth: routeAuths.inLinePublic,
    pageInfo: { navigatorType: 'goBack', prevPage: '/old-user-phone' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/sign-up',
    component: SignUp,
    auth: routeAuths.inLinePublic,
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/sign-up-note',
    component: SignUpNote,
    auth: routeAuths.inLinePublic,
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/sign-up-photo',
    component: SignUpPhoto,
    auth: routeAuths.inLinePublic,
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/sign-up-user-info',
    component: SignUpUserInfo,
    auth: routeAuths.inLinePublic,
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/sign-up-invitation',
    component: SignUpInvitation,
    auth: routeAuths.inLinePublic,
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/welcome',
    component: Welcome,
    auth: routeAuths.inLinePrivate,
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/sign-up-phone',
    component: SmsPhone,
    auth: routeAuths.inLinePublic,
    pageInfo: { navigatorType: 'goBack', prevPage: '/landing-page' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/sign-up-phone-verify',
    component: SmsVerify,
    auth: routeAuths.inLinePublic,
    pageInfo: { navigatorType: 'goBack' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/edit-phone-verify',
    component: SmsVerify,
    auth: routeAuths.inLinePublic,
    pageInfo: { navigatorType: 'goBack' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/edit-invoice',
    component: EditInvoice,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'goBack', prevPage: '/choose-invoice' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/further-documents',
    component: FurtherDocuments,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'goBack', prevPage: '/account' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/take-register-photos',
    component: TakeRegisterPhotos,
    auth: routeAuths.outLinePrivate,
    withTitle: true,
    shouldHaveEmail: false,
  },
  {
    path: '/help',
    component: Help,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'menu' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  // {
  //   path: '/web-view',
  //   component: WebView,
  //   auth: routeAuths.inLinePrivate,
  //   pageInfo: { navigatorType: 'goBack' },
  //   withTitle: true,
  //   shouldHaveEmail: false,
  // },
  {
    path: '/feedback',
    component: Feedback,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'goBack', prevPage: '/help' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/rent-tutorial',
    component: RentTutorial,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'goBack', prevPage: '/help' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/invitation',
    component: Invitation,
    auth: routeAuths.inLinePrivate,
    pageInfo: { navigatorType: 'menu' },
    withTitle: true,
    shouldHaveEmail: true,
  },
  {
    path: '/errors/unauthorized',
    component: Unauthorized,
    auth: routeAuths.outLinePublic,
    withTitle: true,
    shouldHaveEmail: false,
  },
  {
    path: '/errors/not-found',
    component: NotFound,
    auth: routeAuths.outLinePublic,
    withTitle: true,
    shouldHaveEmail: false,
  },
  {
    path: '/errors/ban',
    component: Ban,
    auth: routeAuths.outLinePublic,
    withTitle: true,
    shouldHaveEmail: false,
  },
  {
    path: '/take-return-photo',
    component: TakeReturnPhoto,
    auth: routeAuths.outLinePrivate,
    withTitle: true,
    shouldHaveEmail: false,
  },
  {
    path: '/pay-by-apple-pay',
    component: PayByApplePay,
    auth: routeAuths.outLinePrivate,
    withTitle: true,
    shouldHaveEmail: false,
  },
  {
    path: '/take-registration-photos-v2',
    component: TakeRegistrationPhotosV2,
    auth: routeAuths.outLinePrivate,
    withTitle: true,
    shouldHaveEmail: false,
  },
  {
    path: '/take-return-photo-v2',
    component: TakeReturnPhotoV2,
    auth: routeAuths.outLinePrivate,
    withTitle: true,
    shouldHaveEmail: false,
  },
];

// Nest example:
// {
//     path: '/ride-detail',
//     component: RideDetail,
//     routes: [
//       {
//         path: '/ride-detail/1',
//         component: RideDetail,
//       },
//       {
//         path: '/ride-detail/2',
//         component: RideDetail,
//       },
//     ],
//   }

export default routes;

export const pathIsAuth = (path, ...auths) => {
  const paths = routes.filter(route => auths.includes(route.auth)).map(route => route.path);
  return paths.includes(path);
};

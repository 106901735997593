import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { type, buttonSize, shape, common, color, disabled } from './style';
import { layout } from 'styled-system';
import { useGesture } from 'react-use-gesture';
import { useSpring, animated } from 'react-spring';

const Mask = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  line-height: 0;
  -webkit-tap-highlight-color: transparent;
`;

/* size is a reserved word in style system */
const Button = ({ children, icon, actionType, IconComponent, size, ...props }) => {
  const {
    common,
    buttonSize,
    boxShadow,
    type,
    shape,
    color,
    disabled,
    hasAnimation,
    // onClick,
    layout,
    ...buttonProps
  } = props;

  const [{ springSize }, set] = useSpring(() => ({
    springSize: 1,
    config: { tension: 300 },
  }));

  const bind = useGesture({
    onDrag: ({ down, elapsedTime }) => {
      set({
        springSize: down ? 0 : 1,
        // onRest: onClick
      });
    },
  });

  const scale = springSize.interpolate({
    range: [0, 0.75, 1],
    output: ['scale(0.9)', 'scale(0.9)', 'scale(1)'],
    extrapolate: 'clamp',
  });

  const maskColor = springSize.interpolate({
    range: [0, 1],
    output: ['#00000014', '#00000000'],
    extrapolate: 'clamp',
  });

  return disabled || !hasAnimation ? (
    <animated.button type={actionType} {...buttonProps} disabled={disabled}>
      {icon && <img src={icon} />}
      {IconComponent && <IconComponent />}
      {children}
    </animated.button>
  ) : (
    <animated.button type={actionType} {...buttonProps} {...bind()} style={{ transform: scale }}>
      {icon && <img src={icon} />}
      {IconComponent && <IconComponent />}
      {children}
      <Mask
        style={{
          backgroundColor: maskColor,
        }}
      />
    </animated.button>
  );
};

const ButtonStyled = styled(Button)`
  ${common}
  ${buttonSize}
  ${type}
  ${shape}
  ${color}
  ${disabled}
  ${layout}
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  overflow:hidden;
  /* below: to make overflow:hidden; works with border-radius in safari */
  will-change: transform;
  position:relative;
`;

const PureButton = ({ size, ...props }) => <ButtonStyled buttonSize={size} {...props} />;

// export const ButtonRef = forwardRef(({ children, icon, IconComponent, size, ...props }, ref) => {
//   return <ButtonStyled buttonSize={size} {...props} ref={ref} />;
// });

// ButtonRef.defaultProps = {
//   type: 'default',
//   size: 'medium',
//   shape: 'round',
//   color: 'primary',
//   icon: null,
//   disabled: false,
//   boxShadow: 'dark',
// };

PureButton.defaultProps = {
  type: 'default',
  size: 'medium',
  shape: 'capsule',
  color: 'primary',
  icon: null,
  disabled: false,
  boxShadow: 'dark',
  hasAnimation: true,
  actionType: 'button',
};

// ButtonRef.propTypes = {
//   type: PropTypes.oneOf(['default', 'float']),
//   shape: PropTypes.oneOf(['circle', 'capsule', 'round', 'sharp']),
//   color: PropTypes.oneOf(['plan', 'primary']),
//   width: PropTypes.number,
//   icon: PropTypes.string,
//   size: PropTypes.oneOf(['small', 'medium', 'big']),
//   onClick: PropTypes.func,
//   boxShadow: PropTypes.oneOf(['dark', 'light']),
//   disabled: PropTypes.bool,
//   IconComponent: PropTypes.object,
// };

PureButton.propTypes = {
  type: PropTypes.oneOf(['default', 'float']),
  shape: PropTypes.oneOf(['circle', 'capsule', 'round', 'sharp']),
  color: PropTypes.oneOf(['plan', 'primary', 'black', 'uselessBlue']),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  onClick: PropTypes.func,
  boxShadow: PropTypes.oneOf(['dark', 'light']),
  disabled: PropTypes.bool,
  IconComponent: PropTypes.object,
  hasAnimation: PropTypes.bool,
  actionType: PropTypes.string,
};

export default PureButton;

import React from 'react';
import { Circle, Polyline } from '@react-google-maps/api';
import { withTheme } from 'styled-components';

const ParkingSpaces = ({ theme, location, visible, parkingSpaces }) => {
  return parkingSpaces.map((space, i) => {
    return space.length === 1 ? (
      <Circle
        key={`${space[0][0]},${space[0][1]}`}
        // required
        center={{ lat: space[0][0], lng: space[0][1] }}
        // required
        options={{
          strokeColor: theme.colors.mainGreen,
          strokeWeight: 3,
          fillOpacity: 1,
          visible: visible,
          radius: 0,
          zIndex: -101,
        }}
      />
    ) : (
      <Polyline
        key={`${space[0][0]},${space[0][1]}_${space[1][0]},${space[1][1]}`}
        path={[
          { lat: space[0][0], lng: space[0][1] },
          { lat: space[1][0], lng: space[1][1] },
        ]}
        options={{
          strokeColor: theme.colors.mainGreen,
          strokeWeight: 3,
          visible: visible,
          radius: 0,
          zIndex: -101,
        }}
      />
    );
  });
};

export default React.memo(withTheme(ParkingSpaces));

import { getMockAdapter } from './mock';

const mockUsers = () => {
  const mock = getMockAdapter();
  // mock.onPost('/v4/users/login').reply(200, {
  //   result: 'success',
  //   data: {
  //     accessToken:
  //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIzNjU4NCIsImlhdCI6MTU0MjI3MzQwOCwiZXhwIjoxNTQyNzA1NDA4fQ.pTaGdkHOXUDWu3IaipQDwVT_Mazc3Ne7p0UmrqPGlxI',
  //     refreshToken: '46F5895E461D43D8FD55B396DEAFB06505ABFCF39CE5E4EFE2FA5D0EA1850A4A',
  //   },
  // });
  mock.onPost('/v4/users/login').reply(400, {
    result: 'fail',
    error: { type: 'USER_LOGIN_EMAIL_IN_USE' },
  });

  mock.onGet('/v4/users/me/tokens').reply(200, {
    result: 'success',
    data: {
      accessToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIzNjU4NCIsImlhdCI6MTU0MjI3MzQwOCwiZXhwIjoxNTQyNzA1NDA4fQ.pTaGdkHOXUDWu3IaipQDwVT_Mazc3Ne7p0UmrqPGlxI',
      refreshToken: '46F5895E461D43D8FD55B396DEAFB06505ABFCF39CE5E4EFE2FA5D0EA1850A4A',
    },
  });

  mock.onGet('/v4/users/me/status').reply(200, {
    result: 'success',
    data: {
      statusId: '1',
      // statusId: '3',
      isAllowLogin: true,
      isAllowRide: true,
      isAllowResubmitDoc: true,
      isAllowPurchase: true,
      deadline: '2018-04-26T16:00:00Z',
    },
  });

  mock.onPost('/v4/users/register').reply(400, {
    result: 'fail',
    error: { type: 'USER_REGISTER_EMAIL_DUPLICATE' },
  });

  mock.onPost(/\/v4\/users\/me\/rents\/\d+\/images/).reply(200, {
    result: 'success',
    data: {},
  });

  mock.onGet('/v4/users/me/zones').reply(200, {
    result: 'success',
    data: [
      {
        type: 'FORBIDDEN',
        zIndex: 2,
        scooterGroupIds: [20, 0],
        title: '非營運範圍',
        description: '非營運範圍，此處禁止還車',
        zones: [
          {
            name: '三重-三和夜市周邊',
            coordinates: [
              [25.066359, 121.496851],
              [25.064445, 121.497439],
              [25.063284, 121.497748],
              [25.062426, 121.498024],
              [25.062151, 121.497989],
              [25.062851, 121.500032],
              [25.063774, 121.502532],
              [25.065159, 121.502025],
              [25.065361, 121.50194],
              [25.066311, 121.501599],
              [25.066883, 121.500958],
              [25.067455, 121.500295],
              [25.068629, 121.499153],
              [25.067428, 121.498163],
              [25.066624, 121.497386],
              [25.066359, 121.496851],
            ],
          },
          {
            name: '三重-三重商工',
            coordinates: [
              [25.070755, 121.482333],
              [25.070147, 121.479769],
              [25.06969, 121.479039],
              [25.069331, 121.478942],
              [25.068962, 121.480359],
              [25.068034, 121.481979],
              [25.068882, 121.482684],
              [25.069613, 121.483497],
              [25.070755, 121.482333],
            ],
          },
          {
            name: '三重-中山立體停車場',
            coordinates: [
              [25.061161, 121.489787],
              [25.061197, 121.488151],
              [25.060682, 121.488138],
              [25.060648, 121.489452],
              [25.060852, 121.489763],
              [25.061161, 121.489787],
            ],
          },
          {
            name: '三重-二重國中',
            coordinates: [
              [25.065189, 121.477855],
              [25.065034, 121.47896],
              [25.064762, 121.480098],
              [25.065806, 121.480441],
              [25.066375, 121.47873],
              [25.066234, 121.4777],
              [25.065189, 121.477855],
            ],
          },
          {
            name: '三重-光復平面停車場',
            coordinates: [
              [25.0567669, 121.4742354],
              [25.0560769, 121.4742461],
              [25.0560672, 121.4754638],
              [25.0567499, 121.4754719],
              [25.0567669, 121.4742354],
            ],
          },
          {
            name: '三重-光興國小',
            coordinates: [
              [25.059994, 121.498532],
              [25.058541, 121.498859],
              [25.058716, 121.500436],
              [25.060261, 121.500249],
              [25.059994, 121.498532],
            ],
          },
          {
            name: '三重-力行地下停車場',
            coordinates: [
              [25.0781, 121.48226],
              [25.076734, 121.482485],
              [25.076705, 121.482796],
              [25.077526, 121.483301],
              [25.0781, 121.48226],
            ],
          },
          {
            name: '三重-台灣聯通停車場(介壽)',
            coordinates: [
              [25.071377, 121.493919],
              [25.071274, 121.494073],
              [25.071483, 121.494229],
              [25.071581, 121.494072],
              [25.071377, 121.493919],
            ],
          },
          {
            name: '三重-家樂福(蘆洲店)',
            coordinates: [
              [25.087464, 121.486553],
              [25.087913, 121.487004],
              [25.088433, 121.486451],
              [25.088001, 121.485966],
              [25.087464, 121.486553],
            ],
          },
          {
            name: '三重-市立聯合醫院(三重院區)',
            coordinates: [
              [25.061214, 121.490061],
              [25.060894, 121.49005],
              [25.060034, 121.49078],
              [25.060568, 121.491611],
              [25.061142, 121.491622],
              [25.061214, 121.490061],
            ],
          },
          {
            name: '三重-成功室內停車場',
            coordinates: [
              [25.0548, 121.487568],
              [25.054227, 121.487715],
              [25.054334, 121.488362],
              [25.054919, 121.488195],
              [25.0548, 121.487568],
            ],
          },
          {
            name: '三重-正義公園停車場',
            coordinates: [
              [25.068722, 121.497089],
              [25.068182, 121.497185],
              [25.068326, 121.497808],
              [25.068821, 121.497641],
              [25.068722, 121.497089],
            ],
          },
          {
            name: '三重-正義國小',
            coordinates: [
              [25.064993, 121.494824],
              [25.064366, 121.495618],
              [25.064531, 121.496664],
              [25.065362, 121.496417],
              [25.064993, 121.494824],
            ],
          },
          {
            name: '三重-永福國小',
            coordinates: [
              [25.077072, 121.479244],
              [25.076435, 121.480253],
              [25.076459, 121.480741],
              [25.077645, 121.480468],
              [25.07764, 121.480167],
              [25.077475, 121.47969],
              [25.077072, 121.479244],
            ],
          },
          {
            name: '三重-溪美立體停車場',
            coordinates: [
              [25.078223, 121.490328],
              [25.077966, 121.490543],
              [25.078549, 121.491637],
              [25.079224, 121.491138],
              [25.078223, 121.490328],
            ],
          },
          {
            name: '三重-碧華國小',
            coordinates: [
              [25.0857, 121.489278],
              [25.084922, 121.488505],
              [25.084432, 121.489117],
              [25.083552, 121.489707],
              [25.084325, 121.490801],
              [25.0857, 121.489278],
            ],
          },
          {
            name: '三重-美麗人生產後護理之家',
            coordinates: [
              [25.0486868, 121.4722023],
              [25.047812, 121.4722077],
              [25.047812, 121.4729802],
              [25.0486868, 121.4729587],
              [25.0486868, 121.4722023],
            ],
          },
          {
            name: '三重-聯邦公園停車場',
            coordinates: [
              [25.064448, 121.483677],
              [25.063877, 121.483672],
              [25.063874, 121.484487],
              [25.064448, 121.483677],
            ],
          },
          {
            name: '三重-重新路三段',
            coordinates: [
              [25.06146, 121.493874],
              [25.061208, 121.493906],
              [25.0614407, 121.4953677],
              [25.0567879, 121.496519],
              [25.0568391, 121.4969193],
              [25.0614898, 121.4957995],
              [25.062063, 121.497822],
              [25.062326, 121.497747],
              [25.06183, 121.496374],
              [25.06146, 121.493874],
            ],
          },
          {
            name: '三重-防火巷-1',
            coordinates: [
              [25.067842, 121.496391],
              [25.067225, 121.496571],
              [25.067647, 121.498113],
              [25.06824, 121.497909],
              [25.067842, 121.496391],
            ],
          },
          {
            name: '三重-防火巷-2',
            coordinates: [
              [25.068345, 121.497976],
              [25.067995, 121.498105],
              [25.068126, 121.498521],
              [25.068544, 121.49892],
              [25.068539, 121.498792],
              [25.068345, 121.497976],
            ],
          },
          {
            name: '三重-防火巷-3',
            coordinates: [
              [25.06919, 121.495855],
              [25.068711, 121.496045],
              [25.069001, 121.49763],
              [25.069538, 121.497467],
              [25.06919, 121.495855],
            ],
          },
          {
            name: '三重-龍門公園停車場',
            coordinates: [
              [25.074837, 121.498415],
              [25.074492, 121.497313],
              [25.074006, 121.497493],
              [25.074329, 121.498638],
              [25.074837, 121.498415],
            ],
          },
          {
            name: '中和-COSTCO好事多',
            coordinates: [
              [25.0030667, 121.4909539],
              [25.0003587, 121.4924721],
              [25.0003587, 121.4931748],
              [25.0023423, 121.4930675],
              [25.0032684, 121.4911363],
              [25.0030667, 121.4909539],
            ],
          },
          {
            name: '中和-大潤發',
            coordinates: [
              [24.9928425, 121.5154506],
              [24.9920548, 121.5153004],
              [24.9918627, 121.5170626],
              [24.9926504, 121.5172048],
              [24.9928425, 121.5154506],
            ],
          },
          {
            name: '中和-家樂福',
            coordinates: [
              [25.0022771, 121.4953965],
              [25.0000115, 121.4965445],
              [25.0008769, 121.4971346],
              [25.0011686, 121.4968985],
              [25.0023452, 121.4966947],
              [25.0022771, 121.4953965],
            ],
          },
          {
            name: '中和-環球',
            coordinates: [
              [25.0080917, 121.4741545],
              [25.0069735, 121.4744281],
              [25.0068325, 121.4741008],
              [25.0062637, 121.4740043],
              [25.0060255, 121.4757584],
              [25.0083104, 121.4749162],
              [25.0080917, 121.4741545],
            ],
          },
          {
            name: '中和-美樂家中和健康生活館',
            coordinates: [
              [25.0001873, 121.4907767],
              [24.9996331, 121.4907727],
              [24.9996355, 121.4912139],
              [25.0001812, 121.4912099],
              [25.0001873, 121.4907767],
            ],
          },
          {
            name: '中和-興南夜市',
            coordinates: [
              [24.989882, 121.510669],
              [24.988401, 121.511313],
              [24.986881, 121.512113],
              [24.987841, 121.512527],
              [24.988856, 121.51271],
              [24.990246, 121.512013],
              [24.990446, 121.511109],
              [24.989882, 121.510669],
            ],
          },
          {
            name: '中和-迪卡儂',
            coordinates: [
              [25.0035939, 121.4991771],
              [25.0031977, 121.498418],
              [25.0029376, 121.4985843],
              [25.0029802, 121.4993554],
              [25.0032026, 121.4993447],
              [25.0033691, 121.4992099],
              [25.0035939, 121.4991771],
            ],
          },
          {
            name: '中和-遠東世紀廣場中正路側機車停車場',
            coordinates: [
              [24.9982952, 121.4850126],
              [24.9968464, 121.4851065],
              [24.9968828, 121.48549],
              [24.9983001, 121.4853935],
              [24.9982952, 121.4850126],
            ],
          },
          {
            name: '中山-ATT百貨(大直)',
            coordinates: [
              [25.0824875, 121.5545902],
              [25.0800145, 121.5545258],
              [25.0800145, 121.5565858],
              [25.0800278, 121.5571875],
              [25.0826964, 121.5572011],
              [25.082677, 121.558503],
              [25.0840027, 121.5585041],
              [25.0839976, 121.5564552],
              [25.0838129, 121.5562073],
              [25.0838127, 121.5556161],
              [25.0824802, 121.5556174],
              [25.0824875, 121.5545902],
            ],
          },
          {
            name: '中山-大潤發(中崙店)',
            coordinates: [
              [25.0470885, 121.542226],
              [25.0448772, 121.5429254],
              [25.0449236, 121.5434858],
              [25.0471797, 121.5426337],
              [25.0470885, 121.542226],
            ],
          },
          {
            name: '中山-家樂福大直店',
            coordinates: [
              [25.0825818, 121.5578316],
              [25.0816441, 121.5578182],
              [25.0816343, 121.5583734],
              [25.0825769, 121.5583734],
              [25.0825818, 121.5578316],
            ],
          },
          {
            name: '中正-中正紀念堂/兩廳院',
            coordinates: [
              [25.038234, 121.517808],
              [25.035113, 121.517046],
              [25.032741, 121.522282],
              [25.035376, 121.523816],
              [25.038234, 121.517808],
            ],
          },
          {
            name: '信義-信義區仁愛路四段512巷私人土地',
            coordinates: [
              [25.0370644, 121.5613228],
              [25.0362333, 121.5608333],
              [25.0360024, 121.5612839],
              [25.0368602, 121.5618043],
              [25.0370644, 121.5613228],
            ],
          },
          {
            name: '信義-信義計畫區/商圈',
            coordinates: [
              [25.041047, 121.5646297],
              [25.039553, 121.5637507],
              [25.0375682, 121.5625836],
              [25.0375433, 121.563762],
              [25.0356044, 121.5637059],
              [25.0356301, 121.5613068],
              [25.033002, 121.5597607],
              [25.0328586, 121.566534],
              [25.032844, 121.5675586],
              [25.0328249, 121.568424],
              [25.041024, 121.5685498],
              [25.041037, 121.5668737],
              [25.041047, 121.5646297],
            ],
            holes: [
              {
                coordinates: [
                  [25.0385835, 121.5654345],
                  [25.0368523, 121.5653764],
                  [25.0367967, 121.5665173],
                  [25.0385415, 121.5665611],
                  [25.0385835, 121.5654345],
                ],
              },
              {
                coordinates: [
                  [25.0390226, 121.5675061],
                  [25.0364367, 121.5674648],
                  [25.0364443, 121.5677909],
                  [25.0390105, 121.56785],
                  [25.0390226, 121.5675061],
                ],
              },
            ],
          },
          {
            name: '信義-克勤新城',
            coordinates: [
              [25.0267964, 121.5610033],
              [25.0264598, 121.5613312],
              [25.0266494, 121.5615826],
              [25.0269994, 121.5612453],
              [25.0267964, 121.5610033],
            ],
          },
          {
            name: '信義-松勤街人行道禁停',
            coordinates: [
              [25.0323033, 121.5613742],
              [25.0318343, 121.5613608],
              [25.0317905, 121.5638311],
              [25.0322644, 121.5638392],
              [25.0323033, 121.5613742],
            ],
          },
          {
            name: '信義-松菸/松山文創/松菸誠品',
            coordinates: [
              [25.0451562, 121.5578939],
              [25.0414917, 121.5578139],
              [25.0413633, 121.5622712],
              [25.0451676, 121.5625309],
              [25.0464982, 121.561208],
              [25.0451562, 121.5578939],
            ],
          },
          {
            name: '內湖-7-ELEVEN(瓏馬門市)',
            coordinates: [
              [25.0763745, 121.6214181],
              [25.0758279, 121.62163],
              [25.0759408, 121.6220337],
              [25.0764996, 121.621803],
              [25.0763745, 121.6214181],
            ],
          },
          {
            name: '內湖-costco',
            coordinates: [
              [25.0647252, 121.5754947],
              [25.0643219, 121.5747275],
              [25.0630731, 121.5756019],
              [25.0634667, 121.5764763],
              [25.0647252, 121.5754947],
            ],
          },
          {
            name: '內湖-三總/國防醫學院',
            coordinates: [
              [25.0725089, 121.590114],
              [25.0717409, 121.59005],
              [25.0712699, 121.590182],
              [25.070614, 121.5904092],
              [25.0705944, 121.591257],
              [25.0705698, 121.5916569],
              [25.0705772, 121.5919775],
              [25.0705274, 121.5928351],
              [25.0705119, 121.593065],
              [25.0687908, 121.5928926],
              [25.0686916, 121.5944062],
              [25.0681551, 121.5957588],
              [25.0687861, 121.5960057],
              [25.0691304, 121.5958556],
              [25.0695795, 121.5964028],
              [25.0704795, 121.59663],
              [25.0706381, 121.5976411],
              [25.0707809, 121.597758],
              [25.0720599, 121.597842],
              [25.0732079, 121.597803],
              [25.0732279, 121.597754],
              [25.0737629, 121.595404],
              [25.0735199, 121.593033],
              [25.0733929, 121.590812],
              [25.0729559, 121.590351],
              [25.0725089, 121.590114],
            ],
          },
          {
            name: '內湖-內湖運動中心',
            coordinates: [
              [25.0788495, 121.5746375],
              [25.0774891, 121.5738918],
              [25.0770226, 121.5750505],
              [25.0783831, 121.5758498],
              [25.0788495, 121.5746375],
            ],
          },
          {
            name: '內湖-堤頂大道二段89號(私人停車格)',
            coordinates: [
              [25.0758854, 121.5716926],
              [25.0757275, 121.5718039],
              [25.0759072, 121.5721258],
              [25.0760664, 121.5720105],
              [25.0758854, 121.5716926],
            ],
          },
          {
            name: '北投-COSTCO好事多',
            coordinates: [
              [25.1274708, 121.4687001],
              [25.1261801, 121.4698266],
              [25.1273117, 121.4713662],
              [25.1287736, 121.4703361],
              [25.1274708, 121.4687001],
            ],
          },
          {
            name: '北投-和信醫院',
            coordinates: [
              [25.1289387, 121.470897],
              [25.1275886, 121.4718197],
              [25.1291379, 121.4731018],
              [25.1299053, 121.4723776],
              [25.1289387, 121.470897],
            ],
          },
          {
            name: '北投-復興四路29號私人',
            coordinates: [
              [25.1422386, 121.4999422],
              [25.142017, 121.5001421],
              [25.1422787, 121.5004063],
              [25.1424602, 121.5001695],
              [25.1422386, 121.4999422],
            ],
          },
          {
            name: '北投-振興醫院',
            coordinates: [
              [25.1166972, 121.5219405],
              [25.1157354, 121.5218923],
              [25.1157403, 121.5215611],
              [25.1153979, 121.5215007],
              [25.1152946, 121.5219433],
              [25.1155387, 121.5228552],
              [25.1155606, 121.5236679],
              [25.1156626, 121.5238986],
              [25.1163572, 121.5239629],
              [25.1165369, 121.5238771],
              [25.116872, 121.5233514],
              [25.1169109, 121.5230778],
              [25.1167409, 121.5226808],
              [25.1166972, 121.5219405],
            ],
          },
          {
            name: '南港-南港運動中心',
            coordinates: [
              [25.0490859, 121.581431],
              [25.0485562, 121.5814579],
              [25.0487238, 121.5825428],
              [25.0493398, 121.5823926],
              [25.0490859, 121.581431],
            ],
          },
          {
            name: '南港-應安停車場',
            coordinates: [
              [25.0484453, 121.5847616],
              [25.0486694, 121.5854083],
              [25.048483, 121.5855722],
              [25.0488511, 121.586627],
              [25.0500466, 121.5861723],
              [25.0496275, 121.5839126],
              [25.0484453, 121.5847616],
            ],
          },
          {
            name: '台北-台北機廠員工停車處',
            coordinates: [
              [25.0474759, 121.5637044],
              [25.0460617, 121.5642623],
              [25.0466789, 121.5659145],
              [25.0480202, 121.5652762],
              [25.0474759, 121.5637044],
            ],
          },
          {
            name: '台北-同安街28巷9弄2號私人土地',
            coordinates: [
              [25.0253456, 121.5209389],
              [25.0246797, 121.5206679],
              [25.0245071, 121.521081],
              [25.0251853, 121.5213385],
              [25.0253456, 121.5209389],
            ],
          },
          {
            name: '士林-大葉高島屋',
            coordinates: [
              [25.1123169, 121.5311178],
              [25.1118093, 121.5309206],
              [25.1115203, 121.5308422],
              [25.1112264, 121.530812],
              [25.1114037, 121.5323516],
              [25.1124626, 121.5321827],
              [25.1123169, 121.5311178],
            ],
          },
          {
            name: '士林-故宮路41巷',
            coordinates: [
              [25.096606, 121.547377],
              [25.096238, 121.547778],
              [25.096432, 121.548289],
              [25.096846, 121.548077],
              [25.096606, 121.547377],
            ],
          },
          {
            name: '士林-新光三越A棟(天母店)',
            coordinates: [
              [25.1179639, 121.5336577],
              [25.1176433, 121.5336618],
              [25.1176298, 121.5344772],
              [25.117976, 121.5345483],
              [25.1179639, 121.5336577],
            ],
          },
          {
            name: '士林-新光三越B棟(天母店)',
            coordinates: [
              [25.1173785, 121.5336256],
              [25.1168759, 121.5336256],
              [25.116871, 121.5338751],
              [25.1170057, 121.5339515],
              [25.1170009, 121.5342385],
              [25.1173725, 121.5343913],
              [25.1173785, 121.5336256],
            ],
          },
          {
            name: '大同-家樂福(重慶店)',
            coordinates: [
              [25.0591807, 121.5136632],
              [25.0588709, 121.5136659],
              [25.0589219, 121.514834],
              [25.0593119, 121.5147267],
              [25.0591807, 121.5136632],
            ],
          },
          {
            name: '大安-國立臺灣科技大學',
            coordinates: [
              [25.0142777, 121.5406071],
              [25.0135364, 121.5413983],
              [25.0141902, 121.5421896],
              [25.0149632, 121.5414064],
              [25.0142777, 121.5406071],
            ],
          },
          {
            name: '大安-大安森林公園',
            coordinates: [
              [25.0337617, 121.532674],
              [25.0269079, 121.5350194],
              [25.0261934, 121.5348742],
              [25.02578, 121.5376267],
              [25.0337326, 121.5378082],
              [25.0337617, 121.532674],
            ],
          },
          {
            name: '大安-大安運動中心',
            coordinates: [
              [25.022432, 121.546675],
              [25.022422, 121.546573],
              [25.022466, 121.545844],
              [25.022269, 121.54531],
              [25.022033, 121.544766],
              [25.021306, 121.544573],
              [25.0200342, 121.5460532],
              [25.021231, 121.547518],
              [25.022301, 121.54674],
              [25.022432, 121.546675],
            ],
          },
          {
            name: '大安-敦化南路一段187巷59號(私人土地)',
            coordinates: [
              [25.0429491, 121.5520209],
              [25.0424643, 121.5519994],
              [25.0424582, 121.5522918],
              [25.0429466, 121.5523106],
              [25.0429491, 121.5520209],
            ],
          },
          {
            name: '大安-遠東 SOGO(復興館)',
            coordinates: [
              [25.0414826, 121.5425995],
              [25.0406569, 121.5425781],
              [25.0406384, 121.5435207],
              [25.041496, 121.543554],
              [25.0414826, 121.5425995],
            ],
          },
          {
            name: '大直-北安休閒運動中心',
            coordinates: [
              [25.0798771, 121.5411156],
              [25.0783126, 121.5411907],
              [25.0783951, 121.5441734],
              [25.0800131, 121.5442055],
              [25.0798771, 121.5411156],
            ],
          },
          {
            name: '文山-世界山莊',
            coordinates: [
              [25.0071122, 121.5655211],
              [25.0064948, 121.5650008],
              [25.0058969, 121.5658725],
              [25.0065192, 121.5663902],
              [25.0071122, 121.5655211],
            ],
          },
          {
            name: '文山-家家社區(私人土地)',
            coordinates: [
              [24.9960615, 121.5601685],
              [24.9933897, 121.5595382],
              [24.9931527, 121.5598855],
              [24.9932487, 121.5609463],
              [24.993747, 121.5637626],
              [24.996076, 121.5641006],
              [24.9962682, 121.5608145],
              [24.9960615, 121.5601685],
            ],
          },
          {
            name: '文山-新光路一段32巷周邊(私人土地)',
            coordinates: [
              [24.99024, 121.5727936],
              [24.9894766, 121.572748],
              [24.9889466, 121.5729491],
              [24.9891362, 121.573566],
              [24.9902643, 121.57355],
              [24.99024, 121.5727936],
            ],
          },
          {
            name: '文山-木柵路一段1號',
            coordinates: [
              [24.9885782, 121.5421999],
              [24.9883399, 121.5422026],
              [24.9883739, 121.5426048],
              [24.9885927, 121.5425834],
              [24.9885782, 121.5421999],
            ],
          },
          {
            name: '文山-萬美社區',
            coordinates: [
              [25.003581, 121.562333],
              [25.003479, 121.56198],
              [25.003318, 121.561708],
              [25.003004, 121.561512],
              [25.002689, 121.56138],
              [25.002429, 121.56142],
              [25.002344, 121.56207],
              [25.002335, 121.562517],
              [25.00234, 121.562783],
              [25.002375, 121.562941],
              [25.0024159, 121.5630856],
              [25.0027729, 121.5634871],
              [25.0033511, 121.5632982],
              [25.00352, 121.562786],
              [25.00358, 121.562586],
              [25.003581, 121.562333],
            ],
          },
          {
            name: '文山-試院路 (非營運範圍)',
            coordinates: [
              [24.990941, 121.545869],
              [24.989696, 121.546502],
              [24.990474, 121.54853],
              [24.991661, 121.547918],
              [24.990941, 121.545869],
            ],
          },
          {
            name: '新店-大潤發(碧潭店)',
            coordinates: [
              [24.9624909, 121.5369804],
              [24.9614234, 121.5370931],
              [24.9615157, 121.5376684],
              [24.9625103, 121.5376094],
              [24.9624909, 121.5369804],
            ],
          },
          {
            name: '新店-家樂福(新店店)',
            coordinates: [
              [24.9773366, 121.5458606],
              [24.976984, 121.5452303],
              [24.9761695, 121.5458096],
              [24.9765366, 121.5464614],
              [24.9773366, 121.5458606],
            ],
          },
          {
            name: '新店-捷運大坪林站周邊',
            coordinates: [
              [24.9839134, 121.5412223],
              [24.9830698, 121.5413645],
              [24.9830795, 121.5419975],
              [24.9838988, 121.541866],
              [24.9839134, 121.5412223],
            ],
          },
          {
            name: '新店-法務部調查局',
            coordinates: [
              [24.9698637, 121.5384927],
              [24.9694479, 121.5382245],
              [24.9692509, 121.5384632],
              [24.9695135, 121.5387073],
              [24.9698637, 121.5384927],
            ],
          },
          {
            name: '新莊-COSTCO好事多',
            coordinates: [
              [25.027556, 121.433245],
              [25.026277, 121.43333],
              [25.026554, 121.4348],
              [25.027823, 121.434768],
              [25.027556, 121.433245],
            ],
          },
          {
            name: '新莊-四維市場',
            coordinates: [
              [25.023272, 121.423016],
              [25.020987, 121.421943],
              [25.020404, 121.424062],
              [25.022494, 121.425178],
              [25.023272, 121.423016],
            ],
          },
          {
            name: '新莊-後港新公園地下停車場',
            coordinates: [
              [25.026156, 121.423917],
              [25.025451, 121.424271],
              [25.025781, 121.424936],
              [25.026486, 121.424512],
              [25.026156, 121.423917],
            ],
          },
          {
            name: '新莊-新莊區四維立體停車場',
            coordinates: [
              [25.024208, 121.423697],
              [25.02372, 121.423485],
              [25.023583, 121.423858],
              [25.024086, 121.424059],
              [25.024208, 121.423697],
            ],
          },
          {
            name: '新莊-新莊國中',
            coordinates: [
              [25.035748, 121.451366],
              [25.03515, 121.451479],
              [25.035398, 121.452761],
              [25.036054, 121.45267],
              [25.035748, 121.451366],
            ],
          },
          {
            name: '新莊-新莊國民運動中心',
            coordinates: [
              [25.039912, 121.447451],
              [25.039154, 121.447773],
              [25.039806, 121.449479],
              [25.040593, 121.448985],
              [25.039912, 121.447451],
            ],
          },
          {
            name: '新莊-歐洲村',
            coordinates: [
              [25.0527434, 121.4428601],
              [25.0493366, 121.442887],
              [25.0493366, 121.44433],
              [25.0527385, 121.4443461],
              [25.0527434, 121.4428601],
            ],
          },
          {
            name: '新莊-私立輔仁大學',
            coordinates: [
              [25.0326034, 121.4340547],
              [25.0326316, 121.4344969],
              [25.0328065, 121.4356613],
              [25.0330365, 121.4356572],
              [25.0329648, 121.4352854],
              [25.033494, 121.4353506],
              [25.0360883, 121.4355755],
              [25.0386667, 121.4310547],
              [25.0392803, 121.4315187],
              [25.0394273, 121.4312384],
              [25.0364382, 121.4289558],
              [25.0326034, 121.4340547],
            ],
          },
          {
            name: '新莊-衛生福利部臺北醫院',
            coordinates: [
              [25.043379, 121.458205],
              [25.042592, 121.458189],
              [25.042635, 121.459551],
              [25.0432328, 121.4595204],
              [25.0432401, 121.45967],
              [25.0434029, 121.4596722],
              [25.043379, 121.458205],
            ],
          },
          {
            name: '松山-京華城百貨(停業)',
            coordinates: [
              [25.048541, 121.560991],
              [25.0482219, 121.5610439],
              [25.0482313, 121.5612701],
              [25.0475933, 121.5613432],
              [25.047577, 121.561123],
              [25.04734, 121.561154],
              [25.047004, 121.561189],
              [25.046822, 121.561204],
              [25.046737, 121.561215],
              [25.046653, 121.561226],
              [25.046904, 121.561818],
              [25.047048, 121.562138],
              [25.04717, 121.562415],
              [25.04718, 121.562476],
              [25.047264, 121.562704],
              [25.047362, 121.562976],
              [25.04802, 121.562871],
              [25.04835, 121.562815],
              [25.048515, 121.562794],
              [25.048721, 121.562764],
              [25.048634, 121.561884],
              [25.048592, 121.561442],
              [25.048574, 121.561259],
              [25.048541, 121.560991],
            ],
          },
          {
            name: '松山-家樂福(三民店)',
            coordinates: [
              [25.0618437, 121.56343],
              [25.0615813, 121.5634568],
              [25.0616384, 121.5644372],
              [25.0619069, 121.5644144],
              [25.0618437, 121.56343],
            ],
          },
          {
            name: '松山-小巨蛋/體育館/網球場',
            coordinates: [
              [25.0516384, 121.5491162],
              [25.0492121, 121.5490766],
              [25.0491724, 121.5494849],
              [25.0483736, 121.5494711],
              [25.048362, 121.551392],
              [25.048242, 121.551528],
              [25.0482376, 121.5525082],
              [25.0493069, 121.5525545],
              [25.049309, 121.552434],
              [25.050528, 121.552493],
              [25.05052, 121.552681],
              [25.050828, 121.552691],
              [25.051087, 121.552711],
              [25.051605, 121.552731],
              [25.051623, 121.550978],
              [25.051641, 121.550077],
              [25.0516384, 121.5491162],
            ],
          },
          {
            name: '板橋-中山國中專用停車場',
            coordinates: [
              [25.0187614, 121.4671346],
              [25.0180955, 121.4667538],
              [25.0168267, 121.468229],
              [25.0173031, 121.4689103],
              [25.0179399, 121.4682451],
              [25.0187614, 121.4671346],
            ],
          },
          {
            name: '板橋-亞東(醫院)地下停車場',
            coordinates: [
              [24.9972927, 121.4523833],
              [24.9961793, 121.4519918],
              [24.9956396, 121.4528447],
              [24.9970739, 121.453526],
              [24.9972927, 121.4523833],
            ],
          },
          {
            name: '板橋-亞東技術學院第二停車場',
            coordinates: [
              [24.997329, 121.4539215],
              [24.9962934, 121.4532241],
              [24.995603, 121.4538786],
              [24.9958267, 121.4546189],
              [24.997329, 121.4539215],
            ],
          },
          {
            name: '板橋-凱撒大飯店',
            coordinates: [
              [25.0113686, 121.4620572],
              [25.0109286, 121.462599],
              [25.0117089, 121.4632347],
              [25.0120662, 121.4627385],
              [25.0113686, 121.4620572],
            ],
          },
          {
            name: '板橋-四維公園地下停車場',
            coordinates: [
              [25.0245364, 121.4632757],
              [25.0250565, 121.4642467],
              [25.0263301, 121.4634045],
              [25.0258391, 121.4624496],
              [25.0245364, 121.4632757],
            ],
          },
          {
            name: '板橋-府後立體停車場',
            coordinates: [
              [25.0104994, 121.4583775],
              [25.0102126, 121.4581307],
              [25.0096195, 121.4579215],
              [25.009532, 121.4590909],
              [25.0096681, 121.4593055],
              [25.0104994, 121.4583775],
            ],
          },
          {
            name: '板橋-捷運府中站',
            coordinates: [
              [25.0086693, 121.4590979],
              [25.007821, 121.4583442],
              [25.0075463, 121.4584703],
              [25.0085502, 121.4593125],
              [25.0086693, 121.4590979],
            ],
          },
          {
            name: '板橋-捷運江子翠站停車場',
            coordinates: [
              [25.029755, 121.472338],
              [25.0293273, 121.4719558],
              [25.0291316, 121.4723031],
              [25.0295387, 121.4726224],
              [25.029755, 121.472338],
            ],
          },
          {
            name: '板橋-新北市府、板橋-大遠百、板橋-特專三臨時平面停車場',
            coordinates: [
              [25.0153713, 121.4667263],
              [25.0155527, 121.4663359],
              [25.0164422, 121.4656117],
              [25.0170402, 121.4646085],
              [25.0163742, 121.4641686],
              [25.0134438, 121.4620928],
              [25.0124642, 121.4634983],
              [25.0114069, 121.4645927],
              [25.0131862, 121.4685381],
              [25.0153713, 121.4667263],
            ],
          },
          {
            name: '板橋-新北市立圖書館(總館)',
            coordinates: [
              [25.001494, 121.4547833],
              [25.0004122, 121.4545418],
              [25.0001594, 121.4549362],
              [25.0012995, 121.455832],
              [25.0017978, 121.4551829],
              [25.001494, 121.4547833],
            ],
          },
          {
            name: '板橋-新北市立殯儀館',
            coordinates: [
              [25.0286214, 121.4563793],
              [25.0272166, 121.4570445],
              [25.0276103, 121.4577902],
              [25.0286894, 121.4571411],
              [25.0286214, 121.4563793],
            ],
          },
          {
            name: '板橋-板橋體育館',
            coordinates: [
              [25.0131715, 121.4581202],
              [25.0135701, 121.4581631],
              [25.0136382, 121.4565431],
              [25.0122673, 121.4563768],
              [25.0131715, 121.4581202],
            ],
          },
          {
            name: '板橋-江翠國中',
            coordinates: [
              [25.0285863, 121.4662072],
              [25.0270455, 121.466969],
              [25.027726, 121.468353],
              [25.0293543, 121.4673391],
              [25.0285863, 121.4662072],
            ],
          },
          {
            name: '板橋-江翠國小',
            coordinates: [
              [25.0332669, 121.473798],
              [25.032324, 121.4726339],
              [25.0315511, 121.4733635],
              [25.0326448, 121.4744739],
              [25.0332669, 121.473798],
            ],
          },
          {
            name: '板橋-福利站停車場',
            coordinates: [
              [25.0209701, 121.4648939],
              [25.0200125, 121.4643574],
              [25.0193417, 121.4652104],
              [25.020275, 121.465999],
              [25.0209701, 121.4648939],
            ],
          },
          {
            name: '板橋-華翠橋下平面停車場(A、B、G區)',
            coordinates: [
              [25.0263006, 121.482197],
              [25.0260187, 121.4822346],
              [25.0262909, 121.4832431],
              [25.026602, 121.4831358],
              [25.0263006, 121.482197],
            ],
          },
          {
            name: '板橋-華翠橋下平面停車場(H、I、K區)',
            coordinates: [
              [25.0200559, 121.4731609],
              [25.0198517, 121.4732762],
              [25.020593, 121.4743437],
              [25.0207559, 121.4741801],
              [25.0200559, 121.4731609],
            ],
          },
          {
            name: '板橋-遠東通訊園區',
            coordinates: [
              [24.999227, 121.4557245],
              [24.9982012, 121.455349],
              [24.9976032, 121.4569798],
              [24.9987263, 121.4575377],
              [24.999227, 121.4557245],
            ],
          },
          {
            name: '板橋-領袖天下工業園區停車場',
            coordinates: [
              [24.996212, 121.4556644],
              [24.9965523, 121.4564798],
              [24.9976754, 121.4563618],
              [24.9975053, 121.4554123],
              [24.996212, 121.4556644],
            ],
          },
          {
            name: '板橋-麗寶百貨周邊',
            coordinates: [
              [25.012005, 121.4611031],
              [25.0114265, 121.4619131],
              [25.0122578, 121.4627178],
              [25.0124158, 121.462471],
              [25.0118033, 121.4619051],
              [25.0122213, 121.461264],
              [25.012005, 121.4611031],
            ],
          },
          {
            name: '永和-永和國小',
            coordinates: [
              [25.0018924, 121.517501],
              [25.0018827, 121.517151],
              [25.0016651, 121.5171577],
              [25.0016785, 121.5175184],
              [25.0018924, 121.517501],
            ],
          },
          {
            name: '永和-網溪國小',
            coordinates: [
              [25.0138711, 121.5199223],
              [25.0137234, 121.520116],
              [25.0138079, 121.5202066],
              [25.0139562, 121.5200208],
              [25.0138711, 121.5199223],
            ],
          },
          {
            name: '永和-頂溪國小',
            coordinates: [
              [25.0156879, 121.5107632],
              [25.0154765, 121.5106224],
              [25.0152662, 121.5110288],
              [25.0154826, 121.5111575],
              [25.0156879, 121.5107632],
            ],
          },
          {
            name: '萬華-家樂福(桂林店)',
            coordinates: [
              [25.038149, 121.5053075],
              [25.037714, 121.5052203],
              [25.0375232, 121.506328],
              [25.037979, 121.5063026],
              [25.0380178, 121.5060653],
              [25.0381029, 121.5060867],
              [25.0381697, 121.5057447],
              [25.0380834, 121.5057219],
              [25.038149, 121.5053075],
            ],
          },
          {
            name: '萬華-民和街132號(私人土地)',
            coordinates: [
              [25.0256955, 121.4961535],
              [25.0254792, 121.4958182],
              [25.024857, 121.4962447],
              [25.0250976, 121.4968911],
              [25.0257393, 121.4965987],
              [25.025591, 121.4962045],
              [25.0256955, 121.4961535],
            ],
          },
          {
            name: '萬華-艋舺公園地下停車場',
            coordinates: [
              [25.0367057, 121.4993769],
              [25.0352598, 121.4992911],
              [25.0352403, 121.5002647],
              [25.0366547, 121.500321],
              [25.0367057, 121.4993769],
            ],
          },
          {
            name: '萬華-萬華行政中心地下停車場',
            coordinates: [
              [25.0350629, 121.4994949],
              [25.0346571, 121.4995083],
              [25.0347154, 121.500431],
              [25.0350848, 121.5003908],
              [25.0350629, 121.4994949],
            ],
          },
          {
            name: '蘆洲-Times蘆洲三民路停車場',
            coordinates: [
              [25.0912849, 121.4635984],
              [25.0908428, 121.4633678],
              [25.0906217, 121.4638398],
              [25.0910784, 121.4641027],
              [25.0912849, 121.4635984],
            ],
          },
          {
            name: '蘆洲-仁愛立體停車場',
            coordinates: [
              [25.0912224, 121.4711958],
              [25.0910135, 121.4707559],
              [25.0904646, 121.4710563],
              [25.0906492, 121.471523],
              [25.0912224, 121.4711958],
            ],
          },
          {
            name: '蘆洲-捷運三民高中站停車場',
            coordinates: [
              [25.0860718, 121.4722771],
              [25.0858362, 121.4718345],
              [25.0854718, 121.4722073],
              [25.0855884, 121.4728028],
              [25.0860718, 121.4722771],
            ],
          },
          {
            name: '蘆洲-捷運蘆洲站停車場',
            coordinates: [
              [25.0917568, 121.4642508],
              [25.0913949, 121.4645137],
              [25.0912127, 121.4649992],
              [25.0913414, 121.4656027],
              [25.0921236, 121.4651118],
              [25.0917568, 121.4642508],
            ],
          },
          {
            name: '蘆洲-時上京宴大樓',
            coordinates: [
              [25.0867974, 121.4597502],
              [25.0866177, 121.4592996],
              [25.0861999, 121.4595357],
              [25.0864233, 121.4599487],
              [25.0867974, 121.4597502],
            ],
          },
          {
            name: '蘆洲-蘆洲國民運動中心/鷺江國小',
            coordinates: [
              [25.0914153, 121.4619234],
              [25.0901715, 121.4613548],
              [25.0900112, 121.4619503],
              [25.0911335, 121.4623526],
              [25.0914153, 121.4619234],
            ],
          },
          {
            name: '蘆洲-頂富御寶 no.6 大樓',
            coordinates: [
              [25.0863058, 121.4613021],
              [25.0863981, 121.4615407],
              [25.086579, 121.4614295],
              [25.086494, 121.4612136],
              [25.0863058, 121.4613021],
            ],
          },
        ],
        colorZone: '#00000033',
        colorBorderLine: '#A2A2A233',
      },
      {
        type: 'WARNING',
        zIndex: 1,
        scooterGroupIds: [0, 20],
        title: '私人禁停區域',
        description: '會視情況收取調車費用',
        zones: [
          {
            name: '國立台灣大學',
            coordinates: [
              [25.0124806, 121.5370255],
              [25.0117996, 121.5377225],
              [25.0190626, 121.5463225],
              [25.0204346, 121.5448935],
              [25.0207616, 121.5444095],
              [25.0209166, 121.5437155],
              [25.0209426, 121.5432775],
              [25.0209276, 121.5420135],
              [25.0209726, 121.5407715],
              [25.0209846, 121.5395975],
              [25.0210466, 121.5392695],
              [25.0211236, 121.5390105],
              [25.0212956, 121.5385535],
              [25.0214286, 121.5383515],
              [25.0215316, 121.5381545],
              [25.0216126, 121.5380095],
              [25.0217416, 121.5378745],
              [25.0219426, 121.5376045],
              [25.0221056, 121.5370205],
              [25.0220856, 121.5346655],
              [25.0162716, 121.5330455],
              [25.0124806, 121.5370255],
            ],
          },
          {
            name: '國立台灣師範學院',
            coordinates: [
              [25.010807, 121.536256],
              [25.009543, 121.534046],
              [25.00652, 121.533874],
              [25.00625, 121.53451],
              [25.006286, 121.535135],
              [25.006126, 121.53514],
              [25.006014, 121.536063],
              [25.006641, 121.536358],
              [25.007016, 121.536598],
              [25.007205, 121.536784],
              [25.007655, 121.537308],
              [25.008004, 121.53731],
              [25.008347, 121.537399],
              [25.008682, 121.537161],
              [25.009006, 121.536954],
              [25.009146, 121.536881],
              [25.00932, 121.536828],
              [25.009999, 121.536555],
              [25.010679, 121.536356],
              [25.010807, 121.536256],
            ],
          },
        ],
        colorZone: '#F5822333',
        colorBorderLine: '#F58223FF',
      },
      {
        type: 'FORBIDDEN',
        zIndex: 2,
        scooterGroupIds: [20, 0],
        title: '非營運範圍',
        description: '非營運範圍，此處禁止還車',
        zones: [
          {
            name: '三民-和盛自由停車場',
            coordinates: [
              [22.647542, 120.308194],
              [22.647371, 120.308181],
              [22.647342, 120.308868],
              [22.647485, 120.308884],
              [22.647542, 120.308194],
            ],
          },
          {
            name: '三民-大眾建國停車場',
            coordinates: [
              [22.638034, 120.303925],
              [22.637722, 120.303898],
              [22.637668, 120.30503],
              [22.63795, 120.305084],
              [22.638034, 120.303925],
            ],
          },
          {
            name: '三民-美奇萊影城停車場',
            coordinates: [
              [22.644554, 120.30924],
              [22.643715, 120.309189],
              [22.64369, 120.309458],
              [22.644532, 120.3095],
              [22.644554, 120.30924],
            ],
          },
          {
            name: '三民-高醫停車場',
            coordinates: [
              [22.645187, 120.308934],
              [22.645128, 120.309428],
              [22.646182, 120.309556],
              [22.646222, 120.309036],
              [22.645187, 120.308934],
            ],
          },
          {
            name: '前金-中正骨科停車場',
            coordinates: [
              [22.63054, 120.298098],
              [22.630275, 120.298205],
              [22.630488, 120.298779],
              [22.630735, 120.298672],
              [22.63054, 120.298098],
            ],
          },
          {
            name: '前金-前金立體停車場',
            coordinates: [
              [22.627974, 120.292461],
              [22.627264, 120.292711],
              [22.627519, 120.29347],
              [22.628168, 120.293196],
              [22.627974, 120.292461],
            ],
          },
          {
            name: '左營-漢神巨蛋',
            coordinates: [
              [22.670608, 120.302318],
              [22.669692, 120.301535],
              [22.669474, 120.301937],
              [22.668232, 120.300899],
              [22.668282, 120.300387],
              [22.667777, 120.300263],
              [22.667722, 120.303348],
              [22.670375, 120.303423],
              [22.670608, 120.302318],
            ],
          },
          {
            name: '楠梓-陽光停車場',
            coordinates: [
              [22.727169, 120.324377],
              [22.726845, 120.324256],
              [22.726805, 120.32446],
              [22.727062, 120.324522],
              [22.727169, 120.324377],
            ],
          },
          {
            name: '鼓山-好市多',
            coordinates: [
              [22.656668, 120.306889],
              [22.65497, 120.306677],
              [22.655143, 120.307787],
              [22.656519, 120.307986],
              [22.656668, 120.306889],
            ],
          },
        ],
        colorZone: '#55555533',
        colorBorderLine: '#555555FF',
      },
      {
        type: 'WARNING',
        zIndex: 1,
        scooterGroupIds: [20, 0],
        title: '違法停車區域',
        description: '將有機會收到違規罰單',
        zones: [
          {
            name: '台北車站週邊',
            coordinates: [
              [25.049837, 121.517446],
              [25.050692, 121.513756],
              [25.050571, 121.513745],
              [25.049939, 121.51662],
              [25.048982, 121.516357],
              [25.049171, 121.51536],
              [25.049011, 121.515322],
              [25.0487766, 121.5163014],
              [25.048629, 121.5164221],
              [25.048275, 121.51856],
              [25.0482356, 121.518855],
              [25.048846, 121.519329],
              [25.049312, 121.519673],
              [25.049808, 121.517564],
              [25.050629, 121.517779],
              [25.050658, 121.51765],
              [25.049837, 121.517446],
            ],
          },
          {
            name: '大安區新生南路一段6-5號',
            coordinates: [
              [25.042518, 121.532714],
              [25.042516, 121.532687],
              [25.042491, 121.532976],
              [25.044457, 121.533089],
              [25.044394, 121.532781],
              [25.042518, 121.532714],
            ],
          },
        ],
        colorZone: '#F5822333',
        colorBorderLine: '#F58223FF',
      },
      {
        type: 'ADVERTISEMENT',
        zIndex: 5,
        scooterGroupIds: [0],
        title: '',
        description: '',
        zones: [
          {
            name: 'rf-kaohsiung',
            coordinates: [
              [22.4691332, 120.4262293],
              [22.5154454, 120.4324091],
              [22.5401811, 120.4385889],
              [22.5737893, 120.455755],
              [22.5978806, 120.4550684],
              [22.6149956, 120.4447687],
              [22.6713966, 120.4495752],
              [22.7081399, 120.4653681],
              [22.770834, 120.4612482],
              [22.8050186, 120.5168665],
              [22.8581777, 120.5869043],
              [22.9283918, 120.5958307],
              [22.9239649, 120.5079401],
              [22.9694912, 120.5299128],
              [22.9966732, 120.4756678],
              [23.0099462, 120.4564417],
              [22.9347156, 120.4056299],
              [22.899931, 120.347265],
              [22.9138459, 120.2415216],
              [22.927127, 120.1955164],
              [22.9170082, 120.1728571],
              [22.9100511, 120.1632441],
              [22.7239744, 120.2449549],
              [22.6783659, 120.2607477],
              [22.664427, 120.2415216],
              [22.6162633, 120.2552545],
              [22.5319364, 120.3191126],
              [22.4742093, 120.3932703],
              [22.4691332, 120.4262293],
            ],
          },
        ],
        colorZone: '#00000000',
        colorBorderLine: '#00000000',
      },
      {
        type: 'OPERATION',
        zIndex: 0,
        scooterGroupIds: [23],
        title: '',
        description: '',
        zones: [
          {
            name: '北新路、淡金路一段上段',
            coordinates: [
              [25.180941, 121.452323],
              [25.179936, 121.45044],
              [25.181158, 121.449326],
              [25.180886, 121.447137],
              [25.179585, 121.446043],
              [25.17877, 121.446086],
              [25.1783, 121.445038],
              [25.178936, 121.443585],
              [25.176731, 121.441966],
              [25.1756919, 121.4432965],
              [25.175585, 121.443876],
              [25.179818, 121.453382],
              [25.178605, 121.454422],
              [25.178954, 121.454991],
              [25.1800513, 121.4543314],
              [25.180498, 121.453661],
              [25.180941, 121.452323],
            ],
          },
          {
            name: '學府路',
            coordinates: [
              [25.171222, 121.448938],
              [25.167852, 121.446986],
              [25.167483, 121.447758],
              [25.170998, 121.449614],
              [25.171222, 121.448938],
            ],
          },
          {
            name: '巧立門市',
            coordinates: [
              [25.178033, 121.450955],
              [25.177373, 121.45058],
              [25.176523, 121.453117],
              [25.177258, 121.453423],
              [25.177839, 121.451626],
              [25.178096, 121.450993],
              [25.178033, 121.450955],
            ],
          },
          {
            name: '水源街二段',
            coordinates: [
              [25.173702, 121.448541],
              [25.173091, 121.448831],
              [25.1737171, 121.4510628],
              [25.1744671, 121.4530358],
              [25.1750686, 121.4529037],
              [25.174469, 121.450912],
              [25.173702, 121.448541],
            ],
          },
          {
            name: '淡金路一段下段',
            coordinates: [
              [25.177381, 121.455366],
              [25.176012, 121.456139],
              [25.176352, 121.457072],
              [25.177799, 121.456171],
              [25.177381, 121.455366],
            ],
          },
          {
            name: '英專路',
            coordinates: [
              [25.169372, 121.445266],
              [25.168812, 121.445164],
              [25.168766, 121.445403],
              [25.169348, 121.445507],
              [25.169372, 121.445266],
            ],
          },
        ],
        colorZone: '#87C03600',
        colorBorderLine: '#87C036FF',
      },
      {
        type: 'WARNING',
        zIndex: 1,
        scooterGroupIds: [0, 20],
        title: '高開罰區域',
        description: '非法停放可能收到違規罰單',
        zones: [
          {
            name: '信義計劃區，延伸松仁路95巷',
            coordinates: [
              [25.0367462, 121.5684551],
              [25.0365576, 121.5684494],
              [25.036503, 121.57075],
              [25.03669, 121.57075],
              [25.0367462, 121.5684551],
            ],
          },
          {
            name: '信義計畫區高風險-1',
            coordinates: [
              [25.038574, 121.565456],
              [25.036842, 121.565398],
              [25.036787, 121.566539],
              [25.038532, 121.566583],
              [25.038574, 121.565456],
            ],
          },
          {
            name: '信義計畫區高風險-2',
            coordinates: [
              [25.039023, 121.567506],
              [25.036437, 121.567465],
              [25.036444, 121.567791],
              [25.03901, 121.56785],
              [25.039023, 121.567506],
            ],
          },
          {
            name: '信義運動中心',
            coordinates: [
              [25.032085, 121.566394],
              [25.031569, 121.566394],
              [25.03154, 121.567489],
              [25.030335, 121.567462],
              [25.03034, 121.567805],
              [25.03206, 121.567843],
              [25.032085, 121.566394],
            ],
          },
          {
            name: '南港車站(忠孝東路七段)',
            coordinates: [
              [25.051992, 121.605132],
              [25.051637, 121.605159],
              [25.052002, 121.608254],
              [25.052371, 121.608206],
              [25.051992, 121.605132],
            ],
          },
          {
            name: '士林商圈(文林加油站)',
            coordinates: [
              [25.087759, 121.525998],
              [25.087149, 121.525706],
              [25.086986, 121.526079],
              [25.087591, 121.526385],
              [25.087759, 121.525998],
            ],
          },
          {
            name: '師大公館商圈(古亭-師大)',
            coordinates: [
              [25.026974, 121.520662],
              [25.010417, 121.535557],
              [25.010807, 121.536256],
              [25.010679, 121.536356],
              [25.010534, 121.536412],
              [25.011477, 121.538022],
              [25.011819, 121.537701],
              [25.016272, 121.533046],
              [25.022105, 121.534644],
              [25.022658, 121.534942],
              [25.022648, 121.533912],
              [25.017378, 121.532786],
              [25.021413, 121.528688],
              [25.026799, 121.529095],
              [25.026916, 121.527958],
              [25.022327, 121.527636],
              [25.027441, 121.523023],
              [25.026974, 121.520662],
            ],
          },
          {
            name: '東區商圈(市民北)',
            coordinates: [
              [25.044832, 121.542366],
              [25.044159, 121.541884],
              [25.04413, 121.547688],
              [25.05035, 121.547742],
              [25.050389, 121.54197],
              [25.047033, 121.541935],
              [25.044832, 121.542366],
            ],
          },
          {
            name: '松山車站(八德路四段)',
            coordinates: [
              [25.050163, 121.572248],
              [25.049721, 121.572248],
              [25.049789, 121.577849],
              [25.050226, 121.577843],
              [25.050163, 121.572248],
            ],
          },
          {
            name: '民生西路(限時停車格)',
            coordinates: [
              [25.057217, 121.511435],
              [25.056726, 121.511435],
              [25.0568, 121.513801],
              [25.057295, 121.513774],
              [25.057217, 121.511435],
            ],
          },
          {
            name: '永吉商圈(忠孝東路側)',
            coordinates: [
              [25.041688, 121.567263],
              [25.041046, 121.567226],
              [25.041035, 121.568049],
              [25.041025, 121.568838],
              [25.041649, 121.568851],
              [25.041688, 121.567263],
            ],
          },
          {
            name: '臺北車站(市民大道一段)',
            coordinates: [
              [25.048898, 121.515777],
              [25.048599, 121.515731],
              [25.047827, 121.520688],
              [25.048366, 121.520961],
              [25.048752, 121.519263],
              [25.048221, 121.518863],
              [25.048612, 121.516406],
              [25.048773, 121.516296],
              [25.048898, 121.515777],
            ],
          },
          {
            name: '萬華-西寧停車場周邊',
            coordinates: [
              [25.0445876, 121.5052258],
              [25.044279, 121.505136],
              [25.0440445, 121.5062679],
              [25.0443507, 121.5063443],
              [25.0445876, 121.5052258],
            ],
          },
        ],
        colorZone: '#F5822333',
        colorBorderLine: '#F58223FF',
      },
      {
        type: 'OPERATION',
        zIndex: 0,
        scooterGroupIds: [20, 0],
        title: '',
        description: '',
        zones: [
          {
            name: '內湖',
            coordinates: [
              [25.084909, 121.584663],
              [25.085127, 121.579873],
              [25.084239, 121.577883],
              [25.086532, 121.571703],
              [25.088776, 121.567992],
              [25.08819, 121.56737],
              [25.087774, 121.567259],
              [25.087738, 121.566334],
              [25.088175, 121.566327],
              [25.089039, 121.562278],
              [25.089185, 121.559228],
              [25.089228, 121.558948],
              [25.089175, 121.558603],
              [25.088688, 121.557497],
              [25.086141, 121.552228],
              [25.085108, 121.547],
              [25.085432, 121.53754],
              [25.085486, 121.536528],
              [25.085487, 121.536426],
              [25.085429, 121.536358],
              [25.085322, 121.536347],
              [25.085137, 121.53648],
              [25.083692, 121.537231],
              [25.083105, 121.53754],
              [25.082975, 121.537628],
              [25.082917, 121.53772],
              [25.08286, 121.537887],
              [25.082487, 121.538827],
              [25.081659, 121.540852],
              [25.079222, 121.540758],
              [25.078043, 121.540679],
              [25.077803, 121.540672],
              [25.077524, 121.540793],
              [25.077356, 121.541412],
              [25.077286, 121.542461],
              [25.077242, 121.544215],
              [25.0770383, 121.5487001],
              [25.0774781, 121.5486815],
              [25.0773203, 121.5562222],
              [25.0798324, 121.5562328],
              [25.0797862, 121.5579387],
              [25.0771845, 121.5579601],
              [25.0766162, 121.5604814],
              [25.076222, 121.5634637],
              [25.0773283, 121.5684602],
              [25.076809, 121.569127],
              [25.076678, 121.569665],
              [25.076241, 121.570438],
              [25.075614, 121.57106],
              [25.074132, 121.572004],
              [25.072752, 121.572669],
              [25.071965, 121.572787],
              [25.071412, 121.572819],
              [25.069274, 121.572712],
              [25.067913, 121.572164],
              [25.066591, 121.571869],
              [25.0664166, 121.5722471],
              [25.0626359, 121.5727689],
              [25.0604636, 121.5731086],
              [25.0584828, 121.5733396],
              [25.0577063, 121.573417],
              [25.056279, 121.573849],
              [25.055395, 121.574262],
              [25.054681, 121.574619],
              [25.053915, 121.575054],
              [25.053319, 121.575551],
              [25.052982, 121.575907],
              [25.052878, 121.576177],
              [25.052931, 121.576687],
              [25.053832, 121.581297],
              [25.054205, 121.581714],
              [25.054442, 121.582389],
              [25.054879, 121.583399],
              [25.05662, 121.587198],
              [25.058262, 121.589964],
              [25.059103, 121.59094],
              [25.059788, 121.591959],
              [25.06042, 121.592871],
              [25.060702, 121.593911],
              [25.060731, 121.594426],
              [25.0608, 121.59492],
              [25.060605, 121.59573],
              [25.060275, 121.596648],
              [25.059808, 121.598102],
              [25.059672, 121.599255],
              [25.05975, 121.601143],
              [25.059974, 121.602967],
              [25.060314, 121.603096],
              [25.060896, 121.603096],
              [25.061543, 121.605789],
              [25.062214, 121.606958],
              [25.062788, 121.608128],
              [25.06312, 121.609318],
              [25.062752, 121.609608],
              [25.062325, 121.609887],
              [25.062383, 121.610262],
              [25.062718, 121.61057],
              [25.063074, 121.61094],
              [25.063478, 121.611342],
              [25.064175, 121.611818],
              [25.064745, 121.612617],
              [25.065131, 121.613427],
              [25.066335, 121.617062],
              [25.066499, 121.617413],
              [25.066619, 121.617444],
              [25.066739, 121.61741],
              [25.066999, 121.617279],
              [25.067801, 121.617569],
              [25.068175, 121.618481],
              [25.068544, 121.619321],
              [25.068932, 121.619967],
              [25.069374, 121.620542],
              [25.069932, 121.621118],
              [25.070194, 121.621324],
              [25.070498, 121.621286],
              [25.071318, 121.621233],
              [25.071705, 121.621275],
              [25.07188, 121.621246],
              [25.072035, 121.621173],
              [25.072135, 121.620992],
              [25.072245, 121.620832],
              [25.072387, 121.620716],
              [25.072652, 121.620656],
              [25.073297, 121.620578],
              [25.073469, 121.620635],
              [25.074138, 121.621083],
              [25.074733, 121.621444],
              [25.0749, 121.621652],
              [25.075297, 121.622673],
              [25.075363, 121.622815],
              [25.075452, 121.622832],
              [25.075872, 121.622796],
              [25.076555, 121.622681],
              [25.076688, 121.622378],
              [25.077044, 121.62098],
              [25.077716, 121.618133],
              [25.077999, 121.617347],
              [25.077644, 121.614514],
              [25.076829, 121.612769],
              [25.076718, 121.610943],
              [25.075566, 121.610633],
              [25.074534, 121.609968],
              [25.074471, 121.608707],
              [25.075729, 121.608691],
              [25.075736, 121.608189],
              [25.077143, 121.608224],
              [25.081418, 121.607598],
              [25.082981, 121.606199],
              [25.083275, 121.604871],
              [25.083752, 121.602978],
              [25.084453, 121.602185],
              [25.085499, 121.602001],
              [25.086564, 121.60208],
              [25.086997, 121.602534],
              [25.088752, 121.602773],
              [25.089561, 121.601249],
              [25.090165, 121.60017],
              [25.09036, 121.599887],
              [25.090438, 121.599696],
              [25.09036, 121.599456],
              [25.090234, 121.599013],
              [25.089759, 121.598226],
              [25.08873, 121.596482],
              [25.08788, 121.594861],
              [25.087596, 121.594384],
              [25.087555, 121.594228],
              [25.08777, 121.593979],
              [25.088567, 121.593429],
              [25.089801, 121.592324],
              [25.090224, 121.59197],
              [25.090299, 121.591863],
              [25.090297, 121.591734],
              [25.090052, 121.591511],
              [25.088446, 121.59028],
              [25.088955, 121.587799],
              [25.089606, 121.584662],
              [25.089706, 121.584185],
              [25.089675, 121.583714],
              [25.089155, 121.583465],
              [25.088558, 121.583473],
              [25.0884, 121.583826],
              [25.088106, 121.584801],
              [25.087803, 121.586088],
              [25.087616, 121.586945],
              [25.085149, 121.589597],
              [25.083227, 121.588783],
              [25.084909, 121.584663],
            ],
          },
          {
            name: '台北主營運範圍',
            coordinates: [
              [25.092786, 121.497645],
              [25.091424, 121.499112],
              [25.090422, 121.501168],
              [25.087974, 121.503774],
              [25.08676, 121.504901],
              [25.085595, 121.50563],
              [25.084068, 121.506575],
              [25.082889, 121.507211],
              [25.081587, 121.507654],
              [25.079356, 121.508232],
              [25.076373, 121.509364],
              [25.075102, 121.509009],
              [25.073821, 121.508955],
              [25.070394, 121.509276],
              [25.066833, 121.50889],
              [25.063445, 121.50877],
              [25.060793, 121.508392],
              [25.057706, 121.508424],
              [25.054146, 121.507132],
              [25.052418, 121.506836],
              [25.050874, 121.50639],
              [25.049777, 121.50594],
              [25.048797, 121.505319],
              [25.047788, 121.504494],
              [25.046876, 121.503433],
              [25.044766, 121.500964],
              [25.043818, 121.500332],
              [25.042625, 121.499994],
              [25.042144, 121.499664],
              [25.041662, 121.499081],
              [25.041276, 121.49853],
              [25.04073, 121.49791],
              [25.039642, 121.496465],
              [25.038873, 121.495386],
              [25.038307, 121.494232],
              [25.037394, 121.492308],
              [25.03671, 121.491331],
              [25.035733, 121.490256],
              [25.034231, 121.489148],
              [25.032509, 121.488429],
              [25.031973, 121.488299],
              [25.0311, 121.488207],
              [25.030295, 121.488216],
              [25.02952, 121.488368],
              [25.028187, 121.488888],
              [25.02417, 121.490695],
              [25.018181, 121.493432],
              [25.017672, 121.493746],
              [25.017434, 121.493934],
              [25.017129, 121.494402],
              [25.016892, 121.494892],
              [25.016564, 121.495871],
              [25.016417, 121.496398],
              [25.016337, 121.496956],
              [25.016292, 121.49759],
              [25.016295, 121.498192],
              [25.016693, 121.499287],
              [25.019543, 121.504772],
              [25.020556, 121.506455],
              [25.021724, 121.508073],
              [25.022222, 121.508757],
              [25.022641, 121.509547],
              [25.023441, 121.511857],
              [25.023793, 121.512504],
              [25.023811, 121.513852],
              [25.023586, 121.515113],
              [25.023343, 121.515533],
              [25.022982, 121.515868],
              [25.021772, 121.516451],
              [25.02083, 121.519314],
              [25.020238, 121.522392],
              [25.010769, 121.528781],
              [25.010007, 121.530735],
              [25.009178, 121.532008],
              [25.00803, 121.532914],
              [25.005764, 121.534128],
              [25.00467, 121.534687],
              [25.003894, 121.535112],
              [25.003449, 121.535236],
              [25.000299, 121.535219],
              [24.999025, 121.535017],
              [24.995755, 121.535266],
              [24.994621, 121.536823],
              [24.994291, 121.537142],
              [24.994019, 121.537305],
              [24.993622, 121.537441],
              [24.993313, 121.537505],
              [24.993059, 121.537538],
              [24.992747, 121.53753],
              [24.990078, 121.536565],
              [24.989727, 121.536551],
              [24.989467, 121.536669],
              [24.989198, 121.536901],
              [24.989015, 121.537053],
              [24.98882, 121.537295],
              [24.988586, 121.537619],
              [24.988348, 121.538118],
              [24.988246, 121.540011],
              [24.988144, 121.542339],
              [24.988323, 121.543891],
              [24.988287, 121.544246],
              [24.988151, 121.544439],
              [24.98687, 121.545026],
              [24.984993, 121.545038],
              [24.984415, 121.545088],
              [24.984177, 121.545246],
              [24.983944, 121.545486],
              [24.983817, 121.54569],
              [24.98373, 121.545916],
              [24.983686, 121.546182],
              [24.98369, 121.546464],
              [24.98374, 121.547028],
              [24.983851, 121.547916],
              [24.98404, 121.54875],
              [24.984134, 121.549037],
              [24.984312, 121.549395],
              [24.984568, 121.549753],
              [24.984766, 121.550022],
              [24.985229, 121.550463],
              [24.985386, 121.550597],
              [24.985599, 121.550732],
              [24.985822, 121.550791],
              [24.986258, 121.5509],
              [24.986506, 121.550924],
              [24.98664, 121.550779],
              [24.986768, 121.550683],
              [24.986838, 121.550645],
              [24.986993, 121.550629],
              [24.987139, 121.55071],
              [24.98722, 121.55079],
              [24.987271, 121.550887],
              [24.987387, 121.551177],
              [24.987413, 121.551728],
              [24.98728, 121.552107],
              [24.986902, 121.552498],
              [24.985691, 121.553493],
              [24.985238, 121.553886],
              [24.984728, 121.554129],
              [24.98205, 121.553581],
              [24.980819, 121.553469],
              [24.979894, 121.553519],
              [24.979028, 121.553784],
              [24.977314, 121.554281],
              [24.97706, 121.554568],
              [24.976903, 121.554951],
              [24.976784, 121.555474],
              [24.976744, 121.556029],
              [24.976835, 121.556439],
              [24.976984, 121.556838],
              [24.977157, 121.557071],
              [24.97737, 121.557175],
              [24.978527, 121.557405],
              [24.978972, 121.557492],
              [24.979378, 121.557686],
              [24.979921, 121.55817],
              [24.980298, 121.55889],
              [24.980259, 121.559436],
              [24.97987, 121.561355],
              [24.981713, 121.565464],
              [24.98141, 121.567365],
              [24.9823936, 121.5696966],
              [24.9839843, 121.5714552],
              [24.9875348, 121.5719571],
              [24.9879179, 121.5731654],
              [24.987061, 121.5779935],
              [24.9861066, 121.5792413],
              [24.9857634, 121.5794854],
              [24.9852476, 121.5777579],
              [24.984971, 121.577988],
              [24.985024, 121.580033],
              [24.988637, 121.580625],
              [24.989706, 121.580574],
              [24.989712, 121.579041],
              [24.994742, 121.575591],
              [24.998572, 121.581555],
              [24.99927, 121.580823],
              [24.998567, 121.575755],
              [25.0047239, 121.5725734],
              [25.0050873, 121.5682444],
              [25.0059417, 121.5668281],
              [25.0082399, 121.5661307],
              [25.009702, 121.566592],
              [25.0152923, 121.5632288],
              [25.0178735, 121.5612466],
              [25.020688, 121.559994],
              [25.0229175, 121.5587159],
              [25.0230957, 121.5589613],
              [25.022334, 121.562263],
              [25.022172, 121.563602],
              [25.022724, 121.56418],
              [25.02332, 121.563653],
              [25.025621, 121.564281],
              [25.024283, 121.565914],
              [25.023635, 121.565315],
              [25.020696, 121.568954],
              [25.018555, 121.571157],
              [25.020098, 121.572545],
              [25.021948, 121.574263],
              [25.02624, 121.572521],
              [25.028514, 121.57164],
              [25.032518, 121.570049],
              [25.032537, 121.571109],
              [25.032513, 121.572166],
              [25.032469, 121.572654],
              [25.032302, 121.572806],
              [25.031765, 121.573195],
              [25.031441, 121.573466],
              [25.030998, 121.573869],
              [25.030442, 121.574491],
              [25.030384, 121.575964],
              [25.033728, 121.577224],
              [25.035458, 121.579342],
              [25.036954, 121.584084],
              [25.038146, 121.588462],
              [25.037484, 121.590962],
              [25.037862, 121.593864],
              [25.038198, 121.593868],
              [25.038976, 121.593928],
              [25.04095, 121.594069],
              [25.046734, 121.594942],
              [25.048519, 121.595506],
              [25.050272, 121.595983],
              [25.05241, 121.614963],
              [25.049097, 121.613775],
              [25.049051, 121.61295],
              [25.048824, 121.612588],
              [25.048291, 121.612293],
              [25.047537, 121.61226],
              [25.045213, 121.612462],
              [25.045157, 121.61415],
              [25.04481, 121.614368],
              [25.04464, 121.614543],
              [25.044617, 121.615011],
              [25.044472, 121.615502],
              [25.04247, 121.616656],
              [25.041666, 121.616658],
              [25.040591, 121.617592],
              [25.038788, 121.62141],
              [25.03788, 121.622628],
              [25.03902, 121.623447],
              [25.039264, 121.623597],
              [25.039772, 121.622997],
              [25.039866, 121.622815],
              [25.039917, 121.622627],
              [25.039999, 121.622248],
              [25.040105, 121.621928],
              [25.040369, 121.621563],
              [25.041102, 121.621015],
              [25.04214, 121.620263],
              [25.042584, 121.619991],
              [25.042792, 121.619851],
              [25.04299, 121.619647],
              [25.043375, 121.619216],
              [25.043625, 121.619014],
              [25.044115, 121.618749],
              [25.046251, 121.617532],
              [25.047772, 121.616815],
              [25.048988, 121.616453],
              [25.049384, 121.616403],
              [25.050344, 121.616277],
              [25.050921, 121.61624],
              [25.051299, 121.616446],
              [25.051892, 121.617487],
              [25.054073, 121.620718],
              [25.054651, 121.620742],
              [25.055903, 121.620903],
              [25.057156, 121.621665],
              [25.062174, 121.61784],
              [25.063602, 121.614302],
              [25.063384, 121.613606],
              [25.062971, 121.613039],
              [25.060278, 121.610046],
              [25.059132, 121.605089],
              [25.058588, 121.599746],
              [25.058909, 121.594037],
              [25.058393, 121.592342],
              [25.055507, 121.588974],
              [25.054059, 121.586442],
              [25.053184, 121.583695],
              [25.052815, 121.580756],
              [25.051162, 121.573589],
              [25.051784, 121.570992],
              [25.064088, 121.568911],
              [25.066982, 121.568885],
              [25.066014, 121.563509],
              [25.063365, 121.564141],
              [25.062256, 121.554376],
              [25.06196, 121.550864],
              [25.068446, 121.542285],
              [25.06866, 121.528553],
              [25.071538, 121.528574],
              [25.075036, 121.521879],
              [25.07663, 121.516021],
              [25.080482, 121.514638],
              [25.082203, 121.513786],
              [25.083925, 121.513148],
              [25.085452, 121.512672],
              [25.087017, 121.512378],
              [25.090177, 121.511941],
              [25.091194, 121.51133],
              [25.092152, 121.510548],
              [25.092469, 121.510034],
              [25.092689, 121.509702],
              [25.09311, 121.509041],
              [25.093298, 121.50839],
              [25.093907, 121.503688],
              [25.094428, 121.49697],
              [25.093689, 121.497163],
              [25.092786, 121.497645],
            ],
          },
          {
            name: '士林',
            coordinates: [
              [25.123134, 121.507953],
              [25.125271, 121.51291],
              [25.125839, 121.514221],
              [25.128855, 121.509927],
              [25.130805, 121.507234],
              [25.130964, 121.507052],
              [25.131182, 121.506933],
              [25.131529, 121.506945],
              [25.132663, 121.508008],
              [25.135542, 121.510553],
              [25.136066, 121.511018],
              [25.136209, 121.511336],
              [25.13639, 121.512298],
              [25.137117, 121.513781],
              [25.137859, 121.513549],
              [25.137906, 121.516228],
              [25.141178, 121.516093],
              [25.141429, 121.516075],
              [25.141778, 121.516014],
              [25.14202, 121.515694],
              [25.14235, 121.514778],
              [25.141699, 121.511372],
              [25.141942, 121.510268],
              [25.142044, 121.508644],
              [25.142321, 121.507759],
              [25.143768, 121.506828],
              [25.14386, 121.505983],
              [25.14368, 121.505043],
              [25.143874, 121.50388],
              [25.14428, 121.503512],
              [25.14442, 121.503031],
              [25.144583, 121.502724],
              [25.144832, 121.50234],
              [25.145182, 121.501359],
              [25.145456, 121.499045],
              [25.145934, 121.496278],
              [25.146043, 121.493233],
              [25.14457, 121.491854],
              [25.144281, 121.491312],
              [25.143716, 121.490679],
              [25.143151, 121.490046],
              [25.142846, 121.489402],
              [25.142205, 121.488222],
              [25.13973, 121.483738],
              [25.1386, 121.481623],
              [25.137062, 121.479401],
              [25.136612, 121.478659],
              [25.136181, 121.478153],
              [25.13556, 121.477929],
              [25.133188, 121.474784],
              [25.13165, 121.472485],
              [25.129942, 121.470329],
              [25.12905, 121.468517],
              [25.128401, 121.467261],
              [25.127125, 121.4658],
              [25.125218, 121.464823],
              [25.12472, 121.464827],
              [25.124397, 121.464809],
              [25.124042, 121.464495],
              [25.12304, 121.466145],
              [25.121418, 121.465597],
              [25.117088, 121.465417],
              [25.117324, 121.466706],
              [25.117759, 121.468652],
              [25.11785, 121.469158],
              [25.117899, 121.469316],
              [25.117944, 121.469408],
              [25.11809, 121.469464],
              [25.118227, 121.469503],
              [25.11931, 121.46979],
              [25.120774, 121.470116],
              [25.121592, 121.470198],
              [25.122079, 121.470204],
              [25.122781, 121.470275],
              [25.12297, 121.470343],
              [25.123014, 121.470496],
              [25.123024, 121.47075],
              [25.123045, 121.471579],
              [25.124842, 121.473826],
              [25.125595, 121.473856],
              [25.126585, 121.473892],
              [25.126788, 121.473822],
              [25.126882, 121.472209],
              [25.127517, 121.472455],
              [25.127854, 121.472675],
              [25.12891, 121.473538],
              [25.130022, 121.474288],
              [25.13067, 121.474685],
              [25.131259, 121.474717],
              [25.131982, 121.475525],
              [25.132674, 121.476398],
              [25.133614, 121.477511],
              [25.134384, 121.478411],
              [25.134922, 121.479236],
              [25.13526, 121.479889],
              [25.135579, 121.480724],
              [25.136022, 121.481783],
              [25.13641, 121.483041],
              [25.136914, 121.485081],
              [25.137351, 121.48825],
              [25.137549, 121.489823],
              [25.137418, 121.490109],
              [25.134611, 121.495729],
              [25.133847, 121.495338],
              [25.132976, 121.495171],
              [25.129117, 121.496783],
              [25.125432, 121.497966],
              [25.124614, 121.498113],
              [25.124253, 121.498131],
              [25.124074, 121.498136],
              [25.123841, 121.495957],
              [25.123833, 121.494688],
              [25.121672, 121.494398],
              [25.121513, 121.496374],
              [25.121418, 121.49792],
              [25.121216, 121.498875],
              [25.120747, 121.499551],
              [25.12022, 121.49997],
              [25.11933, 121.500807],
              [25.116946, 121.503294],
              [25.11462, 121.505855],
              [25.111288, 121.509304],
              [25.11002, 121.510624],
              [25.109141, 121.510828],
              [25.101087, 121.511493],
              [25.098676, 121.510804],
              [25.097719, 121.510492],
              [25.096907, 121.510458],
              [25.096558, 121.510447],
              [25.096247, 121.510522],
              [25.093838, 121.513139],
              [25.092678, 121.514029],
              [25.091836, 121.514474],
              [25.091129, 121.514747],
              [25.090655, 121.514908],
              [25.090182, 121.514983],
              [25.089543, 121.515112],
              [25.088807, 121.515198],
              [25.085912, 121.515674],
              [25.085235, 121.515804],
              [25.084538, 121.515912],
              [25.083651, 121.516117],
              [25.083066, 121.516333],
              [25.082427, 121.516634],
              [25.082074, 121.516828],
              [25.081691, 121.517097],
              [25.081063, 121.517581],
              [25.080347, 121.518302],
              [25.079548, 121.519193],
              [25.078439, 121.520679],
              [25.077525, 121.52241],
              [25.076816, 121.523554],
              [25.0765, 121.524018],
              [25.076361, 121.524294],
              [25.076417, 121.524526],
              [25.076754, 121.524731],
              [25.07717, 121.524806],
              [25.078194, 121.525001],
              [25.080088, 121.525047],
              [25.083216, 121.526512],
              [25.090307, 121.528198],
              [25.095568, 121.533573],
              [25.097637, 121.538046],
              [25.097231, 121.539804],
              [25.097143, 121.540526],
              [25.096797, 121.540852],
              [25.096775, 121.541548],
              [25.097083, 121.541611],
              [25.097056, 121.542669],
              [25.096639, 121.54302],
              [25.096255, 121.543368],
              [25.095988, 121.543736],
              [25.095926, 121.54411],
              [25.095941, 121.544613],
              [25.095956, 121.544965],
              [25.095913, 121.54521],
              [25.095536, 121.545848],
              [25.095139, 121.546743],
              [25.094858, 121.547499],
              [25.094747, 121.547893],
              [25.09475, 121.548101],
              [25.094792, 121.548308],
              [25.094958, 121.548624],
              [25.095144, 121.548919],
              [25.095389, 121.549149],
              [25.095653, 121.549401],
              [25.095825, 121.549419],
              [25.095978, 121.549374],
              [25.096652, 121.549089],
              [25.098507, 121.548456],
              [25.100274, 121.551407],
              [25.100418, 121.551682],
              [25.100564, 121.551882],
              [25.10071, 121.551904],
              [25.100848, 121.551803],
              [25.103025, 121.549967],
              [25.103114, 121.549895],
              [25.103184, 121.549802],
              [25.103176, 121.549639],
              [25.103109, 121.549497],
              [25.102588, 121.548881],
              [25.098929, 121.544081],
              [25.098556, 121.543634],
              [25.098428, 121.543475],
              [25.098417, 121.543144],
              [25.098541, 121.542778],
              [25.098704, 121.542369],
              [25.100158, 121.538944],
              [25.100445, 121.538288],
              [25.100771, 121.537813],
              [25.101285, 121.537572],
              [25.101954, 121.537332],
              [25.105431, 121.535744],
              [25.107955, 121.533738],
              [25.109072, 121.535196],
              [25.109377, 121.538367],
              [25.10972, 121.540261],
              [25.109831, 121.540718],
              [25.110023, 121.540804],
              [25.110215, 121.540804],
              [25.112794, 121.540589],
              [25.118671, 121.539817],
              [25.123543, 121.537194],
              [25.126391, 121.535016],
              [25.126807, 121.534473],
              [25.127107, 121.53393],
              [25.127134, 121.533208],
              [25.127058, 121.531501],
              [25.126984, 121.529718],
              [25.126797, 121.528042],
              [25.126455, 121.527375],
              [25.125258, 121.525291],
              [25.123839, 121.521878],
              [25.122729, 121.521212],
              [25.122302, 121.521684],
              [25.121811, 121.522703],
              [25.121639, 121.522735],
              [25.119902, 121.522424],
              [25.119596, 121.522124],
              [25.119096, 121.520997],
              [25.118856, 121.520211],
              [25.118868, 121.519822],
              [25.119533, 121.517392],
              [25.119346, 121.516182],
              [25.119488, 121.515016],
              [25.119614, 121.514179],
              [25.119022, 121.514158],
              [25.119119, 121.512806],
              [25.119987, 121.512427],
              [25.120533, 121.511917],
              [25.120615, 121.511718],
              [25.121683, 121.511583],
              [25.122056, 121.510357],
              [25.121852, 121.509627],
              [25.123134, 121.507953],
            ],
          },
        ],
        colorZone: '#87C03600',
        colorBorderLine: '#87C036FF',
      },
      {
        type: 'OPERATION',
        zIndex: 0,
        scooterGroupIds: [0, 20],
        title: '',
        description: '',
        zones: [
          {
            name: '凱旋站',
            coordinates: [
              [22.598057, 120.314382],
              [22.5975073, 120.3132984],
              [22.5958729, 120.3145644],
              [22.5965266, 120.3155998],
              [22.598057, 120.314382],
            ],
          },
          {
            name: '前鎮加工出口區',
            coordinates: [
              [22.6023915, 120.2922314],
              [22.6056897, 120.2909011],
              [22.6061324, 120.2906104],
              [22.6065948, 120.2900837],
              [22.6074998, 120.288247],
              [22.6084209, 120.2861535],
              [22.6083726, 120.2858243],
              [22.6082401, 120.2855648],
              [22.6079925, 120.2854381],
              [22.6076904, 120.2854294],
              [22.600985, 120.2895734],
              [22.6004601, 120.2889511],
              [22.5998188, 120.2888089],
              [22.5947968, 120.2919231],
              [22.588071, 120.2961984],
              [22.5904657, 120.3035476],
              [22.5912829, 120.3033653],
              [22.5891433, 120.2972552],
              [22.5957674, 120.2947327],
              [22.6023915, 120.2922314],
            ],
          },
          {
            name: '左營鼓山三民鹽埕',
            coordinates: [
              [22.620468, 120.291036],
              [22.61734, 120.28802],
              [22.618648, 120.286527],
              [22.619262, 120.285228],
              [22.618495, 120.284869],
              [22.621367, 120.277783],
              [22.619886, 120.276997],
              [22.620832, 120.274999],
              [22.619844, 120.274422],
              [22.61946, 120.270036],
              [22.62407, 120.269744],
              [22.625509, 120.27071],
              [22.623308, 120.275417],
              [22.625343, 120.276817],
              [22.633988, 120.282771],
              [22.634337, 120.285199],
              [22.638335, 120.284754],
              [22.640927, 120.283654],
              [22.642968, 120.282466],
              [22.644614, 120.282437],
              [22.645351, 120.282662],
              [22.645634, 120.281718],
              [22.654905, 120.282224],
              [22.654951, 120.285036],
              [22.655957, 120.286584],
              [22.655968, 120.287719],
              [22.66088, 120.288001],
              [22.6621, 120.28528],
              [22.6671558, 120.288132],
              [22.6721273, 120.2911184],
              [22.6787355, 120.2970357],
              [22.6788353, 120.2923049],
              [22.6807772, 120.2910402],
              [22.6904619, 120.2988111],
              [22.6892485, 120.3014467],
              [22.6889853, 120.3041681],
              [22.690102, 120.3066498],
              [22.6873465, 120.3099609],
              [22.6915154, 120.3155822],
              [22.6914628, 120.3176198],
              [22.6876638, 120.3225195],
              [22.6769935, 120.3221235],
              [22.6785764, 120.3192328],
              [22.6695855, 120.3176298],
              [22.6693325, 120.3151478],
              [22.6685572, 120.3137113],
              [22.6605683, 120.3104259],
              [22.6605453, 120.3114005],
              [22.6589927, 120.3117152],
              [22.6549, 120.311084],
              [22.654134, 120.303844],
              [22.652393, 120.304178],
              [22.652137, 120.306497],
              [22.646327, 120.31502],
              [22.639946, 120.314457],
              [22.629568, 120.313682],
              [22.627294, 120.314564],
              [22.62035, 120.293449],
              [22.620468, 120.291036],
            ],
          },
          {
            name: '楠梓區',
            coordinates: [
              [22.7191944, 120.283246],
              [22.7183047, 120.2846279],
              [22.7168982, 120.2824692],
              [22.7126417, 120.28538],
              [22.7120003, 120.2860795],
              [22.711735, 120.2872189],
              [22.7109074, 120.2888057],
              [22.7088562, 120.2927302],
              [22.7051387, 120.2926315],
              [22.7070077, 120.2941663],
              [22.707534, 120.2949314],
              [22.7093982, 120.2968584],
              [22.708332, 120.2980294],
              [22.7076468, 120.3000461],
              [22.7076936, 120.3020101],
              [22.7005846, 120.3021608],
              [22.700574, 120.3028017],
              [22.7077239, 120.3025936],
              [22.7077535, 120.3056828],
              [22.7087431, 120.307388],
              [22.7144678, 120.3093916],
              [22.7131517, 120.3130287],
              [22.7159162, 120.3182942],
              [22.7225679, 120.3179452],
              [22.7233899, 120.3193693],
              [22.7244792, 120.3214264],
              [22.7226671, 120.3282711],
              [22.7249618, 120.3328951],
              [22.7305224, 120.3338713],
              [22.7332907, 120.3315343],
              [22.7359205, 120.3333494],
              [22.7368663, 120.3339705],
              [22.7390985, 120.3341893],
              [22.7408124, 120.3343738],
              [22.7425363, 120.3344189],
              [22.7460185, 120.3345306],
              [22.7489962, 120.3344384],
              [22.7519244, 120.3340136],
              [22.7517458, 120.3260384],
              [22.7485283, 120.3260074],
              [22.7477763, 120.328164],
              [22.7467175, 120.3304279],
              [22.744837, 120.3289128],
              [22.7428527, 120.3268237],
              [22.7393193, 120.323032],
              [22.7364925, 120.3251971],
              [22.7343206, 120.3213671],
              [22.7318175, 120.3172667],
              [22.7322336, 120.3169484],
              [22.7311455, 120.3152943],
              [22.7306955, 120.3154275],
              [22.7298793, 120.3141981],
              [22.728251, 120.3123454],
              [22.7293984, 120.3107341],
              [22.7284791, 120.308696],
              [22.7275301, 120.3086105],
              [22.7279774, 120.3071736],
              [22.7273915, 120.3067581],
              [22.7280307, 120.3061738],
              [22.7282204, 120.3033137],
              [22.7282805, 120.3017643],
              [22.7278789, 120.3002695],
              [22.7272224, 120.2991396],
              [22.725731, 120.2982804],
              [22.7226729, 120.290445],
              [22.721186, 120.2866577],
              [22.7191944, 120.283246],
            ],
          },
        ],
        colorZone: '#87C03600',
        colorBorderLine: '#87C036FF',
      },
      {
        type: 'NON_ALERT',
        zIndex: 5,
        scooterGroupIds: [22],
        title: '青春山海線營運範圍',
        description: '限租還北海岸專用車',
        zones: [
          {
            name: 'rf-non-alert',
            coordinates: [
              [25.1322997, 121.8350265],
              [25.1366894, 121.8324101],
              [25.1386907, 121.8234412],
              [25.1365725, 121.8116396],
              [25.1499537, 121.808506],
              [25.1503592, 121.7931832],
              [25.1543899, 121.7783725],
              [25.1591239, 121.7819529],
              [25.1613356, 121.7726708],
              [25.1661105, 121.7664781],
              [25.1657257, 121.7571626],
              [25.1642532, 121.7515382],
              [25.1562526, 121.7547977],
              [25.1487956, 121.7599449],
              [25.141629, 121.7601701],
              [25.140459, 121.7617151],
              [25.1373983, 121.7704753],
              [25.1321419, 121.7770036],
              [25.13046, 121.7764616],
              [25.1287064, 121.777509],
              [25.1274189, 121.7805948],
              [25.1223259, 121.7873455],
              [25.1185156, 121.796282],
              [25.1121696, 121.7945972],
              [25.1085677, 121.798011],
              [25.106406, 121.7993656],
              [25.1040253, 121.8066235],
              [25.104471, 121.8160753],
              [25.1017484, 121.8230425],
              [25.1040792, 121.8268454],
              [25.1235002, 121.8339496],
              [25.1245881, 121.8382412],
              [25.1242766, 121.8469519],
              [25.1214735, 121.8592347],
              [25.1208159, 121.8639082],
              [25.1221791, 121.8647629],
              [25.1281628, 121.8618446],
              [25.1300279, 121.8481114],
              [25.1309604, 121.8416984],
              [25.1322997, 121.8350265],
            ],
          },
        ],
        colorZone: '#00000000',
        colorBorderLine: '#00000000',
      },
      {
        type: 'OPERATION',
        zIndex: 0,
        scooterGroupIds: [22],
        title: '',
        description: '',
        zones: [
          {
            name: '瑞芳車站',
            coordinates: [
              [25.1084197, 121.8071957],
              [25.1083285, 121.8076394],
              [25.1087688, 121.8077311],
              [25.1088538, 121.8072852],
              [25.1084197, 121.8071957],
            ],
          },
          {
            name: '八斗子觀景平台',
            coordinates: [
              [25.1362974, 121.8022483],
              [25.1360838, 121.802023],
              [25.1359429, 121.8021625],
              [25.1358093, 121.8023422],
              [25.1357052, 121.8025051],
              [25.1356254, 121.8026775],
              [25.1358604, 121.8027861],
              [25.1360547, 121.8026889],
              [25.1362974, 121.8022483],
            ],
          },
          {
            name: '環保復育公園',
            coordinates: [
              [25.1465023, 121.8047093],
              [25.1463044, 121.8046341],
              [25.146172, 121.8050498],
              [25.1463772, 121.8051235],
              [25.1465023, 121.8047093],
            ],
          },
          {
            name: '區域探索館',
            coordinates: [
              [25.1401803, 121.7950567],
              [25.1399643, 121.7952927],
              [25.1401863, 121.795604],
              [25.1404049, 121.795368],
              [25.1401803, 121.7950567],
            ],
          },
          {
            name: '象鼻岩',
            coordinates: [
              [25.1333503, 121.8229769],
              [25.1331062, 121.8229702],
              [25.1330844, 121.8232384],
              [25.1333339, 121.8232438],
              [25.1333503, 121.8229769],
            ],
          },
          {
            name: '海科館火車站',
            coordinates: [
              [25.1388288, 121.7986009],
              [25.1385379, 121.7984239],
              [25.1383176, 121.798717],
              [25.1384912, 121.7988302],
              [25.138694, 121.7988679],
              [25.1388288, 121.7986009],
            ],
          },
        ],
        colorZone: '#87C03600',
        colorBorderLine: '#87C036FF',
      },
      {
        type: 'OPERATION',
        zIndex: 0,
        scooterGroupIds: [20, 0],
        title: '',
        description: '',
        zones: [
          {
            name: '三重新莊',
            coordinates: [
              [25.0339275981, 121.4428850636],
              [25.0324544, 121.4359782],
              [25.0322318, 121.4342174],
              [25.0293718, 121.4352078],
              [25.0229974, 121.4355974],
              [25.0200869, 121.434102],
              [25.0206709, 121.428609],
              [25.0131599, 121.428322],
              [25.0114169, 121.427417],
              [25.0110309, 121.427123],
              [25.0118839, 121.426264],
              [25.0131309, 121.425737],
              [25.0177049, 121.42583],
              [25.0205729, 121.414973],
              [25.0210109, 121.410756],
              [25.0215019, 121.41029],
              [25.0240779, 121.415198],
              [25.0272959, 121.41295],
              [25.0276699, 121.412344],
              [25.0284379, 121.41228],
              [25.0305499, 121.414251],
              [25.0304119, 121.416856],
              [25.0281419, 121.41902],
              [25.0282979, 121.419576],
              [25.0293919, 121.420145],
              [25.0292579, 121.420397],
              [25.0285309, 121.420364],
              [25.0311989, 121.429081],
              [25.0325189, 121.433255],
              [25.0357533, 121.4283944],
              [25.0392225, 121.4310618],
              [25.039683, 121.4301156],
              [25.0408659, 121.4307359],
              [25.0406666, 121.4311276],
              [25.039711, 121.4306211],
              [25.0394358, 121.4312384],
              [25.0379499, 121.434124],
              [25.0374639, 121.433743],
              [25.0362057, 121.4357596],
              [25.0329729, 121.4352932],
              [25.0331796, 121.4363339],
              [25.0334992, 121.4363607],
              [25.0335436, 121.4366745],
              [25.0331967, 121.4367898],
              [25.0335867, 121.4391979],
              [25.033442, 121.4392724],
              [25.034084379, 121.4428787749],
              [25.035799, 121.44281],
              [25.037132, 121.442969],
              [25.039457, 121.444008],
              [25.041354, 121.443909],
              [25.042079, 121.442337],
              [25.054851, 121.442638],
              [25.0548978, 121.4518564],
              [25.0591353, 121.4519529],
              [25.0591013, 121.4544796],
              [25.0601655, 121.454501],
              [25.0600927, 121.4574836],
              [25.0590576, 121.4574408],
              [25.0590285, 121.4598333],
              [25.0396787, 121.4601369],
              [25.0391796, 121.4604249],
              [25.0414247, 121.4637269],
              [25.0425862, 121.4645605],
              [25.0418328, 121.4649756],
              [25.0434851, 121.4670022],
              [25.0455844, 121.4693654],
              [25.049003, 121.469421],
              [25.049414, 121.468382],
              [25.055333, 121.468471],
              [25.0581224, 121.4687193],
              [25.0581759, 121.4693263],
              [25.0609021, 121.4694129],
              [25.060901, 121.472384],
              [25.059961, 121.472359],
              [25.059905, 121.474276],
              [25.055245, 121.474343],
              [25.053334, 121.474342],
              [25.053376, 121.476954],
              [25.049512, 121.476297],
              [25.050111, 121.477976],
              [25.04882, 121.47939],
              [25.047816, 121.477046],
              [25.047065, 121.474486],
              [25.045465, 121.469603],
              [25.0430377, 121.4673129],
              [25.0432166, 121.4670988],
              [25.0422595, 121.4657983],
              [25.041138, 121.464282],
              [25.040778, 121.464444],
              [25.036699, 121.457859],
              [25.032503, 121.450545],
              [25.029139, 121.446634],
              [25.030066, 121.444515],
              [25.029666, 121.443056],
              [25.0339275981, 121.4428850636],
            ],
          },
          {
            name: '三重蘆洲',
            coordinates: [
              [25.089988, 121.489616],
              [25.091694, 121.48933],
              [25.092768, 121.487308],
              [25.089636, 121.478556],
              [25.095596, 121.473085],
              [25.095352, 121.470983],
              [25.094701, 121.468746],
              [25.0925, 121.463849],
              [25.092574, 121.463092],
              [25.094148, 121.461114],
              [25.093467, 121.45965],
              [25.09387, 121.45881],
              [25.091684, 121.45728],
              [25.088818, 121.455818],
              [25.087533, 121.455574],
              [25.086248, 121.455427],
              [25.084432, 121.455442],
              [25.082543, 121.455872],
              [25.08276, 121.456923],
              [25.081195, 121.45731],
              [25.080077, 121.459375],
              [25.078862, 121.461102],
              [25.077785, 121.463384],
              [25.07794, 121.464496],
              [25.076925, 121.467085],
              [25.076456, 121.467676],
              [25.076414, 121.468371],
              [25.074641, 121.475933],
              [25.073026, 121.475071],
              [25.072451, 121.473776],
              [25.071796, 121.473302],
              [25.072114, 121.471518],
              [25.068166, 121.471377],
              [25.068177, 121.473113],
              [25.067333, 121.474495],
              [25.066232, 121.476454],
              [25.065206, 121.476391],
              [25.064484, 121.480006],
              [25.062766, 121.479732],
              [25.062718, 121.481068],
              [25.06153, 121.481347],
              [25.059281, 121.482363],
              [25.05806, 121.482924],
              [25.057066, 121.483358],
              [25.055968, 121.483589],
              [25.055652, 121.482639],
              [25.0535817, 121.4836903],
              [25.0539534, 121.4844262],
              [25.0553845, 121.4838747],
              [25.055244, 121.484616],
              [25.054918, 121.485749],
              [25.053698, 121.485499],
              [25.051268, 121.485268],
              [25.051512, 121.486706],
              [25.049538, 121.487172],
              [25.049451, 121.488186],
              [25.04939, 121.488848],
              [25.049864, 121.490229],
              [25.050551, 121.491456],
              [25.051534, 121.492506],
              [25.055278, 121.497262],
              [25.058728, 121.501559],
              [25.061578, 121.503118],
              [25.0638, 121.504109],
              [25.065356, 121.504328],
              [25.067421, 121.5045],
              [25.069083, 121.504323],
              [25.074734, 121.502703],
              [25.077566, 121.501748],
              [25.084922, 121.497167],
              [25.088528, 121.493057],
              [25.089384, 121.491347],
              [25.089988, 121.489616],
            ],
          },
          {
            name: '新店',
            coordinates: [
              [24.994064, 121.535268],
              [24.993198, 121.534662],
              [24.992313, 121.534013],
              [24.991545, 121.533804],
              [24.990796, 121.533723],
              [24.986045, 121.533196],
              [24.979205, 121.533633],
              [24.979017, 121.533384],
              [24.9743868, 121.5302134],
              [24.97514, 121.5289554],
              [24.9743691, 121.5284605],
              [24.9735592, 121.5296501],
              [24.9685887, 121.5366147],
              [24.9669494, 121.537162],
              [24.9614234, 121.5370931],
              [24.9611543, 121.5369064],
              [24.958901, 121.536902],
              [24.958217, 121.537054],
              [24.958235, 121.538294],
              [24.961503, 121.541137],
              [24.964149, 121.543036],
              [24.967519, 121.545471],
              [24.970967, 121.547649],
              [24.973676, 121.549012],
              [24.976053, 121.547617],
              [24.977988, 121.550402],
              [24.979495, 121.553059],
              [24.980779, 121.552929],
              [24.98362, 121.5534],
              [24.984709, 121.553486],
              [24.986226, 121.55237],
              [24.986362, 121.551684],
              [24.985876, 121.55134],
              [24.984806, 121.550696],
              [24.984271, 121.550224],
              [24.983732, 121.549366],
              [24.983267, 121.547907],
              [24.983152, 121.546705],
              [24.983356, 121.545429],
              [24.98395, 121.544452],
              [24.984485, 121.544206],
              [24.985319, 121.544176],
              [24.986853, 121.544447],
              [24.987278, 121.544192],
              [24.987626, 121.543723],
              [24.987393, 121.540558],
              [24.987529, 121.538889],
              [24.987733, 121.537991],
              [24.98891, 121.536062],
              [24.989435, 121.535719],
              [24.990048, 121.53559],
              [24.991443, 121.535762],
              [24.992664, 121.536363],
              [24.993811, 121.536062],
              [24.994122, 121.535708],
              [24.994064, 121.535268],
            ],
          },
          {
            name: '板橋中永和',
            coordinates: [
              [24.995526, 121.526596],
              [24.99548, 121.526637],
              [24.995433, 121.526678],
              [24.995453, 121.528081],
              [25.000082, 121.528858],
              [25.002026, 121.528776],
              [25.004617, 121.527885],
              [25.011736, 121.523128],
              [25.015751, 121.520404],
              [25.017083, 121.518634],
              [25.017937, 121.516416],
              [25.018072, 121.514503],
              [25.017853, 121.511916],
              [25.017074, 121.509593],
              [25.015784, 121.507553],
              [25.012517, 121.504016],
              [25.010713, 121.501789],
              [25.009106, 121.499618],
              [25.008712, 121.498977],
              [25.008389, 121.498319],
              [25.008093, 121.497603],
              [25.007911, 121.496816],
              [25.007779, 121.496141],
              [25.00771, 121.495565],
              [25.007708, 121.494821],
              [25.007685, 121.494255],
              [25.007832, 121.493381],
              [25.008173, 121.492136],
              [25.008689, 121.491085],
              [25.00943, 121.490054],
              [25.010057, 121.489574],
              [25.010385, 121.489329],
              [25.010679, 121.489062],
              [25.011261, 121.488629],
              [25.011607, 121.488426],
              [25.012238, 121.488051],
              [25.013128, 121.487628],
              [25.013527, 121.487451],
              [25.013722, 121.487368],
              [25.013832, 121.487332],
              [25.013939, 121.48728],
              [25.014456, 121.487108],
              [25.014713, 121.48704],
              [25.014951, 121.486998],
              [25.015469, 121.4869],
              [25.016064, 121.486796],
              [25.017453, 121.486596],
              [25.01874, 121.486378],
              [25.020084, 121.486087],
              [25.020082, 121.486085],
              [25.021272, 121.485795],
              [25.022334, 121.485471],
              [25.025065, 121.484301],
              [25.026708, 121.483658],
              [25.027457, 121.483379],
              [25.028166, 121.4831],
              [25.028778, 121.482908],
              [25.030927, 121.482134],
              [25.031345, 121.481963],
              [25.032259, 121.4817],
              [25.033183, 121.481459],
              [25.033975, 121.481343],
              [25.034709, 121.481389],
              [25.036041, 121.481373],
              [25.03606, 121.481083],
              [25.036136, 121.479455],
              [25.036133, 121.478578],
              [25.035895, 121.477682],
              [25.035496, 121.477092],
              [25.034971, 121.476684],
              [25.033345, 121.469783],
              [25.031894, 121.465221],
              [25.031304, 121.463466],
              [25.030792, 121.461572],
              [25.02875, 121.457484],
              [25.028712, 121.456068],
              [25.026845, 121.454566],
              [25.024123, 121.453922],
              [25.020895, 121.454094],
              [25.017201, 121.456197],
              [25.015715, 121.452094],
              [25.014251, 121.451408],
              [25.011358, 121.452258],
              [25.009931, 121.452629],
              [25.009217, 121.452879],
              [25.008703, 121.453048],
              [25.00399, 121.45314],
              [25.001641, 121.453468],
              [24.996355, 121.451568],
              [24.99582, 121.450706],
              [24.9949703, 121.4496351],
              [24.9941011, 121.4492938],
              [24.993412, 121.45014],
              [24.991229, 121.453434],
              [24.991606, 121.458869],
              [24.990699, 121.459819],
              [24.99153, 121.462996],
              [24.993041, 121.465315],
              [24.995521, 121.463505],
              [24.996829, 121.464775],
              [24.996804, 121.465358],
              [24.998148, 121.465544],
              [24.998402, 121.465993],
              [24.998568, 121.466067],
              [24.999165, 121.464532],
              [24.999704, 121.463703],
              [25.000534, 121.463271],
              [25.000944, 121.465727],
              [25.002478, 121.465527],
              [25.003052, 121.467996],
              [25.004834, 121.467854],
              [25.004606, 121.468416],
              [25.005925, 121.471638],
              [25.006046, 121.471719],
              [25.003022, 121.472084],
              [25.000076, 121.472513],
              [24.999505, 121.472534],
              [24.999468, 121.473028],
              [24.999074, 121.474476],
              [24.998695, 121.475324],
              [24.998661, 121.47589],
              [24.998394, 121.47637],
              [24.997003, 121.477416],
              [24.998034, 121.479873],
              [24.99747, 121.480796],
              [24.996284, 121.480667],
              [24.995409, 121.480452],
              [24.994896, 121.480585],
              [24.994289, 121.480507],
              [24.993614, 121.480619],
              [24.993568, 121.48104],
              [24.9936, 121.483349],
              [24.993576, 121.484674],
              [24.993668, 121.494496],
              [24.993313, 121.499496],
              [24.993218, 121.499718],
              [24.990136, 121.499898],
              [24.990142, 121.503639],
              [24.989538, 121.506098],
              [24.989838, 121.506724],
              [24.989005, 121.507585],
              [24.988045, 121.507716],
              [24.986458, 121.507829],
              [24.986448, 121.508458],
              [24.988572, 121.509861],
              [24.987963, 121.510162],
              [24.988016, 121.51055],
              [24.986389, 121.511238],
              [24.987108, 121.513562],
              [24.986699, 121.513935],
              [24.986826, 121.51432],
              [24.990218, 121.512663],
              [24.991369, 121.516704],
              [24.991143, 121.519212],
              [24.990178, 121.520711],
              [24.989941, 121.525975],
              [24.995526, 121.526596],
            ],
          },
        ],
        colorZone: '#87C03600',
        colorBorderLine: '#87C036FF',
      },
    ],
  });

  mock.onGet('/v4/users/me/rejectReasons').reply(200, {
    result: 'success',
    data: [
      {
        type: '1',
        desc: 'Please fill in complete mailing address.',
      },
      {
        type: '2',
        desc: 'Please fill in idididiid.',
      },
      {
        type: '3',
        desc: 'Please fill in idididiid.',
      },
      {
        type: '4',
        desc: 'Please fill in idididiid.',
      },
    ],
  });

  mock.onPost('/service.asmx/UserInfoV3').reply(200, {
    result: 'success',
    userInfo: {
      first_name: '停',
      last_name: '胡',
      birth: '12/28/1936 00:00:00 AM',
      gender: 'F',
      idcard_id: 'EWER',
      license: '',
      phone: '+886973086332',
      address: '彰化縣葫蘆鄉葫蘆鎮葫蘆里糊塗葫蘆路二段四號六樓之二號這地址夠長了ㄇ',
      contact_email: 'ting.hu@wemoscooter.com',
      // user_status_id: '0',
      account_photo: '',
      user_group_id: 0,
      userCategoryId: '2',
      isPhoneVerified: false,
      paymentErr: 0,
      unpaidAmount: 0,
    },
    //  renting or reserving
    appReturnInfo: {
      rentId: '2440993',
      pricingPlanId: '313',
      timePlanId: null,
      // app_return_location: 'RENTING',
      app_return_location: 'ROUTE_TO_SCOOTER',
      // app_return_location: 'NOT_RENTING',
      scooter_group_id: '0',
      scooter_id: 'QAT-0014',
      time: '593',
      lat: '25.0529',
      lng: '121.5467629',
      parkingLot: null,
      parkingSpaceId: null,
      styles: { pin: 'Skyblue' },
      styleId: '64',
      pricing: {
        pricingPlans: [
          {
            id: '313',
            startPrice: 15,
            freeMinutes: 6,
            pricePerMinute: 2.5,
            name: '一般用戶',
            isDefault: true,
            isBasic: true,
          },
        ],
        timePlans: [
          {
            id: '1',
            // type: 'mengoPlan',
            type: 'hourlyPlan',
            name: '6H 內免費',
            useBeginAt: '2019-03-01T16:00:00Z',
            useEndAt: '2019-03-02T16:00:00Z',
            isDefault: true,
            usedFreeMinutes: 0,
            totalFreeMinutes: 360,
            minsLeft: 360,
            detailUrl: 'https://wemoscooter.com',
            price: 250,
            meta: null,
            isDeletable: false,
          },
        ],
      },
      availableDistance: 45,
      batPercent: 100,
    },
  });

  mock.onPost('/v4/developers/login').reply(200, {
    result: 'success',
    data: {
      accessToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxMTU3NzAiLCJ1c2VyX2lkIjoiMTE1NzcwIiwidmVyc2lvbiI6IjIuMCIsImlkZW50aXR5IjoiZGV2ZWxvcGVyIiwiaWF0IjoxNTgxNzQyNzg3LCJleHAiOjE1ODE4MDAzODd9.-s8iGzT7ieqZytCYG9b-RYDZRZKjwkdEAIQzOag9YI4',
    },
  });

  mock.onGet('/v4/users/me/userCategories/default').reply(200, {
    result: 'success',
    data: {
      id: '9527',
      name: 'WeMo 長租 499 閃亮亮方案',
      plan: {
        id: '9528',
        type: 'monthlyRate',
        useEndAt: '2019-08-01T12:34:56.000Z',
        verificationDetail: null,
        isDefault: true,
        isDeletable: false,
        isActive: true,
        url: 'https://www.wemoscooter.com/',
      },
    },
  });

  mock.onPost(`/v13/users/me/rents`).reply(200, {
    result: 'success',
    data: {
      scooterId: 'QAT-0014',
      rentId: '1111',
    },
  });
  // mock.onPost(`/v4/users/me/rents`).reply(500, {
  //   result: 'fail',
  //   error: { type: 'RENT_NOT_FOUND' },
  // });

  // mock.onPatch(/v4\/users\/me\/rents\/\d+/).reply(200, {
  //   result: 'success',
  //   data: {
  //     scooterId: 'QAT-0001',
  //     rentDuration: '00:00:53',
  //     totalRideMinutes: 0,
  //     price: '15',
  //     rentId: '187789',
  //     rentAt: '2019-01-09T06:19:43.000Z',
  //     isRentTimeTooShort: false,
  //     parkingLotId: '1',
  //     distances: '15',
  //     promotionEvents: [
  //       {
  //         name: 'iphoneDraw',
  //         promotionCodes: ['iphoneDraw-3I8', 'iphoneDraw-AAA'],
  //       },
  //     ],
  //   },
  // });
  // mengo
  mock.onPatch(/v4\/users\/me\/rents\/\d+/).reply(400, {
    result: 'fail',
    error: {
      type: 'RENT_FALLBACK_MENGO_SERVICE_UNAVAILABLE',
      // type: 'RENT_FALLBACK_MENGO_POINT_NOT_ENOUGH',
      replacingPricingPlan: null,
    },
  });
  // mock.onPatch(/v4\/users\/me\/rents\/\d+/).reply(500, {
  //   result: 'fail',
  //   error: {
  //     // type: 'INVALID_PRICING_PLAN',
  //     // type: 'RENT_NOT_FOUND',

  //     // type: 'RENT_USER_RESERVATION_IS_CONFLICT',
  //     type: 'USER_PAYMENT_ERROR',
  // type:'RENT_FALLBACK'
  //     // type: 'SCOOTER_NOT_IN_ZONE',
  //     data: {
  //       originalTimePlan: {
  //         id: '1',
  //         type: 'hourlyPlan',
  //         name: '6H內免費',
  //         useBeginAt: '2019-03-01T16:00:00Z',
  //         useEndAt: '2019-03-02T16:00:00Z',
  //         isDefault: true,
  //         totalFreeMinutes: 120,
  //         usedFreeMinutes: 32,
  //         detailUrl: 'https://wemoscooter.com',
  //         price: 99,
  //         meta: {
  //           unitPerRent: 25,
  //           unit: 'point',
  //           freeMinutesPerRent: 12,
  //         },
  //         isDeletable: false,
  //       },
  //       originalPricingPlan: {
  //         id: '1',
  //         startPrice: 15,
  //         freeMinutes: 10,
  //         pricePerMinute: 2.5,
  //         isDefault: true,
  //         isBasic: true,
  //         name: '一般人',
  //       },
  //       replacingPricingPlan: {
  //         id: '1',
  //         startPrice: 15,
  //         freeMinutes: 10,
  //         pricePerMinute: 2.5,
  //         isDefault: true,
  //         isBasic: true,
  //         name: '不一般人',
  //       },
  //     },
  //   },
  // });

  mock.onPatch(/v4\/scooters\/\d+/).reply(config => console.log(config));

  // reserve
  // result: "success"
  // time: "0"
  // bat_percent: "100"
  // power: "0"
  // lat: "25.0529"
  // lng: "121.5467629"
  // scooter_group_id: "0"
  // distance_remaining: "45"
  // ride_distance: "0"

  // renting
  // result: "success"
  // time: "5"
  // bat_percent: "100"
  // power: "0"
  // lat: "25.0529"
  // lng: "121.5467629"
  // scooter_group_id: "0"
  // distance_remaining: "45"
  // ride_distance: "0"

  // fail
  // {
  //   result: 'fail',
  // };
  mock.onPost('/service.asmx/KeyPageInfo').reply(200, {
    result: 'success',
    time: '0',
    bat_percent: '100',
    power: '0',
    lat: '25.0529379',
    lng: '121.55',
    scooter_group_id: '0',
    distance_remaining: '45',
    ride_distance: '0',
    zones: [
      [
        [25.032358, 121.569987],
        [25.032398, 121.572674],
        [25.030453, 121.574254],
        [25.030394, 121.575727],
        [25.033739, 121.576987],
        [25.035469, 121.579105],
        [25.037326, 121.585028],
        [25.038157, 121.588225],
        [25.037494, 121.590725],
        [25.037872, 121.593627],
        [25.040878, 121.593836],
        [25.044791, 121.594427],
        [25.046745, 121.594705],
        [25.050283, 121.595746],
        [25.052421, 121.614726],
        [25.048937, 121.613713],
        [25.049022, 121.612853],
        [25.048335, 121.612272],
        [25.045209, 121.612347],
        [25.045192, 121.614078],
        [25.04465, 121.614306],
        [25.044726, 121.61542],
        [25.042352, 121.616776],
        [25.041535, 121.616843],
        [25.040786, 121.617639],
        [25.038886, 121.621248],
        [25.038012, 121.622466],
        [25.039354, 121.623427],
        [25.040016, 121.622688],
        [25.040135, 121.621724],
        [25.041807, 121.62031],
        [25.045435, 121.617949],
        [25.048887, 121.616295],
        [25.051079, 121.616207],
        [25.051376, 121.616394],
        [25.052382, 121.618092],
        [25.054015, 121.620705],
        [25.054491, 121.62068],
        [25.057125, 121.621407],
        [25.062014, 121.617778],
        [25.06352, 121.614219],
        [25.062811, 121.612977],
        [25.060118, 121.609984],
        [25.058972, 121.605027],
        [25.058428, 121.599684],
        [25.058613, 121.593997],
        [25.058097, 121.592345],
        [25.055347, 121.588912],
        [25.053899, 121.58638],
        [25.053024, 121.583633],
        [25.052655, 121.580694],
        [25.051002, 121.573527],
        [25.051624, 121.57093],
        [25.063928, 121.568849],
        [25.066992, 121.568648],
        [25.066168, 121.564155],
        [25.063977, 121.553882],
        [25.063734, 121.549688],
        [25.068457, 121.542048],
        [25.068671, 121.528316],
        [25.071548, 121.528337],
        [25.075046, 121.521642],
        [25.07664, 121.515784],
        [25.083282, 121.513242],
        [25.090382, 121.511863],
        [25.09362, 121.509834],
        [25.094352, 121.503537],
        [25.096601, 121.498528],
        [25.098532, 121.496749],
        [25.110191, 121.495852],
        [25.114115, 121.489824],
        [25.112813, 121.484536],
        [25.110103, 121.477815],
        [25.109758, 121.469348],
        [25.110239, 121.466187],
        [25.108693, 121.465894],
        [25.105164, 121.474269],
        [25.10329, 121.48168],
        [25.100242, 121.487745],
        [25.095344, 121.494105],
        [25.092934, 121.497384],
        [25.089628, 121.501432],
        [25.085259, 121.505549],
        [25.076698, 121.508317],
        [25.069818, 121.509132],
        [25.056659, 121.507459],
        [25.046648, 121.503146],
        [25.044626, 121.500978],
        [25.04066, 121.497803],
        [25.0358, 121.490121],
        [25.033544, 121.488662],
        [25.030356, 121.488018],
        [25.017329, 121.493468],
        [25.01589, 121.497717],
        [25.023745, 121.512523],
        [25.023337, 121.515441],
        [25.021743, 121.516256],
        [25.02009, 121.522393],
        [25.010698, 121.528766],
        [25.00852, 121.5325],
        [25.00327, 121.535193],
        [24.995666, 121.53559],
        [24.993253, 121.537242],
        [24.989889, 121.536513],
        [24.988451, 121.537263],
        [24.988022, 121.542241],
        [24.988152, 121.544462],
        [24.986412, 121.545083],
        [24.984123, 121.545072],
        [24.983542, 121.546138],
        [24.984146, 121.54929],
        [24.985654, 121.550737],
        [24.987188, 121.551217],
        [24.986205, 121.553023],
        [24.984469, 121.554055],
        [24.980352, 121.553219],
        [24.976913, 121.554582],
        [24.976808, 121.556529],
        [24.977416, 121.557224],
        [24.979646, 121.557894],
        [24.980119, 121.559215],
        [24.979772, 121.561807],
        [24.981468, 121.565301],
        [24.981105, 121.567347],
        [24.9827031, 121.5717074],
        [24.9835702, 121.5734065],
        [24.984515, 121.575213],
        [24.985168, 121.576526],
        [24.9850282, 121.5798439],
        [24.988641, 121.5804361],
        [24.9892856, 121.5802945],
        [24.989754, 121.5804804],
        [24.989716, 121.578779],
        [24.994746, 121.575402],
        [24.998576, 121.581366],
        [24.999274, 121.580634],
        [24.998571, 121.575566],
        [25.003916, 121.572776],
        [25.006381, 121.570072],
        [25.009706, 121.566403],
        [25.018281, 121.561254],
        [25.020692, 121.559805],
        [25.022338, 121.562074],
        [25.022176, 121.563413],
        [25.022728, 121.563991],
        [25.023324, 121.563464],
        [25.025625, 121.564092],
        [25.024287, 121.565725],
        [25.023639, 121.565126],
        [25.02168, 121.567712],
        [25.018559, 121.570968],
        [25.021952, 121.574074],
        [25.032358, 121.569987],
      ],
      [
        [25.084909, 121.584663],
        [25.085074, 121.579771],
        [25.084239, 121.577883],
        [25.086532, 121.571703],
        [25.0887085, 121.5680986],
        [25.0892138, 121.558732],
        [25.086141, 121.552228],
        [25.085108, 121.547],
        [25.085444, 121.53632],
        [25.082975, 121.537628],
        [25.081659, 121.540852],
        [25.077524, 121.540793],
        [25.077242, 121.544215],
        [25.077029, 121.556254],
        [25.075707, 121.563292],
        [25.076892, 121.569386],
        [25.075065, 121.571768],
        [25.071917, 121.573162],
        [25.065386, 121.572218],
        [25.057037, 121.573527],
        [25.052878, 121.576177],
        [25.054034, 121.581917],
        [25.056775, 121.587541],
        [25.059788, 121.591959],
        [25.0608376, 121.5938468],
        [25.0595157, 121.5992547],
        [25.0598276, 121.6038897],
        [25.0607602, 121.6036318],
        [25.0622759, 121.6104125],
        [25.0646962, 121.6125101],
        [25.066499, 121.617413],
        [25.066999, 121.617279],
        [25.067801, 121.617569],
        [25.068175, 121.618481],
        [25.068932, 121.619967],
        [25.070194, 121.621324],
        [25.0717654, 121.621195],
        [25.0732776, 121.6205241],
        [25.0748161, 121.6214872],
        [25.0753633, 121.622815],
        [25.0765549, 121.6226812],
        [25.0779992, 121.6173466],
        [25.0879066, 121.6043293],
        [25.09036, 121.5994558],
        [25.08873, 121.5964825],
        [25.0873795, 121.5941422],
        [25.0885673, 121.5934288],
        [25.090297, 121.5917338],
        [25.0884462, 121.5902803],
        [25.0889549, 121.5877992],
        [25.0896748, 121.5837135],
        [25.0885578, 121.5834731],
        [25.0876157, 121.5869449],
        [25.0851486, 121.5895969],
        [25.083227, 121.588783],
        [25.084909, 121.584663],
      ],
      [
        [25.124071, 121.517415],
        [25.128855, 121.509927],
        [25.131143, 121.506751],
        [25.136134, 121.511007],
        [25.137159, 121.516257],
        [25.141778, 121.516014],
        [25.14768, 121.505472],
        [25.146043, 121.493233],
        [25.144068, 121.489413],
        [25.142341, 121.488233],
        [25.13973, 121.483738],
        [25.1361811, 121.4781534],
        [25.1355598, 121.4779291],
        [25.133188, 121.4747842],
        [25.1316495, 121.4724848],
        [25.129942, 121.4703291],
        [25.1290505, 121.4685169],
        [25.1282938, 121.4674644],
        [25.127125, 121.4658004],
        [25.125218, 121.464823],
        [25.123175, 121.461624],
        [25.11646, 121.463243],
        [25.11796, 121.469396],
        [25.122219, 121.470528],
        [25.121434, 121.488635],
        [25.12074, 121.498607],
        [25.119311, 121.500689],
        [25.116946, 121.503294],
        [25.11462, 121.505855],
        [25.110355, 121.510806],
        [25.101087, 121.511493],
        [25.096538, 121.510158],
        [25.093139, 121.513461],
        [25.091129, 121.514447],
        [25.089118, 121.514876],
        [25.086136, 121.515159],
        [25.083173, 121.515764],
        [25.08174, 121.516389],
        [25.080405, 121.517379],
        [25.076417, 121.524526],
        [25.078194, 121.525001],
        [25.080088, 121.525047],
        [25.083216, 121.526512],
        [25.090307, 121.528198],
        [25.095568, 121.533573],
        [25.097637, 121.5380459],
        [25.0972313, 121.539804],
        [25.0970564, 121.5426686],
        [25.0959881, 121.5437363],
        [25.0959409, 121.544484],
        [25.0959131, 121.5452103],
        [25.0951386, 121.5467433],
        [25.0948582, 121.5474991],
        [25.0947915, 121.5483085],
        [25.0956526, 121.5494007],
        [25.098507, 121.548456],
        [25.100505, 121.551784],
        [25.103242, 121.549791],
        [25.098417, 121.543144],
        [25.100771, 121.537813],
        [25.108961, 121.534514],
        [25.109831, 121.540718],
        [25.118671, 121.539817],
        [25.123543, 121.537194],
        [25.127093, 121.53397],
        [25.126797, 121.528042],
        [25.125258, 121.525291],
        [25.123839, 121.521878],
        [25.124071, 121.517415],
      ],
    ],
  });

  mock.onDelete(/\/v4\/users\/me\/rents\/\d+/).reply(200, {
    result: 'success',
    data: {
      scooterId: 'QAT-0014',
    },
  });

  mock.onGet('/v4/users/me/zones').reply(200, {
    result: 'success',
    data: {},
  });
};
//

export default mockUsers;

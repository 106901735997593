import { useContext, useCallback } from 'react';
import { EditingUserContext } from '../providers/EditingUserProvider';
import ExpiredStorage from 'expired-storage';

const expiredStorage = new ExpiredStorage();

const useEditingUser = () => {
  const [editingUser, dispatch] = useContext(EditingUserContext);
  // console.log('user', user);

  const setEditingUser = useCallback(payload => {
    dispatch({ type: 'setEditingUser', payload });
  }, []);

  const resetEditingUser = useCallback(() => {
    dispatch({ type: 'resetEditingUser' });
    expiredStorage.removeItem('editingUser');
  }, []);

  const setEditingUserUserInvitation = useCallback(payload => {
    dispatch({ type: 'setEditingUserUserInvitation', payload });
  }, []);

  const setEditingUserUserInfo = useCallback(payload => {
    dispatch({ type: 'setEditingUserUserInfo', payload });
  }, []);

  const setScannedIdCard = useCallback(payload => {
    dispatch({ type: 'setScannedIdCard', payload });
  }, []);

  // dev login (external browser line login)
  return {
    editingUser,
    setEditingUser,
    setScannedIdCard,
    resetEditingUser,
    setEditingUserUserInvitation,
    setEditingUserUserInfo,
  };
};

export { useEditingUser };

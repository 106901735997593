import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';

import RadioButton from './RadioButton';

const RadioGroupStyled = styled.div`
  width: 100%;
`;

const RadioGroup = ({ value, colConfig, intitialValue, children, onChange, ...props }) => {
  const [currentValue, setCurrentValue] = useState(value);

  const handleOnChange = evt => {
    typeof onChange === 'function' ? onChange(evt) : setCurrentValue(evt.target.value);
  };

  return (
    <RadioGroupStyled>
      <Row>
        {React.Children.map(
          children,
          child =>
            child.type === RadioButton && (
              <Col {...colConfig}>
                {React.cloneElement(child, {
                  onChange: handleOnChange,
                  intitialValue,
                  currentValue: value || currentValue,
                  props,
                })}
              </Col>
            )
        )}
      </Row>
    </RadioGroupStyled>
  );
};

RadioGroup.defaultProps = {
  colConfig: { xs: 6 },
};

RadioGroup.propTypes = {
  defaultValue: PropTypes.string,
  children: PropTypes.array,
  colConfig: PropTypes.object,
};

export default RadioGroup;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { layout, space } from 'styled-system';
import { Row, Col } from 'react-grid-system';
import Button from 'Components/atoms/Button';
import Form from 'Components/atoms/Form';
import FormItem from 'Components/atoms/Form/FormItem';
import Input from 'Components/atoms/Input';
import Radio from 'Components/atoms/Radio';
import PageHeader from 'Components/molecules/PageHeader';
import PopupNote from 'Components/molecules/PopupNote';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import ExpiredStorage from 'expired-storage';
import { postLineIDTokenVerify } from '~/services/api';
import { useErrorNotification } from '~/context/ErrorNotification';

const PhotoNameStorageExpiredTime = 60 * 60;
const expiredStorage = new ExpiredStorage();
const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const errorsConfig = {
  LINE_CANNOT_GET_USER_PROFILE: {
    popup: {
      title: 'LINE API 異常',
      notes: '無法取得 LINE 使用者資訊',
      confirmButtonText: '確認',
    },
    notifier: 'popup',
  },
  LINE_INVALID_ID_TOKEN: {
    popup: {
      title: 'LINE API 異常',
      notes: '無法驗證 LINE 使用者資訊',
      confirmButtonText: '確認',
    },
    notifier: 'popup',
  },
};

const SignUpUserInfoStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${layout}
  ${space}
`;

const FormContainer = styled.div`
  margin-top: 30px;
`;

const RowStyled = styled(Row)`
  align-self: flex-end;
  width: 100%;
`;

const SignUpUserInfo = Form.create()(
  ({
    defaultValue,
    setEditingUserUserInfo,
    initPhone,
    initEmail,
    onPreviousClick,
    onNextClick,
    setScannedIdCard,
    form,
  }) => {
    const { getFieldDecorator, validateFields, getFieldsValue } = form;
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const history = useHistory();
    const { registerErrorConfig, launchError } = useErrorNotification();

    const handleOnPreviousClick = () => {
      setEditingUserUserInfo(getFieldsValue());
      onPreviousClick();
    };

    const onSubmit = async () => {
      // TODO: check and submit
      try {
        const result = await validateFields();
        setEditingUserUserInfo(result);
        onNextClick();
      } catch (error) {
        console.log(error);
      }
    };

    const checkIsValid = () => {
      const formValue = getFieldsValue();
      return Object.entries(formValue).every(([field, value]) => value);
    };

    useEffect(() => {
      registerErrorConfig('sign-up-user-info', errorsConfig);

      (async () => {
        const urlQuery = qs.parse(window.location.search.slice(1));
        let { photos, idCard, token } = urlQuery;

        if (!token) {
          history.replace('/sign-up?step=1');
          return;
        }

        // TODO: 這部分要調整
        window.history.replaceState(
          null,
          document.title,
          `${history.location.pathname}?${qs.stringify({ token })}`
        );

        const { userId: lineUserId } = await window.liff.getProfile().catch(error => {
          launchError({ type: 'LINE_CANNOT_GET_USER_PROFILE' }, 'sign-up-user-info');
          throw error;
        });
        await postLineIDTokenVerify(token, lineUserId).catch(error => {
          launchError({ type: 'LINE_INVALID_ID_TOKEN' }, 'sign-up-user-info');
          throw error;
        });

        photos = photos || [...(expiredStorage.getJson('registerPhotoNames') || [])];
        if (photos.length !== 5) {
          onPreviousClick();
          return;
        }

        expiredStorage.setJson('registerPhotoNames', photos, PhotoNameStorageExpiredTime);

        idCard = idCard ? idCard : expiredStorage.getItem('registerIdCard');
        if (idCard) {
          expiredStorage.setItem('registerIdCard', idCard, PhotoNameStorageExpiredTime);
          setScannedIdCard(idCard);
        }
      })();
    }, []);

    return (
      <SignUpUserInfoStyled paddingBottom={[20, 'initial']} height={['auto', 'calc(100% - 40px)']}>
        <PageHeader
          title="輸入個人資料"
          hasInfo={true}
          infoOnClick={() => setIsPopupVisible(true)}
        ></PageHeader>
        <FormContainer>
          <Form>
            <Row gutterWidth={24}>
              <Col xs={6}>
                <FormItem label="姓氏" prompt="需與證件相符">
                  {getFieldDecorator('lastName', {
                    rules: [{ required: true, message: '(請填寫姓名)' }],
                    initialValue: defaultValue?.lastName,
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col xs={6}>
                <FormItem label="名字" prompt="需與證件相符">
                  {getFieldDecorator('firstName', {
                    rules: [{ required: true, message: '(請填寫姓名)' }],
                    initialValue: defaultValue?.firstName,
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col xs={12}>
                <FormItem>
                  {getFieldDecorator('gender', { initialValue: defaultValue?.gender || 'M' })(
                    <Radio.Group>
                      <Radio.Button value="M">男性</Radio.Button>
                      <Radio.Button value="F">女性</Radio.Button>
                    </Radio.Group>
                  )}
                </FormItem>
              </Col>
              <Col xs={12}>
                <FormItem label="選擇你的出生日期">
                  {getFieldDecorator('birth', {
                    rules: [{ required: true, message: '(請選擇出生日期)' }],
                    initialValue: defaultValue?.birth,
                  })(<Input type="date" />)}
                </FormItem>
              </Col>
              <Col xs={12}>
                <FormItem label="聯絡電子信箱">
                  {getFieldDecorator('email', {
                    rules: [
                      { required: true },
                      {
                        required: true,
                        pattern: emailRegExp,
                        message: '(信箱格式不符)',
                      },
                    ],
                    initialValue: defaultValue?.email,
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col xs={12}>
                <FormItem label="身分證字號 / 護照號碼">
                  {getFieldDecorator('idcard', {
                    rules: [{ required: true, message: '(請填寫證件號碼)' }],
                    initialValue: defaultValue?.idcard,
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col xs={12}>
                <FormItem label="通訊地址">
                  {getFieldDecorator('address', {
                    rules: [{ required: true, message: '(請填寫居住地址)' }],
                    initialValue: defaultValue?.address,
                  })(<Input />)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </FormContainer>
        <RowStyled>
          <Col xs={6}>
            <Button
              shape="capsule"
              type="float"
              color="plan"
              boxShadow="light"
              width={1}
              onClick={handleOnPreviousClick}
            >
              上一步
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              shape="capsule"
              type="float"
              boxShadow="light"
              width={1}
              disabled={!checkIsValid()}
              onClick={onSubmit}
            >
              下一步
            </Button>
          </Col>
        </RowStyled>
        <PopupNote
          visible={isPopupVisible}
          title="為什麼要輸入個人資料？"
          notes="依中華民國交通部規定，請填寫與證件完全相符之資料，若資料有誤將無法通過審核。您的個人資料僅供 WeMo Scooter 內部使用。"
          onConfirmClick={() => setIsPopupVisible(false)}
          confirmButtonText="確認"
        />
      </SignUpUserInfoStyled>
    );
  }
);

SignUpUserInfo.defaultProps = {};

SignUpUserInfo.propTypes = {};

export default SignUpUserInfo;

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Components/atoms/Button';
import { ReactComponent as Camera } from 'Images/icons/camera.svg';
import { ReactComponent as Check } from 'Images/icons/blue-check.svg';

const ButtonPicture = ({ onClick, isChecked, ...props }) => {
  return (
    <Button
      shape="capsule"
      color={isChecked ? 'uselessBlue' : 'primary'}
      type="float"
      boxShadow="light"
      width={1}
      IconComponent={isChecked ? Check : Camera}
      onClick={onClick}
      {...props}
    ></Button>
  );
};

ButtonPicture.defaultProps = {};

ButtonPicture.propTypes = {
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
};

export default ButtonPicture;

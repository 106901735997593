import React, { useContext } from 'react';
import styled from 'styled-components';
import CardMap from 'Components/atoms/CardMap';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import Button from 'Components/atoms/Button';
import { useUserInfo } from '~/context';
import { useHistory } from 'react-router-dom';

const ButtonStyled = styled(Button)`
  margin: 0 auto;
  min-width: 72%;
`;

const FlexWrapper = styled.div`
  ${space}
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  top: 0;
`;

const Title = styled.span`
  ${props => themeGet(`fonts.subtitle`)};
  color: ${props => themeGet(`colors.wrongRed`)};
  margin-bottom: 2px;
  width: 100%;
`;

const Price = styled.span`
  ${props => themeGet(`fonts.subtitle`)};
  color: ${props => themeGet(`colors.mainDark`)};
  width: 100%;
  margin-bottom: 2px;
`;

const Text = styled.span`
  ${props => themeGet(`fonts.body2`)};
  color: ${props => themeGet(`colors.mainDark`)};
  width: 100%;
  margin-bottom: 4px;
`;

export const PureCardUnpaid = cardProps => {
  const { user } = useUserInfo();
  const history = useHistory();

  const handleFinishPayment = () => {
    history.replace({
      pathname: '/choose-payment',
    });
  };

  return (
    <CardMap ratio={[0.65, 0.6]} {...cardProps}>
      <FlexWrapper padding={['10px 10px', '20px 24px', '20px 30px']}>
        <Title>累計未付金額</Title>
        <Price>NT${user.unpaidAmount}</Price>
        <Text>
          你近期騎乘的付款未成功
          <br />
          請重設你的付款方式並結清款項以繼續使用
        </Text>
        <ButtonStyled
          shape="capsule"
          boxShadow="light"
          type="float"
          color="primary"
          onClick={handleFinishPayment}
        >
          前往完成付款
        </ButtonStyled>
      </FlexWrapper>
    </CardMap>
  );
};

PureCardUnpaid.defaultProps = {};

PureCardUnpaid.propTypes = {};

export default React.memo(PureCardUnpaid);

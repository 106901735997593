const GAP = 10;

const tenMillisecondTimer = (gap = GAP) =>
  new Promise(resolve => {
    const stamp = Date.now();
    setTimeout(() => {
      const now = Date.now();
      resolve([now, (now - stamp - gap) * -1]);
    }, gap);
  });

const polyfill = async duration => {
  let start = Date.now();
  let end = start + duration;
  let stamp = start;
  let gap = GAP;

  while (stamp + GAP <= end) {
    const [_stamp, diff] = await tenMillisecondTimer(gap);
    stamp = _stamp;
    const _gap = GAP + diff;
    gap = _gap > 0 ? _gap : GAP;
  }

  if (stamp < end) {
    const [_stamp] = await tenMillisecondTimer(end - stamp);
    stamp = _stamp;
  }

  return stamp;
};

export default async duration => {
  await polyfill(duration);
};

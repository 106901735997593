import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from 'styled-system';
import Lottie from 'react-lottie';
import animationData from './launchAnimation.json';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PureLaunchAnimation = ({ onComplete }) => {
  return (
    <Container>
      <Lottie
        width={150}
        height="auto"
        eventListeners={[
          {
            eventName: 'complete',
            callback: typeof onComplete === 'function' && onComplete,
          },
        ]}
        options={{
          ...defaultOptions,
        }}
      />
    </Container>
  );
};

PureLaunchAnimation.defaultProps = {};

PureLaunchAnimation.propTypes = { onComplete: PropTypes.func };

export default React.memo(PureLaunchAnimation);

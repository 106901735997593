import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, layout, compose } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { useRenting } from '~/context';
import CardMap from 'Components/atoms/CardMap';
import WarningText from 'Components/molecules/WarningText';
import Tabs from './Tabs';
import ParkingLotInfo from './ParkingLotInfo';
import ScooterList from './ScooterList';
import { parkingLotStatusMap } from '~/configs/';

// import ChristmasLights from 'Components/atoms/ChristmasLights';

const { TabPane } = Tabs;

const Container = styled.div`
  box-sizing: border-box;
  ${compose(space, layout)};
`;

const Header = styled.div`
  height: 80px;
`;

const Text = styled.div`
  height: 80px;
`;

const PlanText = styled.div`
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.supGrey};
  background: ${props => props.theme.colors.typeSpaceGrey};
  padding: 20px 43px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

const TitlePanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h6`
  ${themeGet('fonts.subtitle')};
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 4px;
`;

const StatusTag = styled.label`
  flex: 0 0 auto;
  background: ${({ backgroundColor = 'mainGreen' }) => themeGet(`colors.${backgroundColor}`)};
  border-radius: 4px;
  ${themeGet('fonts.note')};
  color: ${themeGet('colors.white')};
  box-sizing: border-box;
  height: 18px;
  padding: 2px 4px;
  text-align: center;
`;

const DescriptionPanel = styled.div`
  width: 100%;
`;

const Description = styled.p`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
  margin-top: 4px;
`;

const tabWidth = { width: 'calc(calc(100% - 20px) / 2)' };

const statusSpecs = {
  [parkingLotStatusMap.normal]: {
    text: '營運中',
    color: 'mainGreen',
    isScooterAvailable: true,
  },
  [parkingLotStatusMap.errorBothDisabled]: {
    text: '營運異常',
    color: 'wrongRed',
    isScooterAvailable: false,
    scooterInfoText: {
      gateless: <WarningText text={'此場站因特別因素進行維護中，暫時關閉！不便之處敬請見諒！'} />,
      default: (
        <WarningText
          text={'系統維護中無法租車，若要還車請先取代幣 / 票卡進場，並交還停車場管理室。'}
        />
      ),
    },
  },
  [parkingLotStatusMap.aboutToClose]: {
    text: '即將關閉',
    color: 'warningOrange',
    isScooterAvailable: true,
  },
  [parkingLotStatusMap.closed]: {
    text: '休息中',
    color: 'secondaryGrey',
    isScooterAvailable: false,
    scooterInfoText: { default: <PlanText>目前沒有可租借車輛</PlanText> },
  },
};

export const PureCardParkingLot = props => {
  const { ratio, ...cardProps } = props;
  const {
    mapParkingLot: {
      name,
      state,
      availableSpaces,
      description,
      scooters,
      openType,
      businessHours,
      regularBusinessHours,
      specialBusinessHours,
      totalSpaces,
      phone,
      address,
      images,
    } = {},
  } = useRenting();
  const headerEl = useRef();
  const [cardHeight, setCardHeight] = useState(0);
  const [tabPaneHeight, setTabPaneHeight] = useState(0);
  const currentStatusSpec = statusSpecs[state.key];

  const getCardHeight = useCallback(node => {
    if (!node) return;
    const { height } = node.getBoundingClientRect();
    setCardHeight(height);
  }, []);

  useEffect(() => {
    if (!headerEl.current) return;
    const { height: headerHeight } = headerEl.current.getBoundingClientRect();
    setTabPaneHeight(cardHeight - headerHeight - 30);
  }, [cardHeight]);

  return (
    <CardMap ref={getCardHeight} ratio={ratio} {...cardProps}>
      {/* <ChristmasLights /> */}
      <Container height={cardHeight} padding={['10px', '20px']}>
        <Header ref={headerEl}>
          <TitlePanel>
            <Title>{name}</Title>
            <StatusTag backgroundColor={currentStatusSpec?.color}>
              {currentStatusSpec?.text}
            </StatusTag>
          </TitlePanel>
          <DescriptionPanel>
            <Description>
              {`剩餘位子 ${
                availableSpaces === null || !currentStatusSpec?.isScooterAvailable
                  ? '-'
                  : availableSpaces
              } | ${description}`}
            </Description>
          </DescriptionPanel>
        </Header>
        <Tabs>
          <TabPane
            tab={`機車資訊 ${currentStatusSpec?.isScooterAvailable ? scooters.length : '-'}`}
            tabStyle={tabWidth}
            contentStyle={{ height: [tabPaneHeight - 10, tabPaneHeight - 20] }}
          >
            {!currentStatusSpec?.isScooterAvailable ? (
              currentStatusSpec?.scooterInfoText?.[openType] ||
              currentStatusSpec?.scooterInfoText?.default
            ) : (
              <ScooterList scooters={scooters} />
            )}
          </TabPane>
          <TabPane
            tab="停車場資訊"
            tabStyle={tabWidth}
            contentStyle={{ height: [tabPaneHeight - 10, tabPaneHeight - 20] }}
          >
            <ParkingLotInfo
              regularBusinessHours={regularBusinessHours}
              specialBusinessHours={specialBusinessHours}
              businessHours={businessHours}
              totalSpaces={totalSpaces}
              phone={phone}
              address={address}
              openType={openType}
              mapsUrl={images?.maps}
            />
          </TabPane>
        </Tabs>
      </Container>
    </CardMap>
  );
};

PureCardParkingLot.defaultProps = {
  ratio: [1.49, 1.49],
};

PureCardParkingLot.propTypes = {
  ratio: PropTypes.array,
};

export default React.memo(PureCardParkingLot);

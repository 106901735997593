import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import qs from 'qs';
import dayjs from 'dayjs';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { useRenting } from '~/context/';
import { rentStatus, paymentTypes } from '~/configs/';
import PopupExceptionReturn from 'Components/molecules/PopupExceptionReturn';
import {
  returnScooterOpenTrunk,
  getPaymentsInfo,
  postUserOrderTransaction,
  getLinePayPrime,
  getUserHistoryById,
} from '~/services/api';
import useAsyncGet from '~/hooks/useAsyncGet';
import BasicContainer from 'Components/layouts/BasicContainer';
import FullScreenLoading from '~/components/molecules/FullScreenLoading';
import RidingDetail from '~/components/atoms/RidingDetail';
import userLoading from '~/hooks/useLoading';
import Button from '~/components/atoms/Button';
import CountDown from 'Components/atoms/CountDown';
import PopupLoading from '~/components/molecules/PopupLoading';
import PopupNote from '~/components/molecules/PopupNote';
import { ReactComponent as OpenTrunk } from 'Images/icons/openTrunk.svg';
import { ReactComponent as Camera } from 'Images/icons/camera-color.svg';
import { ReactComponent as CameraCheck } from 'Images/icons/camera-check.svg';
import { sign, verify } from '~/utils/externalToken';
import wemoToken from '~/utils/wemoToken';
import { RentingProvider } from '~/context/providers/RentingProvider';
import SwipeUpPage from 'Components/atoms/SwipeUpPage';
import TakeReturnPhotoV2 from 'Components/pages/TakeReturnPhotoV2';

// import ChristmasLights from 'Components/atoms/ChristmasLights';

const OS = window.liff ? window.liff.getOS() : 'android';

const getExternalUrl = ({ domain, pathName, redirectTo = '/ride-detail', urlQuery = {} }) => {
  const token = sign();
  const { accessToken } = wemoToken.getToken();
  return `${domain ||
    process.env
      .REACT_APP_VENUS_URL}${pathName}?token=${token}&wemoToken=${accessToken}&redirectTo=${redirectTo}&${qs.stringify(
    urlQuery
  )}`;
};

/**
 * ! 如果修改此處要記得 ChoosePayment 也可能要同時修改
 */
const orderTransaction = async (orderId, amount, paymentType, redirectUrl, applePayUrlQuery) => {
  if (paymentType === paymentTypes.linePay) {
    const prime = await getLinePayPrime();
    const { paymentUrl } = await postUserOrderTransaction({
      orderId,
      amount,
      paymentType,
      redirectUrl,
      prime,
      isWaiting: true,
    });
    return { paymentUrl };
  } else if (paymentType === paymentTypes.applePay) {
    const { unpaidAmount } = await postUserOrderTransaction({
      orderId,
      amount,
      paymentType,
      isWaiting: true,
    });

    if (unpaidAmount === undefined || unpaidAmount === null) {
      throw new Error('no get unpaidAmount');
    }

    if (unpaidAmount !== 0) {
      window.liff.openWindow({
        url: getExternalUrl({
          domain: process.env.REACT_APP_APPLE_PAY_URL,
          pathName: '/pay-by-apple-pay',
          urlQuery: {
            ...applePayUrlQuery,
            unpaidAmount,
            paymentType,
          },
        }),
        external: true,
      });
    }

    return { unpaidAmount };
  } else {
    // isWaiting 顯示可能會有問題，不確定 bills 資料會不會產生
    await postUserOrderTransaction({ orderId, amount, paymentType, isWaiting: false });
    return {};
  }
};

const userRentHistoryFormatter = api => {
  return async (...args) => api(...args).then(({ rent } = {}) => rent || {});
};

const Container = styled(React.forwardRef((props, ref) => <BasicContainer ref={ref} {...props} />))`
  background-color: ${themeGet('colors.bgGrey')};
  overflow-y: scroll;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  ${space};
`;

const LabelButtonPanel = styled.div`
  width: 120px;
`;

const Label = styled.p`
  ${themeGet(`fonts.body2`)};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
  margin-bottom: 4px;
  text-align: center;
`;

const LabelButton = styled(Button)`
  width: 120px;
`;

const OpenTrunkCountdown = styled(CountDown)`
  margin-left: 2px;
  color: ${themeGet('colors.secondaryGrey')};
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const FooterButton = styled(Button)`
  width: 240px;
`;

const PureRideDetail = () => {
  const history = useHistory();
  const urlQuery = useMemo(() => qs.parse(window.location.search.slice(1)), []);
  const isNeedToPay = useMemo(() => urlQuery.isRentTimeTooShort === 'false' && urlQuery.orderId, [
    urlQuery,
  ]);
  const { updateUserMode } = useRenting();
  const isExceptionReturn = useMemo(() => (urlQuery.isExceptionReturn === 'true' ? true : false), [
    urlQuery,
  ]);
  const paymentInfo = useAsyncGet(getPaymentsInfo, { loading: true, data: {} });
  const rentHistory = useAsyncGet(userRentHistoryFormatter(getUserHistoryById), {
    loading: true,
    data: {},
  });
  const [isExceptionReturnVisible, setIsExceptionReturnVisible] = useState(isExceptionReturn);
  const screenLoading = userLoading({ loading: true });
  const pupupLoading = userLoading();
  const [payway, setPayway] = useState('none');
  const [enableOpenTrunk, setEnableOpenTrunk] = useState(true);
  const [countdownDuration, setCountdownDuration] = useState(60);
  const [orderStatus, setOrderStatus] = useState('unpaid');
  const [showAlert, setShowAlert] = useState({ visible: false, confirmButtonText: '確認' });
  const containerEl = useRef();
  const [successText, setSuccessText] = useState('支付成功');
  const [swipeUpVisible, setSwipeUpVisible] = useState(false);
  const [hasPhoto, setHasPhoto] = useState(urlQuery.hasPhoto === 'true');

  const [handleOpenTrunkOnClick] = useDebouncedCallback(
    async () => {
      try {
        setSuccessText('車廂開啟');
        pupupLoading.start();
        await returnScooterOpenTrunk(urlQuery.scooterId);
        pupupLoading.end();
      } catch (error) {
        console.log(JSON.stringify(error));
        pupupLoading.end('fail');
        setShowAlert(prev => ({
          ...prev,
          title: '開啟車廂失敗',
          notes: '伺服器發生異常狀況',
        }));
      }
    },
    300,
    { leading: true, trailing: false }
  );

  const [handleTakePhotoOnClick] = useDebouncedCallback(
    () => {
      if (OS === 'ios') {
        setSwipeUpVisible(true);
      } else {
        const { token, hasPhoto, ...otherQuery } = urlQuery;
        window.liff.openWindow({
          url: getExternalUrl({ pathName: '/take-return-photo-v2', urlQuery: otherQuery }),
          external: true,
        });
      }
    },
    300,
    { leading: true, trailing: false }
  );

  const [handlePayOnClick] = useDebouncedCallback(
    async () => {
      if (!isNeedToPay) return;
      setSuccessText('支付成功');
      pupupLoading.start();
      updateUserMode(rentStatus.notRenting);

      try {
        const { rentId, orderId, amount, token, ...otherQuery } = urlQuery;
        const redirectUrl = `${
          process.env.REACT_APP_VENUS_URL
        }/interchange?redirect=/ride-detail?${qs.stringify({
          rentId,
          orderId,
          amount,
          ...otherQuery,
        })}`;
        const applePayUrlQuery = { rentId, orderId, amount, ...otherQuery };

        const { paymentUrl, unpaidAmount } = await orderTransaction(
          orderId,
          amount,
          paymentInfo.data.paymentType,
          redirectUrl,
          applePayUrlQuery
        );

        /**
         * ! 如果選用 Line Pay 但 Order 被騎乘金扣完了就不會拿到 paymentUrl
         */
        if (paymentUrl) {
          window.TPDirect.redirect(paymentUrl);
          pupupLoading.end('normal');
        } else if (paymentInfo.data.paymentType === paymentTypes.applePay && unpaidAmount !== 0) {
          // ! 這裡要注意一下行為
          pupupLoading.end('normal');
        } else {
          const { returnAt } = await rentHistory.getData({
            id: rentId,
            type: 'rent',
          });
          // 這段是因為不等待付款完成，所以先強行設定成已付款
          setOrderStatus('paid');
          const duration = 60 - (dayjs().unix() - dayjs(returnAt).unix());
          setCountdownDuration(duration > 0 ? duration : 0);
          pupupLoading.end();
        }
      } catch (error) {
        console.log(error);
        pupupLoading.end('fail');
        setShowAlert(prev => ({
          ...prev,
          title: '付款失敗',
          notes: '伺服器發生異常狀況',
        }));
      }
    },
    300,
    { leading: true, trailing: false }
  );

  const [handleConfirmOnClick] = useDebouncedCallback(
    () => {
      history.replace('/');
    },
    300,
    { leading: true, trailing: false }
  );

  const handleOnCountToZero = useCallback(() => {
    setEnableOpenTrunk(false);
  }, []);

  const handlePopupLoadingOnFailure = useCallback(() => {
    setShowAlert(prev => ({ ...prev, visible: true }));
  }, []);

  const handlePopupNoteOnConfirm = useCallback(() => {
    setShowAlert(prev => ({ ...prev, visible: false, title: '', note: '' }));
  }, []);

  const handleReturnPhotoOnClose = useCallback(() => {
    setSwipeUpVisible(false);
  }, []);

  const handleReturnPhotoOnFinish = useCallback(() => {
    setSwipeUpVisible(false);
    setHasPhoto(true);
    window.history.replaceState(
      null,
      document.title,
      `${window.location.pathname}?${qs.stringify({
        ...urlQuery,
        hasPhoto: true,
      })}`
    );
  }, [urlQuery]);

  const init = useCallback(async () => {
    const { rentId, token } = urlQuery;

    /**
     * 還車拍照跳回 & Apple，但似乎沒意義，因為不會帶參數回來
     */
    if (token) {
      const payload = await verify(token);
      if (!payload) history.replace('/errors/unauthorized');
    }

    try {
      screenLoading.start();
      const [
        { paymentType, creditCard = { type: '-1' } },
        { returnAt, status = 'noRequired' },
      ] = await Promise.all([
        paymentInfo.getData(),
        isNeedToPay
          ? rentHistory.getData({ id: rentId, type: 'rent' })
          : (() => {
              rentHistory.setData(urlQuery);
              return urlQuery;
            })(),
      ]);
      setOrderStatus(status);
      setPayway({
        paymentType,
        creditCardType: creditCard.type,
        last4Digits: creditCard.last4Digits,
      });
      const duration = 60 - (dayjs().unix() - dayjs(returnAt).unix());
      setCountdownDuration(duration > 0 ? duration : 0);
      screenLoading.end();
    } catch (error) {
      screenLoading.end('fail');
      setShowAlert(prev => ({
        ...prev,
        title: '無法支付已計費用',
        notes: '伺服器發生異常狀況',
      }));
    }
  }, [urlQuery, isNeedToPay]);

  useEffect(() => {
    const container = containerEl.current;
    if (!container) return;
    if (!screenLoading.state.spinning) {
      disablePageScroll(container);
    } else {
      enablePageScroll(container);
    }
  }, [screenLoading.state.spinning]);

  useEffect(() => {
    init();
    const container = containerEl.current;
    return () => {
      container && enablePageScroll(container);
    };
  }, []);

  return (
    <FullScreenLoading {...screenLoading.state} onFailure={handlePopupLoadingOnFailure}>
      <Container ref={containerEl} navigationBar={false} autoHeight={false}>
        {/* <ChristmasLights top="-6px" /> */}
        <Header px={['15px', '20px']}>
          <LabelButtonPanel>
            <Label>
              開啟車廂
              <OpenTrunkCountdown
                duration={countdownDuration}
                type="second"
                size="small"
                suffix="s"
                onCountToZero={handleOnCountToZero}
              />
            </Label>
            <LabelButton
              boxShadow="light"
              shape="capsule"
              type="float"
              color="plan"
              IconComponent={OpenTrunk}
              disabled={!enableOpenTrunk}
              onClick={handleOpenTrunkOnClick}
            />
          </LabelButtonPanel>
          <LabelButtonPanel>
            <Label>還車拍照</Label>
            <LabelButton
              boxShadow="light"
              shape="capsule"
              type="float"
              color="plan"
              IconComponent={hasPhoto || isExceptionReturn ? CameraCheck : Camera}
              onClick={handleTakePhotoOnClick}
            />
          </LabelButtonPanel>
        </Header>
        <RidingDetail
          scooterId={rentHistory.data.scooterId}
          rentAt={rentHistory.data.rentAt}
          returnAt={rentHistory.data.returnAt}
          totalRideMinutes={Number(rentHistory.data.totalRideMinutes)}
          rideDistance={rentHistory.data.distances}
          note={
            Number(rentHistory.data.totalRideMinutes) > 2 && !isNeedToPay
              ? '租借時間過短無須支付費用'
              : ''
          }
          totalAmount={rentHistory.data.totalAmount}
          bills={rentHistory.data.bills}
          {...payway}
        />
        <Footer>
          {isNeedToPay && orderStatus === 'unpaid' ? (
            <FooterButton boxShadow="light" shape="capsule" type="float" onClick={handlePayOnClick}>
              支付
            </FooterButton>
          ) : (
            <FooterButton
              boxShadow="light"
              shape="capsule"
              type="float"
              onClick={handleConfirmOnClick}
            >
              確認
            </FooterButton>
          )}
        </Footer>
      </Container>
      <PopupLoading
        {...pupupLoading.state}
        successText={successText}
        onFailure={handlePopupLoadingOnFailure}
      />
      <PopupNote {...showAlert} onConfirmClick={handlePopupNoteOnConfirm} />
      {isExceptionReturn && (
        <PopupExceptionReturn
          status={rentStatus.confirming}
          onClose={() => setIsExceptionReturnVisible(false)}
          visible={isExceptionReturnVisible}
          maskClosable={true}
        />
      )}
      {OS === 'ios' && (
        <SwipeUpPage visible={swipeUpVisible}>
          <TakeReturnPhotoV2
            rentId={urlQuery.rentId}
            onClose={handleReturnPhotoOnClose}
            onFinish={handleReturnPhotoOnFinish}
          />
        </SwipeUpPage>
      )}
    </FullScreenLoading>
  );
};

PureRideDetail.defaultProps = {};

PureRideDetail.propTypes = {};

const RideDetail = () => (
  <RentingProvider>
    <PureRideDetail />
  </RentingProvider>
);

export default React.memo(RideDetail);

import React from 'react';
import qs from 'qs';
import { Route, Redirect } from 'react-router-dom';

const OutLinePrivateRoute = React.memo(({ component: Component, routes, ...rest }) => (
  <Route
    {...rest}
    render={({ location, ...props }) => {
      const { token } = qs.parse(location.search.slice(1));

      return token ? (
        <Component {...props} routes={routes} />
      ) : (
        <Redirect
          to={{
            pathname: '/errors/unauthorized',
            state: { from: location },
          }}
        />
      );
    }}
  />
));

export default OutLinePrivateRoute;

import React from 'react';
import { ErrorNotificationProvider } from '~/context/ErrorNotification';
import { AuthenticationProvider } from '~/context/providers/AuthenticationProvider';
import { UserInfoProvider } from '~/context/providers/UserInfoProvider';
import { StatusProvider } from '~/context/providers/StatusProvider';
import { EditingUserProvider } from '~/context/providers/EditingUserProvider';
import { PageInfoProvider } from '~/context/providers/PageInfoProvider';
import { EmergencyProvider } from '~/context/providers/EmergencyProvider';
import { SnackbarProvider } from '~/context/Snackbars';

const GlobalDataProviders = ({ children }) => (
  <EmergencyProvider>
    <AuthenticationProvider>
      <UserInfoProvider>
        <EditingUserProvider>
          <StatusProvider>
            <PageInfoProvider>
              <SnackbarProvider>
                <ErrorNotificationProvider>{children}</ErrorNotificationProvider>
              </SnackbarProvider>
            </PageInfoProvider>
          </StatusProvider>
        </EditingUserProvider>
      </UserInfoProvider>
    </AuthenticationProvider>
  </EmergencyProvider>
);

export default GlobalDataProviders;

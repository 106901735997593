import ExpiredStorage from 'expired-storage';
import LineAxios from '~/services/LineAxios';

const expiredStorage = new ExpiredStorage();

export const setToken = ({ accessToken, refreshToken }) => {
  const token = { accessToken, refreshToken };
  expiredStorage.setJson('wemo-token', token);
  LineAxios.setInstancesToken(accessToken);
};

export const getToken = () => {
  const token = expiredStorage.getJson('wemo-token') || {};
  return token;
};

export const hasToken = () => {
  const { accessToken, refreshToken } = getToken();
  return accessToken && refreshToken;
};

export const syncLineAxiosToken = _accessToken => {
  const { accessToken } = getToken();
  LineAxios.setInstancesToken(_accessToken || accessToken);
};

export default { setToken, hasToken, getToken, syncLineAxiosToken };

class GetApplePayPrimeError extends Error {
  constructor(message) {
    super(message);
    this.name = 'GetApplePayPrimeError';
    this.type = 'GET_APPLE_PAY_PRIME_ERROR';
  }
}

class CanNotMakePaymentWithActiveCard extends Error {
  constructor(message) {
    super(message);
    this.name = 'CanNotMakePaymentWithActiveCard';
    this.type = 'CAN_NOT_MAKE_PAYMENT_WITH_ACTIVE_CARD';
  }
}

class BrowserNoSupportPaymentRequest extends Error {
  constructor(message) {
    super(message);
    this.name = 'BrowserNoSupportPaymentRequest';
    this.type = 'BROWSER_NO_SUPPORT_PAYMENT_REQUEST';
  }
}

export const getDirectPayPrime = async (cardNumber, expMonth, expYear, ccv) => {
  if (window.TPDirect) {
    return new Promise((resolve, reject) => {
      window.TPDirect.card.createToken(cardNumber, expMonth, expYear, ccv, result => {
        const { status, card, msg } = result;
        if (status === 0) {
          resolve(card.prime);
        } else {
          reject(msg);
        }
      });
    });
  }
};

export const getLinePayPrime = async () => {
  return new Promise((resolve, reject) => {
    window.TPDirect.linePay.getPrime(result => {
      const { status, msg, prime } = result;
      if (status === 0) {
        resolve(prime);
      } else {
        reject(msg);
      }
    });
  });
};

export const getApplePayPrime = async () => {
  return new Promise((resolve, reject) => {
    window.TPDirect.paymentRequestApi.getPrime(result => {
      const { status, msg, prime } = result;
      if (status === 0) {
        resolve(prime);
      } else {
        reject(new GetApplePayPrimeError(msg));
      }
    });
  });
};

export const setupPaymentRequest = async config => {
  return new Promise((resolve, reject) => {
    window.TPDirect.paymentRequestApi.setupPaymentRequest(config, result => {
      if (!result.browserSupportPaymentRequest) {
        reject(new BrowserNoSupportPaymentRequest('browser no support payment request'));
        return;
      }

      // NOTE: apple pay 只會檢查使用者是否有在 apple pay 裡面綁卡片
      if (!result.canMakePaymentWithActiveCard) {
        // 如果是 apple pay，會引導使用者去 apple pay 綁卡片
        reject(new CanNotMakePaymentWithActiveCard('can not make payment with active card'));
        return;
      }

      resolve();
    });
  });
};

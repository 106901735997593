import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import css from '@styled-system/css';
import { useTransition, animated } from 'react-spring';
import { isWithinInterval, getDay, format, parse } from 'date-fns';
import Pin from '~/components/atoms/Pin';
import { ReactComponent as ParkingLot } from 'Images/icons/parking-p-logo.svg';
import { ReactComponent as Money } from 'Images/icons/money.svg';
import Button from '~/components/atoms/Button';
import { getScooterById } from '~/services/api';
import { useDebouncedCallback } from 'use-debounce';
import { rentStatus, parkingLotStatusMap } from '~/configs/';
import useLoading from '~/hooks/useLoading';
import PopupLoading from 'Components/molecules/PopupLoading';
import { scooterButtonText } from 'Components/pages/Main/config';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { useRenting } from '~/context';
import { useErrorNotification } from '~/context/ErrorNotification';
import requestErrors from '~/configs/requestErrors';

const Text = styled.span`
  margin: 0 6px;
`;

const Container = styled.div`
  background: ${themeGet('colors.typeSpaceGrey')};
  ${css({ p: ['10px 10px', '10px 20px'] })};
  height: 100%;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const ListWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScooterItem = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  background: ${themeGet('colors.white')};
  ${themeGet('boxShadows.light')};
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? themeGet('colors.mainBlue') : 'transparent')};
`;

const ScooterPin = styled(Pin)`
  flex: 0 0 auto;
`;

const ScooterInfoPanel = styled.div`
  margin-left: 10px;
  flex-grow: 1;
`;

const ScooterId = styled.p`
  ${themeGet('fonts.body')};
  margin: 0;
  margin-bottom: 4px;
`;

const BatteryInfo = styled.p`
  ${themeGet('fonts.note')};
  color: ${themeGet('colors.mainDark')};
  margin: 0;
  margin-bottom: 4px;
`;

const ParkingGrid = styled.div`
  width: 60px;
  height: 22px;
  background: ${themeGet('colors.typeSpaceGrey')};
  border-radius: 4px;
  flex: 0 0 auto;
  overflow: hidden;
`;

const ParkingGridText = styled.div`
  ${themeGet('fonts.note')};
  display: inline-block;
  width: 38px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  vertical-align: top;
`;

const ParkingIconPanel = styled.div`
  width: 22px;
  height: 22px;
  background: ${themeGet('colors.unavailableGrey')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const ParkingIcon = styled.img.attrs({ as: ParkingLot })``;

const ReserveWrapper = styled(animated.div)`
  flex: 0 0 auto;
  height: 0px;
  opacity: 0;
  background: ${themeGet('colors.white')};
  box-sizing: border-box;
  overflow: hidden;
  ${css({ mx: ['-10px', '-20px'], mb: ['-10px', '-10px'] })};
  border-top: 1px solid ${themeGet('colors.unavailableGrey')};
`;

const ReservePanel = styled.div`
  ${css({ p: ['10px 12px', '10px 30px'] })};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
`;

const BatteryText = styled(Text)`
  &:first-child {
    margin-left: 0;
  }
`;

const Price = styled.div`
  ${themeGet(`fonts.body2`)};
  margin: 8px auto;
  width: 100%;
  text-align: center;
`;

const MoneyIcon = styled.img.attrs({ as: Money })`
  vertical-align: middle;
`;

const PrimaryText = styled(Text)`
  color: ${themeGet('colors.mainBlue')};
`;

const ReserveButton = styled(Button)`
  width: 240px;
`;

const dayTextMap = {
  0: '星期日',
  1: '星期一',
  2: '星期二',
  3: '星期三',
  4: '星期四',
  5: '星期五',
  6: '星期六',
};

const PureScooterList = props => {
  const { scooters } = props;
  const listWrapperEl = useRef();
  const [currentScooterId, setCurrentScooterId] = useState();
  const [pricingPlan, setPricingPlan] = useState();
  const loadingUtil = useLoading();
  const {
    handleReserveScooter,
    scooter,
    setScooter,
    resetScooter,
    setMapParkingLot,
    updateMapParkingLot,
    getNearByParkingLots,
    renting,
  } = useRenting();
  const { registerErrorConfig, launchApiError, launchError } = useErrorNotification();

  const reserveTransitions = useTransition(pricingPlan, null, {
    from: { height: '0px', opacity: 0 },
    enter: { height: '128px', opacity: 1 },
    leave: { height: '0px', opacity: 0 },
  });

  const [handleScooterOnClick] = useDebouncedCallback(
    async scooterId => {
      if (scooterId === scooter.scooterId) {
        resetScooter();
        setPricingPlan();
        setCurrentScooterId();
        return;
      }

      try {
        if (renting.userMode !== rentStatus.reserveParkingLot) return;
        loadingUtil.start();
        setCurrentScooterId(scooterId);
        const scooter = await getScooterById(scooterId);
        let { pricing: { pricingPlans = [] } = {}, parkingLot } = scooter || {};

        if (renting.mapParkingLot.state.key !== parkingLot.state.key) {
          getNearByParkingLots(renting.location);
          updateMapParkingLot();
        }
        if (parkingLot.state.key === parkingLotStatusMap.errorBothDisabled) {
          launchError({ type: 'PARKING_LOT_ERROR' }, 'scooters');
          setMapParkingLot(parkingLot);
        } else if (parkingLot.state.key === parkingLotStatusMap.closed) {
          launchError({ type: 'PARKING_LOT_CLOSED' }, 'scooters');
        } else {
          if (parkingLot.state.key === parkingLotStatusMap.aboutToClose) {
            let dayText = null;

            let specialDay = parkingLot.specialBusinessHours?.find(day =>
              isWithinInterval(new Date(), {
                start: new Date(day.beginAt),
                end: new Date(day.endAt),
              })
            );

            if (specialDay) {
              const dateFormat = 'yyyy-MM-dd HH:mm';
              dayText = `${specialDay.specialHoursName}\n${format(
                new Date(specialDay.beginAt),
                dateFormat
              )}~${format(new Date(specialDay.endAt), dateFormat)}`;
            }
            if (!specialDay) {
              const timeParseFormat = 'HH:mmxx';
              const timeFormat = 'HH:mm';
              const now = new Date();
              const dayOfWeek = getDay(now);
              const today = parkingLot.regularBusinessHours[dayOfWeek % 7];
              dayText = `${dayTextMap[dayOfWeek % 7]} ${today.beginAt &&
                format(parse(today.beginAt, timeParseFormat, now), timeFormat)}~${today.endAt &&
                format(parse(today.endAt, timeParseFormat, now), timeFormat)}`;
            }
            launchError({ type: 'PARKING_LOT_ABOUT_CLOSED', data: dayText }, 'scooters');
          }

          setPricingPlan(pricingPlans.find(pricingPlan => pricingPlan.isDefault));
          setScooter({ scooterId: scooter.id, ...scooter });
        }
        loadingUtil.end();
      } catch (error) {
        loadingUtil.end('fail');
        launchApiError(error, 'scooters');
        console.log(error);
      }
    },
    300,
    { leading: true, trailing: false }
  );

  const [handleReserveOnClick] = useDebouncedCallback(
    async () => {
      if (renting.userMode !== rentStatus.reserveParkingLot) return;
      /**
       * NOTE: 預約時傳預設資費與方案 => 影響 UserImfoV3 appReturnInfo.pricing 裡面的 isDefault 是否為 true
       */
      await handleReserveScooter();
    },
    300,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    const targetEl = listWrapperEl.current;
    disablePageScroll(targetEl);
    return () => {
      enablePageScroll(targetEl);
    };
  }, []);

  useMemo(() => {
    registerErrorConfig('scooters', {
      PARKING_LOT_CLOSED: {
        popup: {
          title: '休息中',
          confirmButtonText: '確認',
          notes: `休息中無法租車`,
        },
        notifier: 'popup',
      },
      PARKING_LOT_ABOUT_CLOSED: ({ data }) => ({
        popup: {
          title: '抓緊時間',
          confirmButtonText: '確認',
          notes: `停車場即將關閉\n${data}`,
        },
        notifier: 'popup',
      }),
      PARKING_LOT_ERROR: {
        popup: {
          title: '停車場異常',
          confirmButtonText: '確認',
          type: 'warning',
          notes: `系統維護中無法租車`,
        },
        notifier: 'popup',
      },
      [requestErrors.SCOOTER_NOT_FOUND]: {
        snackbar: {
          message: '車輛發生異常，請預約其他車輛',
        },
        notifier: 'snackbar',
      },
      [requestErrors.SCOOTER_NOT_IN_ZONE]: {
        popup: {
          title: '請確認您的車輛停放位置',
          confirmButtonText: '確認',
          type: 'warning',
          notes: `您目前在營運範圍外\n無法還車。`,
        },
        notifier: 'popup',
      },
    });
  }, []);

  return (
    <Container>
      <ListWrapper ref={listWrapperEl}>
        {scooters.map(scooter => (
          <ScooterItem
            key={scooter.id}
            isSelected={currentScooterId === scooter.id}
            onClick={() => handleScooterOnClick(scooter.id)}
          >
            <ScooterPin batPercent={scooter.batPercent} />
            <ScooterInfoPanel>
              <ScooterId>{scooter.id}</ScooterId>
              <BatteryInfo>
                <BatteryText>{scooter.batPercent}% </BatteryText> |{' '}
                <BatteryText>{scooter.availableDistance}</BatteryText>km
              </BatteryInfo>
            </ScooterInfoPanel>
            <ParkingGrid>
              <ParkingIconPanel>
                <ParkingIcon />
              </ParkingIconPanel>
              <ParkingGridText>{scooter.parkingSpaceId || '--'}</ParkingGridText>
            </ParkingGrid>
          </ScooterItem>
        ))}
      </ListWrapper>
      {reserveTransitions.map(
        ({ item, key, props }) =>
          item && (
            <ReserveWrapper key={key} style={props}>
              <ReservePanel>
                <Price>
                  {pricingPlan && (
                    <React.Fragment>
                      <MoneyIcon />
                      <Text>
                        前 {pricingPlan.freeMinutes} 分鐘
                        <PrimaryText>{pricingPlan.startPrice} 元</PrimaryText>，之後
                        <PrimaryText>{pricingPlan.pricePerMinute} 元 / 分</PrimaryText>
                      </Text>
                    </React.Fragment>
                  )}
                </Price>
                <ReserveButton
                  shape="capsule"
                  boxShadow="light"
                  type="float"
                  color="primary"
                  onClick={handleReserveOnClick}
                >
                  {scooterButtonText[renting.userMode]}
                </ReserveButton>
              </ReservePanel>
            </ReserveWrapper>
          )
      )}
      <PopupLoading {...loadingUtil.state} />
    </Container>
  );
};

PureScooterList.defaultProps = {
  scooters: [],
};

PureScooterList.propTypes = {
  scooters: PropTypes.array,
};

export default React.memo(PureScooterList);

import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { isNationalIdentificationNumberValid } from 'taiwan-id-validator2';
import BasicContainer from 'Components/layouts/BasicContainer';
import Steps from 'Components/atoms/Steps';
import { Step } from 'Components/atoms/Steps';
import SignUpNote from 'Components/pages/SignUpNote';
import SignUpPhoto from 'Components/pages/SignUpPhoto';
import SignUpUserInfo from 'Components/pages/SignUpUserInfo';
import SignUpInvitation from 'Components/pages/SignUpInvitation';
import { useAuthentication, useEditingUser } from '~/context/';
import { checkLicence } from '~/services/api';
import { useErrorNotification } from '~/context/ErrorNotification';
import ExpiredStorage from 'expired-storage';
import PopupLoading from '~/components/molecules/PopupLoading';
import useLoading from '~/hooks/useLoading';
import {
  userStatus,
  userStatusMap,
  paymentKeys,
  wemoPhone,
  registerPhotosKeySequence,
} from '~/configs';
import requestErrors from '~/configs/requestErrors';
import { logScreenViewEvent, logSignUpEvent } from '~/utils/firebaseService';

const StorageExpiredTime = 60 * 60;
const expiredStorage = new ExpiredStorage();

const DefaultStep = 0;

const getStatusId = status => {
  const [id] = Object.entries(userStatusMap).find(([_, value]) => value === status) || [];
  return id;
};

const StepsStyled = styled(Steps)`
  height: 100%;
`;

const Link = styled.a`
  color: ${props => props.theme.colors.mainBlue};
`;

const popupConfig = {
  REGISTER_ERROR: {
    popup: {
      title: '似乎發生錯誤',
      confirmButtonText: '確認',
      notes: '非常抱歉！\n註冊時出現錯誤，請稍後重試',
    },
    notifier: 'popup',
  },
  [requestErrors.USER_INVALID_INVITATION_CODE]: {
    popup: {
      title: '邀請碼錯誤',
      confirmButtonText: '確認',
      notes: '查無此邀請碼，請重新輸入',
    },
    notifier: 'popup',
  },
  [requestErrors.USER_CREATE_INVITATION_CODE_FAILURE]: {
    popup: {
      title: '邀請碼錯誤',
      confirmButtonText: '確認',
      notes: 'Oops！您輸入的邀請碼有問題，請重新輸入或取消輸入',
    },
    notifier: 'popup',
  },
  [requestErrors.USER_UPDATE_INVITATION_CODE_FAILURE]: {
    popup: {
      title: '邀請碼錯誤',
      confirmButtonText: '確認',
      notes: 'Oops！您輸入的邀請碼有問題，請重新輸入或取消輸入',
    },
    notifier: 'popup',
  },
  PHOTO_ERROR: {
    popup: {
      title: '照片不齊全',
      confirmButtonText: '確認',
      notes: '註冊照片不齊全\n請檢查一下再回來',
    },
    notifier: 'popup',
  },
  PHOTO_ERROR: {
    popup: {
      title: '照片不齊全',
      confirmButtonText: '確認',
      notes: '註冊照片不齊全\n請檢查一下再回來',
    },
    notifier: 'popup',
  },
  USER_INVALID_IDCARDID: {
    popup: {
      title: '無法申請註冊',
      confirmButtonText: '確認',
      notes: '無效的身分證字號，請重新輸入',
    },
    notifier: 'popup',
  },
  NO_LICENCE: {
    popup: {
      title: '無法申請註冊',
      confirmButtonText: '確認',
      notes: '您不具有合法機車或汽車駕照，故無法申請註冊',
    },
    notifier: 'popup',
  },
  HAS_FINE: {
    popup: {
      title: '無法申請註冊',
      confirmButtonText: '確認',
      notes: '您有交通違規罰緩尚未繳納，故無法申請註冊',
    },
    notifier: 'popup',
  },
};

const getStatusWithConditions = ({ isBarcodeEqualsToFormIdcard, licenceStatus }) => {
  if (isBarcodeEqualsToFormIdcard === false) return userStatus.reject;
  if (licenceStatus?.hasResponse && !licenceStatus?.hasLicenceIssue) return userStatus.unconfirmed;
  return userStatus.pending;
};

const SignUp = () => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(DefaultStep);
  const {
    editingUser,
    setEditingUser,
    setEditingUserUserInvitation,
    setEditingUserUserInfo,
    setScannedIdCard,
    resetEditingUser,
  } = useEditingUser();
  const { registerErrorConfig, launchError, launchApiError } = useErrorNotification();
  const loadingUtil = useLoading();
  const { registerAndBindLineId, loginExistedUser, logOut } = useAuthentication();
  const lineEmail = useRef(window.liff.getDecodedIDToken()?.email);
  const nextStep = useCallback(() => {
    setCurrentStep(prev => prev + 1);
  }, []);

  const prevStep = useCallback(() => {
    setCurrentStep(prev => prev - 1);
  }, []);

  useEffect(() => {
    logScreenViewEvent({ name: `sign-up-info-${currentStep}` });
  }, [currentStep]);

  const oldUserLogin = useCallback(
    async data => {
      try {
        loadingUtil.start();
        const isLogin = await loginExistedUser({
          firebaseToken: data.firebaseToken,
          idcard: data.idcard,
        });

        if (isLogin) history.replace('/');
        else {
          throw Error();
        }
        loadingUtil.end();
      } catch (error) {
        launchError({ type: 'VERIFY_ERROR' }, 'signup');
        loadingUtil.end('fail');
        history.replace('/');
        console.log(error);
      }
    },
    [history, launchError, loadingUtil, loginExistedUser]
  );

  useEffect(() => {
    handlePhoneData();
  }, []);

  const checkLicenceStatusFromIdcard = async idcard => {
    let status = {
      hasLicenceIssue: false,
      hasResponse: false,
    };

    try {
      const licenceResult = await checkLicence(idcard);
      status.hasResponse = true;
      if (!licenceResult.hasLicence) {
        launchError({ type: 'NO_LICENCE' }, 'signup');
        status.hasLicenceIssue = true;
      }
      if (!licenceResult.noFine) {
        launchError({ type: 'HAS_FINE' }, 'signup');
        status.hasLicenceIssue = true;
      }
    } catch (error) {
      status.hasResponse = false;
    }

    return status;
  };

  const handleRegister = async userInfoResult => {
    const photos = (expiredStorage.getJson('registerPhotoNames') || []).filter(
      value => value !== null
    );
    const scannedIdCard = editingUser.scannedIdCard || expiredStorage.getItem('registerIdCard');
    userInfoResult = { ...editingUser.userInfo, ...userInfoResult };

    try {
      loadingUtil.start();

      if (photos.length !== registerPhotosKeySequence.length) {
        launchError({ type: 'PHOTO_ERROR' }, 'signup');
        setCurrentStep(1);
      } else {
        const isBarcodeEqualsToFormIdcard =
          scannedIdCard && userInfoResult.idcard === scannedIdCard;
        let licenceStatus;
        if (
          scannedIdCard &&
          isBarcodeEqualsToFormIdcard &&
          isNationalIdentificationNumberValid(userInfoResult.idcard)
        ) {
          licenceStatus = await checkLicenceStatusFromIdcard(userInfoResult.idcard);
          if (licenceStatus.hasResponse && licenceStatus.hasLicenceIssue) return;
        }

        const status = getStatusWithConditions({
          scannedIdCard,
          isBarcodeEqualsToFormIdcard,
          licenceStatus,
        });
        const statusId = getStatusId(status);
        const isLogin = await registerAndBindLineId({
          gender: userInfoResult.gender,
          birth: userInfoResult.birth,
          address: userInfoResult.address,
          photos,
          phone: editingUser.phoneObj.completePhone,
          first_name: userInfoResult.firstName,
          last_name: userInfoResult.lastName,
          idcard_id: userInfoResult.idcard,
          email: lineEmail.current || userInfoResult.email,
          contact_email: userInfoResult.email,
          idToken: editingUser.phoneObj.firebaseToken,
          paymentType: paymentKeys.linePay,
          invitationCode: userInfoResult.invitationCode,
          statusId,
        });

        if (isLogin) {
          logSignUpEvent();
          resetEditingUser();
          expiredStorage.removeItem('registerPhotoNames');
          expiredStorage.removeItem('registerIdCard');
          if (status === userStatus.reject) {
            await logOut();
          }
          history.replace('/welcome', { userStatus: status });
        } else {
          launchError({ type: 'REGISTER_ERROR' }, 'signup');
        }
      }

      loadingUtil.end();
    } catch (error) {
      loadingUtil.end('fail');
      launchApiError(error, 'signup', {
        firebaseToken: editingUser.phoneObj.firebaseToken,
        idcard: userInfoResult.idcard,
      });
    }
  };

  /* 執行檢查: 
    電話
    1. 從手機驗證過來 -> 會有電話
    2. 拍照跳窗回來 -> 沒有電話，看有沒有存在 storage 裡
    3. 重新整理 -> 沒有電話，沒有 storage （註冊完會刪除 storage）
  */
  const handlePhoneData = () => {
    const urlQuery = qs.parse(window.location.search.slice(1));

    if (!editingUser.phoneObj.isSet) {
      const editingUserStorage = expiredStorage.getJson('editingUser');
      if (editingUserStorage?.phoneObj?.isSet) {
        setEditingUser(editingUserStorage);
        setCurrentStep(parseInt(urlQuery.step) || DefaultStep);
      } else {
        history.replace('/sign-up-phone');
      }
    } else {
      expiredStorage.setJson('editingUser', editingUser, StorageExpiredTime);
      setCurrentStep(parseInt(urlQuery.step) || DefaultStep);
    }
  };
  const handleSetEditingUserUserInfo = useCallback(
    payload => {
      setEditingUserUserInfo({ ...payload, invitationCode: editingUser.userInfo.invitationCode });
    },
    [editingUser.userInfo.invitationCode, setEditingUserUserInfo]
  );

  useMemo(
    () =>
      registerErrorConfig('signup', {
        ...popupConfig,
        [requestErrors.USER_REGISTER_IDCARD_DUPLICATE]: error => {
          return {
            popup: {
              title: '帳號已註冊',
              notes: (
                <span>
                  系統偵測到你曾經註冊過 WeMo Scooter，如果沒有請聯絡客服
                  <Link href={`tel:${wemoPhone}`}>{wemoPhone} </Link>
                </span>
              ),
              confirmButtonText: '使用舊用戶登入',
              cancelButtonText: '取消',
              onConfirmClick: () => oldUserLogin(error.data),
              hasCancelButton: true,
            },
            notifier: 'popup',
          };
        },
        [requestErrors.USER_REGISTER_EMAIL_DUPLICATE]: error => {
          return {
            popup: {
              title: '帳號已註冊',
              notes: (
                <span>
                  系統偵測到你曾經註冊過 WeMo Scooter，如果沒有請聯絡客服
                  <Link href={`tel:${wemoPhone}`}>{wemoPhone} </Link>
                </span>
              ),
              confirmButtonText: '使用舊用戶登入',
              cancelButtonText: '取消',
              onConfirmClick: () => oldUserLogin(error.data),
              hasCancelButton: true,
            },
            notifier: 'popup',
          };
        },
        [requestErrors.USER_AUTH_ALREADY_EXIST]: {
          popup: {
            title: '註冊流程發生異常',
            notes: (
              <span>
                很抱歉！在註冊時發生異常，請聯絡客服以便繼續註冊
                <Link href={`tel:${wemoPhone}`}>{wemoPhone} </Link>
              </span>
            ),
            confirmButtonText: '確認',
          },
          notifier: 'popup',
        },
        VERIFY_ERROR: {
          popup: {
            type: 'warning',
            title: '認證失敗',
            confirmButtonText: '確認',
            notes: (
              <span>
                身份認證失敗，如有更多問題請撥打客服專線
                <Link href={`tel:${wemoPhone}`}>{wemoPhone} </Link>
              </span>
            ),
          },
          notifier: 'popup',
        },
      }),
    []
  );

  return (
    <BasicContainer navigationBar={false} autoHeight={false}>
      <StepsStyled current={currentStep} style={{ height: '100%' }}>
        <Step>
          <SignUpNote onConfirm={nextStep} />
        </Step>
        <Step>
          <SignUpPhoto onPreviousClick={prevStep} onNextClick={nextStep} />
        </Step>
        <Step>
          <SignUpUserInfo
            setEditingUserUserInfo={handleSetEditingUserUserInfo}
            defaultValue={{
              ...editingUser.userInfo,
              email: editingUser.userInfo?.email || lineEmail.current,
            }}
            onPreviousClick={prevStep}
            onNextClick={nextStep}
            setScannedIdCard={setScannedIdCard}
          />
        </Step>
        <Step>
          <SignUpInvitation
            preStoredInvitationCode={editingUser.userInfo.invitationCode}
            setEditingUserUserInvitation={setEditingUserUserInvitation}
            onPreviousClick={prevStep}
            onNextClick={handleRegister}
          />
        </Step>
      </StepsStyled>
      <PopupLoading {...loadingUtil.state} />
    </BasicContainer>
  );
};

SignUp.defaultProps = {};

SignUp.propTypes = {};

export default SignUp;

import React, { useRef, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Button from 'Components/atoms/Button';
import ButtonLongPress from 'Components/atoms/ButtonLongPress';
import Timer from 'Components/atoms/Timer';
import { ReactComponent as Battery } from 'Images/icons/battery.svg';
import { ReactComponent as TurnOn } from 'Images/icons/turnOn.svg';
import { ReactComponent as TurnOff } from 'Images/icons/turnOff.svg';
import { ReactComponent as OpenTrunkWhite } from 'Images/icons/openTrunkWhite.svg';

const ButtonStyled = styled(Button)`
  width: 120px;
`;

const ButtonLongPressHalf = styled(ButtonLongPress)`
  width: 120px;
`;

const RideInfo = styled.span`
  ${props => themeGet(`fonts.body`)};
  text-align: center;
  display: block;
  margin: 2px 0 14px;
`;

const Id = styled.span`
  color: ${props => themeGet(`colors.mainDark`)};
  font-weight: 500;
  display: inline-block;
  margin-right: 21px;
`;

const Icon = styled.img`
  vertical-align: -9px;
`;

const TimerStyled = styled(Timer)`
  width: 100%;
  text-align: center;
`;

const Text = styled.span`
  margin: 0 6px;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TabScooter = ({
  rentTime,
  scooterId,
  batPercent,
  availableDistance,
  isScooterStart,
  timerConfig,
  onStartClick,
  onReturnClick,
  onOpenTrunkClick,
  onStopClick,
  onStopCancel,
}) => {
  const timerRef = useRef();

  useEffect(() => {
    const timer = timerRef.current;
    if (timerConfig) {
      timer.stop();
      timer.start(timerConfig);
    }

    return () => {
      timer.stop();
    };
  }, [timerConfig]);

  return (
    <Fragment>
      <TimerStyled ref={timerRef}></TimerStyled>
      <RideInfo>
        <Id>{scooterId}</Id>
        <Icon as={Battery} />
        <Text>{batPercent}% </Text>|<Text>{availableDistance}km</Text>
      </RideInfo>
      <ButtonGroup>
        {isScooterStart ? (
          <Fragment>
            <ButtonStyled
              shape="capsule"
              boxShadow="light"
              type="float"
              color="primary"
              IconComponent={OpenTrunkWhite}
              onClick={onOpenTrunkClick}
            ></ButtonStyled>
            <ButtonLongPressHalf
              shape="capsule"
              type="float"
              boxShadow="light"
              color="plan"
              IconComponent={TurnOff}
              time={1000}
              onCancel={onStopCancel}
              onClick={onStopClick}
            ></ButtonLongPressHalf>
          </Fragment>
        ) : (
          <Fragment>
            <ButtonStyled
              shape="capsule"
              type="float"
              boxShadow="light"
              color="primary"
              IconComponent={TurnOn}
              onClick={onStartClick}
            ></ButtonStyled>
            <ButtonStyled
              boxShadow="light"
              shape="capsule"
              type="float"
              color="plan"
              onClick={onReturnClick}
            >
              還車
            </ButtonStyled>
          </Fragment>
        )}
      </ButtonGroup>
    </Fragment>
  );
};

export default React.memo(TabScooter);

import React, { createContext, useReducer } from 'react';
import authenticationReducer from '../reducers/authenticationReducer';

const AuthenticationContext = createContext();

const AuthenticationProvider = ({ children }) => {
  // console.log('AuthticationProvider');
  const reducers = useReducer(authenticationReducer, authenticationReducer());

  return <AuthenticationContext.Provider value={reducers}>{children}</AuthenticationContext.Provider>;
};

export { AuthenticationProvider, AuthenticationContext };

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { labelShrink, labelFocus, labelSize } from './style';

const inputPaddingTop = {
  medium: 14,
  large: 20,
};

const InputPanelStyled = styled.div`
  position: relative;
  box-sizing: border-box;
  cursor: text;
`;

const LabelStyled = styled.label`
  user-select: none;
  color: ${themeGet('colors.secondaryGrey')};
  line-height: 1;
  padding: 0;
  position: absolute;
  top: ${({ size }) => {
    if (size === 'large') return '4px';
    else return '0px';
  }};
  left: 10px;
  transform-origin: top left;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  ${labelSize}
  ${labelShrink}
  ${labelFocus}
  z-index: 1;
`;

export const PureInlineFromItem = props => {
  const { children, label, focusColor, isShrink, size } = props;

  return (
    <InputPanelStyled>
      <LabelStyled focusColor={focusColor} shrink={isShrink} size={size}>
        {label}
      </LabelStyled>
      {React.cloneElement(children, {
        width: '100%',
        inputStyle: {
          paddingTop: inputPaddingTop[size],
        },
      })}
    </InputPanelStyled>
  );
};

PureInlineFromItem.defaultProps = {
  size: 'medium',
};

PureInlineFromItem.propTypes = {
  label: PropTypes.string,
  isShrink: PropTypes.bool.isRequired,
  focusColor: PropTypes.oneOf(['blue', 'red', 'grey']).isRequired,
  size: PropTypes.oneOf(['medium', 'large']),
};

export default React.memo(PureInlineFromItem);

import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import blackSpinner from '~/assets/black-spinner.json';
import whiteSpinner from '~/assets/white-spinner.json';

const lottieOptions = {
  loop: true,
  autoplay: true,
};

export const PureLoading = props => {
  const { mode, width, height } = props;
  return (
    <Lottie
      options={{
        ...lottieOptions,
        animationData: mode === 'dark' ? blackSpinner : whiteSpinner,
      }}
      width={width}
      height={height}
    />
  );
};

PureLoading.defaultProps = {
  mode: 'dark',
  width: 30,
  height: 30,
};

PureLoading.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']),
  width: PropTypes.number,
  height: PropTypes.number,
};

export default React.memo(PureLoading);

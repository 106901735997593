import { useState, useCallback, useEffect, useRef } from 'react';

const isLoading = items => {
  const status = Object.values(items);
  // 找看看有沒有 status 是 false
  return status.some(value => value);
};

export default (items = {}, status = 'success') => {
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(isLoading(items));
  const [loadingItems, setLoadingItems] = useState(items);
  const [loadingStatus, setLoadingStatus] = useState(status);

  const setItemsStatus = useCallback((key, status) => {
    if (Array.isArray(key)) {
      const keys = key;
      const updateItems = keys.reduce((acc, key) => {
        acc[key] = status;
        return acc;
      }, {});
      setLoadingItems(prev => ({
        ...prev,
        ...updateItems,
      }));
    } else if (typeof key === 'string') {
      setLoadingItems(prev => ({
        ...prev,
        [key]: status,
      }));
    }
  }, []);

  const start = useCallback(
    (key, status = 'success') => {
      if (!isMounted.current) return;
      setLoadingStatus(status);
      setItemsStatus(key, true);
    },
    [setItemsStatus]
  );

  const end = useCallback(
    (key, status) => {
      if (!isMounted.current) return;
      if (status) {
        setLoadingStatus(status);
        // 如果 fail 全部清空
        if (status === 'fail') {
          setItemsStatus(Object.keys(loadingItems), false);
        }
      } else {
        setItemsStatus(key, false);
      }
    },
    [setItemsStatus, loadingItems]
  );

  useEffect(() => {
    setLoading(isLoading(loadingItems));
  }, [loadingItems]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    state: { spinning: loading, status: loadingStatus },
    start,
    end,
  };
};

import React, { useState, useMemo, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import PhoneNumber from 'awesome-phonenumber';
import { useEditingUser, useUserInfo } from '~/context/';
import Input from 'Components/atoms/Input';
import { color } from 'styled-system';
import Button from 'Components/atoms/Button';
import Select from 'Components/atoms/Select';
import Form from 'Components/atoms/Form';
import FormItem from 'Components/atoms/Form/FormItem';
import FullScreenLoading from 'Components/molecules/FullScreenLoading';
import PageHeader from 'Components/molecules/PageHeader';
import BasicContainer from 'Components/layouts/BasicContainer'; // TODO: 統整用到頁面後看有能不能加到樹根ㄑ
import { useHistory } from 'react-router-dom';
import countryCode from '~/configs/countryCode.json';
const { Option } = Select;

const RowStyled = styled(Row)`
  margin: 40px;
`;

const ButtonStyled = styled(Button)`
  margin: 0 auto 40px;
  display: block;
`;

const Status = styled.p`
  text-align: right;
  margin: 0;
  ${props => props.theme.fonts.note}
  ${color}
`;

const EditPhone = Form.create()(({ form }) => {
  const history = useHistory();
  const [oldPhoneData, setOldPhoneData] = useState({ countryCode: '+886', phone: '' });
  const { user } = useUserInfo();
  const { getFieldDecorator, isFieldsTouched, getFieldValue } = form;
  const { editingUser, setEditingUser } = useEditingUser();
  const [toNextPage, setToNextPage] = useState(false);

  const isValid = useMemo(() => {
    if (isFieldsTouched(['countryCode', 'phone'])) {
      return PhoneNumber(getFieldValue('countryCode') + getFieldValue('phone')).isValid();
    }
    if (user.phone) {
      return PhoneNumber(user.phone).isValid();
    }
    return false;
  }, [getFieldValue, isFieldsTouched, user.phone]);

  const isNewPhone = isFieldsTouched(['countryCode', 'phone'])
    ? user.phone !== getFieldValue('countryCode') + getFieldValue('phone')
    : false;

  const helpMessage = useMemo(() => {
    if (isFieldsTouched(['countryCode', 'phone'])) {
      if (isNewPhone && !isValid) {
        return <Status color="mainBlue">請輸入長度符合的手機號碼</Status>;
      }
    }
    return !isNewPhone && user.isPhoneVerified ? <Status color="mainGreen">已驗證</Status> : null;
  }, [isFieldsTouched, isNewPhone, isValid, user.isPhoneVerified]);

  useEffect(() => {
    if (user.phone) {
      const countryCode = '+' + PhoneNumber(`${user.phone}`).getCountryCode();
      const phone = PhoneNumber(`${user.phone}`).getNumber('significant');
      setOldPhoneData({ countryCode, phone });
    }
  }, [user]);

  const sendSmsRequest = () => {
    setEditingUser({
      phoneObj: {
        isSet: true,
        countryCode: getFieldValue('countryCode'),
        phone: getFieldValue('phone'),
        completePhone: getFieldValue('countryCode') + getFieldValue('phone'),
      },
    });
    setToNextPage(true);
  };

  useEffect(() => {
    if (editingUser.phoneObj?.isSet && toNextPage) {
      history.replace('/edit-phone-verify', {
        onValidRedirectTo: '/account',
        prevPage: '/edit-phone',
      });
    }
  }, [editingUser.phoneObj, history, toNextPage]);

  return user.isLoading || !oldPhoneData.phone ? (
    <FullScreenLoading spinning={true} />
  ) : (
    <BasicContainer>
      <Fragment>
        <PageHeader title="編輯手機號碼"></PageHeader>
        <Form>
          <RowStyled gutterWidth={12}>
            <Col xs={5} sm={4}>
              <FormItem labelPosition="top">
                {getFieldDecorator('countryCode', {
                  rules: [{ required: true }],
                  initialValue: oldPhoneData.countryCode || '+886',
                })(
                  <Select>
                    {countryCode.map(code => (
                      <Option key={code} value={code}>
                        {code}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col xs={7} sm={8}>
              <FormItem labelPosition="top" help={helpMessage}>
                {getFieldDecorator('phone', {
                  initialValue: oldPhoneData.phone,
                })(<Input type="tel" />)}
              </FormItem>
            </Col>
          </RowStyled>
          <ButtonStyled
            disabled={!isValid || (!isNewPhone && user.isPhoneVerified)}
            boxShadow="light"
            type="float"
            shape="capsule"
            width={64 / 100}
            onClick={sendSmsRequest}
          >
            傳送驗證碼
          </ButtonStyled>
        </Form>
      </Fragment>
    </BasicContainer>
  );
});

EditPhone.defaultProps = {};

EditPhone.propTypes = {};

export default EditPhone;

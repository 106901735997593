import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Card from 'Components/atoms/Card';
import { layout } from 'styled-system';

const CardStyled = styled(Card)`
  width: 100%;
  margin: auto;
  ${layout}
`;

const AspectRatio = styled.div`
  position: relative;
  box-sizing: border-box;

  &::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: ${props =>
      Array.isArray(props.ratio) ? props.ratio?.[0] * 100 : props.ratio * 100}%;
    @media (min-width: ${props => props.theme.breakpoints[0]}) {
      padding-top: ${props =>
        Array.isArray(props.ratio) ? props.ratio?.[1] * 100 : props.ratio * 100}%;
    }
    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      padding-top: ${props =>
        Array.isArray(props.ratio) ? props.ratio?.[2] * 100 : props.ratio * 100}%;
    }
  }
  &::after {
    /* to clear float */
    content: '';
    display: table;
    clear: both;
  }
`;

export const PureCardMap = ({ children, ratio, bind, ...cardProps }) => {
  return (
    <CardStyled boxShadow="dark" {...cardProps}>
      {ratio !== 'none' ? (
        <AspectRatio ref={bind} ratio={ratio}>
          {children}
        </AspectRatio>
      ) : (
        children
      )}
    </CardStyled>
  );
};

PureCardMap.defaultProps = {
  ratio: 0.53,
};

PureCardMap.propTypes = {
  ratio: PropTypes.oneOfType([PropTypes.oneOf(['none']), PropTypes.number, PropTypes.array]),
};

const ForwardRefCardMap = (props, ref) => {
  const bind = useCallback(
    node => {
      if (!ref) return;
      if ('current' in ref) {
        ref.current = node;
      }
      if (typeof ref === 'function') {
        ref(node);
      }
    },
    [ref]
  );
  return <PureCardMap {...props} bind={bind} />;
};

export default React.memo(React.forwardRef(ForwardRefCardMap));

import React from 'react';
import ErrorCase from '~/components/organisms/ErrorCase';

const Ban = () => {
  return <ErrorCase title="無法登入" description="帳戶已終止使用" />;
};

Ban.defaultProps = {};

Ban.propTypes = {};

export default Ban;

// steal from IOS
export default {
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',

  // common
  FORBIDDEN: 'FORBIDDEN',
  NOT_FOUND: 'NOT_FOUND',
  TIMEOUT: 'TIMEOUT',
  CONFLICT: 'CONFLICT',
  UNFITTABLE_PARAMS: 'UNFITTABLE_PARAMS',
  FIELD_REQUIRED: 'FIELD_REQUIRED',
  NO_RECORDS_INSERTED: 'NO_RECORDS_INSERTED',
  NO_RECORDS_UPDATED: 'NO_RECORDS_UPDATED',
  NO_RECORDS_DELETED: 'NO_RECORDS_DELETED',
  PARAMS_INVALID: 'PARAMS_INVALID',
  NOT_ALLOW_DELETION: 'NOT_ALLOW_DELETION',
  API_UPGRADE: 'API_UPGRADE',

  // redis TODO: DELETE THESE - 這應該4沒有?
  // REDIS_GET_DATA_FAILURE: 'REDIS_GET_DATA_FAILURE',
  // REDIS_SET_DATA_FAILURE: 'REDIS_SET_DATA_FAILURE',
  // REDIS_DEL_DATA_FAILURE: 'REDIS_DEL_DATA_FAILURE',
  // SET_TOKEN_FAILED: 'SET_TOKEN_FAILED',
  // GET_TOKEN_FAILED: 'GET_TOKEN_FAILED',
  // DEL_TOKEN_FAILED: 'DEL_TOKEN_FAILED',
  // SET_SCOOTER_SITUATION_FAILED: 'SET_SCOOTER_SITUATION_FAILED',
  // GET_SCOOTER_SITUATION_FAILED: 'GET_SCOOTER_SITUATION_FAILED',
  // DEL_SCOOTER_SITUATION_FAILED: 'DEL_SCOOTER_SITUATION_FAILED',
  // SET_NOTIFICATION_FAILED: 'SET_NOTIFICATION_FAILED',
  // GET_NOTIFICATION_FAILED: 'GET_NOTIFICATION_FAILED',
  // DEL_NOTIFICATION_FAILED: 'DEL_NOTIFICATION_FAILED',

  // gcp TODO: DELETE THESE - 這應該4沒有?
  // GCP_COMMAND_RESPONSE_EVENT_EMITTER_STILL_INITIATING:
  //   'GCP_COMMAND_RESPONSE_EVENT_EMITTER_STILL_INITIATING',

  // params TODO: DELETE THESE - 這應該4沒有? 又這究竟是什麼呢
  // PARAM_NOT_POSITIVE: 'PARAM_NOT_POSITIVE',

  // auth
  AUTH_NO_TOKEN: 'AUTH_NO_TOKEN',
  AUTH_NO_IDENTITY: 'AUTH_NO_IDENTITY',
  AUTH_NO_VALID_IDENTITY: 'AUTH_NO_VALID_IDENTITY',
  AUTH_EXPIRED_TOKEN: 'AUTH_EXPIRED_TOKEN',

  // Firebase (401被認定是 WeMo Unauthorized, 故這邊用 400) TODO: DELETE THESE - 這應該4沒有?
  // AUTH_FIREBASE_EXPIRED_TOKEN: 'AUTH_FIREBASE_EXPIRED_TOKEN',
  // AUTH_FIREBASE_ERROR: 'AUTH_FIREBASE_ERROR',
  // AUTH_FIREBASE_WRONG_ACCOUNT: 'AUTH_FIREBASE_WRONG_ACCOUNT',

  // AUTH_LINE_EXPIRED_ACCESS_TOKEN: 'AUTH_LINE_EXPIRED_ACCESS_TOKEN',
  // AUTH_FIREBASE_RECAPTCHA_TOKEN_ERROR: 'AUTH_FIREBASE_RECAPTCHA_TOKEN_ERROR',

  // LINE Api Call
  LINE_TOKEN_EXPIRED: 'LINE_TOKEN_EXPIRED',
  LIFF_ACCESS_TOKEN_EXPIRED: 'LIFF_ACCESS_TOKEN_EXPIRED',
  LIFF_ACCESS_TOKEN_INVALID: 'LIFF_ACCESS_TOKEN_INVALID',
  LIFF_CHANNEL_ACCESS_TOKEN_ERROR: 'LIFF_CHANNEL_ACCESS_TOKEN_ERROR',
  LINE_API_CALL_400: 'LINE_API_CALL_400',

  //LINE AOA Notify
  LINE_NOTIFY_USER_UNMATCH: 'LINE_NOTIFY_USER_UNMATCH',
  LINE_NOTIFY_RENT_INCOMPLETE: 'LINE_NOTIFY_RENT_INCOMPLETE',

  // user
  USER_EMAIL_DUPLICATE: 'USER_EMAIL_DUPLICATE',
  USER_IDCARD_DUPLICATE: 'USER_IDCARD_DUPLICATE',
  USER_REGISTER_EMAIL_DUPLICATE: 'USER_REGISTER_EMAIL_DUPLICATE',
  USER_NO_TOKEN: 'USER_NO_TOKEN',
  USER_INVALID_TOKEN: 'USER_INVALID_TOKEN',
  USER_NO_USERID: 'USER_NO_USERID',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_INVALID_IDCARDID: 'USER_INVALID_IDCARDID',
  USER_INVALID_INVITATION_CODE: 'USER_INVALID_INVITATION_CODE',
  USER_CREATE_INVITATION_CODE_FAILURE: 'USER_CREATE_INVITATION_CODE_FAILURE',
  USER_UPDATE_INVITATION_CODE_FAILURE: 'USER_UPDATE_INVITATION_CODE_FAILURE',
  USER_INVITATION_CODE_EXISTS: 'USER_INVITATION_CODE_EXISTS',
  USER_IMAGE_FIELD_NAME_INVALID: 'USER_IMAGE_FIELD_NAME_INVALID',
  USER_PHONE_NOT_VERIFIED: 'USER_PHONE_NOT_VERIFIED',
  USER_LOGIN_FORBIDDEN: 'USER_LOGIN_FORBIDDEN',
  USER_EMAIL_NOT_FOUND: 'USER_EMAIL_NOT_FOUND',
  // line login
  USER_LOGIN_EMAIL_IN_USE: 'USER_LOGIN_EMAIL_IN_USE',
  LINE_TOKEN_LINE_ID_MISSING: 'LINE_TOKEN_LINE_ID_MISSING',
  LINE_TOKEN_EMAIL_MISSING: 'LINE_TOKEN_EMAIL_MISSING',
  USER_BANNED: 'USER_BANNED',

  // UserAuth
  USER_AUTH_NO_ID: 'USER_AUTH_NO_ID',
  USER_AUTH_NOT_FOUND: 'USER_AUTH_NOT_FOUND',
  USER_AUTH_TYPE_NOT_SUPPORTED: 'USER_AUTH_TYPE_NOT_SUPPORTED',
  USER_AUTH_USERAGENT_NOT_SUPPORTED: 'USER_AUTH_USERAGENT_NOT_SUPPORTED',
  USER_AUTH_ALREADY_EXIST: 'USER_AUTH_ALREADY_EXIST',

  // Oauth
  OAUTH_FAIL_GETTING_UUID: 'OAUTH_FAIL_GETTING_UUID',

  // UserRegister
  USER_REGISTER_PHONE_REQUIRED: 'USER_REGISTER_PHONE_REQUIRED',
  USER_REGISTER_FIRSTNAME_REQUIRED: 'USER_REGISTER_FIRSTNAME_REQUIRED',
  USER_REGISTER_LASTNAME_REQUIRED: 'USER_REGISTER_LASTNAME_REQUIRED',
  USER_REGISTER_GENDER_REQUIRED: 'USER_REGISTER_GENDER_REQUIRED',
  USER_REGISTER_IDCARD_ID_REQUIRED: 'USER_REGISTER_IDCARD_ID_REQUIRED',
  USER_REGISTER_ADDRESS_REQUIRED: 'USER_REGISTER_ADDRESS_REQUIRED',
  USER_REGISTER_EMAIL_REQUIRED: 'USER_REGISTER_EMAIL_REQUIRED',
  USER_REGISTER_CONTACT_EMAIL_REQUIRED: 'USER_REGISTER_CONTACT_EMAIL_REQUIRED',
  USER_REGISTER_USERAGENT_REQUIRED: 'USER_REGISTER_USERAGENT_REQUIRED',
  USER_REGISTER_TYPE_REQUIRED: 'USER_REGISTER_TYPE_REQUIRED',
  USER_REGISTER_PHOTOS_REQUIRED: 'USER_REGISTER_PHOTOS_REQUIRED',
  USER_REGISTER_BIRTH_REQUIRED: 'USER_REGISTER_BIRTH_REQUIRED',
  USER_REGISTER_IDTOKEN_REQUIRED: 'USER_REGISTER_IDTOKEN_REQUIRED',
  USER_REGISTER_DATA_REQUIRED: 'USER_REGISTER_DATA_REQUIRED',
  USER_REGISTER_PAYMENTTYPE_REQUIRED: 'USER_REGISTER_PAYMENTTYPE_REQUIRED',

  USER_REGISTER_PHOTOS_REQUIRE_FIVE: 'USER_REGISTER_PHOTOS_REQUIRE_FIVE',
  USER_REGISTER_GENDER_WRONG_FORMAT: 'USER_REGISTER_GENDER_WRONG_FORMAT',
  USER_REGISTER_TYPE_WRONG_FORMAT: 'USER_REGISTER_TYPE_WRONG_FORMAT',
  USER_REGISTER_USERAGENT_WRONG_FORMAT: 'USER_REGISTER_USERAGENT_WRONG_FORMAT',
  USER_REGISTER_IDCARD_DUPLICATE: 'USER_REGISTER_IDCARD_DUPLICATE',
  USER_REGISTER_PAYMENTTYPE_WRONG_FORMAT: 'USER_REGISTER_PAYMENTTYPE_WRONG_FORMAT',
  USER_REGISTER_UPLOAD_IMAGE_NOT_FOUND: 'USER_REGISTER_UPLOAD_IMAGE_NOT_FOUND',
  USER_REGISTER_FAIL_GETTING_UUID: 'USER_REGISTER_FAIL_GETTING_UUID',

  // UserLoginHistory
  USER_LOGIN_HISTORY_NOT_FOUND: 'USER_LOGIN_HISTORY_NOT_FOUND',

  // User Renting
  USER_RENTING_UNABLE_FETCH_GPS: 'USER_RENTING_UNABLE_FETCH_GPS',

  USER_PAYMENT_ERROR: 'USER_PAYMENT_ERROR',
  USER_EXPIRED_TOKEN: 'USER_EXPIRED_TOKEN',

  // User TimePlan
  USER_DUPLICATED_TIME_PLAN: 'USER_DUPLICATED_TIME_PLAN',
  USER_INVALID_TIME_PLAN_PRICE: 'USER_INVALID_TIME_PLAN_PRICE',
  TIME_PLAN_NOT_FOUND: 'TIME_PLAN_NOT_FOUND',

  // scooter
  SCOOTER_NOT_FOUND: 'SCOOTER_NOT_FOUND',
  SCOOTER_IS_OCCUPIED: 'SCOOTER_IS_OCCUPIED',
  SCOOTER_CONTROL_FAILURE: 'SCOOTER_CONTROL_FAILURE',
  SCOOTER_NOT_IN_ZONE: 'SCOOTER_NOT_IN_ZONE',
  SCOOTER_NO_OLD_BOX_ID: 'SCOOTER_NO_OLD_BOX_ID',
  SCOOTER_NOT_MATCH_OLD_BOX_ID: 'SCOOTER_NOT_MATCH_OLD_BOX_ID',
  SCOOTER_DUPLICATE_BOX_ID: 'SCOOTER_DUPLICATE_BOX_ID',

  // rent
  RENT_NOT_FOUND: 'RENT_NOT_FOUND',
  RENT_FAIL_CREATE_ORDER: 'RENT_FAIL_CREATE_ORDER',
  RENT_FAIL_REFUND_ORDER: 'RENT_FAIL_REFUND_ORDER',
  RENT_FAIL_CANCEL_INVOICE: 'RENT_FAIL_CANCEL_INVOICE',
  RENT_INVALID_ORDER_STATUS: 'RENT_INVALID_ORDER_STATUS',
  RENT_TYPE_NOT_FOUND: 'RENT_TYPE_NOT_FOUND',
  RENT_USER_RESERVATION_IS_CONFLICT: 'RENT_USER_RESERVATION_IS_CONFLICT',
  RENT_SCOOTER_IS_CONFLICT: 'RENT_SCOOTER_IS_CONFLICT',
  RENT_POWER_OFF_FAIL: 'RENT_POWER_OFF_FAIL',
  RENT_RESET_ODO_FAIL: 'RENT_RESET_ODO_FAIL',
  RENT_INVALID_PRICING_PLAN: 'RENT_INVALID_PRICING_PLAN',
  RENT_FALLBACK: 'RENT_FALLBACK',
  RENT_FALLBACK_MENGO_POINT_NOT_ENOUGH: 'RENT_FALLBACK_MENGO_POINT_NOT_ENOUGH',
  RENT_FALLBACK_MENGO_SERVICE_UNAVAILABLE: 'RENT_FALLBACK_MENGO_SERVICE_UNAVAILABLE',
  RENT_ORDER_NOT_FOUND: 'RENT_ORDER_NOT_FOUND',
  RENT_USER_NOT_FOUND: 'RENT_USER_NOT_FOUND',

  // box
  BOX_NOT_FOUND: 'BOX_NOT_FOUND',
  BOX_MESSAGE_NOT_FOUND: 'BOX_MESSAGE_NOT_FOUND',
  BOX_INVALID_MODE: 'BOX_INVALID_MODE',
  BOX_DUPLICATE_IMEI: 'BOX_DUPLICATE_IMEI',
  BOX_UPDATE_MODE_FAIL: 'BOX_UPDATE_MODE_FAIL',

  // imeiSim
  IMEI_SIM_NOT_FOUND: 'IMEI_SIM_NOT_FOUND',

  // register
  REGISTER_NO_PARAMS: 'REGISTER_NO_PARAMS',

  // manager TODO: DELETE THESE - 這應該4沒有?
  // MANAGER_NO_TOKEN: 'MANAGER_NO_TOKEN',
  // MANAGER_INVALID_TOKEN: 'MANAGER_INVALID_TOKEN',
  // MANAGER_NO_USERID: 'MANAGER_NO_USERID',
  // MANAGER_NOT_FOUND: 'MANAGER_NOT_FOUND',
  // MANAGER_MAINTENANCEITEM_PERMISSION_FAILED: 'MANAGER_MAINTENANCEITEM_PERMISSION_FAILED',

  // WORKER_INVALID_TOKEN: 'WORKER_INVALID_TOKEN',
  // WORKER_NO_ID: 'WORKER_NO_ID',
  // WORKER_DENY_RELEASE: 'WORKER_DENY_RELEASE',
  // WORKER_NOT_FOUND: 'WORKER_NOT_FOUND',

  // promotion
  PROMOTION_NOT_FOUND: 'PROMOTION_NOT_FOUND',
  PROMOTION_NO_PROMOTION_ID: 'PROMOTION_NO_PROMOTION_ID',
  PROMOTION_NO_PROMOTION_CODE: 'PROMOTION_NO_PROMOTION_CODE',
  PROMOTION_INVALID_PROMOTION_CODE: 'PROMOTION_INVALID_PROMOTION_CODE', // v1
  PROMOTION_USED_UP_PROMOTION_CODE: 'PROMOTION_USED_UP_PROMOTION_CODE', // v1
  PROMOTION_DUPLICATE_PROMOTION_CODE: 'PROMOTION_DUPLICATE_PROMOTION_CODE',
  PROMOTION_NOT_SUITABLE_PROMOTION_CODE: 'PROMOTION_NOT_SUITABLE_PROMOTION_CODE',
  PROMOTION_VALIDATION_FAILURE: 'PROMOTION_VALIDATION_FAILURE',
  PROMOTION_UNEXPECTED_PROMOTION_TYPE: 'PROMOTION_UNEXPECTED_PROMOTION_TYPE',

  PROMOTION_A_REDEEMED_B_NOT_SUITABLE: 'PROMOTION_A_REDEEMED_B_NOT_SUITABLE',

  // v1 promotion error migrate
  PROMOTION_NO_PROMOTION_CODES: 'PROMOTION_NO_PROMOTION_CODES',
  PROMOTION_HAS_EXPIRED: 'PROMOTION_HAS_EXPIRED',
  PROMOTION_DUPLICATE_LIMITED_PROMOTION: 'PROMOTION_DUPLICATE_LIMITED_PROMOTION',
  PROMOTION_NOT_SUITABLE_USERGROUP: 'PROMOTION_NOT_SUITABLE_USERGROUP',
  PROMOTION_NOT_SUITABLE_CREDITCARD: 'PROMOTION_NOT_SUITABLE_CREDITCARD',
  PROMOTION_NOT_SUITABLE_FIRSTRIDE: 'PROMOTION_NOT_SUITABLE_FIRSTRIDE',
  PROMOTION_NOT_SUITABLE_INVITEE: 'PROMOTION_NOT_SUITABLE_INVITEE',
  PROMOTION_DUPLICATE_PROMOTION_EVENT: 'PROMOTION_DUPLICATE_PROMOTION_EVENT',
  PROMOTION_NOT_SUITABLE_REGISTERDATE: 'PROMOTION_NOT_SUITABLE_REGISTERDATE',
  // ride plan code
  PROMOTION_RIDEPLAN_INACTIVE_CODE: 'PROMOTION_RIDEPLAN_INACTIVE_CODE',
  PROMOTION_NOT_BEGIN_YET: 'PROMOTION_NOT_BEGIN_YET',
  PROMOTION_RIDEPLAN_FAIL_TO_EXCHANGE_TIMEPLAN: 'PROMOTION_RIDEPLAN_FAIL_TO_EXCHANGE_TIMEPLAN',
  PROMOTION_RIDEPLAN_CANNOT_REDEEM_RENTING: 'PROMOTION_RIDEPLAN_CANNOT_REDEEM_RENTING',
  PROMOTION_RIDEPLAN_NON_ACTIVE_USER: 'PROMOTION_RIDEPLAN_NON_ACTIVE_USER',

  // payment
  PAYMENT_GET_ORDERS_FAILURE: 'PAYMENT_GET_ORDERS_FAILURE',
  PAYMENT_TYPE_FAILURE: 'PAYMENT_TYPE_FAILURE',
  PAYMENT_TRANS_PARTIAL_FAILURE: 'PAYMENT_TRANS_PARTIAL_FAILURE',

  // invoice
  INVOICE_NOT_FOUND: 'INVOICE_NOT_FOUND',
  INVOICE_INVALID_CONTENT: 'INVOICE_INVALID_CONTENT',
  INVOICE_DUPLICATE_INVOICE: 'INVOICE_DUPLICATE_INVOICE',
  INVOICE_UNKNOWN_INVOICE_TYPE: 'INVOICE_UNKNOWN_INVOICE_TYPE',

  // Iris  TODO: DELETE THESE - 這應該4沒有?
  // IRIS_RETURN_ERROR: 'IRIS_RETURN_ERROR',
  // IRIS_GET_USERS_RESULT_FAILURE: 'IRIS_GET_USERS_RESULT_FAILURE',
  // IRIS_INTERNAL_ERROR: 'IRIS_GET_USER_RESULT_FAILURE',

  // credit
  CREDIT_CREATE_CREDIT_FAILURE: 'CREDIT_CREATE_CREDIT_FAILURE',
  CREDIT_DELETE_CREDIT_FAILURE: 'CREDIT_DELETE_CREDIT_FAILURE',

  // maintenanceItems TODO: DELETE THESE - 這應該4沒有?
  // MAINTENANCE_ITEMS_NOT_FOUND: 'MAINTENANCE_ITEMS_NOT_FOUND',
  // MAINTENANCE_ITEMS_PARAMS_INVALID: 'MAINTENANCE_ITEMS_PARAMS_INVALID',
  // MAINTENANCE_ITEMS_UPSERT_EMPTY: 'MAINTENANCE_ITEMS_UPSERT_EMPTY',
  // MAINTENANCE_ITEMS_UPSERT_ID_INVALID: 'MAINTENANCE_ITEMS_UPSERT_ID_INVALID',
  // MAINTENANCE_ITEMS_UPSERT_TYPE_INVALID: 'MAINTENANCE_ITEMS_UPSERT_TYPE_INVALID',
  // MAINTENANCE_ITEMS_UPSERT_NAME_INVALID: 'MAINTENANCE_ITEMS_UPSERT_NAME_INVALID',
  // MAINTENANCE_ITEMS_UPSERT_DESCRIPTION_INVALID: 'MAINTENANCE_ITEMS_UPSERT_DESCRIPTION_INVALID',
  // MAINTENANCE_ITEMS_UPSERT_VALIDATION_FAILED: 'MAINTENANCE_ITEMS_UPSERT_VALIDATION_FAILED',
  // MAINTENANCE_ITEMS_UPSERT_SAVING_FAILED: 'MAINTENANCE_ITEMS_UPSERT_SAVING_FAILED',

  // maintenances TODO: DELETE THESE - 這應該4沒有?
  // MAINTENANCE_IS_RESOLVED: 'MAINTENANCE_IS_RESOLVED',
  // MAINTENANCE_IS_VERIFIED: 'MAINTENANCE_IS_VERIFIED',
  // MAINTENANCES_UPSERT_ID_INVALID: 'MAINTENANCES_UPSERT_ID_INVALID',
  // MAINTENANCES_UPSERT_SCOOTER_INVALID: 'MAINTENANCES_UPSERT_SCOOTER_INVALID',
  // MAINTENANCES_UPSERT_ITEM_INVALID: 'MAINTENANCES_UPSERT_ITEM_INVALID',
  // MAINTENANCES_UPSERT_PARAMS_INVALID: 'MAINTENANCES_UPSERT_PARAMS_INVALID',
  // MAINTENANCES_UPSERT_SAVING_DENIED: 'MAINTENANCES_UPSERT_SAVING_DENIED',
  // MAINTENANCES_UPSERT_SAVING_FAILED: 'MAINTENANCES_UPSERT_SAVING_FAILED',

  // maintenanceNotes TODO: DELETE THESE - 這應該4沒有?
  // MAINTENANCE_NOTES_NOT_FOUND: 'MAINTENANCE_NOTES_NOT_FOUND',
  // MAINTENANCE_NOTES_UPSERT_ID_INVALID: 'MAINTENANCE_NOTES_UPSERT_ID_INVALID',
  // MAINTENANCE_NOTES_UPSERT_TEXT_INVALID: 'MAINTENANCE_NOTES_UPSERT_TEXT_INVALID',
  // MAINTENANCE_NOTES_UPSERT_MAINTENANCES_INVALID: 'MAINTENANCE_NOTES_UPSERT_MAINTENANCES_INVALID',
  // MAINTENANCE_NOTES_UPSERT_AUTHOR_INVALID: 'MAINTENANCE_NOTES_UPSERT_AUTHOR_INVALID',
  // MAINTENANCE_NOTES_VALIDATION_FAILED: 'MAINTENANCE_NOTES_VALIDATION_FAILED',
  // MAINTENANCE_NOTES_SAVING_FAILED: 'MAINTENANCE_NOTES_SAVING_FAILED',
  // MAINTENANCE_NOTES_PARAMS_INVALID: 'MAINTENANCE_NOTES_PARAMS_INVALID',
  // MAINTENANCE_PERMISSION_DENIED: 'MAINTENANCE_PERMISSION_DENIED',

  // maintenance Comment TODO: DELETE THESE - 這應該4沒有?
  // MAINTENANCE_COMMENT_INSUFFICIENT_PRIVILEGE: 'MAINTENANCE_COMMENT_INSUFFICIENT_PRIVILEGE',
  // MAINTENANCE_NOT_FOUND: 'MAINTENANCE_NOT_FOUND',
  // MAINTENANCE_COMMENT_FAILURE: 'MAINTENANCE_COMMENT_FAILURE',

  // workgroups TODO: DELETE THESE - 這應該4沒有?
  // WORKGROUPS_NOT_FOUND: 'WORKGROUPS_NOT_FOUND',
  // WORKGROUPS_PARAMS_INVALID: 'WORKGROUPS_PARAMS_INVALID',
  // WORKGROUPS_UPSERT_EMPTY: 'WORKGROUPS_UPSERT_EMPTY',
  // WORKGROUPS_UPSERT_ID_INVALID: 'WORKGROUPS_UPSERT_ID_INVALID',
  // WORKGROUPS_UPSERT_NAME_INVALID: 'WORKGROUPS_UPSERT_NAME_INVALID',
  // WORKGROUPS_VALIDATION_FAILED: 'WORKGROUPS_VALIDATION_FAILED',
  // WORKGROUPS_SAVING_FAILED: 'WORKGROUPS_SAVING_FAILED',

  // roles
  ROLES_PARAMS_INVALID: 'ROLES_PARAMS_INVALID',
  ROLES_UPSERT_ID_INVALID: 'ROLES_UPSERT_ID_INVALID',
  ROLES_UPSERT_NAME_INVALID: 'ROLES_UPSERT_NAME_INVALID',
  ROLES_VALIDATION_FAILED: 'ROLES_VALIDATION_FAILED',

  // services TODO: DELETE THESE - 這應該4沒有?
  // SERVICE_PARAMS_INVALID: 'SERVICE_PARAMS_INVALID',
  // SERVICE_NOT_FOUND: 'SERVICE_NOT_FOUND',
  // SERVICE_IS_STARTED: 'SERVICE_IS_STARTED',
  // SERVICE_IS_NOT_STARTED: 'SERVICE_IS_NOT_STARTED',
  // SERVICE_IS_FINISHED: 'SERVICE_IS_FINISHED',
  // SERVICE_PERMISSION_DENIED: 'SERVICE_PERMISSION_DENIED',
  // SERVICE_UPSERT_PARAMS_INVALID: 'SERVICE_UPSERT_PARAMS_INVALID',
  // SERVICE_UPSERT_ID_INVALID: 'SERVICE_UPSERT_ID_INVALID',
  // SERVICE_UPSERT_VALIDATION_FAILED: 'SERVICE_UPSERT_VALIDATION_FAILED',
  // SERVICE_UPSERT_SAVING_DENIED: 'SERVICE_UPSERT_SAVING_DENIED',
  // SERVICE_UPSERT_SAVING_FAILED: 'SERVICE_UPSERT_SAVING_FAILED',

  // appConfig TODO: ?! - WHAT'S THIS?
  APPCONFIG_ADD_ANNOUNCEMENT_FAILED: 'APPCONFIG_ADD_ANNOUNCEMENT_FAILED',
  APPCONFIG_ADD_NEWS_FAILED: 'APPCONFIG_ADD_NEWS_FAILED',
  APPCONFIG_EDIT_ANNOUNCEMENT_FAILED: 'APPCONFIG_EDIT_ANNOUNCEMENT_FAILED',
  APPCONFIG_EDIT_NEWS_FAILED: 'APPCONFIG_EDIT_NEWS_FAILED',
  APPCONFIG_DELETE_ANNOUNCEMENT_FAILED: 'APPCONFIG_DELETE_ANNOUNCEMENT_FAILED',
  APPCONFIG_DELETE_NEWS_FAILED: 'APPCONFIG_DELETE_NEWS_FAILED',
  APPCONFIG_GET_ANNOUNCEMENT_FAILED: 'APPCONFIG_GET_ANNOUNCEMENT_FAILED',
  APPCONFIG_GET_NEWS_FAILED: 'APPCONFIG_GET_NEWS_FAILED',

  // pricing plans
  PRICING_PLAN_USERGROUP_ALREADY_EXIST: 'PRICING_PLAN_USERGROUP_ALREADY_EXIST',
  PRICING_PLAN_DEFAULTPLAN_NOT_EDITABLE: 'PRICING_PLAN_DEFAULTPLAN_NOT_EDITABLE',
  PRICING_PLAN_CANT_INACTIVE: 'PRICING_PLAN_CANT_INACTIVE',

  // user category
  DELETE_USER_CATEGORY_ERROR: 'DELETE_USER_CATEGORY_ERROR',
  DELETE_USER_CATEGORY_DOMAIN_ERROR: 'DELETE_USER_CATEGORY_DOMAIN_ERROR',
  GET_USER_CATEGORIES_ERROR: 'GET_USER_CATEGORIES_ERROR',
  SAVING_USER_CATEGORY_ERROR: 'SAVING_USER_CATEGORY_ERROR',

  // pricing group
  GET_PRICING_GROUP_ERROR: 'GET_PRICING_GROUP_ERROR',
  PATCH_PRICING_GROUP_ERROR: 'PATCH_PRICING_GROUP_ERROR',
  SAVING_PRICING_GROUP_ERROR: 'SAVING_PRICING_GROUP_ERROR',

  GET_SCOOTER_GROUPS_ERROR: 'GET_SCOOTER_GROUPS_ERROR',
  SCOOTER_GROUPS_SAVING_ERROR: 'SCOOTER_GROUPS_SAVING_ERROR',
  SCOOTER_GROUPS_PARAMS_ERROR: 'SCOOTER_GROUPS_PARAMS_ERROR',
  SCOOTER_GROUPS_PATCH_ERROR: 'SCOOTER_GROUPS_PATCH_ERROR',
  SCOOTER_GROUPS_DELETING_ERROR: 'SCOOTER_GROUPS_DELETING_ERROR',
  SCOOTER_GROUPS_FETCH_ERROR: 'SCOOTER_GROUPS_FETCH_ERROR',
  SCOOTER_GROUPS_PUT_ERROR: 'SCOOTER_GROUPS_PUT_ERROR',
  SCOOTER_GROUPS_DELETE_ERROR: 'SCOOTER_GROUPS_DELETE_ERROR',
  SCOOTER_GROUPS_NOT_FOUND: 'SCOOTER_GROUPS_NOT_FOUND',

  // activity
  ACTIVITY_NOT_FOUND: 'ACTIVITY_NOT_FOUND',
  ACTIVITY_USED_UP_ACTIVITY_CODE: 'ACTIVITY_USED_UP_ACTIVITY_CODE',
  ACTIVITY_DUPLICATE_SPECIAL_CODE: 'ACTIVITY_DUPLICATE_SPECIAL_CODE',
  ACTIVITY_IS_DUPLICATE: 'ACTIVITY_IS_DUPLICATE',
  ACTIVITY_INVALID_VERIFICATION: 'ACTIVITY_INVALID_VERIFICATION',
  ACTIVITY_VERIFICATION_DUPLICATE: 'ACTIVITY_VERIFICATION_DUPLICATE',
  ACTIVITY_NOT_ALLOW_DELETE: 'ACTIVITY_NOT_ALLOW_DELETE',
  ACTIVITY_NOT_ALLOW_SET_DEFAULT: 'ACTIVITY_NOT_ALLOW_SET_DEFAULT',

  /** Enterprise */
  // parking lot
  FATCH_PARKING_LOTS_FAILED: 'FATCH_PARKING_LOTS_FAILED',
  PARKING_LOTS_SEEDER_ERROR: 'PARKING_LOTS_SEEDER_ERROR',
  PARKING_LOTS_NOT_FOUND: 'PARKING_LOTS_NOT_FOUND',
  PARKING_LOTS_PARAMS_INVALID: 'PARKING_LOTS_PARAMS_INVALID',
  PARKING_LOTS_INVALID_SPACE_ID: 'PARKING_LOTS_INVALID_SPACE_ID',
  PARKING_LOTS_HISTORY_DUPLICATE: 'PARKING_LOTS_HISTORY_DUPLICATE',
  PARKING_LOT_NON_RESERVABLE: 'PARKING_LOT_NON_RESERVABLE',
  PARKING_LOT_OUT_OF_BUSINESS_HOUR: 'PARKING_LOT_OUT_OF_BUSINESS_HOUR',
  PARKING_LOT_IN_GATE_NON_OPENABLE: 'PARKING_LOT_IN_GATE_NON_OPENABLE',
  PARKING_LOT_OUT_GATE_NON_OPENABLE: 'PARKING_LOT_OUT_GATE_NON_OPENABLE',
  PARKING_LOT_NON_RETURNABLE: 'PARKING_LOT_NON_RETURNABLE',
  
  // parking box
  PARKING_BOX_IOT_NOT_CONNECTED: 'PARKING_BOX_IOT_NOT_CONNECTED',
  PARKING_BOX_NOT_FOUND: 'PARKING_BOX_NOT_FOUND',
  PARKING_BOX_TOKEN_DECRYPTION_ERROR: 'PARKING_BOX_TOKEN_DECRYPTION_ERROR',

  // entity columns
  INVALID_DATE_FORMAT: 'INVALID_DATE_FORMAT',

  ENTERPRISE_VERIFICATION_VERIFY_ERROR: 'ENTERPRISE_VERIFICATION_VERIFY_ERROR',
  // exbat
  EXBAT_SAVING_ERROR: 'EXBAT_SAVING_ERROR',
  GET_EXBAT_ERROR: 'GET_EXBAT_ERROR',
  GET_LOCATION_ERROR: 'GET_LOCATION_ERROR',
  GET_REASON_ERROR: 'GET_REASON_ERROR',

  // operation zone
  OPERATION_ZONE_CAN_NOT_RENT: 'OPERATION_ZONE_CAN_NOT_RENT',
  OPERATION_ZONE_CAN_NOT_RETURN: 'OPERATION_ZONE_CAN_NOT_RETURN',

  // Mengo
  MENGO_INVALID_CARD_NO: 'MENGO_INVALID_CARD_NO',
  MENGO_POINT_NOT_ENOUGH: 'MENGO_POINT_NOT_ENOUGH',
  /** Mengo 3rd party service unavailable */
  MENGO_SERVICE_UNAVAILABLE: 'MENGO_SERVICE_UNAVAILABLE',
  /** Mengo 3rd party service unavailable */
  MENGO_JANUS_UNAVAILABLE: 'MENGO_JANUS_UNAVAILABLE',

  // permission
  PERMISSION_NOT_FOUND: 'PERMISSION_NOT_FOUND',

  // credit card
  CREDIT_CARD_FORMAT: 'CREDIT_CARD_FORMAT',
  CREDIT_CARD_EXPIRED: 'CREDIT_CARD_EXPIRED',
  CREDIT_CARD_NOT_SUPPORT: 'CREDIT_CARD_NOT_SUPPORT',
  CREDIT_CARD_AUTH: 'CREDIT_CARD_AUTH',
  CREDIT_CARD_DUPLICATE_TRANS: 'CREDIT_CARD_DUPLICATE_TRANS',
  CREDIT_CARD_ERROR: 'CREDIT_CARD_ERROR',
};

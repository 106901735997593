import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const InLinePublicRoute = React.memo(({ component: Component, routes, ...rest }) => {
  const hasAuth = window.liff?.isInClient() || process.env.REACT_APP_IS_LOCAL === 'true';

  return (
    <Route
      {...rest}
      render={({ location, ...props }) =>
        hasAuth ? (
          <Component {...props} location={location} routes={routes} />
        ) : (
          <Redirect
            to={{
              pathname: '/errors/unauthorized',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
});

export default InLinePublicRoute;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TakePhotoButton from 'Components/atoms/TakePhotoButton';
import ReturnScooterImage from 'Images/return-scooter.png';

const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const PureTakeScooterPhoto = props => {
  const { isCaptured, facingMode, onChange, display } = props;
  return (
    <TakePhotoButton
      color="plan"
      shape="round"
      width={['220px', '286px']}
      height={['293px', '380.9px']}
      isCaptured={isCaptured}
      facingMode={facingMode}
      display={display}
      onChange={onChange}
    >
      <Image src={ReturnScooterImage} />
    </TakePhotoButton>
  );
};

PureTakeScooterPhoto.defaultProps = {
  isCaptured: false,
  facingMode: 'environment',
  display: 'inline-block',
};

PureTakeScooterPhoto.propTypes = {
  isCaptured: PropTypes.bool,
  facingMode: PropTypes.oneOf(['user', 'environment']),
  onChange: PropTypes.func,
  display: PropTypes.string,
};

export default React.memo(PureTakeScooterPhoto);

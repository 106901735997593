import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorCase from '~/components/organisms/ErrorCase';

const NotFound = () => {
  const history = useHistory();

  const handleOnClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <ErrorCase
      title="找不到頁面"
      description="你 484 來錯地方了"
      buttonText="回到上一頁"
      onClick={handleOnClick}
    />
  );
};

NotFound.defaultProps = {};

NotFound.propTypes = {};

export default NotFound;

import React from 'react';
import { userStatus, wemoPhone } from '~/configs';
import styled from 'styled-components';

const WarningTitle = styled.div`
  color: ${props => props.theme.colors.wrongRed};
`;

const InfoTitle = styled.div`
  color: ${props => props.theme.colors.mainBlue};
`;

export const getAlertMessage = (currentUserStatus, history, date, reason) => {
  const setting = {
    [userStatus.pending]: {
      title: <InfoTitle>帳號審核中</InfoTitle>,
      content: '帳號審核中，結果將以 e-mail 通知',
    },
    [userStatus.unpassed]: {
      title: <WarningTitle>目前無法租車</WarningTitle>,
      content: '審核未過，請更新註冊資料',
      buttonText: '更新',
      onAlertClick: () => history?.replace('/further-documents'),
    },
    [userStatus.disable]: {
      title: <WarningTitle>帳號已停權</WarningTitle>,
      content: '請洽客服以取得更多資訊',
      buttonText: '客服',
      onAlertClick: () => (window.location.href = `tel:${wemoPhone}`),
    },
    [userStatus.incomplete]: {
      title: <WarningTitle>註冊資料待補</WarningTitle>,
      content: `請於 ${date || ''} 前更新資料，否則將無法租車`,
      buttonText: '更新',
      onAlertClick: () => history?.replace('/further-documents'),
    },
    [userStatus.incomplete30]: {
      title: <WarningTitle>註冊資料待補</WarningTitle>,
      content: `請於 ${date || ''} 前更新資料，否則將無法租車`,
      buttonText: '更新',
      onAlertClick: () => history?.replace('/further-documents'),
    },
    [userStatus.updated]: {
      title: <InfoTitle>帳號審核中</InfoTitle>,
      content: '結果將以 e-mail 通知，期間可租車',
    },
  };

  return () => setting[currentUserStatus];
};

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { useDebouncedCallback } from 'use-debounce';
import copy from 'copy-to-clipboard';
import qs from 'qs';
import BasicContainer from 'Components/layouts/BasicContainer';
import Button from 'Components/atoms/Button';
import FullScreenLoading from '~/components/molecules/FullScreenLoading';
import PopupLoading from '~/components/molecules/PopupLoading';
import useAsyncGet from '~/hooks/useAsyncGet';
import useLoading from '~/hooks/useLoading';
import { getUserInvitaionInfo } from '~/services/api';
import FriendlyPng from 'Images/friendly.png';

const sharePickerJson = code => [
  {
    type: 'flex',
    altText: `嗨！這是我的 WeMo Scooter 專屬邀請碼：${code}，立即註冊即可免費體驗最便利的交通新選擇！\n${
      process.env.REACT_APP_LINE_MINI_URL
    }?${qs.stringify({
      utm_medium: 'invitee',
      utm_source: 'line',
      utm_campaign: 'website',
      utm_content: code,
    })}`,
    contents: {
      type: 'bubble',
      body: {
        type: 'box',
        layout: 'vertical',
        contents: [
          {
            type: 'image',
            aspectRatio: '4:3',
            gravity: 'top',
            aspectMode: 'cover',
            size: 'full',
            url: process.env.REACT_APP_VENUS_URL + '/share-invite.jpg',
          },
          {
            type: 'box',
            layout: 'vertical',
            contents: [
              {
                type: 'box',
                layout: 'vertical',
                contents: [
                  {
                    type: 'text',
                    text: 'WeMo Scooter',
                    size: 'xl',
                    color: '#555555',
                    weight: 'bold',
                    style: 'normal',
                    decoration: 'none',
                  },
                ],
                paddingTop: '10px',
              },
              {
                type: 'box',
                layout: 'baseline',
                contents: [
                  {
                    type: 'text',
                    text: `嗨！這是我的 WeMo Scooter 專屬邀請碼：${code}，立即註冊即可免費體驗最便利的交通新選擇！`,
                    color: '#555555',
                    size: 'sm',
                    flex: 0,
                    wrap: true,
                  },
                ],
                spacing: 'lg',
                paddingTop: '10px',
              },
              {
                type: 'box',
                layout: 'vertical',
                contents: [
                  {
                    type: 'filler',
                  },
                  {
                    type: 'box',
                    layout: 'baseline',
                    contents: [
                      {
                        type: 'filler',
                      },
                      {
                        type: 'text',
                        text: '立刻出發',
                        color: '#ffffff',
                        flex: 0,
                        offsetTop: '-2px',
                      },
                      {
                        type: 'filler',
                      },
                    ],
                    spacing: 'sm',
                  },
                  {
                    type: 'filler',
                  },
                ],
                borderWidth: '1px',
                cornerRadius: '8px',
                spacing: 'sm',
                borderColor: '#4bc6e8',
                margin: 'xxl',
                height: '40px',
                action: {
                  type: 'uri',
                  label: 'action',
                  uri: `${process.env.REACT_APP_LINE_MINI_URL}?${qs.stringify({
                    utm_medium: 'invitee',
                    utm_source: 'line',
                    utm_campaign: 'website',
                    utm_content: code,
                  })}`,
                },
                backgroundColor: '#4bc6e8',
              },
            ],
            position: 'relative',
            offsetBottom: '0px',
            offsetStart: '0px',
            offsetEnd: '0px',
            backgroundColor: '#ffffff',
            paddingAll: '20px',
            paddingTop: '18px',
          },
          {
            type: 'box',
            layout: 'vertical',
            contents: [
              {
                type: 'text',
                text: '想去哪 就出發！',
                color: '#ffffff',
                align: 'center',
                size: 'md',
                offsetTop: '5px',
                weight: 'bold',
              },
            ],
            position: 'absolute',
            cornerRadius: '10px',
            backgroundColor: '#98C945',
            height: '30px',
            width: '150px',
            offsetTop: '210px',
            offsetStart: '-10px',
          },
        ],
        paddingAll: '0px',
      },
      styles: {
        footer: {
          separator: true,
        },
      },
    },
  },
];

const Wrapper = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  ${themeGet('fonts.h1')};
  margin: 0;
  margin-top: 10px;
`;

const Description = styled.p`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
  margin-top: 4px;
`;

const InvitationCode = styled.h4`
  ${themeGet('fonts.h4')};
  margin: 30px auto 0;
  margin-top: 30px;
  height: 60px;
  width: 240px;
  line-height: 60px;
  border-radius: 10px;
  color: ${themeGet('colors.mainBlue')};
  background: ${themeGet('colors.typeSpaceGrey')};
`;

const FriendlyImg = styled.img.attrs({ src: FriendlyPng })``;

const SubTitle = styled(Description)`
  color: ${themeGet('colors.supGrey')};
`;

const CopyButton = styled(Button)`
  margin-top: 40px;
  width: 240px;
`;

export const PureInvitation = props => {
  const invitationInfo = useAsyncGet(getUserInvitaionInfo, { loading: true, data: {} });
  const copyLoading = useLoading();
  const shareLoading = useLoading();

  const [handleCopyOnClick] = useDebouncedCallback(
    () => {
      const code = invitationInfo.data.invitationCode;
      copyLoading.start();
      copy(
        code
          ? `嗨！這是我的 WeMo Scooter 專屬邀請碼：${code}，立即註冊即可免費體驗最便利的交通新選擇！
          ${process.env.REACT_APP_LINE_MINI_URL}?${qs.stringify({
              utm_medium: 'invitee',
              utm_source: 'line',
              utm_campaign: 'website',
              utm_content: code,
            })}`
          : ''
      );
      copyLoading.end();
    },
    300,
    { leading: true, trailing: false }
  );

  const [handleShareOnclick] = useDebouncedCallback(
    () => {
      const code = invitationInfo.data.invitationCode;

      if (window.liff.isApiAvailable('shareTargetPicker')) {
        shareLoading.start();

        window.liff
          .shareTargetPicker(sharePickerJson(code))
          .then(() => {
            shareLoading.end();
          })
          .catch(function(res) {
            shareLoading.end();
            handleCopyOnClick();
          });
      } else {
        handleCopyOnClick();
      }
    },
    300,
    { leading: true, trailing: false }
  );

  useEffect(() => {
    invitationInfo.getData();
  }, []);

  return (
    <FullScreenLoading spinning={invitationInfo.loading}>
      <BasicContainer>
        <Wrapper>
          <FriendlyImg />
          <Title>與朋友同享騎乘金</Title>
          <Description>
            每當有新朋友使用您的優惠碼註冊，他們即可獲得騎乘金 50
            只要他們完成第一次騎乘，您就可以獲得騎乘金 50。
          </Description>
          <InvitationCode onClick={handleCopyOnClick}>
            {invitationInfo.data.invitationCode || '----'}
          </InvitationCode>
          <SubTitle>你的專屬邀請碼</SubTitle>
          <CopyButton boxShadow="light" shape="capsule" type="float" onClick={handleShareOnclick}>
            分享
          </CopyButton>
        </Wrapper>
      </BasicContainer>
      <PopupLoading {...copyLoading.state} successText="複製成功" />
      <PopupLoading {...shareLoading.state} successText="分享成功" />
    </FullScreenLoading>
  );
};

PureInvitation.defaultProps = {};

PureInvitation.propTypes = {};

export default React.memo(PureInvitation);

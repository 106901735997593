import LineAxios from './LineAxios';

LineAxios.create('hermes');
const axios = LineAxios.getInstance('hermes').useToken();

export const getPaymentsInfo = async () => {
  // return axios.get('/v3/payments/info').then(({ data }) => data.data);
  return axios.get('/v5/payments/info').then(({ data }) => data.data);
};

export const patchPaymentsInfo = async paymentType => {
  return axios.patch('/v3/payments/info', { paymentType });
};

export const postBindCreditCard = async prime => {
  return axios.post('/v3/users/me/creditCards', { prime });
};

export const postPaymentsLineNotify = async rentId => {
  const liffAccessToken = window.liff.getAccessToken();
  if (!liffAccessToken) {
    console.log('No liff access token');
    return;
  }
  return axios.post('/v4/payments/lineNotify', {
    rentId,
    liffAccessToken,
    button_url: `${process.env.REACT_APP_LINE_MINI_URL}/order-detail?rentId=${rentId}`,
  });
};

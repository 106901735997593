import React, { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { useDebouncedCallback } from 'use-debounce';
import BasicContainer from 'Components/layouts/BasicContainer';
import ActionItemCard from '~/components/organisms/ActionItemCard';
import WeMoCreditInfo from '~/components/organisms/WeMoCreditInfo';
import FullScreenLoading from '~/components/molecules/FullScreenLoading';
import PaymentType from './PaymentType';
import useAsyncGet from '~/hooks/useAsyncGet';
import { getPaymentsInfo } from '~/services/api';
import PopupNote from '~/components/molecules/PopupNote';

const InvoiceTypes = {
  '0': '店家載具',
  '1': '手機條碼',
  '2': '捐贈',
  '3': '自然人憑證',
  '4': '統一編號',
};

const SettingPanel = styled.div`
  position: relative;
  width: 100%;

  > * {
    ${space}
  }
`;

const PayConfirmText = styled.p`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secondaryGrey')};
  margin: 0;
  text-align: center;
`;

const getPaymentInternalServerError = {
  title: '讀取失敗',
  notes: (
    <React.Fragment>
      <PayConfirmText>系統發生異常</PayConfirmText>
      <PayConfirmText>請稍後再試</PayConfirmText>
    </React.Fragment>
  ),
};

export const PurePayment = () => {
  const payment = useAsyncGet(getPaymentsInfo, {
    loading: true,
    data: { invoice: InvoiceTypes[0] },
  });
  const [showAlert, setShowAlert] = useState({ visible: false });

  const [handleAlertOnConfirm] = useDebouncedCallback(
    () => {
      setShowAlert({ visible: false });
    },
    300,
    { leading: true, trailing: false }
  );

  const init = useCallback(async () => {
    try {
      await payment.getData();
    } catch (error) {
      setShowAlert({ visible: true, ...getPaymentInternalServerError });
    }
  }, [payment]);

  useEffect(() => {
    init();
  }, []);

  return (
    <BasicContainer>
      <FullScreenLoading spinning={payment.loading}>
        <WeMoCreditInfo balance={payment.data.wemoCreditBalance} />
        <SettingPanel mt={['15px;', '30px']}>
          <ActionItemCard title="付款方式" actionText="設定" to="choose-payment" replace={true}>
            <PaymentType
              paymentType={payment.data.paymentType}
              walletBalance={payment.data.walletBalance}
              creditCard={payment.data.creditCard}
            />
          </ActionItemCard>
          <ActionItemCard title="發票設定" actionText="設定" to="choose-invoice" replace={true}>
            {InvoiceTypes[payment.data.invoice.type]}
          </ActionItemCard>
        </SettingPanel>
      </FullScreenLoading>
      <PopupNote {...showAlert} onConfirmClick={handleAlertOnConfirm} />
    </BasicContainer>
  );
};

PurePayment.defaultProps = {};

PurePayment.propTypes = {};

export default React.memo(PurePayment);

import { variant } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

export const buttonSize = props =>
  variant({
    prop: 'buttonSize',
    variants: {
      big: {
        ...themeGet(`fonts.h2Button`)(props),
        height: 60,
      },
      medium: {
        ...themeGet(`fonts.h3Button`)(props),
        height: 60,
      },
      small: {
        ...themeGet(`fonts.h5Button`)(props),
        height: 50,
      },
    },
  });

export const common = props => {
  return {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 500,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
  };
};

export const type = props =>
  variant({
    prop: 'type',
    variants: {
      default: {
        color: 'white',
      },
      float: {
        ...themeGet(!props.disabled ? `boxShadows.${props.boxShadow}` : '')(props),
      },
    },
  });

export const color = props =>
  variant({
    prop: 'color',
    variants: {
      plan: {
        backgroundColor: 'white',
        color: themeGet(`colors.secondaryGrey`, 'gray')(props),
      },
      primary: {
        backgroundColor: themeGet('colors.mainBlue', 'gray')(props),
        color: 'white',
      },
      black: {
        backgroundColor: '#000',
        color: 'white',
      },
      uselessBlue: {
        backgroundColor: themeGet(`colors.uselessBlue`, 'gray')(props),
        color: themeGet(`colors.mainBlue`, 'gray')(props),
      },
    },
  });

export const disabled = props =>
  variant({
    prop: 'disabled',
    variants: {
      true: {
        // ...themeGet(`fonts.h3Button`)(props),
        backgroundColor: themeGet('colors.unavailableGrey')(props),
        color: themeGet('colors.supGrey')(props),
        cursor: 'not-allowed',
      },
    },
  });

// NOTE: 曾出現過一個 capsule 按鈕會失去該樣式的問題
export const shape = props =>
  variant({
    prop: 'shape',
    variants: {
      circle: {
        borderRadius: '50%',
        width: 50,
        height: 50,
      },
      capsule: {
        padding: ['8px 20px', '8px 25px'],
        borderRadius: 50,
      },
      round: {
        borderRadius: 6,
      },
      sharp: {},
    },
  });

export default { type, shape, buttonSize, color, common };

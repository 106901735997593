import { useEffect, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { initializeFirebaseService } from '~/utils/firebaseService';

const useFirebase = recaptchaContainerId => {
  const recaptchaVerifierRef = useRef();
  const recaptchaContainerRef = useRef();
  const recaptchaRef = useRef();
  const confirmationResultRef = useRef();
  const shouldRenderReCaptcha = useRef(true);

  const sendMessage = useCallback(async phone => {
    const appVerifier = recaptchaVerifierRef.current;
    const result = await firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then(function(confirmationResult) {
        confirmationResultRef.current = confirmationResult;
        return { vId: confirmationResult.verificationId };
      });

    resetReCaptcha();
    return result;
  }, []);

  const confirmCode = async code => {
    try {
      await confirmationResultRef.current.confirm(`${code}`);
      return true;
    } catch (error) {
      return false;
    }
  };

  const setReCaptcha = () => {
    recaptchaVerifierRef.current = new firebase.auth.RecaptchaVerifier(
      recaptchaContainerRef.current,
      {
        size: 'invisible',
        callback: response => {
          recaptchaRef.current = response;
          shouldRenderReCaptcha.current = false;
        },
      }
    );
  };

  const initializeReCaptcha = useCallback(container => {
    recaptchaContainerRef.current = container;
    if (container && !recaptchaVerifierRef.current && shouldRenderReCaptcha.current) {
      setReCaptcha();
    }
  }, []);

  const resetReCaptcha = async () => {
    recaptchaVerifierRef.current.render().then(widgetId => {
      window.grecaptcha.reset(widgetId);
    });
  };

  const getIdToken = useCallback(async () => {
    const idToken = await firebase.auth().currentUser.getIdToken();
    return idToken;
  }, []);

  useEffect(() => {
    initializeFirebaseService();
    firebase.auth().useDeviceLanguage();
  }, [recaptchaContainerId]);

  return {
    sendMessage,
    initializeReCaptcha,
    confirmCode,
    getIdToken,
    recaptcha: recaptchaRef.current,
  };
};

export default useFirebase;

import { diff } from 'deep-object-diff';
import { userStatus } from '~/configs';

export const omit = (targets = [], source = {}) => {
  const tmp = {};
  const keys = Object.keys(source);
  for (const key of keys) {
    if (targets.includes(key)) continue;
    tmp[key] = source[key];
  }
  return tmp;
};

export const pick = (targets = [], source = {}) => {
  const tmp = {};
  for (const key of targets) {
    tmp[key] = source[key];
  }
  return tmp;
};

export const canUserRent = status => {
  return [
    userStatus.active,
    userStatus.incomplete,
    userStatus.unconfirmed,
    userStatus.updated,
  ].includes(status);
};

export const isObjectDiff = (prevObj, nextObj) => {
  const diffObj = diff(prevObj, nextObj) || {};
  // process.env.NODE_ENV === 'development' && console.log('Diff Object', diffObj);
  return !!Object.keys(diffObj).length;
};

import React, { useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { space } from 'styled-system';
import css from '@styled-system/css';
import Person from 'Components/molecules/Person';
import { usePageInfo, useUserInfo, useStatus, useUserCategory } from '~/context/';
import Alert from 'Components/atoms/Alert';
import FullScreenLoading from 'Components/molecules/FullScreenLoading';
import UserSetting from 'Components/molecules/UserSetting';
import UserCategory from 'Components/molecules/UserCategory';
import { getAlertMessage } from './config';
import { userStatusMap } from '~/configs/';
import NavigationBar from '~/components/organisms/NavigationBar';
import { UserCategoryProvider } from '~/context/providers/UserCategoryProvider';
import ErrorBoundary from 'Components/errorBoundaries/ErrorBoundary';

const AccountStyled = styled.div`
  background-color: ${props => props.theme.colors.bgGrey};
  height: auto;
  min-height: 100%;
  padding-top: 44px;
`;

const PersonStyled = styled(Person)`
  background-color: white;
  width: 100%;
  ${css({ padding: ['12.5px 10px', '15px 20px'] })}
`;

const AlertContainer = styled.div`
  ${space}
`;

const AccountComponent = React.memo(({ user, userCategory, alertMessage, pageInfo }) => {
  return (
    <FullScreenLoading spinning={user.isLoading}>
      <NavigationBar user={user} pageInfo={pageInfo} />
      <ErrorBoundary>
        <AccountStyled>
          <PersonStyled
            type="account"
            padding={['12.5px 10px', '15px 20px']}
            picture={user?.line?.picture}
            name={user?.line?.displayName || user?.firstName}
            email={user?.contactEmail}
          />
          {alertMessage ? (
            <AlertContainer padding={[10, 20]}>
              <Alert {...alertMessage} boxShadow="light" />
            </AlertContainer>
          ) : (
            <br />
          )}
          <UserSetting
            phone={user?.phone}
            email={user?.contactEmail}
            address={user?.address}
            isPhoneVerified={user?.isPhoneVerified}
          />
          <UserCategory userCategory={userCategory} />
        </AccountStyled>
      </ErrorBoundary>
    </FullScreenLoading>
  );
});

const AccountContainer = () => {
  const { pageInfo } = usePageInfo();
  const history = useHistory();
  const { user, updateUserInfo } = useUserInfo();
  const { userCategory, updateUserCategory } = useUserCategory();
  const { status, getCurrentStatus } = useStatus();

  const alertMessage = useMemo(
    getAlertMessage(
      userStatusMap[status.statusId],
      history,
      status.deadline && format(new Date(status.deadline), 'yyyy-MM-dd hh:mm:ss'),
      status.rejectResons
    ),
    [status.statusId, status.rejectResons]
  );

  useEffect(() => {
    updateUserInfo();
    getCurrentStatus();
    updateUserCategory();
  }, []);

  return (
    <AccountComponent
      user={user}
      pageInfo={pageInfo}
      userCategory={userCategory}
      alertMessage={alertMessage}
    />
  );
};

AccountComponent.defaultProps = {};

AccountComponent.propTypes = {};

const AccountConnected = () => (
  <UserCategoryProvider>
    <AccountContainer />
  </UserCategoryProvider>
);

export default AccountConnected;

import qs from 'qs';
import LineAxios from './LineAxios';

LineAxios.create('line', { host: 'https://api.line.me' });
const axios = LineAxios.getInstance('line');

const clientId = process.env.NODE_ENV === 'production' ? 1653909233 : 1653728067;

// TODO: it's useless now
export const verifyIdToken = geoLocation => {
  let formData = new FormData();

  formData.append('id_token', window.liff.getAccessToken());
  formData.append('client_id', '1653728067');

  return fetch(`https://api.line.me/oauth2/v2.1/verify`, {
    method: 'POST',
    mode: 'cors',
    body: formData,
  })
    .then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => console.log('Success:', response));
};

export const getLineUserProfileByAccessToken = async token => {
  return axios
    .get('/v2/profile', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
};

export const postLineIDTokenVerify = async (token, lineUseId) => {
  return axios
    .post(
      '/oauth2/v2.1/verify',
      qs.stringify({
        id_token: token,
        client_id: clientId,
        user_id: lineUseId,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then(({ data }) => data)
    .catch(error => {
      throw error;
    });
};

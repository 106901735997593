import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { labelFocus } from './style';

const LabelStyled = styled.label`
  user-select: none;
  color: ${themeGet('colors.mainDark')};
  font-size: 16px;
  line-height: 1;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  margin-bottom: 12px;
  ${labelFocus}
`;

export const PureTopFromItem = props => {
  const { children, label, focusColor } = props;

  return (
    <React.Fragment>
      {label && <LabelStyled focusColor={focusColor}>{label}</LabelStyled>}
      {React.cloneElement(children || <div />, {
        width: '100%',
      })}
    </React.Fragment>
  );
};

PureTopFromItem.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  focusColor: PropTypes.oneOf(['blue', 'red', 'grey']).isRequired,
};

export default React.memo(PureTopFromItem);

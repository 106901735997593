import React, {
  forwardRef,
  useCallback,
  useEffect,
  Fragment,
  useState,
  useMemo,
  useRef,
  useImperativeHandle,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { rentStatus } from '~/configs';
import { googleMapURL3, layerType } from '../../config';
import throttle from 'lodash.throttle';
import { useRenting, useStatus } from '~/context';
import PopupLoading from 'Components/molecules/PopupLoading';
import { ReactComponent as CenterMarkerIcon } from 'Images/icons/centerMarker.svg';
import {
  getScooters,
  getZones,
  getScooterStyles,
  getScooterById,
  getParkingSpaces,
} from '~/services/api';
import useLoadingGroup from '~/hooks/useLoadingGroup';
import { getColorAndAlphaFromHex } from '~/utils/colorConverter';
import { useErrorNotification } from '~/context/ErrorNotification';
import requestErrors from '~/configs/requestErrors';
import LocationIndicator from './components/LocationIndicator';
import ParkingLotPinList from './components/ParkingLotPinList';
import ScooterPinList from './components/ScooterPinList';
import ParkingSpaces from './components/ParkingSpaces';
import PolylineDirections from './components/PolylineDirections';
import Zones from './components/Zones';
import ScooterInfo from './components/ScooterInfo';
import mapStyles from './mapStyles';
import ErrorBoundaryNormal from './components/ErrorBoundaryNormal';

const WeMoMapStyled = styled.div``;

const defaultMapConfig = {
  center: {
    lat: 25.041969,
    lng: 121.544902,
  },
  zoom: 17,
  gestureHandling: 'greedy',
  options: {
    streetViewControl: false,
    clickableIcons: false,
    mapTypeControl: false,
    zoomControl: false,
    fullscreenControl: false,
    styles: mapStyles,
  },
  mapContainerStyle: {
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
};

const mapElementStyle = { height: `100%` };

const LoadScriptNextProps = {
  googleMapsApiKey: googleMapURL3,
  loadingElement: <div style={mapElementStyle} />,
  containerElement: <div />,
  mapElement: <div />,
};

const CenterMarker = styled.svg`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
`;

const holeTypeLayer = layerType.OPERATION;

const adjustLayer = rawLayer => {
  const layer = rawLayer.reduce((result, { scooterGroupIds, type, typeId, zIndex, zones }) => {
    if (!result[type]) {
      result[type] = [];
    }

    zones.forEach(({ id, polygons, title, desc, polygonsBorderColor, polygonsFilledColor }) => {
      const [polygonsFilledColorHex, polygonsFilledColorOpacity] = getColorAndAlphaFromHex(
        polygonsFilledColor
      );
      const [polygonsBorderColorHex, polygonsBorderColorOpacity] = getColorAndAlphaFromHex(
        polygonsBorderColor
      );

      const paths = polygons.map(polygon => polygon.map(([lat, lng]) => ({ lat, lng })));

      if (typeId === holeTypeLayer) {
        result[type] = [
          {
            id,
            title,
            desc,
            zIndex,
            polygonsFilledColorHex,
            polygonsFilledColorOpacity,
            polygonsBorderColorHex,
            polygonsBorderColorOpacity,
            polygonsPath: [...(result[type][0]?.polygonsPath || []), ...paths],
          },
        ];
      } else {
        result[type].push({
          id,
          zIndex,
          title,
          desc,
          polygonsFilledColorHex,
          polygonsFilledColorOpacity,
          polygonsBorderColorHex,
          polygonsBorderColorOpacity,
          polygonsPath: [...paths],
        });
      }
    });

    return result;
  }, {});

  return layer;
};

export const MapContent = React.memo(
  ({
    otherScooter,
    directions,
    location,
    isGuest,
    userMode,
    parkingLots,
    handleCenterCircleOnload,
    handleOnParkingLotPinClick,
    scooter,
    scooters,
    isAllowReserve,
    handleOnScooterPinClick,
    layers,
    showPolygon,
    googleMapRef,
    parkingSpaces,
    onPinCancel,
  }) => {
    const position = useMemo(() => ({ lat: location.lat, lng: location.lng }), [
      location.lat,
      location.lng,
    ]);

    // TODO 遇到一個問題 - GoogleMap onClick 事件會早於 pin 的 click 事件，所以同一個 pin 被點會再撈一次，需要有 * 小精靈 * 幫忙在 GoogleMap 時偵測是不是點在 pin 上，然後判斷要不要撈車資料
    return (
      <Fragment>
        {userMode === rentStatus.routeToScooter && directions && (
          <PolylineDirections directions={directions} />
        )}
        {location && (
          <LocationIndicator
            position={position}
            handleCenterCircleOnload={handleCenterCircleOnload}
          />
        )}
        {!isGuest && (
          <ParkingLotPinList
            showType={googleMapRef.current?.getZoom() >= 19}
            handleOnParkingLotPinClick={handleOnParkingLotPinClick}
            parkingLots={parkingLots}
          />
        )}
        <ScooterPinList
          onlyShowCurrentScooter={userMode === rentStatus.routeToScooter}
          currentScooter={scooter}
          scooters={scooters}
          handleOnScooterPinClick={handleOnScooterPinClick}
        />
        {otherScooter && <ScooterInfo scooter={otherScooter} />}
        <Zones layers={layers} visible={showPolygon} onPinCancel={onPinCancel} />
        {/* ! 這邊要注意是用 Ref 可能會造成 re-render 失效 */}
        {userMode !== rentStatus.routeToScooter && googleMapRef.current?.getZoom() >= 17 && (
          <ParkingSpaces visible={showPolygon} location={location} parkingSpaces={parkingSpaces} />
        )}
      </Fragment>
    );
  }
);

const handleParkingSpaces = async ({
  location,
  previousLocation,
  setParkingSpaces,
  launchApiError,
}) => {
  if (
    location.lat !== previousLocation.current?.lat ||
    location.lng !== previousLocation.current?.lng
  ) {
    try {
      const parkingSpaces = await getParkingSpaces(location);
      setParkingSpaces(parkingSpaces);
    } catch (error) {
      launchApiError(error, 'scooters');
    }
  }
  previousLocation.current = location;
};

const MapContainer = forwardRef(
  ({ isGuest, isCardVisible, showPolygon, ...weMoMapProps }, mapRef) => {
    const firstLocationRef = useRef();
    const {
      renting,
      scooter,
      mapParkingLot,
      setScooter,
      resetRenting,
      setMapParkingLot,
      updateUserMode,
      getNearByParkingLots
    } = useRenting();
    const { status } = useStatus();
    const [scooters, setScooters] = useState([]);
    const [otherScooter, setOtherScooter] = useState();
    const circleRef = useRef();
    const scooterStyleMapRef = useRef();
    const loadingUtils = useLoadingGroup({
      scooters: false,
      parkingLots: false,
      otherScooter: false,
    });
    const [layers, setLayers] = useState([]);
    const googleMapRef = useRef();
    const previousLocation = useRef();
    const [parkingSpaces, setParkingSpaces] = useState([]);
    const { registerErrorConfig, launchApiError } = useErrorNotification();
    const { isLoaded } = useLoadScript(LoadScriptNextProps);
    // TODO: 偷看看 paymentError 有沒有反映到 status.isAllowRide 上Ｆ
    const isAllowReserve = useMemo(
      () =>
        // status.paymentErr === 0 &&
        (status.isAllowRide &&
          [
            rentStatus.none,
            rentStatus.notRenting,
            rentStatus.guest,
            rentStatus.reserveParkingLot,
          ].includes(renting.userMode)) ||
        false,
      // TODO: 偷看看 paymentError 有沒有反映到 status.isAllowRide 上Ｆ
      [status.isAllowRide, renting.userMode]
      // [status.isAllowRide, status.paymentErr, renting.userMode]
    );

    const mapOnload = useCallback(ref => {
      if (googleMapRef) googleMapRef.current = ref;
      const mapCenter = ref.getCenter();
    }, []);

    const mapProps = useMemo(
      () => ({
        ...defaultMapConfig,
        center: firstLocationRef.current?.lat ? firstLocationRef.current : defaultMapConfig.center,
      }),
      [firstLocationRef.current]
    );

    const handleOnScooterPinClick = useCallback(async (id, evt) => {
      evt.stopPropagation();
      // if (!isGuest && isAllowReserve && id) {
      try {
        loadingUtils.start('scooters');
        const rawScooter = await getScooterById(id);
        const scooter = { ...rawScooter, ...scooterStyleMapRef.current[rawScooter.styleId] };
        setScooter({ scooterId: scooter.id, ...scooter });
        updateUserMode(rentStatus.notRenting);
        loadingUtils.end('scooters');
      } catch (error) {
        loadingUtils.end('scooters', 'fail');
        launchApiError(error, 'scooters');
        console.log(error);
      }
      // }
    }, []);

    const handleOnOtherScooterPinClick = useCallback(async (id, evt) => {
      evt.stopPropagation();
      // if (!isGuest && isAllowReserve && id) {
      try {
        loadingUtils.start('otherScooter');
        const scooter = await getScooterById(id);
        // const scooter = { ...rawScooter, ...otherScootertyleMapRef.current[rawScooter.styleId] };
        setOtherScooter({ scooterId: scooter.id, ...scooter });
        loadingUtils.end('otherScooter');
      } catch (error) {
        loadingUtils.end('otherScooter', 'fail');
        launchApiError(error, 'otherScooter');
        console.log(error);
      }
    }, []);

    const handleOnParkingLotPinClick = useCallback(
      async (parkingLot, evt) => {
        evt.stopPropagation();
        if (isAllowReserve) {
          updateUserMode(rentStatus.reserveParkingLot);
        } else if (renting.userMode === rentStatus.renting) {
          updateUserMode(rentStatus.rentingParkingLot);
        } else {
          return;
        }

        if (parkingLot.id !== mapParkingLot.id) {
          // const parkingLot = parkingLots.filter(parkingLot => parkingLot.id === id)[0];
          setMapParkingLot(parkingLot);
        }
      },
      [isAllowReserve, mapParkingLot.id, renting.userMode, setMapParkingLot, updateUserMode]
    );

    const [updateCenterCircle] = useDebouncedCallback(() => {
      circleRef.current?.setCenter(googleMapRef.current?.getCenter());
    }, 300);

    const handleCenterCircleOnload = useCallback(circle => {
      circleRef.current = circle;
    }, []);

    const assignScooterStyles = async () => {
      if (!scooterStyleMapRef.current) {
        const scooterStyles = await getScooterStyles();
        scooterStyleMapRef.current = scooterStyles.reduce((styleMap, { id, ...rest }) => {
          styleMap[id] = rest;
          return styleMap;
        }, {});
      }
      return scooterStyleMapRef.current;
    };

    const getNearbyScooters = throttle(async location => {
      if (!location) return;

      try {
        // loadingUtils.start('scooters');
        const originalScooters = await getScooters(location);

        const scooterStyles = await assignScooterStyles();
        const scooters = originalScooters.map(scooter => ({
          ...scooter,
          ...scooterStyles[scooter.styleId],
        }));

        // loadingUtils.end('scooters');
        setScooters(scooters);
      } catch (error) {
        // loadingUtils.end('scooters', 'fail');
        launchApiError(error, 'scooters');
        console.error(error);
      }
    }, 300);

    const handleOnMapIdle = useCallback(() => {
      const mapCenter = googleMapRef.current?.getCenter();
      if (
        renting.location &&
        mapCenter &&
        (isGuest ||
          isAllowReserve ||
          (renting.userMode !== rentStatus.routeToScooter && renting.userMode !== rentStatus.none))
      ) {
        getNearbyScooters({ lat: mapCenter.lat(), lng: mapCenter.lng() });
        getNearByParkingLots({ lat: mapCenter.lat(), lng: mapCenter.lng() });
      }
      if (showPolygon) {
        handleParkingSpaces({
          location: { lat: mapCenter.lat(), lng: mapCenter.lng() },
          previousLocation,
          setParkingSpaces,
          launchApiError,
        });
      }
    }, [
      showPolygon,
      launchApiError,
      getNearByParkingLots,
      getNearbyScooters,
      isAllowReserve,
      isGuest,
      renting.location,
      renting.userMode,
    ]);
    /**
     * 1. 把 zones 以 layer type 分類
     * 2. 如果不同 layer 同 type ，也分為同類（array 形式）
     */
    const handleLayers = async () => {
      try {
        const result = await getZones();

        const layers = adjustLayer(result?.[0]?.layers);

        setLayers(layers);
      } catch (error) {
        launchApiError(error, 'scooters');
      }
    };

    useImperativeHandle(
      mapRef,
      () => {
        return {
          googleMap: googleMapRef.current,
        };
      },
      [googleMapRef.current]
    );

    useMemo(() => {
      registerErrorConfig('scooters', {
        [requestErrors.SCOOTER_NOT_FOUND]: {
          snackbar: {
            message: '車輛發生異常，請預約其他車輛',
          },
          notifier: 'snackbar',
        },
        [requestErrors.SCOOTER_NOT_IN_ZONE]: {
          popup: {
            title: '請確認您的車輛停放位置',
            confirmButtonText: '確認',
            type: 'warning',
            notes: `您目前在營運範圍外\n無法還車。`,
          },
          notifier: 'popup',
        },
      });
    }, []);

    const setScooterStyle = async () => {
      if (scooter.scooterId && !scooter.pinImage) {
        const scooterStyles = await assignScooterStyles();
        const styledScooter = { ...scooter, ...scooterStyles[scooter.styleId] };
        setScooter({ ...styledScooter });
      }
    };

    useEffect(() => {
      if (showPolygon && renting.location)
        handleParkingSpaces({
          location: renting.location,
          previousLocation,
          setParkingSpaces,
          launchApiError,
        });
    }, [showPolygon]);

    useEffect(() => {
      setScooterStyle(); // 預約中狀態額外去指定 style，因 keyPageInfo 不會給此資訊
    }, [scooter.scooterId]);

    useEffect(() => {
      handleLayers();
    }, []);

    useEffect(() => {
      if (!firstLocationRef.current && renting.location) {
        firstLocationRef.current = renting.location;
      }
    }, [googleMapRef, renting.location]);

    const handleOnPinCancel = useCallback(
      evt => {
        setOtherScooter();
        if (isCardVisible) {
          if (isAllowReserve) {
            resetRenting();
          } else if (renting.userMode === rentStatus.rentingParkingLot) {
            // resetMapParkingLot();
            updateUserMode(rentStatus.renting);
          }
        }
      },
      [isCardVisible, isAllowReserve, renting.userMode, resetRenting, updateUserMode]
    );

    const handleOnMapClick = useCallback(
      evt => {
        // if (!evt.tb.cancelBubble) {
        handleOnPinCancel(evt);
        // }
      },
      [handleOnPinCancel]
    );

    return (
      <WeMoMapStyled {...weMoMapProps} data-testid="wemoMap">
        <CenterMarker as={CenterMarkerIcon} />
        {isLoaded && (
          <GoogleMap
            onClick={handleOnMapClick}
            onZoomChanged={updateCenterCircle}
            onIdle={handleOnMapIdle}
            onDrag={updateCenterCircle}
            {...mapProps}
            onLoad={mapOnload}
          >
            <ErrorBoundaryNormal>
              <MapContent
                directions={renting.directions}
                {...weMoMapProps}
                location={renting.location}
                isGuest={isGuest}
                userMode={renting.userMode}
                parkingLots={renting.mapAllParkingLots}
                handleCenterCircleOnload={handleCenterCircleOnload}
                handleOnParkingLotPinClick={handleOnParkingLotPinClick}
                scooter={scooter}
                otherScooter={otherScooter}
                scooters={scooters}
                isAllowReserve={isAllowReserve}
                handleOnScooterPinClick={
                  !isGuest && isAllowReserve
                    ? renting.userMode === rentStatus.renting
                      ? null
                      : handleOnScooterPinClick
                    : handleOnOtherScooterPinClick
                }
                layers={layers}
                showPolygon={showPolygon}
                googleMapRef={googleMapRef}
                parkingSpaces={parkingSpaces}
                onPinCancel={handleOnPinCancel}
              />
            </ErrorBoundaryNormal>
          </GoogleMap>
        )}
        <PopupLoading {...loadingUtils.state} />
      </WeMoMapStyled>
    );
  }
);

MapContainer.defaultProps = {
  scooters: [],
  mapConfig: {},
};

const WeMoMap = React.memo(MapContainer);

WeMoMap.whyDidYouRender = true;

export default WeMoMap;

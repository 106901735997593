import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { animated, useTransition } from 'react-spring';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import Loading from '~/components/atoms/Loading';
import Popup from '~/components/atoms/Popup';
import useLeastLoading from '~/hooks/useLeastLoading';
import loadingCheck from '~/assets/loading-check.json';

const loadingText = '處理中...';

const Content = styled.p`
  ${themeGet('fonts.note')}
  margin: 0;
  margin-top: 2px;
  color: #fcfcfc;
`;

const Wrapper = styled.div`
  text-align: center;
  ${space};
  ${layout};
`;

const Panel = styled(animated.div)`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  ${space};
`;

export const PurePopupLoading = props => {
  const {
    spinning,
    getContainer,
    successText,
    successDuration,
    onFinish,
    onSuccess,
    onFailure,
    status,
    onSuccessReplace,
    onSuccessGoBack,
  } = props;
  const history = useHistory();
  const { loading, start, stop } = useLeastLoading(600, spinning);
  const [showSuccess, setShowSuccess] = useState(false);
  const loadingTransition = useTransition(showSuccess, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  useEffect(() => {
    let id;
    if (spinning) {
      if (loading) return;
      start();
      setShowSuccess(false);
    } else {
      if (!loading) return;
      if (successText && status === 'success') {
        setShowSuccess(true);
        id = setTimeout(() => {
          clearTimeout(id);
          id = null;
          setShowSuccess(false);
          stop();
          if (onSuccessReplace) history.replace(onSuccessReplace.path, onSuccessReplace.state);
          else if (onSuccessGoBack) history.goBack();
          if (typeof onFinish === 'function') onFinish();
          if (typeof onSuccess === 'function') onSuccess();
        }, successDuration);
      } else {
        stop();
        if (typeof onFinish === 'function') onFinish();
        if (status === 'fail') {
          if (typeof onFailure === 'function') onFailure();
        }
      }
    }

    return () => {
      clearTimeout(id);
    };
  }, [
    spinning,
    loading,
    successText,
    // successDuration,
    // onFinish,
    // onSuccess,
    // onFailure,
    status,
    // history,
    // onSuccessReplace,
    // onSuccessGoBack,
    // start,
    // stop,
  ]);

  return (
    <Popup mode="light" getContainer={getContainer} visible={loading} width={118}>
      <Wrapper m={['0 -9px', '0 -18px']} minHeight={['82px', '46px']}>
        {loadingTransition.map(({ item, key, props }) =>
          item ? (
            <Panel key={key} style={props} py={['18px', '0']}>
              <Lottie
                options={{ loop: false, autoplay: true, animationData: loadingCheck }}
                width={30}
                height={30}
              />
              <Content>{successText}</Content>
            </Panel>
          ) : (
            <Panel key={key} style={props} py={['18px', '0']}>
              <Loading mode="light" />
              <Content>{loadingText}</Content>
            </Panel>
          )
        )}
      </Wrapper>
    </Popup>
  );
};

PurePopupLoading.defaultProps = {
  spinning: false,
  getContainer: () => document.body,
  successDuration: 1000,
  status: 'success',
  onSuccessGoBack: false,
};

PurePopupLoading.propTypes = {
  getContainer: PropTypes.elementType,
  spinning: PropTypes.bool,
  successText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  successDuration: PropTypes.number,
  status: PropTypes.oneOf(['success', 'fail', 'normal']),
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  onFinish: PropTypes.func,
  onSuccessReplace: PropTypes.shape({ path: PropTypes.string.isRequired, state: PropTypes.object }),
  onSuccessGoBack: PropTypes.bool,
};

export default React.memo(PurePopupLoading);

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { themeGet } from '@styled-system/theme-get';
import { color } from 'styled-system';
import { animated } from 'react-spring';

const Card = ({ children, ...props }) => {
  const { boxShadow, backgroundColor, ...cardProps } = props;
  return <animated.div {...cardProps}>{children}</animated.div>;
};

const CardStyled = styled(Card)`
  border-radius: 10px;
  width: 100%;
  background: white;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  position: relative;
  ${color}
  ${props => themeGet(`boxShadows.${props.boxShadow}`)};
  ${props => props.style};
`;

export const PureCard = props => {
  return <CardStyled {...props} />;
};

PureCard.defaultProps = {
  boxShadow: 'light',
};

PureCard.propTypes = {
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  boxShadow: PropTypes.oneOf(['none', 'light', 'dark', 'love']),
};

export default React.memo(PureCard);

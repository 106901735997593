import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const RadioButtonStyled = styled.label`
  width: 100%;
  height: 44px;
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  background-color: white;
  ${props => themeGet(`fonts.body`)};
  ${props => themeGet(`boxShadows.light`)};
  border-color: ${props =>
    props.checked ? themeGet('colors.mainBlue') : themeGet('colors.typeSpaceGrey')};
  color: themeGet('colors.secondaryGrey');
`;

const RadioInvisible = styled.input`
  display: none;
`;

const RadioButton = ({
  children,
  intitialValue,
  currentValue,
  value,
  onChange,
  className,
  ...props
}) => {
  const handleOnChange = evt => {
    typeof onChange === 'function' && onChange(evt);
  };

  const checked = value ? (currentValue ? currentValue === value : intitialValue === value) : false;

  return (
    <RadioButtonStyled checked={checked} className={className}>
      <span>{children}</span>
      <RadioInvisible
        type="radio"
        value={value}
        onChange={handleOnChange}
        checked={checked}
        {...props}
      />
    </RadioButtonStyled>
  );
};

RadioButton.propTypes = {
  children: PropTypes.string,
  currentValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default RadioButton;

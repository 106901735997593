import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popup from 'Components/atoms/Popup';
import Button from 'Components/atoms/Button';
import { themeGet } from '@styled-system/theme-get';

const Note = styled.div`
  position: relative;
  padding-left: 16px;
  margin-bottom: 10px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    top: 7.5px;
    background-color: ${props => props.theme.colors.mainBlue};
  }
`;

export const Title = styled.h1`
  ${props => props.theme.fonts.subtitle}
  margin: 0;
  color: ${({ type }) =>
    type === 'warning' ? themeGet('colors.wrongRed') : themeGet('colors.mainDark')};
  text-align: center;
`;

export const NotesP = styled.p`
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.secondaryGrey};
  margin: 0;
  margin-top: 4px;
  text-align: center;
  white-space: pre-line;
`;

export const NotesDiv = styled.div`
  ${props => props.theme.fonts.body2};
  color: ${props => props.theme.colors.secondaryGrey};
  margin: 0;
  margin-top: 4px;
  text-align: center;
  white-space: pre-line;
`;

const Footer = styled.div`
  width: 100%;
`;

const ButtonStyled = styled(Button)`
  margin-top: 18px;

  &:first-child {
    margin-top: 36px;
  }
`;

const PopupNote = ({
  children,
  title,
  notes,
  type,
  onConfirmClick,
  confirmButtonText,
  onCancelClick,
  cancelButtonText,
  footer,
  contentComponent,
  ...popupProp
}) => {
  return (
    <Popup {...popupProp}>
      <div>
        <Title type={type}>{title}</Title>
        {children ? (
          children
        ) : Array.isArray(notes) || typeof notes === 'string' ? (
          <NotesP>
            {Array.isArray(notes) ? notes?.map((note, i) => <Note key={note}>{note}</Note>) : notes}
          </NotesP>
        ) : (
          <NotesDiv>{notes}</NotesDiv>
        )}
        {contentComponent && contentComponent}
        <Footer>
          {footer || (
            <React.Fragment>
              <ButtonStyled
                boxShadow="light"
                size="small"
                width={1}
                shape="capsule"
                type="float"
                onClick={onConfirmClick}
              >
                {confirmButtonText}
              </ButtonStyled>
              {typeof onCancelClick === 'function' && (
                <ButtonStyled
                  color="plan"
                  boxShadow="light"
                  size="small"
                  width={1}
                  shape="capsule"
                  type="float"
                  onClick={onCancelClick}
                >
                  {cancelButtonText}
                </ButtonStyled>
              )}
            </React.Fragment>
          )}
        </Footer>
      </div>
    </Popup>
  );
};

PopupNote.defaultProps = {
  confirmButtonText: '確認',
  cancelButtonText: '取消',
  type: 'info',
};

PopupNote.propTypes = {
  type: PropTypes.oneOf(['info', 'warning']),
  title: PropTypes.string,
  notes: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
  contentComponent: PropTypes.element,
  onConfirmClick: PropTypes.func,
  confirmButtonText: PropTypes.string,
  onCancelClick: PropTypes.func,
  cancelButtonText: PropTypes.string,
  footer: PropTypes.element,
};

export default PopupNote;

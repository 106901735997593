import { setConfiguration } from 'react-grid-system';
setConfiguration({ breakpoints: [375, 414] }); // iphone 6/7/8, iphone 6/7/8/11 plus & pro max

const light = {
  breakpoints: ['375px', '414px'], // iphone 6/7/8, iphone 6/7/8/11 plus & pro
  colors: {
    mainBlue: '#4BC6E8',
    mainGreen: '#98C945',
    mainDark: '#555555',
    unavailableGrey: '#E5E5E5',
    secondaryGrey: '#969696',
    bgGrey: '#F2F2F2',
    supGrey: '#BCBCBC',
    grey3: '#828282',
    typeSpaceGrey: '#F7F7F7',
    wrongRed: '#EB5757',
    warningOrange: '#FF9A00',
    alertYellow: '#FFB800',
    supWrongRed: '#FFE1E1',
    uselessGreen: '#F8FCF0',
    uselessBlue: '#EFFCFF',
    gpsBlue: '#389EE3',
    gpsBlueSup: '#389ee32b',
    white: '#ffffff',
    transparent: 'transparent',
  },
  fontSizes: [12, 14, 16, 24, 32, 48, 64, 96, 128],
  boxShadows: {
    light: {
      boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.04), 0px 2px 3px rgba(0, 0, 0, 0.08)',
    },
    dark: {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    love: {
      boxShadow: '-1px 8px 24px rgba(0, 0, 0, 0.12)',
    },
    none: {},
  },
  fonts: {
    h1: {
      fontWeight: 600,
      fontSize: 30,
      lineHeight: '42px',
      color: '#555555',
      userSelect: 'none',
    },
    h2: {
      fontWeight: 'normal',
      fontSize: 24,
      lineHeight: '34px',
      color: '#555555',
      userSelect: 'none',
    },
    h2Button: {
      fontWeight: 500,
      fontSize: 17,
      color: '#555555',
      userSelect: 'none',
    },
    h3Button: {
      fontWeight: 500,
      fontSize: 24,
      color: '#555555',
      lineHeight: 1.5,
      userSelect: 'none',
    },
    h4: {
      fontWeight: 'normal',
      fontSize: 20,
      lineHeight: '28px',
      letterSpacing: 0.15,
      textIndent: 0.15,
      color: '#555555',
      userSelect: 'none',
    },
    h5Button: {
      fontWeight: 500,
      fontSize: 17,
      lineHeight: '24px',
      color: '#555555',
      userSelect: 'none',
    },
    h5: {
      fontSize: 38,
      lineHeight: '53px',
      color: '#4bc6e8',
      userSelect: 'none',
    },
    h6: {
      fontSize: 60,
      lineHeight: '64px',
      color: '#555555',
      letterSpacing: '1.9px',
      fontWeight: 300,
      userSelect: 'none',
    },
    subtitle: {
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '26px',
      letterSpacing: 0.15,
      color: '#555555',
      userSelect: 'none',
    },
    body: {
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 0.15,
      color: '#555555',
      userSelect: 'none',
    },
    body2: {
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: 0.15,
      color: '#555555',
      userSelect: 'none',
    },
    caption: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: 1.25,
      color: '#555555',
      userSelect: 'none',
    },
    captnoteion: {
      fontWeight: 'normal',
      fontSize: 10,
      lineHeight: '14px',
      color: '#555555',
      userSelect: 'none',
    },
    note: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '14px',
      color: '#BCBCBC',
      userSelect: 'none',
    },
    button2: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '24px',
      color: '#555555',
      userSelect: 'none',
    },
  },
};

export default light;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as LandscapeAlertIcon } from 'Images/icons/landscape-alert.svg';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: ${({ isDisplay }) => (isDisplay ? 'flex' : 'none')};
`;

const Wrapper = styled.div``;

const Title = styled.h1`
  ${themeGet('fonts.h1')};
  color: ${themeGet('colors.white')};
  margin: 0;
  text-align: center;
  height: 51px;
`;

const SubTitle = styled.h2`
  ${themeGet('fonts.h2')};
  color: ${themeGet('colors.white')};
  margin: 0;
  text-align: center;
  height: 51px;
`;

const Icon = styled.img.attrs({ as: LandscapeAlertIcon })`
  vertical-align: middle;
`;

export const PureLandscapeAlert = props => {
  const container = useRef();
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const containerEl = container.current;
    if (display) {
      disablePageScroll(containerEl);
    } else {
      enablePageScroll(containerEl);
    }
  }, [display]);

  useEffect(() => {
    const containerEl = container.current;
    const handleOrientatiOnChange = () => {
      if (window.orientation === 90 || window.orientation === -90) {
        setDisplay(true);
        return;
      }
      if (window.orientation === 180 || window.orientation === 0) {
        setDisplay(false);
        return;
      }
    };

    if ('onorientationchange' in window) {
      handleOrientatiOnChange();
      window.addEventListener('orientationchange', handleOrientatiOnChange);
    }

    return () => {
      if ('onorientationchange' in window) {
        window.removeEventListener('orientationchange', handleOrientatiOnChange);
      }
      containerEl && enablePageScroll(containerEl);
    };
  }, []);

  return (
    <Container ref={container} isDisplay={display}>
      <Wrapper>
        <Title>不支援橫向螢幕</Title>
        <SubTitle>請將螢幕轉正</SubTitle>
        <Icon />
      </Wrapper>
    </Container>
  );
};

PureLandscapeAlert.defaultProps = {};

PureLandscapeAlert.propTypes = {};

export default React.memo(PureLandscapeAlert);

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, border } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

const CheckboxStyled = styled.label`
  ${props => themeGet(`fonts.body2`)};
  line-height: auto;
  -webkit-tap-highlight-color: transparent;
`;

const Checkmark = styled.div`
  display: inline-block;
  vertical-align: -7px;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  padding: 4px;
  border: 1px solid ${props => themeGet(`colors.secondaryGrey`)};
  ${color}
  ${border}
`;

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0;
  height: 100%;
  width: 100%;

  & ~ svg {
    display: none;
  }

  &:checked ~ svg {
    display: block;
  }
`;

const Checkbox = ({
  label,
  defaultChecked,
  value,
  onChange,
  disabled,
  className,
  ...checkProps
}) => {
  if (defaultChecked !== undefined && value !== undefined) {
    console.error(
      'please use `initialValue` instead of `defaultChecked` if you Form element is used'
    );
  }

  const [checked, setChecked] = useState(
    value !== undefined ? value : defaultChecked !== undefined ? defaultChecked : false
  );

  const checkboxRef = useRef();

  const handleOnChange = evt => {
    setChecked(checkboxRef.current.checked);
    if (typeof onChange === 'function') {
      onChange({ ...evt, target: { ...evt.target, value: evt.target.checked } });
    }
  };

  return (
    <CheckboxStyled className={className}>
      <Checkmark
        backgroundColor={disabled ? 'unavailableGrey' : checked ? 'mainBlue' : 'white'}
        borderColor={checked && 'mainBlue'}
      >
        <CheckboxInput
          {...checkProps}
          disabled={disabled}
          type="checkbox"
          ref={ref => (checkboxRef.current = ref)}
          defaultChecked={defaultChecked}
          onChange={handleOnChange}
        />
        {/* TODO: animation  */}
        <svg
          width="15"
          height="14"
          viewBox="0 0 15 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 7.71429L6.55172 12L13 2"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Checkmark>
      {label}
    </CheckboxStyled>
  );
};

Checkbox.defaultProps = {
  disabled: false,
};

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  defaultChecked: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Checkbox;

import React from 'react';
import { Polyline } from '@react-google-maps/api';
import { withTheme } from 'styled-components';

const PolylineDirections = ({ directions, theme }) => {
  return (
    <Polyline
      path={directions}
      options={{
        strokeColor: theme.colors.mainBlue,
        strokeWeight: 3,
        visible: true,
        radius: 0,
      }}
    />
  );
};

export default React.memo(withTheme(PolylineDirections));

import { useEffect, useState, useRef } from 'react';

export const useFeedback = ({ ms = 300, onFinish } = {}) => {
  const isMounted = useRef(false);
  const timerId = useRef(null);
  const startAt = useRef(null);
  const finish = useRef(onFinish);
  const [feedback, setFeedback] = useState(false);

  const fallback = timestamp => {
    if (!startAt.current) startAt.current = timestamp;
    if (!isMounted.current || timerId.current === null || startAt.current === null) {
      cancelAnimationFrame(timerId.current);
      timerId.current = null;
      return;
    }

    if (timestamp - startAt.current >= ms) {
      setFeedback(false);
      if (typeof finish.current === 'function') finish.current();
      cancelAnimationFrame(timerId.current);
      timerId.current = null;
      startAt.current = null;
    } else {
      const oldTimerId = timerId.current;
      timerId.current = requestAnimationFrame(fallback);
      cancelAnimationFrame(oldTimerId);
    }
  };

  const trigger = onFinish => {
    if (!isMounted.current) return;
    finish.current = onFinish || finish.current;
    setFeedback(true);
    timerId.current = requestAnimationFrame(fallback);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return [feedback, trigger];
};

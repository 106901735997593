import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PageHeader from 'Components/molecules/PageHeader';
import { Row, Col } from 'react-grid-system';
import Button from 'Components/atoms/Button';
import { useDebouncedCallback } from 'use-debounce';
import RegistrationPhotoGroup from '~/components/molecules/RegistrationPhotoGroup';

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  overflow-y: scroll;
`;

const CustomPageHeader = styled(PageHeader)`
  flex-shrink: 0;
`;

const Content = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  flex: 1 0 auto;
`;

const Footer = styled.div`
  width: 100%;
  flex-shrink: 0;
  margin-top: 30px;
`;

class StepOutOffRange extends Error {
  constructor(message) {
    super(message);
    this.name = 'StepOutOffRange';
    this.type = 'STEP_OUT_OFF_RANGE';
  }
}

const stepsConfig = [
  {
    title: '拍攝身份證',
    subtitle: '確認照片清晰可辨\n避免照片過於模糊、反光',
    component: props => <RegistrationPhotoGroup type="IdCard" {...props} />,
  },
  {
    title: '拍攝駕照',
    subtitle: '確認照片清晰可辨\n避免照片過於模糊、反光',
    component: props => <RegistrationPhotoGroup type="License" {...props} />,
  },
  {
    title: '拍攝自拍照',
    subtitle: '確認照片清晰可辨\n避免照片過於模糊、反光',
    component: props => <RegistrationPhotoGroup type="Face" {...props} />,
  },
];

export const PureTakeRegistrationPhotoStep = props => {
  const {
    step,
    prevStepText,
    prevStepDisabled,
    prevStepOnClick,
    nextStepText,
    nextStepDisabled,
    nextStepOnClick,
    onChange,
    onStepFinish,
    photos,
    onError,
    isAllFinish,
    onAllFinishClick,
    allFinishText,
  } = props;
  const Component = useMemo(() => stepsConfig[step].component, [step]);

  const [handlePrevOnClick] = useDebouncedCallback(
    () => {
      if (typeof prevStepOnClick === 'function') {
        prevStepOnClick();
      }
    },
    300,
    {
      leading: true,
      trailing: false,
    }
  );

  const [handleNextOnClick] = useDebouncedCallback(
    () => {
      if (typeof nextStepOnClick === 'function') {
        nextStepOnClick();
      }
    },
    300,
    {
      leading: true,
      trailing: false,
    }
  );

  const handleOnChange = useCallback(
    (index, photo) => {
      if (typeof onChange === 'function') {
        onChange(index, photo);
      }
    },
    [onChange]
  );

  const handleOnStepFinish = useCallback(() => {
    if (typeof onStepFinish === 'function') {
      onStepFinish();
    }
  }, [onStepFinish]);

  const [handleOnAllFinishClick] = useDebouncedCallback(
    () => {
      if (typeof onAllFinishClick === 'function') {
        onAllFinishClick();
      }
    },
    300,
    {
      leading: true,
      trailing: false,
    }
  );

  useEffect(() => {
    if (step > stepsConfig.length || step < 0) {
      onError(new StepOutOffRange());
    }
  }, [step]);

  return (
    <Container>
      <CustomPageHeader title={stepsConfig[step].title} subtitle={stepsConfig[step].subtitle} />
      <Content>
        <Component photos={photos} onChange={handleOnChange} onFinish={handleOnStepFinish} />
      </Content>
      <Footer>
        <Row>
          {isAllFinish ? (
            <Col xs={12}>
              <Button
                shape="capsule"
                type="float"
                boxShadow="light"
                width={1}
                onClick={handleOnAllFinishClick}
              >
                {allFinishText}
              </Button>
            </Col>
          ) : (
            <React.Fragment>
              <Col xs={6}>
                <Button
                  shape="capsule"
                  boxShadow="light"
                  type="float"
                  color="plan"
                  width={1}
                  disabled={prevStepDisabled}
                  onClick={handlePrevOnClick}
                >
                  {prevStepText}
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  shape="capsule"
                  type="float"
                  boxShadow="light"
                  width={1}
                  disabled={nextStepDisabled}
                  onClick={handleNextOnClick}
                >
                  {nextStepText}
                </Button>
              </Col>
            </React.Fragment>
          )}
        </Row>
      </Footer>
    </Container>
  );
};

PureTakeRegistrationPhotoStep.defaultProps = {
  step: 0,
  prevStepText: '上一步',
  nextStepText: '下一步',
  prevStepDisabled: false,
  nextStepDisabled: false,
  isAllFinish: false,
  allFinishText: '繼續完成註冊',
};

PureTakeRegistrationPhotoStep.propTypes = {
  step: PropTypes.number,
  prevStepText: PropTypes.string,
  prevStepDisabled: PropTypes.bool,
  prevStepOnClick: PropTypes.func,
  nextStepText: PropTypes.string,
  nextStepDisabled: PropTypes.bool,
  nextStepOnClick: PropTypes.func,
  onChange: PropTypes.func,
  onStepFinish: PropTypes.func,
  photos: PropTypes.array,
  onError: PropTypes.func,
  isAllFinish: PropTypes.bool,
  onAllFinishClick: PropTypes.func,
  allFinishText: PropTypes.string,
};

export default React.memo(PureTakeRegistrationPhotoStep);

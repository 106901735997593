import { enableMockData, disableMockData } from '../mock';
export * from './users';
export * from './scooters';
export * from './scooterStyles';
export * from './parkingLots';
export * from './line';
export * from './payment';
export * from './invoice';
export * from './tapPay';
export * from './order';
export * from './googleMap';
export * from './feedback';
export * from './parkingSpaces';
export * from './emergency';
export * from './mission';

if (process.env.REACT_APP_API_MOCK || process.env.NODE_ENV === 'test') {
  enableMockData();
} else {
  disableMockData();
}
